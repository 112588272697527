import React,
{
  useEffect,
  FC,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, { Column } from 'material-table';
import { useCookies } from 'react-cookie';
import { RootState } from '../../store/slices';
import { ItemOfUserList } from '../../interfaces/adminRoleAssignment.interface';
import { getAllUsers, updateUserData, resendRegistrationConfirmationEmail } from '../../store/slices/adminRoleAssignment.slice';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import { UserData } from '../../interfaces/user.interface';
import Contacts from '../../svg/contacts';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
import UserProfileDetails from '../../components/Request/UserProfileDetails';


const AdminRoleAssignment: FC = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['rowAdminRoleAssignment']);

  const [open, setOpen] = useState<number | false>(false);
  const handleClose = () => {
    setOpen(false);
  };

  const columns: Array<Column<ItemOfUserList>> = [
    {
      title: 'Open',
      field: '',
      render: (rowData) => (
        <ButtonSC
          variant="fill"
          text="Open"
          size="small"
          color="green"
          handleClick={() => {
            setOpen(rowData.id);
          }}
        />
      ),
    },
    {
      title: 'Role',
      field: 'role',
      lookup: {
        Guest: 'Guest',
        Admin: 'Admin',
        Broker: 'Broker',
        Seller: 'Seller',
        Buyer: 'Buyer',
        SellerBuyer: 'Seller & Buyer',
      },
      render: (rowData) => {
        if (rowData.role === 'SellerBuyer') {
          return 'Seller & Buyer';
        }
        return rowData.role;
      },
    },
    {
      title: 'Name',
      field: 'lastName',
      render: (rowData) => `${rowData.lastName} ${rowData.firstName}`,
      customFilterAndSearch: (term, rowData) => (`${rowData.lastName} ${rowData.firstName}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
      editable: 'never',
    },
    { title: 'Email', field: 'email' },
  ];

  const [
    stateListOfUsers,
    setListOfUsers,
  ] = useState<Array<ItemOfUserList>>([]);

  const {
    listOfUsers,
  } = useSelector(
    (state: RootState) => state.users,
  );

  useEffect(() => {
    const { userId, token } = getLocalStorageData();
    dispatch(getAllUsers(userId, token));
  }, [dispatch]);

  useEffect(() => {
    setListOfUsers(listOfUsers.map((item) => ({ ...item })));
  }, [listOfUsers, setListOfUsers]);

  const handleUpdateUser = useCallback(
    async (values, roleChange, emailChange) => {
      const { userId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      let isAdmin = false;
      let isBroker = false;
      let isBuyer = false;
      let isGuest = false;
      let isSeller = false;

      let isConfirmed = false;
      if (values.role === 'Guest') { isGuest = true; isAdmin = false; isSeller = false; isBroker = false; isBroker = false; isConfirmed = true; }
      if (values.role === 'Admin') { isGuest = false; isAdmin = true; isSeller = false; isBroker = false; isBroker = false; isConfirmed = true; }
      if (values.role === 'Buyer') { isGuest = false; isBuyer = true; isSeller = false; isBroker = false; isAdmin = false; isConfirmed = true; }
      if (values.role === 'Seller') { isGuest = false; isBuyer = false; isSeller = true; isBroker = false; isAdmin = false; isConfirmed = true; }
      if (values.role === 'Broker') { isGuest = false; isBroker = true; isBuyer = false; isSeller = false; isAdmin = false; isConfirmed = true; }
      if (values.role === 'SellerBuyer') { isGuest = false; isBuyer = true; isSeller = true; isBroker = false; isAdmin = false; isConfirmed = true; }
      let dataToSend = { userToUpdateId: values.id } as UserData;
      if (roleChange) {
        dataToSend = {
          ...dataToSend,
          isGuest,
          isAdmin,
          isBroker,
          isSeller,
          isBuyer,
          isConfirmed,
        };
      }
      if (emailChange) {
        dataToSend = {
          ...dataToSend,
          newUserEmail: values.email,
        };
      }
      await dispatch(
        updateUserData(
          dataToSend,
          userId,
          token,
          key,
        ),
      );
    },
    [dispatch],
  );

  return (
    <TableContainer>
      <MaterialTable
        title="Users"
        columns={columns}
        data={stateListOfUsers}
        editable={{
          onRowUpdate: (newData, oldData) => handleUpdateUser(
            newData,
            oldData?.role !== newData?.role,
            oldData?.email !== newData?.email,
          ),
        }}
        actions={[
          (rowData: ItemOfUserList) => ({
            icon: () => <Contacts />,
            tooltip: 'Resend email',
            onClick: () => {
              const { token } = getLocalStorageData();
              const key = new Date().getTime() + Math.random();
              dispatch(resendRegistrationConfirmationEmail(rowData.id, token, key));
            },
            disabled: rowData.role !== 'Unconfirmed',
          }),
        ]}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
          },
          actionsCellStyle: {
            color: '#075955',
          },
          pageSize: +cookies.rowAdminRoleAssignment || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowAdminRoleAssignment', pageSize, { path: '/' })}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
        }}
      />
      {open && <UserProfileDetails open={open} onClose={() => handleClose()} />}
    </TableContainer>
  );
};

export default AdminRoleAssignment;
