import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  drawChatstyleOpen: {
    width: '25vw',
    '@media (max-width: 1025px)': {
      width: '40vw',
    },
    '@media (max-width: 768px)': {
      width: '35vw',
    },
    '@media (max-width: 600px)': {
      width: '100vw',
      height: '100vh',
    },
    '@media (max-width: 900px) and (max-height: 560px)': {
      width: '100vw',
      height: '100vh',
    },
    '& .MuiDrawer-docked':{
      width: '45vw',
    },
    '& .MuiDrawer-paper': {
      width: '25vw',
      borderRadius: '15px',
      marginTop: 92,
      height: 'calc(100% - 102px)',

      // height: '84vh',
      overflowY: 'clip',
      '@media (max-width: 1025px)': {
        width: '40vw',
      },
      '@media (max-width: 1025px) and (max-height: 769px)': {
        width: '40vw',
      },
      '@media (max-width: 768px)': {
        width: '35vw',
      },
      '@media (max-width: 600px)': {
        width: '100vw',
        height: '100vh',
        marginTop: 0,
        maxHeight: '100%',
      },
      '@media (max-width: 900px) and (max-height: 560px)': {
        width: '100vw',
        height: '100vh',
        marginTop: 0,
        maxHeight: '100%',
      },
    },
  },
  drawChatstyleClosed: {
    width: 0,
  },
  chatContainerWrapper: {
    height: '100%',
    paddingTop: 20,
    paddingBottom: 30,
    '@media (max-width: 900px) and (max-height: 560px)': {
      paddingTop: 5,
      paddingBottom: 5,
    }
  },
  chatFixedContainer: {
    boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
  },
  chatWidth: {
    height: '100%',
    Width: '100%',
    margin: '0',
  },
  paperContainer: {
    boxShadow: 'none',
    padding: '0',
  },
  chatTitle: {
    padding: '0 24px 8px',
  },
  chatFab: {
    // zIndex: '10',
    visibility: 'hidden',
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '@media (max-width: 1025px)': {
      visibility: 'initial',
    },
  },
}));

export default useStyles;
