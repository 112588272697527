import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import { UserListState, ItemOfUserList } from '../../interfaces/adminRoleAssignment.interface';
import { apiUpdateUserData, apiGetAllUsers, apiResendRegistrationConfirmationEmail } from '../../api/adminRoleAssignment';
import { UserData } from '../../interfaces/user.interface';
import translationEN from '../../locales/en/translation.json';

const initialState: UserListState = {
  listOfUsers: [],
  error: '',
  isLoading: false,
};

const adminRoleAssignmentSlice = createSlice({
  initialState,
  name: 'users',
  reducers: {
    usersRequest(state) {
      state.error = '';
      state.isLoading = true;
    },
    usersSuccess(state, action) {
      if (Array.isArray(action.payload)) {
        state.listOfUsers = action.payload;
      } else {
        const index = state.listOfUsers
          .findIndex((obj: ItemOfUserList) => obj.id === action.payload.id);
        if (index !== -1) {
          state.listOfUsers[index] = action.payload;
        } else {
          state.listOfUsers.push(action.payload);
        }
      }
      state.error = '';
      state.isLoading = false;
    },
    usersFailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  usersRequest,
  usersSuccess,
  usersFailure,
} = adminRoleAssignmentSlice.actions;

export const getAllUsers = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
): AppThunk => async (dispatch) => {
  try {
    dispatch(usersRequest());
    const users = await dispatch(apiGetAllUsers(userId, token));
    dispatch(usersSuccess(users));
  } catch (error) {
    dispatch(usersFailure(error?.response ? error?.response?.data?.error : error?.message));
  }
};

export const updateUserData = (
  data: UserData,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(usersRequest());
    const user = await dispatch(apiUpdateUserData(data, userId, token));
    dispatch(usersSuccess(user));
    dispatch(addNotification({
      key,
      message: translationEN.UserDataUpdatedSuccessfully,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(usersFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const resendRegistrationConfirmationEmail = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiResendRegistrationConfirmationEmail(userId, token));
    dispatch(addNotification({
      key,
      message: translationEN.SuccessResendConfirmationEmail,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default adminRoleAssignmentSlice.reducer;
