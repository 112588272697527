import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actions: {
    color: theme.palette.primary.light,
    fontSize: 24,
    paddingLeft: 0,
  },
  test: {
    background: 'yellow',
  },
  searchInputWrapper: {
    position: 'absolute',
    right: '24px',
    width: '320px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  inputSearchSpan: {
    width: '100%',
    borderBottom: '1px solid gray',
    marginRight: '16px'

  },
  searchInput: {
    fontSize: '14px',
    border: 'none',
    width: '80%',
    outline: 'none'
  },
  tableWrapper: {
    background: 'white',
    padding: '24px',
    width: '100%',
    borderRadius: '16px',
    marginTop: '32px',
    overflowY: 'auto',
    // maxHeight: '366px',
    position: 'relative',
    marginBottom: '42px',
    '&:last-child': {
      marginBottom: '0px',
    }
  },
  table: {
    width: '100%',
    background: 'white',
    borderRadius: '16px',
    border: 'none',
    boxSizing: 'inherit',
    position: 'relative',
    marginTop: '48px',
    marginBottom: '16px'
  },
  tableHeader: {
    background: '#F0F4F8',
    border: 'none',
    borderCollapse: 'collapse',
  },
  tableHeaderText: {
    fontFamily: 'Arimo, Arial',
    whiteSpace: 'nowrap',
    background: '#F0F4F8',
    color: 'rgba(0, 0, 0, 0.54)',
    height: '40px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '16px',
    border: 'none',
    padding: '0px 8px 0px 8px',
    borderCollapse: 'collapse',
  },
  tableText: {
    fontFamily: 'Arimo, Arial',
    whiteSpace: 'nowrap',
    height: '40px',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '16px',
    border: 'none',
    padding: '0px 8px 0px 8px',
    borderCollapse: 'collapse',
  },
  buttonStyle: {
    border: 'none',
    background: 'none',
    color: 'green',
    cursor: 'pointer'
  },
  headerName: {
    marginLeft: '32px'
  },
  pagination: {
    position: 'absolute',
    right: '32px'
  },
  pagination_button: {
    border: 'none', 
    background: 'white',
    cursor: 'pointer'
  }
}));

export default useStyles;
