import React, { FC, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

// import WebcamCapture from './WebcamCapture';
// import ImageUploader from '../ImageUploader/ImageUploader';
import { User } from '../../interfaces/user.interface';
import useStyles from './ProfilePage.style';
import ButtonSC from '../ButtonSC/ButtonSC';
import FileUploader from '../FileUploader/FileUploader';
import constants from '../../core/constants';
import DocUpload from '../../svg/docUpload';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

interface Props {
  setValue: (field: string, file: Array<File> | string | undefined | Date) => void;
  newFiles: User;
  currentTab: number;
  legalStatus: string;
}

const Documents: FC<Props> = ({
  newFiles,
  setValue,
  currentTab,
  legalStatus,
}) => {
  const classes = useStyles();

  // const [isOpenDelPhotoModal, openDelPhotoModal] = useState(false);
  // const [webCam, enableWebCam] = useState(0);
  const [isOpenDelFileModal, openDelFileModal] = useState({ open: false, name: '' });

  const [W9Uri, setW9Uri] = useState<string>('');
  const [LLC1DocUri, setLLC1DocUri] = useState<string>('');
  const [LLC2DocUri, setLLC2DocUri] = useState<string>('');
  const [incorporationCertificateUri, setIncorporationCertificateUri] = useState<string>('');
  const [incorporationArticlesUri, setIncorporationArticlesUri] = useState<string>('');
  const [tickerSymbolUri, setTickerSymbolUri] = useState<string>('');

  const fileToDataUri = (file: any) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result);
    };
    reader.readAsDataURL(file[0]);
  });

  useEffect(() => {
    if (newFiles?.W9) fileToDataUri(newFiles.W9).then((dataUri) => setW9Uri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (newFiles?.LLC1Doc) fileToDataUri(newFiles.LLC1Doc).then((dataUri) => setLLC1DocUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (newFiles?.LLC2Doc) fileToDataUri(newFiles.LLC2Doc).then((dataUri) => setLLC2DocUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (newFiles?.incorporationCertificate) fileToDataUri(newFiles.incorporationCertificate).then((dataUri) => setIncorporationCertificateUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (newFiles?.incorporationArticles) fileToDataUri(newFiles.incorporationArticles).then((dataUri) => setIncorporationArticlesUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (newFiles?.tickerSymbol) fileToDataUri(newFiles.tickerSymbol).then((dataUri) => setTickerSymbolUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
  }, [newFiles]);

  return (
    <Grid container className={currentTab === 2 ? '' : classes.displayNone} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.title}>
          {legalStatus && legalStatus !== 'SOLE PROPRIETOR' ? (
            'Upload the following documents based on the legal status selected, up to 10Mb in size:'
          ) :
            (
              'You don\'t need upload documents'
            )}
        </Typography>
        {legalStatus && legalStatus !== 'SOLE PROPRIETOR' && (
          <ol type="i">
            <li>
              {'W-9 '}
              <Link href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" rel="noreferrer">
                Open
              </Link>
            </li>
            <FileUploader
              multiSelect={false}
              uploadFile={(file) => {
                setValue('W9', file);
              }}
            />
            {newFiles?.W9MediaLink && (
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={1}>
                  <DocUpload />
                </Grid>
                <Grid item xs={9}>
                  <p className={classes.docName}>
                    <Link href={`${constants.AWS_URL}${newFiles.W9MediaLink.link}`} target="_blank" rel="noreferrer">
                      {newFiles.W9MediaLink.title}
                    </Link>
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => { openDelFileModal({ open: true, name: 'W9MediaLink' }); }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {newFiles?.W9 && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    New:
                  </Typography>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={1}>
                    <DocUpload />
                  </Grid>
                  <Grid item xs={9}>
                    <p className={classes.docName}>
                      <Link href={W9Uri} download={newFiles.W9 && newFiles.W9[0].name} target="_blank" rel="noreferrer">
                        {newFiles.W9 && newFiles.W9[0].name}
                      </Link>
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => { openDelFileModal({ open: true, name: 'W9' }); }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            )}
            {legalStatus && legalStatus === 'PARTNERSHIP/LLC' && (
              <>
                <li>
                  Partnership Agreement for General Partnership; or Certificate or
                  Articles of Incorporation for LLC.
                </li>
                <FileUploader
                  multiSelect={false}
                  uploadFile={(file) => {
                    setValue('LLC1Doc', file);
                  }}
                />
                {newFiles.LLC1DocMediaLink && (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={1}>
                      <DocUpload />
                    </Grid>
                    <Grid item xs={9}>
                      <p className={classes.docName}>
                        <Link href={`${constants.AWS_URL}${newFiles.LLC1DocMediaLink.link}`} target="_blank" rel="noreferrer">
                          {newFiles.LLC1DocMediaLink.title}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => { openDelFileModal({ open: true, name: 'LLC1DocMediaLink' }); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {newFiles.LLC1Doc && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        New:
                      </Typography>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={1}>
                        <DocUpload />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.docName}>
                          <Link href={LLC1DocUri} download={newFiles.LLC1Doc && newFiles.LLC1Doc[0].name} target="_blank" rel="noreferrer">
                            {newFiles.LLC1Doc && newFiles.LLC1Doc[0].name}
                          </Link>
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => { openDelFileModal({ open: true, name: 'LLC1Doc' }); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                )}
                <li>
                  Operating Agreement for Managing Members for LLC
                </li>
                <FileUploader
                  multiSelect={false}
                  uploadFile={(file) => {
                    setValue('LLC2Doc', file);
                  }}
                />
                {newFiles.LLC2DocMediaLink && (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={1}>
                      <DocUpload />
                    </Grid>
                    <Grid item xs={9}>
                      <p className={classes.docName}>
                        <Link href={`${constants.AWS_URL}${newFiles.LLC2DocMediaLink.link}`} target="_blank" rel="noreferrer">
                          {newFiles.LLC2DocMediaLink.title}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => { openDelFileModal({ open: true, name: 'LLC2DocMediaLink' }); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {newFiles.LLC2Doc && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        New:
                      </Typography>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={1}>
                        <DocUpload />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.docName}>
                          <Link href={LLC2DocUri} download={newFiles.LLC2Doc && newFiles.LLC2Doc[0].name} target="_blank" rel="noreferrer">
                            {newFiles.LLC2Doc && newFiles.LLC2Doc[0].name}
                          </Link>
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => { openDelFileModal({ open: true, name: 'LLC2Doc' }); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
            {legalStatus && legalStatus === 'PRIVATE CO' && (
              <>
                <li>
                  Certificate of Incorporation
                </li>
                <FileUploader
                  multiSelect={false}
                  uploadFile={(file) => {
                    setValue('incorporationCertificate', file);
                  }}
                />
                {newFiles.incorporationCertificateMediaLink && (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={1}>
                      <DocUpload />
                    </Grid>
                    <Grid item xs={9}>
                      <p className={classes.docName}>
                        <Link href={`${constants.AWS_URL}${newFiles.incorporationCertificateMediaLink.link}`} target="_blank" rel="noreferrer">
                          {newFiles.incorporationCertificateMediaLink.title}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => { openDelFileModal({ open: true, name: 'incorporationCertificateMediaLink' }); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {newFiles.incorporationCertificate && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        New:
                      </Typography>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={1}>
                        <DocUpload />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.docName}>
                          <Link href={incorporationCertificateUri} download={newFiles.incorporationCertificate && newFiles.incorporationCertificate[0].name} target="_blank" rel="noreferrer">
                            {newFiles.incorporationCertificate &&
                              newFiles.incorporationCertificate[0].name}
                          </Link>
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => { openDelFileModal({ open: true, name: 'incorporationCertificate' }); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                )}
                <li>
                  Articles of Incorporation
                </li>
                <FileUploader
                  multiSelect={false}
                  uploadFile={(file) => {
                    setValue('incorporationArticles', file);
                  }}
                />
                {newFiles.incorporationArticlesMediaLink && (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={1}>
                      <DocUpload />
                    </Grid>
                    <Grid item xs={9}>
                      <p className={classes.docName}>
                        <Link href={`${constants.AWS_URL}${newFiles.incorporationArticlesMediaLink.link}`} target="_blank" rel="noreferrer">
                          {newFiles.incorporationArticlesMediaLink.title}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => { openDelFileModal({ open: true, name: 'incorporationArticlesMediaLink' }); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {newFiles.incorporationArticles && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        New:
                      </Typography>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={1}>
                        <DocUpload />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.docName}>
                          <Link href={incorporationArticlesUri} download={newFiles.incorporationArticles && newFiles.incorporationArticles[0].name} target="_blank" rel="noreferrer">
                            {newFiles.incorporationArticles &&
                              newFiles.incorporationArticles[0].name}
                          </Link>
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => { openDelFileModal({ open: true, name: 'incorporationArticles' }); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
            {legalStatus && legalStatus === 'PUBLIC CO' && (
              <>
                <li>
                  Ticker Symbol
                </li>
                <FileUploader
                  multiSelect={false}
                  uploadFile={(file) => {
                    setValue('tickerSymbol', file);
                  }}
                />

                {newFiles.tickerSymbolMediaLink && (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={1}>
                      <DocUpload />
                    </Grid>
                    <Grid item xs={9}>
                      <p className={classes.docName}>
                        <Link href={`${constants.AWS_URL}${newFiles.tickerSymbolMediaLink.link}`} target="_blank" rel="noreferrer">
                          {newFiles.tickerSymbolMediaLink.title}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => { openDelFileModal({ open: true, name: 'tickerSymbolMediaLink' }); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {newFiles.tickerSymbol && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        New:
                      </Typography>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={1}>
                        <DocUpload />
                      </Grid>
                      <Grid item xs={9}>
                        <p className={classes.docName}>
                          <Link href={tickerSymbolUri} download={newFiles.tickerSymbol && newFiles.tickerSymbol[0].name} target="_blank" rel="noreferrer">
                            {newFiles.tickerSymbol && newFiles.tickerSymbol[0].name}
                          </Link>
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => { openDelFileModal({ open: true, name: 'tickerSymbol' }); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </ol>
        )}
      </Grid>
      {/* <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          Approve photo
        </Typography>
        {webCam ?
          (
            <>
              <WebcamCapture />
              <Button
                variant="contained"
                color="primary"
                onClick={() => enableWebCam(0)}
              >
                Close webcam
            </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => enableWebCam(1)}
              >
                Enable webcam
              </Button>
              <Button
                variant="contained"
                color="primary"
              >
                Add photo
                <ImageUploader uploadPhoto={(file) => setValue('personalPhoto', file)} />
              </Button>
            </>
          )}
      </Grid> */}
      {isOpenDelFileModal.open && (
        <ConfirmationDialog
          open={isOpenDelFileModal.open}
          title="File deletion"
          description={(
            <p>
              Are you sure you want to delete the selected file?
            </p>
          )}
          acceptButtonText="Delete"
          cancelButtonText="Cancel"
          color="green"
          onAcceptButtonClicked={
            () => {
              setValue(isOpenDelFileModal.name, undefined);
              openDelFileModal({ open: false, name: '' });
            }
          }
          onCancelButtonClicked={
            () => openDelFileModal({ open: false, name: '' })
          }
        />
      )}
    </Grid>
  );
};

export default Documents;
