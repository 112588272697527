import React, { FC } from 'react';
import { Drawer, Fab, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import useStyles from './DrawerChat.style';
import cx from 'classnames';
import { ChatMessage, ItemOfDealList } from '../../../interfaces/deals.interface';
import Chat from '../Chat';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


interface Props {
  deal: ItemOfDealList | null;
  chatMessages: ChatMessage[];
  handleSendMessage: (message: string, showForSeller: boolean, showForBuyer: boolean) => void;
  handleDrawerOpen: () => void;
  open: boolean;
  // sellerOnOff: boolean;
  // buyerOnOff: boolean;
}

const DrawerChat: FC<Props> = ({ deal, chatMessages, handleSendMessage, open, handleDrawerOpen, 
  // sellerOnOff, buyerOnOff
}) => {
  const deals = deal;
  const sellerIdPrpops = (deals?.sellerBroker && deals?.sellerBroker.id) || (deals?.seller && deals?.seller.id);

  const classes = useStyles();
  return (

    <Drawer
      className={ open ? classes.drawChatstyleOpen : classes.drawChatstyleClosed }
      variant={ window.innerWidth < 600 ? 'temporary' : 'persistent'}
      anchor="right"
      open={open}
    >
      {
        (
          <Paper
            className={cx(
              classes.paperContainer,
              classes.chatFixedContainer,
              classes.chatWidth,
            )}
          >
            <div className={classes.chatContainerWrapper}>
              <Grid container justifyContent="space-between" className={classes.chatTitle}>
                <Grid item style={{ alignSelf: 'center' }}>
                  <Typography variant="h2">
                    Chat
                  </Typography>
                </Grid>
                <Grid item>
                  <Fab color="primary" aria-label="openChat" size="small" className={classes.chatFab} onClick={() => handleDrawerOpen()}>
                    <ChevronRightIcon/>
                  </Fab>
                </Grid>
              </Grid>
              <Chat
                sellerId={sellerIdPrpops}
                buyerId={(deals?.buyerBroker && deals?.buyerBroker.id) ||
                  (deals?.buyer && deals?.buyer.id)}
                messages={chatMessages}
                sendMessage={handleSendMessage}
                status={deals?.status}
                // sellerOnOff={sellerOnOff}
                // buyerOnOff={buyerOnOff}
              />
            </div>
          </Paper>
        )}
    </Drawer>
  );
}

export default DrawerChat;