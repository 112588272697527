import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const BrokerClients = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 11">
    <path d="M16 7.2875C16 7.7 15.2 7.975 14.2667 8.1125C13.6667 6.94375 12.4667 6.05 11.0667 5.43125C11.2 5.225 11.3333 5.0875 11.4667 4.88125H12C14.0667 4.8125 16 6.11875 16 7.2875ZM4.53333 4.8125H4C1.93333 4.8125 0 6.11875 0 7.2875C0 7.7 0.8 7.975 1.73333 8.1125C2.33333 6.94375 3.53333 6.05 4.93333 5.43125L4.53333 4.8125ZM8 5.5C9.46667 5.5 10.6667 4.2625 10.6667 2.75C10.6667 1.2375 9.46667 0 8 0C6.53333 0 5.33333 1.2375 5.33333 2.75C5.33333 4.2625 6.53333 5.5 8 5.5ZM8 6.1875C5.26667 6.1875 2.66667 7.975 2.66667 9.625C2.66667 11 8 11 8 11C8 11 13.3333 11 13.3333 9.625C13.3333 7.975 10.7333 6.1875 8 6.1875ZM11.8 4.125H12C13.1333 4.125 14 3.23125 14 2.0625C14 0.89375 13.1333 0 12 0C11.6667 0 11.4 0.0687501 11.1333 0.20625C11.6667 0.89375 12 1.7875 12 2.75C12 3.23125 11.9333 3.7125 11.8 4.125ZM4 4.125H4.2C4.06667 3.7125 4 3.23125 4 2.75C4 1.7875 4.33333 0.89375 4.86667 0.20625C4.6 0.0687501 4.33333 0 4 0C2.86667 0 2 0.89375 2 2.0625C2 3.23125 2.86667 4.125 4 4.125Z" fill="#D5D5D7" />
  </SvgIcon>
);

export default BrokerClients;
