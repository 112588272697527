import { makeStyles } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { fontSize } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
  card: {
    '&:first-child': {
      marginTop: 56,
    },
    maxHeight: 104,
  },
  cardType: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  },
  cardHeader: {
    fontSize: '0.8rem',
    padding: '5px',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  },
  questionOrderIcon: {
    [theme.breakpoints.down('xs')]: {
      width: 18,
      height: 18,
    },
    '&:hover': {
      color: 'rgb(7, 89, 85)',
    },
    cursor: 'pointer',
    width: '32px',
    height: '32px',
  },

  typeSelect: {
    width: '100%',
  },
  dialogPaper: {
    maxHeight: 500,
    maxWidth: 500,
    borderRadius: 15,
  },
  dialogPaperOrderQuestions: {
    width: '100%',
    minWidth: '200px',
    borderRadius: 15,
    paddingTop: 20,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
      minWidth: '200px',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      minWidth: '200px',
      overflow: 'hidden',
    },
  },
  dialogPaperFullSizeImage: {
    maxWidth: '100%',
    minWidth: 'auto',
  },
  fullSizeImageInsideDialog: {
    maxWidth: '100%',
    height: 'auto',
  },
  marginTopAndBottom: {
    marginTop: 20,
    marginBottom: 15,
  },
  dialogTitlePadding: {
    padding: '24px 40px 16px',
  },
  dialogPadding40: {
    padding: 40,
  },
  valueItem: {
    color: theme.palette.secondary.dark,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      display: 'flex',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '9px',
      display: 'flex',
      justifyContent: 'center'
    },
    a: {
      textDecoration: 'none',
    },
    display: 'flex',
    justifyContent: 'center'
  },
  linkHref: {
    border:'none',
    background: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    paddingLeft: '2px',
    color: '#3c3cdb',
  },
  cardItems: {
    width: 'calc(100% - 120px)',
    alignSelf: 'center',
    justifyContent: 'space-evenly'
  },

  titleCreate: {
    textAlign: 'center',
  },
  values: {
    minHeight: 83,
    padding: '6px 0',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  valuesSell: {
    justifyContent: 'flex-end',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 520,
    borderRadius: 15,
    boxShadow: 'none',
    margin: '0 auto',
    padding: '24px',
    [theme.breakpoints.up('sm')]: {
      padding: '56px 64px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '56px auto 0',
    },
  },
  label: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.secondary.dark,
    marginBottom: 4,
  },
  form: {
    width: '100%',
    '& .MuiGrid-item:not(:last-child)': {
      marginBottom: 20,
    },
    '& .MuiGrid-item(:first-child)': {
      marginTop: 40,
    },
  },
  docName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  docList: {
    padding: 0,
  },
  iconMargin: {
    marginRight: 16,
  },
  docNameWidth: {
    width: '90%',
  },
  dialogTitle: {
    fontWeight: 'bold',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dialogContent: {
    padding: 0,
    wordWrap: 'break-word',
  },
  input: {
    '& .Mui-disabled': {
      color: theme.palette.text.secondary,
      '-webkit-text-fill-color': theme.palette.text.secondary,
    },
  },
  btnWidth: {
    minWidth: 'unset',
    padding: 'unset',
    height: '34px',
  },
  infoColorAttest: {
    color: theme.palette.primary.light,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
      height: '32px',
      wordBreak: 'break-word',
      width: '32px',
    },
  },
  infoColorNoAttest: {
    color: theme.palette.secondary.main,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
      height: '32px',
      wordBreak: 'break-word',
      width: '32px',
    },
  },
  badgeFullWidth: {
    width: '100%',
  },
  infoButton: {
    fontSize: 14,
    color: theme.palette.text.primary,
    '&.MuiButton-label': {
      display: 'block',
    },
  },
  closeModalIcon: {
    position: 'absolute',
    right: '5px',
    top: '7px',
    fontSize: 24,
  },
  labelBtn: {
    display: 'block',
    flexWrap: 'wrap',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },

  icon: {
    borderRadius: 5,
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.secondary.contrastText}`,
    'input:hover ~ &': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'" +
        "%3E%3Cpath d='M5.36433 8.81296C5.2867 8.88842 5.16314 8.88842 5.08551 8.81296L1.9181 5.73398C1.72404 5.54535 1.41513 5.54534" +
        ' 1.22107 5.73398L0.36882 6.56244C0.166838 6.75878 0.166838 7.08314 0.36882 7.27948L4.87641 11.6612C5.07046 11.8499 5.37938' +
        ' 11.8499 5.57343 11.6612L15.6312 1.88428C15.8332 1.68794 15.8332 1.36358 15.6312 1.16724L14.7789 0.338782C14.5849 0.150146 ' +
        "14.276 0.150146 14.0819 0.338782L5.36433 8.81296Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
    },
  },
  picture: {
    height: 50,
    width: 50,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.contrastText,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 40,
    },
  },
  paddingBottom40: {
    paddingBottom: 40,
  },
  modalButton: {
    height: 40,
    [theme.breakpoints.down('xs')]: {
      height: 30,
    },
    marginBottom: '20px',
  },
  longDescriptionHide: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  longDescriptionBtn: {
    padding: 0,
    textDecoration: 'underline',
    color: theme.palette.primary.light,
  },

  leaveMessage: {
    maxWidth: 200,
  },

  messageInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
    marginBottom: '20px',
  },

  MuiDialogActions: {
    root: {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },

  sendButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginLeft: '50px',
    },
  },

  MuiDialogContent: {
    root: {
      marginBottom: '20px',
    },
  },

  timeWrapper: {
    marginRight: '5px',
    width: '90px',
    '@media (max-width: 680px)': {
      marginRight: '20px',
    },
  },
  estWrapper: {
    marginRight: '5px',
    width: '60px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  calendarWrapper: {
    marginRight: '5px',
    width: '140px',
    '@media (max-width: 680px)': {
      marginTop: '10px',
    },
  },

  textMessagesWrapper: {
    fontSize: '16px',
    margin: '5px 0px 5px 10px',
    '@media (max-width: 680px)': {
      fontSize: '15px',
    },
  },

  InformMessage: {
    width: '100%',
    textAlign: 'center',
    color: '#db6161',
    '@media (max-width: 680px)': {
      padding: '8px',
      textAlign: 'justify',
    },
  },
  InformMeeting: {
    fontSize: '16px',
    fontWeight: 500,
    width: '100%',
    textAlign: 'start',
    marginLeft: '30px',
    marginTop: '30px',
    '@media (max-width: 680px)': {
      padding: '8px',
      marginLeft: '0px',
    },
  },
  
  InformMeetingNotificationRed: {
    fontSize: '16px',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center',
    // marginTop: '60px',
    color: 'red',
    '@media (max-width: 680px)': {
      padding: '8px',
    },
  },  
  InformMeetingNotificationBlack: {
    fontSize: '16px',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center',
    // marginTop: '60px',
    color: 'black',
    '@media (max-width: 680px)': {
      padding: '8px',
    },
  },

  textAlignCenter: {
    textAlign: 'center',
  },

  ModalQuestionWrapper: {
    width: '100%'
  },

  DateAndTimeWrapper: {
    width: '100%',
    display: 'flex',
    margin: '24px',
    '@media (max-width: 680px)': {
      flexWrap: 'wrap',
      padding: '8px',
      margin: '8px',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
    },
  },
  buyDialogWrapper: {
    padding: '16px',
    overflow: 'hidden',
  },

  closeButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },

  orderQuestionTitleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orderQuestionTitle: {
    fontSize: '18px',
    '@media (max-width: 680px)': {
      fontSize: '12px',
    },
  },
  orderNumber: {
    // position: 'absolute',
    // top: '5px',
    // right: '70px',
    fontSize: '21px',
    '@media (max-width: 680px)': {
      // top: '17px',
      // right: '57px',
      fontSize: '15px',
    },
  },

  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },

  linkForSignup: {
    marginLeft: '4px',
    marginRight: '4px',
  },
  cardActions: {
    padding: '8px 48px 24px',
  },
}));

export default useStyles;
