import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '..';
import { apiGetAllNotifications } from '../../api/notification';
import {
  NotificationMessage,
  NotificationMessageInterface,
} from '../../interfaces/notificationMessage.interface';
import { addNotification } from './snackbar.slice';

const initialState: NotificationMessageInterface = {
  notificationsMessage: [],
  unreadedMessagesCount: 0,
  loading: true,
};

const NotificationMessageState = createSlice({
  initialState,
  name: 'notificationMessage',
  reducers: {
    setNotificationsMessages(
      state,
      action: PayloadAction<NotificationMessage[]>
    ) {
      const data = action.payload;
      state.notificationsMessage = data;
      state.unreadedMessagesCount = data.filter(
        (item: any) => item.read === false
      ).length;
      state.loading = false;
    },
    addNotificationMessage(state, action: PayloadAction<NotificationMessage>) {
      const newMessage = action.payload;
      const { notificationsMessage } = state;
      state.notificationsMessage = [newMessage, ...notificationsMessage];
      if (newMessage.read === false) {
        state.unreadedMessagesCount += 1;
      }
    },
    readNotificationsMessage(state, action) {
      const { notificationsMessage } = state;
      const newMessages = notificationsMessage.map((item) => {
        if (item.id === action.payload.notificationId) {
          item.read = true;
        }
        return item;
      });
      state.notificationsMessage = newMessages;
      state.unreadedMessagesCount -= 1;
    },
    delNotificationMessage(state, action) {
      // state.notificationsMessage.filter(
      //   (notificationsMessages) => notificationsMessages.id !== action.payload.id,
      // );
    },
  },
});

export const {
  setNotificationsMessages,
  addNotificationMessage,
  delNotificationMessage,
  readNotificationsMessage,
} = NotificationMessageState.actions;

export const getNotifications =
  (
    accountId: number,
    token: {
      accessToken: string | null;
      tokenExp: string | null;
      refreshToken: string | null;
    }
  ): AppThunk => async (dispatch) => {
    try {
      const data = await dispatch(
        apiGetAllNotifications(accountId, token)
      ) as any;
      dispatch(setNotificationsMessages(data));
    } catch (error) {
      dispatch(
        addNotification({
          key: new Date().getTime() + Math.random(),
          message: error?.response
            ? error?.response?.data?.error
            : error?.message,
          options: { variant: 'error' },
        })
      );
    }
  };

export default NotificationMessageState.reducer;
