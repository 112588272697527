import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const PostTradeSteps = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 18">
    <path d="M0.436335 14.4577H5.23602V15.5647C5.23602 16.2106 4.98318 16.83 4.53312 17.2867C4.08307 17.7434 3.47266 18 2.83618 18C2.1997 18 1.58929 17.7434 1.13923 17.2867C0.689175 16.83 0.436335 16.2106 0.436335 15.5647V14.4577ZM3.92702 3.93706C5.67236 3.93706 6.54503 6.48752 6.54503 8.25867C6.54503 9.14424 6.10869 10.0298 5.67236 11.3582L5.23602 12.6865H0.436335C0.436335 11.801 0 10.4726 0 8.25867C0 6.04473 1.7436 3.93706 3.92702 3.93706ZM14.4462 11.0022L14.2568 12.0923C14.1463 12.7285 13.7913 13.294 13.2699 13.6645C12.7485 14.035 12.1034 14.1802 11.4765 14.068C10.8496 13.9559 10.2923 13.5956 9.9272 13.0665C9.56208 12.5374 9.41905 11.8827 9.52956 11.2466L9.7198 10.1573L14.4462 11.0022ZM12.8091 0.0272424C14.9593 0.411582 16.3154 2.79378 15.9367 4.97407C15.558 7.15524 14.9008 8.38619 14.7499 9.25848L10.0226 8.41276L9.82016 7.02772C9.6177 5.64268 9.34019 4.69334 9.49116 3.82193C9.79398 2.07735 11.0899 -0.280052 12.8091 0.0272424Z" />
  </SvgIcon>
);

export default PostTradeSteps;
