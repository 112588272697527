import React, { FC, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Typography,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from 'notistack';
import MessageIcon from '@material-ui/icons/Message';
import useStyles from './Dashboard.style';
import ContactsIcon from '../../svg/contacts';
import PrivacyPolicyIcon from '../../svg/privacyPolicy';
import TermsAndConditionsIcon from '../../svg/termsAndConditions';
import MarketPlaceIcon from '../../svg/marketPlace';
import ProfileIcon from '../../svg/profile';
import RequestsIcon from '../../svg/requests';
import MyRequestsIcon from '../../svg/myRequest';
import InventoryTypesIcon from '../../svg/inventoryTypes';
import NegotiationPointsIcon from '../../svg/negotiationPoints';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PostTradeStepsIcon from '../../svg/postTradeSteps';
import UserListIcon from '../../svg/userList';
import DealsIcon from '../../svg/deals';
import ContractsIcon from '../../svg/contracts';
import BrokerClientsIcon from '../../svg/brokerClients';
import CreateBuyOrderIcon from '../../svg/createBuyOrder';
import MyOrdersIcon from '../../svg/myOrders';
import Settlements from '../../svg/settlements';
import AdminOrders from '../../svg/adminOrders';
import { addNotification, delNotification } from '../../store/slices/snackbar.slice';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { RootState } from '../../store/slices';
import getLocalStorageData from '../../api/localStorage';
import { Socket } from '../../containers/MainContainer/MainContainer';


const CheckActive = (props: any) => {
  const location: any = useLocation();
  if (location.pathname === '/newOrder' && props.sell) {
    return location.state?.inventoryId;
  }
  if (location.pathname === '/newOrder' && !props.sell && location.state?.inventoryId) {
    return false;
  }
  if (props.path === '/deals' || props.path === '/deal-room') {
    const regexObject = new RegExp(`${props.path}`);
    const test = regexObject.test(location.pathname);
    return test;
  }
  return location.pathname === props.path;
};

const styleListItemText = (text: string) => <Typography variant="body2" style={{ fontSize: '12px', lineHeight: '14px' }}>{text}</Typography>;

export const CommonListItems: FC<{
  open: boolean;
}> = ({ open }) => {
  const classes = useStyles();
  return (
    <>
      <Link to="../contacts" className={classes.linkItem}>
        <ListItem button className={cx(classes.commonListItem, CheckActive({ path: '/contacts' }) && classes.listItemActive)}>
          <ListItemIcon className={classes.commonListMenuIcon}>
            <ContactsIcon fontSize="small" />
          </ListItemIcon>
          {open && <ListItemText className={classes.commonListItemText} primary={styleListItemText('Contacts')} />}
        </ListItem>
      </Link>
      <Link to="../privacyPolicy" className={classes.linkItem}>
        <ListItem button className={cx(classes.commonListItem, CheckActive({ path: '/privacyPolicy' }) && classes.listItemActive)}>
          <ListItemIcon className={classes.commonListMenuIcon}>
            <PrivacyPolicyIcon fontSize="small" />
          </ListItemIcon>
          {open && <ListItemText className={classes.commonListItemText} primary={styleListItemText('Privacy Policy')} />}
        </ListItem>
      </Link>
      <Link to="../termsOfService" className={classes.linkItem}>
        <ListItem button className={cx(classes.commonListItem, CheckActive({ path: '/termsOfService' }) && classes.listItemActive)}>
          <ListItemIcon className={classes.commonListMenuIcon}>
            <TermsAndConditionsIcon fontSize="small" />
          </ListItemIcon>
          {open && <ListItemText className={classes.commonListItemText} primary={styleListItemText('Terms of Service')} />}
        </ListItem>
      </Link>
      <p className={classes.versionStyle}>
        Version January 12, 2022
      </p>
    </>
  );
};

export const UnauthorizedListItems: FC<{
  open: boolean;
}> = ({ open }) => {
  const classes = useStyles();
  return (
    <Link to="/MarketPlace" className={classes.linkItem}>
      <ListItem button className={cx(classes.listItem, CheckActive({ path: '/MarketPlace' }) && classes.listItemActive)}>
        <ListItemIcon className={classes.menuIcon}>
          <MarketPlaceIcon />
        </ListItemIcon>
        {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Marketplace')} />}
      </ListItem>
    </Link>
  );
};

export const MainListItems: FC<{
  open: boolean;
  newMessagesAndAlerts: number;
  isUser: boolean;
}> = ({ open, newMessagesAndAlerts, isUser }) => {
  const classes = useStyles();
  return (
    <>
      <Link to="../" className={classes.linkItem}>
        <ListItem button className={cx(classes.listItem, CheckActive({ path: '/' }) && classes.listItemActive)}>
          <ListItemIcon className={classes.menuIcon}>
            <MarketPlaceIcon />
          </ListItemIcon>
          {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Marketplace')} />}
        </ListItem>
      </Link>
      <Link to="../profile" className={classes.linkItem}>
        <ListItem button className={cx(classes.listItem, CheckActive({ path: '/profile' }) && classes.listItemActive)}>
          <ListItemIcon className={classes.menuIcon}>
            <ProfileIcon />
          </ListItemIcon>
          {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Profile')} />}
        </ListItem>
      </Link>
      {isUser &&
        !newMessagesAndAlerts && <Link to="../messagesAndAlerts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/messagesAndAlerts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newMessagesAndAlerts} color="error">
                <MessageIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Messages and Alerts')} />}
          </ListItem>
        </Link>
      }
      {isUser &&
        newMessagesAndAlerts && <Link to="../messagesAndAlerts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/messagesAndAlerts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newMessagesAndAlerts} color="error">
                <MessageIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Messages and Alerts')} />}
          </ListItem>
        </Link>
      }
    </>
  );
};

export const SellerListItems: FC<{
  newDeals: number;
  newSettlements: number;
  newContracts: number;
  open: boolean;
  newMessagesAndAlerts: number;
  notificationNumber: number;
}> =
  ({
    newDeals,
    newSettlements,
    newContracts,
    open,
    newMessagesAndAlerts,
    notificationNumber,
  }) => {
    const classes = useStyles();
    const location: any = useLocation();

    const dispatch = useDispatch();
    const history = useHistory();
    const { closeSnackbar } = useSnackbar();
    const handleClose = () => closeSnackbar();

    const {
      userRole,
    } = useSelector(
      (state: RootState) => state.auth,
    );

    const notificationNumberState = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((notif: any) => notif.read === false).length
    );
    const notificationNumberNewDeal = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((item: any) => (item.deal !== null) && (item.read === false)).length
    );

    return (
      <>
        <MainListItems open={open} newMessagesAndAlerts={newMessagesAndAlerts} isUser={false} />
        <Link to="../myInventory" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItem, CheckActive({ path: location.pathname === '/myInventory' ? '/myInventory' : '/editInventory' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <InventoryTypesIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Inventory')} />}
          </ListItem>
        </Link>
        <Link to="../orders" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: location.pathname === '/orders' ? '/orders' : '/editOrder' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <MyOrdersIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Orders')} />}
          </ListItem>
        </Link>

        <Link to="../deals" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItem, CheckActive({ path: location.pathname === '/deals' ? '/deals' : '/deal-room' }) && classes.listItemActive)}
          >
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newDeals} color="error">
                <DealsIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Deals')} />}
          </ListItem>
        </Link>

        {/* {newDeals && <Link to="../deals" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItemAllerNewMessage, CheckActive({ path: location.pathname === '/deals' ? '/deals' : '/deal-room' }) && classes.listItemActive)}
          >
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newDeals} color="error">
                <DealsIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Deals')} />}
          </ListItem>
        </Link>} */}

        <Link to="../contracts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/contracts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newContracts} color="error">
                <PeopleOutlineIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Contracts')} />}
          </ListItem>
        </Link>
        {/* {newContracts && <Link to="../contracts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/contracts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newContracts} color="error">
                <PeopleOutlineIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Contracts')} />}
          </ListItem>
        </Link>} */}
        <Link to="../settlements" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/settlements' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newSettlements} color="error">
                <Settlements />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Settlements')} />}
          </ListItem>
        </Link>
        {/* {newSettlements && <Link to="../settlements" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/settlements' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newSettlements} color="error">
                <Settlements />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Settlements')} />}
          </ListItem>
        </Link>} */}
        {!newMessagesAndAlerts && <Link to="../messagesAndAlerts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/messagesAndAlerts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newMessagesAndAlerts} color="error">
                <MessageIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Messages and Alerts')} />}
          </ListItem>
        </Link>}
        {newMessagesAndAlerts && <Link to="../messagesAndAlerts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/messagesAndAlerts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newMessagesAndAlerts} color="error">
                <MessageIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Messages and Alerts')} />}
          </ListItem>
        </Link>}

        {!notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <NotificationsNoneIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}

        {notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={notificationNumberState} color="error">
                <NotificationsNoneIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}
      </>
    );
  };

export const BuyerListItems: FC<{
  newDeals: number;
  newSettlements: number;
  newContracts: number;
  open: boolean;
  newMessagesAndAlerts: number;
  notificationNumber: number;
}> =
  ({
    newDeals,
    newSettlements,
    newContracts,
    open,
    newMessagesAndAlerts,
    notificationNumber,
  }) => {
    const classes = useStyles();
    const location: any = useLocation();

    const notificationNumberState = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((notif: any) => notif.read === false).length
    );
    const notificationNumberNewDeal = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((item: any) => (item.deal !== null) && (item.read === false)).length
    );

    return (
      <>
        <MainListItems open={open} newMessagesAndAlerts={newMessagesAndAlerts} isUser={false} />
        <Link to="../newOrder" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/newOrder' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <CreateBuyOrderIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Create Order')} />}
          </ListItem>
        </Link>
        <Link to="../orders" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItem, CheckActive({ path: location.pathname === '/orders' ? '/orders' : '/editOrder' }) && classes.listItemActive)}
          >
            <ListItemIcon className={classes.menuIcon}>
              <MyOrdersIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Orders')} />}
          </ListItem>
        </Link>
        <Link to="../deals" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItem, CheckActive({ path: location.pathname === '/deals' ? '/deals' : '/deal-room' }) && classes.listItemActive)}
          >
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newDeals} color="error">
                <DealsIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Deals')} />}
          </ListItem>
        </Link>
        {/* {notificationNumberNewDeal && <Link to="../deals" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItemAllerNewMessage, CheckActive({ path: location.pathname === '/deals' ? '/deals' : '/deal-room' }) && classes.listItemActive)}
          >
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={notificationNumberNewDeal} color="error">
                <DealsIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Deals')} />}
          </ListItem>
        </Link>} */}
        <Link to="../contracts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/contracts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newContracts} color="error">
                <PeopleOutlineIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Contracts')} />}
          </ListItem>
        </Link>
        {/* {newContracts && <Link to="../contracts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/contracts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newContracts} color="error">
                <PeopleOutlineIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Contracts')} />}
          </ListItem>
        </Link>} */}
        <Link to="../settlements" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/settlements' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newSettlements} color="error">
                <Settlements />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Settlements')} />}
          </ListItem>
        </Link>
        {/* {newSettlements && <Link to="../settlements" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/settlements' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newSettlements} color="error">
                <Settlements />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Settlements')} />}
          </ListItem>
        </Link>} */}
        {!newMessagesAndAlerts && <Link to="../messagesAndAlerts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/messagesAndAlerts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newMessagesAndAlerts} color="error">
                <MessageIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Messages and Alerts')} />}
          </ListItem>
        </Link>}
        {newMessagesAndAlerts && <Link to="../messagesAndAlerts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/messagesAndAlerts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newMessagesAndAlerts} color="error">
                <MessageIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Messages and Alerts')} />}
          </ListItem>
        </Link>}

        {!notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <NotificationsNoneIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}

        {notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={notificationNumberState} color="error">
                <NotificationsNoneIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}

      </>
    );
  };

export const SellerBuyerListItems: FC<{
  newDeals: number;
  newSettlements: number;
  newContracts: number;
  open: boolean;
  newMessagesAndAlerts: number;
  notificationNumber: number;
}> =
  ({
    newDeals,
    newSettlements,
    newContracts,
    open,
    newMessagesAndAlerts,
    notificationNumber,
  }) => {
    const classes = useStyles();
    const location: any = useLocation();

    const notificationNumberState = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((notif: any) => notif.read === false).length
    );
    const notificationNumberNewDeal = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((item: any) => (item.deal !== null) && (item.read === false)).length
    );

    return (
      <>
        <MainListItems open={open} newMessagesAndAlerts={newMessagesAndAlerts} isUser={false} />
        <Link to="../myInventory" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItem, CheckActive({ path: location.pathname === '/myInventory' ? '/myInventory' : '/editInventory' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <InventoryTypesIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Inventory')} />}
          </ListItem>
        </Link>
        <Accordion
          classes={{
            root: classes.createOrder,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.createOrderIcon} />}
            classes={{
              content: classes.createOrderSummaryContent,
              root: classes.createOrderSummaryRoot,
              expandIcon: classes.createOrderExpandIcon,
            }}
          >
            <ListItem button className={cx(classes.createOrderListItem)}>
              <ListItemIcon className={classes.menuIcon}>
                <CreateBuyOrderIcon />
              </ListItemIcon>
              {open && <ListItemText primary={styleListItemText('Create Order')} className={classes.listItemText} />}
            </ListItem>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.createOrderDetails,
            }}
          >
            <List>
              <Link to="../newOrder" className={cx(CheckActive({ path: '/newOrder' }) ? classes.createOrderListItemActive : classes.createOrderListItemText)}>
                <ListItem>
                  <ListItemText primary={styleListItemText(`Buy ${open ? 'Order' : ''}`)} />
                </ListItem>
              </Link>
              <Link to="../myInventory" className={cx(CheckActive({ path: '/newOrder', sell: true }) ? classes.createOrderListItemActive : classes.createOrderListItemText)}>
                <ListItem>
                  <ListItemText primary={styleListItemText(`Sell ${open ? 'Order' : ''}`)} />
                </ListItem>
              </Link>
            </List>
          </AccordionDetails>
        </Accordion>
        <Link to="../orders" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/orders' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <MyOrdersIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Orders')} />}
          </ListItem>
        </Link>
        <Link to="../deals" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/deals' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newDeals} color="error">
                <DealsIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Deals')} />}
          </ListItem>
        </Link>
        {/* {notificationNumberNewDeal && <Link to="../deals" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/deals' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={notificationNumberNewDeal} color="error">
                <DealsIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Deals')} />}
          </ListItem>
        </Link>} */}
        <Link to="../contracts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/contracts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newContracts} color="error">
                <PeopleOutlineIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Contracts')} />}
          </ListItem>
        </Link>
        {/* {newContracts && <Link to="../contracts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/contracts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newContracts} color="error">
                <PeopleOutlineIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Contracts')} />}
          </ListItem>
        </Link>} */}
        <Link to="../settlements" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/settlements' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newSettlements} color="error">
                <Settlements />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Settlements')} />}
          </ListItem>
        </Link>
        {/* {newSettlements && <Link to="../settlements" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/settlements' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newSettlements} color="error">
                <Settlements />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Settlements')} />}
          </ListItem>
        </Link>} */}
        {!newMessagesAndAlerts && <Link to="../messagesAndAlerts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/messagesAndAlerts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newMessagesAndAlerts} color="error">
                <MessageIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Messages and Alerts')} />}
          </ListItem>
        </Link>}
        {newMessagesAndAlerts && <Link to="../messagesAndAlerts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/messagesAndAlerts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newMessagesAndAlerts} color="error">
                <MessageIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Messages and Alerts')} />}
          </ListItem>
        </Link>}

        {!notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <NotificationsNoneIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}

        {notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={notificationNumberState} color="error">
                <NotificationsNoneIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}

      </>
    );
  };

export const BrokerListItems: FC<{
  userRole: string;
  newDeals: number;
  newSettlements: number;
  newContracts: number;
  open: boolean;
  newMessagesAndAlerts: number;
  notificationNumber: number;
}> =
  ({
    userRole, newDeals, newSettlements, newContracts, open, newMessagesAndAlerts, notificationNumber,
  }) => {
    const classes = useStyles();
    const location: any = useLocation();

    const notificationNumberState = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((notif: any) => notif.read === false).length
    );
    const notificationNumberNewDeal = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((item: any) => (item.deal !== null) && (item.read === false)).length
    );

    return (
      <>
        {userRole === 'Guest' && <MainListItems open={open} newMessagesAndAlerts={newMessagesAndAlerts} isUser={userRole === 'Guest'} />}
        {userRole === 'Buyer' && (
          <BuyerListItems
            newDeals={notificationNumberNewDeal}
            newSettlements={newSettlements}
            newContracts={newContracts}
            open={open}
            newMessagesAndAlerts={newMessagesAndAlerts}
            notificationNumber={notificationNumber}
          />
        )}
        {userRole === 'Seller' && (
          <SellerListItems
            newDeals={notificationNumberNewDeal}
            newSettlements={newSettlements}
            newContracts={newContracts}
            open={open}
            newMessagesAndAlerts={newMessagesAndAlerts}
            notificationNumber={notificationNumber}
          />
        )}
        {userRole === 'Seller & Buyer' && (
          <SellerBuyerListItems
            newDeals={notificationNumberNewDeal}
            newSettlements={newSettlements}
            newContracts={newContracts}
            open={open}
            newMessagesAndAlerts={newMessagesAndAlerts}
            notificationNumber={notificationNumber}
          />
        )}
        {userRole === 'Broker' && <MainListItems open={open} newMessagesAndAlerts={newMessagesAndAlerts} isUser={false} />}
        <Link to="../clients" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/clients' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <BrokerClientsIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Clients')} />}
          </ListItem>
        </Link>

        {!notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <NotificationsNoneIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}

        {notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={notificationNumberState} color="error">
                <NotificationsNoneIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}

      </>
    );
  };

export const AdminListItems: FC<{
  newNotyfication: number;
  adminNotification: number;
  open: boolean;
  newMessagesAndAlerts: number;
  notificationNumber: number;
}> =
  ({
    newNotyfication, adminNotification, open, newMessagesAndAlerts, notificationNumber,
  }) => {
    const classes = useStyles();
    const location: any = useLocation();

    const notificationNumberState = useSelector(
      (state: RootState) => state.notificationMess.notificationsMessage.filter((notif: any) => notif.read === false).length
    );

    return (
      <>
        <MainListItems open={open} newMessagesAndAlerts={newMessagesAndAlerts} isUser={false} />
        <Link to="../requests" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/requests' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={newNotyfication} color="error">
                <RequestsIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Requests')} />}
          </ListItem>
        </Link>
        <Link to="../myRequests" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/myRequests' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={adminNotification} color="primary">
                <MyRequestsIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('My Requests')} />}
          </ListItem>
        </Link>
        <Link to="../inventoryTypes" className={classes.linkItem}>
          <ListItem
            button
            className={
              cx(classes.listItem, CheckActive({ path: location.pathname === '/inventoryTypes' ? '/inventoryTypes' : '/editInventoryType' }) && classes.listItemActive)
            }>
            <ListItemIcon className={classes.menuIcon}>
              <InventoryTypesIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Inventory Types')} />}
          </ListItem>
        </Link>
        <Link to="../negotiationPoints" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItem, CheckActive({ path: location.pathname === '/negotiationPoints' ? '/negotiationPoints' : '/editNegotiationPoints' }) && classes.listItemActive)
            }>
            <ListItemIcon className={classes.menuIcon}>
              <NegotiationPointsIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Negotiation Points')} />}
          </ListItem>
        </Link>
        <Link to="../postTradeSteps" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItem, CheckActive({ path: location.pathname === '/postTradeSteps' ? '/postTradeSteps' : '/editPostTradeSteps' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <PostTradeStepsIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Post Trade Steps')} />}
          </ListItem>
        </Link>
        <Link to="../userList" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/userList' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <UserListIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('User List')} />}
          </ListItem>
        </Link>
        <Link to="../inventory" className={classes.linkItem}>
          <ListItem
            button
            className={cx(classes.listItem, CheckActive({ path: location.pathname === '/inventory' ? '/inventory' : '/itemOfInventory' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <InventoryTypesIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Inventory')} />}
          </ListItem>
        </Link>
        <Link to="../orders" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/orders' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <AdminOrders />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Orders')} />}
          </ListItem>
        </Link>
        <Link to="../deals" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/deals' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <DealsIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Deals')} />}
          </ListItem>
        </Link>
        <Link to="../contracts" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/contracts' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <PeopleOutlineIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Contracts')} />}
          </ListItem>
        </Link>
        <Link to="../settlements" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/settlements' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Settlements />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Settlements')} />}
          </ListItem>
        </Link>
        {!notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItem, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <NotificationsNoneIcon />
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}

        {notificationNumberState && <Link to="/notification" className={classes.linkItem}>
          <ListItem button className={cx(classes.listItemAllerNewMessage, CheckActive({ path: '/notification' }) && classes.listItemActive)}>
            <ListItemIcon className={classes.menuIcon}>
              <Badge badgeContent={notificationNumberState} color="error">
                <NotificationsNoneIcon />
              </Badge>
            </ListItemIcon>
            {open && <ListItemText className={classes.listItemText} primary={styleListItemText('Notification')} />}
          </ListItem>
        </Link>}
      </>
    );
  };
