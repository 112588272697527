import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import cx from 'classnames';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Hidden,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './DealRoom.style';
import { PostTradeStepForDeal } from '../../interfaces/deals.interface';
import getLocalStorageData from '../../api/localStorage';
import { getDate, getDateUTC12H } from '../../utils/getDate';
import ButtonSC from '../ButtonSC/ButtonSC';

interface Props {
  postTradeSteps: PostTradeStepForDeal[];
  sellerId: number;
  buyerId: number;
  saveValue: (newValue: { date: string; responsible: string }, negotiationPointId: number) => void;
  setAgree: (
    agreedValue: { date: string; responsible: string },
    negotiationPointId: number
  ) => void;
  status: string;
  agree: boolean;
  expanded: string | false;
  setExpanded: (value: string | false) => void;
  proceedError: boolean;
  userRole: 'Buyer' | 'Seller' | 'Unconfirmed' | 'Admin' | 'Broker' | 'Seller & Buyer' | 'Guest';
  nextStep: false | { type: string; id: number; agreed: boolean | 'byOne' };
}

const PostTradeSteps: FC<Props> = ({
  postTradeSteps,
  sellerId,
  buyerId,
  saveValue,
  setAgree,
  status,
  agree,
  expanded,
  setExpanded,
  proceedError,
  userRole,
  nextStep,
}) => {
  const classes = useStyles();
  const [date, setDate] = useState<Date | string | null>(null);
  const [role, setRole] = useState<string>('seller');

  useEffect(() => {
    setExpanded(false);
  }, [setExpanded, status, agree]);

  const handleGetAccountId = () => {
    const { clientAccountId, accountId } = getLocalStorageData();
    return clientAccountId || accountId || 0;
  };

  const handleGetStringResponsible = (item: PostTradeStepForDeal) => {
    let respValue = '';
    if (sellerId !== handleGetAccountId()) {
      respValue = item.responsibleValueSeller as string;
    } else {
      respValue = item.responsibleValueBuyer as string;
    }
    return respValue || item.responsible;
  };

  const handleChange = (item: PostTradeStepForDeal) => (
    event: ChangeEvent<{}>, newExpanded: boolean,
  ) => {
    setExpanded(newExpanded ? item.description : false);
    if (newExpanded) {
      setDate(sellerId === handleGetAccountId() ? item.valueSeller : item.valueBuyer);
      setRole(
        ((sellerId === handleGetAccountId() ?
          item.responsibleValueSeller : item.responsibleValueBuyer) ||
          item.responsible) as string,
      );
    }
  };

  const handleSetAgree = useCallback(
    async (item: PostTradeStepForDeal) => {
      // if (sellerId === handleGetAccountId() ?
      //   item.valueBuyer === null : item.valueSeller === null
      // ) {
      //   return dispatch(addNotification({
      //     key: new Date().getTime() + Math.random(),
      //     message: 'Value required',
      //     options: { variant: 'error' },
      //   }));
      // }
      await setAgree(
        sellerId === handleGetAccountId() ?
          { date: item.valueBuyer, responsible: item.responsibleValueBuyer as string } :
          { date: item.valueSeller, responsible: item.responsibleValueSeller as string },
        item.id,
      );
      return setExpanded(false);
    },
    [setAgree, sellerId, setExpanded],
  );

  const renderPTSFields = (currentRole: string, item: PostTradeStepForDeal) => (
    (currentRole === 'valueBuyer' ? buyerId === handleGetAccountId() : sellerId === handleGetAccountId()) ? (
      <>
        <Grid item xs={8} lg={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              minDate={new Date()}
              variant="inline"
              format="MM/dd/yyyy"
              label={`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry`}
              emptyLabel=""
              value={date}
              onChange={(selectDate) => setDate(getDateUTC12H(selectDate))}
              autoOk
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <InputLabel>Role*</InputLabel>
            <Select
              labelId="Role"
              value={role}
              onChange={({ target }) => setRole(target.value as string)}
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="seller">Seller</MenuItem>
              <MenuItem value="buyer">Buyer</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={8} lg={6} className={classes.npButtonContainer}>
          {date && role && (
            <ButtonSC
              variant="fill"
              size="large"
              text={(item[currentRole] === new Date(new Date(date)
                .setHours(12, 0, 0, 0)).toISOString() ||
                new Date(date).setHours(12, 0, 0, 0) <
                new Date().setHours(12, 0, 0, 0)) &&
                (currentRole === 'valueBuyer' ? role === item.responsibleValueBuyer : role === item.responsibleValueSeller
                ) ? 'Saved' : 'Save'}
              handleClick={() => {
                saveValue({ date: date.toString(), responsible: role }, item.id);
              }}
              color="green"
              styleClass={classes.npButton}
              disabled={
                ((item[currentRole] as string) === new Date(new Date(date).setHours(12, 0, 0, 0))
                  .toISOString() ||
                  new Date(date).setHours(12, 0, 0, 0) <
                  new Date().setHours(12, 0, 0, 0)) && (
                  currentRole === 'valueBuyer' ? role === item.responsibleValueBuyer : role === item.responsibleValueSeller
                )
              }
            />
          )}
        </Grid>
      </>
    ) :
      (
        <>
          <Grid container item xs={8} lg={6} style={{ marginTop: 16 }}>
            <Grid item xs={12}>
              <span className={classes.heading}>
                {`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry`}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.heading}> Date: </span>
              <span className={classes.secondaryHeading}>
                {`${item[currentRole] ? getDate(item[currentRole] as string) : '-'}`}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.heading}> Role: </span>
              <span className={classes.secondaryHeading}>
                {`${(handleGetStringResponsible(item) as string).charAt(0).toUpperCase() + (handleGetStringResponsible(item) as string).substring(1) || '-'}`}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={8} lg={6} className={classes.npButtonContainer}>
            {!item.agreed && (
              <>
                <Hidden mdDown>
                  <Tooltip title={`Accept ${sellerId !== handleGetAccountId() ? 'Seller' : 'Buyer'} Offer`}>
                    <span>
                      <ButtonSC
                        variant="outline"
                        size="large"
                        text="Accept"
                        handleClick={() => {
                          setDate(sellerId !== handleGetAccountId() ?
                            item.valueBuyer : item.valueSeller);
                          setRole(
                            ((sellerId !== handleGetAccountId() ?
                              item.responsibleValueSeller : item.responsibleValueBuyer) ||
                              item.responsible) as string,
                          );
                          handleSetAgree(item);
                        }}
                        color="green"
                        styleClass={classes.npButton}
                      />
                    </span>
                  </Tooltip>
                </Hidden>
                <Hidden lgUp>
                  <ButtonSC
                    variant="outline"
                    size="large"
                    text={`Accept ${sellerId !== handleGetAccountId() ? 'Seller' : 'Buyer'} Offer`}
                    handleClick={() => {
                      setDate(sellerId !== handleGetAccountId() ?
                        item.valueBuyer : item.valueSeller);
                      setRole(
                        ((sellerId !== handleGetAccountId() ?
                          item.responsibleValueSeller : item.responsibleValueBuyer) ||
                          item.responsible) as string,
                      );
                      handleSetAgree(item);
                    }}
                    color="green"
                    styleClass={classes.npButton}
                  />
                </Hidden>
              </>
            )}
          </Grid>
        </>
      )
  );

  const handleGetAccordionSummaryStyle = useCallback(
    (item) => {
      if (item.agreed) return classes.AccordionSummaryAgreed;
      if (nextStep && nextStep.type === 'PTS' && nextStep.id === item.id) return classes.AccordionSummaryNextStep;
      if (proceedError && !(item.agreedBySeller || item.agreedByBuyer)) return classes.AccordionSummaryError;
      if ((item.agreedBySeller && userRole === 'Seller' && !item.agreedByBuyer) || (item.agreedByBuyer && userRole === 'Buyer' && !item.agreedBySeller)) return classes.AccordionSummaryAgreedByOne;
      if ((!item.agreedBySeller && userRole === 'Seller' && item.agreedByBuyer) || (!item.agreedByBuyer && userRole === 'Buyer' && item.agreedBySeller)) return classes.AccordionSummaryWarning;
      return '';
    },
    [classes, proceedError, userRole, nextStep],
  );

  return (
    <div className={classes.root}>
      {postTradeSteps.map((item) => (
        <Accordion
          key={item.description}
          expanded={expanded === item.description}
          onChange={handleChange(item)}
          disabled={status !== 'IN PROGRESS' || agree}
          className={classes.Accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            className={cx(
              classes.AccordionSummary,
              handleGetAccordionSummaryStyle(item),
            )}
          // className={cx(classes.AccordionSummary, (getDate(item.valueBuyer) !== getDate(item.valueSeller) || item.responsibleValueBuyer !== item.responsibleValueSeller) && classes.AccordionSummaryWarning, item.agreed ? classes.AccordionSummaryAgreed : (proceedError && classes.AccordionSummaryError) || '')}
          >
            <>
              <Grid item xs={5} lg={4}>
                <Typography
                  className={item.agreed ?
                    classes.AccordionSummaryTitleAgreed :
                    classes.AccordionSummaryTitle}
                >
                  {item.description}
                </Typography>
              </Grid>
              <Grid item xs={5} lg={4} style={{ marginLeft: 12 }}>
                <Typography className={classes.AccordionSummaryTitleAgreed}>
                  {`${item.valueBuyer ? getDate(item.valueBuyer) : '-'}  / ${item.valueSeller ? getDate(item.valueSeller) : '-'}`}
                </Typography>
              </Grid>
            </>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            <Grid container justifyContent="space-between">
              {/* Buyer */}
              <Grid container item xs={12} sm={6} lg={4} alignContent="space-between">
                {renderPTSFields('valueBuyer', item)}
              </Grid>
              {/* Seller */}
              <Grid container item xs={12} sm={6} lg={4} alignContent="space-between">
                {renderPTSFields('valueSeller', item)}
              </Grid>
              {/* Seller */}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default PostTradeSteps;
