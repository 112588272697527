import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '120px auto',
    padding: '40px 24px',
    width: 'calc(100% - 32px)',
    [theme.breakpoints.up('sm')]: {
      margin: '112px auto',
      padding: '56px 64px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 520,
    borderRadius: 15,
    boxShadow: 'none',
  },
  title: {
    marginBottom: 40,
  },
  form: {
    width: '100%',
    '& .MuiGrid-item:not(:last-child)': {
      marginBottom: 32,
    },
    '&:first-child': {
      marginTop: 40,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '16px',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
    },
  },
  container: {
    height: '100%',
    overflow: 'auto',
  },
}));

export default useStyles;
