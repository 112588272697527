import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  useParams, Link,
} from 'react-router-dom';
import cx from 'classnames';
import { Paper, Typography } from '@material-ui/core';
import useStyles from './style';
import { activateAccount } from '../../store/slices/auth.slice';

export const ConfirmPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token }: any = useParams();
  if (token) {
    const key = new Date().getTime() + Math.random();
    dispatch(activateAccount(token, key));
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h1">
          Account activation
        </Typography>
        <Typography variant="h1" className={classes.text}>
          Thanks! Your account has been successfully activated!
        </Typography>
        <Typography variant="h1" className={classes.text}>
          You can
          <Link to="/signin" className={cx(classes.link, classes.linkLarge)}>
            {' login '}
          </Link>
          now!
        </Typography>
      </Paper>
    </div>
  );
};

export default ConfirmPage;
