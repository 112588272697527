import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import useStyles from './Inventory.style';
import { Inventory } from '../../interfaces/inventory.interface';
import { createDealFromSellerStage2 } from '../../store/slices/deals.slice';
import getLocalStorageData from '../../api/localStorage';
import { RootState } from '../../store/slices';
import ButtonSC from '../ButtonSC/ButtonSC';

interface Props {
  inventory: Inventory;
  needQuantity: number;
  chosenOrderId: number;
}

const InventoryCardForDeal: FC<Props> = ({
  inventory,
  needQuantity,
  chosenOrderId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isLoading,
  } = useSelector(
    (state: RootState) => state.deals,
  );

  const handleCheckForDeal = async (
    orderId: number,
    inventoryId: number,
    quantity: number,
  ) => {
    const { clientAccountId, accountId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    await dispatch(createDealFromSellerStage2(
      orderId,
      inventoryId,
      quantity,
      clientAccountId ? accountId : null,
      clientAccountId || accountId,
      token,
      key,
    ));
    history.push('deals');
  };

  return (
    <Grid container className={classes.card} direction="column">
      <Grid item className={classes.cardHeader}>
        {inventory.inventoryItemType.type}
      </Grid>
      <Grid item className={classes.cardContent}>
        <Typography variant="subtitle1" align="center">
          {inventory.isForwardInventory ? "Amount Available " : "Remaining amount " }
          {(inventory.quantityRemained).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item className={classes.cardActions}>
        <ButtonSC
          variant="fill"
          text= {inventory.isForwardInventory ? "Select This Inventory" : "Select This Item To Sell"}
          size="large"
          color="green"
          handleClick={() => { handleCheckForDeal(chosenOrderId, inventory.id, needQuantity); }}
          disabled={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default InventoryCardForDeal;
