import React, { FC } from 'react';
import { Hidden } from '@material-ui/core';
import Header from '../../components/Header/Header';
import useStyles from './style';

const ContentContainer: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Hidden xsDown>
        <Header />
      </Hidden>
      <div className={classes.container}>{children}</div>
    </>
  );
};

export default ContentContainer;
