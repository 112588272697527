/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import InfoPageContainer from '../InfoPageContainer/InfoPageContainer';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import useStyles from './style';

const PrivacyPolicyPage: FC = () => {
  const classes = useStyles();
  return (
    <>
      <PaperContainer>
        <InfoPageContainer>
          {/* <h1 className={classes.privacyPolicyHeader}>Privacy Policy</h1> */}
          <p className={classes.privacyPolicySubHeader}>
            MGTrading LLC  Privacy Policy
          </p>
          <p className={classes.privacyPolicyText}>MGTrading LLC (MGT) is committed to maintaining the confidentiality, integrity and security of personal information about our current and prospective customers. We understand that privacy is an important issue for you, and we also want you to understand how we protect your privacy when we collect personal information about you.</p>
          <p className={classes.privacyPolicySubHeader}>Information We Collect</p>
          <p className={classes.privacyPolicyText}>The personal information we collect from you comes from information you supply to us in user applications and other communications (whether written or electronic), or in other forms you may provide to us. This information may include your name, address, social security number or tax identification number, account numbers, account balances and financial information about you.</p>
          <p className={classes.privacyPolicySubHeader}>Information We May Share</p>
          <p className={classes.privacyPolicyText}>MGT may share personal information about our current and former customers with our affiliated companies and service providers around the world. We may share the information for everyday business purposes, including to process your transactions, to assist us in providing services, or for marketing purposes to offer products and services that may be of interest to you.</p>
          <p className={classes.privacyPolicyText}>We may also share personal with non-affiliated companies that are required to process your transactions such as banks or to provide other services. We do not sell your information or provide it to non-affiliated parties for marketing purposes.</p>
          <p className={classes.privacyPolicyText}>We may also disclose information about current or former customers in order to cooperate with legal or regulatory authorities or pursuant to a court order or subpoena.  In addition, we may disclose personal information as necessary to perform credit checks, collect debts, enforce our legal rights or otherwise protect our interests and property.</p>
          <p className={classes.privacyPolicySubHeader}>How We Protect Your Information</p>
          <p className={classes.privacyPolicyText}>It is our policy not to release your personal information except as permitted by law, with your consent, as requested by you or set forth below. Within MGT, we restrict access to your personal information to those who require it to provide products or services to you.</p>
          <p className={classes.privacyPolicyText}>To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings.</p>
          <p className={classes.privacyPolicySubHeader}>Opt Out Notice</p>
          <p className={classes.privacyPolicyText}>If you prefer that we do not disclose your nonpublic personal information to unaffiliated third parties, you may opt out of those disclosures; that is, you may direct us not to make those disclosures (other than disclosures permitted or required by law). If you wish to opt out of disclosures to non-affiliated third parties, you may email a request to compliance@MGHoldings.io stating:  “I do not wish to share personal information with non-affiliated third parties.” Please include your account number/ name, the name as it appears on your “statement”/ mobile app and, if applicable, an email address.</p>
          <p className={classes.privacyPolicyText}>
            If our privacy policy changes, you will be notified through the MGT web site (
            <a href="http://www.mgtrading.io/" target="_blank">www.mgtrading.io</a>
            {' '}
            and/ or
            {' '}
            <a href="http://www.mgtrader.io/" target="_blank">www.mgtrader.io</a>
            ) or by other appropriate means. If you have questions relating to this policy, please contact us directly.
          </p>

        </InfoPageContainer>
      </PaperContainer>
    </>
  );
};

export default PrivacyPolicyPage;
