/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import InfoPageContainer from '../InfoPageContainer/InfoPageContainer';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import useStyles from './ContactsPage.style';

const ContactsPage: FC = () => {
  const classes = useStyles();
  return (
    <>
      <PaperContainer>
        <InfoPageContainer>
          <p className={classes.contactsHeader}>MG Trading LLC Contact Information:</p>
          <p className={classes.contactsText}>
            Address:
            {' '}
            <a target="_blank" href="https://goo.gl/maps/pL1XXzvbpMKagADy7">1795 Riverside Dr. Ste #3-A, New York, NY 10034 USA</a>
          </p>
          <p className={classes.contactsText}>
            Phone Numbers:
            {' '}
            <a href="tel:(402) 218-4666">(402) 218-4666</a>
            ,
            {' '}
            <a href="tel:(855) 978-6781">(855) 978-6781</a>
          </p>
          <p className={classes.contactsText}>
            Email:
            {' '}
            <a href="mailto: info@mgtrader.io">info@mgtrader.io</a>
          </p>
        </InfoPageContainer>
      </PaperContainer>
    </>
  );
};

export default ContactsPage;
