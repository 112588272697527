import { useTranslation } from 'react-i18next';
import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select,
  MenuItem,
  Paper,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Link,
  IconButton,
  Divider,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import cx from 'classnames';
// import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import DateFnsUtils from '@date-io/date-fns';
import { useForm, Controller } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';
import { getUserProfile, updateUserProfile } from '../../store/slices/userUpdate.slice';
import getLocalStorageData from '../../api/localStorage';
import { RootState } from '../../store/slices';
import { getRole, getBrokerRole } from '../../utils/getRole';
import useStyles from './ChangeRolePage.style';
import ButtonSC from '../ButtonSC/ButtonSC';
import { addNotification } from '../../store/slices/snackbar.slice';
import { UserRole } from '../../interfaces/user.interface';
import FileUploader from '../FileUploader/FileUploader';
import DocUpload from '../../svg/docUpload';
import constants from '../../core/constants';
import LoadingPage from '../LoadingPage/LoadingPage';
import { NumberFormatZip, NumberFormatSSN, NumberFormatEIN } from '../NumberFormat/NumberFormat';
import { getDateUTC12H } from '../../utils/getDate';
import TextFieldProfile from '../TextFieldProfile/TextFieldProfile';
import StateAutocomplete from '../StateAutocomplete/StateAutocomplete';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import translationEN from '../../locales/en/translation.json';

const ChangeRole: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
  } = useForm({ shouldFocusError: true });

  const [userDocuments, setUserDocuments] = useState<any>();

  const { t, i18n } = useTranslation();

  const [isBuyer, setBuyer] = useState(false);
  const [isSeller, setSeller] = useState(false);
  const [isBroker, setBroker] = useState(false);

  const [role, setRole] = useState('');

  const [isOpenDelFileModal, openDelFileModal] = useState({ open: false, name: '' });

  const [driverLicenseFrontUri, setDriverLicenseFrontUri] = useState<string>('');
  const [driverLicenseBackUri, setDriverLicenseBackUri] = useState<string>('');
  const [passportStateIdUri, setPassportStateIdUri] = useState<string>('');
  const [W9Uri, setW9Uri] = useState<string>('');
  const [LLC1DocUri, setLLC1DocUri] = useState<string>('');
  const [LLC2DocUri, setLLC2DocUri] = useState<string>('');
  const [incorporationCertificateUri, setIncorporationCertificateUri] = useState<string>('');
  const [incorporationArticlesUri, setIncorporationArticlesUri] = useState<string>('');
  const [tickerSymbolUri, setTickerSymbolUri] = useState<string>('');

  const {
    user,
  } = useSelector(
    (state: RootState) => state.user,
  );

  useEffect(() => {
    const { clientAccountId, userId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    if (userId) {
      dispatch(getUserProfile(clientAccountId || userId, token, key));
    }
  }, [dispatch]);

  const fileToDataUri = (file: any) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result);
    };
    reader.readAsDataURL(file[0]);
  });

  useEffect(() => {
    if (userDocuments?.driverLicenseFront) fileToDataUri(userDocuments?.driverLicenseFront).then((dataUri) => setDriverLicenseFrontUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (userDocuments?.driverLicenseBack) fileToDataUri(userDocuments?.driverLicenseBack).then((dataUri) => setDriverLicenseBackUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (userDocuments?.stateIdDocument || userDocuments?.passport) fileToDataUri(userDocuments?.stateIdDocument || userDocuments?.passport).then((dataUri) => setPassportStateIdUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (userDocuments?.W9) fileToDataUri(userDocuments?.W9).then((dataUri) => setW9Uri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (userDocuments?.LLC1Doc) fileToDataUri(userDocuments?.LLC1Doc).then((dataUri) => setLLC1DocUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (userDocuments?.LLC2Doc) fileToDataUri(userDocuments?.LLC2Doc).then((dataUri) => setLLC2DocUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (userDocuments?.incorporationCertificate) fileToDataUri(userDocuments?.incorporationCertificate).then((dataUri) => setIncorporationCertificateUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (userDocuments?.incorporationArticles) fileToDataUri(userDocuments?.incorporationArticles).then((dataUri) => setIncorporationArticlesUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (userDocuments?.tickerSymbol) fileToDataUri(userDocuments?.tickerSymbol).then((dataUri) => setTickerSymbolUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
  }, [userDocuments]);

  useEffect(() => {
    setUserDocuments({
      driverLicenseFrontMediaLink: user.driverLicenseFrontMediaLink,
      driverLicenseBackMediaLink: user.driverLicenseBackMediaLink,
      stateIdDocumentMediaLink: user.stateIdDocumentMediaLink,
      passportMediaLink: user.passportMediaLink,
      W9MediaLink: user.W9MediaLink,
      LLC1DocMediaLink: user.LLC1DocMediaLink,
      LLC2DocMediaLink: user.LLC2DocMediaLink,
      profilePhotoLink: user.profilePhotoLink,
      incorporationCertificateMediaLink: user.incorporationCertificateMediaLink,
      incorporationArticlesMediaLink: user.incorporationArticlesMediaLink,
      tickerSymbolMediaLink: user.tickerSymbolMediaLink,
    });
  }, [user, setUserDocuments]);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      const fieldName = Object.keys(errors)[0].replace(/([a-z0-9])([A-Z])/g, '$1 $2');
      const error = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}: ${errors[Object.keys(errors)[0]].message}`;
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: error,
        options: { variant: 'error' },
      }));
    }
  }, [errors, dispatch]);

  useEffect(() => {
    if (isBuyer && !isSeller) setRole('Buyer');
    if (!isBuyer && isSeller) setRole('Seller');
    if (isBuyer && isSeller) setRole('Seller & Buyer');
    if (isBroker) setRole('Broker');
  }, [isBuyer, isSeller, isBroker, setRole]);

  const handleGetMaxDate = useCallback(
    (type: string, value: number) => {
      const date = new Date(new Date().setUTCHours(12, 0, 0, 0));
      if (type === 'year') date.setFullYear(date.getFullYear() - value);
      if (type === 'hours') date.setHours(date.getHours() - value);
      return date;
    },
    [],
  );

  const handleGetMinDate = useCallback(
    (type: string, value: number) => {
      const date = new Date(new Date().setUTCHours(12, 0, 0, 0));
      if (type === 'hours') date.setHours(date.getHours() + value);
      return date;
    },
    [],
  );

  const handleGetUserRole = useCallback(
    () => getRole({
      isConfirmed: user.isConfirmed,
      isAdmin: user.isAdmin,
      isBroker: user.isBroker,
      isBuyer: user.isBuyer,
      isSeller: user.isSeller,
    } as UserRole),
    [user],
  );

  const submitChangeRole = useCallback(
    async (values) => {
      const {
        clientAccountId,
        accountId,
        userId,
        token,
      } = getLocalStorageData();
      const formData = new FormData();
      if (role === handleGetUserRole()) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.ThisIsYourCurrentRole,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.governmentIdType === 'PASSPORT' && !userDocuments.passport && !userDocuments.passportMediaLink) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadCopyOfPassport,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.governmentIdType === 'STATE ID' && !userDocuments.stateIdDocument && !userDocuments.stateIdDocumentMediaLink) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadCopyOfStateIssuedID,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.governmentIdType === 'DRIVER LICENSE' &&
        !userDocuments.driverLicenseFront && !userDocuments.driverLicenseFrontMediaLink
      ) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadCopyOfDLFrontSide,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.governmentIdType === 'DRIVER LICENSE' &&
        !userDocuments.driverLicenseBack && !userDocuments.driverLicenseBackMediaLink
      ) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadCopyOfDLBackSide,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.legalStatus !== 'SOLE PROPRIETOR' &&
        !userDocuments.W9 && !userDocuments.W9MediaLink
      ) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadCopyOfW9,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.legalStatus === 'PARTNERSHIP/LLC' &&
        !userDocuments.LLC1Doc && !userDocuments.LLC1DocMediaLink
      ) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadPartnershipAgreement,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.legalStatus === 'PARTNERSHIP/LLC' &&
        !userDocuments.LLC2Doc && !userDocuments.LLC2DocMediaLink
      ) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadOperatingAgreement,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.legalStatus === 'PRIVATE CO' &&
        !userDocuments.incorporationCertificate && !userDocuments.incorporationCertificateMediaLink
      ) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadCertificateOfIncorporation,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.legalStatus === 'PRIVATE CO' &&
        !userDocuments.incorporationArticles && !userDocuments.incorporationArticlesMediaLink
      ) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.UploadArticlesOfIncorporation,
          options: { variant: 'error' },
        }));
        return;
      }
      if (values.legalStatus === 'PUBLIC CO' &&
        !userDocuments.tickerSymbol && !userDocuments.tickerSymbolMediaLink
      ) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.EnterTickerSymbol,
          options: { variant: 'error' },
        }));
        return;
      }
      const newData = { ...values };
      if (newData.mobileNumber === user.mobileNumber) newData.mobileNumber = '';
      if (newData.email === user.email) newData.email = '';

      const changeRoleUserDataFields = {
        ...newData,
        ...userDocuments,
        isBuyer,
        isSeller,
        changeUserRole: true,
        brokerAccountId: clientAccountId && accountId,
      };
      const userInfo = JSON.stringify(changeRoleUserDataFields);

      formData.append('user', userInfo);
      if (userDocuments.driverLicenseFront) formData.append('driverLicenseFront', userDocuments.driverLicenseFront[0]);
      if (userDocuments.driverLicenseBack) formData.append('driverLicenseBack', userDocuments.driverLicenseBack[0]);
      if (userDocuments.stateIdDocument) formData.append('stateIdDocument', userDocuments.stateIdDocument[0]);
      if (userDocuments.passport) formData.append('passport', userDocuments.passport[0]);
      if (userDocuments.W9) formData.append('W9', userDocuments.W9[0]);
      if (userDocuments.LLC1Doc) formData.append('LLC1Doc', userDocuments.LLC1Doc[0]);
      if (userDocuments.LLC2Doc) formData.append('LLC2Doc', userDocuments.LLC2Doc[0]);
      if (userDocuments.incorporationCertificate) formData.append('incorporationCertificate', userDocuments.incorporationCertificate[0]);
      if (userDocuments.incorporationArticles) formData.append('incorporationArticles', userDocuments.incorporationArticles[0]);
      if (userDocuments.tickerSymbol) formData.append('tickerSymbol', userDocuments.tickerSymbol[0]);

      await dispatch(updateUserProfile(
        formData,
        clientAccountId || userId,
        token,
        new Date().getTime() + Math.random(),
      ));
    },
    [
      dispatch,
      isBuyer,
      isSeller,
      user,
      role,
      handleGetUserRole,
      userDocuments,
    ],
  );

  const handleChangeRole = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      if (event.target.value === '') { setRole(''); }
      if (event.target.value === 'Buyer') { setBuyer(true); setSeller(false); setBroker(false); }
      if (event.target.value === 'Seller') { setBuyer(false); setSeller(true); setBroker(false); }
      if (event.target.value === 'Broker') { setBroker(true); setBuyer(false); setSeller(false); }
      if (event.target.value === 'Seller & Buyer') { setBuyer(true); setSeller(true); setBroker(false); }
    },
    [],
  );

  const {
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  const handleSetDocuments = useCallback((
    field: string,
    value: string | Date | undefined | Array<File> | File | {
      link: string;
      title: string;
    }[] | null,
  ) => {
    const newUserDocuments = { ...userDocuments, [field]: value };
    setUserDocuments(newUserDocuments);
  }, [userDocuments, setUserDocuments]);

  return (
    <>
      {Object.keys(user).length !== 0 && userDocuments ? (
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h1">
                Change role
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-between" className={classes.role}>
              <Grid item>
                <span className={classes.roleTitle}> From:</span>
                <span className={classes.roleValue}>
                  {getRole(userRole) === 'Broker' ?
                    getBrokerRole(userRole, localStorage.getItem('clientAccountId')) :
                    getRole(userRole)}
                </span>
              </Grid>
              <Grid item>
                <span className={classes.roleTitle}> To:</span>
                <Select
                  value={role}
                  onChange={handleChangeRole}
                  className={classes.input}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  error={role === handleGetUserRole()}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  {/* <MenuItem value="Guest" disabled>Guest</MenuItem> */}
                  <MenuItem value="Buyer">Buyer</MenuItem>
                  <MenuItem value="Seller">Seller</MenuItem>
                  <MenuItem value="Seller & Buyer">Seller & Buyer</MenuItem>
                  {/* <MenuItem value="Broker">Broker</MenuItem> */}
                </Select>
                <Grid item xs={12}>
                  {(role === handleGetUserRole()) &&
                    <Typography variant="caption" color="error">{t('ThisIsYourCurrentRole')}</Typography>}
                </Grid>
              </Grid>
            </Grid>
            <Typography variant="h5">
              Please fill in all required fields:
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(submitChangeRole)} autoComplete="off">
            <>
              <Divider className={classes.verticalMargin24} />
              <Grid item xs={12}>
                <Typography variant="h6">
                  Personal Info:
                </Typography>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={user.firstName || ''}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="First Name*"
                      error={!!errors.firstName?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    pattern: {
                      value: /^[A-Za-z- ']+$/i,
                      message: 'Incorrect value',
                    },
                  }}
                />
                {errors.firstName &&
                  <Typography variant="caption" color="error">{errors.firstName.message}</Typography>}
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={user.lastName || ''}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="Last Name*"
                      error={!!errors.lastName?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    pattern: {
                      value: /^[A-Za-z- ']+$/i,
                      message: 'Incorrect value',
                    },
                  }}
                />
                {errors.lastName &&
                  <Typography variant="caption" color="error">{errors.lastName.message}</Typography>}
                <Controller
                  name="email"
                  control={control}
                  defaultValue={user.email || ''}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="Email*"
                      error={!!errors.email?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    pattern: {
                      value: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i,
                      message: 'Incorrect value',
                    },
                  }}
                />
                {errors.email &&
                  <Typography variant="caption" color="error">{errors.email.message}</Typography>}
                <Controller
                  name="dateOfBirth"
                  control={control}
                  defaultValue={user.dateOfBirth && new Date(user.dateOfBirth)}
                  render={({ onChange, value, name }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        size="small"
                        name={name}
                        inputVariant="outlined"
                        label="Date Of Birth*"
                        format="MM/dd/yyyy"
                        maxDate={handleGetMaxDate('year', 18)}
                        value={value}
                        onChange={(date) => {
                          onChange(getDateUTC12H(date));
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk
                        fullWidth
                        error={!!errors.dateOfBirth?.message}
                        invalidDateMessage=""
                        maxDateMessage=""
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  rules={{
                    required: 'Required field',
                    validate: (value) => {
                      // eslint-disable-next-line no-restricted-globals
                      if (value && isNaN(value.getTime())) return 'Invalid date';
                      if (value && value.getTime() > handleGetMaxDate('year', 18).getTime()) return 'Minimum age 18 years old';
                      return true;
                    },
                  }}
                />
                {errors.dateOfBirth &&
                  <Typography variant="caption" color="error">{errors.dateOfBirth.message}</Typography>}
                <FormControl margin="normal" className={cx(classes.fullWidth, classes.phone, !!errors.mobileNumber && classes.errorPhone)}>
                  <Controller
                    name="mobileNumber"
                    control={control}
                    defaultValue={user.mobileNumber || ''}
                    render={({ onChange, value, name }) => (
                      <PhoneInput
                        country="us"
                        countryCodeEditable={false}
                        onlyCountries={['us', 'mx', 'ca']}
                        value={value}
                        inputProps={{
                          name,
                          className: (cx(
                            classes.inputPhone,
                            !!errors.mobileNumber && classes.errorInputPhone,
                          )),
                          autoComplete: 'new-password',
                        }}
                        onChange={(tel) => {
                          onChange(tel);
                        }}
                        specialLabel="Mobile Number*"
                      />
                    )}
                    rules={{
                      required: 'Required field',
                      validate: (value) => {
                        if (value && (value === '1' || value === '52')) return 'Required field';
                        if (value) return value.length >= 11 || 'Invalid value';
                        return true;
                      },
                    }}
                  />
                  {errors.mobileNumber &&
                    <Typography variant="caption" color="error">{errors.mobileNumber.message}</Typography>}
                </FormControl>
                <Controller
                  name="line1"
                  control={control}
                  defaultValue={user.line1 || ''}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="Address Line1*"
                      error={!!errors.line1?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                  }}
                />
                {errors.line1 &&
                  <Typography variant="caption" color="error">{errors.line1.message}</Typography>}
                <Controller
                  name="line2"
                  control={control}
                  defaultValue={user.line2 || ''}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="Address Line 2"
                      error={!!errors.line2?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                />
                {errors.line2 &&
                  <Typography variant="caption" color="error">{errors.line2.message}</Typography>}
                <Controller
                  name="city"
                  control={control}
                  defaultValue={user.city || ''}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="City*"
                      error={!!errors.city?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                  }}
                />
                {errors.city &&
                  <Typography variant="caption" color="error">{errors.city.message}</Typography>}
                <Controller
                  name="state"
                  control={control}
                  defaultValue={user.state || ''}
                  render={({ onChange, value }) => (
                    <FormControl margin="normal" fullWidth>
                      <StateAutocomplete
                        label="State*"
                        defaultValue={user.state || ''}
                        currentState={value}
                        handleChange={(newValue: any) => {
                          onChange(newValue);
                        }}
                        error={!!errors.state?.message}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: 'Required field',
                  }}
                />
                {errors.state &&
                  <Typography variant="caption" color="error">{errors.state.message}</Typography>}
                <Controller
                  name="zip"
                  control={control}
                  defaultValue={user.zip || ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      margin="normal"
                      size="small"
                      variant="outlined"
                      fullWidth
                      label="Zip Code*"
                      error={!!errors.zip?.message}
                      autoComplete="new-password"
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value);
                      }}
                      InputProps={{
                        inputComponent: NumberFormatZip as any,
                      }}
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    minLength: {
                      value: 5,
                      message: 'Incorrect zip: 5 symbols needed',
                    },
                  }}
                />
                {errors.zip &&
                  <Typography variant="caption" color="error">{errors.zip.message}</Typography>}
                <Controller
                  name="country"
                  control={control}
                  defaultValue={user.country || ''}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="Country"
                      error={!!errors.country?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                />
                {errors.country &&
                  <Typography variant="caption" color="error">{errors.country.message}</Typography>}
                <Divider className={classes.verticalMargin24} />
                <Typography variant="h6">
                  Government ID:
                </Typography>
                <Controller
                  name="governmentIdType"
                  control={control}
                  defaultValue={user.governmentIdType || ''}
                  render={({ onChange, value, name }) => (
                    <FormControl variant="outlined" size="small" fullWidth margin="normal">
                      <InputLabel error={!!errors.governmentIdType?.message}>ID Type*</InputLabel>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        onChange={({ target }) => {
                          onChange(target.value);
                          setValue('governmentIdNumber', '');
                          setValue('governmentIdDateOfIssue', null);
                          setValue('governmentIdDateOfExpiration', null);
                          setUserDocuments({
                            ...userDocuments,
                            driverLicenseFrontMediaLink: null,
                            driverLicenseBackMediaLink: null,
                            stateIdDocumentMediaLink: null,
                            passportMediaLink: null,
                            driverLicenseFront: null,
                            driverLicenseBack: null,
                            stateIdDocument: null,
                            passport: null,
                          });
                        }}
                        value={value}
                        label="ID Type*"
                        name={name}
                        error={!!errors.governmentIdType?.message}
                      >
                        <MenuItem value="" disabled>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="DRIVER LICENSE">Driver License</MenuItem>
                        <MenuItem value="STATE ID">State ID</MenuItem>
                        <MenuItem value="PASSPORT">Passport</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  rules={{
                    required: 'Required field',
                  }}
                />
                {errors.governmentIdType &&
                  <Typography variant="caption" color="error">{errors.governmentIdType.message}</Typography>}
                <Controller
                  name="governmentIdNumber"
                  control={control}
                  defaultValue={user.governmentIdNumber || ''}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="ID Number*"
                      error={!!errors.governmentIdNumber?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                  }}
                />
                {errors.governmentIdNumber &&
                  <Typography variant="caption" color="error">{errors.governmentIdNumber.message}</Typography>}
                <Controller
                  name="governmentIdDateOfIssue"
                  control={control}
                  defaultValue={user.governmentIdDateOfIssue &&
                    new Date(user.governmentIdDateOfIssue)}
                  render={({ onChange, value, name }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        size="small"
                        name={name}
                        inputVariant="outlined"
                        label="Date Of Issue*"
                        format="MM/dd/yyyy"
                        maxDate={handleGetMaxDate('hours', 24)}
                        value={value}
                        margin="normal"
                        onChange={(date) => {
                          onChange(getDateUTC12H(date));
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk
                        fullWidth
                        error={!!errors.governmentIdDateOfIssue?.message}
                        invalidDateMessage=""
                        maxDateMessage=""
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  rules={{
                    required: 'Required field',
                    validate: (value) => {
                      // eslint-disable-next-line no-restricted-globals
                      if (value && isNaN(value.getTime())) return 'Invalid date';
                      if (value && value.getTime() > handleGetMaxDate('hours', 24).getTime()) return 'Issue Date should be prior to today’s date';
                      return true;
                    },
                  }}
                />
                {errors.governmentIdDateOfIssue &&
                  <Typography variant="caption" color="error">{errors.governmentIdDateOfIssue.message}</Typography>}
                <Controller
                  name="governmentIdDateOfExpiration"
                  control={control}
                  defaultValue={user.governmentIdDateOfExpiration &&
                    new Date(user.governmentIdDateOfExpiration)}
                  render={({ onChange, value, name }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        size="small"
                        name={name}
                        inputVariant="outlined"
                        label="Date Of Expiration*"
                        format="MM/dd/yyyy"
                        minDate={handleGetMinDate('hours', 24)}
                        margin="normal"
                        value={value}
                        onChange={(date) => {
                          onChange(getDateUTC12H(date));
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk
                        fullWidth
                        error={!!errors.governmentIdDateOfExpiration?.message}
                        invalidDateMessage=""
                        minDateMessage=""
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  rules={{
                    required: 'Required field',
                    validate: (value) => {
                      // eslint-disable-next-line no-restricted-globals
                      if (value && isNaN(value.getTime())) return 'Invalid date';
                      if (value && value.getTime() < handleGetMinDate('hours', 24).getTime()) return 'Expiration Date should be a future date';
                      return true;
                    },
                  }}
                />
                {errors.governmentIdDateOfExpiration &&
                  <Typography variant="caption" color="error">{errors.governmentIdDateOfExpiration.message}</Typography>}
                {watch().governmentIdType && (
                  <>
                    <Divider className={classes.verticalMargin24} />
                    <Typography variant="h6">
                      Documents
                    </Typography>
                    <Typography variant="body2" className={classes.verticalMargin24}>
                      Upload a copy of the document, up to 10Mb.
                      For driver’s license please upload copies of both front and back
                    </Typography>
                  </>
                )}
                {(watch().governmentIdType === 'PASSPORT' || watch().governmentIdType === 'STATE ID') && (
                  <>
                    <Grid item xs={12}>
                      <FileUploader
                        multiSelect={false}
                        uploadFile={(file) => {
                          handleSetDocuments(watch().governmentIdType === 'PASSPORT' ? 'passport' : 'stateIdDocument', file);
                        }}
                      />
                      {(userDocuments.passportMediaLink ||
                        userDocuments.stateIdDocumentMediaLink) &&
                        (
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                <Link href={`${constants.AWS_URL}${userDocuments.passportMediaLink ? userDocuments.passportMediaLink.link : userDocuments.stateIdDocumentMediaLink.link}`} target="_blank" rel="noreferrer">
                                  {userDocuments.passportMediaLink ?
                                    userDocuments.passportMediaLink.title :
                                    userDocuments.stateIdDocumentMediaLink.title}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: (userDocuments.passportMediaLink && 'passportMediaLink') || 'stateIdDocumentMediaLink' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      {(userDocuments.passport || userDocuments.stateIdDocument) && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              New:
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                <Link
                                  href={passportStateIdUri}
                                  download={(userDocuments.passport &&
                                    userDocuments.passport[0].name) ||
                                    (userDocuments.stateIdDocument &&
                                      userDocuments.stateIdDocument[0].name)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {(userDocuments.passport && userDocuments.passport[0].name) ||
                                    (userDocuments.stateIdDocument &&
                                      userDocuments.stateIdDocument[0].name)}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: (watch().governmentIdType === 'PASSPORT' && 'passport') || 'stateIdDocument' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}

                {(watch().governmentIdType as string) === 'DRIVER LICENSE' && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Front Side
                      </Typography>
                      <FileUploader
                        multiSelect={false}
                        uploadFile={(file) => {
                          handleSetDocuments('driverLicenseFront', file);
                        }}
                      />
                      {userDocuments.driverLicenseFrontMediaLink && (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={1}><DocUpload /></Grid>
                          <Grid item xs={9}>
                            <p className={classes.docName}>
                              <Link href={`${constants.AWS_URL}${userDocuments.driverLicenseFrontMediaLink.link}`} target="_blank" rel="noreferrer">
                                {userDocuments.driverLicenseFrontMediaLink.title}
                              </Link>
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => { openDelFileModal({ open: true, name: 'driverLicenseFrontMediaLink' }); }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                      {userDocuments.driverLicenseFront && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              New:
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                {/* {driverLicenseFront && driverLicenseFront[0].name} */}
                                <Link href={driverLicenseFrontUri} download={userDocuments.driverLicenseFront && userDocuments.driverLicenseFront[0].name} target="_blank" rel="noreferrer">
                                  {userDocuments.driverLicenseFront &&
                                    userDocuments.driverLicenseFront[0].name}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: 'driverLicenseFront' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Back Side
                      </Typography>
                      <FileUploader
                        multiSelect={false}
                        uploadFile={(file) => {
                          handleSetDocuments('driverLicenseBack', file);
                        }}
                      />
                      {userDocuments.driverLicenseBackMediaLink && (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={1}><DocUpload /></Grid>
                          <Grid item xs={9}>
                            <p className={classes.docName}>
                              <Link href={`${constants.AWS_URL}${userDocuments.driverLicenseBackMediaLink.link}`} target="_blank" rel="noreferrer">
                                {userDocuments.driverLicenseBackMediaLink.title}
                              </Link>
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => { openDelFileModal({ open: true, name: 'driverLicenseBackMediaLink' }); }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                      {userDocuments.driverLicenseBack && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              New:
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                {/* {driverLicenseBack && driverLicenseBack[0].name} */}
                                <Link href={driverLicenseBackUri} download={userDocuments.driverLicenseBack && userDocuments.driverLicenseBack[0].name} target="_blank" rel="noreferrer">
                                  {userDocuments.driverLicenseBack &&
                                    userDocuments.driverLicenseBack[0].name}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: 'driverLicenseBack' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
                <Divider className={classes.verticalMargin24} />
                <Typography variant="h6">
                  Legal Status:
                </Typography>
                <FormControl variant="outlined" size="small" fullWidth margin="normal">
                  <InputLabel error={!!errors.legalStatus?.message}>Legal Status*</InputLabel>
                  <Controller
                    name="legalStatus"
                    control={control}
                    defaultValue={user.legalStatus || ''}
                    error={!!errors.legalStatus?.message}
                    render={({ onChange, value, name }) => (
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        onChange={({ target }) => {
                          onChange(target.value);
                        }}
                        value={value}
                        label="Legal Status*"
                        name={name}
                        error={!!errors.legalStatus?.message}
                      >
                        <MenuItem value="" disabled>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="SOLE PROPRIETOR">Sole Proprietor</MenuItem>
                        <MenuItem value="PARTNERSHIP/LLC">Partnership/LLC</MenuItem>
                        <MenuItem value="PRIVATE CO">Private Co</MenuItem>
                        <MenuItem value="PUBLIC CO">Public Co</MenuItem>
                      </Select>
                    )}
                    rules={{
                      required: 'Required field',
                    }}
                  />
                </FormControl>
                {errors.legalStatus &&
                  <Typography variant="caption" color="error">{errors.legalStatus.message}</Typography>}
                {watch().legalStatus === 'SOLE PROPRIETOR' && (
                  <>
                    <Controller
                      name="SSN"
                      control={control}
                      defaultValue={user.SSN || ''}
                      render={({ onChange, value, name }) => (
                        <TextField
                          value={value}
                          name={name}
                          margin="normal"
                          size="small"
                          variant="outlined"
                          fullWidth
                          label="SSN*"
                          error={!!errors.SSN?.message}
                          InputProps={{
                            inputComponent: NumberFormatSSN as any,
                          }}
                          onChange={({ target }) => {
                            onChange(target.value);
                          }}
                        />
                      )}
                      rules={{
                        required: 'Required field',
                        pattern: {
                          value: /^[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/i,
                          message: 'Incorrect value',
                        },
                      }}
                    />
                    {errors.SSN &&
                      <Typography variant="caption" color="error">{errors.SSN.message}</Typography>}
                  </>
                )}
                {watch().legalStatus && watch().legalStatus !== 'SOLE PROPRIETOR' && (
                  <>
                    <Controller
                      name="organizationName"
                      control={control}
                      defaultValue={user.organizationName || ''}
                      render={({ onChange, value, name }) => (
                        <TextFieldProfile
                          name={name}
                          value={value}
                          label="Company Name*"
                          error={!!errors.organizationName?.message}
                          handleChange={(data) => {
                            onChange(data);
                          }}
                          margin="normal"
                        />
                      )}
                      rules={{
                        required: 'Required field',
                      }}
                    />
                    {errors.organizationName &&
                      <Typography variant="caption" color="error">{errors.organizationName.message}</Typography>}
                    <Controller
                      name="contactsTitle"
                      control={control}
                      defaultValue={user.contactsTitle}
                      render={({ onChange, value, name }) => (
                        <TextFieldProfile
                          name={name}
                          value={value}
                          label="Your Title*"
                          error={!!errors.contactsTitle?.message}
                          handleChange={(data) => {
                            onChange(data);
                          }}
                          margin="normal"
                        />
                      )}
                      rules={{
                        required: 'Required field',
                      }}
                    />
                    {errors.contactsTitle &&
                      <Typography variant="caption" color="error">{errors.contactsTitle.message}</Typography>}
                    <Controller
                      name="stateOfIncorporation"
                      control={control}
                      defaultValue={user.stateOfIncorporation || ''}
                      render={({ onChange, value }) => (
                        <FormControl margin="normal" fullWidth>
                          <StateAutocomplete
                            label="State of Formation*"
                            defaultValue={user.stateOfIncorporation || ''}
                            currentState={value}
                            handleChange={(newValue: any) => {
                              onChange(newValue);
                            }}
                            error={!!errors.stateOfIncorporation?.message}
                          />
                        </FormControl>
                      )}
                      rules={{
                        required: 'Required field',
                      }}
                    />
                    {errors.stateOfIncorporation &&
                      <Typography variant="caption" color="error">{errors.stateOfIncorporation.message}</Typography>}
                    <Controller
                      name="federalEIN"
                      control={control}
                      defaultValue={user.federalEIN || ''}
                      render={({ onChange, value, name }) => (
                        <TextField
                          value={value}
                          name={name}
                          margin="normal"
                          size="small"
                          variant="outlined"
                          fullWidth
                          label="EIN*"
                          error={!!errors.federalEIN?.message}
                          InputProps={{
                            inputComponent: NumberFormatEIN as any,
                          }}
                          onChange={({ target }) => {
                            onChange(target.value);
                          }}
                        />
                      )}
                      rules={{
                        required: 'Required field',
                        pattern: {
                          value: /^[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/i,
                          message: 'Incorrect value',
                        },
                      }}
                    />
                    {errors.federalEIN && <Typography variant="caption" color="error">{errors.federalEIN.message}</Typography>}
                    <FormControl margin="normal" className={cx(classes.fullWidth, classes.phone, !!errors.mobileNumber && classes.errorPhone)}>
                      <Controller
                        name="organizationOfficePhone"
                        control={control}
                        defaultValue={user.organizationOfficePhone || ''}
                        render={({ onChange, value, name }) => (
                          <PhoneInput
                            country="us"
                            countryCodeEditable={false}
                            onlyCountries={['us', 'mx', 'ca']}
                            value={value}
                            inputProps={{
                              name,
                              className: (cx(
                                classes.inputPhone,
                                !!errors.organizationOfficePhone && classes.errorInputPhone,
                              )),
                              autoComplete: 'new-password',
                            }}
                            onChange={(tel) => {
                              onChange(tel || '');
                            }}
                            specialLabel="Office Phone*"
                          />
                        )}
                        rules={{
                          required: 'Required field',
                          validate: (value) => {
                            if (value && (value === '1' || value === '52')) return 'Required field';
                            if (value) return value.length >= 11 || 'Invalid value';
                            return true;
                          },
                        }}
                      />
                      {errors.organizationOfficePhone &&
                        <Typography variant="caption" color="error">{errors.organizationOfficePhone.message}</Typography>}
                    </FormControl>
                    <Controller
                      name="organizationLine1"
                      control={control}
                      defaultValue={user.organizationLine1 || ''}
                      render={({ onChange, value, name }) => (
                        <TextFieldProfile
                          name={name}
                          value={value}
                          label="Address Line 1*"
                          error={!!errors.organizationLine1?.message}
                          handleChange={(data) => {
                            onChange(data);
                          }}
                          margin="normal"
                        />
                      )}
                      rules={{
                        required: 'Required field',
                      }}
                    />
                    {errors.organizationLine1 &&
                      <Typography variant="caption" color="error">{errors.organizationLine1.message}</Typography>}
                    <Controller
                      name="organizationCity"
                      control={control}
                      defaultValue={user.organizationCity}
                      render={({ onChange, value, name }) => (
                        <TextFieldProfile
                          name={name}
                          value={value}
                          label="City*"
                          error={!!errors.organizationCity?.message}
                          handleChange={(data) => {
                            onChange(data);
                          }}
                          margin="normal"
                        />
                      )}
                      rules={{
                        required: 'Required field',
                      }}
                    />
                    {errors.organizationCity &&
                      <Typography variant="caption" color="error">{errors.organizationCity.message}</Typography>}
                    <Controller
                      name="organizationState"
                      control={control}
                      defaultValue={user.organizationState || ''}
                      render={({ onChange, value }) => (
                        <FormControl margin="normal" fullWidth>
                          <StateAutocomplete
                            label="State*"
                            defaultValue={user.organizationState || ''}
                            currentState={value}
                            handleChange={(newValue: any) => {
                              onChange(newValue);
                            }}
                            error={!!errors.organizationState?.message}
                          />
                        </FormControl>
                      )}
                      rules={{
                        required: 'Required field',
                      }}
                    />
                    {errors.organizationState &&
                      <Typography variant="caption" color="error">{errors.organizationState.message}</Typography>}
                    <Controller
                      name="organizationZip"
                      control={control}
                      defaultValue={user.organizationZip || ''}
                      render={({ onChange, value, name }) => (
                        <TextField
                          margin="normal"
                          size="small"
                          variant="outlined"
                          fullWidth
                          label="Zip Code*"
                          autoComplete="new-password"
                          error={!!errors.organizationZip?.message}
                          name={name}
                          value={value}
                          onChange={({ target }) => {
                            onChange(target.value);
                          }}
                          InputProps={{
                            inputComponent: NumberFormatZip as any,
                          }}
                        />
                      )}
                      rules={{
                        required: 'Required field',
                        minLength: {
                          value: 5,
                          message: 'Incorrect zip: 5 symbols needed',
                        },
                      }}
                    />
                    {errors.organizationZip &&
                      <Typography variant="caption" color="error">{errors.organizationZip.message}</Typography>}
                    <Controller
                      name="organizationCountry"
                      control={control}
                      defaultValue={user.organizationCountry || ''}
                      render={({ onChange, value, name }) => (
                        <TextFieldProfile
                          name={name}
                          value={value}
                          label="Country"
                          error={!!errors.organizationCountry?.message}
                          handleChange={(data) => {
                            onChange(data);
                          }}
                          margin="normal"
                        />
                      )}
                    />
                    {errors.organizationCountry &&
                      <Typography variant="caption" color="error">{errors.organizationCountry.message}</Typography>}
                  </>
                )}
              </Grid>

              {watch().legalStatus && watch().legalStatus !== 'SOLE PROPRIETOR' && (
                <>
                  <Divider className={classes.verticalMargin24} />
                  <Typography variant="h6">
                    Corporate Documents:
                  </Typography>
                  <Typography variant="body2" className={classes.verticalMargin24}>
                    Upload a copy of the document, up to 10Mb.
                  </Typography>
                  <Typography variant="body1">
                    {'i.   W-9   '}
                    <Link href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" rel="noreferrer">
                      Open
                    </Link>
                  </Typography>
                  <FileUploader
                    multiSelect={false}
                    uploadFile={(file) => {
                      handleSetDocuments('W9', file);
                    }}
                  />
                  {userDocuments.W9MediaLink && (
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={1}><DocUpload /></Grid>
                      <Grid item xs={9}>
                        <p className={classes.docName}>
                          <Link href={`${constants.AWS_URL}${userDocuments.W9MediaLink.link}`} target="_blank" rel="noreferrer">
                            {userDocuments.W9MediaLink.title}
                          </Link>
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => { openDelFileModal({ open: true, name: 'W9MediaLink' }); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                  {userDocuments.W9 && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          New:
                        </Typography>
                      </Grid>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={1}><DocUpload /></Grid>
                        <Grid item xs={9}>
                          <p className={classes.docName}>
                            <Link href={W9Uri} download={userDocuments.W9 && userDocuments.W9[0].name} target="_blank" rel="noreferrer">
                              {userDocuments.W9 &&
                                userDocuments.W9[0].name}
                            </Link>
                          </p>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => { openDelFileModal({ open: true, name: 'W9' }); }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {watch().legalStatus === 'PARTNERSHIP/LLC' && (
                    <>
                      ii. Partnership Agreement for General Partnership; or Certificate or
                      Articles of Incorporation for LLC.
                      <FileUploader
                        multiSelect={false}
                        uploadFile={(file) => {
                          handleSetDocuments('LLC1Doc', file);
                        }}
                      />
                      {userDocuments.LLC1DocMediaLink && (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={1}><DocUpload /></Grid>
                          <Grid item xs={9}>
                            <p className={classes.docName}>
                              <Link href={`${constants.AWS_URL}${userDocuments.LLC1DocMediaLink.link}`} target="_blank" rel="noreferrer">
                                {userDocuments.LLC1DocMediaLink.title}
                              </Link>
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => { openDelFileModal({ open: true, name: 'LLC1DocMediaLink' }); }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                      {userDocuments.LLC1Doc && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              New:
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                <Link href={LLC1DocUri} download={userDocuments.LLC1Doc && userDocuments.LLC1Doc[0].name} target="_blank" rel="noreferrer">
                                  {userDocuments.LLC1Doc &&
                                    userDocuments.LLC1Doc[0].name}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: 'LLC1Doc' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      iii. Operating Agreement for Managing Members for LLC
                      <FileUploader
                        multiSelect={false}
                        uploadFile={(file) => {
                          handleSetDocuments('LLC2Doc', file);
                        }}
                      />
                      {userDocuments.LLC2DocMediaLink && (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={1}><DocUpload /></Grid>
                          <Grid item xs={9}>
                            <p className={classes.docName}>
                              <Link href={`${constants.AWS_URL}${userDocuments.LLC2DocMediaLink.link}`} target="_blank" rel="noreferrer">
                                {userDocuments.LLC2DocMediaLink.title}
                              </Link>
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => { openDelFileModal({ open: true, name: 'LLC2DocMediaLink' }); }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                      {userDocuments.LLC2Doc && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              New:
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                <Link href={LLC2DocUri} download={userDocuments.LLC2Doc && userDocuments.LLC2Doc[0].name} target="_blank" rel="noreferrer">
                                  {userDocuments.LLC2Doc &&
                                    userDocuments.LLC2Doc[0].name}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: 'LLC2Doc' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {watch().legalStatus === 'PRIVATE CO' && (
                    <>
                      ii. Certificate of Incorporation
                      <FileUploader
                        multiSelect={false}
                        uploadFile={(file) => {
                          handleSetDocuments('incorporationCertificate', file);
                        }}
                      />
                      {userDocuments.incorporationCertificateMediaLink && (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={1}><DocUpload /></Grid>
                          <Grid item xs={9}>
                            <p className={classes.docName}>
                              <Link href={`${constants.AWS_URL}${userDocuments.incorporationCertificateMediaLink.link}`} target="_blank" rel="noreferrer">
                                {userDocuments.incorporationCertificateMediaLink.title}
                              </Link>
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => { openDelFileModal({ open: true, name: 'incorporationCertificateMediaLink' }); }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                      {userDocuments.incorporationCertificate && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              New:
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                <Link href={incorporationCertificateUri} download={userDocuments.incorporationCertificate && userDocuments.incorporationCertificate[0].name} target="_blank" rel="noreferrer">
                                  {userDocuments.incorporationCertificate &&
                                    userDocuments.incorporationCertificate[0].name}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: 'incorporationCertificate' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      iii. Articles of Incorporation
                      <FileUploader
                        multiSelect={false}
                        uploadFile={(file) => {
                          handleSetDocuments('incorporationArticles', file);
                        }}
                      />
                      {userDocuments.incorporationArticlesMediaLink && (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={1}><DocUpload /></Grid>
                          <Grid item xs={9}>
                            <p className={classes.docName}>
                              <Link href={`${constants.AWS_URL}${userDocuments.incorporationArticlesMediaLink.link}`} target="_blank" rel="noreferrer">
                                {userDocuments.incorporationArticlesMediaLink.title}
                              </Link>
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => { openDelFileModal({ open: true, name: 'incorporationArticlesMediaLink' }); }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                      {userDocuments.incorporationArticles && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              New:
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                <Link href={incorporationArticlesUri} download={userDocuments.incorporationArticles && userDocuments.incorporationArticles[0].name} target="_blank" rel="noreferrer">
                                  {userDocuments.incorporationArticles &&
                                    userDocuments.incorporationArticles[0].name}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: 'incorporationArticles' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {watch().legalStatus === 'PUBLIC CO' && (
                    <>
                      ii. Ticker Symbol
                      <FileUploader
                        multiSelect={false}
                        uploadFile={(file) => {
                          handleSetDocuments('tickerSymbol', file);
                        }}
                      />
                      {userDocuments.tickerSymbolMediaLink && (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={1}><DocUpload /></Grid>
                          <Grid item xs={9}>
                            <p className={classes.docName}>
                              <Link href={`${constants.AWS_URL}${userDocuments.tickerSymbolMediaLink.link}`} target="_blank" rel="noreferrer">
                                {userDocuments.tickerSymbolMediaLink.title}
                              </Link>
                            </p>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => { openDelFileModal({ open: true, name: 'tickerSymbolMediaLink' }); }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                      {userDocuments.tickerSymbol && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              New:
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={1}><DocUpload /></Grid>
                            <Grid item xs={9}>
                              <p className={classes.docName}>
                                <Link href={tickerSymbolUri} download={userDocuments.tickerSymbol && userDocuments.tickerSymbol[0].name} target="_blank" rel="noreferrer">
                                  {userDocuments.tickerSymbol &&
                                    userDocuments.tickerSymbol[0].name}
                                </Link>
                              </p>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => { openDelFileModal({ open: true, name: 'tickerSymbol' }); }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <Grid item xs={12} className={classes.button}>
                {role !== '' && role !== handleGetUserRole() && (
                  <ButtonSC
                    variant="fill"
                    type="submit"
                    text="Submit Request"
                    size="large"
                    color="green"
                  />
                )}
              </Grid>
            </>
          </form>
          {isOpenDelFileModal.open && (
            <ConfirmationDialog
              open={isOpenDelFileModal.open}
              title="File deletion"
              description={(
                <p>
                  Are you sure you want to delete the selected file?
                </p>
              )}
              acceptButtonText="Delete"
              cancelButtonText="Cancel"
              color="green"
              onAcceptButtonClicked={
                () => {
                  handleSetDocuments(isOpenDelFileModal.name, undefined);
                  openDelFileModal({ open: false, name: '' });
                }
              }
              onCancelButtonClicked={
                () => openDelFileModal({ open: false, name: '' })
              }
            />
          )}
        </Paper>
      ) :
        (
          <LoadingPage />
        )}
    </>
  );
};

export default ChangeRole;
