import React, { FC } from 'react';
import {
  TextField,
} from '@material-ui/core';

interface Props {
  name: string;
  value: string;
  label: string;
  error: boolean;
  handleChange: (value: string) => void;
  disabled?: boolean;
  margin?: 'none' | 'dense' | 'normal' | undefined;
}

const TextFieldProfile: FC<Props> = ({
  name,
  value,
  label,
  error,
  handleChange,
  disabled,
  margin,
}) => (
    <TextField
      type="text"
      name={name}
      value={value}
      label={label}
      onChange={({ target }) => {
        handleChange(target.value);
      }}
      onBlur={({ target }) => {
        handleChange(target.value.trim());
      }}
      autoComplete="new-password"
      size="small"
      InputLabelProps={{ shrink: !!value }}
      variant="outlined"
      fullWidth
      inputProps={{
        maxLength: 80,
      }}
      error={error}
      disabled={disabled}
      margin={margin}
    />
  );

export default TextFieldProfile;
