import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const UserList = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 13">
    <path d="M12.6 11.2667V13H0V11.2667C0 11.2667 0 7.80002 6.3 7.80002C12.6 7.80002 12.6 11.2667 12.6 11.2667ZM9.45 3.03337C9.45 2.43343 9.26526 1.84697 8.91913 1.34814C8.573 0.849317 8.08104 0.460529 7.50545 0.230944C6.92986 0.00135864 6.2965 -0.0587113 5.68546 0.0583302C5.07442 0.175372 4.51315 0.464268 4.07261 0.888485C3.63208 1.3127 3.33207 1.85319 3.21053 2.4416C3.08898 3.03 3.15136 3.6399 3.38978 4.19417C3.6282 4.74844 4.03194 5.22218 4.54995 5.55548C5.06797 5.88879 5.67699 6.06669 6.3 6.06669C7.13543 6.06669 7.93665 5.74711 8.52739 5.17825C9.11813 4.60939 9.45 3.83786 9.45 3.03337ZM12.546 7.80002C13.0993 8.21233 13.552 8.73639 13.8723 9.33532C14.1926 9.93425 14.3727 10.5935 14.4 11.2667V13H18V11.2667C18 11.2667 18 8.12068 12.546 7.80002ZM11.7 4.55683e-05C11.0805 -0.00327611 10.4747 0.175073 9.963 0.511377C10.5097 1.24695 10.8036 2.12882 10.8036 3.03337C10.8036 3.93792 10.5097 4.81979 9.963 5.55536C10.4747 5.89166 11.0805 6.07001 11.7 6.06669C12.5354 6.06669 13.3366 5.74711 13.9274 5.17825C14.5181 4.60939 14.85 3.83786 14.85 3.03337C14.85 2.22888 14.5181 1.45734 13.9274 0.888485C13.3366 0.319627 12.5354 4.55683e-05 11.7 4.55683e-05Z" />
  </SvgIcon>
);

export default UserList;
