import React, {
  FC, useState, useCallback, useEffect, ChangeEvent,
} from 'react';
import cx from 'classnames';
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './DealRoom.style';
import { PostTradeStepForDeal } from '../../interfaces/deals.interface';
import getLocalStorageData from '../../api/localStorage';
import ButtonSC from '../ButtonSC/ButtonSC';
import { getDate } from '../../utils/getDate';
import { MultiFileUploaderButton, MultiFileUploaderList } from '../FileUploader/MultiFileUploader';
import constants from '../../core/constants';

interface Props {
  postTradeSteps: PostTradeStepForDeal[];
  finishStep: (id: number, documents: File[]) => void;
  sellerId: number;
  buyerId: number;
  expanded: string | false;
  setExpanded: (value: string | false) => void;
  currentStep: number;
}

const PostTradeStepsCheck: FC<Props> = ({
  postTradeSteps,
  finishStep,
  sellerId,
  buyerId,
  expanded,
  setExpanded,
  currentStep,
}) => {
  const classes = useStyles();
  const { clientAccountId, accountId } = getLocalStorageData();
  const [documents, setDocuments] = useState<Array<File>>([]);

  const handleSetDocs = useCallback(
    (newFiles) => {
      setDocuments(newFiles);
    },
    [],
  );

  useEffect(() => {
    setDocuments([]);
  }, [expanded]);

  return (
    <div className={classes.root}>
      {postTradeSteps.map((item) => (
        <Accordion
          key={item.description + item.valueSeller}
          expanded={expanded === item.description + item.valueSeller}
          onChange={
            (event: ChangeEvent<{}>, newExpanded: boolean) => {
              setExpanded(newExpanded ? item.description + item.valueSeller : false);
            }
          }
          className={classes.Accordion}
          disabled={currentStep + 1 < item.step}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            className={cx(classes.AccordionSummary, item.agreed ? classes.AccordionSummaryAgreed : '')}
          >
            <Grid container alignItems="flex-end">
              <Grid item xs={6} sm={3} style={{ alignSelf: 'center' }}>
                <Typography className={classes.AccordionSummaryTitle}>
                  {item.description}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} style={{ alignSelf: 'center' }}>
                <Typography className={classes.AccordionSummaryTitleAgreed}>
                  {(item.responsibleValueSeller as string).charAt(0).toUpperCase() +
                    (item.responsibleValueSeller as string).substring(1)}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} style={{ alignSelf: 'center' }}>
                <Typography className={classes.AccordionSummaryTitleAgreed}>
                  {`Deadline: ${getDate(item.valueSeller)}`}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                {item.completed && (
                  <Typography className={classes.AccordionSummaryTitleAgreed}>
                    Completed
                  </Typography>
                )}
                {!item.completed && (
                  <Typography className={classes.AccordionSummaryTitleAgreed}>
                    Pending
                  </Typography>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            {((!item.completed && buyerId === (clientAccountId || accountId) && item.responsibleValueSeller === 'buyer') ||
              (!item.completed && sellerId === (clientAccountId || accountId) && item.responsibleValueSeller === 'seller')) ? (
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} sm={3}>
                    <MultiFileUploaderButton
                      currentFiles={[]}
                      newFiles={documents || []}
                      setListFiles={(files) => handleSetDocs(files)}
                      docsTitle
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} style={{ maxHeight: 220, overflowY: 'auto' }}>
                    <MultiFileUploaderList
                      currentFiles={[]}
                      newFiles={documents || []}
                      setListFiles={(files) => handleSetDocs(files)}
                      docsTitle
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ButtonSC
                      variant="fill"
                      text="Complete"
                      size="large"
                      color="green"
                      handleClick={() => finishStep(+item.id, documents)}
                    />
                  </Grid>
                </Grid>
              ) : (
                !item.completed && (
                  <Typography className={classes.AccordionSummaryTitleAgreed}>
                    Pending
                  </Typography>
                )
              )}

            {(item.completed && item.proveDocuments.length > 0 && item.proveDocuments.map(
              (doc: { link: string; title: string }) => (
                <div key={`${doc.link}`}>
                  <p className={classes.docName}>
                    <Link href={`${constants.AWS_URL}${doc.link}`} target="_blank" rel="noreferrer">
                      {doc.title}
                    </Link>
                  </p>
                </div>
              ),
            ))}

            {(item.completed && item.proveDocuments.length === 0 && (
              <Typography className={classes.AccordionSummaryTitleAgreed}>
                No files
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default PostTradeStepsCheck;
