import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import {
  Grid,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  IconButton,
  Button,
  Link,
  DialogActions,
  DialogTitle,
  Avatar,
  Input,
  TextField,
  Fab,
  MenuItem,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/Help";
// import ModalImage: any from 'react-modal-image';
import useStyles from "./Order.style";
import { Inventory } from "../../interfaces/inventory.interface";
import {
  createDealFromSellerStage1,
  createDealFromBuyer,
  createDealFromSellerStage2,
} from "../../store/slices/deals.slice";
import { RootState } from "../../store/slices";
import InventoryCardForDeal from "../Inventory/InventoryCardForDeal";
import { getRole, getBrokerRole } from "../../utils/getRole";
import { addNotification } from "../../store/slices/snackbar.slice";
import getLocalStorageData from "../../api/localStorage";
import ButtonSC from "../ButtonSC/ButtonSC";
import constants from "../../core/constants";
import { getDate } from "../../utils/getDate";
import { createOrderQuestion } from "../../store/slices/orderQuestion.slice";
import { InventoryItemTypeField } from "../../interfaces/inventoryTypes.interface";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { Stack } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { apiGetUserInfo } from "../../api/updateUserInfo";
import { createInventory } from "../../store/slices/inventory.slice";

interface Props {
  id: number;
  inventory?: Inventory;
  quantity: number;
  price: number;
  unitPrice: number;
  btnLabel: string;
  ownerAccountId: number;
  isLoading: boolean;
  type: string;
  dynamicFieldValue?: any;
  orderBuyFields?: any;
  dynamicColumnSelectedValueSellOrders?: any;
  state?: string | number;
  zipCode?: string | number | any;
  orderNumber?: string | number;
  uniqueNum?: number;
  isForwardType?: boolean;
  forwardInventory?: any;
}

const OrderCard: FC<Props> = ({
  id,
  inventory,
  price,
  quantity,
  unitPrice,
  btnLabel,
  ownerAccountId,
  isLoading,
  type,
  dynamicFieldValue,
  orderBuyFields,
  state,
  zipCode,
  orderNumber,
  uniqueNum,
  isForwardType,
  forwardInventory,
  // dynamicColumnSelectedValueSellOrders,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openQuestionsDialog, setOpenQuestionsDialog] = useState(false);

  const { t, i18n } = useTranslation();

  const [openFullSizeImageDialog, setOpenFullSizeImageDialog] = useState(false);

  const [openImageSrc, setOpenImageSrc] = useState("");

  const [seeMore, setSeeMore] = useState<boolean>(false);

  const { userRole } = useSelector((state: RootState) => state.auth);

  const sateAllINfo = useSelector((state: RootState) => state);

  // check role and owner of order before show modal dialog
  const setOpenQuestionsDialogCheck = () => {
    const role = getRole(userRole);
    const { accountId } = getLocalStorageData();

    // if owner of order and accountId of user are the same, error
    if (ownerAccountId === accountId) {
      return dispatch(
        addNotification({
          key: new Date().getTime() + Math.random(),
          message: "It's your order",
          options: { variant: "error" },
        })
      );
    } else if (role === "Unconfirmed") {
      return dispatch(
        addNotification({
          key: new Date().getTime() + Math.random(),
          message: "You do not have the appropriate permissions",
          options: { variant: "error" },
        })
      );
    }

    return setOpenQuestionsDialog(true);
  };

  const handleOpenFullSizeImageDialog = async (imgSrc: any) => {
    setOpenFullSizeImageDialog(true);
    setOpenImageSrc(imgSrc);
  };

  const handleSendQuestion = async (data: any) => {
    const key = new Date().getTime() + Math.random();
    const { text } = data;

    const { accountId, clientAccountId, token } = getLocalStorageData();

    await dispatch(
      createOrderQuestion(
        id,
        clientAccountId || accountId,
        sendAnswerText,
        token,
        key
      )
    );
    setOpenQuestionsDialog(false);
    setCurrentMessage("");
    setOpenConfirm(false);
  };

  const [currentMessage, setCurrentMessage] = useState<string>("");

  const currentHours = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  const [currencyHour, setCurrencyHour] = React.useState("");

  const handleChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyHour(event.target.value);
  };

  const currentMinutes = [
    { value: "00", label: "00" },
    { value: "05", label: "05" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "25", label: "25" },
    { value: "30", label: "30" },
    { value: "35", label: "35" },
    { value: "40", label: "40" },
    { value: "45", label: "45" },
    { value: "50", label: "50" },
    { value: "55", label: "55" },
  ];

  const [currencyMinute, setCurrencyMinute] = React.useState("");

  const handleChangeMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyMinute(event.target.value);
  };

  const currentAmPm = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
  ];

  const [currencyAmPm, setCurrencyAmPm] = React.useState("");

  const handleChangeAmPm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyAmPm(event.target.value);
  };

  const [calendar, setCalendar] = React.useState(new Date());

  let dataString = calendar.toString();
  const handleChangeCalendar = (newValue: Date) => {
    setCalendar(newValue);
    dataString = calendar.toString();
    dataString.substring(0, 15);
  };

  let sendAnswerText = currencyHour
    ? `${currentMessage} Seller suggests to meet in SmartDeal Room at: ${currencyHour}:${currencyMinute}${currencyAmPm} EST on ${dataString.substring(
        0,
        15
      )}. Please, confirm or suggest an alternative time and date. `
    : `${currentMessage}`;

  const renderDialogActionsBuyOrSellOrder = (
    typeOrder: string,
    ownerOrderAccountId: any
  ) => (
    <DialogActions>
      <Grid className={classes.ModalQuestionWrapper}>
        <Grid xs={12} container direction="column" spacing={2}>
          <Grid item xs={11} className={classes.messageInputWrapper}>
            <TextField
              label="Type your message here"
              name="message"
              multiline
              variant="outlined"
              id="message"
              fullWidth
              placeholder="Write message"
              onChange={({ target }) => setCurrentMessage(target.value)}
              value={currentMessage}
              inputProps={{ "data-testid": "chat-message-input" }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  handleSendQuestion(sendAnswerText);
                }
              }}
            />
            <Grid item xs={1} className={classes.sendButtonWrapper}>
              <Fab
                color="primary"
                aria-label="openChat"
                size="small"
                onClick={() => handleSendQuestion(sendAnswerText)}
              >
                <SendIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <p className={classes.InformMessage}>
            Please, refrain from sending email addresses and/or phone numbers.
            You may be blocked from MGTrader.
          </p>
        </Grid>

        {/* <Grid>
          <p className={classes.InformMeeting}>
            Schedule a meeting in the SmartDeal Room:
          </p>
        </Grid>

        <Grid className={classes.DateAndTimeWrapper}>

          <TextField
            id="select_currency_Hours"
            select
            label="Hour"
            value={currencyHour}
            onChange={handleChangeHours}
            size="small"
            className={classes.timeWrapper}
          >
            {currentHours.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="select_currency_Minutes"
            select
            label="Minutes"
            value={currencyMinute}
            onChange={handleChangeMinutes}
            size="small"
            className={classes.timeWrapper}
          >
            {currentMinutes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="select_currency_AMPM"
            select
            label="AM/PM"
            value={currencyAmPm}
            onChange={handleChangeAmPm}
            size="small"
            className={classes.timeWrapper}
          >
            {currentAmPm.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            id="outlined-read-only-input"
            // label="Read Only"
            defaultValue="EST"
            size="small"
            className={classes.estWrapper}
            InputProps={{
              readOnly: true,
            }}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="MM/dd/yyyy"
                value={calendar}
                //@ts-ignore
                onChange={handleChangeCalendar}
                size="small"
                renderInput={(params) =>
                  //@ts-ignore
                  <TextField {...params}
                    size="small"
                    className={classes.calendarWrapper}
                  />}
              />
            </Stack>
          </LocalizationProvider>
        </Grid> */}
      </Grid>
    </DialogActions>
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBuy = async () => {
    const key = new Date().getTime() + Math.random();
    const { accountId, clientAccountId, token } = getLocalStorageData();
    await dispatch(
      createDealFromBuyer(
        id,
        clientAccountId ? accountId : null,
        clientAccountId || accountId,
        token,
        key
      )
    );
    history.push("deals");
  };

  const handleSellOrBuy = async (action: string) => {
    let role = getRole(userRole);
    const { accountId, clientAccountId, token, userId } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();

    if (!userId) {
      return dispatch(
        addNotification({
          key: new Date().getTime() + Math.random(),
          message: (
            <div>
              {t("PleaseLoginAsSellerOrBuyer")}
              <a href="/signin" className={classes.linkForSignup}>
                Log In
              </a>

              <a href="/signup" className={classes.linkForSignup}>
                Sign Up
              </a>
            </div>
          ),
          options: { variant: "error" },
        })
      );
    }
    if (role === "Broker") role = getBrokerRole(userRole, clientAccountId);

    if (action === "Sell") {
      if (
        role === "Seller" ||
        (role === "Seller & Buyer" &&
          ownerAccountId !== (clientAccountId || accountId))
      ) {
        await dispatch(
          createDealFromSellerStage1(
            id,
            clientAccountId ? accountId : null,
            clientAccountId || accountId,
            token,
            key
          )
        );
        handleOpen();
      } else {
        dispatch(
          addNotification({
            key: new Date().getTime() + Math.random(),
            message: "You do not have the appropriate permissions",
            options: { variant: "error" },
          })
        );
      }
    }
    if (action === "Buy") {
      if (
        role === "Buyer" ||
        (role === "Seller & Buyer" &&
          ownerAccountId !== (clientAccountId || accountId))
      ) {
        setOpenConfirm(true);
      } else {
        dispatch(
          addNotification({
            key: new Date().getTime() + Math.random(),
            message: "You do not have the appropriate permissions",
            options: { variant: "error" },
          })
        );
      }
    }
    return null;
  };

  const { appropriateInventoryItems, needQuantity, chosenOrderId } =
    useSelector((state: RootState) => state.deals);

  const handleGetUserId = () => {
    const { userId } = getLocalStorageData();
    return userId;
  };

  const handleGetValueOfynamicField = (item: InventoryItemTypeField) => {
    if (item.typeOfField === "DATE")
      return (item.value && getDate(item.value as string)) || "";
    if (
      item.typeOfField === "NUMBER" &&
      item.format === "PERCENT" &&
      item.value
    )
      return `${item.value} %`;
    if (item.typeOfField === "NUMBER" && item.format === "DOLLAR" && item.value)
      return `$ ${item.value}`;
    if (item.typeOfField === "BOOLEAN")
      return item.value === "false" ? "No" : "Yes";
    return item.value || "";
  };

  const getField = (item: any) =>
    item.typeOfField !== "DOCUMENTS" && (
      <Grid container key={item.name}>
        <Grid item xs={6}>
          <Typography variant="body1" color="primary">
            {`${item.name.replace(/\/,/g, ".")}:`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">
            {handleGetValueOfynamicField(item)}
          </Typography>
        </Grid>
      </Grid>
    );

  const getDocumentsField = (item: any) =>
    item.typeOfField === "DOCUMENTS" &&
    item.proveDocuments &&
    item.proveDocuments.map((doc: { link: string; title: string }) => (
      <Grid container key={item.name}>
        <Grid item xs={6}>
          <Typography variant="body1" color="primary">
            {`${item.name.replace(/\/,/g, ".")}:`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" className={classes.docName}>
            <Link
              key={doc.link}
              href={`${constants.AWS_URL}${doc.link}`}
              target="_blank"
              rel="noreferrer"
            >
              {doc.title}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    ));

  const handleReturnDinamicValue = (field: any) => {
    if (field === "totalPrice")
      return `${price >= 1000 ? Math.round(price) : price.toFixed(2)}`;
    if (field?.typeOfField === "DATE" && field?.value)
      return getDate(field?.value) || "---";
    if (field?.typeOfField === "BOOLEAN")
      return field.value === "true" ? "Yes" : "No";
    return field?.value || "---";
  };

  const [zipCodeBuyer, setZipCodeBuyer] = useState("");
  const asyncSetZip = async () => {
    const { token, userId } = getLocalStorageData();
    if (userId && token) {
      const res: any = await dispatch(apiGetUserInfo(userId, token));
      res?.zip ? setZipCodeBuyer(res.zip) : setZipCodeBuyer("");
    }
  };
  useEffect(() => {
    asyncSetZip();
  }, []);

  const relocate = useCallback(async () => {
    console.log("!!!");
  }, []);

  const createForwardInventory = async () => {
    const { accountId, token, clientAccountId, userId } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    const typeId = inventory?.inventoryItemType?.id;

    const newForwardInventory = {
      ...forwardInventory,
      isForwardType,
      ["typeId"]: typeId,
    };

    const formData = new FormData();
    formData.append("inventory", JSON.stringify(newForwardInventory));

    await dispatch(createInventory(formData, relocate, accountId, token, key));
    await dispatch(
      createDealFromSellerStage1(
        id,
        clientAccountId ? accountId : null,
        clientAccountId || accountId,
        token,
        key
      )
    );
  };

  return (
    //Bids To Buy
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        item
        className={cx(classes.values, type === "sell" && classes.valuesSell)}
        direction={type === "buy" ? "row-reverse" : "row"}
      >
        <Grid
          container
          item
          xs={1}
          sm={1}
          alignContent="center"
          alignItems="center"
        >
          <ButtonSC
            variant="fill"
            text={btnLabel}
            size="large"
            type="submit"
            handleClick={() => handleSellOrBuy(btnLabel)}
            disabled={isLoading}
            color="green"
            styleClass={classes.btnWidth}
          />
        </Grid>
        <Grid
          container
          item
          xs={11}
          className={classes.cardItems}
          alignContent="center"
          alignItems="center"
          justifyContent={type === "buy" ? "flex-end" : "flex-start"}
        >
          {handleGetUserId() && (
            <>
              <Grid item xs={1} className={classes.valueItem}>
                <HelpIcon
                  className={classes.questionOrderIcon}
                  onClick={() => setOpenQuestionsDialogCheck()}
                />
              </Grid>
              <Grid item xs={2} className={classes.valueItem}>
                <form
                  action={`https://www.google.com/maps/dir/+USA+${zipCodeBuyer}/+USA+${zipCode}`}
                  target="_blank"
                >
                  <button className={classes.linkHref}>
                    {state} {zipCode?.length < 5 ? `0${zipCode}` : zipCode}
                  </button>
                </form>
              </Grid>
              <Grid item xs={2} className={classes.valueItem}>
                <Button
                  aria-label="info"
                  onClick={() => {
                    if (inventory) {
                      // setInventoryDocs(inventory.labAttestationDocs);
                      // setInventoryAttest(inventory.labAttest);
                      setOpenInfo(true);
                    }
                  }}
                  className={classes.infoButton}
                  classes={{
                    label: classes.labelBtn,
                  }}
                >
                  {inventory?.productPicture?.length ? (
                    <Avatar
                      variant="rounded"
                      alt="Avatar"
                      className={classes.picture}
                      src={
                        inventory.productPicture &&
                        inventory.productPicture[0] &&
                        `${constants.AWS_URL}${inventory.productPicture[0].link}`
                      }
                    />
                  ) : (
                    <div
                      className={
                        inventory && inventory.labAttest
                          ? classes.infoColorAttest
                          : classes.infoColorNoAttest
                      }
                    >
                      More info
                    </div>
                  )}
                </Button>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={handleGetUserId() ? 2 : 3}
            className={classes.valueItem}
          >
            {`${
              unitPrice >= 1000 ? Math.round(unitPrice) : unitPrice.toFixed(2)
            }`}
          </Grid>
          <Grid
            item
            xs={handleGetUserId() ? 2 : 3}
            className={classes.valueItem}
          >
            {`${Number.isInteger(quantity) ? quantity : quantity.toFixed(2)}`}
          </Grid>
          <Grid
            item
            xs={handleGetUserId() ? 2 : 3}
            md={3}
            className={classes.valueItem}
          >
            {handleReturnDinamicValue(dynamicFieldValue)}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      {appropriateInventoryItems && needQuantity && chosenOrderId && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll="paper"
          classes={{ paper: classes.dialogPaper }}
        >
          <Typography
            variant="h1"
            className={cx(classes.dialogTitle, classes.dialogTitlePadding)}
          >
            {isForwardType
              ? !!appropriateInventoryItems.length
                ? "Inventory Created"
                : "No Inventory Available"
              : "Choose inventory"}
          </Typography>
          {isForwardType ? (
            <DialogContent className={classes.dialogContent}>
              <Grid item className={classes.cardActions}>
                {!!appropriateInventoryItems.length &&
                  appropriateInventoryItems.map((inventoryItem) => (
                    <InventoryCardForDeal
                      key={inventoryItem.createdAt}
                      inventory={inventoryItem}
                      needQuantity={needQuantity}
                      chosenOrderId={chosenOrderId}
                    />
                  ))}
                {appropriateInventoryItems.length === 0 && (
                  <ButtonSC
                    variant="fill"
                    text="Create Inventory"
                    size="large"
                    color="green"
                    handleClick={() => createForwardInventory()}
                    disabled={isLoading}
                  />
                )}
              </Grid>
            </DialogContent>
          ) : (
            <DialogContent className={classes.dialogContent}>
              {appropriateInventoryItems.map((inventoryItem) => (
                <InventoryCardForDeal
                  key={inventoryItem.createdAt}
                  inventory={inventoryItem}
                  needQuantity={needQuantity}
                  chosenOrderId={chosenOrderId}
                />
              ))}
            </DialogContent>
          )}
        </Dialog>
      )}
      <Dialog
        open={openInfo}
        onClose={() => setOpenInfo(false)}
        scroll="paper"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>
          {type === "buy" ? "Inventory Sheet" : "Order Sheet"}
        </DialogTitle>
        <Divider />

        <IconButton
          aria-label="close"
          onClick={() => setOpenInfo(false)}
          className={classes.closeModalIcon}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          {type === "buy" && (
            <Grid container className={classes.dialogPadding40}>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{inventory?.title}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Order №:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {orderNumber && orderNumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Short Description:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {inventory?.shortDescription}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Long Description:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {inventory?.description && (
                  <>
                    <Typography
                      variant="body1"
                      className={!seeMore ? classes.longDescriptionHide : ""}
                    >
                      {inventory?.description}
                    </Typography>
                    {inventory?.description.length > 30 &&
                      (!seeMore ? (
                        <Button
                          className={classes.longDescriptionBtn}
                          onClick={() => setSeeMore(true)}
                        >
                          See more
                        </Button>
                      ) : (
                        <Button
                          className={classes.longDescriptionBtn}
                          onClick={() => setSeeMore(false)}
                        >
                          See less
                        </Button>
                      ))}
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Location:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {inventory?.locationState && inventory?.locationZip && (
                  <Typography variant="body1">
                    {`${inventory?.locationState}, ${
                      inventory?.locationZip.split(" ")[0]
                    }`}
                  </Typography>
                )}
              </Grid>
              {inventory &&
                inventory.inventoryItemType.inventoryItemTypeFieldsTypes
                  ?.length > 0 &&
                inventory?.inventoryItemType.inventoryItemTypeFieldsTypes?.map(
                  (item: any) => getField(item)
                )}
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Lab meets minimum standards:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {inventory?.labAttest ? "Yes" : "No"}
                </Typography>
                <Typography variant="body1">
                  <Link
                    href={`${constants.BASE_URL}media/Minimum_Laboratory_Testing_Standards.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See Requirements
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Lab Analysis:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {inventory?.labAttest && inventory?.labAttestationDocs ? (
                  inventory?.labAttestationDocs &&
                  inventory?.labAttestationDocs.map(
                    (doc: { link: string; title: string }) => (
                      <Typography variant="body1" key={doc.link}>
                        <Link
                          href={`${constants.AWS_URL}${doc.link}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click here to see Test Results
                        </Link>
                      </Typography>
                    )
                  )
                ) : (
                  <Typography variant="body1">Not Attested</Typography>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Display Image:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {inventory?.productPicture && (
                  <Avatar
                    onClick={() => {
                      if (inventory.productPicture[0]) {
                        handleOpenFullSizeImageDialog(
                          `${constants.AWS_URL}${inventory?.productPicture[0].link}`
                        );
                      }
                    }}
                    variant="rounded"
                    alt="Avatar"
                    className={classes.picture}
                    src={
                      inventory?.productPicture &&
                      inventory?.productPicture[0] &&
                      `${constants.AWS_URL}${inventory?.productPicture[0].link}`
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color="primary">
                  Other media:
                </Typography>
              </Grid>
              {inventory &&
                inventory.inventoryItemType.inventoryItemTypeFieldsTypes
                  ?.length > 0 &&
                inventory?.inventoryItemType.inventoryItemTypeFieldsTypes?.map(
                  (item: any) => getDocumentsField(item)
                )}
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Inventory Verified:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {inventory?.generalAttestationDate
                    ? getDate(inventory?.generalAttestationDate)
                    : "Not Attested"}
                </Typography>
              </Grid>
            </Grid>
          )}
          {type === "sell" && (
            <Grid container className={classes.dialogPadding40}>
              {orderBuyFields?.length > 0 &&
                orderBuyFields?.map((item: any) => getField(item))}
              {!orderBuyFields?.filter(
                (item: any) => item.typeOfField !== "DOCUMENTS"
              ).length && (
                <Typography variant="body1">No information</Typography>
              )}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      {openConfirm && (
        <Dialog open={openConfirm} className={classes.buyDialogWrapper}>
          {/* <DialogTitle>
            Are you sure?
          </DialogTitle> */}
          <Grid item xs={12} className={classes.closeButtonWrapper}>
            <Fab
              aria-label="openChat"
              size="small"
              onClick={() => setOpenConfirm(false)}
            >
              <CloseIcon />
            </Fab>
          </Grid>
          <DialogActions>
            <Grid xs={6}>
              <ButtonSC
                variant="fill"
                text="Deal Now"
                size="middle"
                color="green"
                handleClick={() => handleBuy()}
              />
            </Grid>
            {/* <ButtonSC
              variant="fill"
              text='No'
              size="large"
              color='green'
              handleClick={() => setOpenConfirm(false)}
            /> */}
          </DialogActions>
          <Grid>
            <p className={classes.InformMeeting}>
              Schedule a meeting in the SmartDeal Room:
            </p>
          </Grid>

          <Grid className={classes.DateAndTimeWrapper}>
            <TextField
              id="select_currency_Hours"
              select
              label="Hour"
              value={currencyHour}
              onChange={handleChangeHours}
              size="small"
              className={classes.timeWrapper}
            >
              {currentHours.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="select_currency_Minutes"
              select
              label="Minutes"
              value={currencyMinute}
              onChange={handleChangeMinutes}
              size="small"
              className={classes.timeWrapper}
            >
              {currentMinutes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="select_currency_AMPM"
              select
              label="AM/PM"
              value={currencyAmPm}
              onChange={handleChangeAmPm}
              size="small"
              className={classes.timeWrapper}
            >
              {currentAmPm.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="outlined-read-only-input"
              // label="Read Only"
              defaultValue="EST"
              size="small"
              className={classes.estWrapper}
              InputProps={{
                readOnly: true,
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Date desktop"
                  inputFormat="MM/dd/yyyy"
                  value={calendar}
                  //@ts-ignore
                  onChange={handleChangeCalendar}
                  size="small"
                  renderInput={(params) => (
                    //@ts-ignore
                    <TextField
                      {...params}
                      size="small"
                      className={classes.calendarWrapper}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
            <Grid item xs={1} className={classes.sendButtonWrapper}>
              <Fab
                color="primary"
                aria-label="openChat"
                size="small"
                onClick={() => handleSendQuestion(sendAnswerText)}
              >
                <SendIcon />
              </Fab>
            </Grid>
          </Grid>
        </Dialog>
      )}

      {/* dialog with questions */}
      <Dialog
        open={openQuestionsDialog}
        onClose={() => setOpenQuestionsDialog(false)}
        scroll="paper"
        classes={{ paper: classes.dialogPaperOrderQuestions }}
      >
        <DialogTitle>
          {`You may ask Buyer a question:`}
          <p className={classes.orderNumber}>
            {`Order # ${orderNumber ? orderNumber : ""}`}
          </p>
          <IconButton
            aria-label="close"
            onClick={() => setOpenQuestionsDialog(false)}
            className={classes.closeModalIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {renderDialogActionsBuyOrSellOrder(
          type,
          inventory && inventory.account
        )}
      </Dialog>

      {/* dialog with fullSize image */}
      <Dialog
        open={openFullSizeImageDialog}
        onClose={() => setOpenFullSizeImageDialog(false)}
        scroll="paper"
        classes={{ paper: classes.dialogPaperFullSizeImage }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenFullSizeImageDialog(false)}
          className={classes.closeModalIcon}
        >
          <CloseIcon />
        </IconButton>

        <img
          className={classes.fullSizeImageInsideDialog}
          src={openImageSrc}
          alt=""
        />
      </Dialog>
    </>
  );
};

export default OrderCard;
