import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  MuDialog: {
    padding: '0 16px 15px 16px',
  },
  leaveMessage: {
    maxWidth: 200,
  },
}));

export default useStyles;
