import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import MaterialTable, { Column } from "material-table";
import { RootState } from "../../store/slices";
import { deleteOrder, getAllOrders } from "../../store/slices/order.slice";
import { OrderMP } from "../../interfaces/order.interface";
import getLocalStorageData from "../../api/localStorage";
import TableContainer from "../../containers/TableContainer/TableContainer";
import { getDate } from "../../utils/getDate";

const ListOrdersForAdmin = () => {
  const dispatch = useDispatch();
  const [currentOrderListActive, setListOfOrdersActive] = useState<OrderMP[]>(
    []
  );
  const [currentOrderList, setListOfOrders] = useState<OrderMP[]>([]);
  const [cookies, setCookie] = useCookies([
    "rowListOrdersForAdmin",
    "rowListOrdersForAdminHistory",
  ]);

  const { listOfOrders } = useSelector((state: RootState) => state.order);

  const { listOfInventoryTypes } = useSelector(
    (state: RootState) => state.inventoryTypes
  );

  const [typeInventory, setTypeInventory] = useState<string>();
  const [inventoryTypeId, setInventoryTypeId] = useState<number>(1);

  useEffect(() => {
    const { token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    dispatch(getAllOrders(true, token, key, inventoryTypeId));
  }, [dispatch]);

  useEffect(() => {
    const userListOrdersActive = listOfOrders.filter(
      (item) =>
        item.orderStatus === "OPEN" || item.orderStatus === "IN PROGRESS"
    );
    setListOfOrdersActive(userListOrdersActive.map((item) => ({ ...item })));
    const userListOrders = listOfOrders.filter(
      (item) =>
        item.orderStatus !== "OPEN" && item.orderStatus !== "IN PROGRESS"
    );
    setListOfOrders(userListOrders.map((item) => ({ ...item })));
  }, [setListOfOrdersActive, setListOfOrders, listOfOrders]);

  const columns: Array<Column<OrderMP>> = [
    {
      title: "Status",
      field: "orderStatus",
    },
    {
      title: "Created",
      field: "createdAt",
      render: (rowData) => getDate(rowData.createdAt),
      customFilterAndSearch: (term, rowData) =>
        getDate(rowData.createdAt).indexOf(term) !== -1,
    },
    {
      title: "Owner",
      field: "ownerUserEmail",
    },
    {
      title: "Order Type",
      field: "orderType",
    },
    {
      title: "Inventory Type",
      field: "inventory.inventoryItemType.type",
    },
    {
      title: "Unit Price",
      field: "unitPrice",
      render: (rowData) =>
        `$ ${rowData.unitPrice.toFixed(2)}/ ${
          rowData.inventory && rowData.inventory.inventoryItemType.units
        }`,
      customFilterAndSearch: (term, rowData) =>
        `$ ${rowData.unitPrice.toFixed(2)}/ ${
          rowData.inventory && rowData.inventory.inventoryItemType.units
        }`
          .toLowerCase()
          .indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: "Amount",
      field: "quantity",
      render: (rowData) =>
        `${
          Number.isInteger(rowData.quantity)
            ? rowData.quantity
            : rowData.quantity.toFixed(2)
        } ${rowData.inventory && rowData.inventory.inventoryItemType.units}`,
      customFilterAndSearch: (term, rowData) =>
        `${
          Number.isInteger(rowData.quantity)
            ? rowData.quantity
            : rowData.quantity.toFixed(2)
        } ${rowData.inventory && rowData.inventory.inventoryItemType.units}`
          .toLowerCase()
          .indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: "Total Price",
      field: "price",
      render: (rowData) => `$ ${rowData.price.toFixed(2)}`,
      customFilterAndSearch: (term, rowData) =>
        `$ ${rowData.price.toFixed(2)}`.indexOf(term) !== -1,
    },
    {
      title: "Broker",
      field: "brokerUserName",
    },
  ];

  return (
    <TableContainer>
      <MaterialTable
        title="Active Orders"
        columns={columns}
        data={currentOrderListActive}
        options={{
          searchFieldStyle: {
            borderBottom: "none",
          },
          pageSize: +cookies.rowListOrdersForAdmin || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) =>
          setCookie("rowListOrdersForAdmin", pageSize, { path: "/" })
        }
        actions={[
          (rowData: OrderMP) => ({
            icon: "cancel",
            iconProps:
              rowData.orderStatus === "OPEN"
                ? { color: "error" }
                : { color: "secondary" },
            tooltip: "Cancel",
            onClick: () => {
              const { token } = getLocalStorageData();
              const key = new Date().getTime() + Math.random();
              dispatch(deleteOrder(rowData.id, token, key));
            },
          }),
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">No records to display</p>
            ),
          },
        }}
      />
      <br />
      <MaterialTable
        title="History"
        columns={columns}
        data={currentOrderList}
        options={{
          searchFieldStyle: {
            borderBottom: "none",
          },
          pageSize: +cookies.rowListOrdersForAdminHistory || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) =>
          setCookie("rowListOrdersForAdminHistory", pageSize, { path: "/" })
        }
        actions={[
          (rowData: OrderMP) => ({
            icon: "cancel",
            iconProps:
              rowData.orderStatus === "OPEN"
                ? { color: "error" }
                : { color: "secondary" },
            tooltip: "Cancel",
            onClick: () => {
              const { token } = getLocalStorageData();
              const key = new Date().getTime() + Math.random();
              dispatch(deleteOrder(rowData.id, token, key));
            },
          }),
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">No records to display</p>
            ),
          },
        }}
      />
    </TableContainer>
  );
};

export default ListOrdersForAdmin;
