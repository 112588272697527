/* eslint-disable arrow-body-style */
import React, {
  FC,
} from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogClassKey,
  GridSize,
  GridSpacing,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import 'react-phone-input-2/lib/material.css';
import ButtonSC from '../ButtonSC/ButtonSC';
import useStyles from './ConfirmationDialog.style';

interface Props {
  open: boolean;
  title: string;
  scroll?: 'paper' | 'body' | undefined;
  disableBackdropClick?: boolean | undefined;
  labelledby?: string | undefined;
  describedby?: string | undefined;
  stylesDialog?: Partial<ClassNameMap<DialogClassKey>>;
  stylesDialogContent?: Partial<ClassNameMap<DialogClassKey>>;
  dialogTitleId?: string;
  color: 'green' | 'red';
  description: string | React.ReactNode;
  acceptButtonText: string;
  cancelButtonText: string;
  gridSapcing?: GridSpacing | undefined;
  gridSize?: boolean | GridSize | undefined;
  onAcceptButtonClicked: () => void;
  onCancelButtonClicked: () => void;
}

const ConfirmationDialog: FC<Props> = ({
  open, title, color, description, acceptButtonText,
  cancelButtonText, onAcceptButtonClicked, onCancelButtonClicked,
  describedby, labelledby, stylesDialog, stylesDialogContent,
  gridSapcing, gridSize, dialogTitleId, scroll, disableBackdropClick,
}) => {
  const classes = useStyles();

  return (

    <Dialog
      open={open}
      scroll={scroll}
      disableBackdropClick={disableBackdropClick}
      aria-labelledby={describedby}
      aria-describedby={labelledby}
      classes={stylesDialog || {}}
    >
      <DialogTitle
        id={dialogTitleId}
      >
        {title}
      </DialogTitle>
      <DialogContent
        classes={stylesDialogContent || {}}
      >
        {/* <DialogContentText>
          {description}
        </DialogContentText> */}
        {description}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={gridSapcing} justifyContent="space-between" className={classes.MuDialog}>
          <Grid item xs={gridSize}>
            <ButtonSC
              variant="outline"
              text={acceptButtonText}
              size="large"
              color={color}
              handleClick={() => {
                onAcceptButtonClicked();
              }}
            />
          </Grid>
          <Grid item xs={gridSize}>
            <ButtonSC
              variant="fill"
              text={cancelButtonText}
              size="large"
              color={color}
              handleClick={() => onCancelButtonClicked()}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>

  );
};

export default ConfirmationDialog;
