import React,
{
  FC,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Paper,
  FormControl,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  InputLabel,
  DialogContentText,
} from '@material-ui/core';
import { Location } from 'history';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import useStyles from './Inventory.style';
import { updateInventoryType, createInventoryType } from '../../store/slices/inventoryTypes.slice';
import getLocalStorageData from '../../api/localStorage';
import ButtonSC from '../ButtonSC/ButtonSC';
import { RootState } from '../../store/slices';
import { addNotification } from '../../store/slices/snackbar.slice';
import CheckboxSC from '../CheckboxSC/CheckboxSC';
import { InventoryItemTypeField } from '../../interfaces/inventoryTypes.interface';
import { NumberFormatCustom, NumberFormatInteger } from '../NumberFormat/NumberFormat';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import ConfirmationWithoutSaving from '../ConfirmationDialog/ConfirmationWithoutSaving/ConfirmationwithoutSaving';
import translationEN from '../../locales/en/translation.json';

export const CreateInventoryType: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    handleSubmit,
    errors,
    control,
    watch,
    formState,
  } = useForm();
  const numberFormat = [{ value: 'NUMBER', label: 'xx0.00' }, { value: 'DOLLAR', label: '$xx0.00' }, { value: 'PERCENT', label: 'xx0.00%' }];
  const booleanFormat = [{ value: 'CHECKBOX', label: 'CHECKBOX' }, { value: 'DROPDOWN', label: 'DROPDOWN' }];

  const [choice, setChoice] = useState<string>('');
  const [open, setOpen] = useState<number | boolean>(false);

  const [fields, setFields] = useState<InventoryItemTypeField[]>([]);

  const location: any = useLocation();
  const type = location.state && location.state.data;

  const [openSaveChangesModal, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [changes, setChanges] = useState<boolean>(false);

  const {
    isLoading,
  } = useSelector(
    (state: RootState) => state.inventoryTypes,
  );

  const closeModal = useCallback(
    () => {
      setModalVisible(false);
    }, [],
  );

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location) => {
      if (!confirmedNavigation && changes) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, changes],
  );

  const handleConfirmNavigationClick = useCallback(
    () => {
      setModalVisible(false);
      setConfirmedNavigation(true);
    },
    [],
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    if (changes) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [confirmedNavigation, lastLocation, history, changes]);

  useEffect(() => {
    if (type) {
      setChanges(JSON.stringify(type.inventoryItemTypeFieldsTypes) !== JSON.stringify(fields)
        || formState.isDirty);
    } else {
      setChanges(fields.length > 0 || formState.isDirty);
    }
  }, [formState, fields, type]);

  useEffect(() => {
    setFields(type?.inventoryItemTypeFieldsTypes || []);
  }, [type, setFields]);

  const handleCheckFields = useCallback(
    () => {
      const key = new Date().getTime() + Math.random();
      const errorsCount: string[] = [];

      fields.map((item) => {
        if (
          !item.name ||
          !item.typeOfField
        ) {
          errorsCount.push('Required field');
        }
        if ((item.typeOfField === 'NUMBER' || item.typeOfField === 'BOOLEAN') && !item.format) {
          errorsCount.push('Required format');
        }
        if (fields.some(
          (field: InventoryItemTypeField) => field.uiId !== item.uiId &&
            field.order === item.order,
        )) {
          errorsCount.push('Duplicate values in order numbers');
        }
        if (item.order < 1) {
          errorsCount.push('Positive value required');
        }
        // eslint-disable-next-line no-plusplus
        if (fields.some(
          (field: InventoryItemTypeField) => field.uiId !== item.uiId &&
            field.name === item.name,
        )) {
          errorsCount.push('Duplicate values');
        }
        if (item.typeOfField === 'NUMBER' && item.format === 'PERCENT' && item.defaultValue > 100) {
          errorsCount.push('For Percent format value must be within 100');
        }
        if (item.typeOfField === 'NUMBER' && +item.defaultValue < 0) {
          errorsCount.push('NUMBER must be greater or equal 0');
        }
        if (item.typeOfField === 'DATE' && +item.defaultValue < 0) {
          errorsCount.push('NUMBER of days must be greater or equal 0');
        }
        if (item.typeOfField === 'DATE' && +item.defaultValue > 100) {
          errorsCount.push('NUMBER of days must be less or equal 100');
        }
        if (item.typeOfField === 'CHOICE' && !item.arrayOfChoices) {
          errorsCount.push('Choice type must have at least 2 values');
        }
        if (item.typeOfField === 'CHOICE' && item.arrayOfChoices && item.arrayOfChoices.length < 2) {
          errorsCount.push('Choice type must have at least 2 values');
        }
        if (item.typeOfField === 'CHOICE' && !item.arrayOfChoices?.find((ch) => ch.toUpperCase() === (item.defaultValue as string).toUpperCase()) && item.defaultValue !== '') {
          errorsCount.push('Default value should be one of specified values');
        }
        return null;
      });

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= fields.length; i++) {
        if (fields.find((field) => +field.order === +i) === undefined) {
          errorsCount.push(`Missed step ${i}`);
        }
      }

      if (errorsCount.length) {
        dispatch(addNotification({
          key,
          message: errorsCount[0],
          options: { variant: 'error' },
        }));
        return true;
      }

      return false;
    },
    [fields, dispatch],
  );

  const relocate = useCallback(
    () => {
      setChanges(false);
      history.push('/inventoryTypes');
    },
    [history],
  );

  const handleCreateInventory = useCallback(
    async (values) => {
      const { userId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      const {
        feeForSeller,
        feeForBuyer,
        feeType,
        ...data
      } = values;
      if (!handleCheckFields()) {
        const newFields = fields.map((item) => {
          if (item.typeOfField === 'CHOICE' && item.defaultValue) {
            return {
              ...item,
              defaultValue: item.arrayOfChoices?.find((ch) => ch.toUpperCase() === (item.defaultValue as string).toUpperCase()) || '',
            };
          }
          return item;
        });
        dispatch(createInventoryType(
          {
            ...data,
            feeType,
            systemCommission: {
              feeType,
              feeForSeller,
              feeForBuyer,
            },
            fields: newFields,
          },
          relocate,
          userId,
          token,
          key,
        ));
      }
    },
    [
      dispatch,
      handleCheckFields,
      fields,
      relocate,
    ],
  );

  const handleUpdateInventory = useCallback(
    (values) => {
      const { userId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      const {
        feeForSeller,
        feeForBuyer,
        feeType,
        ...data
      } = values;
      if (!handleCheckFields()) {
        const newFields = fields.map((item) => {
          if (item.typeOfField === 'CHOICE' && item.defaultValue) {
            return {
              ...item,
              defaultValue: item.arrayOfChoices?.find((ch) => ch.toUpperCase() === (item.defaultValue as string).toUpperCase()) || '',
            };
          }
          return item;
        });
        dispatch(updateInventoryType(
          {
            ...data,
            id: type.id,
            feeType,
            systemCommission: {
              feeType,
              feeForSeller,
              feeForBuyer,
            },
            fields: newFields,
          },
          relocate,
          userId,
          token,
          key,
        ));
      }
    },
    [
      dispatch,
      handleCheckFields,
      type,
      fields,
      relocate,
    ],
  );

  const handleAddField = useCallback(
    () => {
      const listOfFields = JSON.parse(JSON.stringify(fields));
      listOfFields.push({
        uiId: new Date().getTime() + Math.random(),
        name: '',
        typeOfField: '',
        format: '',
        required: false,
        order: listOfFields.length + 1,
      });
      setFields(listOfFields);
    },
    [fields, setFields],
  );

  const handleAddChoice = useCallback(
    (id: number) => {
      const listOfFields = JSON.parse(JSON.stringify(fields));
      const itemFieldIndex =
        listOfFields.findIndex((field: InventoryItemTypeField) => field.uiId === id);
      const listChoices =
        listOfFields[itemFieldIndex].arrayOfChoices ?
          listOfFields[itemFieldIndex].arrayOfChoices : [];
      const valueChoice = listChoices
        .filter((item: string) => item.toUpperCase() === choice.toUpperCase());
      if (valueChoice.length) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: translationEN.ValueAlreadyExists,
          options: { variant: 'error' },
        }));
      } else {
        const newArrayOfChoices = listChoices.slice();
        newArrayOfChoices.push(choice);
        listOfFields[itemFieldIndex].arrayOfChoices = newArrayOfChoices;
      }
      setFields(listOfFields);
    },
    [dispatch, choice, setFields, fields],
  );

  const handleDeleteChoice = useCallback(
    (id, value) => {
      const listOfFields = JSON.parse(JSON.stringify(fields));
      const itemFieldIndex =
        listOfFields.findIndex((field: InventoryItemTypeField) => field.uiId === id);
      const newArrayOfChoices =
        listOfFields[itemFieldIndex].arrayOfChoices.filter((obj: string) => obj !== value);
      listOfFields[itemFieldIndex].arrayOfChoices = newArrayOfChoices;
      setFields(listOfFields);
    },
    [setFields, fields],
  );

  const handleDelField = useCallback(
    (id: number) => {
      setFields(fields.filter((item) => item.uiId !== id));
    },
    [setFields, fields],
  );

  const capitalize = (str: string, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match: any) => match.toUpperCase());

  const handleClickOpen = (id: number) => {
    setOpen(id);
  };

  const handleClose = useCallback(
    (save: boolean) => {
      if (save && choice) handleAddChoice(open as number);
      setChoice('');
      setOpen(false);
    },
    [open, handleAddChoice, setChoice, setOpen, choice],
  );

  const handleChangeField = useCallback(
    (id: number, field: string, value: string | boolean | unknown) => {
      const listOfFields = JSON.parse(JSON.stringify(fields));
      const itemFieldIndex =
        listOfFields.findIndex((step: InventoryItemTypeField) => step.uiId === id);
      if (field === 'order' && value) {
        listOfFields[itemFieldIndex][field] = +(value as string);
      } else if (field === 'name') {
        const capitalizeName = capitalize(value as string);
        // listOfFields[itemFieldIndex][field] = capitalizeName.replace(/\./g, '/,');
        listOfFields[itemFieldIndex][field] = capitalizeName;
      } else if (field === 'typeOfField') {
        listOfFields[itemFieldIndex].defaultValue = '';
        listOfFields[itemFieldIndex].format = '';
        listOfFields[itemFieldIndex].typeOfField = value;
      } else {
        listOfFields[itemFieldIndex][field] = value;
      }
      setFields(listOfFields);
    },
    [setFields, fields],
  );

  return (
    <Paper className={classes.paperType}>
      <Prompt when={changes} message={handleBlockedNavigation} />
      <form
        className={classes.formType}
        onSubmit={handleSubmit(
          type ?
            handleUpdateInventory :
            handleCreateInventory,
        )}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1">
              {type ? 'Update' : 'Create'}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid xs={8} md={3} item className={classes.marginRight16}>
              <Controller
                name="order"
                control={control}
                defaultValue={type ? type.order : ''}
                render={({ onChange, value, name }) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    error={!!errors.type?.message}
                    name={name}
                    value={value}
                    onChange={({ target }) => {
                      onChange(target.value);
                    }}
                    onBlur={({ target }) => {
                      onChange(target.value.trim());
                    }}
                    label="Order*"
                  />
                )}
                rules={{
                  required: 'Required field',
                }}
              />
              {errors.order &&
                <Typography variant="caption" color="error">{errors.order.message}</Typography>}
            </Grid>
            <Grid item>
              <Controller
                name="active"
                value="primary"
                control={control}
                defaultValue={type ? type.active : false}
                render={({ onChange, name }) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name={name}
                        checked={watch().active || false}
                        checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                        icon={<span className={classes.icon} />}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    )}
                    label="Active"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="instantCheckout"
                value="primary"
                control={control}
                defaultValue={type ? type.instantCheckout : false}
                render={({ onChange, name }) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name={name}
                        checked={watch().instantCheckout || false}
                        checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                        icon={<span className={classes.icon} />}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    )}
                    label="Instant Checkout"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="forwardContract"
                value="primary"
                control={control}
                defaultValue={type ? type.forwardContract : false}
                render={({ onChange, name }) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name={name}
                        checked={watch().forwardContract || false}
                        checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                        icon={<span className={classes.icon} />}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    )}
                    label="Open Market"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} className={classes.fieldsContainer}>
            <Grid container item xs={12} spacing={2} >
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <Controller
                  name="type"
                  control={control}
                  defaultValue={type ? type.type : ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.type?.message}
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value);
                      }}
                      onBlur={({ target }) => {
                        onChange(target.value.trim());
                      }}
                      label="Type*"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                  }}
                />
                {errors.type &&
                  <Typography variant="caption" color="error">{errors.type.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <Controller
                  name="units"
                  control={control}
                  defaultValue={type ? type.units : ''}
                  render={({ onChange, value, name }) => (
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel error={!!errors.units?.message}>Units*</InputLabel>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        onChange={({ target }) => {
                          onChange(target.value);
                        }}
                        value={value}
                        name={name}
                        error={!!errors.units?.message}
                        label="Units*"
                      >
                        {!type && (
                          <MenuItem value="" disabled>
                            <em>None</em>
                          </MenuItem>
                        )}
                        <MenuItem value="lb">lb</MenuItem>
                        <MenuItem value="kg">kg</MenuItem>
                        <MenuItem value="liter">liter</MenuItem>
                        <MenuItem value="ton">ton</MenuItem>
                        <MenuItem value="gallon">gallon</MenuItem>
                        <MenuItem value="barrel">barrel</MenuItem>
                        <MenuItem value="ounce">ounce (oz)</MenuItem>
                        <MenuItem value="grams">grams</MenuItem>
                        <MenuItem value="bushels">bushels</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  rules={{
                    required: 'Required field',
                  }}
                />
                {errors.units &&
                  <Typography variant="caption" color="error">{errors.units.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <Controller
                  name="priceIncrement"
                  control={control}
                  defaultValue={type ? type.priceIncrement : ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value ? +target.value : '');
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        inputProps: {
                          name: 'priceIncrement',
                        },
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.priceIncrement?.message}
                      label="Price Increment*"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    validate: {
                      positive: (value) => parseFloat(value) > 0 || 'Should be greater than 0',
                    },
                  }}
                />
                {errors.priceIncrement &&
                  <Typography variant="caption" color="error">{errors.priceIncrement.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <Controller
                  name="minTradeSize"
                  control={control}
                  defaultValue={type ? type.minTradeSize : ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value ? +target.value : '');
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        inputProps: {
                          name: 'minTradeSize',
                        },
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.minTradeSize?.message}
                      label="Min Trade Size*"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    validate: {
                      positive: (value) => parseFloat(value) > 0 || 'Should be greater than 0',
                    },
                  }}
                />
                {errors.minTradeSize &&
                  <Typography variant="caption" color="error">{errors.minTradeSize.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <Controller
                  name="maxTradeSize"
                  control={control}
                  defaultValue={type ? type.maxTradeSize : ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value ? +target.value : '');
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        inputProps: {
                          name: 'maxTradeSize',
                        },
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.maxTradeSize?.message}
                      label="Max Trade Size"
                    />
                  )}
                  rules={{
                    validate: {
                      positive: (value) => (value ? (parseFloat(value) > 0 || 'Should be greater than 0') : true),
                      greaterMin: (value) => (value ? (+watch().minTradeSize < +value || 'Max must be > Min') : true),
                    },
                  }}
                />
                {errors.maxTradeSize &&
                  <Typography variant="caption" color="error">{errors.maxTradeSize.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <Controller
                  name="tradeSizeIncrement"
                  control={control}
                  defaultValue={type ? type.tradeSizeIncrement : ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value ? +target.value : '');
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        inputProps: {
                          name: 'tradeSizeIncrement',
                        },
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.tradeSizeIncrement?.message}
                      label="Trade Size Increment*"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    validate: {
                      positive: (value) => parseFloat(value) > 0 || 'Should be greater than 0',
                      greaterMin: (value) => {
                        if (watch().maxTradeSize) {
                          return (watch().minTradeSize && watch().maxTradeSize && ((+watch().maxTradeSize - +watch().minTradeSize) > +value)) || 'Increment must be less than difference between Min and Max Values';
                        }
                        return true;
                      },
                    },
                  }}
                />
                {errors.tradeSizeIncrement &&
                  <Typography variant="caption" color="error">{errors.tradeSizeIncrement.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.label}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <Controller
                    name="feeType"
                    control={control}
                    defaultValue={type ? type.systemCommission.feeType : ''}
                    render={({ onChange, value, name }) => (
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel error={!!errors.feeType?.message}>
                          Service Fee Type*
                        </InputLabel>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          onChange={({ target }) => {
                            onChange(target.value);
                          }}
                          value={value}
                          name={name}
                          error={!!errors.feeType?.message}
                          label="Service Fee Type*"
                        >
                          {!type && (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                          <MenuItem value="PERCENT">PERCENT</MenuItem>
                          <MenuItem value="FIXED DOLLAR AMOUNT PER DEAL">FIXED DOLLAR AMOUNT PER DEAL</MenuItem>
                          <MenuItem value="FIXED DOLLAR AMOUNT PER LOT">FIXED DOLLAR AMOUNT PER LOT</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    rules={{
                      required: 'Required field',
                    }}
                  />
                </FormControl>
                {errors.feeType &&
                  <Typography variant="caption" color="error">{errors.feeType.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.label}>
                <Controller
                  name="feeForSeller"
                  control={control}
                  defaultValue={type ? type.systemCommission.feeForSeller : ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value ? +target.value : '');
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        inputProps: {
                          name: 'feeForSeller',
                        },
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.feeForSeller?.message}
                      label="Seller Service Fee*"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    validate: {
                      positive: (value) => parseFloat(value) >= 0 || 'Should be greater than 0',
                      percent: (value) => {
                        if (watch().feeType === 'PERCENT') {
                          if (value <= 100) {
                            return true;
                          }
                          return 'For Percent format value must be within 100';
                        }
                        return true;
                      },
                    },
                  }}
                />
                {errors.feeForSeller &&
                  <Typography variant="caption" color="error">{errors.feeForSeller.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.label}>
                <Controller
                  name="feeForBuyer"
                  control={control}
                  defaultValue={type ? type.systemCommission.feeForBuyer : ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value ? +target.value : '');
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        inputProps: {
                          name: 'feeForBuyer',
                        },
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.feeForBuyer?.message}
                      label="Buyer Service Fee*"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    validate: {
                      positive: (value) => parseFloat(value) >= 0 || 'Should be greater than 0',
                      percent: (value) => {
                        if (watch().feeType === 'PERCENT') {
                          if (value <= 100) {
                            return true;
                          }
                          return 'For Percent format value must be within 100';
                        }
                        return true;
                      },
                    },
                  }}
                />
                {errors.feeForBuyer &&
                  <Typography variant="caption" color="error">{errors.feeForBuyer.message}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.label}>
                <Controller
                  name="needDocumentsDescription"
                  control={control}
                  defaultValue={type ? type.needDocumentsDescription : ''}
                  render={({ onChange, value, name }) => (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.needDocumentsDescription?.message}
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value);
                      }}
                      onBlur={({ target }) => {
                        onChange(target.value.trim());
                      }}
                      label="Required Document List*"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                  }}
                />
              </Grid>
              {errors.needDocumentsDescription &&
                <Typography variant="caption" color="error">{errors.needDocumentsDescription.message}</Typography>}
            </Grid>
          </Grid>

          <Grid container item xs={12} alignContent="flex-start">
            <Grid container className={classes.justefyCenter}>
              <Grid item>
                <Typography variant="h2" > Dynamic Data Elements</Typography>
              </Grid>
              <Grid item>
                <IconButton aria-label="add" onClick={handleAddField} className={cx(classes.padding5, classes.marginLeft40)}>
                  <AddBoxIcon fontSize="large" style={{ color: '#87BC14' }} />
                </IconButton>
              </Grid>
            </Grid>
            {fields.slice().sort((a, b) => a.order - b.order).map((item) => (
              <Grid container xs={12} sm={12} md={12} key={item.uiId} spacing={2} className={classes.marginBottom10}>
                <Grid item xs={4} sm={2} md={1}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={item.order}
                    onChange={({ target }) => handleChangeField(item.uiId, 'order', target.value)}
                    error={
                      !item.order ||
                      fields.some((field: InventoryItemTypeField) => field.uiId !== item.uiId &&
                        field.order === item.order) ||
                      item.order < 1
                    }
                    helperText={
                      (!item.order && 'Required') ||
                      (fields.some((field: InventoryItemTypeField) => field.uiId !== item.uiId && field.order === item.order) && 'Duplicate') ||
                      (item.order < 1 && 'Positive value required')
                    }
                    type="number"
                    label="No.*"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                {/* <Controller
                  name="email"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <TextFieldProfile
                      name={name}
                      value={value}
                      label="Email*"
                      error={!!errors.email?.message}
                      handleChange={(data) => {
                        onChange(data);
                      }}
                      margin="normal"
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    pattern: {
                      value: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i,
                      message: 'Incorrect value',
                    },
                  }}
                /> */}
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    // value={(item.name).replace(/\/,/g, '.')}
                    value={(item.name)}
                    onChange={({ target }) => handleChangeField(item.uiId, 'name', target.value)}
                    onBlur={({ target }) => {
                      handleChangeField(item.uiId, 'name', target.value.trim());
                    }}
                    label="Name*"
                    error={
                      !item.name ||
                      fields.some((field: InventoryItemTypeField) => field.uiId !== item.uiId &&
                        field.name === item.name)
                    }
                    helperText={
                      (!item.name && 'Required') ||
                      (fields.some((field: InventoryItemTypeField) => field.uiId !== item.uiId && field.name === item.name) && 'Duplicate')
                      }
                  />
                  
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl variant="outlined" size="small" fullWidth error={!item.typeOfField}>
                    <InputLabel>Data Type*</InputLabel>
                    <Select
                      label="Data Type*"
                      name={`${item.uiId} ${item.typeOfField} ${item.name} type`}
                      className={classes.typeSelect}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={item.typeOfField}
                      onChange={({ target }) => handleChangeField(item.uiId, 'typeOfField', target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="NUMBER">NUMBER</MenuItem>
                      <MenuItem value="STRING">STRING</MenuItem>
                      <MenuItem value="BOOLEAN">BOOLEAN</MenuItem>
                      <MenuItem value="DATE">DATE</MenuItem>
                      <MenuItem value="CHOICE">CHOICE</MenuItem>
                      <MenuItem value="DOCUMENTS">DOCUMENTS</MenuItem>
                    </Select>
                    {!item.typeOfField && <FormHelperText>Required</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" size="small" fullWidth error={(item.typeOfField === 'NUMBER' || item.typeOfField === 'BOOLEAN') && !item.format}>
                    <InputLabel>Format</InputLabel>
                    <Select
                      label="Format"
                      className={classes.typeSelect}
                      disabled={item.typeOfField !== 'NUMBER' && item.typeOfField !== 'BOOLEAN'}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      name={`${item.uiId} ${item.typeOfField} ${item.name} format`}
                      onChange={({ target }) => handleChangeField(item.uiId, 'format', target.value)}
                      value={item.format}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {item.typeOfField === 'NUMBER' && numberFormat.map((format) => <MenuItem value={format.value} key={format.value}>{format.label}</MenuItem>)}
                      {item.typeOfField === 'BOOLEAN' && booleanFormat.map((format) => <MenuItem value={format.value} key={format.value}>{format.label}</MenuItem>)}
                    </Select>
                    {!item.format && (item.typeOfField === 'NUMBER' || item.typeOfField === 'BOOLEAN') && <FormHelperText>Required</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                  {item.typeOfField === 'NUMBER' && (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={item.defaultValue || ''}
                      onChange={({ target }) => handleChangeField(item.uiId, 'defaultValue', target.value)}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      label="Default"
                      InputLabelProps={{
                        style: {
                          display: 'inline-block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          margin: 0,
                          width: '85%',
                        },
                      }}
                      error={
                        (item.typeOfField === 'NUMBER' && item.format === 'PERCENT' && item.defaultValue > 100) ||
                        (item.typeOfField === 'NUMBER' && +item.defaultValue < 0)
                      }
                      helperText={
                        (item.typeOfField === 'NUMBER' && item.format === 'PERCENT' && item.defaultValue > 100 && 'For Percent format value must be within 100') ||
                        (item.typeOfField === 'NUMBER' && +item.defaultValue < 0 && 'NUMBER must be greater or equal 0')
                      }
                    />
                  )}
                  {item.typeOfField === 'DATE' && (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={item.defaultValue || ''}
                      onChange={({ target }) => handleChangeField(item.uiId, 'defaultValue', target.value)}
                      InputProps={{
                        inputComponent: NumberFormatInteger as any,
                      }}
                      label="Default number of days to add"
                      InputLabelProps={{
                        style: {
                          display: 'inline-block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          margin: 0,
                          width: '85%',
                        },
                      }}
                      error={
                        (item.typeOfField === 'DATE' && +item.defaultValue < 0) ||
                        (item.typeOfField === 'DATE' && +item.defaultValue > 100)
                      }
                      helperText={
                        (item.typeOfField === 'DATE' && +item.defaultValue < 0 && 'NUMBER of days must be greater or equal 0') ||
                        (item.typeOfField === 'DATE' && +item.defaultValue > 100 && 'NUMBER of days must be less or equal 100')
                      }
                    />
                  )}
                  {(item.typeOfField === 'STRING' || item.typeOfField === 'CHOICE' || item.typeOfField === '') && (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={item.defaultValue || ''}
                      onChange={({ target }) => handleChangeField(item.uiId, 'defaultValue', target.value)}
                      onBlur={({ target }) => {
                        handleChangeField(item.uiId, 'defaultValue', target.value.trim());
                      }}
                      label="Default"
                      error={
                        (item.typeOfField === 'CHOICE' && !item.arrayOfChoices?.find((ch) => ch.toUpperCase() === (item.defaultValue as string).toUpperCase()) && item.defaultValue !== '')
                      }
                      helperText={
                        (item.typeOfField === 'CHOICE' && !item.arrayOfChoices?.find((ch) => ch.toUpperCase() === (item.defaultValue as string).toUpperCase()) && item.defaultValue !== '' && 'Default value should be one of specified values')
                      }
                    />
                  )}
                  {item.typeOfField === 'BOOLEAN' && (
                    <FormControlLabel
                      control={(
                        <CheckboxSC
                          text=""
                          checked={(typeof item.defaultValue) === 'string' ? (item.defaultValue === 'true' && true) || false : item.defaultValue as boolean}
                          onChange={(value) => {
                            handleChangeField(item.uiId, 'defaultValue', value);
                            return value;
                          }}
                        />
                      )}
                      label="Default"
                    />
                  )}
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                  <FormControlLabel
                    control={(
                      <CheckboxSC
                        text=""
                        name={`${item.name} required`}
                        checked={item.required}
                        onChange={(value) => handleChangeField(item.uiId, 'required', value)}
                      />
                    )}
                    label="Required"
                  />
                </Grid>
                <Grid item xs={4} sm={2} md={1}>
                  <IconButton aria-label="add" onClick={() => handleDelField(item.uiId)} style={{ padding: '0 12px' }}>
                    <DeleteIcon fontSize="large" />
                  </IconButton>
                </Grid>
                {
                  item.typeOfField === 'CHOICE' && (
                    <Grid container item alignItems="center">
                      <Typography className={classes.label}>
                        Values
                      </Typography>
                      <IconButton
                        onClick={() => handleClickOpen(item.uiId)}
                        className={cx(classes.padding5, classes.marginTop5)}
                      >
                        <AddBoxIcon fontSize="small" style={{ color: '#87BC14' }} />
                      </IconButton>
                      <Grid item xs={12}>
                        {item.typeOfField === 'CHOICE' && item.arrayOfChoices && item.arrayOfChoices.map((choiceItem, index) => (
                          <Chip key={`${index}_${choiceItem}`} label={choiceItem} onDelete={() => handleDeleteChoice(item.uiId, choiceItem)} color="primary" />
                        ))}
                      </Grid>
                    </Grid>
                  )
                }
              </Grid>
            ))}

          </Grid>
          <Grid container item justifyContent="flex-end" className={classes.saveButtonWrapper}>
            <ButtonSC
              variant="fill"
              text={(!isLoading && (type ? 'Update' : 'Create')) || ''}
              size="middle"
              type="submit"
              disabled={isLoading || !changes}
              color="green"
              styleClass={classes.saveBtn}
            >
              {isLoading && <CircularProgress color="primary" size={20} />}
            </ButtonSC>
          </Grid>
        </Grid>

      </form>
      <Dialog open={!!open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Value</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Enter Value"
            fullWidth
            name="choices"
            id="choices"
            onChange={({ target }) => setChoice(target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {openSaveChangesModal && (
        <ConfirmationWithoutSaving
          open={openSaveChangesModal}
          color="green"
          onAcceptButtonClicked={
            () => {
              handleConfirmNavigationClick();
            }
          }
          onCancelButtonClicked={
            () => closeModal()
          }
        />
      )}
    </Paper>
  );
};

export default CreateInventoryType;
