import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const InventoryTypes = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 14">
    <path d="M0.7 0C0.313401 0 0 0.313401 0 0.7V13.3C0 13.6866 0.313401 14 0.7 14H15.3C15.6866 14 16 13.6866 16 13.3V0.7C16 0.313401 15.6866 0 15.3 0H0.7ZM3.06471 2C3.45131 2 3.76471 2.3134 3.76471 2.7V3.3C3.76471 3.6866 3.45131 4 3.06471 4H2.58235C2.19575 4 1.88235 3.6866 1.88235 3.3V2.7C1.88235 2.3134 2.19575 2 2.58235 2H3.06471ZM2.58235 8C2.19575 8 1.88235 7.6866 1.88235 7.3V6.7C1.88235 6.3134 2.19575 6 2.58235 6H3.06471C3.45131 6 3.76471 6.3134 3.76471 6.7V7.3C3.76471 7.6866 3.45131 8 3.06471 8H2.58235ZM1.88235 10.7C1.88235 10.3134 2.19575 10 2.58235 10H3.06471C3.45131 10 3.76471 10.3134 3.76471 10.7V11.3C3.76471 11.6866 3.45131 12 3.06471 12H2.58235C2.19575 12 1.88235 11.6866 1.88235 11.3V10.7ZM14.1176 11.3C14.1176 11.6866 13.8042 12 13.4176 12H6.34706C5.96046 12 5.64706 11.6866 5.64706 11.3V10.7C5.64706 10.3134 5.96046 10 6.34706 10H13.4176C13.8042 10 14.1176 10.3134 14.1176 10.7V11.3ZM14.1176 7.3C14.1176 7.6866 13.8042 8 13.4176 8H6.34706C5.96046 8 5.64706 7.6866 5.64706 7.3V6.7C5.64706 6.3134 5.96046 6 6.34706 6H13.4176C13.8042 6 14.1176 6.3134 14.1176 6.7V7.3ZM14.1176 3.3C14.1176 3.6866 13.8042 4 13.4176 4H6.34706C5.96046 4 5.64706 3.6866 5.64706 3.3V2.7C5.64706 2.3134 5.96046 2 6.34706 2H13.4176C13.8042 2 14.1176 2.3134 14.1176 2.7V3.3Z" />
  </SvgIcon>
);

export default InventoryTypes;
