import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

// TO DO, add alerts request, now it's only get message
// Get by Id
export const apiGetSingleMessageInfoById = (
  messageId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getSingleMessageInfoByMessageId}${messageId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

// Get messages
export const apiGetAllMessagesAndAlertsOfAccount = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllMessagesByAccountId}${accountId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

// Create order question
export const apiCreateOrderQuestion = (
  orderId: number,
  accountIdOfQuestionsAuthor: number | null,
  text: string,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.addOrderQuestion}`;
  return request(
    url,
    'POST',
    {
      orderId,
      accountIdOfQuestionsAuthor,
      text,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

// Create answer of order question
export const apiAddQuestionAnswer = (
  orderQuestionId: number | null,
  text: string,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.addAnswerForQuestion}`;
  return request(
    url,
    'POST',
    {
      orderQuestionId,
      text,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

export const apiMarkAsViewedAnswer = (
  orderAnswerId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.markAsViewedAnswer}${orderAnswerId}`;
  return request(
    url,
    'PUT',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

export const apiLoadAnswersForOrderQuestion = (
  messageId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getSingleMessageInfoByMessageId}${messageId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};
