import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Grid, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import useStyles from './Header.style';
import { logOut } from '../../store/slices/auth.slice';
import { getBrokerRole } from '../../utils/getRole';
import { RootState } from '../../store/slices';
import ButtonSC from '../ButtonSC/ButtonSC';
import Logo from '../../svg/logoMobile.svg';
import getLocalStorageData from '../../api/localStorage';

interface Props {
  openMenu?: () => void;
}

const Header: FC<Props> = ({ openMenu }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [clientName, setClientName] = useState<string>('');
  const { token } = getLocalStorageData();

  const { userRole } = useSelector((state: RootState) => state.auth);

  const { user } = useSelector((state: RootState) => state.user);

  const notificationNumber = useSelector(
    (state: RootState) => state.notificationMess.unreadedMessagesCount
  );

  useEffect(() => {
    const lastName = localStorage.getItem('clientLastName');
    const firstName = localStorage.getItem('clientFirstName');
    const name = ` ${lastName} ${firstName}`;
    if (lastName && firstName) setClientName(name);
  }, [setClientName, user]); // user need to update client data in header for broker

  let loginCheck = false;

  if (localStorage.getItem('accessToken')) {
    loginCheck = true;
  } else {
    loginCheck = false;
  }

  const getHeader = () => {
    const titleArray = location.pathname.split('/')[1].split(/(?=[A-Z])/);
    if (titleArray[0] === 'deal-room') return 'SmartDeal™ Room';
    if (titleArray[0] === '' && loginCheck) return 'Marketplace';
    if (titleArray[0] === '' && !loginCheck) return 'MGTrader';
    if (location.pathname === '/changeRole') return 'Profile';
    return titleArray.join(' ');
  };
  const iconClassName = notificationNumber && location.pathname !== '/notification' ? classes.ring_icon_active : classes.ring_icon
  return (
    <Grid container justifyContent="space-between" className={classes.appBar}>
      <Hidden smUp>
        <Grid container item className={classes.alignSelf} xs={6}>
          <IconButton
            aria-label="Open drawer"
            onClick={openMenu}
            className={classes.toCapitalize}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Grid item className={classes.alignSelf}>
            <img src={Logo} alt="MGT logo" />
          </Grid>
        </Grid>
        <Link color="textPrimary" to="/notification" className={classes.link}>
          <button className={classes.button_ring}>
            <NotificationsNoneIcon className={iconClassName} />
          </button>
        </Link>
      </Hidden>
      <Hidden xsDown>
        <Grid item className={classes.alignSelf}>
          <Typography variant="h1" className={classes.toCapitalize}>
            {getHeader()}
          </Typography>
        </Grid>

        <Grid item className={classes.ring_wrapper}>
          {clientName && (
            <span className={classes.roleString}>
              Act as:
              <span className={classes.nameRole}>{clientName}</span>
              Role:
              <span className={classes.nameRole}>
                {getBrokerRole(
                  userRole,
                  localStorage.getItem('clientAccountId')
                )}
              </span>
            </span>
          )}
          {localStorage.getItem('userId') ? (
            <>
              <Grid className={classes.ring_wrapper}>
                <Link
                  color="textPrimary"
                  to="/notification"
                  className={classes.link}
                >
                  <button className={classes.button_ring}>
                    <NotificationsNoneIcon className={iconClassName} />
                  </button>
                </Link>
                <Link color="textPrimary" to="/signin" className={classes.link}>
                  <ButtonSC
                    variant="outline"
                    text="Log Out"
                    size="small"
                    handleClick={() =>
                      dispatch(
                        logOut(token, new Date().getTime() + Math.random())
                      )
                    }
                    color="green"
                    styleClass={classes.appBarButton}
                  />
                </Link>
              </Grid>
            </>
          ) : (
            <>
              <Link color="textPrimary" to="/signin" className={classes.link}>
                <ButtonSC
                  variant="fill"
                  text="Log In"
                  size="small"
                  color="green"
                  styleClass={classes.appBarButton}
                />
              </Link>
              <Link color="textPrimary" to="/signup" className={classes.link}>
                <ButtonSC
                  variant="outline"
                  text="Sign Up"
                  size="small"
                  color="green"
                  styleClass={classes.appBarButton}
                />
              </Link>
            </>
          )}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Header;
