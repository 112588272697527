import constants from '../core/constants';
import api from '../core/api';
import { UserData } from '../interfaces/user.interface';
import request from './apiRequest';

export const apiGetAllUsers = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllUsers}${userId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiUpdateUserData = (
  data: UserData,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.adminChangeUserData}${userId}`;
  return request(
    url,
    'POST',
    { ...data },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   {
  //     userToChangeRoleId,
  //     role,
  //   },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};
export const apiResendRegistrationConfirmationEmail = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.resendRegistrationConfirmationEmail}${userId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};
