import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import useStyles from './NoRoleWarning.style';

const NoRoleWarning: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.warnContainer}>
      <div className={classes.warnMessage}>
        <InfoIcon />
        <Typography
          variant="body1"
          align="center"
          color="textPrimary"
          className={classes.text}
        >
          {children}
        </Typography>
      </div>
    </div>
  );
};

export default NoRoleWarning;
