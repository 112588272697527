/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {
  FC,
  useState,
  useEffect,
} from 'react';
import { PluginHook, TableInstance, TableOptions, useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { takeRequests, setOpenRequest } from '../../store/slices/adminRequest.slice';
import { RootState } from '../../store/slices';
import { AdminRequest, AdminRequestState } from '../../interfaces/adminRequest.interface';
import RequestDetail from '../../components/Request/RequestDetail';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import useStyles from './RequestTable.style';

const ListOfRequests: FC = () => {

  const classes = useStyles();
  const location: unknown = useLocation();


  const dispatch = useDispatch();

  const [newRequests, setListOfNewRequests] = useState<Array<AdminRequest>>([]);
  const [processedRequests, setListOfProcessedRequests] = useState<Array<AdminRequest>>([]);

  const [currentRequest, setCurrentRequest] = useState<AdminRequest | undefined>(undefined);

  const [open, setOpen] = useState(false);

  const handleOpen = async (rowData: AdminRequest) => {
    const { userId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    await dispatch(takeRequests(rowData.request_id, userId, token, key));
    setCurrentRequest(rowData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listOfRequests = useSelector(
    (state: RootState) => JSON.parse(JSON.stringify(state.requests.listOfRequests)),
  );

  const listOfRequestsForuseEffect: any = useSelector((state: RootState) => state.requests);

  

  const stateRender = useSelector(
    (state: RootState) => state,
  );

  const filterRequest = async (listOfRequests: AdminRequest[]) => {
    const freeRequests = await listOfRequests.filter((item) => item.admin_id === null);
    setListOfNewRequests(freeRequests);
    const acceptedRequests = listOfRequests.filter((item) => item.admin_id !== null);
    setListOfProcessedRequests(acceptedRequests);
  }

  useEffect(() => {
    filterRequest(listOfRequests);
  }, [location, stateRender, listOfRequestsForuseEffect]);


  const data = newRequests;
  const dataComplete = processedRequests;

  // Define a default UI for filtering
  function GlobalFilter({ globalFilter, setGlobalFilter }: {
    globalFilter: string | undefined;
    setGlobalFilter: (filterValue: unknown) => void;
  }): JSX.Element {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value: unknown) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span className={classes.inputSearchSpan}>
        <SearchIcon />
        {' '}
        <input
          className={classes.searchInput}
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search"
          style={{ fontSize: '1.1rem', border: '0' }}
        />
      </span>
    );
  }
  

  function Table(
    { columns, data }: {
      columns: any;
      data: AdminRequest[];
    }
  ): JSX.Element {

    const {
      getTableProps, getTableBodyProps, headerGroups, prepareRow, state,
      setGlobalFilter, canPreviousPage, canNextPage, pageOptions, page,
      pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    )

    return (
      <Grid className={classes.tableWrapper}>
        <Grid className={classes.searchInputWrapper}
        >
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Grid>

        <table className={classes.table} {...getTableProps()}>
          {headerGroups.map((headerGroup: any) => (
            <tr  {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                //@ts-ignore
                <th className={classes.tableHeaderText} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <ArrowDropUpIcon />
                        : <ArrowDropDownIcon />
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {/* </thead> */}

          <tbody {...getTableBodyProps()}>
            {page.map(
              (row: any, i: any) => {
                prepareRow(row);
                return (
                  <tr className={classes.tableText} {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
        <div className={classes.pagination}>
          <button className={classes.pagination_button} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button className={classes.pagination_button} onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button className={classes.pagination_button} onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button className={classes.pagination_button} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 15, 20].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <br />
      </Grid >
    )
  }

  function AppTable() {
    const columns = React.useMemo(
      () => [
        {
          Header: 'Actions',
          Cell: ({ row }: { row: any }) => {
            return (
              <button className={classes.buttonStyle}
                onClick={() => {
                  handleOpen(row.original as AdminRequest);
                  dispatch(setOpenRequest(
                    (row.original as AdminRequest).inventoryItem_id ?
                      { id: (row.original as AdminRequest).inventoryItem_id, type: 'inventory' } :
                      { id: (row.original as AdminRequest).users_id, type: 'userRole' },
                  ))
                }}
              >
                <AssignmentTurnedInIcon />
              </button>
            )
          }
        },
        {
          Header: 'Type',
          accessor: 'request_type',
        },
        {
          Header: 'Status',
          accessor: 'request_status',
        },
        {
          Header: 'Created At',
          accessor: 'request_createdAt',
          Cell: ({ row }: { row: any }) => {
            const getDate = new Date(row.original.request_createdAt);

            return (
              <span>
                {getDate.toString().substring(0,24)}
              </span>
            );
          },
        },
        {
          Header: 'Email',
          accessor: 'users_email',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                {row.original.users_email ? row.original.users_email : <DoDisturbIcon />}
              </span>
            );
          },
        },
        {
          Header: 'Broker',
          accessor: 'request_brokerEmail',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                {row?.request_comment || row?.request_reasonForDecline || 'None'}
              </span>
            );
          },
        },
        {
          Header: 'Admin',
          accessor: 'adminUser_lastName',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                {row.original.adminUser_firstName ? row.original.adminUser_firstName : ''} {row.original.adminUser_lastName ? row.original.adminUser_lastName : ''}
              </span>
            );
          },
        },
      ],
      []
    );
    const dath = new Date();
    const columnComplete = React.useMemo(
      () => [
        {
          Header: 'Type',
          accessor: 'request_type',
        },
        {
          Header: 'Status',
          accessor: 'request_status',
        },
        {
          Header: 'Created At',
          accessor: 'request_createdAt',
          Cell: ({ row }: { row: any }) => {
            const getDate = new Date(row.original.request_createdAt);

            return (
              <span>
                {getDate.toString().substring(0,24)}
              </span>
            );
          },
        },
        {
          Header: 'Email',
          accessor: 'users_email',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                {row.original.users_email ? row.original.users_email : <DoDisturbIcon />}
              </span>
            );
          },
        },
        {
          Header: 'Broker',
          accessor: 'request_brokerEmail',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                {row?.request_comment || row?.request_reasonForDecline || 'None'}
              </span>
            );
          },
        },
        {
          Header: 'Admin',
          accessor: 'adminUser_lastName',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                {row.original.adminUser_firstName ? row.original.adminUser_firstName : ''} {row.original.adminUser_lastName ? row.original.adminUser_lastName : ''}
              </span>
            );
          },
        },
      ],
      []
    )

    return (
      <>
        <h2 className={classes.headerName}>New Requests</h2>
        <Table columns={columns} data={data} />
        <h2 className={classes.headerName}>Requests History</h2>
        <Table columns={columnComplete} data={dataComplete} />
      </>
    )
  }

  return (
    <TableContainer>
      <AppTable />
      {currentRequest &&
        <RequestDetail open={open} onClose={() => handleClose()} request={currentRequest} />}
    </TableContainer>
  );
};

export default ListOfRequests;
