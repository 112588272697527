import React, { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import cx from 'classnames';
import 'react-phone-input-2/lib/material.css';
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { User } from '../../interfaces/user.interface';
import useStyles from './ProfilePage.style';
import { NumberFormatZip, NumberFormatSSN, NumberFormatEIN } from '../NumberFormat/NumberFormat';
import StateAutocomplete from '../StateAutocomplete/StateAutocomplete';
import TextFieldProfile from '../TextFieldProfile/TextFieldProfile';

interface Props {
  userInfo: User;
  currentTab: number;
  errors: any;
  control: any;
  watchData: any;
  setValue: any;
  clearErrors: any;
}

const CompanyInfo: FC<Props> = ({
  control,
  errors,
  userInfo,
  currentTab,
  watchData,
  setValue,
  clearErrors,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      className={currentTab === 1 ? classes.fullHeight : classes.displayNone}
    >
      <Grid item xs={12}>
        <Controller
          name="legalStatus"
          control={control}
          defaultValue={userInfo.legalStatus || ''}
          render={({ onChange, value, name }) => (
            <FormControl
              variant="outlined"
              size="small"

              fullWidth
            >
              <InputLabel>Legal Status</InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                onChange={({ target }) => {
                  onChange(target.value);
                }}
                value={value}
                label="Legal Status*"
                name={name}
                error={!!errors.legalStatus?.message}
              >
                <MenuItem value="" disabled>
                  <em>None</em>
                </MenuItem>
                <MenuItem value="SOLE PROPRIETOR">Sole Proprietor</MenuItem>
                <MenuItem value="PARTNERSHIP/LLC">Partnership/LLC</MenuItem>
                <MenuItem value="PRIVATE CO">Private Co</MenuItem>
                <MenuItem value="PUBLIC CO">Public Co</MenuItem>
              </Select>
            </FormControl>
          )}
        // rules={{
        //   required: 'Required field',
        // }}
        />
        {errors.legalStatus && <Typography variant="caption" color="error">{errors.legalStatus?.message}</Typography>}
      </Grid>
      {watchData.legalStatus && watchData.legalStatus !== 'SOLE PROPRIETOR' && (
        <>
          <Grid item xs={12} sm={4}>
            <Controller
              name="organizationName"
              control={control}
              defaultValue={userInfo.organizationName || ''}
              render={({ onChange, value, name }) => (
                <TextFieldProfile
                  name={name}
                  value={value}
                  label="Company Name"
                  error={!!errors.organizationName?.message}
                  handleChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            // rules={{
            //   required: 'Required field',
            // }}
            />
            {errors.organizationName && <Typography variant="caption" color="error">{errors.organizationName?.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="contactsTitle"
              control={control}
              defaultValue={userInfo.contactsTitle || ''}
              render={({ onChange, value, name }) => (
                <TextFieldProfile
                  name={name}
                  value={value}
                  label="Your Title"
                  error={!!errors.contactsTitle?.message}
                  handleChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            // rules={{
            //   required: 'Required field',
            // }}
            />
            {errors.contactsTitle && <Typography variant="caption" color="error">{errors.contactsTitle?.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="businessWebsiteAddress"
              control={control}
              defaultValue={userInfo.businessWebsiteAddress || ''}
              render={({ onChange, value, name }) => (
                <TextFieldProfile
                  name={name}
                  value={value}
                  label="Business URL"
                  error={!!errors.businessWebsiteAddress?.message}
                  handleChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            />
          </Grid>
        </>
      )}
      {watchData.legalStatus && watchData.legalStatus === 'SOLE PROPRIETOR' && (
        <>
          <Grid item xs={12} sm={4}>
            <Controller
              name="SSN"
              control={control}
              defaultValue={userInfo.SSN || ''}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  size="small"
                  variant="outlined"
                  fullWidth
                  label="SSN"
                  value={value}
                  onChange={({ target }) => {
                    onChange(target.value);
                  }}
                  error={!!errors.SSN?.message}
                  InputProps={{
                    inputComponent: NumberFormatSSN as any,
                  }}
                />
              )}
              rules={{
                // required: 'Required field',
                // pattern: {
                //   value: /^[1-9][0-9][0-9]+-[0-9][0-9]+-[0-9][0-9][0-9][0-9]/i,
                //   message: 'Incorrect value',
                // },
              }}
            />
            {errors.SSN && <Typography variant="caption" color="error">{errors.SSN?.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="businessWebsiteAddress"
              control={control}
              defaultValue={userInfo.businessWebsiteAddress || ''}
              render={({ onChange, value, name }) => (
                <TextFieldProfile
                  name={name}
                  value={value}
                  label="Business URL"
                  error={!!errors.businessWebsiteAddress?.message}
                  handleChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            />
          </Grid>
        </>
      )}
      {watchData.legalStatus && watchData.legalStatus !== 'SOLE PROPRIETOR' && (
        <>
          <Grid item xs={12} sm={4}>
            <Controller
              name="stateOfIncorporation"
              control={control}
              defaultValue={userInfo.stateOfIncorporation || ''}
              render={({ onChange }) => (
                <StateAutocomplete
                  label="State of Formation"
                  defaultValue={userInfo.stateOfIncorporation || ''}
                  currentState={watchData.stateOfIncorporation}
                  handleChange={(newValue: any) => {
                    onChange(newValue);
                  }}
                  error={!!errors.stateOfIncorporation?.message}
                />
              )}
            />
            {errors.stateOfIncorporation && <Typography variant="caption" color="error">{errors.stateOfIncorporation?.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="federalEIN"
              control={control}
              defaultValue={userInfo.federalEIN || ''}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  size="small"

                  variant="outlined"
                  fullWidth
                  label="Federal EIN"
                  value={value}
                  onChange={({ target }) => {
                    onChange(target.value);
                  }}
                  InputProps={{
                    inputComponent: NumberFormatEIN as any,
                  }}
                  error={!!errors.federalEIN?.message}
                />
              )}
              rules={{
                // required: 'Required field',
                pattern: {
                  value: /^[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/i,
                  message: 'Incorrect value',
                },
              }}
            />
            {errors.federalEIN && <Typography variant="caption" color="error">{errors.federalEIN?.message}</Typography>}
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={4} />
          </Hidden>
          <Grid
            item
            xs={12}
            sm={4}
            className={cx(classes.phone, !!errors.organizationOfficePhone && classes.errorPhone)}
          >
            <Controller
              name="organizationOfficePhone"
              control={control}
              defaultValue={userInfo.organizationOfficePhone || '1'}
              render={({ onChange, value, name }) => (
                <PhoneInput
                  country="us"
                  countryCodeEditable={false}
                  onlyCountries={['us', 'mx', 'ca']}
                  value={value}
                  inputProps={{
                    name,
                    className: (cx(
                      classes.inputPhone,
                      !!errors.organizationOfficePhone && classes.errorInputPhone,
                    )),
                    autoComplete: 'new-password',
                  }}
                  // onChange={(tel) => {
                  //   onChange(tel || '');
                  // }}
                  onChange={(tel) => {
                    if (userInfo.organizationOfficePhone === '' && (tel === '1' || tel === '52') && (value === '1' || value === '52')) setValue('organizationOfficePhone', tel, { shouldDirty: false });
                    else if (userInfo.organizationOfficePhone === '' && (tel === '1' || tel === '52') && value !== '1' && value !== '52') {
                      setValue('organizationOfficePhone', '1', { shouldDirty: true });
                      clearErrors('organizationOfficePhone');
                    } else setValue('organizationOfficePhone', tel, { shouldDirty: true });
                  }}
                  specialLabel="Office Phone"
                />
              )}
              rules={{
                validate: (value) => {
                  if (value) return ((value === '1') || (value === '52') || (value.length >= 11)) || 'Invalid value';
                  return true;
                },
              }}
            />
            {errors.organizationOfficePhone && <Typography variant="caption" color="error">{errors.organizationOfficePhone?.message}</Typography>}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className={cx(classes.phone, !!errors.organizationOfficeFax && classes.errorPhone)}
          >
            <Controller
              name="organizationOfficeFax"
              control={control}
              defaultValue={userInfo.organizationOfficeFax || '1'}
              render={({ onChange, value, name }) => (
                <PhoneInput
                  country="us"
                  countryCodeEditable={false}
                  onlyCountries={['us', 'mx', 'ca']}
                  value={value}
                  inputProps={{
                    name,
                    className: (cx(
                      classes.inputPhone,
                      !!errors.organizationOfficeFax && classes.errorInputPhone,
                    )),
                    autoComplete: 'new-password',
                  }}
                  // onChange={(tel) => {
                  //   onChange(tel || '');
                  // }}
                  onChange={(tel) => {
                    if (userInfo.organizationOfficeFax === '' && (tel === '1' || tel === '52') && (value === '1' || value === '52')) setValue('organizationOfficeFax', tel, { shouldDirty: false });
                    else if (userInfo.organizationOfficeFax === '' && (tel === '1' || tel === '52') && value !== '1' && value !== '52') {
                      setValue('organizationOfficeFax', '1', { shouldDirty: true });
                      clearErrors('organizationOfficeFax');
                    } else setValue('organizationOfficeFax', tel, { shouldDirty: true });
                  }}
                  specialLabel="Office Fax"
                />
              )}
              rules={{
                validate: (value) => {
                  if (value) return ((value === '1') || (value === '52') || (value.length >= 11)) || 'Invalid value';
                  return true;
                },
              }}
            />
            {errors.organizationOfficeFax && <Typography variant="caption" color="error">{errors.organizationOfficeFax?.message}</Typography>}
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={4} />
          </Hidden>
          <Grid item xs={12} sm={4}>
            <Controller
              name="organizationLine1"
              control={control}
              defaultValue={userInfo.organizationLine1 || ''}
              render={({ onChange, value, name }) => (
                <TextFieldProfile
                  name={name}
                  value={value}
                  label="Address Line 1"
                  error={!!errors.organizationLine1?.message}
                  handleChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            />
            {errors.organizationLine1 && <Typography variant="caption" color="error">{errors.organizationLine1?.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="organizationLine2"
              control={control}
              defaultValue={userInfo.organizationLine2 || ''}
              render={({ onChange, value, name }) => (
                <TextFieldProfile
                  name={name}
                  value={value}
                  label="Address Line 2"
                  error={!!errors.organizationLine2?.message}
                  handleChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            />
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={4} />
          </Hidden>
          <Grid item xs={12} sm={4}>
            <Controller
              name="organizationCity"
              control={control}
              defaultValue={userInfo.organizationCity || ''}
              render={({ onChange, value, name }) => (
                <TextFieldProfile
                  name={name}
                  value={value}
                  label="City"
                  error={!!errors.organizationCity?.message}
                  handleChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            />
            {errors.organizationCity && <Typography variant="caption" color="error">{errors.organizationCity?.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="organizationState"
              control={control}
              defaultValue={userInfo.organizationState || ''}
              render={({ onChange }) => (
                <StateAutocomplete
                  label="State"
                  defaultValue={userInfo.organizationState || ''}
                  currentState={watchData.organizationState}
                  handleChange={(newValue: any) => {
                    onChange(newValue);
                  }}
                  error={!!errors.organizationState?.message}
                />
              )}
            />
            {errors.organizationState && <Typography variant="caption" color="error">{errors.organizationState?.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="organizationZip"
              control={control}
              defaultValue={userInfo.organizationZip || ''}
              render={({ onChange, value, name }) => (
                <TextField
                  name={name}
                  size="small"
                  InputLabelProps={{ shrink: !!value }}
                  variant="outlined"
                  fullWidth
                  label="Zip Code"
                  autoComplete="new-password"
                  value={value}
                  onChange={({ target }) => {
                    onChange(target.value);
                  }}
                  InputProps={{
                    inputComponent: NumberFormatZip as any,
                  }}
                  error={!!errors.organizationZip?.message}
                />
              )}
              rules={{
                // required: 'Required field',
                minLength: {
                  value: 5,
                  message: 'Incorrect zip: 5 symbols needed',
                },
              }}
            />
            {errors.organizationZip && <Typography variant="caption" color="error">{errors.organizationZip?.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="organizationCountry"
              control={control}
              defaultValue={userInfo.organizationCountry || ''}
              render={({ onChange, value, name }) => (
                <TextFieldProfile
                  name={name}
                  value={value}
                  label="Country"
                  error={!!errors.organizationCountry?.message}
                  handleChange={(data) => {
                    onChange(data);
                  }}
                />
              )}
            />
            {errors.organizationCountry && <Typography variant="caption" color="error">{errors.organizationCountry?.message}</Typography>}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CompanyInfo;
