import React, {
  FC,
  useState,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './ListOfNegotiationPoints.style';
import { RootState } from '../../store/slices';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import { InventoryItemType } from '../../interfaces/inventoryTypes.interface';
import { getInventoryTypes } from '../../store/slices/inventoryTypes.slice';

const ListOfNegotiationPoints: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['rowListOfNegotiationPoints']);

  const [
    stateListOfInventoryTypes,
    setListOfInventoryTypes,
  ] = useState<Array<InventoryItemType>>([]);

  const {
    listOfInventoryTypes,
  } = useSelector(
    (state: RootState) => state.inventoryTypes,
  );

  useEffect(() => {
    const { userId } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    dispatch(getInventoryTypes(userId, key));
  }, [dispatch]);

  useEffect(() => {
    setListOfInventoryTypes(listOfInventoryTypes.map((item) => ({ ...item })));
  }, [listOfInventoryTypes, setListOfInventoryTypes]);

  const columns: Array<Column<InventoryItemType>> = [
    { title: 'Type', field: 'type' },
  ];

  return (
    <TableContainer>
      <MaterialTable
        title=""
        columns={columns}
        data={stateListOfInventoryTypes}
        actions={[
          (rowData: InventoryItemType) => ({
            icon: 'edit',
            tooltip: 'Edit',
            onClick: () => history.push('editNegotiationPoints', { id: rowData.id, type: rowData.type }),
          }),
        ]}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
            paddingLeft: 0,
          },
          pageSize: +cookies.rowListOfNegotiationPoints || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowListOfNegotiationPoints', pageSize, { path: '/' })}
        components={{
          Toolbar: (props) => (
            <MTableToolbar {...props} classes={{ actions: classes.actions }} />
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
        }}
      />
    </TableContainer>
  );
};

export default ListOfNegotiationPoints;
