import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import { Paper, Typography } from '@material-ui/core';
import useStyles from './style';
import { activateAccount } from '../../store/slices/auth.slice';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
import { resendRegistrationConfirmationEmail } from '../../store/slices/adminRoleAssignment.slice';
import getLocalStorageData from '../../api/localStorage';

export const ActivateAccountPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token: confirmToken } = useParams();
  if (confirmToken) {
    const key = new Date().getTime() + Math.random();
    dispatch(activateAccount(confirmToken, key));
  }
  const { accountId, token } = getLocalStorageData();

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h1">
          Your account is not yet active.
        </Typography>
        <Typography variant="h2" className={classes.textActivateAccount}>
          An email message was sent to you.
        </Typography>
        <Typography variant="h2" className={classes.textActivateAccount}>
          Please, find our email and click on the link to confirm your email address.
        </Typography>
        <Typography variant="h2" className={classes.textActivateAccount}>
          If you can’t find our email, please check your spam or junk email folder.
        </Typography>
        <ButtonSC
          variant="fill"
          text="Click here to resend your registration email."
          size="large"
          color="green"
          styleClass={classes.marginTop24}
          handleClick={() => dispatch(resendRegistrationConfirmationEmail(
            accountId,
            token,
            new Date().getTime() + Math.random(),
          ))}
        />
      </Paper>
    </div>
  );
};
export default ActivateAccountPage;
