import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 275,
    '& .MuiAccordion-rounded:first-child': {
      borderRadius: 0,
    },
    '& .MuiAccordion-rounded:last-child': {
      borderRadius: 0,
    },
    '& .MuiAccordion-root.Mui-disabled': {
      backgroundColor: theme.palette.text.disabled,
    },
    '& .MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },
    '& .MuiTypography-root': {
      paddingBottom: 8,
      '@media (max-width: 680px)': {
        fontSize: '12px',
      },
    },
  },
  heading: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.text.secondary,
    // fontWeight: 'bold',
    marginRight: 8,
  },
  secondaryHeading: {
    fontSize: 14,
    lineHeight: '16px',
    // textTransform: 'lowercase',
    color: theme.palette.text.primary,
    minHeight: 24,
    // fontWeight: 'bold',

    // '&::first-line': {
    //   textTransform: 'capitalize',
    // },
  },
  headingLarge: {
    fontSize: 18,
    lineHeight: '20px',
    fontWeight: 'bold',
    [theme.breakpoints.up(1070)]: {
      fontSize: 16,
      lineHeight: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 13,
      lineHeight: '12px',
    },
  },
  // icon: {
  //   verticalAlign: 'bottom',
  //   height: 20,
  //   width: 20,
  // },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  message: {
    width: '50%',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 12,
  },
  textEnd: {
    textAlign: 'end',
  },
  messagePadding8: {
    padding: '8px',
    '&:last-child': {
      padding: '8px',
    },
  },
  typeSelect: {
    width: '100%',
  },

  dealPaper: {
    padding: '24px 16px',
    boxShadow: 'none',
    borderRadius: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 16px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '16px 24px',
    },
  },
  dealPaperDate: {
    padding: '24px 16px',
    marginTop: '16px',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 16px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '16px 24px',
    },
  },
  saveButton: {
    margin: '0 20px',
  },
  npButton: {
    height: 32,
    padding: '6px 8px',
    [theme.breakpoints.up(1280)]: {
      fontSize: 13,
      padding: '8px 12px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
      maxWidth: 168,
    },
    [theme.breakpoints.down(768)]: {
      padding: '4px 6px',
    },
  },
  acceptTimeout: {
    height: 32,
  },
  finishPTSButton: {
    height: 32,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 168,
    },
  },
  npButtonContainer: {
    padding: '0 16px',
    width: 200,
    [theme.breakpoints.down(1280)]: {
      padding: '16px 0',
    },
  },
  saveButtonVertical: {
    margin: '20px 0',
  },
  acceptAllButtonVertical: {
    margin: '20px 0',
    textAlign: 'end',
    padding: '0 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 72px',
    },
  },
  alignCenter: {
    justifyContent: 'center',
    height: 40,
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  marginBottom16: {
    marginBottom: 16,
  },
  leaveButton: {
    height: 32,
    marginTop: 16,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      width: 168,
    },
  },
  iconSmall: {
    fontSize: 12,
    marginRight: 8,
  },
  paddingBottom8_child: {
    '& .MuiTypography-body1': {
      paddingBottom: 8,
    },
  },
  paddingBottom16: {
    paddingBottom: 16,
  },
  textColorGreen: {
    color: theme.palette.primary.main,
  },
  docName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('sm')]: {
      margin: '12px 0',
    },
  },
  docItem: {
    display: 'flex',
    alignItems: 'center',
  },
  heightFitContent: {
    height: 'fit-content',
  },
  paperContainer: {
    boxShadow: 'none',
    borderRadius: 15,
    padding: '16px 0',
  },
  paperTitle: {
    root: {
      fontSize: '1rem',
    },
    fontSize: '1rem',
    h2: {
      fontSize: '1rem',
    },
    padding: '0 16px 24px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 24px 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 72px 24px',
    },
  },
  totalPrice: {
    padding: '16px 16px 0',
    textAlign: 'end',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 24px 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '16px 72px 0',
    },
  },
  textHide: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
  },
  ptsStatus: {
    fontStyle: 'italic',
    paddingTop: 8,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
      textAlign: 'end',
    },
  },
  maxHeight177: {
    [theme.breakpoints.up('sm')]: {
      overflowY: 'auto',
      maxHeight: 177,
    },
  },
  xsMaxHeight: {
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      maxHeight: 177,
    },
  },
  // Accordion
  Accordion: {
    boxShadow: 'none',
    borderBottom: '1px solid #F0F4F8',
  },
  AccordionSummary: {
    padding: '0 16px',
    minHeight: 56,
    [theme.breakpoints.up('sm')]: {
      padding: '0 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 72px',
    },
  },
  negotiationPointsHeader: {
    minHeight: 24,
    paddingBottom: 8,
    alignItems: 'center',
  },
  negotiationPointsHeaderAlignRight: {
    textAlign: 'right',
  },
  dialogPaperFullSizeImage: {
    maxWidth: '100%',
    // minWidth: 'auto',
  },
  fullSizeImageInsideDialog: {
    maxWidth: '100%',
    height: 'auto',
  },
  closeModalIcon: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: 'white',
    background: '#80808078',
  },
  AccordionSummaryAgreed: {
    backgroundColor: '#D4E8E6',
  },
  AccordionSummaryAgreedByOne: {
    border: `4px solid ${theme.palette.primary.light}`,
  },
  AccordionSummaryError: {
    backgroundColor: 'rgba(220,93,93,0.5)',
  },
  AccordionSummaryWarning: {
    backgroundColor: '#FCE48F',
  },
  AccordionSummaryNextStep: {
    border: '4px solid #FCE48F',
  },
  AccordionSummaryTitle: {
    color: theme.palette.secondary.dark,
  },
  AccordionSummaryTitleAgreed: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  AccordionDetails: {
    display: 'block',
    alignItems: 'center',
    padding: '12px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '12px 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '12px 72px',
    },
  },

  expanationPanelTimeout: {
    borderRadius: 15,
    '&:before': {
      height: 0,
    },
  },

  // Chat
  chatItem: {
    height: 150,
  },
  chatContainer: {
    height: '100%',
    padding: '16px 8px',
    backgroundColor: '#F8FAFC',
    overflowY: 'scroll',
    display: 'block',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    width: '100%',
    flexWrap: 'unset',
    '& .MuiGrid-root': {
      padding: '0',
      margin: 4,
      // maxHeight: '100px',
    },
    '& .MuiCard-root': {
      boxShadow: 'none',
      borderRadius: 5,
      marginRight: '8px',
      border: '2px solid white',
    },
  },
  newMessage: {
    boxShadow: 'none',
    borderRadius: 5,
    marginRight: '8px',
    border: '1px solid white',
    '&:nth-last-child(3)': {
      '& .MuiCard-root': {
        animationName: '$NewMessageBlinker',
        animationDuration: '1000ms',
        animationIterationCount: '20',
        animationDirection: 'alternate',
        animationTimingFunction: 'ease-in-out',
      }
    }
  },

  '@keyframes NewMessageBlinker': {
    '0%': {
      background: 'white',
      border: '1px solid white',
    },
    '100%': {
      background: '#daf5e1',
      borderRadius: '8px',
      border: '1px solid #baeeba',
      // transform: 'scale(1.2)',
    },
  },
  chatActions: {
    padding: '24px 16px 0',
    width: '96%',
  },
  // chatTitle: {
  //   padding: '0 24px 8px',
  // },
  chatFab: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  chatFabNew: {
    backgroundColor: theme.palette.error.main,
    animationName: '$blinker',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes blinker': {
    '0%': {
      backgroundColor: theme.palette.primary.dark,
    },
    '100%': {
      backgroundColor: theme.palette.error.main,
      transform: 'scale(1.2)',
    },
  },
  chatFixedContainer: {
    position: 'fixed',
    bottom: 16,
    right: 25,
    zIndex: 1000,
    boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
  },
  chatWidth: {
    height: '75vh',
    minWidth: 'calc(100% - 20px)',
    right: 0,
    margin: '0 10px',
    [theme.breakpoints.up('sm')]: {
      minWidth: 'calc(100% - 103px)',
      maxWidth: 'calc(100% - 120px)',
      marginLeft: 42,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '500px',
      maxWidth: '500px',
    },
  },
  chatContainerWrapper: {
    height: '100%',
    position: 'relative',
  },
  icon: {
    borderRadius: 5,
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.secondary.contrastText}`,
    'input:hover ~ &': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'" +
        "%3E%3Cpath d='M5.36433 8.81296C5.2867 8.88842 5.16314 8.88842 5.08551 8.81296L1.9181 5.73398C1.72404 5.54535 1.41513 5.54534" +
        ' 1.22107 5.73398L0.36882 6.56244C0.166838 6.75878 0.166838 7.08314 0.36882 7.27948L4.87641 11.6612C5.07046 11.8499 5.37938' +
        ' 11.8499 5.57343 11.6612L15.6312 1.88428C15.8332 1.68794 15.8332 1.36358 15.6312 1.16724L14.7789 0.338782C14.5849 0.150146 ' +
        "14.276 0.150146 14.0819 0.338782L5.36433 8.81296Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
    },
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
  picture: {
    height: 50,
    width: 50,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.contrastText,
    cursor: 'pointer',
  },
  popper: {
    boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
    borderRadius: 5,
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F4F8',
    },
    '&:hover': {
      backgroundColor: '#F0F4F8',
    },
  },

  longDescriptionHide: {
    height: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  longDescriptionBtn: {
    padding: 0,
    textDecoration: 'underline',
    color: theme.palette.primary.light,
  },
  indicatorsContainer: {
    zIndex: 1000,
    position: 'fixed',
    top: 17,
    width: 'calc(100% - 48px)',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      top: 10,
      width: 'calc(100% - 131px)',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      top: 17,
      width: 'calc(100% - 288px)',
    },
    [theme.breakpoints.down('xs')]: {
      top: 10,
      width: '60%',
      left: '20%',
      justifyContent: 'flex-end',
    },
  },
  indicatorButton: {
    minWidth: 44,
    height: 44,
    width: 44,
    padding: 5,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
  },
  green: {
    backgroundColor: '#D4E8E6',
  },
  greenBorder: {
    border: `4px solid ${theme.palette.primary.light}`,
  },
  yellow: {
    backgroundColor: theme.palette.error.light,
  },
  yellowBorder: {
    border: `4px solid ${theme.palette.error.light}`,
  },
  white: {
    border: `4px solid ${theme.palette.secondary.light}`,
  },
  box_style: {
    transition: '1s',
    width: '100%',
  },

  box_smart_deal1_wrapper: {
    display: 'flex'
  },

  userInfoChatWrapper: {
    position: 'relative'
  },

  statusWrapper: {
    position: 'absolute',
    width: '70px',
    top: '5px',
    right: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },

  ledStatusOn: {
    width: 8,
    height: 8,
    // background: '#40d940',
    // border: '1px solid #40d940',
    borderRadius: '50%',
    // boxShadow: '1px -2px 25px 2px #40d940',
    animationName: '$OnlineStatusOn',
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },

  '@keyframes OnlineStatusOn': {
    '0%': {
      background: '#3dc93d',
      boxShadow: '0px 0px 15px 0px #40d940',
    },
    '100%': {
      background: '#40d940',
      boxShadow: '1px -2px 25px 3px #40d940',
      transform: 'scale(1.1)',
    },
  },
  ledStatusOff: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    animationName: '$OnlineStatusOff',
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },

  '@keyframes OnlineStatusOff': {
    '0%': {
      background: '#cc5d5d',
      boxShadow: '0px 0px 15px 0px #cc5d5d',
    },
    '100%': {
      background: '#ee4d4d',
      boxShadow: '1px -2px 25px 3px #ee4d4d',
      transform: 'scale(1.1)',
    },
  },

  textStatus: {
    textAlign: 'center'
  },

  confirm_order_wrapper: {
    marginTop: '30px',

  },

  confirm_message_wrapper: {
    textAlign: 'center',
    marginBottom: '30px',
  },

  confirm_message: {

    '@media (max-width: 1380px)': {
      fontSize: '18px'
    },
    '@media (max-width: 680px)': {
      fontSize: '16px'
    },
  },

  textAlignCenter: {
    textAlign: 'center',
  },

  ModalQuestionWrapper: {
    width: '100%'
  },

  DateAndTimeWrapper: {
    width: '100%',
    display: 'flex',
    margin: '24px',
    justifyContent: 'space-evenly',

    '@media (max-width: 1024px)': {
      padding: '8px',
      margin: '8px',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    '@media (max-width: 1012px)': {
      flexWrap: 'wrap',
      padding: '8px',
      margin: '8px',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
      padding: '8px',
      margin: '8px',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    '@media (max-width: 680px)': {
      flexWrap: 'wrap',
      padding: '8px',
      margin: '8px',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
  },
  buyDialogWrapper: {
    padding: '16px',
    overflow: 'hidden',
  },

  closeButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },

  orderNumber: {
    position: 'absolute',
    top: '5px',
    right: '70px',
    fontSize: '21px'
  },

  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },

  timeWrapper: {
    marginRight: '5px',
    width: '90px',

    '@media (max-width: 1024px)': {
      marginRight: '8px',
      width: '68px',
    },
    '@media (max-width: 1012px)': {
      width: '110px',
      marginBottom: '32px'
    },
    '@media (max-width: 768px)': {
      marginRight: '20px',
      width: '65px',
      marginBottom: '32px'
    },
    '@media (max-width: 680px)': {
      marginRight: '20px',
      marginBottom: '6px'
    },
  },
  estWrapper: {
    marginRight: '5px',
    width: '60px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 1024px)': {
      marginRight: '5px',
      marginBottom: '6px',
    },
    '@media (max-width: 1012px)': {
      width: '110px',
      height: '42px'
    },
    '@media (max-width: 680px)': {
      width: '60px',
      marginRight: '20px',
    },
  },
  calendarWrapper: {
    marginRight: '5px',
    width: '140px',
    '@media (max-width: 680px)': {
      marginTop: '10px',
    },
  },
  sendButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '10px',
    '@media (max-width: 1024px)': {
      display: 'block',
      marginTop: '8px',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginLeft: '50px',
      marginTop: '25px',
    },
  },

  open: {
    width: '40%',
    display: 'flex'
  },

  closed: {
    width: '0',
    display: 'none'
  },
  acceptButtonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '16px'
  },
  padding8: {
    padding: '8px'
  }

}));

export default useStyles;
