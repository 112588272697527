import { createTheme } from '@material-ui/core';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
});
const { breakpoints } = defaultTheme;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: '#87BC14',
      main: '#075955',
    },
    secondary: {
      light: '#F0F4F8',
      main: '#979BA9',
      dark: '#32343C',
      contrastText: '#D5D5D7',
    },
    error: {
      main: '#DC5D5D',
      light: '#FCE48F',
    },
  },
  overrides: {
    // MuiIconButton: {
    //   root: {
    //     fontSize: 'inherit',
    //   },
    // },
    // MuiIcon: {
    //   root: {
    //     fontSize: 'inherit',
    //   },
    // },
    MuiIconButton: {
      root: {
        borderRadius: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        [breakpoints.down('sm')]: {
          padding: '6px',
        },
      },
    },
    MuiPaper: {
      elevation2: {
        borderRadius: 15,
        boxShadow: 'none',
        color: '#32343C',
        padding: 16,
        [breakpoints.up('lg')]: {
          padding: '16px 72px',
        },
      },
      elevation8: {
        boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
      },
    },
    MuiTableRow: {
      root: {
        height: 40,
        borderBottom: '1px solid #F0F4F8',
        '&:last-child': { borderBottom: 'none' },
      },
      head: {
        borderRadius: 5,
        borderBottom: 'none',
        backgroundColor: '#F0F4F8',
        color: '#979BA9',
        '&:first-child th:first-child': { borderTopLeftRadius: 5 },
        '&:first-child th:last-child': { borderTopRightRadius: 5 },
        '&:last-child th:first-child': { borderBottomLeftRadius: 5 },
        '&:last-child th:last-child': { borderBottomRightRadius: 5 },
      },
    },
    MuiTablePagination: {
      spacer: {
        display: 'none',
      },
      toolbar: {
        justifyContent: 'space-between',
      },
      input: {
        color: '#979BA9',
        '@media (max-width: 680px)': {
          width: '75px',
          paddingLeft: 0,
        }
      },
    },
    MuiInput: {
      input: {
        width: '320px',
        paddingLeft: 0,
        '@media (max-width: 680px)': {
          width: '150px',
          paddingLeft: 0,
        },
        '@media (max-width: 380px)': {
          width: '120px',
          paddingLeft: 0,
        },
      },
      underline: {
        paddingLeft: 10,
        color: '#979BA9',
        '&:before': {
          borderBottom: '1px solid #979BA9',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #87BC14',
        },
        '&:hover': {
          color: '#87BC14',
          background: '#F0F4F8',
        },
        '&:after': {
          borderBottom: '1px solid #075955',
        },
        '&$focused:not(.Mui-disabled):before': {
          borderBottom: '1px solid #075955',
        },
        '&$focused': {
          color: '#075955',
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        fontSize: 14,
        whiteSpace: 'nowrap',
        padding: 5,
      },
      paddingCheckbox: {
        padding: '12px 16px',
      },
      head: {
        color: '#979BA9',
        height: 40,
        padding: 5,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: '16px',
      },
      alignRight: {
        textAlign: 'left',
        flexDirection: 'row',
      },
    },
    MuiTableSortLabel: {
      root: {
        height: '16px',
      },
    },
    MuiTable: {
      root: {
        '& tr': {
          '& th': {
            backgroundColor: '#F0F4F8',
          },
        },
        [breakpoints.down('xs')]: {
          '& tr': {
            '& th': {
              backgroundColor: '#F0F4F8',
            },
            '& th:first-child': {
              backgroundColor: '#F0F4F8',
              position: 'sticky',
              left: 0,
              zIndex: 999,
              boxShadow: '2px 0 2px -2px rgba(0, 41, 71, 0.2)',
            },
            '& td:first-child': {
              backgroundColor: '#fefefe',
              position: 'sticky',
              left: 0,
              zIndex: 999,
              boxShadow: '2px 0 2px -2px rgba(0, 41, 71, 0.2)',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        lineHeight: '16px',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#F0F4F8',
        },
        '&$selected:hover': {
          backgroundColor: '#F0F4F8',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#F0F4F8',
        },
      },
    },
    MuiToolbar: {
      regular: {
        padding: 0,
      },
    },
    MuiTypography: {
      root: {
        h2: {
          fontSize: '18px',
          fontFamily: 'Arimo, Arial',
          fontWeight: 'bold',
          lineHeight: '21px',
          textTransform: 'capitalize',
          '@media (max-width: 680px)': {
            fontSize: '12px',
          },
          '@media (max-width: 330px)': {
            fontSize: 14,
          }
        }
      },
      h6: {
        fontSize: 18,
        lineHeight: '28px',
        fontWeight: 'bold',
        '@media (max-width: 680px)': {
          fontSize: '12px',
        },
        [breakpoints.up('sm')]: {
          fontSize: 20,
          lineHeight: '23px',
        },
        [breakpoints.up('md')]: {
          fontSize: 24,
          lineHeight: '28px',
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 15,
        padding: 16,
        [breakpoints.up('sm')]: {
          padding: 24,
        },
        [breakpoints.up('md')]: {
          padding: 48,
        },
      },
      scrollBody: {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '0 0 36px',
        width: '80%',
      },
    },
    MuiDialogContent: {
      root: {
        overflowX: 'hidden',
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
        justifyContent: 'center',
      },
    },
    MuiSelect: {
      icon: {
        color: '#87BC14',
      },
      select: {
        color: '#075955',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
        '&$focused $notchedOutline': {
          borderColor: '#87BC14',
          borderWidth: 1,
        },
        '&:hover $notchedOutline': {
          borderColor: '#87BC14',
        },
        '&:disabled': {
          color: 'red',
          opacity: 0.1,
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '320px',
        paddingLeft: 0,
        '@media (max-width: 680px)': {
          width: '150px',
          paddingLeft: 0,
        },
        '@media (max-width: 380px)': {
          width: '120px',
          paddingLeft: 0,
        },
        '&.Mui-disabled': {
          background: 'red',
          opacity: 0.1,
          color: 'red',
        },
      },
    },
    MuiChip: {
      root: {
        border: '1px solid #87BC14',
        margin: 5,
      },
      colorPrimary: {
        color: '#87BC14',
        backgroundColor: '#FEFEFE',
      },
      deletableColorPrimary: {
        '&:focus': {
          backgroundColor: '#FEFEFE',
        },
      },
      deleteIconColorPrimary: {
        color: '#87BC14',
        '&:hover': {
          color: '#87BC14',
        },
      },
    },
    // MuiPopover: {
    //   root: {
    //     top: '40px !important',
    //   },
    // },
  },
  typography: {
    fontFamily: 'Arimo, Arial',
  },
});

export default theme;
