import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import {
  Hidden,
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { useCookies } from 'react-cookie';
import io from 'socket.io-client';
import { useSnackbar } from 'notistack';
import useStyles from './MainContainer.style';
import DashboardDrawer from '../../components/DashboardDrawer/DashboardDrawer';
import {
  MainListItems,
  UnauthorizedListItems,
  AdminListItems,
  BrokerListItems,
  SellerListItems,
  BuyerListItems,
  SellerBuyerListItems,
} from '../../components/DashboardDrawer/dashboardListItems';
import { RootState } from '../../store/slices';
import { getRole, getBrokerRole } from '../../utils/getRole';
import getLocalStorageData from '../../api/localStorage';
import DashboardDrawerTablet from '../../components/DashboardDrawer/DashboardDrawerTablet';
import Header from '../../components/Header/Header';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
import constants from '../../core/constants';
import {
  addNotification,
  delNotification,
} from '../../store/slices/snackbar.slice';
import Snackbar from '../../components/Snackbar/Snackbar';
import {
  addNotificationMessage,
  getNotifications,
} from '../../store/slices/notifocation.slice';
import { NotificationMessage } from '../../interfaces/notificationMessage.interface';
import { apiGetUserInfo } from '../../api/updateUserInfo';

// eslint-disable-next-line import/no-mutable-exports
export let Socket: any;

const newMessage = require('../../sounds/alert_high-intensity.wav');

const MainContainer: FC = ({ children }) => {
  const dispatch = useDispatch();

  const notificationNumber = useSelector(
    (state: RootState) => state.notificationMess.unreadedMessagesCount
  );

  useEffect(() => {
    const { token, accountId } = getLocalStorageData();
    if (accountId && token.accessToken) {
      dispatch(getNotifications(accountId, token));
    }
    Socket = io(`${constants.BASE_URL}`, {
      query: { token: token.accessToken },
    });
    Socket.emit('connectToPersonalRoom', accountId);
    Socket.on('newNotification', (data: NotificationMessage) => {
      dispatch(addNotificationMessage(data));
    });
  }, [getLocalStorageData, localStorage.getItem('accessToken')]);

  const location: any = useLocation();

  useEffect(() => {
    const checkEveryMS = 60000 ; // 60000 ;
    const inactivityPeriodForIddleMS = 30 * 60000; //  30 * 60000

    let iddleTime = 0;
    let isIddle = true;
    const { userId } = getLocalStorageData();
    document.onmousemove = function () {
      iddleTime = 0;
      if (isIddle) {
        isIddle = false
        return Socket.emit('userActive', userId);
      }
    };
    setInterval(() => {
      iddleTime += checkEveryMS;
      if (iddleTime > inactivityPeriodForIddleMS) {
        if (!isIddle) {
          isIddle = true;
          return Socket.emit('userIdle', userId);
        }
      }
    }, checkEveryMS);
  }, []);

  const newMessageAudio = new Audio(newMessage);

  const [isSoundAlertsAccepted, setIsSoundAlertsAccepted] =
    useState<boolean>(false);

    function unlockAudio() {
      const sound = newMessageAudio;
      sound.play();
      sound.pause();
      sound.currentTime = 0;
    }

  useEffect(() => {
    //  show modal
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if ( isSafari && !isSoundAlertsAccepted ) {
      setIsSoundAlertsAccepted(false);
    } else {
      unlockAudio();
      setIsSoundAlertsAccepted(true);
    }
    document.body.addEventListener('touchstart', unlockAudio);
  });

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const { closeSnackbar } = useSnackbar();
  const [amountNewRequests, setAmountOfNewRequests] = useState<number>(0);
  const [amountAdminRequests, setAmountOfAdminRequests] = useState<number>(0);
  const [amountOfNewDeals, setAmountOfNewDeals] = useState<number>(0);
  const [dealsDetails, setDealsDetails] = useState<any>([]);
  const [amountOfNewSettlements, setAmountOfNewSettlements] =
    useState<number>(0);
  const [amountOfNewContracts, setAmountOfNewContracts] = useState<number>(0);
  const [amountOfNewMessagesAndAlerts, setAmountOfNewMessagesAndAlerts] =
    useState<number>(0);

  const [, setCurrentPath] = useState('');
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['cookieWarningMessage']);

  const handleDrawerToogle = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const { authError, isLoading, userId, userRole } = useSelector(
    (state: RootState) => state.auth
  );

  const { listOfDeals, listOfSettlements, negotiationPoints } = useSelector(
    (state: RootState) => state.deals
  );
  const onlineStatus = useSelector(
    (state: RootState) => state?.user?.user?.isOnline
  );
  useEffect(()=>{
    const {
      token,
      userId,
    } = getLocalStorageData();
    if(userId && token && !onlineStatus){
      dispatch(apiGetUserInfo(userId, token));
    }
  },[location])

  const allState: any = useSelector((state: RootState) => state.deals);

  const { listOfContracts } = useSelector(
    (state: RootState) => state.contracts
  );

  const { listOfAlertsAndMessages } = useSelector(
    (state: RootState) => state.alertsAndMessages
  );

  useEffect(() => {
    setCurrentPath(location.pathname);
    setOpen(false);
  }, [location]);

  useEffect(() => {
    const { clientAccountId, accountId } = getLocalStorageData();
    if (userId) {
      setAmountOfNewMessagesAndAlerts(
        listOfAlertsAndMessages.filter(
          (item) =>
            (item.accountOfWhoIsTheQuestionFor.id ===
              (clientAccountId || accountId) &&
              item.answered === false) ||
            (item.accountOfWhoIsTheQuestionFor.id !==
              (clientAccountId || accountId) &&
              item.orderAnswers.length > 0 &&
              !item.orderAnswers[0].viewed)
        ).length
      );
      setAmountOfNewSettlements(
        listOfSettlements.filter((item) => item.status === 'POST TRADE').length
      );
      setAmountOfNewContracts(
        listOfContracts.filter(
          (item) =>
            item.status === 'NOT SIGNED' &&
            ((getRole(userRole) === 'Seller' &&
              item.deal.seller &&
              !item.sellerSignature) ||
              (getRole(userRole) === 'Buyer' &&
                item.deal.buyer &&
                !item.buyerSignature) ||
              (getRole(userRole) === 'Broker' &&
                getBrokerRole(userRole, null) === 'Seller' &&
                item.deal.sellerBroker &&
                !item.sellerBrokerSignature) ||
              (getRole(userRole) === 'Broker' &&
                getBrokerRole(userRole, null) === 'Buyer' &&
                item.deal.buyerBroker &&
                !item.buyerBrokerSignature) ||
              (getRole(userRole) === 'Seller & Buyer' &&
                (((item.deal.seller && item.deal.seller.id) ===
                  (clientAccountId || accountId) &&
                  !item.sellerSignature) ||
                  ((item.deal.buyer && item.deal.buyer.id) ===
                    (clientAccountId || accountId) &&
                    !item.buyerSignature))) ||
              (getRole(userRole) === 'Broker' &&
                getBrokerRole(userRole, null) === 'Seller & Buyer' &&
                (((item.deal.seller && item.deal.seller.id) ===
                  (clientAccountId || accountId) &&
                  !item.sellerBrokerSignature) ||
                  ((item.deal.buyer && item.deal.buyer.id) ===
                    (clientAccountId || accountId) &&
                    !item.buyerBrokerSignature))))
        ).length
      );
    }
  }, [
    userId,
    listOfDeals,
    listOfSettlements,
    listOfContracts,
    listOfAlertsAndMessages,
    setAmountOfNewDeals,
    setAmountOfNewSettlements,
    setAmountOfNewContracts,
    setAmountOfNewMessagesAndAlerts,
    userRole,
    location,
    history,
    Link,
    useState,
    allState,
  ]);
  useEffect(() => {
    if (userId) {
      setAmountOfNewDeals(
        listOfDeals.filter(
          (item) => item.status === 'PENDING'
          // || item.status === 'NEEDS CONFIRMATION'
        ).length
      );
      setDealsDetails(
        listOfDeals.filter(
          (item) => item.status === 'PENDING'
          //  || item.status === 'NEEDS CONFIRMATION'
        )
      );
    }
  }, [userId, listOfDeals]);

  const { listOfRequests, listOfRequestsByAdminId } = useSelector(
    (state: RootState) => state.requests
  );

  useEffect(() => {
    setAmountOfNewRequests(
      listOfRequests.filter(
        (item) => item.request_status === 'NEEDS CONFIRMATION'
      ).length
    );
    const { accountId } = getLocalStorageData();
    const userListRequests = listOfRequestsByAdminId.filter(
      (item) => item.admin_id === accountId
    );
    setAmountOfAdminRequests(
      userListRequests.filter((item) => item.request_status === 'IN PROGRESS')
        .length
    );
  }, [
    listOfRequests,
    listOfRequestsByAdminId,
    setAmountOfNewRequests,
    setAmountOfAdminRequests,
  ]);

  const handleGetMenuItems = useCallback(() => {
    const { clientAccountId, userId: lsUserId } = getLocalStorageData();
    if (!authError && !isLoading && lsUserId) {
      if (getRole(userRole) === 'Guest') {
        return (
          <MainListItems
            open={matches || open}
            newMessagesAndAlerts={amountOfNewMessagesAndAlerts}
            isUser={getRole(userRole) === 'Guest'}
          />
        );
      }
      if (getRole(userRole) === 'Admin') {
        return (
          <AdminListItems
            newNotyfication={amountNewRequests}
            adminNotification={amountAdminRequests}
            newMessagesAndAlerts={amountOfNewMessagesAndAlerts}
            open={matches || open}
            notificationNumber={notificationNumber}
          />
        );
      }
      if (getRole(userRole) === 'Broker') {
        return (
          <BrokerListItems
            userRole={getBrokerRole(userRole, clientAccountId)}
            newDeals={amountOfNewDeals}
            newSettlements={amountOfNewSettlements}
            newContracts={amountOfNewContracts}
            newMessagesAndAlerts={amountOfNewMessagesAndAlerts}
            open={matches || open}
            notificationNumber={notificationNumber}
          />
        );
      }
      if (getRole(userRole) === 'Seller') {
        return (
          <SellerListItems
            newDeals={amountOfNewDeals}
            newSettlements={amountOfNewSettlements}
            newContracts={amountOfNewContracts}
            open={matches || open}
            newMessagesAndAlerts={amountOfNewMessagesAndAlerts}
            notificationNumber={notificationNumber}
          />
        );
      }
      if (getRole(userRole) === 'Buyer') {
        return (
          <BuyerListItems
            newDeals={amountOfNewDeals}
            newSettlements={amountOfNewSettlements}
            newContracts={amountOfNewContracts}
            open={matches || open}
            newMessagesAndAlerts={amountOfNewMessagesAndAlerts}
            notificationNumber={notificationNumber}
          />
        );
      }
      if (getRole(userRole) === 'Seller & Buyer') {
        return (
          <SellerBuyerListItems
            newDeals={amountOfNewDeals}
            newSettlements={amountOfNewSettlements}
            newContracts={amountOfNewContracts}
            open={matches || open}
            newMessagesAndAlerts={amountOfNewMessagesAndAlerts}
            notificationNumber={notificationNumber}
          />
        );
      }
    }
    return <UnauthorizedListItems open={matches || open} />;
  }, [
    open,
    authError,
    isLoading,
    // userId,
    matches,
    userRole,
    amountOfNewDeals,
    amountOfNewSettlements,
    amountOfNewContracts,
    amountNewRequests,
    amountAdminRequests,
    amountOfNewMessagesAndAlerts,
    location,
  ]);

  const closeModalNitofication = (notificationKey: number) => {
    closeSnackbar(notificationKey);
    dispatch(delNotification({ key: notificationKey }));
  };

  const linkModalNitofication = (
    id: string | number,
    dealId: string | number
  ) => {
    localStorage.setItem(
      'closedNotifications',
      `${localStorage.getItem('closedNotifications') || ''}${dealId},`
    );
    history.push(`/deal-room/${dealId}?dealId=${id}&dealRoomId=${dealId}`);
    closeSnackbar(dealId);
    dispatch(delNotification({ key: dealId }));
  };

  const action = (
    id: string | number,
    dealId: string | number,
    key: number
  ) => {
    return (
      <>
        <Button onClick={() => linkModalNitofication(id, dealId)}>
          <a href="">Go to SmartDeal Room</a>
        </Button>
        {/* <Button
          onClick={() => closeModalNitofication(key)}
        >
          Later
        </Button> */}
      </>
    );
  };

  useEffect(() => {
    if (
      amountOfNewDeals &&
      (getRole(userRole) === 'Seller' || 'Buyer' || 'Seller & Buyer')
    ) {
      dealsDetails?.map((item: any) => {
        if (
          window.location.pathname !== `/deal-room/${item.dealRoomId}` &&
          !localStorage.closedNotifications?.includes(item.dealRoomId) &&
          getRole(userRole) !== 'Admin'
        ) {
          dispatch(
            addNotification({
              message: `Interest in order # ${item.order.orderNumber}`,
              key: `${item.dealRoomId}`,
              options: {
                key: `${item.dealRoomId}`,
                variant: 'warning',
                persist: true,
                action: action(item.id, item.dealRoomId, item.dealRoomId),
                pathname: `/deal-room/${item.dealRoomId}`,
              },
            })
          );
        } else {
          closeSnackbar(item.dealRoomId);
          dispatch(delNotification({ key: item.dealRoomId }));
        }
      });
    }
  }, [amountOfNewDeals, window.location.pathname, listOfDeals]);

  return (
    <>
      {!isSoundAlertsAccepted && (
        <Button
          className={classes.alertSoundModal}
          onClick={() => setIsSoundAlertsAccepted(!isSoundAlertsAccepted)}
        >
          Click to allow audio notifications
        </Button>
      )}

      {!cookies.cookieWarningMessage && (
        <div className={classes.cookieContainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5">
                This website is using cookies, for more information please see
                our
                <Link to="/privacyPolicy" className={classes.link}>
                  {' Privacy Policy.'}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <ButtonSC
                variant="fill"
                text="CLOSE"
                size="small"
                color="green"
                handleClick={() =>
                  setCookie('cookieWarningMessage', true, { path: '/' })
                }
              />
            </Grid>
          </Grid>
        </div>
      )}
      <div className={classes.root}>
        <Hidden lgUp>
          <DashboardDrawerTablet
            menuItems={handleGetMenuItems()}
            openMenu={handleDrawerToogle}
            open={open}
          />
        </Hidden>
        <Hidden mdDown>
          <DashboardDrawer menuItems={handleGetMenuItems()} />
        </Hidden>
        <div className={classes.main}>
          <Hidden smUp>
            <Header openMenu={handleDrawerToogle} />
          </Hidden>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      <Snackbar newDeals={amountOfNewDeals} dealDetails={listOfDeals} />
    </>
  );
};

export default MainContainer;
