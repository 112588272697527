import React, {
  FC,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import { RootState } from '../../store/slices';
import { addNotification } from '../../store/slices/snackbar.slice';
import {
  getAllBrokerClients,
  addUserToBroker,
  actAsClient,
} from '../../store/slices/brokerClients.slice';
import constants from '../../core/constants';
import { BrokerClient } from '../../interfaces/brokerClients.interface';
import getLocalStorageData from '../../api/localStorage';
import MultiFileUploader from '../../components/FileUploader/MultiFileUploader';
import TableContainer from '../../containers/TableContainer/TableContainer';
import useStyles from './BrokerUserList.style';
import ButtonSC from '../../components/ButtonSC/ButtonSC';

const BrokerUserList: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['rowDeclineClients', 'rowActiveClients']);

  const [listOfActiveClients, setListOfActiveClients] = useState<Array<BrokerClient>>([]);
  const [listOfDeclineClients, setListOfDeclineClients] = useState<Array<BrokerClient>>([]);
  const [documents, setDocuments] = useState<Array<File>>([]);
  const [open, setOpen] = useState(false);
  const [currentClient, setCurrentClient] = useState<BrokerClient>();

  // Selectors
  const {
    listOfBrokerClients,
  } = useSelector(
    (state: RootState) => state.brokerClients,
  );

  // Use Efffect
  useEffect(() => {
    const { accountId, token } = getLocalStorageData();
    dispatch(getAllBrokerClients(accountId, token));
    return () => {
      setDocuments([]);
    };
  }, [dispatch]);

  const columns: Array<Column<BrokerClient>> = [
    {
      title: 'Email',
      field: 'clientEmail',
    },
    {
      title: 'Fee Type',
      field: 'feeType',
      lookup: {
        PERCENT: 'PERCENT',
        'FIXED DOLLAR AMOUNT PER DEAL': 'FIXED DOLLAR AMOUNT PER DEAL',
        'FIXED DOLLAR AMOUNT PER LOT': 'FIXED DOLLAR AMOUNT PER LOT',
      },
    },
    { title: 'Fee', field: 'fee' },
    {
      title: 'Status',
      field: 'approvedByAdmin',
      type: 'boolean',
      editable: 'never',
      render: (rowData) => (rowData && (rowData.approvedByAdmin ? ((rowData.comment && `CONFIRMED. Comment: ${rowData.comment}`) || 'CONFIRMED') : (rowData.reasonForDecline && `DECLINED. Reason: ${rowData.reasonForDecline}`) || 'NEEDS CONFIRMATION')),
      customFilterAndSearch: (term, rowData) => (rowData && (rowData.approvedByAdmin ? ((rowData.comment && `CONFIRMED. Comment: ${rowData.comment}`) || 'CONFIRMED') : (rowData.reasonForDecline && `DECLINED. Reason: ${rowData.reasonForDecline}`) || 'NEEDS CONFIRMATION').toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Documents',
      field: 'links',
      render: (rowData) => rowData.links.length > 0 &&
        rowData.links.map(
          (item: { link: string; title: string }) => (
            <div key={`${item.link}`}>
              <Link href={`${constants.AWS_URL}${item.link}`} target="_blank" rel="noreferrer">
                {item.title}
              </Link>
            </div>
          ),
        ),
      customFilterAndSearch: (term, rowData) => ((rowData.links.map((item) => item.title).join(' ')).toLowerCase()).indexOf(term.toLowerCase()) !== -1,
      editComponent: () => (
        <MultiFileUploader
          currentFiles={[]}
          newFiles={documents || []}
          setListFiles={(newFiles) => setDocuments(newFiles)}
          docsTitle
          availableForEdit
        />
      ),
    },
  ];

  useEffect(() => {
    const activeClients = listOfBrokerClients.filter((item) => !item.reasonForDecline);
    setListOfActiveClients(activeClients.map((item: BrokerClient) => ({ ...item })));
    const declineClients = listOfBrokerClients.filter((item) => !!item.reasonForDecline);
    setListOfDeclineClients(declineClients.map((item: BrokerClient) => ({ ...item })));
  }, [
    setListOfActiveClients,
    setListOfDeclineClients,
    listOfBrokerClients,
  ]);

  const checkTableRow = (
    newData: BrokerClient,
  ) => new Promise<void>((resolve, reject) => setTimeout(() => {
    const formData = new FormData();
    const {
      fee,
      clientEmail,
      feeType,
    } = newData;
    const errorsCount = [];
    if (
      !fee ||
      !clientEmail ||
      !feeType
    ) {
      errorsCount.push('Required field');
    }
    const feeTypeFormatted = feeType ? feeType.replace(/_/g, ' ') : '';
    const user = { fee, clientEmail, feeType: feeTypeFormatted };
    formData.append('user', JSON.stringify(user));
    if (feeType === 'PERCENT' && fee > 100) {
      errorsCount.push('PERCENT must be less than 100');
    }
    if (+fee < 0) {
      errorsCount.push('Only positive values');
    }
    if (documents.length > 10) {
      errorsCount.push('The maximum number of files is 10');
    }
    if (!!documents.length) {
      documents.forEach((item: File) => {
        formData.append('docs', item);
      });
    }
    const { accountId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    if (!errorsCount.length) {
      dispatch(addUserToBroker(formData, accountId, token, key));
      setDocuments([]);
      return resolve();
    } else {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: errorsCount[0],
        options: { variant: 'error' },
      }));
      // setDocuments([]);
      return reject();
    }
  }, 600));

  const handleClickOpen = useCallback(
    (rowData: BrokerClient) => {
      setCurrentClient(rowData);
      setOpen(true);
    },
    [setCurrentClient],
  );

  const { clientAccountId, accountId, token } = getLocalStorageData();

  const handleClose = useCallback(
    async (value: boolean) => {
      setOpen(false);
      const key = new Date().getTime() + Math.random();
      if (value && currentClient) {
        if (clientAccountId === currentClient.clientAccountId) {
          localStorage.removeItem('clientAccountId');
          localStorage.removeItem('clientId');
          localStorage.removeItem('clientFirstName');
          localStorage.removeItem('clientLastName');
          window.location.reload();
        } else {
          await dispatch(actAsClient(currentClient.id, accountId, token, key));
        }
      }
    }, [currentClient, clientAccountId, dispatch, accountId, token],
  );

  return (
    <>
      <TableContainer>
        <MaterialTable
          title="Active Clients"
          columns={columns}
          data={listOfActiveClients}
          editable={{
            onRowAdd: (newData) => checkTableRow(newData as BrokerClient),
          }}
          actions={[
            (rowData: BrokerClient) => ({
              icon: rowData.clientAccountId === (clientAccountId) ? 'highlight_off' : 'check_circle_outline',
              iconProps: {
                color: rowData.clientAccountId === (clientAccountId) ?
                  'error' : (rowData.approvedByClient && rowData.approvedByAdmin && 'primary') || 'secondary',
              },
              tooltip: clientAccountId ? 'Act As Broker' : 'Act As User',
              onClick: () => handleClickOpen(rowData),
            }),
          ]}
          options={{
            searchFieldStyle: {
              borderBottom: 'none',
              paddingLeft: '0',
            },
            pageSize: +cookies.rowActiveClients || 5,
          }}
          onChangeRowsPerPage={(pageSize: number) => setCookie('rowActiveClients', pageSize, { path: '/' })}
          components={{
            Toolbar: (props) => (
              <MTableToolbar {...props} classes={{ actions: classes.actions }} />
            ),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <p className="tableNoRecords">
                  No records to display
                </p>),
            },
          }}
        />
        <br />
        <MaterialTable
          title="Decline clients"
          columns={columns}
          data={listOfDeclineClients}
          options={{
            searchFieldStyle: {
              borderBottom: 'none',
              width: '150px',
              paddingLeft: '0',
            },
            pageSize: +cookies.rowDeclineClients || 5,
          }}
          onChangeRowsPerPage={(pageSize: number) => setCookie('rowDeclineClients', pageSize, { path: '/' })}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <p className="tableNoRecords">
                  No records to display
                </p>),
            },
          }}
        />
      </TableContainer>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
        <DialogTitle id="form-dialog-title">
          {
            (!clientAccountId || (currentClient && clientAccountId && currentClient.clientAccountId !== clientAccountId)) ? 'Select User' : 'Deseclect User'
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonSC
            variant="outline"
            text="Cancel"
            size="large"
            color="green"
            handleClick={() => handleClose(false)}
          />
          <ButtonSC
            variant="fill"
            text="Yes"
            size="large"
            color="green"
            handleClick={() => handleClose(true)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BrokerUserList;
