import { makeStyles } from '@material-ui/core';
import { NonceProvider } from 'react-select';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    margin: 'auto',
    width: 'calc(100% - 32px)',
    padding: '40px 24px',
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      padding: '56px 64px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 520,
    borderRadius: 15,
    boxShadow: 'none',
  },
  verifyInputNone: {
    display: 'none',
  },
  verifyInputFlex: {
    display: 'flex',
  },
  title: {
    marginBottom: 24,
  },
  marginTop24: {
    marginTop: 24,
  },
  textCenter: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '16px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '16px',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
    },
  },
  linkLarge: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  alignSelfCenter: {
    textAlign: 'center',
  },
  text: {
    marginTop: 48,
    textTransform: 'none',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 'normal',
  },
  textActivateAccount: {
    marginTop: 12,
    textTransform: 'none',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 'normal',
  },
  container: {
    height: '100%',
    padding: '112px 0',
    overflow: 'auto',
  },
  inputPhone: {
    width: '100%',
    height: 54,
    borderRadius: 5,
    cursor: 'text',
    padding: '10px 14px 10px 60px',
    border: '1px solid #c4c4c4',
    outline: 'none',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.primary.light} !important`,
    },
    fontFamily: 'Arimo, Arial',
  },
  phone: {
    '& .react-tel-input::focus-within': {
      '& .special-label': {
        color: theme.palette.primary.light,
      },
    },
    '& .react-tel-input .special-label': {
      fontSize: 11,
      color: '#979BA9',
      padding: '3px 5px',
      left: 7,
      fontFamily: 'Arimo, Arial',
      lineHeight: 1,
    },
  },
  errorInputPhone: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.error.main} !important`,
    },
  },
  errorPhone: {
    '& .react-tel-input .special-label': {
      color: theme.palette.error.main,
    },
  },
  icon: {
    borderRadius: 5,
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.secondary.contrastText}`,
    'input:hover ~ &': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'" +
        "%3E%3Cpath d='M5.36433 8.81296C5.2867 8.88842 5.16314 8.88842 5.08551 8.81296L1.9181 5.73398C1.72404 5.54535 1.41513 5.54534" +
        ' 1.22107 5.73398L0.36882 6.56244C0.166838 6.75878 0.166838 7.08314 0.36882 7.27948L4.87641 11.6612C5.07046 11.8499 5.37938' +
        ' 11.8499 5.57343 11.6612L15.6312 1.88428C15.8332 1.68794 15.8332 1.36358 15.6312 1.16724L14.7789 0.338782C14.5849 0.150146 ' +
        "14.276 0.150146 14.0819 0.338782L5.36433 8.81296Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
    },
  },
  smsDisplayNone: {
    display: 'none',
  },
  smsDisplayExist: {
    display: 'inline',
  },
  verifyCode_Wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
  },
  },
  input_verifyCode_Wrapper: {

  },
  input_Verify: {
    '@media (max-width: 768px)': {
      width: '100%',
      marginBottom: '16px'
  },
  },
  verify_button_Wrapper: {
    marginTop: 6,
  },
  verifyMargin: {
    marginTop: 6,
  },

}));

export default useStyles;
