import React, { FC, useCallback } from 'react';

interface Props {
  uploadPhoto: (file: File) => void;
  setNewAvatar: (file: string) => void;
}

const ImageUploader: FC<Props> = ({ uploadPhoto, setNewAvatar }) => {
  const handleImageUpload = useCallback(
    (e) => {
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
      e.target.value = '';
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        uploadPhoto(file);
        if (reader.result) setNewAvatar(`${reader.result}`);
      };
    },
    [uploadPhoto, setNewAvatar],
  );

  return (
    <input
      type="file"
      accept=".jpg, .jpeg, .png"
      data-testid="upload-image"
      style={{
        opacity: 0,
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
      }}
      onChange={handleImageUpload}
    />
  );
};

export default ImageUploader;
