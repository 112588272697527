import React, {
  FC,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { Location } from 'history';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useHistory, Prompt } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Grid,
  TextField,
  Typography,
  Slider,
  List,
  ListItem,
  ListItemText,
  Link,
  CircularProgress,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import useStyles from './Order.style';
import {
  createSellerOrder, getOrderById, updateOrder, orderSuccess,
} from '../../store/slices/order.slice';
import { getInventoryById } from '../../store/slices/inventory.slice';
import { RootState } from '../../store/slices';
import constants from '../../core/constants';
import { getDate } from '../../utils/getDate';
import { Inventory } from '../../interfaces/inventory.interface';
import { OrderMP } from '../../interfaces/order.interface';
import getLocalStorageData from '../../api/localStorage';
import ButtonSC from '../ButtonSC/ButtonSC';
import DocUpload from '../../svg/docUpload';
import numberToFixed from '../../utils/numberToFixed';
import { NumberFormatCustom } from '../NumberFormat/NumberFormat';
import { InventoryItemTypeField } from '../../interfaces/inventoryTypes.interface';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import ConfirmationWithoutSaving from '../ConfirmationDialog/ConfirmationWithoutSaving/ConfirmationwithoutSaving';

export const CreateSellerOrder: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { inventoryItemId } : any = useParams();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    formState,
    reset,
  } = useForm();

  const [currentInventory, setCurrentInventory] = useState<Inventory | null>(null);
  const [currentOrder, setCurrentOrder] = useState<OrderMP | null>(null);

  const location: any = useLocation();
  const orderId = location.state && location.state.orderId;
  const inventoryId = inventoryItemId || (location.state && location.state.inventoryId);

  const [changes, setChanges] = useState(false);
  const [openSaveChangesModal, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    dispatch(orderSuccess(null));
  }, [dispatch]);

  useEffect(() => {
    setChanges(formState.isDirty);
  }, [formState]);

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && changes) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    if (changes) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [confirmedNavigation, lastLocation, history, changes]);

  // Get inventory Info
  useEffect(() => {
    const { token } = getLocalStorageData();
    if (inventoryId) {
      dispatch(getInventoryById(inventoryId, token, new Date().getTime() + Math.random()));
    }
    if (orderId) dispatch(getOrderById(orderId, token, new Date().getTime() + Math.random()));
  }, [dispatch, inventoryId, orderId]);

  const {
    inventory,
  } = useSelector(
    (state: RootState) => state.inventory,
  );
  const {
    order,
    isLoadingOrders,
  } = useSelector(
    (state: RootState) => state.order,
  );

  useEffect(() => {
    if (inventory) {
      setCurrentInventory({ ...inventory });
    }
    if (orderId && order) {
      setCurrentOrder({ ...order });
    }
    return () => {
      setCurrentInventory(null);
      setCurrentOrder(null);
    };
  }, [order, orderId, inventory]);

  const relocate = useCallback(
    () => {
      setChanges(false);
      history.push(inventoryItemId ? '../orders' : 'orders');
    },
    [history, inventoryItemId],
  );

  const handleCreateOrder = useCallback(
    async (values) => {
      const { accountId, clientAccountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      await dispatch(createSellerOrder(
        relocate,
        inventoryId,
        +values.quantity,
        +values.unitPrice,
        clientAccountId || accountId,
        clientAccountId && accountId,
        token,
        key,
      ));
    },
    [dispatch, inventoryId, relocate],
  );

  const handleUpdateOrder = useCallback(
    async (values) => {
      const { accountId, clientAccountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      if (currentOrder?.inventory) {
        await dispatch(updateOrder(
          relocate,
          orderId,
          +values.quantity,
          +values.unitPrice,
          currentOrder.inventory.inventoryItemType.type,
          clientAccountId || accountId,
          clientAccountId && accountId,
          token,
          key,
        ));
      }
    },
    [dispatch, orderId, currentOrder, relocate],
  );

  const handleSaveChanges = useCallback(
    (values) => {
      if (orderId) handleUpdateOrder(values);
      else handleCreateOrder(values);
      reset({
        ...values,
      }, {
        errors: false,
        dirtyFields: false,
        isDirty: false,
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      });
    },
    [orderId, handleUpdateOrder, handleCreateOrder, reset],
  );

  const getMaxQuantity = useCallback(
    () => {
      if (currentInventory) {
        if (currentOrder) {
          const maxValue =
            numberToFixed(+currentOrder.quantity + +currentInventory.quantityRemained);
          if (maxValue > currentInventory.inventoryItemType.maxTradeSize) {
            return currentInventory.inventoryItemType.maxTradeSize ||
              numberToFixed(+currentOrder.quantity + +currentInventory.quantityRemained);
          }
          return maxValue;
        }
        if ((+currentInventory.quantityRemained > currentInventory.inventoryItemType.maxTradeSize)
          && currentInventory.inventoryItemType.maxTradeSize) {
          return currentInventory.inventoryItemType.maxTradeSize ||
            numberToFixed(+currentInventory.quantityRemained);
        }
        return numberToFixed(+currentInventory.quantityRemained);
      }
      return 999;
    },
    [currentInventory, currentOrder],
  );

  const handleGetValueOfynamicField = (item: InventoryItemTypeField) => {
    if (item.typeOfField === 'DATE') return (item.value && getDate(item.value as string)) || '';
    if (item.typeOfField === 'BOOLEAN') return (item.value === 'false' || item.value === '') ? 'No' : 'Yes';
    return item.value;
  };

  const getField = (item: InventoryItemTypeField) => (
    <Grid item xs={12} key={item.uiId}>
      {item.typeOfField !== 'DOCUMENTS' && (
        <TextField
          disabled
          value={handleGetValueOfynamicField(item)}
          name={item.name}
          variant="outlined"
          size="small"
          fullWidth
          id={item.name}
          className={classes.input}
          label={item.typeOfField !== 'NUMBER' ?
            (item.name).replace(/\/,/g, '.')
            :
            `${(item.name).replace(/\/,/g, '.')} ${item.format === 'PERCENT' ? '(%)' : ''} ${item.format === 'DOLLAR' ? '($)' : ''}`}
        />
      )}
    </Grid>
  );

  const getFieldDocuments = (item: any) => (
    <Grid item xs={12} key={item.uiId}>
      {item.typeOfField === 'DOCUMENTS' && (
        <>
          <Typography className={classes.label}>
            {(item.name).replace(/\/,/g, '.')}
          </Typography>
          <List>
            {item.proveDocuments.map((doc: { link: string; title: string }) => (
              <ListItem key={doc.title} className={classes.docList}>
                <DocUpload className={classes.iconMargin} />
                <ListItemText>
                  <p className={classes.docName}>
                    <Link href={`${constants.AWS_URL}${doc.link}`} target="_blank" rel="noreferrer">
                      {doc.title}
                    </Link>
                  </p>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Grid>
  );

  return (
    <Paper className={classes.paper}>
      <Prompt when={changes} message={handleBlockedNavigation} />
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleSaveChanges)}
      >
        {(orderId ? currentOrder : true) && currentInventory &&
          (
            <>
              <Grid item xs={12}>
                <Typography variant="h1" className={classes.titleCreate}>
                  {orderId ? 'Update' : 'Create'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  value={currentInventory.inventoryItemType.type}
                  name="type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="type"
                  className={classes.input}
                  label="Type"
                />
              </Grid>

              {currentInventory.instantCheckout === true && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={currentInventory.instantCheckout}
                        color="primary"
                        checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                        icon={<span className={classes.icon} />}
                      />
                    )}
                    label="Instant Checkout"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  disabled
                  value={currentInventory.title || '-'}
                  name="title"
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="title"
                  className={classes.input}
                  label="Title"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  value={currentInventory.shortDescription || '-'}
                  name="shortDescription"
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="shortDescription"
                  className={classes.input}
                  multiline
                  label="Description"
                />
              </Grid>
              <Grid container item xs={12} justifyContent="space-between">
                <Grid item xs={12} style={{ margin: 0 }}>
                  <Typography className={classes.label}>
                    {`Amount ${currentInventory.inventoryItemType.units}`}
                  </Typography>
                </Grid>
                {/* <Grid item xs={8} style={{ margin: 0 }}>
                  <Slider
                    name="quantity"
                    min={(!currentInventory && 1) ||
                      +currentInventory.inventoryItemType.minTradeSize === getMaxQuantity() ?
                      0 : +currentInventory.inventoryItemType.minTradeSize}
                    max={getMaxQuantity()}
                    step={currentInventory ?
                      numberToFixed(+currentInventory.inventoryItemType.tradeSizeIncrement)
                      : 1}
                    marks={
                      [{
                        value: (!currentInventory && 1) ||
                          +currentInventory.inventoryItemType.minTradeSize === getMaxQuantity() ?
                          0 : +currentInventory.inventoryItemType.minTradeSize,
                        label: (!currentInventory && 1) ||
                          +currentInventory.inventoryItemType.minTradeSize === getMaxQuantity() ?
                          0 : +currentInventory.inventoryItemType.minTradeSize,
                      },
                      {
                        value: getMaxQuantity(),
                        label: getMaxQuantity(),
                      }]
                    }
                    onChange={(event, value) => {
                      setValue('quantity', value as number);
                    }}
                    value={+watch().quantity || +(currentOrder?.quantity || 0)}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Controller
                    name="quantity"
                    control={control}
                    defaultValue={currentOrder ? currentOrder.quantity : ''}
                    render={({ onChange, value, name }) => (
                      <TextField
                        name={name}
                        value={value}
                        size="small"
                        variant="outlined"
                        fullWidth
                        error={!!errors.quantity?.message}
                        onChange={({ target }) => {
                          if (getMaxQuantity() >= +(target as HTMLInputElement).value) {
                            onChange(+target.value);
                            // setValue('quantity', +(target as HTMLInputElement).value);
                          } else {
                            onChange(getMaxQuantity());
                            // setValue('quantity', getMaxQuantity());
                          }
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                        }}
                      />
                    )}
                    rules={{
                      required: 'Required field',
                      validate: {
                        positive: (value) => parseFloat(value) > 0 || 'Should be greater than 0',
                        multipleIncrement: (value) => {
                          if (value !== currentInventory.inventoryItemType.minTradeSize) {
                            const amount =
                              +((value - currentInventory.inventoryItemType.minTradeSize) * 100)
                                .toFixed() /
                              +(currentInventory.inventoryItemType.tradeSizeIncrement * 100)
                                .toFixed();
                            const min = Math.floor(amount);
                            const max = Math.ceil(amount);
                            return Number.isInteger(amount) ||
                              `Nearest minimum value ${min * currentInventory.inventoryItemType.tradeSizeIncrement + currentInventory.inventoryItemType.minTradeSize}, nearest maximum value
                              ${max * currentInventory.inventoryItemType.tradeSizeIncrement + currentInventory.inventoryItemType.minTradeSize}`;
                            // `Quantity must be a multiple of ${currentInventory
                            // .inventoryItemType.tradeSizeIncrement}`;
                          }
                          return true;
                        },
                      },
                    }}
                  />
                </Grid>
                {errors.quantity &&
                  <Typography variant="caption" color="error">{errors.quantity.message}</Typography>}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="unitPrice"
                  control={control}
                  defaultValue={currentOrder ? currentOrder.unitPrice : null}
                  render={({ onChange, value, name }) => (
                    <TextField
                      label=" Unit price"
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={!!errors.unitPrice?.message}
                      name={name}
                      value={value}
                      onChange={({ target }) => {
                        onChange(+target.value);
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                    />
                  )}
                  rules={{
                    required: 'Required field',
                    validate: {
                      positive: (value) => parseFloat(value) > 0 || 'Should be greater than 0',
                      multipleIncrement: (value) => Number.isInteger(+(value * 100).toFixed() / +(currentInventory.inventoryItemType.priceIncrement * 100).toFixed()) || `Unit price must be a multiple of ${currentInventory.inventoryItemType.priceIncrement}`,
                    },
                  }}
                />
                {errors.unitPrice &&
                  <Typography variant="caption" color="error">{errors.unitPrice.message}</Typography>}
              </Grid>
              {currentInventory.inventoryItemType.inventoryItemTypeFieldsTypes.length > 0 &&
                currentInventory.inventoryItemType.inventoryItemTypeFieldsTypes.map((item: any) => (
                  getField(item)
                ))}
              {currentInventory.inventoryItemType.inventoryItemTypeFieldsTypes.length > 0 &&
                currentInventory.inventoryItemType.inventoryItemTypeFieldsTypes.map((item: any) => (
                  getFieldDocuments(item)
                ))}
              <Grid item xs={12}>
                <List>
                  {currentInventory.proveDocuments.map((doc: { link: string; title: string }) => (
                    <ListItem key={doc.title} className={classes.docList}>
                      <DocUpload className={classes.iconMargin} />
                      <ListItemText>
                        <p className={classes.docName}>
                          <Link href={`${constants.AWS_URL}${doc.link}`} target="_blank" rel="noreferrer">
                            {doc.title}
                          </Link>
                        </p>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12}>
                <ButtonSC
                  variant="fill"
                  text={(!isLoadingOrders && (orderId ? 'Update' : 'Create')) || ''}
                  size="large"
                  type="submit"
                  disabled={isLoadingOrders || !changes}
                  color="green"
                >
                  {isLoadingOrders && <CircularProgress color="primary" size={20} />}
                </ButtonSC>
              </Grid>
            </>
          )}
      </form>
      {openSaveChangesModal && (
        <ConfirmationWithoutSaving
          open={openSaveChangesModal}
          color="green"
          onAcceptButtonClicked={
            () => {
              handleConfirmNavigationClick();
            }
          }
          onCancelButtonClicked={
            () => closeModal()
          }
        />
      )}

    </Paper>
  );
};

export default CreateSellerOrder;
