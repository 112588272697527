import React, { FC, useState } from 'react';
import {
  FormControl,
  InputLabel,
} from '@material-ui/core';
import cx from 'classnames';
import CreatableSelect from 'react-select/creatable';

import useStyles from './StateAutocomplete.style';
import { newStates } from '../../core/constants';

interface Props {
  label: string;
  defaultValue: string;
  currentState: string;
  handleChange: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
}

const StateAutocomplete: FC<Props> = ({
  label,
  defaultValue,
  currentState,
  handleChange,
  disabled,
  error,
}) => {
  const classes = useStyles();
  const [shrink, setShrink] = useState<boolean>(false);
  const [focusState, setFocus] = useState<boolean>(false);
  return (
    <FormControl variant="outlined" size="small" fullWidth error={error}>
      <InputLabel shrink={!!currentState || shrink} focused={focusState} htmlFor="state" className={cx(classes.label, error && classes.errorLabel)}>{label}</InputLabel>
      <CreatableSelect
        styles={{
          menu: (provided, state) => ({
            ...provided,
            zIndex: 1500,
            boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
            borderRadius: 5,
            width: state.selectProps.width,
          }),
          menuList: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
          }),
          control: (base, state) => ({
            ...base,
            backgroundColor: '#fff',
            boxShadow: 'none',
            border: error ? '1px solid #DC5D5D':
              `1px solid ${state.isFocused ? '#87BC14' : '#c4c4c4'}`,
            '&:hover': {
              border: error ? '1px solid #DC5D5D':
                '1px solid #87BC14',
            },
          }),
          singleValue: (base) => ({
            ...base,
            color: '#075955',
          }),
        }}
        isClearable
        onChange={(newValue: any) => {
          handleChange(newValue?.value || '');
        }}
        onFocus={() => { setFocus(true); }}
        onBlur={() => { setFocus(false); }}

        onInputChange={(newValue: any) => setShrink(!!newValue)}
        defaultValue={newStates.find((st) => defaultValue === st.value)}
        // onInputChange={this.handleInputChange}
        options={newStates}
        formatCreateLabel={() => undefined}
        placeholder=""
        id="state"
        isDisabled={disabled}
      />
    </FormControl>
  );
};

export default StateAutocomplete;
