import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paperBrokerRole: {
    marginBottom: 16,
    padding: 16,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 15,
    boxShadow: 'none',
    textAlign: 'center',
  },
  roleString: {
    fontSize: 18,
    lineHeight: '21px',
    color: theme.palette.secondary.main,
  },
  nameRole: {
    fontSize: 18,
    lineHeight: '21px',
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    padding: '0 5px',
  },
}));

export default useStyles;
