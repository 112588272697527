import { makeStyles } from '@material-ui/core';

interface Props {
  color: 'red' | 'green';
}

const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor: '#FFF',
    padding: '0 5px',
    '&:hover': {
      color: theme.palette.primary.light,
    },
    '&.Mui-focused': {
      color: theme.palette.primary.light,
    },
  },
  errorLabel: {
    '&:hover': {
      color: theme.palette.error.main,
    },
    '&.Mui-focused': {
      color: theme.palette.error.main,
    },
  },
}));

export default useStyles;
