import React,
{
  FC,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Paper,
  FormControl,
  IconButton,
  FormHelperText,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { Location } from 'history';

import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLocation, Prompt, useHistory } from 'react-router-dom';
import useStyles from './EditPTS.style';
import getLocalStorageData from '../../api/localStorage';
import ButtonSC from '../ButtonSC/ButtonSC';
import { RootState } from '../../store/slices';
import { addNotification } from '../../store/slices/snackbar.slice';
import { NumberFormatCustom } from '../NumberFormat/NumberFormat';
import { getAllPostTradeProcessingTemplates, updatePTSTemplate } from '../../store/slices/tradeProcessingTemplate.slice';
import { PostTradeProcessingTemplates } from '../../interfaces/postTradeProcessingTemplates.interface';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import ConfirmationWithoutSaving from '../ConfirmationDialog/ConfirmationWithoutSaving/ConfirmationwithoutSaving';

export const EditPTS: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();

  const typeId = location.state && location.state.id;
  const typeType = location.state && location.state.type;

  const [steps, setSteps] = useState<PostTradeProcessingTemplates[]>([]);
  const [changes, setChanges] = useState<boolean>(false);
  const [openSaveChangesModal, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  // Selectors
  const {
    listOfPostTradeProcessingSteps,
    postTradeProcessingTemplateId,
    isLoading,
  } = useSelector(
    (state: RootState) => state.postTradeProcessingTemplates,
  );

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && changes && (location.pathname !== 'profile')) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    if (changes) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [confirmedNavigation, lastLocation, history, changes]);

  // Use Efffect
  useEffect(() => {
    const { token } = getLocalStorageData();
    dispatch(getAllPostTradeProcessingTemplates(typeId, token));
  }, [dispatch, typeId]);

  useEffect(() => {
    if (typeId) {
      const listOfPTS = listOfPostTradeProcessingSteps
        .map((item) => ({ ...item, uiId: new Date().getTime() + Math.random() }));
      setSteps(listOfPTS);
    }
  }, [typeId, listOfPostTradeProcessingSteps]);

  useEffect(() => {
    const stepsList = JSON.parse(JSON.stringify(steps));
    stepsList.map((item: PostTradeProcessingTemplates) => delete item.uiId);
    setChanges(JSON.stringify(listOfPostTradeProcessingSteps) !== JSON.stringify(stepsList));
  }, [listOfPostTradeProcessingSteps, steps]);

  const handleUpdateSteps = useCallback(
    () => {
      const { token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      const errorsCount: string[] = [];

      // eslint-disable-next-line array-callback-return
      steps.map((item) => {
        if (
          !item.description ||
          !item.step ||
          !item.responsible
        ) {
          errorsCount.push('Required field');
        }
        if (item.defaultValue && (+item.defaultValue < 0)) {
          errorsCount.push('Only positive or 0 value');
        }
        if (item.defaultValue && (+item.defaultValue > 100)) {
          errorsCount.push('Maximum number of days - 100');
        }
        if (steps.some(
          (step: PostTradeProcessingTemplates) => step.uiId !== item.uiId &&
            step.description === item.description,
        )) {
          errorsCount.push('Duplicate description');
        }
        if (steps.some(
          (step: PostTradeProcessingTemplates) => step.uiId !== item.uiId &&
            +step.step === +item.step,
        )) {
          errorsCount.push('Duplicate order number');
        }
        if (item.defaultValue === undefined) item.defaultValue = 0;
      });

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= steps.length; i++) {
        if (steps.find((item) => +item.step === +i) === undefined) {
          errorsCount.push(`Missed step ${i}`);
        }
      }

      if (errorsCount.length) {
        return dispatch(addNotification({
          key,
          message: errorsCount[0],
          options: { variant: 'error' },
        }));
      }
      return postTradeProcessingTemplateId && dispatch(updatePTSTemplate(steps, postTradeProcessingTemplateId, token, key));
    },
    [steps, dispatch, postTradeProcessingTemplateId],
  );

  const handleAddStep = useCallback(
    () => {
      const listOfSteps = JSON.parse(JSON.stringify(steps));
      listOfSteps.push({
        uiId: new Date().getTime() + Math.random(),
        step: listOfSteps.length + 1,
        description: '',
        responsible: '',
        defaultValue: undefined,
      });
      setSteps(listOfSteps);
    },
    [steps, setSteps],
  );

  const handleDelStep = useCallback(
    (id: number) => {
      setSteps(steps.filter((item) => item.uiId !== id));
    },
    [setSteps, steps],
  );

  const capitalize = (str: string, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match: any) => match.toUpperCase());

  const handleChangeStep = useCallback(
    (id: number, field: string, value: string) => {
      const listOfSteps = JSON.parse(JSON.stringify(steps));
      const itemStepsIndex =
        listOfSteps.findIndex((step: PostTradeProcessingTemplates) => step.uiId === id);
      if (field === 'step' || field === 'defaultValue') {
        listOfSteps[itemStepsIndex][field] = +value;
      } else if (field === 'description') {
        const capitalizeName = capitalize(value);
        listOfSteps[itemStepsIndex][field] = capitalizeName;
      } else {
        listOfSteps[itemStepsIndex][field] = value;
      }
      setSteps(listOfSteps);
    },
    [setSteps, steps],
  );

  const getStep = (item: PostTradeProcessingTemplates) => (
    <Grid xs={12} container key={item.uiId} spacing={2} className={classes.marginBottom10}>
      <Grid item xs={4} sm={2} md={1}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          value={item.step}
          onChange={({ target }) => handleChangeStep(item.uiId, 'step', target.value)}
          error={
            !item.step ||
            steps.some((field: PostTradeProcessingTemplates) => field.id !== item.id &&
              +field.step === +item.step) ||
            +item.step < 1
          }
          helperText={
            (!item.step && 'Required') ||
            (steps.some((step: PostTradeProcessingTemplates) => step.uiId !== item.uiId && +step.step === +item.step) && 'Duplicate') ||
            (item.step < 1 && 'Positive value required')
          }
          label="No.*"
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={10} md={4}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          value={item.description}
          onChange={({ target }) => handleChangeStep(item.uiId, 'description', target.value)}
          error={!item.description ||
            steps.some((field: PostTradeProcessingTemplates) => field.id !== item.id &&
              field.description === item.description)}
          helperText={(!item.description && 'Required') ||
            (steps.some((pts: PostTradeProcessingTemplates) => pts.uiId !== item.uiId && pts.description === item.description) && 'Duplicate')}
          label="Description*"
        />
      </Grid>
      <Grid item xs={12} sm={7} md={4}>
        <FormControl variant="outlined" size="small" fullWidth error={!item.responsible}>
          <InputLabel>Responsible*</InputLabel>
          <Select
            label="Responsible*"
            className={classes.typeSelect}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            value={item.responsible}
            onChange={({ target }) => handleChangeStep(item.uiId, 'responsible', target.value as string)}
          >
            <MenuItem value="" disabled>
              <em>None</em>
            </MenuItem>
            <MenuItem value="buyer">Buyer</MenuItem>
            <MenuItem value="seller">Seller</MenuItem>
          </Select>
          {!item.responsible && <FormHelperText>Required</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={8} sm={3} md={2}>
        <FormControl variant="outlined" size="small" fullWidth error={false}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={item.defaultValue !== undefined ? +item.defaultValue : item.defaultValue}
            onChange={({ target }) => handleChangeStep(item.uiId, 'defaultValue', target.value)}
            error={!item.defaultValue || +item.defaultValue < 0 || +item.defaultValue > 100}
            helperText={(!item.defaultValue && 'Required') || (item.defaultValue && +item.defaultValue < 0 && 'Only positive or 0 value') || (item.defaultValue && +item.defaultValue > 100 && 'Maximum number of days - 100')}
            label="Additional days*"
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4} sm={1} md={1} style={{ alignSelf: 'center' }}>
        <IconButton aria-label="add" onClick={() => handleDelStep(item.uiId)} style={{ padding: '0 12px' }}>
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <Paper className={classes.paperType}>
      <Prompt when={changes} message={handleBlockedNavigation} />
      <Grid container spacing={4}>
        <Grid container item xs={12} alignContent="flex-start">
          <Grid container alignItems="center" className={classes.marginBottom24}>
            <Grid item>
              <Typography variant="h2">{`${typeType} Steps`}</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="add" onClick={handleAddStep} className={cx(classes.padding5, classes.marginLeft40)}>
                <AddBoxIcon fontSize="large" style={{ color: '#87BC14' }} />
              </IconButton>
            </Grid>
          </Grid>
          {steps.slice().sort((a, b) => +a.step - +b.step).map((item) => (
            getStep(item)
          ))}
        </Grid>
        <Grid container item justifyContent="flex-end">
          <ButtonSC
            variant="fill"
            text={(!isLoading && 'Save changes') || ''}
            size="middle"
            handleClick={() => handleUpdateSteps()}
            disabled={isLoading || !changes}
            color="green"
            styleClass={classes.saveBtn}
          >
            {isLoading && <CircularProgress color="primary" size={20} />}
          </ButtonSC>
        </Grid>
      </Grid>
      {openSaveChangesModal && (
        <ConfirmationWithoutSaving
          open={openSaveChangesModal}
          color="green"
          onAcceptButtonClicked={
            () => {
              handleConfirmNavigationClick();
            }
          }
          onCancelButtonClicked={
            () => closeModal()
          }
        />
      )}
    </Paper>
  );
};

export default EditPTS;
