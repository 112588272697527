import React, { FC, useCallback } from 'react';
import {
  Grid, Button,
} from '@material-ui/core';
import cx from 'classnames';
import useStyles from './DealRoom.style';
import { NegotiationPointForDeal, PostTradeStepForDeal } from '../../interfaces/deals.interface';

interface NextStep {
  type: string;
  id: number;
  agreed: boolean | 'byOne';
}

interface Props {
  IA: NegotiationPointForDeal[];
  NP: NegotiationPointForDeal[];
  PTS: PostTradeStepForDeal[];
  nextStep: NextStep | false;
  userRole: 'Buyer' | 'Seller' | 'Unconfirmed' | 'Admin' | 'Broker' | 'Seller & Buyer' | 'Guest';
  executeScroll: (ref: any) => void;
  IARef: any;
  NPRef: any;
  PTSRef: any;
}

const Indicators: FC<Props> = ({
  IA, NP, PTS, nextStep, userRole, executeScroll, IARef, NPRef, PTSRef,
}) => {
  const classes = useStyles();

  const handleGetStyle = useCallback(
    (type: string) => {
      if (type === 'IA') {
        if (IA.filter((item: NegotiationPointForDeal) => !item.agreed).length === 0) return classes.green;
        if (IA.filter((item: NegotiationPointForDeal) => (!item.agreedBySeller && userRole === 'Seller' && item.agreedByBuyer) || (!item.agreedByBuyer && userRole === 'Buyer' && item.agreedBySeller)).length > 0) return classes.yellow;
        if ((nextStep as NextStep).type === 'IA') return classes.yellowBorder;
        if (IA.filter((item: NegotiationPointForDeal) => (item.agreedBySeller && userRole === 'Seller' && !item.agreedByBuyer) || (item.agreedByBuyer && userRole === 'Buyer' && !item.agreedBySeller)).length > 0) return classes.greenBorder;
        return classes.white;
      }
      if (type === 'NP') {
        if (NP.filter((item: NegotiationPointForDeal) => !item.agreed).length === 0) return classes.green;
        if (NP.filter((item: NegotiationPointForDeal) => (!item.agreedBySeller && userRole === 'Seller' && item.agreedByBuyer) || (!item.agreedByBuyer && userRole === 'Buyer' && item.agreedBySeller)).length > 0) return classes.yellow;
        if ((nextStep as NextStep).type === 'NP') return classes.yellowBorder;
        if (NP.filter((item: NegotiationPointForDeal) => (item.agreedBySeller && userRole === 'Seller' && !item.agreedByBuyer) || (item.agreedByBuyer && userRole === 'Buyer' && !item.agreedBySeller)).length > 0) return classes.greenBorder;
        return classes.white;
      }
      if (type === 'PTS') {
        if (PTS.filter((item: PostTradeStepForDeal) => !item.agreed).length === 0) return classes.green;
        if (PTS.filter((item: PostTradeStepForDeal) => (!item.agreedBySeller && userRole === 'Seller' && item.agreedByBuyer) || (!item.agreedByBuyer && userRole === 'Buyer' && item.agreedBySeller)).length > 0) return classes.yellow;
        if ((nextStep as NextStep).type === 'PTS') return classes.yellowBorder;
        if (PTS.filter((item: PostTradeStepForDeal) => (item.agreedBySeller && userRole === 'Seller' && !item.agreedByBuyer) || (item.agreedByBuyer && userRole === 'Buyer' && !item.agreedBySeller)).length > 0) return classes.greenBorder;
        return classes.white;
      }
      return classes.white;
    },
    [IA, NP, PTS, nextStep],
  );

  return (
    <Grid
      container
      spacing={1}
      className={classes.indicatorsContainer}
    >
      {IA.length > 0 && (
        <Grid item>
          <Button
            className={cx(
              classes.indicatorButton,
              handleGetStyle('IA'),
            )}
            onClick={() => executeScroll(IARef)}
          >
            IA
          </Button>
        </Grid>
      )}
      {NP.length > 0 && (
        <Grid item>
          <Button
            className={cx(
              classes.indicatorButton,
              handleGetStyle('NP'),
            )}
            onClick={() => executeScroll(NPRef)}
          >
            NP
          </Button>
        </Grid>
      )}
      {PTS.length > 0 && (
        <Grid item>
          <Button
            className={cx(
              classes.indicatorButton,
              handleGetStyle('PTS'),
            )}
            onClick={() => executeScroll(PTSRef)}
          >
            PTS
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Indicators;
