import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import { InventoryTypeState, InventoryItemType, InventoryItemTypeToSend } from '../../interfaces/inventoryTypes.interface';
import {
  apiGetInventoryTypes,
  apiCreateInventoryType,
  apiUpdateInventoryType,
  apiGetInventoryTypeById,
} from '../../api/inventoryTypes';
import translationEN from '../../locales/en/translation.json';

const initialState: InventoryTypeState = {
  inventoryType: null,
  listOfInventoryTypes: [],
  error: '',
  isLoading: false,
};

const inventoryTypesSlice = createSlice({
  initialState,
  name: 'inventoryTypes',
  reducers: {
    inventoryTypeRequest(state) {
      state.inventoryType = null;
      state.error = '';
      state.isLoading = true;
    },
    inventoryTypeSuccess(state, action) {
      state.inventoryType = null;
      const index = state.listOfInventoryTypes
        .findIndex((obj: InventoryItemType) => obj.id === action.payload.id);
      if (index !== -1) {
        state.listOfInventoryTypes[index] = action.payload;
      } else {
        state.listOfInventoryTypes.push(action.payload);
      }
      state.error = '';
      state.isLoading = false;
    },
    inventoryTypeFailure(state, action) {
      state.inventoryType = null;
      state.error = action.payload;
      state.isLoading = false;
    },
    listOfInventoryTypesRequest(state) {
      state.inventoryType = null;
      state.listOfInventoryTypes = [];
      state.error = '';
      state.isLoading = true;
    },
    listOfInventoryTypesSuccess(state, action) {
      state.inventoryType = null;
      state.listOfInventoryTypes = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    listOfInventoryTypesFailure(state, action) {
      state.inventoryType = null;
      state.listOfInventoryTypes = [];
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  inventoryTypeRequest,
  inventoryTypeSuccess,
  inventoryTypeFailure,
  listOfInventoryTypesRequest,
  listOfInventoryTypesSuccess,
  listOfInventoryTypesFailure,
} = inventoryTypesSlice.actions;

export const createInventoryType = (
  inventoryType: InventoryItemTypeToSend,
  relocate: () => void,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(inventoryTypeRequest());
    const inventoryTypeInfo =
      await dispatch(apiCreateInventoryType(inventoryType, userId, token));
    dispatch(inventoryTypeSuccess(inventoryTypeInfo));
    dispatch(addNotification({
      key,
      message: translationEN.NewInventoryTypeCreated,
      options: { variant: 'success' },
    }));
    relocate();
  } catch (error) {
    dispatch(inventoryTypeFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const updateInventoryType = (
  inventoryTypeData: InventoryItemType,
  relocate: () => void,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(inventoryTypeRequest());
    const inventoryTypeInfo =
      await dispatch(apiUpdateInventoryType(inventoryTypeData, userId, token));
    dispatch(inventoryTypeSuccess(inventoryTypeInfo));
    dispatch(addNotification({
      key,
      message: translationEN.InventoryTypeUpdated,
      options: { variant: 'success' },
    }));
    relocate();
  } catch (error) {
    dispatch(inventoryTypeFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getInventoryTypes = (
  userId: number | null,
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(listOfInventoryTypesRequest());
    const inventoryInfo = await apiGetInventoryTypes(userId);
    dispatch(listOfInventoryTypesSuccess(inventoryInfo));
  } catch (error) {
    dispatch(listOfInventoryTypesFailure(
      error?.response ? error?.response?.data?.error : error?.message,
    ));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getInventoryTypeById = (
  idInventoryType: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(inventoryTypeRequest());
    const inventoryTypeInfo =
      await dispatch(apiGetInventoryTypeById(idInventoryType, userId, token));
    dispatch(inventoryTypeSuccess(inventoryTypeInfo));
  } catch (error) {
    dispatch(inventoryTypeFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default inventoryTypesSlice.reducer;
