import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const Requests = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 18">
    <path d="M8 18C8.91524 18 9.69203 17.4249 9.92715 16.6468C10.007 16.3825 9.77614 16.1538 9.5 16.1538H6.5C6.22386 16.1538 5.99321 16.3823 6.0726 16.6468C6.16402 16.9513 6.33946 17.2319 6.58579 17.4593C6.96086 17.8055 7.46957 18 8 18ZM14.1609 12.61C14.0583 12.5154 14 12.3822 14 12.2426V7.84615C14 5.14387 12.5087 2.86127 9.89041 2.11088C9.66465 2.04619 9.5 1.84498 9.5 1.61014V1.38462C9.5 0.618462 8.83 0 8 0C7.17 0 6.5 0.618462 6.5 1.38462V1.60984C6.5 1.84482 6.33515 2.04612 6.10919 2.11058C3.48249 2.85995 2 5.13477 2 7.84615V12.2426C2 12.3822 1.94168 12.5154 1.83914 12.61L0.16086 14.1592C0.0583188 14.2539 0 14.3871 0 14.5266V14.7308C0 15.0069 0.223858 15.2308 0.5 15.2308H15.5C15.7761 15.2308 16 15.0069 16 14.7308V14.5266C16 14.3871 15.9417 14.2539 15.8391 14.1592L14.1609 12.61Z" />
  </SvgIcon>
);

export default Requests;
