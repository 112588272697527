import React, {
  FC, useState, useCallback, useEffect,
} from 'react';
import cx from 'classnames';
import {
  Grid,
  TextField,
  Typography,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  Link,
  IconButton,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import AvatarPhoto from './AvatarPhoto';
import constants from '../../core/constants';
import useStyles from './ProfilePage.style';
import FileUploader from '../FileUploader/FileUploader';
import DocUpload from '../../svg/docUpload';
import { NumberFormatZip } from '../NumberFormat/NumberFormat';
import { getDateUTC12H } from '../../utils/getDate';
import StateAutocomplete from '../StateAutocomplete/StateAutocomplete';
import TextFieldProfile from '../TextFieldProfile/TextFieldProfile';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../store/slices/snackbar.slice';

interface Props {
  control: any;
  errors: any;
  userInfo: any;
  newFiles: any;
  currentTab: number;
  setDocuments: (field: string, value: string | Date | undefined | File | null | File[]) => void;
  watchData: any;
  setValue: any;
  clearDocuments: any;
  clearErrors: any;
}

const PersonalInfo: FC<Props> = ({
  control,
  errors,
  userInfo,
  newFiles,
  setValue,
  currentTab,
  watchData,
  setDocuments,
  clearDocuments,
  clearErrors,
}) => {
  const classes = useStyles();
  const [isOpenDelFileModal, openDelFileModal] = useState({ open: false, name: '' });
  const [driverLicenseFrontUri, setDriverLicenseFrontUri] = useState<string>('');
  const [driverLicenseBackUri, setDriverLicenseBackUri] = useState<string>('');
  const [passportStateIdUri, setPassportStateIdUri] = useState<string>('');

  const dispatch = useDispatch();


  const handleGetMaxDate = useCallback(
    (type: string, value: number) => {
      const date = new Date(new Date().setUTCHours(12, 0, 0, 0));
      if (type === 'year') date.setFullYear(date.getFullYear() - value);
      if (type === 'hours') date.setHours(date.getHours() - value);
      return date;
    },
    [],
  );

  const handleGetMinDate = useCallback(
    (type: string, value: number) => {
      const date = new Date(new Date().setUTCHours(12, 0, 0, 0));
      if (type === 'hours') date.setHours(date.getHours() + value);
      return date;
    },
    [],
  );

  const fileToDataUri = (file: any) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result);
    };
    reader.readAsDataURL(file[0]);
  });

  useEffect(() => {
    if (newFiles?.driverLicenseFront) fileToDataUri(newFiles.driverLicenseFront).then((dataUri) => setDriverLicenseFrontUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (newFiles?.driverLicenseBack) fileToDataUri(newFiles.driverLicenseBack).then((dataUri) => setDriverLicenseBackUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
    if (newFiles?.stateIdDocument || newFiles?.passport) fileToDataUri(newFiles.stateIdDocument || newFiles.passport).then((dataUri) => setPassportStateIdUri(`data:application/octet-stream;base64, ${(dataUri as string).split(',')[1]}`));
  }, [newFiles]);

  return (
    <Grid container justifyContent="center" className={currentTab === 0 ? classes.fullHeight : classes.displayNone} spacing={2}>
      <Hidden smUp>
        <AvatarPhoto
          setValue={(field, file) => {
            setDocuments(field, file);
          }}
          photo={(newFiles?.profilePhotoLink && `${constants.AWS_URL}${newFiles.profilePhotoLink}`) || ''}
        />
      </Hidden>
      <Grid
        container
        item
        xs={12}
        sm={8}
        spacing={2}
        alignContent="flex-start"
      >
        {/* Email */}
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="email"
            control={control}
            defaultValue={userInfo.email}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="Email*"
                error={!!errors.email?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i,
                message: 'Incorrect value',
              },
            }}
          />
          {errors.email &&
            // <Typography variant="caption" color="error">{errors.email.message}</Typography>}

            <Typography variant="caption" color="error">{'This email already exists in database!'}</Typography>}
        </Grid>
        {/* Date Of Birth */}
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="dateOfBirth"
            control={control}
            defaultValue={userInfo.dateOfBirth && new Date(userInfo.dateOfBirth)}
            render={({ onChange, value, name }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  name={name}
                  inputVariant="outlined"
                  label="Date Of Birth*"
                  format="MM/dd/yyyy"
                  maxDate={handleGetMaxDate('year', 18)}
                  value={value}
                  onChange={(date) => {
                    onChange(getDateUTC12H(date));
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk
                  fullWidth
                  error={!!errors.dateOfBirth?.message}
                  invalidDateMessage=""
                  maxDateMessage=""
                />
              </MuiPickersUtilsProvider>
            )}
            rules={{
              required: 'Required field',
              validate: (value) => {
                // eslint-disable-next-line no-restricted-globals
                if (value && isNaN(value.getTime())) return 'Invalid date';
                if (value && value.getTime() > handleGetMaxDate('year', 18).getTime()) return 'Minimum age 18 years old';
                return true;
              },
            }}
          />
          {errors.dateOfBirth &&
            <Typography variant="caption" color="error">{errors.dateOfBirth.message}</Typography>}
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6} md={4} />
        </Hidden>
        {/* First Name */}
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="firstName"
            control={control}
            defaultValue={userInfo.firstName}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="First Name*"
                error={!!errors.firstName?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: /^[A-Za-z- ']+$/i,
                message: 'Incorrect value',
              },
            }}
          />
          {errors.firstName &&
            <Typography variant="caption" color="error">{errors.firstName.message}</Typography>}
        </Grid>
        {/* Middle Name */}
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="middleName"
            control={control}
            defaultValue={userInfo.middleName}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="Middle Name"
                error={!!errors.middleName?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
            rules={{
              pattern: {
                value: /^[A-Za-z- ']+$/i,
                message: 'Incorrect value',
              },
            }}
          />
          {errors.middleName &&
            <Typography variant="caption" color="error">{errors.middleName.message}</Typography>}
        </Grid>
        {/* Last Name */}
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="lastName"
            control={control}
            defaultValue={userInfo.lastName}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="Last Name*"
                error={!!errors.lastName?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: /^[A-Za-z- ']+$/i,
                message: 'Incorrect value',
              },
            }}
          />
          {errors.lastName &&
            <Typography variant="caption" color="error">{errors.lastName.message}</Typography>}
        </Grid>
        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          <Grid item xs={12} sm={6} md={4} />
        </Hidden>
        {/* Main Number */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={cx(classes.phone, !!errors.mainNumber && classes.errorPhone)}
        >
          <Controller
            name="mainNumber"
            control={control}
            defaultValue={userInfo.mainNumber || '1'}
            render={({ onChange, value, name }) => (
              <PhoneInput
                country="us"
                countryCodeEditable={false}
                onlyCountries={['us', 'mx', 'ca']}
                value={value}
                inputProps={{
                  name,
                  className: (cx(
                    classes.inputPhone,
                    !!errors.mainNumber && classes.errorInputPhone,
                  )),
                  autoComplete: 'new-password',
                }}
                onChange={(tel) => {
                  if (userInfo.mainNumber === '' && (tel === '1' || tel === '52') && (value === '1' || value === '52')) setValue('mainNumber', tel, { shouldDirty: false });
                  else if (userInfo.mainNumber === '' && (tel === '1' || tel === '52') && value !== '1' && value !== '52') {
                    setValue('mainNumber', '1', { shouldDirty: true });
                    clearErrors('mainNumber');
                  } else setValue('mainNumber', tel, { shouldDirty: true });
                }}
                specialLabel="Main Number"
                isValid={false}
              />
            )}
            rules={{
              validate: (value) => {
                if (value) return ((value === '1') || (value === '52') || (value.length >= 11)) || 'Invalid value';
                return true;
              },
            }}
          />
          {errors.mainNumber && <Typography variant="caption" color="error">{errors.mainNumber?.message}</Typography>}
        </Grid>
        {/* Mobile Number */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={cx(classes.phone, !!errors.mobileNumber && classes.errorPhone)}
        >
          <Controller
            name="mobileNumber"
            control={control}
            defaultValue={userInfo.mobileNumber || '1'}
            render={({ onChange, value, name }) => (
              <PhoneInput
                country="us"
                countryCodeEditable={false}
                onlyCountries={['us', 'mx', 'ca']}
                value={value}
                inputProps={{
                  name,
                  className: (cx(
                    classes.inputPhone,
                    !!errors.mobileNumber && classes.errorInputPhone,
                  )),
                  autoComplete: 'new-password',
                }}
                // onChange={(tel) => {
                //   onChange(tel || '');
                // }}
                onChange={(tel) => {
                  if (userInfo.mobileNumber === '' && (tel === '1' || tel === '52') && (value === '1' || value === '52')) setValue('mobileNumber', tel, { shouldDirty: false });
                  else if (userInfo.mobileNumber === '' && (tel === '1' || tel === '52') && value !== '1' && value !== '52') setValue('mobileNumber', '1', { shouldDirty: true });
                  else setValue('mobileNumber', tel, { shouldDirty: true });
                }}
                specialLabel="Mobile Number*"
              />
            )}
            rules={{
              validate: (value) => {
                if (value && (value === '1' || value === '52')) return 'Required field';
                if (value) return value.length >= 11 || 'Invalid value';
                return true;
              },
              required: 'Required field',
            }}
          />
          {errors.mobileNumber && <Typography variant="caption" color="error">{errors.mobileNumber?.message}</Typography>}
        </Grid>

        <Hidden smDown>
          <Grid item xs={12} md={4} />
        </Hidden>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="line1"
            control={control}
            defaultValue={userInfo.line1 || ''}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="Address Line 1"
                error={!!errors.line1?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
          // rules={{
          //   required: 'Required field',
          // }}
          />
          {errors.line1 &&
            <Typography variant="caption" color="error">{errors.line1.message}</Typography>}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="line2"
            control={control}
            defaultValue={userInfo.line2 || ''}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="Address Line 2"
                error={!!errors.line2?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
          />
          {errors.line2 &&
            <Typography variant="caption" color="error">{errors.line2.message}</Typography>}
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={4} />
        </Hidden>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="city"
            control={control}
            defaultValue={userInfo.city || ''}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="Сity"
                error={!!errors.city?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
          // rules={{
          //   required: 'Required field',
          // }}
          />
          {errors.city &&
            <Typography variant="caption" color="error">{errors.city.message}</Typography>}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="state"
            control={control}
            defaultValue={userInfo.state || ''}
            render={({ onChange }) => (
              <StateAutocomplete
                label="State*"
                defaultValue={userInfo.state || ''}
                currentState={watchData.state || userInfo.state}
                handleChange={(newValue: any) => {
                  onChange(newValue);
                }}
                error={!!errors.state?.message}
              />
            )}
          rules={{
            required: 'Required field',
          }}
          />
          {errors.state &&
            <Typography variant="caption" color="error">{errors.state.message}</Typography>}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="zip"
            control={control}
            defaultValue={userInfo.zip || ''}
            render={({ onChange, value, name }) => (
              <TextField
                name={name}
                size="small"
                InputLabelProps={{ shrink: !!value }}
                variant="outlined"
                fullWidth
                label="Zip Code*"
                autoComplete="new-password"
                value={value}
                onChange={({ target }) => {
                  onChange(target.value);
                }}
                error={!!errors.zip?.message}
                InputProps={{
                  inputComponent: NumberFormatZip as any,
                }}
              />
            )}
            rules={{
              required: 'Required field',
              minLength: {
                value: 5,
                message: 'Invalid postal code: 5 characters required',
              },
            }}
          />
          {errors.zip &&
            <Typography variant="caption" color="error">{errors.zip.message}</Typography>}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name="country"
            control={control}
            defaultValue={userInfo.country || ''}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="Country"
                error={!!errors.country?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
          // rules={{
          //   required: 'Required field',
          // }}
          />
          {errors.country &&
            <Typography variant="caption" color="error">{errors.country.message}</Typography>}
        </Grid>

      </Grid>
      <Hidden xsDown>
        <AvatarPhoto
          setValue={(field, file) => {
            setDocuments(field, file);
          }}
          photo={(newFiles?.profilePhotoLink && `${constants.AWS_URL}${newFiles.profilePhotoLink}`) || ''}
        />
      </Hidden>

      <Grid
        container
        item
        xs={12}
        spacing={2}
      >
        <Grid item xs={12} className={classes.alignSelfCenter}>
          <Typography variant="h1" className={classes.title}>Notifications</Typography>
        </Grid>
        <Grid item>
          <Controller
            render={({ onChange, name, value }) => (
              <FormControlLabel
                control={(
                  <Checkbox
                    name={name}
                    checked={value}
                    checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      if (e.target.checked) {
                        clearErrors('recieveEmailMessages');
                        clearErrors('recieveSMSMessages');
                      }
                    }}
                  />
                )}
                label="Email"
              />
            )}
            defaultValue={userInfo.recieveEmailMessages || false}
            name="recieveEmailMessages"
            value="primary"
            control={control}
            rules={{
              required: watchData.recieveSMSMessages ? false : 'Must Accept One Of Notification Type',
            }}
          />
        </Grid>
        <Grid item>
          <Controller
            render={({ onChange, name, value }) => (
              <FormControlLabel
                control={(
                  <Checkbox
                    name={name}
                    checked={value}
                    checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      if (e.target.checked) {
                        clearErrors('recieveEmailMessages');
                        clearErrors('recieveSMSMessages');
                      }
                    }}
                  />
                )}
                label="SMS"
              />
            )}
            defaultValue={userInfo.recieveSMSMessages || false}
            name="recieveSMSMessages"
            value="primary"
            control={control}
            rules={{
              required: watchData.recieveEmailMessages ? false : 'Must Accept One Of Notification Type',
            }}
          />
        </Grid>
        <Grid item style={{ alignSelf: 'center' }}>
          {(errors.recieveSMSMessages || errors.recieveEmailMessages) &&
            <Typography variant="caption" color="error">{errors.recieveSMSMessages?.message || errors.recieveEmailMessages?.message}</Typography>}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Typography variant="h5" className={classes.title}>
          Government Issued Picture ID
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={4}
        spacing={2}
      >
        <Grid item xs={12}>
          <Controller
            name="governmentIdType"
            control={control}
            defaultValue={userInfo.governmentIdType || ''}
            render={({ onChange, value, name }) => (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>ID Type</InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={({ target }) => {
                    onChange(target.value);
                    setValue('governmentIdNumber', '');
                    setValue('governmentIdDateOfIssue', null);
                    setValue('governmentIdDateOfExpiration', null);
                    clearDocuments();
                  }}
                  value={value}
                  label="ID Type*"
                  name={name}
                  error={!!errors.governmentIdType?.message}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="DRIVER LICENSE">Driver License</MenuItem>
                  <MenuItem value="STATE ID">State ID</MenuItem>
                  <MenuItem value="PASSPORT">Passport</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {errors.governmentIdType && <Typography variant="caption" color="error">{errors.governmentIdNumber?.message}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="governmentIdNumber"
            control={control}
            defaultValue={userInfo.governmentIdNumber || ''}
            render={({ onChange, value, name }) => (
              <TextFieldProfile
                name={name}
                value={value}
                label="ID Number"
                error={!!errors.governmentIdNumber?.message}
                handleChange={(data) => {
                  onChange(data);
                }}
              />
            )}
          />
          {errors.governmentIdNumber && <Typography variant="caption" color="error">{errors.governmentIdNumber?.message}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="governmentIdDateOfIssue"
            control={control}
            defaultValue={userInfo.governmentIdDateOfIssue &&
              new Date(userInfo.governmentIdDateOfIssue)}
            render={({ onChange, value, name }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  name={name}
                  inputVariant="outlined"
                  label="Date Of Issue"
                  format="MM/dd/yyyy"
                  maxDate={handleGetMaxDate('hours', 24)}
                  value={value}
                  onChange={(date) => {
                    onChange(getDateUTC12H(date));
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk
                  fullWidth
                  error={!!errors.governmentIdDateOfIssue?.message}
                  invalidDateMessage=""
                  maxDateMessage=""
                />
              </MuiPickersUtilsProvider>
            )}
            rules={{
              // required: 'Required field',
              validate: (value) => {
                // eslint-disable-next-line no-restricted-globals
                if (value && isNaN(value.getTime())) return 'Invalid date';
                if (value && value.getTime() > handleGetMaxDate('hours', 24).getTime()) return 'Issue Date should be prior to today’s date';
                return true;
              },
            }}
          />
          {errors.governmentIdDateOfIssue &&
            <Typography variant="caption" color="error">{errors.governmentIdDateOfIssue.message}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="governmentIdDateOfExpiration"
            control={control}
            defaultValue={userInfo.governmentIdDateOfExpiration &&
              new Date(userInfo.governmentIdDateOfExpiration)}
            render={({ onChange, value, name }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  name={name}
                  inputVariant="outlined"
                  label="Date Of Expiration"
                  format="MM/dd/yyyy"
                  minDate={handleGetMinDate('hours', 24)}
                  value={value}
                  onChange={(date) => {
                    onChange(getDateUTC12H(date));
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk
                  fullWidth
                  error={!!errors.governmentIdDateOfExpiration?.message}
                  invalidDateMessage=""
                  minDateMessage=""
                />
              </MuiPickersUtilsProvider>
            )}
            rules={{
              // required: 'Required field',
              validate: (value) => {
                // eslint-disable-next-line no-restricted-globals
                if (value && isNaN(value.getTime())) return 'Invalid date';
                if (value && value.getTime() < handleGetMinDate('hours', 24).getTime()) return 'Expiration Date should be a future date';
                return true;
              },
            }}
          />
          {errors.governmentIdDateOfExpiration &&
            <Typography variant="caption" color="error">{errors.governmentIdDateOfExpiration.message}</Typography>}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={8}
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="body1" className={classes.body1}>
            Documents
          </Typography>
          <Typography variant="body2" className={classes.body2}>
            Upload a copy of the document, up to 10Mb.
            For driver’s license please upload copies of both front and back
          </Typography>
        </Grid>
        {((watchData.governmentIdType as string) === 'PASSPORT' || (watchData.governmentIdType as string) === 'STATE ID') && (
          <>
            <Grid item xs={12} sm={6}>
              <FileUploader
                multiSelect={false}
                uploadFile={(file) => {
                  setDocuments(watchData.governmentIdType === 'PASSPORT' ? 'passport' : 'stateIdDocument', file);
                }}
              />
              {(newFiles.passportMediaLink || newFiles.stateIdDocumentMediaLink) && (
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={2}>
                    <DocUpload />
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.docName}>
                      <Link href={`${constants.AWS_URL}${newFiles.passportMediaLink ? newFiles.passportMediaLink.link : newFiles.stateIdDocumentMediaLink.link}`} target="_blank" rel="noreferrer">
                        {newFiles.passportMediaLink ? newFiles.passportMediaLink.title :
                          newFiles.stateIdDocumentMediaLink.title}
                      </Link>
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => { openDelFileModal({ open: true, name: (newFiles.passportMediaLink && 'passportMediaLink') || 'stateIdDocumentMediaLink' }); }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              {(newFiles.passport || newFiles.stateIdDocument) && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      New:
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                      <DocUpload />
                    </Grid>
                    <Grid item xs={8}>
                      <p className={classes.docName}>
                        <Link
                          href={passportStateIdUri}
                          download={(newFiles.passport && newFiles.passport[0].name) ||
                            (newFiles.stateIdDocument && newFiles.stateIdDocument[0].name)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {(newFiles.passport && newFiles.passport[0].name) ||
                            (newFiles.stateIdDocument && newFiles.stateIdDocument[0].name)}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => { openDelFileModal({ open: true, name: (watchData.governmentIdType === 'PASSPORT' && 'passport') || 'stateIdDocument' }); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}

        {(watchData.governmentIdType as string) === 'DRIVER LICENSE' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                Front Side
              </Typography>
              <FileUploader
                multiSelect={false}
                uploadFile={(file) => {
                  setDocuments('driverLicenseFront', file);
                }}
              />
              {newFiles.driverLicenseFrontMediaLink && (
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={2}>
                    <DocUpload />
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.docName}>
                      <Link href={`${constants.AWS_URL}${newFiles.driverLicenseFrontMediaLink.link}`} target="_blank" rel="noreferrer">
                        {newFiles.driverLicenseFrontMediaLink.title}
                      </Link>
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => { openDelFileModal({ open: true, name: 'driverLicenseFrontMediaLink' }); }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              {newFiles.driverLicenseFront && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      New:
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                      <DocUpload />
                    </Grid>
                    <Grid item xs={8}>
                      <p className={classes.docName}>
                        {/* {driverLicenseFront && driverLicenseFront[0].name} */}
                        <Link href={driverLicenseFrontUri} download={newFiles.driverLicenseFront && newFiles.driverLicenseFront[0].name} target="_blank" rel="noreferrer">
                          {newFiles.driverLicenseFront && newFiles.driverLicenseFront[0].name}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => { openDelFileModal({ open: true, name: 'driverLicenseFront' }); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                Back Side
              </Typography>
              <FileUploader
                multiSelect={false}
                uploadFile={(file) => {
                  setDocuments('driverLicenseBack', file);
                }}
              />
              {newFiles.driverLicenseBackMediaLink && (
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={2}>
                    <DocUpload />
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.docName}>
                      <Link href={`${constants.AWS_URL}${newFiles.driverLicenseBackMediaLink.link}`} target="_blank" rel="noreferrer">
                        {newFiles.driverLicenseBackMediaLink.title}
                      </Link>
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => { openDelFileModal({ open: true, name: 'driverLicenseBackMediaLink' }); }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              {newFiles.driverLicenseBack && (
                <>
                  <Typography variant="body1" className={classes.body1}>
                    New:
                  </Typography>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                      <DocUpload />
                    </Grid>
                    <Grid item xs={8}>
                      <p className={classes.docName}>
                        {/* {driverLicenseBack && driverLicenseBack[0].name} */}
                        <Link href={driverLicenseBackUri} download={newFiles.driverLicenseBack && newFiles.driverLicenseBack[0].name} target="_blank" rel="noreferrer">
                          {newFiles.driverLicenseBack && newFiles.driverLicenseBack[0].name}
                        </Link>
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => { openDelFileModal({ open: true, name: 'driverLicenseBack' }); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>

      {isOpenDelFileModal.open && (
        <ConfirmationDialog
          open={isOpenDelFileModal.open}
          title="File deletion"
          description={(
            <p>
              Are you sure you want to delete the selected file?
            </p>
          )}
          acceptButtonText="Delete"
          cancelButtonText="Cancel"
          color="green"
          onAcceptButtonClicked={
            () => {
              setDocuments(isOpenDelFileModal.name, undefined);
              openDelFileModal({ open: false, name: '' });
            }
          }
          onCancelButtonClicked={
            () => openDelFileModal({ open: false, name: '' })
          }
        />
      )}
    </Grid>
  );
};

export default PersonalInfo;
