import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: 5,
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.secondary.contrastText}`,
    'input:hover ~ &': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'" +
        "%3E%3Cpath d='M5.36433 8.81296C5.2867 8.88842 5.16314 8.88842 5.08551 8.81296L1.9181 5.73398C1.72404 5.54535 1.41513 5.54534" +
        ' 1.22107 5.73398L0.36882 6.56244C0.166838 6.75878 0.166838 7.08314 0.36882 7.27948L4.87641 11.6612C5.07046 11.8499 5.37938' +
        ' 11.8499 5.57343 11.6612L15.6312 1.88428C15.8332 1.68794 15.8332 1.36358 15.6312 1.16724L14.7789 0.338782C14.5849 0.150146 ' +
        "14.276 0.150146 14.0819 0.338782L5.36433 8.81296Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
    },
  },
  labelText: {
    color: theme.palette.secondary.main,
  },
  checkBoxWithLabel: {
    margin: '0 5px 0 0',
  },
}));

export default useStyles;
