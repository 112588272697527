import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actions: {
    color: `${theme.palette.primary.light} !important`,
    fontSize: 24,
    paddingLeft: 0,
  },
  spacer: {
    display: 'none',
  },
  root: {
    justifyContent: 'flex-end',
    '@media (max-width: 340px)': {
      flexWrap: 'wrap',
    }
  },
  addBtn: {
    fontFamily: 'Arimo, Arial',
    fontSize: 14,
    fontWeight: 700,
    padding: '10px 15px',
    background: theme.palette.primary.main,
    borderRadius: 5,
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.light,
      boxShadow: 'none',
    },
    webkitappearance: 'none',
    '@media (max-width: 340px)': {
      fontSize: 14,
      fontWeight: 600,
      padding: '10px 15px',
      borderRadius: 5,
      boxShadow: 'none',
      color: 'white',
      width:'72vw',
      marginBottom: 5,
    }
  },
  buttonHeader: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#979BA9'
  },
}));

export default useStyles;
