import React, { FC, useCallback, useState, useEffect, Fragment } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Location } from "history";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Paper,
  FormControl,
  FormControlLabel,
  Checkbox,
  Avatar,
  Link,
  Divider,
  InputLabel,
} from "@material-ui/core";
import PageviewIcon from "@material-ui/icons/Pageview";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import {
  createInventory,
  getInventoryById,
  updateInventory,
} from "../../store/slices/inventory.slice";
import useStyles from "./Inventory.style";
import { RootState } from "../../store/slices";
import { getInventoryTypes } from "../../store/slices/inventoryTypes.slice";
import { InventoryItemType } from "../../interfaces/inventoryTypes.interface";
import { addNotification } from "../../store/slices/snackbar.slice";
import getLocalStorageData from "../../api/localStorage";
import MultiFileUploader from "../FileUploader/MultiFileUploader";
import ButtonSC from "../ButtonSC/ButtonSC";
import LabAttestationModal from "./LabAttestationModal";
import GeneralAttestationModal from "./GeneralAttestationModal";

import { getDate, getDateUTC12H } from "../../utils/getDate";
import {
  NumberFormatCustom,
  NumberFormatZip,
} from "../NumberFormat/NumberFormat";
import ImageUploader from "../ImageUploader/ImageUploader";
import constants from "../../core/constants";
import StateAutocomplete from "../StateAutocomplete/StateAutocomplete";
import TextFieldProfile from "../TextFieldProfile/TextFieldProfile";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import ConfirmationWithoutSaving from "../ConfirmationDialog/ConfirmationWithoutSaving/ConfirmationwithoutSaving";

export const CreateInventory: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    setError,
    watch,
    formState,
  } = useForm();

  const [typeInventory, setTypeInventory] =
    useState<InventoryItemType | null>();

  const [currentInventory, setCurrentInventory] = useState<any>({});
  const [fieldsInventory, setFieldsInventory] = useState<any[]>();
  const [currentFieldsInventory, setCurrentFieldsInventory] = useState<any[]>();

  const [currentAttestationDocuments, setCurrentAttestationDocuments] =
    useState<Array<{ link: string; title: string }>>([]);
  const [newAttestationDocuments, setNewAttestationDocuments] = useState<
    Array<File>
  >([]);

  const [productPicture, setProductPicture] = useState<string>("");
  const [uploadPhoto, setUploadPhoto] = useState<boolean>(false);
  const [productPictureFile, setProductPictureFile] = useState<File | null>(
    null
  );

  const [generalAttestationDate, setGeneralAttestationDate] =
    useState<Date | null>(null);
  const [signature, setSignature] = useState<number | false>(false);

  const [openLab, setOpenLab] = useState(false);
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openQuantity, setOpenQuantity] = useState(false);

  const location: any = useLocation();
  const inventoryId = location.state && location.state.inventoryId;

  const [openSaveChangesModal, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [changes, setChanges] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // Selectors
  const { listOfInventoryTypes } = useSelector(
    (state: RootState) => state.inventoryTypes
  );

  const { inventory, isLoading } = useSelector(
    (state: RootState) => state.inventory
  );

  const relocate = useCallback(() => {
    setChanges(false);
    history.push("/myInventory");
  }, [history]);

  const getCurrentValuesForFields = (el: any) => {
    const field = { ...el };
    if (el.typeOfField === "DATE") {
      field.value = el.value
        ? new Date(new Date().getTime() + el.defaultValue * 24 * 60 * 60 * 1000)
        : null;
    } else if (field.format === "CHECKBOX") {
      field.value = el.value === "false" ? false : !!el.value;
    }
    return field.value;
  };

  const handleGetListOfFields = useCallback((inventoryFields: any) => {
    const listOfFields = JSON.parse(JSON.stringify(inventoryFields));
    const fieldList = listOfFields.map((field: any) => ({
      ...field,
      value: getCurrentValuesForFields(field),
    }));
    return fieldList;
  }, []);

  useEffect(() => {
    const diffAttestDocs = inventory
      ? JSON.stringify(currentAttestationDocuments) !==
        JSON.stringify(inventory?.labAttestationDocs)
      : false;
    const diffGenDateAttest =
      generalAttestationDate && inventory?.generalAttestationDate
        ? generalAttestationDate?.toLocaleString() !==
          new Date(inventory?.generalAttestationDate).toLocaleString()
        : false;
    let diffFields = false;
    if (inventory) {
      diffFields =
        JSON.stringify(currentFieldsInventory) !==
        JSON.stringify(fieldsInventory);
    }
    setChanges(
      formState.isDirty ||
        newAttestationDocuments.length > 0 ||
        diffAttestDocs ||
        !!productPictureFile ||
        diffGenDateAttest ||
        diffFields
    );
  }, [
    formState,
    inventory,
    newAttestationDocuments,
    currentAttestationDocuments,
    productPictureFile,
    generalAttestationDate,
    watch,
    fieldsInventory,
    currentFieldsInventory,
  ]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && changes) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    setIsAdmin(location.pathname === "/itemOfInventory");
  }, [location, setIsAdmin]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    if (changes) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [confirmedNavigation, lastLocation, history, changes]);

  useEffect(() => {
    const { userId, token } = getLocalStorageData();
    dispatch(getInventoryTypes(userId, new Date().getTime() + Math.random()));
    if (inventoryId) {
      dispatch(
        getInventoryById(
          inventoryId,
          token,
          new Date().getTime() + Math.random()
        )
      );
    }
  }, [dispatch, inventoryId]);

  const getDefaultValuesForFields = (el: any) => {
    const field = { ...el };
    if (el.typeOfField === "DATE") {
      field.value = el.defaultValue
        ? new Date(new Date().getTime() + el.defaultValue * 24 * 60 * 60 * 1000)
        : null;
    } else {
      field.value = el.defaultValue || "";
    }
    return field;
  };

  useEffect(() => {
    if (inventory) {
      setTypeInventory(inventory.inventoryItemType);
      const currentFields = handleGetListOfFields(
        inventory.inventoryItemType.inventoryItemTypeFieldsTypes
      );
      setFieldsInventory(currentFields);
      setCurrentFieldsInventory(currentFields);
      setCurrentInventory({ ...inventory });
      setCurrentAttestationDocuments(inventory.labAttestationDocs);
      setGeneralAttestationDate(
        inventory.generalAttestationDate
          ? new Date(inventory.generalAttestationDate)
          : null
      );
    }
  }, [
    inventory,
    setTypeInventory,
    setCurrentInventory,
    handleGetListOfFields,
    setFieldsInventory,
    listOfInventoryTypes,
    setGeneralAttestationDate,
  ]);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      dispatch(
        addNotification({
          key: new Date().getTime() + Math.random(),
          message: "Errors in fields",
          options: { variant: "error" },
        })
      );
    }
  }, [dispatch, errors]);

  useEffect(() => {
    if (productPictureFile !== null) {
      setUploadPhoto(false);
    }
  }, [productPictureFile]);

  const handleRoundQuantity = useCallback(() => {
    if (typeInventory) {
      if (!watch().quantity) setValue("quantity", typeInventory.minTradeSize);
      else if (watch().quantity < typeInventory.minTradeSize)
        setValue("quantity", typeInventory.minTradeSize);
      else if (
        !Number.isInteger(
          ((watch().quantity as number) - typeInventory.minTradeSize) /
            typeInventory.tradeSizeIncrement
        )
      ) {
        const value = Math.floor(
          ((watch().quantity as number) - typeInventory.minTradeSize) /
            typeInventory.tradeSizeIncrement
        );
        setValue(
          "quantity",
          typeInventory.minTradeSize + typeInventory.tradeSizeIncrement * value
        );
      }
    }
    setOpenQuantity(false);
  }, [setValue, typeInventory, watch]);

  const handleSaveInventory = useCallback(
    (values) => {
      const { clientAccountId, accountId, token } = getLocalStorageData();
      const {
        type,
        title,
        description,
        shortDescription,
        locationLine1,
        locationCity,
        locationState,
        locationZip,
        locationCountry,
        quantity,
        labAttest,
        generalAttest,
      } = values;
      const data = {
        typeId: typeInventory?.id,
        type,
        title,
        description,
        shortDescription,
        locationLine1,
        locationCity,
        locationState,
        locationZip,
        locationCountry,
        quantity,
        labAttest,
        generalAttest,
        fields: fieldsInventory,

        brokerAccountId: clientAccountId && accountId,

        labAttestationDocs: currentAttestationDocuments,
        productPicture:
          currentInventory.productPicture && currentInventory.productPicture[0],
        generalAttestationDate,
        signature,
      };
      const formData = new FormData();
      let docError: string | false = false;
      fieldsInventory?.map((item) => {
        if (
          item.required &&
          item.typeOfField === "DOCUMENTS" &&
          (!item.proveDocuments || item.proveDocuments.length === 0) &&
          (!item.newProveDocuments || item.newProveDocuments.length === 0)
        ) {
          item.error = "Required document";
          docError = "Required document";
        }
      });
      if (docError) {
        dispatch(
          addNotification({
            key: new Date().getTime() + Math.random(),
            message: "Errors in fields",
            options: { variant: "error" },
          })
        );
        return;
      }

      const inventoryInfo = JSON.stringify(data);
      formData.append("inventory", inventoryInfo);
      if (typeInventory) {
        if (
          !Number.isInteger(
            (+((values.quantity - typeInventory.minTradeSize) * 100).toFixed(
              2
            ) /
              typeInventory.tradeSizeIncrement) *
              100
          ) ||
          !values.quantity ||
          values.quantity < typeInventory.minTradeSize
        ) {
          setOpenQuantity(true);
          setError("quantity", {
            type: "manual",
            message: "Incorrect value",
          });
          return;
        }
      }
      if (
        newAttestationDocuments.length + currentAttestationDocuments.length >
        10
      ) {
        dispatch(
          addNotification({
            key: new Date().getTime() + Math.random(),
            message:
              "The maximum number of files for Attestation Documents is 10",
            options: { variant: "error" },
          })
        );
        return;
      }
      fieldsInventory?.map((item) => {
        if (
          item.typeOfField === "DOCUMENTS" &&
          item.newProveDocuments &&
          !!item.newProveDocuments.length
        ) {
          if (item.newProveDocuments.length + item.proveDocuments.length > 10) {
            docError = item.name;
          }
          item.newProveDocuments.forEach((doc: File) => {
            formData.append(item.name, doc);
          });
        }
      });
      if (docError) {
        dispatch(
          addNotification({
            key: new Date().getTime() + Math.random(),
            message: `The maximum number of files for ${docError} is 10`,
            options: { variant: "error" },
          })
        );
        return;
      }
      if (!!newAttestationDocuments.length) {
        newAttestationDocuments.forEach((item: File) => {
          formData.append("labAttestationDocs", item);
        });
      }
      if (productPictureFile)
        formData.append("productPicture", productPictureFile);
      const key = new Date().getTime() + Math.random();

      if (inventoryId) {
        dispatch(
          updateInventory(
            inventoryId,
            formData,
            relocate,
            clientAccountId || accountId,
            token,
            key
          )
        );
      } else {
        dispatch(
          createInventory(
            formData,
            relocate,
            clientAccountId || accountId,
            token,
            key
          )
        );
      }
    },
    [
      inventoryId,
      productPictureFile,
      fieldsInventory,
      newAttestationDocuments,
      currentAttestationDocuments,
      typeInventory,
      generalAttestationDate,
      signature,
      currentInventory,
      dispatch,
      setOpenQuantity,
      setError,
      relocate,
    ]
  );

  const handleChangeType = useCallback(
    (value) => {
      const type = listOfInventoryTypes.filter(
        (item) => item.type === value
      )[0];
      setTypeInventory(type);
      const fields = type.inventoryItemTypeFieldsTypes.map((el: any) =>
        getDefaultValuesForFields(el)
      );
      setFieldsInventory(fields);
    },
    [setTypeInventory, listOfInventoryTypes]
  );

  const handleChangeField = useCallback(
    (id: number, value: any) => {
      if (fieldsInventory) {
        const listOfFields = fieldsInventory.map((a) => ({ ...a }));
        const itemFieldIndex = listOfFields.findIndex(
          (field: any) => field.id === id
        );
        if (listOfFields[itemFieldIndex].typeOfField === "NUMBER") {
          listOfFields[itemFieldIndex].value = value
            .toString()
            .replace(/,/g, ".");
        } else if (listOfFields[itemFieldIndex].typeOfField === "DOCUMENTS") {
          listOfFields[itemFieldIndex].newProveDocuments = value;
        } else {
          listOfFields[itemFieldIndex].value = value;
        }
        listOfFields[itemFieldIndex].error = "";
        setFieldsInventory(listOfFields);
      }
    },
    [fieldsInventory, setFieldsInventory]
  );

  const handleSetDocsForFields = useCallback(
    (id, newFiles, currentFiles) => {
      if (fieldsInventory) {
        const listOfFields = fieldsInventory.map((a) => ({ ...a }));
        const itemFieldIndex = listOfFields.findIndex(
          (field: any) => field.id === id
        );
        listOfFields[itemFieldIndex].newProveDocuments = newFiles;
        listOfFields[itemFieldIndex].proveDocuments = currentFiles;
        setFieldsInventory(listOfFields);
      }
    },
    [fieldsInventory]
  );

  const handleSetAttestationDocs = useCallback(
    (newFiles, currentFiles) => {
      if (newFiles.length === 0 && currentFiles.length === 0)
        setValue("labAttest", false, { shouldDirty: true });
      setNewAttestationDocuments(newFiles);
      setCurrentAttestationDocuments(currentFiles);
    },
    [setValue]
  );

  const handleClose = useCallback(
    (toAttest: boolean, sign?: number) => {
      if (toAttest && generalAttestationDate && sign) {
        setValue("generalAttest", true, { shouldDirty: true });
        setSignature(sign);
      }
      if (!toAttest) {
        setGeneralAttestationDate(null);
        setSignature(false);
      }
      setOpenGeneral(false);
    },
    [generalAttestationDate, setValue]
  );

  const handleOpenGeneral = useCallback(
    (value: any) => {
      if (!value) {
        setOpenGeneral(true);
      } else {
        setValue("generalAttest", false, { shouldDirty: true });
        setGeneralAttestationDate(null);
        setSignature(false);
      }
    },
    [setOpenGeneral, setGeneralAttestationDate, setSignature, setValue]
  );

  const getField = (item: any) => (
    <Grid item xs={12} key={item.name}>
      {item.typeOfField === "STRING" && (
        <>
          <Controller
            name={item.name}
            control={control}
            defaultValue={(inventoryId ? item.value : item.defaultValue) || ""}
            render={({ onChange, value, name }) => (
              <TextField
                label={`${item.name.replace(/\/,/g, ".")} ${
                  item.required ? "*" : ""
                }`}
                size="small"
                variant="outlined"
                fullWidth
                name={name}
                value={value}
                onChange={({ target }) => {
                  onChange(target.value);
                  handleChangeField(item.id, target.value);
                }}
                error={!!errors[item.name]?.message}
                disabled={isAdmin}
              />
            )}
            rules={{
              required: item.required ? "Required field" : false,
            }}
          />
          {errors[item.name]?.message && (
            <Typography variant="caption" color="error">
              {errors[item.name]?.message}
            </Typography>
          )}
        </>
      )}
      {item.typeOfField === "NUMBER" && (
        <>
          <Controller
            name={item.name}
            control={control}
            defaultValue={(inventoryId ? item.value : item.defaultValue) || ""}
            render={({ onChange, value, name }) => (
              <TextField
                label={`${item.name.replace(/\/,/g, ".")} ${
                  item.format === "PERCENT" ? "(%)" : ""
                } ${item.format === "DOLLAR" ? "($)" : ""} ${
                  item.required ? "*" : ""
                }`}
                size="small"
                variant="outlined"
                fullWidth
                name={name}
                value={value}
                onChange={({ target }) => {
                  onChange(target.value);
                  handleChangeField(item.id, target.value);
                }}
                error={!!errors[item.name]?.message}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    name,
                  },
                }}
                disabled={isAdmin}
              />
            )}
            rules={{
              required: item.required ? "Required field" : false,
              validate: {
                percent: (value: number) => {
                  if (item.format === "PERCENT" && (+value < 0 || +value > 100))
                    return "The percentage must be between 0 and 100";
                  return true;
                },
              },
            }}
          />
          {errors[item.name]?.message && (
            <Typography variant="caption" color="error">
              {errors[item.name]?.message}
            </Typography>
          )}
        </>
      )}
      {item.typeOfField === "BOOLEAN" && item.format === "CHECKBOX" && (
        <>
          <Controller
            render={({ onChange, name, value }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={name}
                    checked={value}
                    checkedIcon={
                      <span className={cx(classes.icon, classes.checkedIcon)} />
                    }
                    icon={<span className={classes.icon} />}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      handleChangeField(item.id, e.target.checked);
                    }}
                    disabled={isAdmin}
                  />
                }
                label={`${item.name.replace(/\/,/g, ".")} ${
                  item.required ? "*" : ""
                }`}
              />
            )}
            name={item.name}
            value="primary"
            control={control}
            defaultValue={
              (inventoryId
                ? (item.value === "false" && false) || true
                : (item.defaultValue === "false" && false) || true) || false
            }
            rules={{
              required: item.required ? "Required field" : false,
            }}
          />
          {errors[item.name]?.message && (
            <Typography variant="caption" color="error">
              {errors[item.name]?.message}
            </Typography>
          )}
        </>
      )}
      {item.typeOfField === "BOOLEAN" && item.format === "DROPDOWN" && (
        <>
          <Controller
            name={item.name}
            control={control}
            defaultValue={(inventoryId ? item.value : item.defaultValue) || ""}
            render={({ onChange, value, name }) => (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel error={!!errors[item.name]?.message}>{`${
                  item.name
                } ${item.required ? "*" : ""}`}</InputLabel>
                <Select
                  label={`${item.name.replace(/\/,/g, ".")} ${
                    item.required ? "*" : ""
                  }`}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={({ target }) => {
                    onChange(target.value);
                    handleChangeField(item.id, target.value);
                  }}
                  className={classes.typeSelect}
                  value={value}
                  name={name}
                  error={!!errors[item.name]?.message}
                  disabled={isAdmin}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="true">YES</MenuItem>
                  <MenuItem value="false">NO</MenuItem>
                </Select>
              </FormControl>
            )}
            rules={{
              required: item.required ? "Required field" : false,
            }}
          />
          {errors[item.name]?.message && (
            <Typography variant="caption" color="error">
              {errors[item.name]?.message}
            </Typography>
          )}
        </>
      )}
      {item.typeOfField === "DATE" && (
        <>
          <Controller
            name={item.name}
            control={control}
            defaultValue={
              (inventoryId ? item.value : item.defaultValue) || null
            }
            render={({ onChange, value, name }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  name={name}
                  inputVariant="outlined"
                  label={`${item.name.replace(/\/,/g, ".")} ${
                    item.required ? "*" : ""
                  }`}
                  format="MM/dd/yyyy"
                  value={value}
                  onChange={(date) => {
                    onChange(getDateUTC12H(date));
                    handleChangeField(item.id, getDateUTC12H(date));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  autoOk
                  fullWidth
                  error={!!errors[item.name]?.message}
                  invalidDateMessage=""
                  disabled={isAdmin}
                />
              </MuiPickersUtilsProvider>
            )}
            rules={{
              required: "Required field",
              pattern: {
                value: /^\d{2}[./-]\d{2}[./-]\d{4}$/,
                message: "Incorrect value",
              },
            }}
            // rules={{
            //   required: item.required ? 'Required field' : false,
            //   validate: (value) => {
            //     // eslint-disable-next-line no-restricted-globals
            //     if (value && isNaN(value?.getTime())) return 'Invalid date';
            //     return true;
            //   },
            // }}
          />
          {errors[item.name]?.message && (
            <Typography variant="caption" color="error">
              {errors[item.name]?.message}
            </Typography>
          )}
        </>
      )}
      {item.typeOfField === "CHOICE" && (
        <>
          <Controller
            name={item.name}
            control={control}
            defaultValue={(inventoryId ? item.value : item.defaultValue) || ""}
            render={({ onChange, value, name }) => (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel error={!!errors[item.name]?.message}>{`${
                  item.name
                } ${item.required ? "*" : ""}`}</InputLabel>
                <Select
                  label={`${item.name.replace(/\/,/g, ".")} ${
                    item.required ? "*" : ""
                  }`}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={({ target }) => {
                    onChange(target.value);
                    handleChangeField(item.id, target.value);
                  }}
                  className={classes.typeSelect}
                  value={value}
                  name={name}
                  error={!!errors[item.name]?.message}
                  disabled={isAdmin}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  {item.arrayOfChoices.map((choice: string) => (
                    <MenuItem key={choice} value={choice}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            rules={{
              required: item.required ? "Required field" : false,
            }}
          />
          {errors[item.name]?.message && (
            <Typography variant="caption" color="error">
              {errors[item.name]?.message}
            </Typography>
          )}
        </>
      )}
    </Grid>
  );

  const getDocumentsField = (item: any) => (
    <Fragment key={item.name}>
      <Grid item xs={12} key={item.name}>
        {item.typeOfField === "DOCUMENTS" && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Typography variant="h1" className={classes.titleCreate}>
              {`${item.name.replace(/\/,/g, ".")} ${item.required ? "*" : ""}`}
            </Typography>
            {item.error && (
              <Typography
                variant="h1"
                className={classes.titleCreate}
                color="error"
              >
                {item.error}
              </Typography>
            )}
            <MultiFileUploader
              currentFiles={item.proveDocuments || []}
              newFiles={item.newProveDocuments || []}
              setListFiles={(newFiles, newList) =>
                handleSetDocsForFields(item.id, newFiles, newList)
              }
              docsTitle={false}
              availableForEdit={!isAdmin}
            />
          </>
        )}
      </Grid>
    </Fragment>
  );

  return (
    <Paper className={classes.paper}>
      <Prompt when={changes} message={handleBlockedNavigation} />
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleSaveInventory)}
      >
        {(inventoryId
          ? Object.keys(currentInventory).length !== 0
          : listOfInventoryTypes.length > 0) && (
          <>
            {!isAdmin && (
              <Grid item xs={12}>
                <Typography variant="h1" className={classes.titleCreate}>
                  {inventoryId ? "Update" : "Create"}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              {!!inventoryId || isAdmin ? (
                <Controller
                  name="type"
                  control={control}
                  defaultValue={currentInventory.inventoryItemType.type}
                  render={({ value, name }) => (
                    <TextField
                      label="Type"
                      value={value}
                      name={name}
                      size="small"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  )}
                />
              ) : (
                <Controller
                  name="type"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value, name }) => (
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel error={!!errors.type?.message}>
                        Type*
                      </InputLabel>
                      <Select
                        label="Type*"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        onChange={({ target }) => {
                          onChange(target.value);
                          handleChangeType(target.value);
                        }}
                        className={classes.typeSelect}
                        value={value}
                        name={name}
                        error={!!errors.type?.message}
                      >
                        <MenuItem value="" disabled>
                          <em>None</em>
                        </MenuItem>
                        {listOfInventoryTypes.map((item) => (
                          <MenuItem key={item.type} value={item.type}>
                            {item.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  rules={{
                    required: "Required field",
                  }}
                />
              )}
              {errors.type?.message && (
                <Typography variant="caption" color="error">
                  {errors.type?.message}
                </Typography>
              )}
            </Grid>
            {typeInventory && typeInventory.instantCheckout && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={typeInventory && typeInventory.instantCheckout}
                      color="primary"
                      checkedIcon={
                        <span
                          className={cx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                    />
                  }
                  label="Instant Checkout"
                />
              </Grid>
            )}
            {/* Title */}
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                defaultValue={inventoryId ? currentInventory.title : ""}
                render={({ onChange, value, name }) => (
                  <TextFieldProfile
                    name={name}
                    value={value}
                    label="Title"
                    error={!!errors.title?.message}
                    handleChange={(data) => {
                      onChange(data);
                    }}
                    disabled={isAdmin}
                  />
                )}
              />
            </Grid>
            {/* Short Description */}
            <Grid item xs={12}>
              <Controller
                name="shortDescription"
                control={control}
                defaultValue={
                  inventoryId ? currentInventory.shortDescription : ""
                }
                render={({ onChange, value, name }) => (
                  <TextFieldProfile
                    name={name}
                    value={value}
                    label="Short Description"
                    error={!!errors.shortDescription?.message}
                    handleChange={(data) => {
                      onChange(data);
                    }}
                    disabled={isAdmin}
                  />
                )}
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue={inventoryId ? currentInventory.description : ""}
                render={({ onChange, value, name }) => (
                  <TextField
                    label="Description"
                    size="small"
                    variant="outlined"
                    fullWidth
                    multiline
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={({ target }) => {
                      onChange(target.value.trim());
                    }}
                    disabled={isAdmin}
                  />
                )}
              />
            </Grid>
            {/* Location Line1 */}
            <Grid item xs={12}>
              <Controller
                name="locationLine1"
                control={control}
                defaultValue={inventoryId ? currentInventory.locationLine1 : ""}
                render={({ onChange, value, name }) => (
                  <TextFieldProfile
                    name={name}
                    value={value}
                    label="Location Line1*"
                    error={!!errors.locationLine1?.message}
                    handleChange={(data) => {
                      onChange(data);
                    }}
                    disabled={isAdmin}
                  />
                )}
                rules={{
                  required: "Required field",
                }}
              />
              {errors.locationLine1?.message && (
                <Typography variant="caption" color="error">
                  {errors.locationLine1?.message}
                </Typography>
              )}
            </Grid>
            {/* City */}
            <Grid item xs={12}>
              <Controller
                name="locationCity"
                control={control}
                defaultValue={inventoryId ? currentInventory.locationCity : ""}
                render={({ onChange, value, name }) => (
                  <TextFieldProfile
                    name={name}
                    value={value}
                    label="City*"
                    error={!!errors.locationCity?.message}
                    handleChange={(data) => {
                      onChange(data);
                    }}
                    disabled={isAdmin}
                  />
                )}
                rules={{
                  required: "Required field",
                }}
              />
              {errors.locationCity?.message && (
                <Typography variant="caption" color="error">
                  {errors.locationCity?.message}
                </Typography>
              )}
            </Grid>
            {/* State */}
            <Grid item xs={12}>
              <Controller
                name="locationState"
                control={control}
                defaultValue={inventoryId ? currentInventory.locationState : ""}
                render={({ onChange, value }) => (
                  <StateAutocomplete
                    label="State*"
                    defaultValue={
                      inventoryId ? currentInventory.locationState : ""
                    }
                    currentState={value || watch().locationState}
                    handleChange={(newValue: any) => {
                      onChange(newValue);
                    }}
                    disabled={isAdmin}
                    error={!!errors.locationState?.message}
                  />
                )}
                rules={{
                  required: "Required field",
                }}
              />
              {errors.locationState?.message && (
                <Typography variant="caption" color="error">
                  {errors.locationState?.message}
                </Typography>
              )}
            </Grid>
            {/* Zip */}
            <Grid item xs={12}>
              <Controller
                name="locationZip"
                control={control}
                defaultValue={inventoryId ? currentInventory.locationZip : ""}
                render={({ onChange, value, name }) => (
                  <TextField
                    label="Zip*"
                    size="small"
                    variant="outlined"
                    fullWidth
                    name={name}
                    value={value}
                    autoComplete="new-password"
                    onChange={({ target }) => {
                      onChange(target.value);
                    }}
                    InputProps={{
                      inputComponent: NumberFormatZip as any,
                    }}
                    error={!!errors.locationZip?.message}
                    disabled={isAdmin}
                  />
                )}
                rules={{
                  required: "Required field",
                  minLength: {
                    value: 5,
                    message: "Incorrect zip: 5 symbols needed",
                  },
                }}
              />
              {errors.locationZip?.message && (
                <Typography variant="caption" color="error">
                  {errors.locationZip?.message}
                </Typography>
              )}
            </Grid>
            {/* Country */}
            <Grid item xs={12}>
              <Controller
                name="locationCountry"
                control={control}
                defaultValue={
                  inventoryId ? currentInventory.locationCountry : ""
                }
                render={({ onChange, value, name }) => (
                  <TextFieldProfile
                    name={name}
                    value={value}
                    label="Country"
                    error={!!errors.locationCountry?.message}
                    handleChange={(data) => {
                      onChange(data);
                    }}
                    disabled={isAdmin}
                  />
                )}
              />
              {errors.locationCountry?.message && (
                <Typography variant="caption" color="error">
                  {errors.locationCountry?.message}
                </Typography>
              )}
            </Grid>
            {/* Quantity */}
            <Grid item xs={12}>
              <Controller
                name="quantity"
                control={control}
                defaultValue={inventoryId ? currentInventory.quantity : ""}
                render={({ onChange, value, name }) => (
                  <TextField
                    label={`Quantity ${
                      typeInventory ? `(${typeInventory.units})` : ""
                    }*`}
                    size="small"
                    variant="outlined"
                    fullWidth
                    name={name}
                    value={value}
                    error={!!errors.quantity?.message}
                    onChange={({ target }) => {
                      onChange(+target.value);
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                    }}
                    disabled={isAdmin}
                  />
                )}
                rules={{
                  required: "Required field",
                }}
              />
              {errors.quantity?.message && (
                <Typography variant="caption" color="error">
                  {errors.quantity?.message}
                </Typography>
              )}
            </Grid>
            {/* Dynamic fields */}
            {fieldsInventory &&
              fieldsInventory.length > 0 &&
              fieldsInventory.map((item: any) => getField(item))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/* Certificate of Authenticity (COA) */}
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.titleCreate}>
                Lab Analysis
              </Typography>
            </Grid>
            <MultiFileUploader
              currentFiles={currentAttestationDocuments || []}
              newFiles={newAttestationDocuments || []}
              setListFiles={handleSetAttestationDocs}
              docsTitle={false}
              availableForEdit={!isAdmin}
            />
            {!isAdmin && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="labAttest"
                    value="primary"
                    control={control}
                    defaultValue={
                      inventoryId ? currentInventory.labAttest : false
                    }
                    render={({ onChange, name, value }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={name}
                            checked={value}
                            checkedIcon={
                              <span
                                className={cx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            onChange={(e) => {
                              if (
                                e.target.checked &&
                                (currentAttestationDocuments.length > 0 ||
                                  newAttestationDocuments.length > 0)
                              ) {
                                onChange(e.target.checked);
                              } else if (!e.target.checked) {
                                onChange(e.target.checked);
                              } else {
                                dispatch(
                                  addNotification({
                                    key: new Date().getTime() + Math.random(),
                                    message: "Upload document",
                                    options: { variant: "error" },
                                  })
                                );
                              }
                            }}
                          />
                        }
                        label="Confirm Lab meets minimum standards"
                      />
                    )}
                  />
                  {/* <FormControlLabel
                      control={(
                        <Checkbox
                          checked={labAttest}
                          onChange={() => {
                            if (currentAttestationDocuments.length > 0 ||
                              newAttestationDocuments.length > 0) {
                              setLabAttest(!labAttest);
                            } else {
                              dispatch(addNotification({
                                key: new Date().getTime() + Math.random(),
                                message: 'Upload document',
                                options: { variant: 'error' },
                              }));
                            }
                          }}
                          name="labAttest"
                          color="primary"
                          checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                          icon={<span className={classes.icon} />}
                        />
                      )}
                      label="Confirm Lab meets minimum standards"
                    /> */}

                  <div>
                    <Link
                      onClick={() => setOpenLab(true)}
                      className={classes.cursorPointer}
                    >
                      Click here to read Lab Attestation Requirements
                    </Link>
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/* Display Picture */}
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.titleCreate}>
                Display Picture
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Avatar
                variant="rounded"
                alt="Avatar"
                className={classes.picture}
                src={
                  productPicture ||
                  (currentInventory.productPicture &&
                    currentInventory.productPicture[0] &&
                    `${constants.AWS_URL}${currentInventory.productPicture[0].link}`)
                }
              >
                {!(
                  productPicture || currentInventory.productPicture?.length
                ) && <PageviewIcon fontSize="large" />}
              </Avatar>
              {!isAdmin && (
                <ButtonSC
                  variant="outline"
                  text="Add Display Picture"
                  size="large"
                  color="green"
                  disabled={uploadPhoto}
                >
                  <ImageUploader
                    uploadPhoto={(file) => {
                      setUploadPhoto(true);
                      setProductPictureFile(file);
                    }}
                    setNewAvatar={(newAvatar) => setProductPicture(newAvatar)}
                  />
                </ButtonSC>
              )}
            </Grid>
            {/* Dynamic docs fields */}
            {fieldsInventory &&
              fieldsInventory.length > 0 &&
              fieldsInventory.map((item: any) => getDocumentsField(item))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="generalAttest"
                value="primary"
                control={control}
                defaultValue={
                  inventoryId ? currentInventory.generalAttest : false
                }
                render={({ onChange, name, value }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={name}
                        checked={value}
                        checkedIcon={
                          <span
                            className={cx(classes.icon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.icon} />}
                        onChange={(e) => {
                          handleOpenGeneral(value);
                        }}
                        disabled={isAdmin}
                      />
                    }
                    label={`${
                      value && generalAttestationDate
                        ? `General Attestation, ${getDate(
                            generalAttestationDate.toISOString()
                          )}`
                        : "General Attestation"
                    }`}
                  />
                )}
              />
              {/* <FormControlLabel
                  control={(
                    <Checkbox
                      checked={generalAttest}
                      onChange={handleOpenGeneral}
                      name="showForSeller"
                      color="primary"
                      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      disabled={isAdmin}
                    />
                  )}
                  label={`${generalAttest && generalAttestationDate ?
                    `General Attestation, ${getDate(generalAttestationDate.toISOString())}` :
                    'General Attestation'}`}
                /> */}
            </Grid>
            {!isAdmin && (
              <Grid item xs={12}>
                <ButtonSC
                  variant="fill"
                  text={
                    (!isLoading && (inventoryId ? "Update" : "Create")) || ""
                  }
                  size="large"
                  type="submit"
                  disabled={isLoading || !changes}
                  color="green"
                >
                  {isLoading && <CircularProgress color="primary" size={20} />}
                </ButtonSC>
              </Grid>
            )}
          </>
        )}
      </form>
      <LabAttestationModal open={openLab} close={() => setOpenLab(false)} />
      <GeneralAttestationModal
        open={openGeneral}
        attestationDate={generalAttestationDate}
        changeDate={setGeneralAttestationDate}
        close={() => handleClose(false)}
        attest={(sign) => handleClose(true, sign)}
      />
      {openQuantity && (
        <ConfirmationDialog
          open={openQuantity}
          scroll="paper"
          color="green"
          disableBackdropClick
          title="Error"
          dialogTitleId="alert-dialog-title"
          labelledby="alert-dialog-title"
          describedby="alert-dialog-description"
          stylesDialogContent={{ root: classes.paddingBottom40 }}
          acceptButtonText="Change"
          cancelButtonText="Round"
          gridSapcing={2}
          gridSize={4}
          description={
            <>
              <p>
                {`The amount entered should be multiple of ${typeInventory?.tradeSizeIncrement} and minimum value should be  ${typeInventory?.minTradeSize}.`}
              </p>
              <p>
                Would you like to change or it will be rounded down nearest
                correct amount?
              </p>
            </>
          }
          onAcceptButtonClicked={() => {
            setOpenQuantity(false);
          }}
          onCancelButtonClicked={() => handleRoundQuantity()}
        />
      )}
      {openSaveChangesModal && (
        <ConfirmationWithoutSaving
          open={openSaveChangesModal}
          color="green"
          onAcceptButtonClicked={() => {
            handleConfirmNavigationClick();
          }}
          onCancelButtonClicked={() => closeModal()}
        />
      )}
    </Paper>
  );
};

export default CreateInventory;
