import { createSlice } from '@reduxjs/toolkit';
import {
  apiGetAllMessagesAndAlertsOfAccount,
  apiGetSingleMessageInfoById,
} from '../../api/alertsAndMessages';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import { AlertsAndMessagesState, AlertsAndMessages } from '../../interfaces/alertsAndMessages.interface';

const initialState: AlertsAndMessagesState = {
  listOfAlertsAndMessages: [],
  alertsAndMessages: null,
  signature: null,
  error: '',
  isLoading: false,
  signatureIsLoading: false,
  signatureError: '',
  // isSoundAlertsAccepted: false
};

const alertsAndMessagesSlice = createSlice({
  initialState,
  name: 'alertsAndMessages',
  reducers: {
    alertsAndMessagesRequest(state) {
      state.alertsAndMessages = null;
      state.error = '';
      state.isLoading = true;
    },
    alertsAndMessagesSuccess(state, action) {
      state.alertsAndMessages = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    alertsAndMessagesFailure(state, action) {
      state.alertsAndMessages = null;
      state.error = action.payload;
      state.isLoading = false;
    },
    listAlertsAndMessages(state) {
      state.alertsAndMessages = null;
      state.listOfAlertsAndMessages = [];
      state.error = '';
      state.isLoading = true;
    },
    listAlertsAndMessagesSuccess(state, action) {
      state.alertsAndMessages = null;
      state.listOfAlertsAndMessages = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    listAlertsAndMessagesFailure(state, action) {
      state.alertsAndMessages = null;
      state.listOfAlertsAndMessages = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    updateListAlertsAndMessages(state, action) {
      const index = state.listOfAlertsAndMessages
        .findIndex((obj: AlertsAndMessages) => obj.id === action.payload.id);
      if (index !== -1) {
        state.listOfAlertsAndMessages[index] = action.payload;
      } else {
        state.listOfAlertsAndMessages.push(action.payload);
      }
    },
  },
});

export const {
  alertsAndMessagesRequest,
  alertsAndMessagesSuccess,
  alertsAndMessagesFailure,

  listAlertsAndMessages,
  listAlertsAndMessagesSuccess,
  listAlertsAndMessagesFailure,
  updateListAlertsAndMessages,
} = alertsAndMessagesSlice.actions;

export const getAllMessagesAndAlerts = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    // dispatch(listAlertsAndMessages());
    const alertsAndMessages = await dispatch(apiGetAllMessagesAndAlertsOfAccount(accountId, token));
    dispatch(listAlertsAndMessagesSuccess(alertsAndMessages));
  } catch (error) {
    dispatch(listAlertsAndMessagesFailure(error?.response ?
      error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getMessageInfoById = (
  messageId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(alertsAndMessagesRequest());
    const alertsAndMessages = await dispatch(apiGetSingleMessageInfoById(messageId, token));
    dispatch(alertsAndMessagesSuccess(alertsAndMessages));
  } catch (error) {
    dispatch(alertsAndMessagesFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default alertsAndMessagesSlice.reducer;
