import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import {
  Grid,
  TextField,
  Typography,
  Paper,
} from '@material-ui/core';
import useStyles from './style';
import { changeUserPassword } from '../../store/slices/userUpdate.slice';
import ButtonSC from '../../components/ButtonSC/ButtonSC';

export const PasswordReset: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token } = useParams();
  const {
    handleSubmit, errors, control, getValues,
  } = useForm();

  // Callbacks
  const handlePasswordChange = useCallback(
    (values) => {
      if (Object.keys(errors).length === 0) {
        const key = new Date().getTime() + Math.random();
        dispatch(changeUserPassword(values.password, token, key));
      }
    },
    [dispatch, errors, token],
  );

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h1"> Enter new password </Typography>
        <form className={classes.form} onSubmit={handleSubmit(handlePasswordChange)}>
          <Grid container className={classes.form} id="container">
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                as={(
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    error={!!errors.password?.message}
                  />
                )}
                rules={{
                  required: 'Required field',
                  // pattern: {
                  //   value: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i,
                  //   message: 'Incorrect value',
                  // },
                  // .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])
                  // (?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/i,
                  //   { message: 'Password should be at least 8 characters long
                  //  must contain uppercase and lowercase letters,
                  //  numbers and on of !@#$ %^&* symbols' }),
                }}
              />
              {errors.password &&
                <Typography variant="caption" color="error">{errors.password.message}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="confirmPassword"
                control={control}
                as={(
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    data-testid="confirmPassword"
                    error={!!errors.confirmPassword?.message}
                  />
                )}
                rules={{
                  required: 'Required field',
                  validate: (value) => getValues().password === value || 'Passwords must match',
                }}
              />
              {errors.confirmPassword &&
                <Typography variant="caption" color="error">{errors.confirmPassword.message}</Typography>}
            </Grid>
            <ButtonSC
              variant="fill"
              text="Change password"
              size="large"
              type="submit"
              color="green"
            />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default PasswordReset;
