import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const CreateBuyOrder = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path d="M15.5 9.77778C15.7761 9.77778 16 10.0016 16 10.2778V14.2222C16 14.6937 15.8127 15.1459 15.4793 15.4793C15.1459 15.8127 14.6937 16 14.2222 16H1.77778C1.30628 16 0.854097 15.8127 0.520699 15.4793C0.187301 15.1459 0 14.6937 0 14.2222V1.77778C0 1.30628 0.187301 0.854097 0.520699 0.520699C0.854097 0.187301 1.30628 0 1.77778 0H5.72222C5.99836 0 6.22222 0.223858 6.22222 0.5V1.27778C6.22222 1.55392 5.99836 1.77778 5.72222 1.77778H2.27778C2.00164 1.77778 1.77778 2.00164 1.77778 2.27778V13.7222C1.77778 13.9984 2.00164 14.2222 2.27778 14.2222H13.7222C13.9984 14.2222 14.2222 13.9984 14.2222 13.7222V10.2778C14.2222 10.0016 14.4461 9.77778 14.7222 9.77778H15.5Z" />
    <path d="M16 3.6C14.5941 3.6 12.4 3.6 12.4 3.6V0H10.6V3.6H7V5.4H10.6V9H12.4V5.4H16" />
  </SvgIcon>
);

export default CreateBuyOrder;
