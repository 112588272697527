import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const Profile = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path d="M0 15.3C0 15.6866 0.313401 16 0.7 16H15.3C15.6866 16 16 15.6866 16 15.3V0.7C16 0.313401 15.6866 0 15.3 0H0.7C0.313401 0 0 0.313401 0 0.7V15.3ZM10.6667 5.33333C10.6667 6.80889 9.47556 8 8 8C6.52444 8 5.33333 6.80889 5.33333 5.33333C5.33333 3.85778 6.52444 2.66667 8 2.66667C9.47556 2.66667 10.6667 3.85778 10.6667 5.33333ZM2.66667 12.4444C2.66667 10.6667 6.22222 9.68889 8 9.68889C9.77778 9.68889 13.3333 10.6667 13.3333 12.4444V12.6333C13.3333 13.0199 13.0199 13.3333 12.6333 13.3333H3.36667C2.98007 13.3333 2.66667 13.0199 2.66667 12.6333V12.4444Z" />
  </SvgIcon>
);

export default Profile;
