const api = {
  // Auth
  login: 'api/auth/login',
  logout: 'api/auth/logout',
  signup: 'api/auth/signup',
  activateAccount: 'api/auth/confirm-email',
  checkLogin: 'api/auth/check/',
  sendEmailChangePassword: 'api/auth/forget-password',
  changePassword: 'api/auth/set-new-password',
  confirmNewPassword: 'api/auth/confirm-new-password',
  updateToken: 'api/auth/updateToken',

  // User
  getUserInfo: 'api/user/get_user/',
  updateUserInfo: 'api/user/update_user/',
  updateUserRole: 'api/user/change_user_role/',
  resendRegistrationConfirmationEmail: 'api/user/resend_registration_confirmation_email/',

  // Inventory
  createInventory: 'api/inventoryItem/create_inventory_item/',
  setActiveStatusInventory: 'api/inventoryItem/make_active_inventory_item/',
  approveInventory: 'api/inventoryItem/request_approve_inventory_item/',
  getInventory: 'api/inventoryItem/get_all_inventory_items/',
  getInventoryById: 'api/inventoryItem/get_inventory_item/',
  updateInventory: 'api/inventoryItem/update_inventory_item/',
  deleteInventory: 'api/inventoryItem/delete_inventory_item/',
  checkSignName: 'api/inventoryItem/checkSignName',
  checkActiveOrders: 'api/inventoryItem/check_inventory_for_active_orders/',
  getAllExistedInventory: 'api/inventoryItem/get_all_existed_inventory_items',

  // Types
  createInventoryItemType: 'api/inventoryItemType/create_inventory_item_type/',
  updateInventoryItemType: 'api/inventoryItemType/update_inventory_item_type/',
  deleteInventoryItemType: 'api/inventoryItemType/delete_inventory_item_type/',
  getInventoryItemType: 'api/inventoryItemType/get_inventory_item_type/',
  getInventoryTypes: 'api/inventoryItemType/get_all_existed_inventory_item_types/',

  // Order
  createOrderBuy: 'api/order/create_order_buy/',
  createOrderSell: 'api/order/create_order_sell/',
  deleteOrder: 'api/order/delete_order/',
  getOrderById: 'api/order/get_order/',
  getAllOrdersByAccountId: 'api/order/get_all_orders/',
  getAllOrdersForeGuest: 'api/order/get_all_orders/',
  getAllOrders: 'api/order/get_all_orders',
  getAllOrdersForAdmin: 'api/order/get_all_orders_for_admin',
  updateOrderStatus: 'api/order/change_order_status/',

  updateOrder: 'api/order/update_order/',
  // Quality
  // getAllQualityTypesForInventory: 'api/quality/get_all_quality_types_for_inventory_item_type',
  // getAllQualityTypes: 'api/quality/get_all_quality_types',

  // Request
  getAllRequests: 'api/request/get_all_requests/',
  getAllRequestsByAdminId: 'api/request/get_all_pending_requests_of_admin/',
  // getItemRequest: 'api/request/get_request/',
  approveRoleRequest: 'api/request/approve_role_request/',
  declineRoleRequest: 'api/request/decline_role_request/',
  approveInventoryRequest: 'api/request/approve_inventory_item_request/',
  declineInventoryRequest: 'api/request/decline_inventory_item_request/',
  takeRequestFromQueue: 'api/request/take_request_from_queue/',
  approveBrokerClientRequest: 'api/request/approve_broker_client_request/',
  declineBrokerClientRequest: 'api/request/decline_broker_client_request/',
  getBrokerClientRequestInfo: 'api/request/get_broker_client_request_info/',
  approveDealRequest: 'api/request/approve_deal_request/',
  declineDealRequest: 'api/request/decline_deal_request/',
  getChangeEmailRequest: 'api/request/get_user_change_email_request_info/',
  approveChangeEmailRequest: 'api/request/approve_user_email_request/',
  declineChangeEmailRequest: 'api/request/decline_user_email_request/',
  sendRequestBackToQueue: 'api/request/send_request_back_to_queue/',

  // NP
  getAllNegotiationPointTemplates: 'api/negotiationPointsTemplates/get_all_negotiation_point_templates_of_inventory_item_type/',
  updateNegotiationPointTemplate: 'api/negotiationPointsTemplates/update_all_negotiation_point_templates_of_inventory_item_type/',

  // Broker
  getAllClientsOfBroker: 'api/broker/get_all_broker_clients/',
  addUserToBroker: 'api/broker/send_request_to_user_for_approving_broker_client_connection/',
  actAsClient: 'api/broker/act_as_a_client/',

  // Deals
  getAllDealsOfAccount: 'api/deal/get_all_deals_of_account/',
  getAllDealsOfBrokerAccount: 'api/deal/get_all_deals_of_broker_account/',
  getAllSettlementsOfAccount: 'api/settlements/get_all_settlements_of_account/',
  getAllSettlementsOfBrokerAccount: 'api/settlements/get_all_settlements_of_broker_account/',
  createDealFromSellerStage1: 'api/deal/create_deal_from_seller_stage_1/',
  createDealFromSellerStage2: 'api/deal/create_deal_from_seller_stage_2/',
  createDealFromBuyer: 'api/deal/create_deal_from_buyer/',
  goToDeal: 'api/deal/go_to_deal/',
  getSingleDealInfo: 'api/deal/get_single_deal_info/',
  agreeWithDeal: 'api/deal/agree_with_deal/',
  leftTheDeal: 'api/deal/left_the_deal/',
  checkСomission: 'api/deal/check_comission',
  acceptAllIA: 'api/deal/accept_all_inventory_attributes',
  acceptAllNP: 'api/deal/accept_all_np',
  acceptAllPTS: 'api/deal/accept_all_pts',
  updateDealStatus: 'api/deal/change_deal_status/',

  markPTSCompleted: 'api/postTradeSteps/mark_post_trade_step_completed',

  // Contracts
  signContract: 'api/contract/sign_contract',
  getAllContracts: 'api/contract/get_all_contracts/',
  getSingleContract: 'api/contract/get_single_contract/',

  // Users
  getAllUsers: 'api/user/get_all_users/',
  adminChangeUserData: 'api/user/admin_change_user_data/',

  // PostTrade
  completePostTrafeStep: 'api/postTradeProcessingStepsDealRouter/mark_step_completed',

  getAllPTSTemplate: 'api/postTradeProcessingTemplates/get_all_post_trade_processing_templates/',
  updatePTS: 'api/postTradeProcessingTemplates/update_post_trade_processing_template/',

  // Messages and Alerts

  getSingleMessageInfoByMessageId: 'api/messagesAndAlerts/get_single_message_info/',
  getAllMessagesByAccountId: 'api/messagesAndAlerts/get_all_messages_of_account/',
  addOrderQuestion: 'api/messagesAndAlerts/add_order_question',
  addAnswerForQuestion: 'api/messagesAndAlerts/add_answer_for_question',
  markAsViewedAnswer: 'api/messagesAndAlerts/mark_answer_as_viewed/',

  // Notification
  updateNotification: 'api/notification/update_notification/',
  getAllNotifications: 'api/notification/get_all_notifications/',

  // Phone Confirmation
  phoneConfirmation: 'api/phoneConfirmation/',
  verifyPhone: 'api/phoneConfirmation/verify_phone',
};
export default api;
