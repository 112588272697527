import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const Contacts = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14" transform="translate(0,2)">
    <path d="M14 0H0V10H14V0ZM12.6 2.5L7 5.625L1.4 2.5V1.25L7 4.375L12.6 1.25V2.5Z" />
  </SvgIcon>
);

export default Contacts;
