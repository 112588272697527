import React, {
  FC,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useCookies } from 'react-cookie';

import { useHistory, useLocation } from 'react-router-dom';
import MaterialTable, { Column } from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/slices';
import { ItemOfDealList } from '../../interfaces/deals.interface';
import { getAllDealsOfAccount, goToDeal } from '../../store/slices/deals.slice';
import { getDateTime } from '../../utils/getDate';
import { getRole } from '../../utils/getRole';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
import useStyles from './DealRoom.style';
import { classNames } from 'react-select/src/utils';
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { addNotification } from '../../store/slices/snackbar.slice';



const ListOfDeals: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['rowListOfDeals']);
  const classes = useStyles();

  const [
    currentListOfDeals,
    setListOfDeals,
  ] = useState<ItemOfDealList[]>([]);
  const [openDeal, setOpenDeal] = useState<ItemOfDealList>({} as ItemOfDealList);
  // Selectors
  const {
    listOfDeals,
    error,
    isLoading,
  } = useSelector(
    (state: RootState) => state.deals,
  );

  const {
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  // Use Efffect
  useEffect(() => {
    const { clientAccountId, accountId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    dispatch(getAllDealsOfAccount(
      clientAccountId && accountId,
      clientAccountId || accountId,
      token,
      key,
    ));
  }, [dispatch]);

  useEffect(() => {
    const deals = listOfDeals.filter((item) => item.status !== 'POST TRADE');
    setListOfDeals(deals.map((item) => ({ ...item })));
  }, [
    listOfDeals,
    setListOfDeals,
  ]);

  const handleOpenDealRoom = useCallback(
    async (dealToOpen: any) => {

      if (dealToOpen.status === 'PENDING' || dealToOpen.status === 'REJECTED') {
        const { clientAccountId, accountId, token } = getLocalStorageData();
        setOpenDeal(dealToOpen);
        const key = new Date().getTime() + Math.random();
        if (getRole(userRole) !== 'Admin') {
          await dispatch(goToDeal(
            dealToOpen.dealRoomId,
            dealToOpen.id,
            clientAccountId ? accountId : null,
            accountId,
            token,
            key,
          ));
        }
      } else {
        history.push(
          `deal-room/${dealToOpen.dealRoomId}?dealRoomId=${dealToOpen.dealRoomId}&dealId=${dealToOpen.id}`,
          {
            dealId: dealToOpen.id,
            dealRoomId: dealToOpen.dealRoomId,
          },
        );
      }
    },
    [dispatch, userRole],
  );

  const columns: Array<Column<ItemOfDealList>> = [
    {
      title: 'Deal Room',
      field: '',
      render: (rowData) => (
        <ButtonSC
          variant="fill"
          text="Enter"
          size="large"
          color="green"
          disabled={isLoading || rowData.status === 'REJECTED' }
          handleClick={() => handleOpenDealRoom(rowData as ItemOfDealList)}
        />
      ),
    },
    { title: 'No.', field: 'dealRoomId' },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => ((rowData.status === 'DECLINED') ? `${rowData.status} Reason: ${rowData.request.reasonForDecline}` : rowData.status),
      customFilterAndSearch: (term, rowData) => (((rowData.status === 'DECLINED') ? `${rowData.status} Reason: ${rowData.request.reasonForDecline}` : rowData.status).toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Expire',
      field: 'expiredAt',
      render: (rowData) => (getDateTime(rowData.expiredAt)), 
      // render: (rowData) => ((rowData.status === 'TIMEOUT' || rowData.status === 'STARTED' || rowData.status === 'IN PROGRESS') ? getDateTime(rowData.expiredAt) : ''),
      // customFilterAndSearch: (term, rowData) => (((rowData.status === 'TIMEOUT' || rowData.status === 'STARTED' || rowData.status === 'IN PROGRESS') ? getDateTime(rowData.expiredAt) : '').toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Date Started',
      field: 'createdAt',
      render: (rowData) => `${getDateTime(rowData.startedAt) }`,
    },
    {
      title: 'Order No',
      field: 'order.id',
      render: (rowData) => `# ${rowData.order.orderNumber}`,
    },
    { title: 'Type', field: 'order.inventory.inventoryItemType.type' },
    {
      title: 'Quantity',
      field: 'order.quantity',
      render: (rowData) => `${rowData.order.quantity} ${rowData.units}`,
      customFilterAndSearch: (term, rowData) => (`${rowData.order.quantity} ${rowData.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Price',
      field: 'order.price',
      render: (rowData) => `$ ${rowData.order && rowData.order.price && rowData.order.price.toFixed(2)}`,
      customFilterAndSearch: (term, rowData) => (`$ ${rowData.order && rowData.order.price && rowData.order.price.toFixed(2)}`).indexOf(term) !== -1,
    },
    {
      title: 'Unit Price',
      field: 'order.unitPrice',
      render: (rowData) => `$ ${(rowData.order && rowData.order.unitPrice && rowData.order.unitPrice.toFixed(2)) || 'ERROR'}`,
      customFilterAndSearch: (term, rowData) => (`$ ${(rowData.order && rowData.order.unitPrice && rowData.order.unitPrice.toFixed(2)) || 'ERROR'}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Negotiation Points',
      field: 'negotiationPoints',
      render: (rowData) => `${rowData.agreedAmountOfNP}/${rowData.totalAmountOfNP}`,
      customFilterAndSearch: (term, rowData) => (`${rowData.agreedAmountOfNP}/${rowData.totalAmountOfNP}`).indexOf(term) !== -1,
    },
    { title: 'Broker', field: 'brokerUserName' },
    { title: 'Counterparty', field: 'counterpartyUserName' },
  ];

  const columnsAdmin: Array<Column<ItemOfDealList>> = [
    {
      title: 'Deal Room',
      field: '',
      render: (rowData) => (
        <ButtonSC
          variant="fill"
          text="Enter"
          size="large"
          color="green"
          disabled={isLoading}
          handleClick={() => handleOpenDealRoom(rowData as ItemOfDealList)}
        />
      ),
    },
    { title: 'No.', field: 'dealRoomId' },
    { title: 'Status', field: 'status' },
    {
      title: 'Expire',
      field: 'expiredAt',
      render: (rowData) => ((rowData.status === 'TIMEOUT' || rowData.status === 'STARTED' || rowData.status === 'IN PROGRESS') ? getDateTime(rowData.expiredAt) : ''),
      customFilterAndSearch: (term, rowData) => ((rowData.status === 'TIMEOUT' || rowData.status === 'STARTED' || rowData.status === 'IN PROGRESS') ? getDateTime(rowData.expiredAt) : '').indexOf(term) !== -1,
    },
    {
      title: 'Date Started',
      field: 'createdAt',
      render: (rowData) => `${getDateTime(rowData.startedAt) }`,
    },
    {
      title: 'Order No',
      field: 'order.id',
      render: (rowData) => `# ${rowData.order.orderNumber}`,
    },
    { title: 'Type', field: 'order.inventory.inventoryItemType.type' },
    {
      title: 'Quantity',
      field: 'order.quantity',
      render: (rowData) => `${rowData.order.quantity} ${rowData.units}`,
      customFilterAndSearch: (term, rowData) => (`${rowData.order.quantity} ${rowData.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Price',
      field: 'order.price',
      render: (rowData) => `$ ${rowData.order && rowData.order.price && rowData.order.price.toFixed(2)}`,
      customFilterAndSearch: (term, rowData) => (`$ ${rowData.order && rowData.order.price && rowData.order.price.toFixed(2)}`).indexOf(term) !== -1,
    },
    {
      title: 'Unit Price',
      field: 'order.unitPrice',
      render: (rowData) => `$ ${(rowData.order && rowData.order.unitPrice && rowData.order.unitPrice.toFixed(2)) || 'ERROR'}`,
      customFilterAndSearch: (term, rowData) => (`$ ${(rowData.order && rowData.order.unitPrice && rowData.order.unitPrice.toFixed(2)) || 'ERROR'}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    }, // TODO: del
    {
      title: 'Negotiation Points',
      field: 'negotiationPoints',
      render: (rowData) => `${rowData.agreedAmountOfNP}/${rowData.totalAmountOfNP}`,
      customFilterAndSearch: (term, rowData) => (`${rowData.agreedAmountOfNP}/${rowData.totalAmountOfNP}`).indexOf(term) !== -1,
    },
    {
      title: 'Members',
      field: 'members',
      render: (rowData) => (rowData.members && (
        <>
          {rowData.members.seller && <div key={rowData.members.seller}>{`Seller: ${rowData.members.seller}`}</div>}
          {rowData.members.buyer && <div key={rowData.members.buyer}>{`Buyer: ${rowData.members.buyer}`}</div>}
          {rowData.members.sellerBroker && <div key={rowData.members.sellerBroker}>{`Seller Broker: ${rowData.members.sellerBroker}`}</div>}
          {rowData.members.buyerBroker && <div key={rowData.members.buyerBroker}>{`Buyer Broker: ${rowData.members.buyerBroker}`}</div>}
        </>
      )),
      customFilterAndSearch: (term, rowData) => (rowData.members ? (`
          ${rowData.members.seller && `Seller: ${rowData.members.seller}`}
          ${rowData.members.buyer && `Buyer: ${rowData.members.buyer}`}
          ${rowData.members.sellerBroker && `Seller Broker: ${rowData.members.sellerBroker}`}
          ${rowData.members.buyerBroker && `Buyer Broker: ${rowData.members.buyerBroker}`}
      `.toLowerCase()) : '').indexOf(term.toLowerCase()) !== -1,
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && Object.keys(openDeal).length !== 0) {
      history.push(
        `deal-room/${openDeal.dealRoomId}?dealRoomId=${openDeal.dealRoomId}&dealId=${openDeal.id}`,
        {
          dealId: openDeal.id,
          dealRoomId: openDeal.dealRoomId,
        },
      );
    }
  }, [error, isLoading, openDeal, history]);

  useEffect(() => {
    if (error) {
      setOpenDeal({} as ItemOfDealList);
    }
  }, [error, setOpenDeal]);

  return (
    <TableContainer>
      <MaterialTable
        title=""
        columns={getRole(userRole) !== 'Admin' ? columns : columnsAdmin}
        data={currentListOfDeals}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
          },
          pageSize: +cookies.rowListOfDeals || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowListOfDeals', pageSize, { path: '/' })}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
        }}
      />
    </TableContainer>
  );
};

export default ListOfDeals;
