
import React, { FC } from 'react';
import { Hidden } from '@material-ui/core';
import { LogoInfoLarge, LogoInfoMiddle, LogoInfoSmall } from '../../svg/logoInfo';
import useStyles from './style';


const InfoPageContainer: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <LogoInfoLarge />
      </Hidden>
      <Hidden only={['xs', 'lg', 'xl']}>
        <LogoInfoMiddle />
      </Hidden>
      <Hidden smUp>
        <LogoInfoSmall />
      </Hidden>
      {children}
    </div>
  );
};

export default InfoPageContainer;
