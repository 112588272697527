import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import {
  apiCreateOrderQuestion, apiLoadAnswersForOrderQuestion, apiAddQuestionAnswer, apiMarkAsViewedAnswer,
} from '../../api/alertsAndMessages';
import { updateListAlertsAndMessages } from './alertsAndMessages.slice';
import translationEN from '../../locales/en/translation.json';

const initialState: any = {
  orderQuestionAnswers: null,
  orderQuestion: null,
  error: '',
  isLoading: false,
  orderQuestionAnswer: null,
};

const orderQuestionSlice = createSlice({
  initialState,
  name: 'orderQuestion',
  reducers: {
    orderQuestionCreateRequest(state) {
      state.orderQuestion = null;
      state.error = '';
      state.isLoading = true;
    },
    orderQuestionCreateSuccess(state, action) {
      state.orderQuestion = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    dealSuccessNotUpdDeal(state) {
      state.error = '';
      state.isLoading = false;
    },
    orderQuestionCreateFailure(state, action) {
      state.orderQuestion = null;
      state.error = action.payload;
      state.isLoading = false;
    },
    // orderAnswers
    loadAnswersForOrderQuestionRequest(state) {
      state.orderQuestionAnswers = null;
      state.error = '';
      state.isLoading = true;
    },
    loadAnswersForOrderQuestionSuccess(state, action) {
      state.orderQuestionAnswers = action.payload;
      state.error = '';
      state.isLoading = false;
    },

    loadAnswersForOrderQuestionFailure(state, action) {
      state.orderQuestionAnswers = null;
      state.error = action.payload;
      state.isLoading = false;
    },

    // answer
    orderQuestionAddAnswerRequest(state) {
      state.orderQuestionAnswer = null;
      state.error = '';
      state.isLoading = true;
    },
    orderQuestionAddAnswerSuccess(state, action) {
      state.orderQuestionAnswer = action.payload;
      state.error = '';
      state.isLoading = false;
    },

    orderQuestionAddAnswerFailure(state, action) {
      state.orderQuestionAnswer = null;
      state.error = action.payload;
      state.isLoading = false;
    },

  },
});

export const {
  orderQuestionCreateRequest,
  orderQuestionCreateSuccess,
  //   dealSuccessNotUpdDeal,
  orderQuestionCreateFailure,
  loadAnswersForOrderQuestionRequest,
  loadAnswersForOrderQuestionSuccess,
  loadAnswersForOrderQuestionFailure,
  // answer
  orderQuestionAddAnswerRequest,
  orderQuestionAddAnswerSuccess,
  orderQuestionAddAnswerFailure,
} = orderQuestionSlice.actions;

export const createOrderQuestion = (
  orderId: number,
  accountIdOfQuestionsAuthor: number | null,
  text: string,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(orderQuestionCreateRequest());
    await dispatch(apiCreateOrderQuestion(
      orderId,
      accountIdOfQuestionsAuthor,
      text,
      token,
    ));
    dispatch(orderQuestionCreateSuccess(undefined));
    dispatch(addNotification({
      key,
      message: translationEN.YourQuestionHasBeenSentSuccessfully,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(orderQuestionCreateFailure(error?.response ?
      error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

// add anwer
export const createOrderQuestionAnswer = (
  orderQuestionId: number | null,
  text: string,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(orderQuestionAddAnswerRequest());
    const response = await dispatch(apiAddQuestionAnswer(
      orderQuestionId,
      text,
      token,
    ));
    dispatch(updateListAlertsAndMessages(response));
    dispatch(orderQuestionAddAnswerSuccess(undefined));
    dispatch(addNotification({
      key,
      message: translationEN.Success,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(orderQuestionAddAnswerFailure(error?.response ?
      error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const loadAnswersForOrderQuestion = (
  messageId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(loadAnswersForOrderQuestionRequest());
    const loadedOrderWithAnswers = await dispatch(apiLoadAnswersForOrderQuestion(
      messageId,
      token,
    ));
    dispatch(loadAnswersForOrderQuestionSuccess(loadedOrderWithAnswers));
  } catch (error) {
    dispatch(loadAnswersForOrderQuestionFailure(error?.response ?
      error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const markAsViewedAnswer = (
  orderAnswerId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
): AppThunk => async (dispatch) => {
  try {
    const response = await dispatch(apiMarkAsViewedAnswer(
      orderAnswerId,
      token,
    ));
    dispatch(updateListAlertsAndMessages(response));
  } catch (error) {
    //   dispatch(orderQuestionAddAnswerFailure(error?.response ?
    //     error?.response?.data?.error : error?.message));
  }
};

export const createDealFromBuyer = 'test';

export default orderQuestionSlice.reducer;
