import React, {
  FC,
  useCallback,
  ChangeEvent,
} from 'react';
import { Paper } from '@material-ui/core';
import useStyles from './FoleUploader.style';

interface Props {
  multiSelect: boolean;
  uploadFile: (file: Array<File>) => void;
}

const FileUploader: FC<Props> = ({ multiSelect, uploadFile }) => {
  const classes = useStyles();

  const handleFileUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const documents: Array<File> = Object.values(e.target.files);
      uploadFile(documents);
      e.target.value = '';
    }
  }, [uploadFile]);

  return (
    <Paper className={classes.fileUploader}>
      <input
        type="file"
        data-testid="upload-file"
        className={classes.fileUploaderInput}
        // accept="application/pdf"
        onChange={handleFileUpload}
        multiple={multiSelect}
      />
      <div className={classes.buttonName}>
        Choose file
      </div>
    </Paper>
  );
};

export default FileUploader;
