import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Input,
  Hidden,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useLocation, useHistory, Redirect } from 'react-router';
import { getAllOrders, getAllOrdersForeGuest } from '../../store/slices/order.slice';
import { getInventoryTypes } from '../../store/slices/inventoryTypes.slice';
import { OrderMP } from '../../interfaces/order.interface';
import useStyles from './style';
// import getLocalStorageData from '../../api/localStorage';
import { RootState } from '../../store/slices';
import OrderCard from '../../components/Order/OrderCard';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import { orders } from '../../mockDataForTest';
import getLocalStorageData from '../../api/localStorage';

const PublicMarketPlace = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location: any = useLocation();
  const history = useHistory();
  const [typeInventory, setTypeInventory] = useState<string>();
  const [unitInventory, setUnitInventory] = useState<string>();

  const [buyOrders, setBuyOrders] = useState<Array<OrderMP>>([]);
  const [sellOrders, setSellOrders] = useState<Array<OrderMP>>([]);
  const [tabValue, setTabValue] = useState(0);
  const {
    listOfOrders,
  } = useSelector(
    (state: RootState) => state.order,
  );


  const {
    listOfInventoryTypes,
  } = useSelector(
    (state: RootState) => state.inventoryTypes,
  );

  const {
    isLoading,
  } = useSelector(
    (state: RootState) => state.deals,
  );

  // useEffect(() => {
  //   if (location.pathname.includes('deal-room')) {
  //     const dealRoom = parseInt(location.search.split('&')[0].match(/\d+/));
  //     const dealId = parseInt(location.search.split('&')[1].match(/\d+/));
  //     history.push(`/signin?prev_page=${location.pathname}?dealId=${dealId}&dealRoomId=${dealRoom}`);
  //   } else if (location.pathname !== '/') {
  //     history.push(`/signin?prev_page=${location.pathname}`);
  //   }
  // }, [location, history]);

  useEffect(() => {
  const { token } = getLocalStorageData();
    // const token: string | null = '';
    dispatch(getInventoryTypes(null, new Date().getTime() + Math.random()));
    // dispatch(getAllOrdersForeGuest(token, new Date().getTime() + Math.random()));
  }, [dispatch]);

  useEffect(() => {
    if (listOfInventoryTypes.length !== 0) {
      const type = listOfInventoryTypes[0];
      setTypeInventory(type.type);
      setUnitInventory(type.units);
    }
  }, [dispatch, listOfInventoryTypes]);

  useEffect(() => {
    const slicelistOfOrders = orders.slice();
    const buyOrder = slicelistOfOrders
      .filter((order: OrderMP) => (order.orderStatus === 'OPEN' && order.orderType === 'BUY' && (typeInventory !== 'ALL' ? order.inventory && (typeInventory === order.inventory.inventoryItemType.type) : true)))
      .sort((a: OrderMP, b: OrderMP) => b.unitPrice - a.unitPrice);
    setBuyOrders(buyOrder);
    const sellOrder = slicelistOfOrders
      .filter((order: OrderMP) => (order.orderStatus === 'OPEN' && order.orderType === 'SELL' && (typeInventory !== 'ALL' ? order.inventory && (typeInventory === order.inventory.inventoryItemType.type) : true)))
      .sort((a: OrderMP, b: OrderMP) => a.unitPrice - b.unitPrice);
    setSellOrders(sellOrder);
  }, [listOfOrders, typeInventory, setBuyOrders, setSellOrders]);

  const handleChangeType = useCallback(
    (value) => {
      if (listOfInventoryTypes.length !== 0) {
        const type = listOfInventoryTypes.filter((item) => item.type === value)[0];
        setTypeInventory(type.type);
        setUnitInventory(type.units);
      }
    },
    [setTypeInventory, listOfInventoryTypes],
  );

  return (
    <PaperContainer>
      {listOfInventoryTypes.length !== 0 && typeInventory && (
        <Grid container direction="column" className={classes.root}>
          <Grid container item justifyContent="space-between" className={cx(classes.paddingBottom16, classes.titlePanel)}>
            <Hidden xsDown>
              <Grid item xs={4} className={classes.title}>
                <Typography variant="h1">
                  Bids to Buy
                </Typography>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={6}>
                <Tabs
                  value={tabValue}
                  onChange={(event, newValue) => setTabValue(newValue)}
                >
                  <Tab
                    label="Bids to Buy"
                    classes={{
                      root: classes.rootTab,
                    }}
                  />
                  <Tab
                    label="Offers to Sell"
                    classes={{
                      root: classes.rootTab,
                    }}
                  />
                </Tabs>
              </Grid>
            </Hidden>
            <Grid item xs={4}>
              <Select
                name="type"
                id="type"
                value={typeInventory}
                onChange={({ target }) => handleChangeType(target.value)}
                fullWidth
                input={(
                  <Input
                    classes={{
                      underline: classes.underline,
                    }}
                  />
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {listOfInventoryTypes.map((item) => (
                  <MenuItem key={item.type} value={item.type}>{item.type}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={4} className={classes.title}>
                <Typography variant="h1">
                  Offers to Sell
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Hidden xsDown>
            <Grid container item justifyContent="space-between" className={classes.container}>
              <Grid container item sm={6} alignContent="flex-start" className={classes.ordersContainerRight}>
                {buyOrders.length !== 0 ? (
                  <>
                    <Grid container item className={cx(classes.titleCard, classes.card)}>
                      <Grid container item xs={2} className={classes.cardItems} alignContent="center">
                        <Grid item xs={12} className={classes.titleItem}>Action</Grid>
                      </Grid>
                      <Grid container item xs={10} className={classes.cardItems} alignContent="center">
                        <Grid item xs={4} className={classes.titleItem}>Price $</Grid>
                        <Grid item xs={4} className={classes.titleItem}>
                          {/* TODO: del lb */}
                          {`Amount ${unitInventory || 'lb'}`}
                        </Grid>
                        <Grid item xs={4} className={classes.titleItem}>Total $</Grid>
                      </Grid>
                    </Grid>
                    {
                      buyOrders
                        .map((order: OrderMP) => (
                          <OrderCard
                            id={order.id}
                            key={order.createdAt}
                            inventory={order.inventory}
                            price={order.price}
                            quantity={order.quantity}
                            unitPrice={order.unitPrice}
                            btnLabel="Sell"
                            ownerAccountId={order.ownerAccountId}
                            isLoading={isLoading}
                            type="sell"
                          />
                        ))
                    }
                  </>
                )
                  : (
                    <Grid item xs={12}>
                      <Typography variant="h1" align="center" color="secondary" className={classes.marginTop32}>
                        No orders
                      </Typography>
                    </Grid>
                  )}
              </Grid>
              <Grid container item sm={6} alignContent="flex-start" className={classes.ordersContainerLeft}>
                {sellOrders.length !== 0 ? (
                  <>
                    <Grid container item className={cx(classes.titleCard, classes.card)}>
                      <Grid container item xs={10} className={classes.cardItems} alignContent="center" justifyContent="flex-end">
                        <Grid item xs={4} className={classes.titleItem}>
                          Price $
                        </Grid>
                        <Grid item xs={4} className={classes.titleItem}>
                          {`Amount ${unitInventory || 'lb'}`}
                        </Grid>
                        <Grid item xs={4} className={classes.titleItem}> Total $ </Grid>
                      </Grid>
                      <Grid container item xs={2} className={classes.cardItems} alignContent="center">
                        <Grid item xs={12} className={classes.titleItem}>
                          Action
                        </Grid>
                      </Grid>
                    </Grid>
                    {sellOrders
                      .map((order: OrderMP) => (
                        <OrderCard
                          id={order.id}
                          key={order.createdAt}
                          inventory={order.inventory}
                          price={order.price}
                          quantity={order.quantity}
                          unitPrice={order.unitPrice}
                          btnLabel="Buy"
                          ownerAccountId={order.ownerAccountId}
                          isLoading={isLoading}
                          type="buy"
                        />
                      ))}
                  </>
                )
                  : (
                    <Grid item xs={12}>
                      <Typography variant="h1" align="center" color="secondary" className={classes.marginTop32}>
                        No orders
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid container item justifyContent="space-between" className={classes.container}>
              {tabValue === 0 && (
                <Grid container item xs={12} alignContent="flex-start" className={classes.ordersContainerRight}>
                  {buyOrders.length !== 0 ? (
                    <>
                      <Grid container item className={cx(classes.titleCard, classes.card)}>
                        <Grid container item xs={2} className={classes.cardItems} alignContent="center">
                          <Grid item xs={12} className={classes.titleItem}>Action</Grid>
                        </Grid>
                        <Grid container item xs={10} className={classes.cardItems} alignContent="center">
                          <Grid item xs={4} className={classes.titleItem}>Price $</Grid>
                          <Grid item xs={4} className={classes.titleItem}>
                            {`Amount ${unitInventory || 'lb'}`}
                          </Grid>
                          <Grid item xs={4} className={classes.titleItem}>Total $</Grid>
                        </Grid>
                      </Grid>
                      {buyOrders
                        .map((order: OrderMP) => (
                          <OrderCard
                            id={order.id}
                            key={order.createdAt}
                            inventory={order.inventory}
                            price={order.price}
                            quantity={order.quantity}
                            unitPrice={order.unitPrice}
                            btnLabel="Sell"
                            ownerAccountId={order.ownerAccountId}
                            isLoading={isLoading}
                            type="sell"
                          />
                        ))}
                    </>
                  )
                    : (
                      <Grid item xs={12}>
                        <Typography variant="h1" align="center" color="secondary" className={classes.marginTop32}>
                          No orders
                        </Typography>
                      </Grid>
                    )}
                </Grid>
              )}
              {tabValue === 1 && (
                <Grid container item xs={12} alignContent="flex-start" className={classes.ordersContainerLeft}>
                  {sellOrders.length !== 0 ? (
                    <>
                      <Grid container item className={cx(classes.titleCard, classes.card)}>
                        <Grid container item xs={10} className={classes.cardItems} alignContent="center" justifyContent="flex-end">
                          <Grid item xs={4} className={classes.titleItem}>Price $</Grid>
                          <Grid item xs={4} className={classes.titleItem}>
                            {`Amount ${unitInventory || 'lb'}`}
                          </Grid>
                          <Grid item xs={4} className={classes.titleItem}>Total $</Grid>
                        </Grid>
                        <Grid container item xs={2} className={classes.cardItems} alignContent="center">
                          <Grid item xs={12} className={classes.titleItem}>Action</Grid>
                        </Grid>
                      </Grid>
                      {sellOrders
                        .map((order: OrderMP) => (
                          <OrderCard
                            id={order.id}
                            key={order.createdAt}
                            inventory={order.inventory}
                            price={order.price}
                            quantity={order.quantity}
                            unitPrice={order.unitPrice}
                            btnLabel="Buy"
                            ownerAccountId={order.ownerAccountId}
                            isLoading={isLoading}
                            type="buy"
                          />
                        ))}
                    </>
                  )
                    : (
                      <Grid item xs={12}>
                        <Typography variant="h1" align="center" color="secondary" className={classes.marginTop32}>
                          No orders
                        </Typography>
                      </Grid>
                    )}
                </Grid>
              )}
            </Grid>
          </Hidden>
        </Grid>
      )}
    </PaperContainer>
  );
};

export default PublicMarketPlace;
