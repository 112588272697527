import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const MyRequest = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path d="M1.6 0H14.4C14.8243 0 15.2313 0.168571 15.5314 0.468629C15.8314 0.768687 16 1.17565 16 1.6V11.2C16 11.6243 15.8314 12.0313 15.5314 12.3314C15.2313 12.6314 14.8243 12.8 14.4 12.8H11.4071C11.2745 12.8 11.1473 12.8527 11.0536 12.9464L8.35355 15.6464C8.15829 15.8417 7.84171 15.8417 7.64645 15.6464L4.94645 12.9464C4.85268 12.8527 4.7255 12.8 4.59289 12.8H1.6C1.17565 12.8 0.768687 12.6314 0.468629 12.3314C0.168571 12.0313 0 11.6243 0 11.2V1.6C0 1.17565 0.168571 0.768687 0.468629 0.468629C0.768687 0.168571 1.17565 0 1.6 0Z" />
  </SvgIcon>
);

export default MyRequest;
