import constants from '../core/constants';
import api from '../core/api';
import { PostTradeProcessingTemplates } from '../interfaces/postTradeProcessingTemplates.interface';
import request from './apiRequest';

// GetAllRequests
export const apiGetAllPostTradeProcessingTemplates = (
  typeId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllPTSTemplate}${typeId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

export const apiUpdatePostTradeProcessingTemplate = (
  steps: PostTradeProcessingTemplates[],
  postProcessingTemplateId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.updatePTS}${postProcessingTemplateId}`;
  return request(
    url,
    'POST',
    steps,
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};
