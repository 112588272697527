import { createSlice } from '@reduxjs/toolkit';
import { SnackbarState } from '../../interfaces/snackbar.interface';

const initialState: SnackbarState = {
  notifications: [],
};

const snackbarSlice = createSlice({
  initialState,
  name: 'snackbar',
  reducers: {
    addNotification(state, action) {
      state.notifications = [
        ...state.notifications,
        {
          key: action.payload.key,
          message: action.payload.message,
          options: {
            ...action.payload.options,
            preventDuplicate: true,
          },
          action: action.payload.action,
        },
      ];
    },

    setNotifications(state, action) {
      state.notifications = action.payload
    },
    delNotification(state, action) {
      state.notifications.filter(
        (notification) => notification.key !== action.payload.key,
      );
    },

  },
});

export const { addNotification, delNotification, setNotifications } = snackbarSlice.actions;

export default snackbarSlice.reducer;
