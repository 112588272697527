import React, { FC, useState } from 'react';
import cx from 'classnames';

import {
  Typography,
  Paper,
  Grid,
  Link,
  FormControlLabel,
  Checkbox,
  Avatar,

  Dialog,

  IconButton,
  Button,

} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import constants from '../../core/constants';
import { getDate } from '../../utils/getDate';
import { Inventory } from '../../interfaces/inventory.interface';
import useStyles from './DealRoom.style';
import { InventoryItemTypeField } from '../../interfaces/inventoryTypes.interface';

interface Props {
  inventory: Inventory;
}

const InventoryInfo: FC<Props> = ({
  inventory,
}) => {
  const {
    inventoryItemType,
    instantCheckout,
    title,
    shortDescription,
    description,
    // addressOfOriginId,
    // currentLocationAddress,
    // harvestDate,
    // expirationDate,,
    // proveDocuments,
    generalAttestationDate,
  } = inventory;
  const classes = useStyles();

  const [openFullSizeImageDialog, setOpenFullSizeImageDialog] = useState(false);
  const [openImageSrc, setOpenImageSrc] = useState('');
  const [seeMore, setSeeMore] = useState<{ name: string; open: boolean }>({ name: '', open: false });

  const handleOpenFullSizeImageDialog = async (imgSrc: any) => {
    setOpenFullSizeImageDialog(true);
    setOpenImageSrc(imgSrc);
  };

  // test

  const handleGetValueOfynamicField = (item: InventoryItemTypeField) => {
    if (item.typeOfField === 'DATE') return (item.value && getDate(item.value as string)) || '';
    if (item.typeOfField === 'NUMBER' && item.format === 'PERCENT' && item.value) return `${item.value} %`;
    if (item.typeOfField === 'NUMBER' && item.format === 'DOLLAR' && item.value) return `$ ${item.value}`;
    if (item.typeOfField === 'BOOLEAN') return item.value === 'false' ? 'No' : 'Yes';
    return item.value || '';
  };

  return (
    <Paper className={classes.dealPaper}>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.marginBottom16}>
            Description of the product:
          </Typography>
        </Grid>
        {instantCheckout && (
          <Grid container item xs={12} lg={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={instantCheckout}
                  color="primary"
                  checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                />
              )}
              label="Instant$ Checkout"
            />
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          spacing={1}
          className={cx(classes.heightFitContent, classes.maxHeight177)}
        >
          {/* <div> */}
            {/* Type */}
            <Grid container item xs={12} lg={6}>
              <Grid item xs={4}>
                <Typography className={classes.heading} noWrap>
                  Type:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.secondaryHeading}>
                  {inventoryItemType.type}
                </Typography>
              </Grid>
            </Grid>

            {/* Unit */}
            <Grid container item xs={12} lg={6}>
              <Grid item xs={4}>
                <Typography className={classes.heading}>
                  Unit:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.secondaryHeading}>
                  {inventoryItemType.units}
                </Typography>
              </Grid>
            </Grid>

            {/* Title */}
            <Grid container item xs={12} lg={6}>
              <Grid item xs={4}>
                <Typography className={classes.heading}>
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.secondaryHeading}>
                  {title}
                </Typography>
              </Grid>
            </Grid>

            {/* Description: */}
            <Grid container item xs={12} lg={6}>
              <Grid item xs={4}>
                <Typography className={classes.heading} noWrap>
                  Description:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {description && (
                  <>
                    <Typography variant="body1" className={!(seeMore.name === 'Description' && seeMore.open) ? classes.longDescriptionHide : classes.secondaryHeading}>
                      {description}
                    </Typography>
                    {description.length > 30 && (
                      !(seeMore.name === 'Description' && seeMore.open) ?
                        (
                          <Button
                            className={classes.longDescriptionBtn}
                            onClick={() => setSeeMore({ name: 'Description', open: true })}
                          >
                            See more
                          </Button>
                        ) :
                        (
                          <Button
                            className={classes.longDescriptionBtn}
                            onClick={() => setSeeMore({ name: 'Description', open: false })}
                          >
                            See less
                          </Button>
                        )
                    )}
                  </>
                )}
              </Grid>
            </Grid>

            {/* Short Description: */}
            <Grid container item xs={12} lg={6}>
              <Grid item xs={4}>
                <Typography className={classes.heading} noWrap>
                  Short Description:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.secondaryHeading}>
                  {shortDescription}
                </Typography>
              </Grid>
            </Grid>
            {inventoryItemType.inventoryItemTypeFieldsTypes &&
              inventoryItemType.inventoryItemTypeFieldsTypes.length > 0 &&
              inventoryItemType.inventoryItemTypeFieldsTypes.map((item: any) => (

                <Grid container item xs={12} lg={6} key={item.id}>
                  <Grid item xs={4}>
                    <Typography className={classes.heading}>
                      {`${(item.name).replace(/\/,/g, '.')}:`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {item.typeOfField !== 'DOCUMENTS' && (
                      <Typography variant="body1" className={!(seeMore.name === item.name && seeMore.open) ? classes.longDescriptionHide : classes.secondaryHeading}>
                        {handleGetValueOfynamicField(item)}
                      </Typography>
                    )}
                    {item.typeOfField === 'DOCUMENTS' && (
                      <Typography variant="body1">
                        {item.proveDocuments?.map((doc: { link: string; title: string }) => (
                          <Link key={doc.link} href={`${constants.AWS_URL}${doc.link}`} target="_blank" rel="noreferrer">
                            {doc.title}
                          </Link>
                        ))}
                      </Typography>
                    )}
                    {item.typeOfField === 'STRING' && item.value.length > 30 && (
                      !(seeMore.name === item.name && seeMore.open) ?
                        (
                          <Button
                            className={classes.longDescriptionBtn}
                            onClick={() => setSeeMore({ name: item.name, open: true })}
                          >
                            See more
                          </Button>
                        ) :
                        (
                          <Button
                            className={classes.longDescriptionBtn}
                            onClick={() => setSeeMore({ name: item.name, open: false })}
                          >
                            See less
                          </Button>
                        )
                    )}
                  </Grid>
                </Grid>
              ))}
          {/* </div> */}
          {/* <div> */}
            {/* Attest: */}
            <Grid container item xs={12} lg={6}>
              <Grid item xs={4}>
                <Typography className={classes.heading} noWrap>
                  Lab Analysis:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {inventory?.labAttest && inventory?.labAttestationDocs ? (
                  inventory?.labAttestationDocs &&
                  inventory?.labAttestationDocs.map((doc: { link: string; title: string }) => (
                    <Typography variant="body1" key={doc.link}>
                      <Link href={`${constants.AWS_URL}${doc.link}`} target="_blank" rel="noreferrer">
                        Click here to see Test Results
                      </Link>
                    </Typography>
                  ))
                ) :
                  (
                    <Typography variant="body1" className={classes.secondaryHeading}>
                      Not Attested
                    </Typography>
                  )}
              </Grid>
            </Grid>

            <Grid container item xs={12} lg={6}>
              <Grid item xs={4}>
                <Typography className={classes.heading} noWrap>
                  General Attestation:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {generalAttestationDate ? (
                  <Typography variant="body1" className={classes.secondaryHeading}>
                    {getDate(generalAttestationDate)}
                  </Typography>
                ) :
                  (
                    <Typography variant="body1" className={classes.secondaryHeading}>
                      Not Attested
                    </Typography>
                  )}
              </Grid>
            </Grid>

            <Grid container item xs={12} lg={6}>
              <Grid item xs={4}>
                <Typography className={classes.heading} noWrap>
                  Display Image:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {inventory?.productPicture && (
                  <Avatar
                    onClick={() => {
                      if (inventory.productPicture[0]) {
                        handleOpenFullSizeImageDialog(
                          `${constants.AWS_URL}${inventory?.productPicture[0].link}`,
                        );
                      }
                    }}
                    variant="rounded"
                    alt="Avatar"
                    className={classes.picture}
                    src={(inventory?.productPicture
                      && inventory?.productPicture[0]
                      && `${constants.AWS_URL}${inventory?.productPicture[0].link}`)}
                  />
                )}
              </Grid>
            </Grid>
          {/* </div> */}
        </Grid>
      </Grid>

      {/* dialog with fullSize image */}
      <Dialog
        open={openFullSizeImageDialog}
        onClose={() => setOpenFullSizeImageDialog(false)}
        scroll="paper"
        classes={{ paper: classes.dialogPaperFullSizeImage }}
      >

        <IconButton aria-label="close" onClick={() => setOpenFullSizeImageDialog(false)} className={classes.closeModalIcon}>
          <CloseIcon />
        </IconButton>

        <img className={classes.fullSizeImageInsideDialog} src={openImageSrc} alt="" />

      </Dialog>

    </Paper>
  );
};

export default InventoryInfo;
