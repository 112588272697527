import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TermsAndConditions = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path d="M13.0668 0C11.0948 0.0087 9.62892 0.288 8.56813 0.7707C7.77655 1.1307 7.4668 1.4031 7.4668 2.3259V12C8.67926 10.875 9.75521 10.56 14.0001 10.56V0H13.0668Z" />
    <path d="M0.933333 0C2.90529 0.0087 4.37121 0.288 5.432 0.7707C6.22358 1.1307 6.53333 1.4031 6.53333 2.3259V12C5.32088 10.875 4.24492 10.56 0 10.56V0H0.933333Z" />
  </SvgIcon>
);

export default TermsAndConditions;
