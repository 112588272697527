import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import MaterialTable, { Column } from 'material-table';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../store/slices';
import { getAllOrdersByAccountId, deleteOrder, getAllOrdersForeGuest } from '../../store/slices/order.slice';
import { OrderMP } from '../../interfaces/order.interface';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';

const ListOrders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentOrderListActive, setListOfOrdersActive] = useState<OrderMP[]>([]);
  const [currentOrderList, setListOfOrders] = useState<OrderMP[]>([]);

  const [cookies, setCookie] = useCookies(['rowListOrders', 'rowListOrdersHistory']);

  const {
    listOfOrders,
  } = useSelector(
    (state: RootState) => state.order,
  );

  useEffect(() => {

    const { accountId, clientAccountId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    accountId ? dispatch(getAllOrdersByAccountId(clientAccountId || accountId, token, key)) : dispatch(getAllOrdersForeGuest(token, key));
  }, [dispatch]);
  

  useEffect(() => {
    const { accountId, clientAccountId } = getLocalStorageData();
    const user = clientAccountId || accountId || 0;
    const userListOrdersActive = listOfOrders
      .filter((item) => ((item.orderStatus === 'OPEN' || item.orderStatus === 'IN PROGRESS') && +item.ownerAccountId === +user));
    setListOfOrdersActive(userListOrdersActive.map((item) => ({ ...item })));
    const userListOrders = listOfOrders
      .filter((item) => ((item.orderStatus !== 'OPEN' && item.orderStatus !== 'IN PROGRESS') && +item.ownerAccountId === +user));
    setListOfOrders(userListOrders.map((item) => ({ ...item })));
  }, [setListOfOrdersActive, setListOfOrders, listOfOrders]);

  const columns: Array<Column<OrderMP>> = [
    {
      title: 'Status',
      field: 'orderStatus',
    },
    {
      title: 'Order Type',
      field: 'orderType',
    },
    {
      title: 'Inventory Type',
      field: 'inventory.inventoryItemType.type',
    },
    {
      title: 'Unit Price',
      field: 'unitPrice',
      render: (rowData) => `$ ${rowData.unitPrice.toFixed(2)}/ ${rowData.inventory?.inventoryItemType.units}`,
      customFilterAndSearch: (term, rowData) => (`$ ${rowData.unitPrice.toFixed(2)}/ ${rowData.inventory?.inventoryItemType.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Amount',
      field: 'quantity',
      render: (rowData) => `${Number.isInteger(rowData.quantity) ? rowData.quantity : rowData.quantity.toFixed(2)} ${rowData.inventory?.inventoryItemType.units}`,
      customFilterAndSearch: (term, rowData) => (`${Number.isInteger(rowData.quantity) ? rowData.quantity : rowData.quantity.toFixed(2)} ${rowData.inventory?.inventoryItemType.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Total Price',
      field: 'price',
      render: (rowData) => `$ ${rowData.price.toFixed(2)}`,
      customFilterAndSearch: (term, rowData) => (`$ ${rowData.price.toFixed(2)}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Broker',
      field: 'brokerUserName',
    },
    {
      title: 'Order №',
      field: 'orderNumber',
      render: (rowData) => `${rowData?.orderNumber && rowData?.orderNumber}`,
    },
  ];

  return (
    <TableContainer>
      <MaterialTable
        title="Active Orders"
        columns={columns}
        data={currentOrderListActive}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
          },
          pageSize: +cookies.rowListOrders || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowListOrders', pageSize, { path: '/' })}
        actions={[
          (rowData: OrderMP) => ({
            icon: 'edit',
            iconProps: rowData.orderStatus === 'OPEN' ? { color: 'primary' } : { color: 'secondary' },
            tooltip: 'Edit',
            disabled: rowData.orderStatus !== 'OPEN',
            onClick: () => {
              if (rowData.orderStatus === 'OPEN') {
                history.push(
                  'editOrder',
                  {
                    orderId: rowData.id,
                    inventoryId: rowData.inventory && rowData.inventory.id,
                  },
                );
              }
            },
          }),
          (rowData: OrderMP) => ({
            icon: 'cancel',
            iconProps: rowData.orderStatus === 'OPEN' ? { color: 'error' } : { color: 'secondary' },
            tooltip: 'Cancel',
            onClick: () => {
              const { token } = getLocalStorageData();
              const key = new Date().getTime() + Math.random();
              dispatch(deleteOrder(rowData.id, token, key));
            },
          }),
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
        }}
      />
      <br />
      <MaterialTable
        title="History"
        columns={columns}
        data={currentOrderList}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
          },
          pageSize: +cookies.rowListOrdersHistory || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowListOrdersHistory', pageSize, { path: '/' })}
        actions={[
          (rowData: OrderMP) => ({
            icon: 'edit',
            iconProps: rowData.orderStatus === 'OPEN' ? { color: 'primary' } : { color: 'secondary' },
            tooltip: 'Edit',
            disabled: true,
            onClick: () => {
              if (rowData.orderStatus === 'OPEN') {
                history.push(
                  'editOrder',
                  {
                    orderId: rowData.id,
                    inventoryId: rowData.inventory && rowData.inventory.id,
                  },
                );
              }
            },
          }),
          (rowData: OrderMP) => ({
            icon: 'cancel',
            iconProps: rowData.orderStatus === 'OPEN' ? { color: 'error' } : { color: 'secondary' },
            disabled: true,
            tooltip: 'Cancel',
            onClick: () => {
              const { token } = getLocalStorageData();
              const key = new Date().getTime() + Math.random();
              dispatch(deleteOrder(rowData.id, token, key));
            },
          }),
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
        }}
      />
    </TableContainer>
  );
};

export default ListOrders;
