import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../index";
import { addNotification } from "./snackbar.slice";
import { OrderState, Order } from "../../interfaces/order.interface";
import {
  apiGetAllOrders,
  apiCreateBuyerOrder,
  apiCreateSellerOrder,
  apiGetAllOrdersByAccountId,
  apiGetOrderById,
  apiUpdateOrder,
  apiDeleteOrder,
} from "../../api/order";
import { InventoryItemTypeField } from "../../interfaces/inventoryTypes.interface";

const initialState: OrderState = {
  listOfOrders: [],
  listOfOrdersSell: [],
  order: null,
  error: "",
  isLoadingOrdersSell: false,
  isLoadingOrders: false,
};

const orderSellSlice = createSlice({
  initialState,
  name: "orderSell",
  reducers: {
    orderRequest(state) {
      state.error = "";
      state.isLoadingOrdersSell = false;
    },
    orderSuccess(state, action) {
      state.order = action.payload;
      state.error = "";
      state.isLoadingOrders = true;
    },
    orderFailure(state, action) {
      state.error = action.payload;
      state.isLoadingOrders = false;
    },
    listOrderRequest(state) {
      state.listOfOrdersSell = [];
      state.error = "";
      state.isLoadingOrdersSell = false;
    },
    listOrderSuccess(state, action) {
      state.listOfOrdersSell = action.payload;
      state.error = "";
      state.isLoadingOrdersSell = true;
    },
    listOrderFailure(state, action) {
      state.listOfOrdersSell = [];
      state.error = action.payload;
      state.isLoadingOrders = false;
    },
    updateListOfOrders(state, action) {
      const index = state.listOfOrdersSell.findIndex(
        (obj: Order) => obj.id === action.payload.id
      );
      if (index !== -1) {
        state.listOfOrdersSell[index] = action.payload;
      } else {
        state.listOfOrdersSell.push(action.payload);
      }
    },
  },
});

export const {
  orderRequest,
  orderSuccess,
  orderFailure,
  listOrderRequest,
  listOrderSuccess,
  listOrderFailure,
  updateListOfOrders,
} = orderSellSlice.actions;

export const createSellerOrder =
  (
    relocate: () => void,
    inventoryId: number,
    quantity: number,
    unitPrice: number,
    accountId: number | null,
    brokerAccountId: number | null,
    token: {
      accessToken: string | null;
      tokenExp: string | null;
      refreshToken: string | null;
    },
    key: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(orderRequest());
      await dispatch(
        apiCreateSellerOrder(
          inventoryId,
          quantity,
          unitPrice,
          accountId,
          brokerAccountId,
          token
        )
      );
      dispatch(orderSuccess(null));
      relocate();
      dispatch(
        addNotification({
          key,
          message: "Buy Order Created",
          options: { variant: "success" },
        })
      );
    } catch (error) {
      dispatch(
        orderFailure(
          error?.response ? error?.response?.data?.error : error?.message
        )
      );
      dispatch(
        addNotification({
          key,
          message: error?.response
            ? error?.response?.data?.error
            : error?.message,
          options: { variant: "error" },
        })
      );
    }
  };

export const createBuyerOrder =
  (
    relocate: () => void,
    typeInventory: string,
    quantity: number,
    // quality: string,
    unitPrice: number,
    accountId: number | null,
    brokerAccountId: number | null,
    token: {
      accessToken: string | null;
      tokenExp: string | null;
      refreshToken: string | null;
    },
    key: number,
    fieldsInventory?: InventoryItemTypeField[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(orderRequest());
      await dispatch(
        apiCreateBuyerOrder(
          typeInventory,
          quantity,
          unitPrice,
          accountId,
          brokerAccountId,
          token,
          fieldsInventory
        )
      );
      dispatch(orderSuccess(null));
      relocate();
      dispatch(
        addNotification({
          key,
          message: "Success create buy order",
          options: { variant: "success" },
        })
      );
    } catch (error) {
      dispatch(
        orderFailure(
          error?.response ? error?.response?.data?.error : error?.message
        )
      );
      dispatch(
        addNotification({
          key,
          message: error?.response
            ? error?.response?.data?.error
            : error?.message,
          options: { variant: "error" },
        })
      );
    }
  };

export const updateOrder =
  (
    relocate: () => void,
    orderId: number,
    quantity: number,
    unitPrice: number,
    typeInventory: string,
    accountId: number | null,
    brokerAccountId: number | null,
    token: {
      accessToken: string | null;
      tokenExp: string | null;
      refreshToken: string | null;
    },
    key: number,
    fieldsInventory?: InventoryItemTypeField[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      await dispatch(
        apiUpdateOrder(
          orderId,
          quantity,
          unitPrice,
          typeInventory,
          accountId,
          brokerAccountId,
          token,
          fieldsInventory
        )
      );
      relocate();
      dispatch(
        addNotification({
          key,
          message: "Success update order",
          options: { variant: "success" },
        })
      );
    } catch (error) {
      dispatch(
        orderFailure(
          error?.response ? error?.response?.data?.error : error?.message
        )
      );
      dispatch(
        addNotification({
          key,
          message: error?.response
            ? error?.response?.data?.error
            : error?.message,
          options: { variant: "error" },
        })
      );
    }
  };

export const getAllSellOrders =
  (
    isAdmin: boolean,
    token: {
      accessToken: string | null;
      tokenExp: string | null;
      refreshToken: string | null;
    },
    key: number,
    inventoryTypeId: number,
    sort?: string | undefined,
    order?: string | undefined,
    limit?: number | undefined,
    offset?: number | undefined,
    type?: string | undefined,
    filterState?: string | undefined
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(listOrderRequest());
      const OrderInfo = await dispatch(
        apiGetAllOrders(
          isAdmin,
          token,
          inventoryTypeId,
          sort,
          order,
          limit,
          offset,
          type,
          filterState
        )
      );
      dispatch(listOrderSuccess(OrderInfo));
    } catch (error) {
      dispatch(
        listOrderFailure(
          error?.response ? error?.response?.data?.error : error?.message
        )
      );
      dispatch(
        addNotification({
          key,
          message: error?.response
            ? error?.response?.data?.error
            : error?.message,
          options: { variant: "error" },
        })
      );
    }
  };

export const getAllOrdersByAccountId =
  (
    accountId: number | null,
    token: {
      accessToken: string | null;
      tokenExp: string | null;
      refreshToken: string | null;
    },
    key: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(listOrderRequest());
      const OrderInfo = await dispatch(
        apiGetAllOrdersByAccountId(accountId, token)
      );
      dispatch(listOrderSuccess(OrderInfo));
    } catch (error) {
      dispatch(
        listOrderFailure(
          error?.response ? error?.response?.data?.error : error?.message
        )
      );
      dispatch(
        addNotification({
          key,
          message: error?.response
            ? error?.response?.data?.error
            : error?.message,
          options: { variant: "error" },
        })
      );
    }
  };

export const getOrderById =
  (
    idOrder: number,
    token: {
      accessToken: string | null;
      tokenExp: string | null;
      refreshToken: string | null;
    },
    key: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(orderRequest());
      const orderInfo = await dispatch(apiGetOrderById(idOrder, token));
      dispatch(orderSuccess(orderInfo));
    } catch (error) {
      dispatch(
        orderFailure(
          error?.response ? error?.response?.data?.error : error?.message
        )
      );
      dispatch(
        addNotification({
          key,
          message: error?.response
            ? error?.response?.data?.error
            : error?.message,
          options: { variant: "error" },
        })
      );
    }
  };

export const deleteOrder =
  (
    idOrder: number,
    token: {
      accessToken: string | null;
      tokenExp: string | null;
      refreshToken: string | null;
    },
    key: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      await dispatch(apiDeleteOrder(idOrder, token));
    } catch (error) {
      dispatch(
        addNotification({
          key,
          message: error?.response
            ? error?.response?.data?.error
            : error?.message,
          options: { variant: "error" },
        })
      );
    }
  };

export default orderSellSlice.reducer;
