import React, {
  FC,
  useEffect,
} from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ProfilePage from '../../components/ProfilePage/ProfilePage';
import ListOfRequests from '../../tables/Request/ListOfRequests';
import MarketPlacePage from '../../components/MarketPlace/MarketPlacePage';
import ListInventoryType from '../../tables/InventoryType/InventoryType';
// import QualityType from '../../tables/QualityType/QualityType';
import ListOfRequestsByAdminId from '../../tables/Request/ListOfRequestsByAdminId';
import {
  getAllRequests, getAllRequestsByAdminId, requestSuccess, setReloadRequest,
} from '../../store/slices/adminRequest.slice';
import CreateNegotiationPoint from '../../components/NegotiationPoints/CreateNegotiationPoint';
import AdminRoleAssignment from '../../tables/AdminRoleAssignment/AdminRoleAssignment';
import ListOfNegotiationPoints from '../../tables/NegotiationPoints/ListOfNegotiationPoints';
import getLocalStorageData from '../../api/localStorage';
import ListOfContracts from '../../tables/Contract/ListOfContracts';
import ListOfDeals from '../../tables/DealRoom/ListOfDeals';
import Contract from '../../components/Contract/Contract';
import DealRoom from '../../components/DealRoom/DealRoom';
import ListOfPostTradeProcessingTemplates from '../../tables/PostTradeProcessingTemplates/ListOfPostTradeProcessingTemplates';
import { usersSuccess } from '../../store/slices/adminRoleAssignment.slice';
import NotFoundPage from '../NotFound/NotFoundPage';
import { updateListOfOrders } from '../../store/slices/order.slice';
import ListOfSettlements from '../../tables/DealRoom/ListOfSettlements';
import { listOfSettlementsSuccess, listOfDealsSuccess } from '../../store/slices/deals.slice';
import { RootState } from '../../store/slices';
import { updateListOfContracts } from '../../store/slices/contracts.slice';
import CreateInventoryType from '../../components/Inventory/CreateInventoryType';
import ListOrdersForAdmin from '../../tables/Order/ListOrdersForAdmin';
import { updateListAlertsAndMessages } from '../../store/slices/alertsAndMessages.slice';
// import ListOfMessagesAndAlerts from '../../tables/MessagesAndAlerts/ListMessagesAndAlerts';
import ListInventoryForAdmin from '../../tables/Inventory/ListInventoryForAdmin';
import { addNotification } from '../../store/slices/snackbar.slice';
import { updateListInventorySuccess } from '../../store/slices/inventory.slice';
import CreateInventory from '../../components/Inventory/CreateInventory';
import EditPTS from '../../components/PostTradeSteps/EditPTS';
import { Socket } from '../MainContainer/MainContainer';
import NotificationPage from '../../components/NotificationPage/NotificationPage';

// const socket = io(`${constants.BASE_URL}`);
const AdminPage: FC = () => {
  const dispatch = useDispatch();
  const {
    deal,
  } = useSelector(
    (state: RootState) => state.deals,
  );
  const {
    openRequest,
  } = useSelector(
    (state: RootState) => state.requests,
  );

  useEffect(() => {
    const { userId, token } = getLocalStorageData();
    dispatch(getAllRequests(userId, token));
    dispatch(getAllRequestsByAdminId(userId, token));
  }, [dispatch]);

  useEffect(() => {
    Socket.on('createRequest', (data: any) => {
      dispatch(requestSuccess(data));
    });

    Socket.on('connectToPersonalRoom', (data: any) => {
      if (data.message === 'userList update') {
        dispatch(usersSuccess(data.userToSend));
      }
      if (data.message === 'orders updated') {
        dispatch(updateListOfOrders(data.orderToSend));
      }
      if (data.message === 'order questions updated') {
        dispatch(updateListAlertsAndMessages(data.foundMessageWithAnswers));
      }
      if (data.message === 'new deal') {
        dispatch(listOfDealsSuccess(data.foundDealWithNPInfo));
      }
      if (data.message === 'deal updated') {
        dispatch(listOfDealsSuccess(data.foundDealWithNPInfo));
        if (data.foundDealWithNPInfo.status === 'DECLINED' && deal?.id === data.foundDealWithNPInfo.id) {
          dispatch(addNotification({
            key: new Date().getTime() + Math.random(),
            message: `Deal was declined by the administrator. Reason: ${data.foundDealWithNPInfo.request.reasonForDecline}`,
            options: { variant: 'error' },
          }));
        }
      }
      if (data.message === 'settlement updated') {
        dispatch(listOfSettlementsSuccess(data.foundDealWithPTSInfo));
      }
      if (data.message === 'contract updated') {
        dispatch(updateListOfContracts(data.foundContract));
      }
      if (data.message === 'inventory updated by user') {
        if (openRequest?.id === data.inventoryItemId) {
          dispatch(setReloadRequest(true));
        }
      }
      if (data.message === 'role request updated by user') {
        if (openRequest?.id === data.userAccountId) {
          dispatch(setReloadRequest(true));
        }
      }
      if (data.message === 'inventory created') dispatch(updateListInventorySuccess(data.foundInventoryItem));
      if (data.message === 'inventory updated') dispatch(updateListInventorySuccess(data.foundInventoryItem));
    });

    return () => {
      Socket.off('connectToPersonalRoom');
      Socket.off('createRequest');
    };
  }, [dispatch, deal, openRequest]);

  return (
    <Switch>
      <Route exact path="/" component={MarketPlacePage} />
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/requests" component={ListOfRequests} />
      <Route exact path="/myRequests" component={ListOfRequestsByAdminId} />
      {/* <Route exact path="/inventoryQualities" component={QualityType} /> */}
      <Route exact path="/inventoryTypes" component={ListInventoryType} />
      <Route exact path="/createInventoryType" component={CreateInventoryType} />
      <Route exact path="/editInventoryType" component={CreateInventoryType} />
      <Route exact path="/negotiationPoints" component={ListOfNegotiationPoints} />
      <Route exact path="/createNegotiationPoint" component={CreateNegotiationPoint} />
      <Route exact path="/postTradeSteps" component={ListOfPostTradeProcessingTemplates} />
      <Route exact path="/userList" component={AdminRoleAssignment} />
      <Route exact path="/inventory" component={ListInventoryForAdmin} />
      <Route exact path="/itemOfInventory" component={CreateInventory} />
      <Route exact path="/orders" component={ListOrdersForAdmin} />
      <Route exact path="/deals" component={ListOfDeals} />
      <Route exact path="/deal-room/:id" component={DealRoom} />
      <Route exact path="/contracts" component={ListOfContracts} />
      <Route exact path="/contract/:contractId?" component={Contract} />
      <Route exact path="/settlements" component={ListOfSettlements} />
      {/* <Route exact path="/messagesAndAlerts" component={ListOfMessagesAndAlerts} /> */}
      <Route exact path="/notfound" component={NotFoundPage} />
      <Route exact path="/editPostTradeSteps" component={EditPTS} />
      <Route exact path="/editNegotiationPoints" component={CreateNegotiationPoint} />
      <Route exact path="/notification" component={NotificationPage} />
      <Redirect to="/notfound" />
    </Switch>
  );
};

export default AdminPage;
