import React, {
  FC,
  useCallback,
  memo,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RoleRequestDetails from './RoleRequestDetails';
import useStyles from './Request.style';

interface Props {
  onClose: () => void;
  open: false | number;
}
const UserProfileDetails: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const handleClose = useCallback(
    () => {
      onClose();
    },
    [onClose],
  );

  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Profile Details
        <IconButton aria-label="close" onClick={handleClose} className={classes.closeModalIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {open && <RoleRequestDetails userId={open} />}
      </DialogContent>
    </Dialog>
  );
};

export default memo(UserProfileDetails);
