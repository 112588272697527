import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import {
  apiGetAllContracts,
  apiGetContractById,
  apiSignContract,
} from '../../api/contracts';
import { ContractState, Contract } from '../../interfaces/contract.interface';
import { addNotification } from './snackbar.slice';
import history from '../../core/history';
import translationEN from '../../locales/en/translation.json';

const initialState: ContractState = {
  listOfContracts: [],
  contract: {} as Contract,
  error: '',
  isLoading: false,
};

const inventorySlice = createSlice({
  initialState,
  name: 'contract',
  reducers: {
    contractRequest(state) {
      state.contract = {} as Contract;
      state.error = '';
      state.isLoading = true;
    },
    contractSuccess(state, action) {
      state.contract = action.payload.foundContract ?
        action.payload.foundContract : action.payload;
      state.error = '';
      state.isLoading = false;
    },
    contractFailure(state, action) {
      state.contract = {} as Contract;
      state.error = action.payload;
      state.isLoading = false;
    },
    listContractsRequest(state) {
      state.listOfContracts = [];
      state.error = '';
      state.isLoading = true;
    },
    listContractsSuccess(state, action) {
      state.listOfContracts = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    listContractsFailure(state, action) {
      state.listOfContracts = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    updateListOfContracts(state, action) {
      const index = state.listOfContracts
        .findIndex((obj: Contract) => obj.id === action.payload.id);
      if (index !== -1) {
        state.listOfContracts[index] = action.payload;
        if (state.contract.id === action.payload.id) {
          state.contract = action.payload;
        }
      } else {
        state.listOfContracts.unshift(action.payload);
      }
    },
  },
});

export const {
  contractRequest,
  contractSuccess,
  contractFailure,
  listContractsRequest,
  listContractsSuccess,
  listContractsFailure,
  updateListOfContracts,
} = inventorySlice.actions;

export const signContract = (
  idContract: number,
  signName: string,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiSignContract(idContract, signName, accountId, token));
    // dispatch(contractSuccess(contractInfo));
    dispatch(addNotification({
      key,
      message: translationEN.ContractSignedSuccessfully,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getAllContracts = (
  accountId: number | null,
  brokerAccountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    const contractsInfo = await dispatch(apiGetAllContracts(accountId, brokerAccountId, token));
    dispatch(listContractsSuccess(contractsInfo));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getContractById = (
  idContract: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(contractRequest());
    const contractInfo = await dispatch(apiGetContractById(idContract, token));
    dispatch(contractSuccess(contractInfo));
  } catch (error) {
    history.push('./../notfound');
    dispatch(contractFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default inventorySlice.reducer;
