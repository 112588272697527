import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Hidden,
  Button,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import useStyles from './DealRoom.style';
import { NegotiationPointForDeal } from '../../interfaces/deals.interface';
import getLocalStorageData from '../../api/localStorage';
import { addNotification } from '../../store/slices/snackbar.slice';
import { getDate, getDateUTC12H } from '../../utils/getDate';
import ButtonSC from '../ButtonSC/ButtonSC';
import { NumberFormatCustom } from '../NumberFormat/NumberFormat';

interface Props {
  negotiationPoints: NegotiationPointForDeal[];
  sellerId: number;
  buyerId: number;
  saveValue: (
    newValue: { data: string | boolean | number; description: string | undefined },
    negotiationPointId: number
  ) => void;
  setAgree: (
    agreedValue: { data: string | boolean | number; description: string | undefined },
    negotiationPointId: number
  ) => void;
  status: string;
  agree: boolean;
  expanded: string | false;
  setExpanded: (value: string | false) => void;
  unitPriceIncrement: number;
  tradeSizeIncrement: number;
  minTradeSizeIncrement: number;
  proceedError: boolean;
  userRole: 'Buyer' | 'Seller' | 'Unconfirmed' | 'Admin' | 'Broker' | 'Seller & Buyer' | 'Guest';
  nextStep: false | { type: string; id: number; agreed: boolean | 'byOne' };
}

const NegotiationPoints: FC<Props> = ({
  negotiationPoints,
  sellerId,
  buyerId,
  saveValue,
  setAgree,
  status,
  agree,
  expanded,
  setExpanded,
  unitPriceIncrement,
  tradeSizeIncrement,
  minTradeSizeIncrement,
  proceedError,
  userRole,
  nextStep,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [valueNumber, setValueNumber] = useState<number>(0);
  const [valueString, setValueString] = useState<string>('');
  const [valueDate, setValueDate] = useState<string>('');
  const [valueBoolean, setValueBoolean] = useState<boolean>(false);
  const [valueChoice, setValueChoice] = useState<string>('');

  const [description, setDescription] = useState<string>('');
  const [currentChoiceList, serCurrentChoiceList] = useState<{ id: number; value: string }[]>([]);
  const [seeMore, setSeeMore] = useState<{ name: string; open: boolean }>({ name: '', open: false });

  useEffect(() => {
    setExpanded(false);
  }, [setExpanded, status, agree]);

  const handleGetAccountId = () => {
    const { clientAccountId, accountId } = getLocalStorageData();
    return clientAccountId || accountId || 0;
  };

  useEffect(() => {
    if (expanded) {
      const item = negotiationPoints.find((np) => np.title === expanded);
      if (item) {
        if (item.valueType === 'NUMBER' && item.title === 'Quantity') setValueNumber(sellerId !== handleGetAccountId() ? +item.valueBuyerNumber : +item.valueSellerNumber);
        else if (item.valueType === 'NUMBER' && item.title !== 'Quantity') setValueNumber(sellerId !== handleGetAccountId() ? +(+item.valueBuyerNumber).toFixed(2) : +(+item.valueSellerNumber).toFixed(2));
        else if (item.valueType === 'STRING') setValueString(sellerId !== handleGetAccountId() ? item.valueBuyerString : item.valueSellerString);
        else if (item.valueType === 'DATE') setValueDate(sellerId !== handleGetAccountId() ? item.valueBuyerDate : item.valueSellerDate);
        else if (item.valueType === 'CHOICE') setValueChoice(sellerId !== handleGetAccountId() ? item.valueBuyerString : item.valueSellerString);
        else if (item.valueType === 'BOOLEAN') setValueBoolean(sellerId !== handleGetAccountId() ? item.valueBuyerBoolean : item.valueSellerBoolean);
        setDescription(
          (sellerId === handleGetAccountId() ?
            item.descriptionOfNPMadeByUserInDealValueSeller :
            item.descriptionOfNPMadeByUserInDealValueBuyer) as string,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expanded,
    setValueNumber,
    setValueString,
    setValueDate,
    setValueChoice,
    setValueBoolean,
    sellerId,
  ]);

  const getValue = useCallback(
    (item: NegotiationPointForDeal) => {
      if (item.valueType === 'NUMBER' && item.title === 'Quantity') return sellerId === handleGetAccountId() ? +item.valueBuyerNumber : +item.valueSellerNumber;
      else if (item.valueType === 'NUMBER' && item.title !== 'Quantity') return sellerId === handleGetAccountId() ? +(+item.valueBuyerNumber).toFixed(2) : +(+item.valueSellerNumber).toFixed(2);
      else if (item.valueType === 'STRING') return sellerId === handleGetAccountId() ? item.valueBuyerString : item.valueSellerString;
      else if (item.valueType === 'DATE') return sellerId === handleGetAccountId() ? item.valueBuyerDate : item.valueSellerDate;
      else if (item.valueType === 'CHOICE') return sellerId === handleGetAccountId() ? item.valueBuyerString : item.valueSellerString;
      else {
        return sellerId === handleGetAccountId() ? item.valueBuyerBoolean : item.valueSellerBoolean;
      }
    }, [sellerId],
  );

  const handleSetAgree = useCallback(
    async (item: NegotiationPointForDeal) => {
      if ((item.valueType === 'STRING' || item.valueType === 'DATE' || item.valueType === 'CHOICE') && !!getValue(item) === false) {
        return dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: 'Input Required',
          options: { variant: 'error' },
        }));
      }
      await setAgree(
        {
          data: getValue(item),
          description: sellerId === handleGetAccountId() ?
            item.descriptionOfNPMadeByUserInDealValueBuyer :
            item.descriptionOfNPMadeByUserInDealValueSeller as string,
        },
        item.id,
      );
      if (item.valueType === 'NUMBER' && item.title === 'Quantity') setValueNumber(sellerId === handleGetAccountId() ? +item.valueBuyerNumber : +item.valueSellerNumber);
      else if (item.valueType === 'NUMBER' && item.title !== 'Quantity') setValueNumber(sellerId === handleGetAccountId() ? +(+item.valueBuyerNumber).toFixed(2) : +(+item.valueSellerNumber).toFixed(2));
      else if (item.valueType === 'STRING') setValueString(sellerId === handleGetAccountId() ? item.valueBuyerString : item.valueSellerString);
      else if (item.valueType === 'DATE') setValueDate(sellerId === handleGetAccountId() ? item.valueBuyerDate : item.valueSellerDate);
      else if (item.valueType === 'CHOICE') setValueChoice(sellerId === handleGetAccountId() ? item.valueBuyerString : item.valueSellerString);
      else if (item.valueType === 'BOOLEAN') setValueBoolean(sellerId === handleGetAccountId() ? item.valueBuyerBoolean : item.valueSellerBoolean);
      setDescription(
        (sellerId === handleGetAccountId() ?
          item.descriptionOfNPMadeByUserInDealValueBuyer :
          item.descriptionOfNPMadeByUserInDealValueSeller) as string,
      );
      return setExpanded(false);
    },
    [
      dispatch,
      setAgree,
      sellerId,
      setValueNumber,
      setValueString,
      setValueDate,
      setValueChoice,
      setValueBoolean,
      setDescription,
      getValue,
      setExpanded,
    ],
  );

  const handleSetValue = useCallback(
    (item: NegotiationPointForDeal, data: string | boolean | number | null | Date) => {
      if (item.valueType === 'NUMBER') setValueNumber(data as number);
      else if (item.valueType === 'STRING') setValueString(data as string);
      else if (item.valueType === 'DATE') setValueDate(data as string);
      else if (item.valueType === 'CHOICE') setValueChoice(data as string);
      else if (item.valueType === 'BOOLEAN') setValueBoolean(data as boolean);
    },
    [
      setValueNumber,
      setValueString,
      setValueDate,
      setValueChoice,
      setValueBoolean,
    ],
  );

  const handleSetDescription = useCallback(
    (data: string) => {
      setDescription(data);
    },
    [setDescription],
  );

  const handleSaveValue = useCallback(
    (item: NegotiationPointForDeal) => {
      let valueToSave;
      if ((item.title === 'Quantity' || item.title === 'Unit price') && valueNumber <= 0) {
        return dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: 'Value must be greater than 0',
          options: { variant: 'error' },
        }));
      }
      if (item.valueType === 'NUMBER' && valueNumber < 0) {
        return dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: 'Value must be positive',
          options: { variant: 'error' },
        }));
      }
      if (item.valueType === 'NUMBER' && Number.isNaN(valueNumber)) {
        return dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: 'Enter Numbers Only',
          options: { variant: 'error' },
        }));
      }
      if ((item.title === 'Quantity') && (
        !Number.isInteger(+((valueNumber - minTradeSizeIncrement) * 100).toFixed(2) /
          (tradeSizeIncrement * 100))
        || valueNumber < minTradeSizeIncrement)) {
        return dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: 'Positive Value Only',
          options: { variant: 'error' },
        }));
      }
      if (item.title === 'Unit price' && !Number.isInteger(+(valueNumber * 100).toFixed() / +(unitPriceIncrement * 100).toFixed())) {
        return dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: `Price must be a multiple of ${unitPriceIncrement}`,
          options: { variant: 'error' },
        }));
      }

      if (item.valueType === 'NUMBER' && item.title === 'Quantity') valueToSave = valueNumber;
      else if (item.valueType === 'NUMBER' && item.title !== 'Quantity') valueToSave = (+valueNumber).toFixed(2);
      else if (item.valueType === 'STRING') valueToSave = valueString;
      else if (item.valueType === 'DATE') valueToSave = valueDate;
      else if (item.valueType === 'CHOICE') valueToSave = valueChoice;
      else valueToSave = valueBoolean;
      return saveValue({ data: valueToSave as string | number | boolean, description }, item.id);
    },
    [dispatch,
      saveValue,
      valueNumber,
      valueString,
      valueDate,
      valueChoice,
      valueBoolean,
      description,
      unitPriceIncrement,
      tradeSizeIncrement,
      minTradeSizeIncrement],
  );

  const getValueByRole = useCallback(
    (item: NegotiationPointForDeal, role: string) => {
      if (item.valueType === 'NUMBER' && item.title === 'Quantity') return role === 'valueBuyer' ? item.valueBuyerNumber : item.valueSellerNumber;
      else if (item.valueType === 'NUMBER' && item.title !== 'Quantity') return role === 'valueBuyer' ? (+item.valueBuyerNumber).toFixed(2) : (+item.valueSellerNumber).toFixed(2);
      else if (item.valueType === 'STRING') return role === 'valueBuyer' ? item.valueBuyerString : item.valueSellerString;
      else if (item.valueType === 'DATE') return role === 'valueBuyer' ? item.valueBuyerDate : item.valueSellerDate;
      else if (item.valueType === 'CHOICE') return role === 'valueBuyer' ? item.valueBuyerString || '' : item.valueSellerString || '';
      else return ((role === 'valueBuyer' && (item.valueBuyerBoolean === false ? 'No' : 'Yes')) || (item.valueSellerBoolean === false ? 'No' : 'Yes'));
    }, [],
  );

  const checkChanges = useCallback(
    (item: NegotiationPointForDeal, role: string) => {
      if (item.valueType === 'NUMBER' && item.title === 'Misc. fees') {
        return role === 'valueBuyer' ?
          item.descriptionOfNPMadeByUserInDealValueBuyer === description &&
          +item.valueBuyerNumber === +((+valueNumber).toFixed(2)) :
          item.descriptionOfNPMadeByUserInDealValueSeller === description &&
          +item.valueSellerNumber === +((+valueNumber).toFixed(2));
      } else if (item.valueType === 'NUMBER' && !valueNumber && valueNumber !== 0) return true;
      else if (item.valueType === 'NUMBER') return role === 'valueBuyer' ? +item.valueBuyerNumber === +((+valueNumber).toFixed(2)) : +item.valueSellerNumber === +((+valueNumber).toFixed(2));
      else if (item.valueType === 'STRING') return role === 'valueBuyer' ? item.valueBuyerString === valueString : item.valueSellerString === valueString;
      else if (item.valueType === 'DATE') return role === 'valueBuyer' ? item.valueBuyerDate === valueDate : item.valueSellerDate === valueDate;
      else if (item.valueType === 'CHOICE') return role === 'valueBuyer' ? item.valueBuyerString === valueChoice : item.valueSellerString === valueChoice;
      return role === 'valueBuyer' ? item.valueBuyerBoolean === valueBoolean : item.valueSellerBoolean === valueBoolean;
    },
    [valueNumber, valueString, valueDate, valueChoice, valueBoolean, description],
  );

  const renderNPFields = (currentRole: string, item: NegotiationPointForDeal) => (
    (currentRole === 'valueBuyer' ? buyerId === handleGetAccountId() : sellerId === handleGetAccountId()) ? (
      <>
        <Grid item xs={8} lg={6}>
          {item.valueType === 'NUMBER' && (
            <>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  label={`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry`}
                  value={valueNumber || ''}
                  onChange={({ target }) => handleSetValue(item, target.value)}
                  disabled={currentRole === 'valueBuyer' ? buyerId !== handleGetAccountId() : sellerId !== handleGetAccountId()}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
              </Grid>
              {item.title === 'Misc. fees' && (
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    label="Description"
                    value={description}
                    onChange={({ target }) => handleSetDescription(target.value)}
                    disabled={currentRole === 'valueBuyer' ? buyerId !== handleGetAccountId() : sellerId !== handleGetAccountId()}
                    fullWidth
                    multiline
                  />
                </Grid>
              )}
            </>
          )}
          {item.valueType === 'STRING' && (
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label={`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry`}
                value={valueString}
                onChange={({ target }) => handleSetValue(item, target.value)}
                disabled={currentRole === 'valueBuyer' ? buyerId !== handleGetAccountId() : sellerId !== handleGetAccountId()}
                fullWidth
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}
          {item.valueType === 'BOOLEAN' && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      checked={valueBoolean as boolean}
                      onChange={({ target }) => handleSetValue(item, target.checked)}
                      disabled={currentRole === 'valueBuyer' ? buyerId !== handleGetAccountId() : sellerId !== handleGetAccountId()}
                      name="checkedB"
                      color="primary"
                      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                    />
                  )
                }
                label={`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry`}
              />
            </Grid>
          )}
          {item.valueType === 'DATE' && (
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  format="MM/dd/yyyy"
                  label={`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry`}
                  emptyLabel=""
                  value={valueDate}
                  onChange={(date) => handleSetValue(item, getDateUTC12H(date))}
                  autoOk
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
          {item.valueType === 'CHOICE' && currentChoiceList && currentChoiceList.length > 0 &&
            (
              <Grid item xs={12}>
                <InputLabel id="inventoryType-label">{`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry`}</InputLabel>
                <Select
                  label={`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry`}
                  value={valueChoice === null ? '' : valueChoice}
                  onChange={({ target }) => setValueChoice(target.value as string)}
                  fullWidth
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {currentChoiceList.map((itemChoice) => (
                    <MenuItem
                      key={itemChoice.value}
                      value={itemChoice.value}
                    >
                      {itemChoice.value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
        </Grid>
        <Grid item xs={8} lg={6} className={classes.npButtonContainer}>
          <ButtonSC
            variant="fill"
            size="large"
            text={checkChanges(item, currentRole) ? 'Saved' : 'Save'}
            handleClick={() => handleSaveValue(item)}
            color="green"
            disabled={checkChanges(item, currentRole)}
            styleClass={classes.npButton}
          />
        </Grid>
      </>
    ) :
      (
        <>
          <Grid item xs={8} lg={6} className={classes.alignSelfCenter}>
            <Typography className={classes.heading}>
              {`${currentRole === 'valueBuyer' ? 'Buyer' : 'Seller'} Entry:`}
            </Typography>
            <Typography className={!(seeMore.name === item.title && seeMore.open) ? classes.longDescriptionHide : classes.secondaryHeading}>
              {item.valueType === 'DATE' ?
                `${getValueByRole(item, currentRole) ? getDate(getValueByRole(item, currentRole) as string) : '-'}` :
                `${getValueByRole(item, currentRole)}`}
            </Typography>
            {item.valueType === 'STRING' && (getValueByRole(item, currentRole) as string).length > 30 && (
              !(seeMore.name === item.title && seeMore.open) ?
                (
                  <Button
                    className={classes.longDescriptionBtn}
                    onClick={() => setSeeMore({ name: item.title, open: true })}
                  >
                    See more
                  </Button>
                ) :
                (
                  <Button
                    className={classes.longDescriptionBtn}
                    onClick={() => setSeeMore({ name: item.title, open: false })}
                  >
                    See less
                  </Button>
                )
            )}
          </Grid>
          <Grid item xs={8} lg={6} className={classes.npButtonContainer}>
            {!item.agreed && (
              <>
                <Hidden mdDown>
                  <Tooltip title={`Accept ${sellerId !== handleGetAccountId() ? 'Seller' : 'Buyer'} Offer`}>
                    <span>
                      <ButtonSC
                        variant="outline"
                        size="large"
                        text="Accept"
                        handleClick={() => handleSetAgree(item)}
                        color="green"
                        styleClass={classes.npButton}
                      />
                    </span>
                  </Tooltip>
                </Hidden>
                <Hidden lgUp>
                  <ButtonSC
                    variant="outline"
                    size="large"
                    text={`Accept ${sellerId !== handleGetAccountId() ? 'Seller' : 'Buyer'} Offer`}
                    handleClick={() => handleSetAgree(item)}
                    color="green"
                    styleClass={classes.npButton}
                  />
                </Hidden>
              </>
            )}
          </Grid>
          {item.title === 'Misc. fees' && (
            <Grid item xs={8} sm={10} style={{ padding: '16px 0' }}>
              <span className={classes.heading}>
                Description:
              </span>
              <p className={cx(classes.textHide, classes.secondaryHeading)}>
                {`${currentRole === 'valueBuyer' ? item.descriptionOfNPMadeByUserInDealValueBuyer : item.descriptionOfNPMadeByUserInDealValueSeller}`}
              </p>
            </Grid>
          )}
        </>
      )
  );

  const handleGetAccordionSummaryStyle = useCallback(
    (item) => {
      if (item.agreed) return classes.AccordionSummaryAgreed;
      if (nextStep && nextStep.type === 'NP' && nextStep.id === item.id) return classes.AccordionSummaryNextStep;
      if (proceedError && !(item.agreedBySeller || item.agreedByBuyer)) return classes.AccordionSummaryError;
      if ((item.agreedBySeller && userRole === 'Seller' && !item.agreedByBuyer) || (item.agreedByBuyer && userRole === 'Buyer' && !item.agreedBySeller)) return classes.AccordionSummaryAgreedByOne;
      if ((!item.agreedBySeller && userRole === 'Seller' && item.agreedByBuyer) || (!item.agreedByBuyer && userRole === 'Buyer' && item.agreedBySeller)) return classes.AccordionSummaryWarning;
      return '';
    },
    [classes, proceedError, userRole, nextStep],
  );

  return (
    <div className={classes.root}>
      {negotiationPoints.map((item) => (
        <Accordion
          key={item.title}
          expanded={expanded === item.title}
          onChange={
            (event: ChangeEvent<{}>, newExpanded: boolean) => {
              setValueChoice('');
              if (item.valueType === 'CHOICE') serCurrentChoiceList(item.negotiationPointsDealsChoices);
              setExpanded(newExpanded ? item.title : false);
            }
          }
          disabled={status !== 'IN PROGRESS' || agree}
          className={classes.Accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            className={cx(
              classes.AccordionSummary,
              handleGetAccordionSummaryStyle(item),
            )}
          >
            <>
              <Grid item xs={5} lg={4}>
                <Typography
                  className={item.agreed ?
                    classes.AccordionSummaryTitleAgreed :
                    classes.AccordionSummaryTitle}
                >
                  {item.title}
                </Typography>
              </Grid>
              <Grid item xs={5} lg={4} style={{ marginLeft: 12 }}>
                <Typography className={cx(classes.AccordionSummaryTitleAgreed, classes.longDescriptionHide)}>
                  {item.valueType === 'DATE' ?
                    `${getValueByRole(item, 'valueBuyer') ? getDate(getValueByRole(item, 'valueBuyer') as string) : '-'} /
                ${getValueByRole(item, 'valueSeller') ? getDate(getValueByRole(item, 'valueSeller') as string) : '-'}` :
                    `${getValueByRole(item, 'valueBuyer')} / ${getValueByRole(item, 'valueSeller')}`}
                </Typography>
              </Grid>
            </>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            <Grid container justifyContent="space-between">
              {item.description && (
                <Grid item xs={12}>
                  <Typography className={classes.marginBottom16}>
                    {`Description: ${item.description}`}
                  </Typography>
                </Grid>
              )}
              {/* Buyer */}
              <Grid container item xs={12} sm={6} lg={4} alignContent="space-between">
                {renderNPFields('valueBuyer', item)}
              </Grid>
              {/* Seller */}
              <Grid container item xs={12} sm={6} lg={4} alignContent="space-between">
                {renderNPFields('valueSeller', item)}
              </Grid>
              {/* Seller */}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default NegotiationPoints;
