import React, {
  FC, useEffect, useState, useCallback,
} from 'react';
import cx from 'classnames';
import {
  Typography,
  Grid,
  Divider,
  Box,
  TextField,
  Link,
  Button,
} from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { RootState } from '../../store/slices';
import { getDate } from '../../utils/getDate';
import { getContractById, signContract } from '../../store/slices/contracts.slice';
import getLocalStorageData from '../../api/localStorage';
import { getRole, getBrokerRole } from '../../utils/getRole';
import LoadingPage from '../LoadingPage/LoadingPage';
import { NegotiationPointForDeal, ItemOfDealList } from '../../interfaces/deals.interface';
import PaperContainer from '../PaperContainer/PaperContainer';
import useStyles from './style';
import ButtonSC from '../ButtonSC/ButtonSC';
import { InventoryItemTypeField } from '../../interfaces/inventoryTypes.interface';
import constants from '../../core/constants';
import ContractViewer from '../ContractViewer/ContractViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Contract: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const { contractId } = useParams<{ contractId: string }>();
  // @ts-ignore
  const currentContractId = contractId || location?.state?.contractId;
  const [sign, setSignName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [seeMore, setSeeMore] = useState<{ name: string; open: boolean }>({ name: '', open: false });

  useEffect(() => {
    const { token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    if (currentContractId) {
      dispatch(getContractById(+currentContractId, token, key));
    }
  }, [dispatch, currentContractId]);

  const {
    contract,
    isLoading,
  } = useSelector(
    (state: RootState) => state.contracts,
  );
  const {
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  const {
    deal,
    buyerSignature,
    buyerBrokerSignature,
    sellerSignature,
    sellerBrokerSignature,
    buyerBrokerCommission,
    sellerBrokerCommission,
    buyerSystemCommission,
    sellerSystemCommission,
    sellerSignedDate,
    sellerBrokerSignedDate,
    buyerSignedDate,
    buyerBrokerSignedDate,
    document,
  } = contract;

  const handleCheckSign = () => {
    const { accountId, clientAccountId } = getLocalStorageData();
    if (buyerSignature && buyerSignature.user.id === accountId) return true;
    if (
      buyerBrokerSignature && buyerBrokerSignature.user.id === accountId
    ) return true;
    if (sellerSignature && sellerSignature.user.id === accountId) return true;
    if (
      sellerBrokerSignature && sellerBrokerSignature.user.id === accountId
    ) return true;

    if (
      getRole(userRole) === 'Broker' &&
      getBrokerRole(userRole, clientAccountId) === 'Seller' &&
      deal.sellerBroker.id !== accountId
    ) return true;
    if (
      getRole(userRole) === 'Broker' &&
      getBrokerRole(userRole, clientAccountId) === 'Buyer' &&
      !deal.buyerBroker
    ) return true;
    if (
      getRole(userRole) === 'Broker' &&
      getBrokerRole(userRole, clientAccountId) === 'Buyer' &&
      deal.buyerBroker.id !== accountId
    ) return true;
    return false;
  };

  const handleSignContract = useCallback(
    () => {
      const { accountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      dispatch(signContract(currentContractId, sign, accountId, token, key));
    }, [dispatch, currentContractId, sign],
  );

  const getValue = (item: NegotiationPointForDeal) => {
    if (item.valueType === 'NUMBER' && item.title === 'Quantity') {
      return (
        <>
          <Grid item xs={6}>
            <Typography className={classes.heading}>
              {`${(item.title).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.secondaryHeading}>
              {item.valueSellerNumber}
            </Typography>
          </Grid>
        </>
      );
    } else if (item.valueType === 'NUMBER' && (item.format === 'DOLLAR' || item.title === 'Unit price')) {
      return (
        <>
          <Grid item xs={6}>
            <Typography className={classes.heading}>
              {`${(item.title).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.secondaryHeading}>
              {`$ ${(+item.valueSellerNumber).toFixed(2)}`}
            </Typography>
          </Grid>
        </>
      );
    } else if (item.valueType === 'NUMBER' && item.format === 'PERCENT') {
      return (
        <>
          <Grid item xs={6}>
            <Typography className={classes.heading}>
              {`${(item.title).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.secondaryHeading}>
              {`${(+item.valueSellerNumber).toFixed(2)}%`}
            </Typography>
          </Grid>
        </>
      );
    } else if (item.valueType === 'NUMBER') {
      return (
        <>
          <Grid item xs={6}>
            <Typography className={classes.heading}>
              {`${(item.title).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.secondaryHeading}>
              {`${(+item.valueSellerNumber).toFixed(2)}`}
            </Typography>
          </Grid>
        </>
      );
    } else if (item.valueType === 'STRING') {
      return (
        <>
          <Grid item xs={6}>
            <Typography className={classes.heading}>
              {`${(item.title).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={
                !(seeMore.name === item.title && seeMore.open) ?
                  classes.longDescriptionHide : classes.secondaryHeading
              }
            >
              {item.valueSellerString}
            </Typography>
            {item.valueSellerString.length > 30 && (
              !(seeMore.name === item.title && seeMore.open) ?
                (
                  <Button
                    className={classes.longDescriptionBtn}
                    onClick={() => setSeeMore({ name: item.title, open: true })}
                  >
                    See more
                  </Button>
                ) :
                (
                  <Button
                    className={classes.longDescriptionBtn}
                    onClick={() => setSeeMore({ name: item.title, open: false })}
                  >
                    See less
                  </Button>
                )
            )}
          </Grid>
        </>
      );
    } else if (item.valueType === 'DATE') {
      return (
        <>
          <Grid item xs={6}>
            <Typography className={classes.heading}>
              {`${(item.title).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.secondaryHeading}>
              {getDate(item.valueSellerDate)}
            </Typography>
          </Grid>
        </>
      );
    } else if (item.valueType === 'CHOICE') {
      return (
        <>
          <Grid item xs={6}>
            <Typography className={classes.heading}>
              {`${(item.title).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.secondaryHeading}>
              {item.valueSellerString}
            </Typography>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={6}>
            <Typography className={classes.heading}>
              {`${(item.title).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.secondaryHeading}>
              {item.valueSellerBoolean ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        </>
      );
    }
  };

  const { accountId } = getLocalStorageData();
  const getInventoryInfo = (item: ItemOfDealList) => ({
    type: item.order.inventory.inventoryItemType.type,
    units: item.order.inventory.inventoryItemType.units,
    title: item.order.inventory.title,
    description: item.order.inventory.description,
    shortDescription: item.order.inventory.shortDescription,
    inventoryItemTypeFieldsTypes:
      item.order.inventory.inventoryItemType.inventoryItemTypeFieldsTypes,
    instantCheckout: item.order.inventory.instantCheckout,
  });

  const handleGetValueOfynamicField = (item: InventoryItemTypeField) => {
    if (item.typeOfField === 'DATE') return (item.value && getDate(item.value as string)) || '';
    if (item.typeOfField === 'NUMBER' && item.format === 'PERCENT' && item.value) return `${item.value} %`;
    if (item.typeOfField === 'NUMBER' && item.format === 'DOLLAR' && item.value) return `$ ${item.value}`;
    if (item.typeOfField === 'BOOLEAN') return item.value === 'false' ? 'No' : 'Yes';
    return item.value || '';
  };

  return (
    <>
      <PaperContainer>
        {isLoading ? (
          <LoadingPage />
        ) :
          (
            Object.keys(contract).length !== 0 && (
              <div className={classes.container}>
                <Grid container className={cx(classes.chapterContainer, classes.agreementHeight)} justifyContent="space-between" alignItems="flex-end">
                  <Grid>
                    <Typography variant="h1" gutterBottom>
                      Contract:
                      <span className={classes.contractNumber}>
                        {`${contract.uuid}        `}
                      </span>
                    </Typography>
                  </Grid>
                  {document && (
                    <Grid>
                      <Typography variant="h2" gutterBottom>
                        <a href={`${constants.AWS_URL}${document.link}`} download={document.title} target="_blank" rel="noopener noreferrer" className={classes.link}>Download contract</a>
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <ContractViewer />

                <Grid container className={classes.chapterContainer} style={{ wordWrap: 'break-word' }}>
                  <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                      {`Inventory Info ${getInventoryInfo(deal).instantCheckout ? '(Instant Checkout)' : ''}:`}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={6} lg={3}>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        Type:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        {getInventoryInfo(deal).type}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6} lg={3}>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        Unit:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        {getInventoryInfo(deal).units}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6} lg={3}>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        Title:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        {getInventoryInfo(deal).title}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6} lg={3}>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        Description:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={!(seeMore.name === 'Description' && seeMore.open) ? classes.longDescriptionHide : classes.secondaryHeading}>
                        {getInventoryInfo(deal).description}
                      </Typography>
                      {getInventoryInfo(deal).description.length > 30 && (
                        !(seeMore.name === 'Description' && seeMore.open) ?
                          (
                            <Button
                              className={classes.longDescriptionBtn}
                              onClick={() => setSeeMore({ name: 'Description', open: true })}
                            >
                              See more
                            </Button>
                          ) :
                          (
                            <Button
                              className={classes.longDescriptionBtn}
                              onClick={() => setSeeMore({ name: 'Description', open: false })}
                            >
                              See less
                            </Button>
                          )
                      )}
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6} lg={3}>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        Short Description:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        {getInventoryInfo(deal).shortDescription}
                      </Typography>
                    </Grid>
                  </Grid>

                  {getInventoryInfo(deal).inventoryItemTypeFieldsTypes.length > 0 &&
                    getInventoryInfo(deal)
                      .inventoryItemTypeFieldsTypes
                      .map((item: InventoryItemTypeField) => (
                        <Grid container item xs={12} sm={6} lg={3} key={item.id}>
                          <Grid item xs={6}>
                            <Typography className={classes.heading}>
                              {`${(item.name).replace(/\/,/g, '.')}:`}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={!(seeMore.name === `II${item.name}` && seeMore.open) ? classes.longDescriptionHide : classes.secondaryHeading}>
                              {item.typeOfField !== 'DOCUMENTS' && handleGetValueOfynamicField(item)}
                            </Typography>
                            {item.typeOfField === 'STRING' && (handleGetValueOfynamicField(item) as string).length > 30 && (
                              !(seeMore.name === `II${item.name}` && seeMore.open) ?
                                (
                                  <Button
                                    className={classes.longDescriptionBtn}
                                    onClick={() => setSeeMore({ name: `II${item.name}`, open: true })}
                                  >
                                    See more
                                  </Button>
                                ) :
                                (
                                  <Button
                                    className={classes.longDescriptionBtn}
                                    onClick={() => setSeeMore({ name: `II${item.name}`, open: false })}
                                  >
                                    See less
                                  </Button>
                                )
                            )}
                          </Grid>
                        </Grid>
                      ))}
                </Grid>
                <Divider />
                <Grid container className={classes.chapterContainer}>
                  <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                      Inventory Attributes Info:
                    </Typography>
                  </Grid>
                  {deal.negotiationPointsDealsInventoryAttribute.map((item) => (
                    <Grid container item xs={12} sm={6} lg={3} key={item.id}>
                      {getValue(item)}
                      {item.descriptionOfNPMadeByUserInDealValueSeller && (
                        <>
                          <Grid item xs={6}>
                            <Typography className={classes.heading}>
                              {`${item.title} description:`}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.secondaryHeading}>
                              {item.descriptionOfNPMadeByUserInDealValueSeller}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Divider />
                <Grid container className={classes.chapterContainer}>
                  <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                      Negotiation Points Info:
                    </Typography>
                  </Grid>
                  {deal.negotiationPointsDeals.map((item) => (
                    <Grid container item xs={12} sm={6} lg={3} key={item.id}>
                      {getValue(item)}
                    </Grid>
                  ))}
                  <Grid container item xs={12} sm={6} lg={3}>
                    <Grid item xs={6}>
                      <Typography className={classes.heading}>
                        Total price:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        {`$ ${deal.totalPrice.toFixed(2)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.chapterContainer}>
                  <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                      Post Trading:
                    </Typography>
                  </Grid>
                  {deal.postTradeProcessingDeal.postTradeProcessingStepsDeal.map((item) => (
                    <Grid container item xs={12} sm={6} lg={3} key={item.id}>
                      <Grid item xs={6}>
                        <Typography className={classes.heading}>
                          {`${item.description}:`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.secondaryHeading}>
                          {getDate(item.valueSeller)}
                        </Typography>
                      </Grid>
                      {item.responsibleValueSeller && (
                        <>
                          <Grid item xs={6}>
                            <Typography className={classes.heading}>
                              Responsible:
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.secondaryHeading}>
                              {item.responsibleValueSeller}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Divider />
                <Grid container className={classes.chapterContainer}>
                  <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                      Fees:
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={6} lg={3} className={classes.marginBottom8}>
                    {/* {!!buyerCommission && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Buyer Broker Fee:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.secondaryHeading}>
                            {buyerCommission ? `$ ${buyerCommission.toFixed(2)}` : '$ 0'}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {!!sellerCommission && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Seller Fee:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.secondaryHeading}>
                            {sellerCommission ? `$ ${sellerCommission.toFixed(2)}` : '$ 0'}
                          </Typography>
                        </Grid>
                      </>
                    )} */}
                    {!!deal.buyerBroker && deal.buyerBroker.id === accountId && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Buyer Broker Commissions:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.secondaryHeading}>
                            {buyerBrokerCommission ? `$ ${buyerBrokerCommission.toFixed(2)}` : '$ 0'}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {!!deal.sellerBroker && deal.sellerBroker.id === accountId && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Seller Broker Commissions:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.secondaryHeading}>
                            {sellerBrokerCommission ? `$ ${sellerBrokerCommission.toFixed(2)}` : '$ 0'}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {deal.buyer.id === accountId && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Buyer System Fee:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.secondaryHeading}>
                            {buyerSystemCommission ? `$ ${buyerSystemCommission.toFixed(2)}` : '$ 0'}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {deal.seller.id === accountId && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Seller System Fee:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.secondaryHeading}>
                            {sellerSystemCommission ? `$ ${sellerSystemCommission.toFixed(2)}` : '$ 0'}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                {/* <Grid container className={classes.chapterContainer}>
                  <Grid container item xs={12} sm={6} alignContent="flex-start">
                    <Grid item xs={12}>
                      <Typography variant="h2" gutterBottom>
                        Buyer:
                      </Typography>
                    </Grid>
                    {getParticipant(contract.deal.buyer)}
                    {contract.deal.buyerBroker && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h2" gutterBottom>
                            Broker:
                          </Typography>
                        </Grid>
                        {getParticipant(contract.deal.buyerBroker)}
                      </>
                    )}
                  </Grid>

                  <Grid container item xs={12} sm={6} alignContent="flex-start">
                    <Grid item xs={12}>
                      <Typography variant="h2" gutterBottom>
                        Seller:
                      </Typography>
                    </Grid>
                    {getParticipant(contract.deal.seller)}
                    {contract.deal.sellerBroker && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h2" gutterBottom>
                            Broker:
                          </Typography>
                        </Grid>
                        {getParticipant(contract.deal.sellerBroker)}
                      </>
                    )}
                  </Grid>

                </Grid>
                 */}
                {/* <Divider /> */}

                <Grid container className={classes.chapterContainer}>
                  <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                      Contract Signed By
                    </Typography>
                    <Typography variant="body1">
                      By typing your full name you understand and agree to the following:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <Link href={`${constants.BASE_URL}media/E-sig_disclaimer.pdf`} target="_blank" rel="noreferrer">
                        Click here to read the full disclosure
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={6} lg={3} className={classes.margin8}>
                    <Box fontStyle="italic">
                      {!buyerSignature && !buyerBrokerSignature && !sellerSignature && !sellerBrokerSignature && 'None'}
                    </Box>
                    {buyerSignature && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Buyer:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={cx(classes.secondaryHeading, classes.italic)}>
                            {`${buyerSignature.user.lastName} ${buyerSignature.user.firstName}`}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {buyerBrokerSignature && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Buyer broker:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={cx(classes.secondaryHeading, classes.italic)}>
                            {`${buyerBrokerSignature.user.lastName} ${buyerBrokerSignature.user.firstName}`}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {sellerSignature && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Seller:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={cx(classes.secondaryHeading, classes.italic)}>
                            {`${sellerSignature.user.lastName} ${sellerSignature.user.firstName}`}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {sellerBrokerSignature && (
                      <>
                        <Grid item xs={6}>
                          <Typography className={classes.heading}>
                            Seller broker:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={cx(classes.secondaryHeading, classes.italic)}>
                            {`${sellerBrokerSignature.user.lastName} ${sellerBrokerSignature.user.firstName}`}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                {getRole(userRole) !== 'Admin' &&
                  (!handleCheckSign() ? (
                    <Grid container item xs={12} sm={6} lg={4} spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="firstName"
                          variant="standard"
                          id="firstName"
                          fullWidth
                          label="First Name"
                          onChange={({ target }) => {
                            setFirstName(target.value);
                            setSignName(`${target.value} ${lastName}`);
                          }}
                          className={classes.marginBottom20}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="lastName"
                          variant="standard"
                          id="lastName"
                          fullWidth
                          label="Last Name"
                          onChange={({ target }) => {
                            setLastName(target.value);
                            setSignName(`${firstName} ${target.value}`);
                          }}
                          className={classes.marginBottom20}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ButtonSC
                          variant="fill"
                          size="large"
                          text="Sign Agreement"
                          handleClick={() => handleSignContract()}
                          color="green"
                          disabled={handleCheckSign()}
                        />
                      </Grid>
                    </Grid>
                  ) :
                    (
                      <Grid container item xs={12} sm={6} lg={3}>
                        <Grid item xs={6}>
                          <Typography variant="h2">
                            Signed on:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h2" className={cx(classes.secondaryHeading, classes.italic)}>
                            {getRole(userRole) !== 'Broker' && getRole(userRole) === 'Buyer' && buyerSignature && getDate(buyerSignedDate)}
                            {getRole(userRole) === 'Broker' && getBrokerRole(userRole, null) === 'Buyer' && buyerBrokerSignature && getDate(buyerBrokerSignedDate)}
                            {getRole(userRole) !== 'Broker' && getRole(userRole) === 'Seller' && sellerSignature && getDate(sellerSignedDate)}
                            {getRole(userRole) === 'Broker' && getBrokerRole(userRole, null) === 'Seller' && sellerBrokerSignature && getDate(sellerBrokerSignedDate)}

                            {getRole(userRole) !== 'Broker' && getRole(userRole) === 'Seller & Buyer' && deal.seller.id === accountId && sellerSignature && getDate(sellerSignedDate)}
                            {getRole(userRole) !== 'Broker' && getRole(userRole) === 'Seller & Buyer' && deal.buyer.id === accountId && buyerSignature && getDate(buyerSignedDate)}
                            {getRole(userRole) === 'Broker' && getBrokerRole(userRole, null) === 'Seller & Buyer' && (deal.sellerBroker && deal.sellerBroker.id) === accountId && sellerBrokerSignature && getDate(sellerBrokerSignedDate)}
                            {getRole(userRole) === 'Broker' && getBrokerRole(userRole, null) === 'Seller & Buyer' && (deal.buyerBroker && deal.buyerBroker.id) === accountId && buyerBrokerSignature && getDate(buyerBrokerSignedDate)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
              </div>
            )
          )}
      </PaperContainer>
    </>
  );
};

export default Contract;
