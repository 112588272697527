import { createSlice } from '@reduxjs/toolkit';
import {
  apiAcceptAllIA,
  apiGetAllDealsOfAccount,
  apiCreateDealFromSellerStage1,
  apiCreateDealFromSellerStage2,
  apiCreateDealFromBuyer,
  apiGoToDeal,
  apiGetSingleDealInfo,
  apiAgreeWithDeal,
  apiLeftTheDeal,
  apiGetAllSettlementsOfAccount,
  apiMarkPTSCompleted,
  apiAcceptAllNP,
  apiAcceptAllPTS,
} from '../../api/deals';
import { AppThunk } from '../index';
import translationEN from '../../locales/en/translation.json';

import {
  ItemOfDealListState,
  NegotiationPointForDeal,
  PostTradeStepForDeal,
  ItemOfDealList,
} from '../../interfaces/deals.interface';
import { addNotification } from './snackbar.slice';
import history from '../../core/history';

const initialState: ItemOfDealListState = {
  deal: null,
  listOfDeals: [],
  listOfSettlements: [],
  appropriateInventoryItems: [],
  needQuantity: null,
  chosenOrderId: null,
  chatMessages: [],
  negotiationPoints: [],
  inventoryAttributes: [],
  totalPrice: 0,
  postTradeSteps: [],
  error: '',
  isLoading: false,
};

const dealsSlice = createSlice({
  initialState,
  name: 'deals',
  reducers: {
    dealRequest(state) {
      state.deal = null;
      state.error = '';
      state.isLoading = true;
    },
    dealSuccess(state, action) {
      state.deal = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    dealSuccessNotUpdDeal(state) {
      state.error = '';
      state.isLoading = false;
    },
    dealFailure(state, action) {
      state.deal = null;
      state.error = action.payload;
      state.isLoading = false;
    },
    listOfDealsRequest(state) {
      state.listOfDeals = [];
      state.error = '';
      state.isLoading = true;
    },
    listOfDealsSuccess(state, action) {
      if (Array.isArray(action.payload)) {
        state.listOfDeals = action.payload;
      } else {
        const index = state.listOfDeals
          .findIndex((obj: ItemOfDealList) => obj.id === action.payload.id);
        if (index !== -1) {
          state.listOfDeals[index] = action.payload;
        } else {
          state.listOfDeals.push(action.payload);
        }
      }
      if (
        state.deal &&
        (action.payload.id === state.deal.id)
      ) state.deal = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    listOfDealsFailure(state, action) {
      state.listOfDeals = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    listOfSettlementsSuccess(state, action) {
      if (Array.isArray(action.payload)) {
        state.listOfSettlements = action.payload;
      } else {
        const index = state.listOfSettlements
          .findIndex((obj: ItemOfDealList) => obj.id === action.payload.id);
        if (index !== -1) {
          state.listOfSettlements[index] = action.payload;
        } else {
          state.listOfSettlements.push(action.payload);
        }
      }
      if (
        state.deal &&
        (action.payload.id === state.deal.id)
      ) state.deal = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    listOfSettlementsFailure(state, action) {
      state.listOfSettlements = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    listOfInventoryRequest(state) {
      state.appropriateInventoryItems = [];
      state.needQuantity = null;
      state.chosenOrderId = null;
      state.error = '';
      state.isLoading = true;
    },
    listOfInventorySuccess(state, action) {
      state.appropriateInventoryItems = action.payload.appropriateInventoryItems;
      state.needQuantity = action.payload.needQuantity;
      state.chosenOrderId = action.payload.chosenOrderId;
      state.error = '';
      state.isLoading = false;
    },
    listOfInventoryFailure(state, action) {
      state.appropriateInventoryItems = [];
      state.needQuantity = null;
      state.chosenOrderId = null;
      state.error = action.payload;
      state.isLoading = false;
    },
    updateChatMessages(state, action) {
      if (Array.isArray(action.payload)) {
        state.chatMessages = action.payload;
      } else {
        state.chatMessages.push(action.payload);
      }
    },
    updateNP(state, action) {
      if (action.payload.totalPrice) state.totalPrice = action.payload.totalPrice;
      if (Array.isArray(action.payload.foundNP)) {
        state.negotiationPoints = action.payload.foundNP;
      } else {
        const index = state.negotiationPoints
          .findIndex((obj: NegotiationPointForDeal) => obj.id === action.payload.foundNP.id);
        if (index !== -1) {
          state.negotiationPoints[index] = action.payload.foundNP;
        }
      }
    },
    updateIA(state, action) {
      if (Array.isArray(action.payload.foundNP)) {
        state.inventoryAttributes = action.payload.foundNP;
      } else {
        const index = state.inventoryAttributes
          .findIndex((obj: NegotiationPointForDeal) => obj.id === action.payload.foundNP.id);
        if (index !== -1) {
          state.inventoryAttributes[index] = action.payload.foundNP;
        }
      }
    },
    updatePostTradeSteps(state, action) {
      if (Array.isArray(action.payload)) {
        state.postTradeSteps = action.payload;
      } else {
        const index = state.postTradeSteps
          .findIndex((obj: PostTradeStepForDeal) => obj.id === action.payload.id);
        if (index !== -1) {
          state.postTradeSteps[index] = action.payload;
        }
      }
    },
    updateDealRequest(state) {
      state.error = '';
      state.isLoading = true;
    },
  },
});

export const {
  dealRequest,
  dealSuccess,
  dealSuccessNotUpdDeal,
  dealFailure,
  listOfDealsRequest,
  listOfDealsSuccess,
  listOfDealsFailure,
  listOfSettlementsSuccess,
  listOfSettlementsFailure,
  listOfInventoryRequest,
  listOfInventorySuccess,
  listOfInventoryFailure,
  updateChatMessages,
  updateNP,
  updateIA,
  updatePostTradeSteps,
  updateDealRequest,
} = dealsSlice.actions;

export const getAllDealsOfAccount = (
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    const deals = await dispatch(apiGetAllDealsOfAccount(brokerAccountId, accountId, token));
    dispatch(listOfDealsSuccess(deals));
  } catch (error) {
    dispatch(listOfDealsFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getAllSettlementsOfAccount = (
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    const settlements =
      await dispatch(apiGetAllSettlementsOfAccount(brokerAccountId, accountId, token));
    dispatch(listOfSettlementsSuccess(settlements));
  } catch (error) {
    dispatch(listOfSettlementsFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const createDealFromSellerStage1 = (
  orderId: number,
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(listOfInventoryRequest());
    const inventories =
      await dispatch(apiCreateDealFromSellerStage1(
        orderId,
        brokerAccountId,
        accountId,
        token,
      ));
      await dispatch(listOfInventorySuccess(inventories));
  } catch (error) {
    dispatch(listOfInventoryFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const createDealFromSellerStage2 = (
  orderId: number,
  chosenSellerInventoryId: number,
  needQuantity: number,
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(dealRequest());
    await dispatch(apiCreateDealFromSellerStage2(
      orderId,
      chosenSellerInventoryId,
      needQuantity,
      brokerAccountId,
      accountId,
      token,
    ));
    dispatch(dealSuccess(undefined));
  } catch (error) {
    dispatch(dealFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const createDealFromBuyer = (
  orderId: number,
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
  param?: any,
  sendAnswerText?: any,
): AppThunk => async (dispatch) => {
  try {
    dispatch(dealRequest());
    const createdDeal = await dispatch(apiCreateDealFromBuyer(
      orderId,
      brokerAccountId,
      accountId,
      token,
      param,
      sendAnswerText,
    ));
    dispatch(dealSuccess(undefined));
    return createdDeal;
  } catch (error) {
    dispatch(dealFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const goToDeal = (
  dealRoomId: number | string,
  dealId: number,
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(dealRequest());
    await dispatch(apiGoToDeal(
      dealRoomId,
      brokerAccountId,
      accountId,
      token,
    ));
    dispatch(dealSuccessNotUpdDeal());
  } catch (error) {
    dispatch(dealFailure(error?.response ? error?.response?.data?.error : error?.message));
  }
};

export const getSingleDealInfo = (
  dealId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
): AppThunk => async (dispatch) => {
  try {
    dispatch(dealRequest());
    const deal: any = await dispatch(apiGetSingleDealInfo(dealId, token));
    dispatch(dealSuccess(deal));
    dispatch(updateChatMessages(deal.dealMessages));
    dispatch(updateNP({
      foundNP: deal.negotiationPointsDeals,
      totalPrice: deal.totalPrice,
    }));
    dispatch(updateIA({
      foundNP: deal.negotiationPointsDealsInventoryAttribute,
    }));
    dispatch(updatePostTradeSteps(deal.foundPostTradeProcessingStepsDeal));
  } catch (error) {
    history.push('./../notfound');
  }
};

export const agreeWithDeal = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateDealRequest());
    const deal = await dispatch(apiAgreeWithDeal(dealId, accountId, token));
    dispatch(dealSuccess(deal));
    dispatch(addNotification({
      key,
      message: translationEN.YouWillBeNotifiedWhenCounterpartyAgreesAndAdminReviews,
      options: { variant: 'success', autoHideDuration: 12000 },
    }));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const leftTheDeal = (
  dealRoomId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiLeftTheDeal(dealRoomId, accountId, token));
    dispatch(addNotification({
      key,
      message: translationEN.YouHaveLeftTheSmartDealRoom,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const markPTSCompleted = (
  ptsData: FormData,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiMarkPTSCompleted(ptsData, token));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const acceptAllIA = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiAcceptAllIA(dealId, accountId, token));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const acceptAllNP = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiAcceptAllNP(dealId, accountId, token));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const acceptAllPTS = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiAcceptAllPTS(dealId, accountId, token));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default dealsSlice.reducer;
