import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import {
  apiGetAllBrokerClients,
  apiAddUserToBroker,
  apiActAsClient,
} from '../../api/brokerClients';
import { BrokerClientState, BrokerClient } from '../../interfaces/brokerClients.interface';
import translationEN from '../../locales/en/translation.json';

const initialState: BrokerClientState = {
  brokerClient: null,
  listOfBrokerClients: [],
  error: '',
  isLoading: false,
};

const brokerClientsSlice = createSlice({
  initialState,
  name: 'brokerClients',
  reducers: {
    brokerClientRequest(state) {
      state.error = '';
      state.isLoading = true;
    },
    brokerClientSuccess(state, action) {
      if (Array.isArray(action.payload)) {
        state.listOfBrokerClients = action.payload;
      } else {
        state.brokerClient = action.payload;
        const index = state.listOfBrokerClients
          .findIndex((obj: BrokerClient) => obj.id === action.payload.id);
        if (index !== -1) {
          state.listOfBrokerClients[index] = action.payload;
        } else {
          state.listOfBrokerClients.push(action.payload);
        }
      }
      state.error = '';
      state.isLoading = false;
    },
    brokerClientFailure(state, action) {
      state.brokerClient = null;
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  brokerClientRequest,
  brokerClientSuccess,
  brokerClientFailure,
} = brokerClientsSlice.actions;

export const getAllBrokerClients = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
): AppThunk => async (dispatch) => {
  try {
    dispatch(brokerClientRequest());
    const requests = await dispatch(apiGetAllBrokerClients(accountId, token));
    dispatch(brokerClientSuccess(requests));
  } catch (error) {
    dispatch(brokerClientFailure(error?.response ? error?.response?.data : error?.message));
  }
};

export const addUserToBroker = (
  user: FormData,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(brokerClientRequest());
    const requests = await dispatch(apiAddUserToBroker(user, accountId, token));
    dispatch(brokerClientSuccess(requests));
    dispatch(addNotification({
      key,
      message: translationEN.RequestToApproveSendToAdmin,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(brokerClientFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const actAsClient = (
  userId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(brokerClientRequest());
    const request: any = await dispatch(apiActAsClient(userId, accountId, token));
    localStorage.setItem('clientAccountId', request.clientAccountId);
    localStorage.setItem('clientId', request.clientId);
    localStorage.setItem('clientUserName', request.clientUserName);
    localStorage.setItem('clientFirstName', request.clientFirstName);
    localStorage.setItem('clientLastName', request.clientLastName);
    window.location.reload();
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default brokerClientsSlice.reducer;
