export const mockUserConfirmed = {
  id: 1,

  // Personal Info
  userName: 'UserName',
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  dateOfBirth: '2020-07-13T06:11:00.000Z',
  mainNumber: '+1 (333) 333-3333',
  mobileNumber: '+1 (333) 333-3333',
  email: 'user@gmail.com',
  profilePhotoLink: 'link',
  line1: 'line1',
  line2: 'line2',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
  recieveEmailMessages: true,
  recieveSMSMessages: true,

  // Company Info
  organizationName: 'organizationName',
  contactsTitle: 'contactsTitle',
  businessWebsiteAddress: 'businessWebsiteAddress',
  stateOfIncorporation: 'stateOfIncorporation',
  organizationOfficePhone: 'organizationPhone',
  organizationOfficeFax: 'organizationFax',
  organizationLine1: 'organizationLine1',
  organizationLine2: 'organizationLine2',
  organizationCity: 'organizationCity',
  organizationState: '',
  organizationZip: '',
  // organizationDocuments: [],
  federalEIN: '',

  // Documents
  governmentIdType: 'Passport',
  governmentIdNumber: 'passportSerialNumber',
  governmentIdDateOfIssue: '2020-07-13T06:11:00.000Z',
  governmentIdDateOfExpiration: '2020-07-13T06:11:00.000Z',
  // governmentIdMediaLink: {
  //   link: 'passportMediaLink/a/name',
  //   title: 'name',
  // },
  // governmentIdPhoto: [{ name: 'passportPhoto' } as File],

  // New Fields
  avatar: { name: 'avatar' } as File,
  // newOrganizationDocuments: [{ name: 'orgDocs' } as File],

  // Role for admin Request
  isGuest: false,
  isSeller: false,
  isBuyer: false,
  isBroker: false,
  isAdmin: false,
  isConfirmed: true,
};

export const tokenFromApi = {
  token: 'token',
  tokenExp: (new Date(new Date().getTime() + 65000)).toISOString(),
  refreshToken: 'token',
};

export const token = {
  accessToken: 'token',
  tokenExp: (new Date(new Date().getTime() + 65000)).toISOString(),
  refreshToken: 'token',
};

export const unauthorizedToken = {
  accessToken: null,
  tokenExp: null,
  refreshToken: null,
};

export const expiredToken = {
  accessToken: 'token',
  tokenExp: (new Date(new Date().getTime() - 65000)).toISOString(),
  refreshToken: 'token',
};

export const mockLocalStorage = {
  userId: 1,
  accountId: 2,
  token: {
    accessToken: 'token',
    tokenExp: '2020-07-13T06:11:00.000Z',
    refreshToken: 'refreshToken',
  },
  clientUserName: 'clientUserName',
  clientLastName: 'clientLastName',
  clientFirstName: 'clientFirstName',
  clientAccountId: 3,
  clientId: 4,
};

export const noAuthUser = {
  userId: 1,
  userRole: {
    isAdmin: false,
    isBroker: false,
    isSeller: false,
    isBuyer: false,
    requestCreatedAt: false,
    isConfirmed: false,
  },
  isLoading: false,
  isAuthenticated: false,
  authError: null,
};

export const authUser = {
  userId: 1,
  userRole: {
    isAdmin: false,
    isBroker: false,
    isSeller: false,
    isBuyer: false,
    requestCreatedAt: false,
    isConfirmed: true,
  },
  isLoading: false,
  isAuthenticated: true,
  authError: null,
};

export const inventoryItemTypeField = {
  uiId: 1,
  order: 1,
  name: 'text',
  typeOfField: 'NUMBER',
  format: 'PERCENT',
  required: true,
  defaultValue: 1,
  id: 1,
  value: 1,
};

export const inventoryItemType = {
  id: 26,
  type: 'BIOMASS',
  units: 'lb',
  minTradeSize: 1,
  maxTradeSize: 10,
  tradeSizeIncrement: 1,
  title: '',
  description: '',
  priceIncrement: 1,
  active: true,
  systemCommission: {
    id: 1,
    feeType: 'PERCENT',
    feeForSeller: 1,
    feeForBuyer: 1,
  },
  inventoryItemTypeFieldsTypes: [
    inventoryItemTypeField,
    {
      ...inventoryItemTypeField,
      typeOfField: 'DATE',
      value: '2020-07-07T05:00:33.967Z',
    },
    {
      ...inventoryItemTypeField,
      format: 'DOLLAR',
      value: '2020-07-07T05:00:33.967Z',
    },
    {
      ...inventoryItemTypeField,
      format: 'STRING',
    },
  ],
  needDocumentsDescription: 'docs',
  instantCheckout: false,
};

export const inventory = {
  active: true,
  instantCheckout: false,
  approved: true,
  approvedAt: '2020-07-07T05:00:45.786Z',
  brokerEmail: null,
  brokerName: null,
  brokerUserName: null,
  createdAt: '2020-07-07T04:59:43.561Z',
  description: '',
  shortDescription: '',
  id: 1,
  inventoryItemStatus: 'CONFIRMED',
  inventoryItemType,
  price: null,
  proveDocuments: [],
  labAttestationDocs: [],
  productPicture: [],
  quantity: 1000,
  quantityRemained: 999,
  sendToAdminApprovingDate: '2020-07-07T05:00:33.967Z',
  statusChangeDate: '2020-07-07T05:00:45.786Z',
  title: '',
  tradeSizeIncrementCopy: 1,
  requestForApprovement: null,
  labAttest: true,
  labAttestationDate: '2020-07-07T05:00:33.967Z',
  generalAttest: true,
  generalAttestationDate: '2020-07-07T05:00:33.967Z',
  signature: {
    user: mockUserConfirmed,
  },
};

export const order = {
  id: 1,
  inventory,
  quantity: 100,
  unitPrice: 100,
  createdAt: '2020-07-07T05:00:33.967Z',
  orderType: 'SELL',
  price: 10000,
  orderStatus: 'OPEN',
  ownerAccountId: 1,
  reasonForDecline: '',
};

export const orders = [
  order,
  {
    ...order,
    id: 2,
    orderType: 'BUY',
  },
];

export const negotiationPointsDeals = [{
  id: 13,
  title: 'choice',
  valueType: 'CHOICE',
  description: '',
  valueBuyerBoolean: false,
  valueBuyerDate: '2020-07-07T05:00:33.967Z',
  valueBuyerNumber: 0,
  valueBuyerString: 'LOW',
  valueSellerBoolean: false,
  valueSellerDate: '2020-07-07T05:00:33.967Z',
  valueSellerNumber: 0,
  valueSellerString: 'LOW',
  agreed: true,
  descriptionOfNPMadeByUserInDealValueBuyer: 'test',
  descriptionOfNPMadeByUserInDealValueSeller: 'test',

  affectTheTotalPrice: false,
  agreedValue: 'LOW',

  negotiationPointsDealsChoices: [],
  defaultValue: '',
},
{
  affectTheTotalPrice: false,
  agreed: true,
  agreedValue: '1',
  description: '',
  descriptionOfNPMadeByUserInDealValueBuyer: '',
  descriptionOfNPMadeByUserInDealValueSeller: '',
  id: 12,
  title: 'number',
  valueBuyerBoolean: false,
  valueBuyerDate: '2020-07-07T05:00:33.967Z',
  valueBuyerNumber: 1,
  valueBuyerString: '',
  valueSellerBoolean: false,
  valueSellerDate: '2020-07-07T05:00:33.967Z',
  valueSellerNumber: 1,
  valueSellerString: '',
  valueType: 'NUMBER',
  negotiationPointsDealsChoices: [],
  defaultValue: '',
},

{
  affectTheTotalPrice: false,
  agreed: true,
  agreedValue: '1',
  description: '',
  descriptionOfNPMadeByUserInDealValueBuyer: '',
  descriptionOfNPMadeByUserInDealValueSeller: '',
  id: 14,
  title: 'date',
  valueBuyerBoolean: false,
  valueBuyerDate: '2020-07-07T05:00:33.967Z',
  valueBuyerNumber: 1,
  valueBuyerString: '',
  valueSellerBoolean: false,
  valueSellerDate: '2020-07-07T05:00:33.967Z',
  valueSellerNumber: 1,
  valueSellerString: '',
  valueType: 'DATE',
  negotiationPointsDealsChoices: [],
  defaultValue: '',
},
{
  affectTheTotalPrice: false,
  agreed: true,
  agreedValue: '1',
  description: '',
  descriptionOfNPMadeByUserInDealValueBuyer: '',
  descriptionOfNPMadeByUserInDealValueSeller: '',
  id: 15,
  title: 'boolean true',
  valueBuyerBoolean: false,
  valueBuyerDate: '2020-07-07T05:00:33.967Z',
  valueBuyerNumber: 1,
  valueBuyerString: '',
  valueSellerBoolean: true,
  valueSellerDate: '2020-07-07T05:00:33.967Z',
  valueSellerNumber: 1,
  valueSellerString: '',
  valueType: 'BOOLEAN',
  negotiationPointsDealsChoices: [],
  defaultValue: '',
},
{
  affectTheTotalPrice: false,
  agreed: true,
  agreedValue: '1',
  description: '',
  descriptionOfNPMadeByUserInDealValueBuyer: '',
  descriptionOfNPMadeByUserInDealValueSeller: '',
  id: 18,
  title: 'boolean false',
  valueBuyerBoolean: false,
  valueBuyerDate: '2020-07-07T05:00:33.967Z',
  valueBuyerNumber: 1,
  valueBuyerString: '',
  valueSellerBoolean: false,
  valueSellerDate: '2020-07-07T05:00:33.967Z',
  valueSellerNumber: 1,
  valueSellerString: '',
  valueType: 'BOOLEAN',
  negotiationPointsDealsChoices: [],
  defaultValue: '',
},
{
  affectTheTotalPrice: false,
  agreed: true,
  agreedValue: '1',
  description: '',
  descriptionOfNPMadeByUserInDealValueBuyer: '',
  descriptionOfNPMadeByUserInDealValueSeller: '',
  id: 16,
  title: 'string',
  valueBuyerBoolean: false,
  valueBuyerDate: '2020-07-07T05:00:33.967Z',
  valueBuyerNumber: 1,
  valueBuyerString: '',
  valueSellerBoolean: false,
  valueSellerDate: '2020-07-07T05:00:33.967Z',
  valueSellerNumber: 1,
  valueSellerString: '',
  valueType: 'STRING',
  negotiationPointsDealsChoices: [],
  defaultValue: '',
},
{
  affectTheTotalPrice: false,
  agreed: true,
  agreedValue: '1',
  description: '',
  descriptionOfNPMadeByUserInDealValueBuyer: '',
  descriptionOfNPMadeByUserInDealValueSeller: '',
  id: 17,
  title: 'Quantity',
  valueBuyerBoolean: false,
  valueBuyerDate: '2020-07-07T05:00:33.967Z',
  valueBuyerNumber: 1,
  valueBuyerString: '',
  valueSellerBoolean: false,
  valueSellerDate: '2020-07-07T05:00:33.967Z',
  valueSellerNumber: 1,
  valueSellerString: '',
  valueType: 'NUMBER',
  negotiationPointsDealsChoices: [],
  defaultValue: '',
}];

export const postTradeProcessingStepsDeal = [{
  agreed: true,
  completed: false,
  dateToCompleteStep: '2020-07-08T21:00:00.000Z',
  description: 'Payment',
  descriptionValueBuyer: '',
  descriptionValueSeller: '',
  id: 9,
  nextStep: 2,
  previousStep: 0,
  responsible: 'buyer',
  responsibleValueBuyer: 'buyer',
  responsibleValueSeller: 'buyer',
  step: 1,
  valueBuyer: '2020-07-08T21:00:00.000Z',
  valueSeller: '2020-07-08T21:00:00.000Z',
  title: '',
  proveDocuments: [],
},
{
  agreed: true,
  completed: false,
  dateToCompleteStep: '2020-07-08T21:00:00.000Z',
  description: 'Shipment',
  descriptionValueBuyer: '',
  descriptionValueSeller: '',
  id: 10,
  nextStep: 3,
  previousStep: 1,
  responsible: 'seller',
  responsibleValueBuyer: 'seller',
  responsibleValueSeller: 'seller',
  step: 2,
  valueBuyer: '2020-07-08T21:00:00.000Z',
  valueSeller: '2020-07-08T21:00:00.000Z',
  title: '',
  proveDocuments: [],
}];

export const dealUser = {
  id: 1,
  name: 'name',
  phone: 'phone',
  organizationName: 'organizationName',
  organizationAddress: 'organizationAddress',
  organizationStateOfIncorporation: 'organizationStateOfIncorporation',

  userName: 'userName',
  email: 'email',
};

export const deal = {
  id: 1,
  seller: dealUser,
  buyer: {
    ...dealUser,
    id: 2,
  },
  sellerBroker: {
    ...dealUser,
    id: 3,
  },
  buyerBroker: {
    ...dealUser,
    id: 4,
  },
  buyerSessionExpiredAt: '2020-07-07T05:00:33.967Z',
  sellerSessionExpiredAt: '2020-07-07T05:00:33.967Z',
  buyerNewSetInDealSessionExpiredAt: '2020-07-07T05:00:33.967Z',
  sellerNewSetInDealSessionExpiredAt: '2020-07-07T05:00:33.967Z',
  buyerSideAgreedOnADeal: 1,
  sellerSideAgreedOnADeal: 2,
  units: 'lb',
  agreedAmountOfNP: 4,
  totalAmountOfNP: 4,
  completeAmountOfPTS: 4,
  totalAmountOfPTS: 4,
  startedAt: '2020-07-07T05:00:33.967Z',
  updatedAt: '2020-07-07T05:00:33.967Z',
  expiredAt: '2020-07-07T05:00:33.967Z',
  completedAt: '2020-07-07T05:00:33.967Z',
  status: 'CONFIRMED',
  totalPrice: 200,
  postTradeProcessing: null,
  order,
  negotiationPointsDeals,
  lastDayDate: '2020-07-07T05:00:33.967Z',
  foundPostTradeProcessingStepsDeal: postTradeProcessingStepsDeal,
  dealRoomId: 1,
  dealMessages: [],
  postTradeProcessingDeal: {
    id: 1,
    postTradeProcessingStepsDeal,
  },
  quantity: 4,
  price: 30,
};

export const contract = {
  deal: {
    id: 3,
    status: 'CONFIRMED',
    order,
    negotiationPointsDeals,
    postTradeProcessingDeal: {
      postTradeProcessingStepsDeal,
    },
    totalPrice: 200,
    seller: {
      name: 'seller name',
    },
    buyer: {
      name: 'buyer name',
    },
    buyerBroker: {
      name: 'buyer Broker name',
    },
    sellerBroker: {
      name: 'seller Broker name',
    },
  },
  sellerSystemCommission: 3,
  buyerSystemCommission: 3,
  sellerBrokerCommission: 3,
  buyerBrokerCommission: 3,
  sellerCommission: 3,
  buyerCommission: 3,
};

export const storeInitial = {
  auth: {},
  user: {},
  notifications: [],
};
