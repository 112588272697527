import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
} from 'react-router-dom';
import {
  Grid,
  TextField,
  Typography,
  Link as LinkOpen,
  FormControlLabel,
  Checkbox,
  Input,
} from '@material-ui/core';
import useStyles from './style';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
import CheckboxSC from '../../components/CheckboxSC/CheckboxSC';
import constants from '../../core/constants';
import { activateMobilePhone, confirmVerificationCode, setStatusPhoneNumberSendActive } from '../../store/slices/mobileConfirmation.slice';
import { addNotification } from '../../store/slices/snackbar.slice';
import { RootState } from '../../store/slices';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreePrivatePolicy: boolean;
  mobileNumber: string;
};

interface Props {
  onSubmit: (data: FormData) => void;
  isLoading: boolean;
}

export const SignUpForm: FC<Props> = ({ onSubmit, isLoading }) => {
  const classes = useStyles();
  const {
    handleSubmit, errors, control, watch,
  } = useForm();

  const statusVerification = useSelector((state: RootState) => {
    return state.mobileConfirmation.status;
  });
  const sendStatus = useSelector((state: RootState) => {
    return state.mobileConfirmation.activeSend;
  })

  const dispatch = useDispatch();
  const [activeSubmitButton, setActiveSubmitButton] = useState(true);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [email, setEmail] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [verifyInputDisplay, setVerifyInputDisplay] = useState<boolean | string>('NOT_VERIFIED');
  const [numberForVerification, setNumberForVerification] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<number | string>('');

  const [activeVerify, setActiveVerify] = useState<boolean>(false);
  const [smsTooltip, setSmsTooltip] = useState<boolean>(sendStatus);
  const [verificationCheckBox, setVerificationCheckBox] = useState<boolean | string>(statusVerification);

  useEffect(() => {
    setSmsTooltip(sendStatus);
    setVerifyInputDisplay(statusVerification);
  }, [sendStatus, statusVerification]);


  const activeSubmitButtonControl = () => {
    if (firstName && lastName && email && mobile && password && confirmPassword) {
      setActiveSubmitButton(false);
    };
  };

  const fn = () => { setFirstName(true); activeSubmitButtonControl() };
  const ln = () => { setLastName(true); activeSubmitButtonControl() };
  const em = () => { setEmail(true); activeSubmitButtonControl() };
  const mb = () => { setMobile(true); activeSubmitButtonControl() };
  const ps = () => { setPassword(true); activeSubmitButtonControl() };
  const confrm = () => { setConfirmPassword(true); activeSubmitButtonControl() };

  const [errorMessage, setErrorMessage] = useState('');

  const clearNotification = () => {
    (watch().recieveEmailMessages || watch().recieveSMSMessages) ? setErrorMessage('') : setErrorMessage('Must Accept One Of Notification Type')
  }

  useEffect(() => {
    (
      clearNotification
    )()
  }, [watch().recieveEmailMessages, watch().recieveSMSMessages])

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ onChange, value, name }) => (
              <TextField
                value={value}
                name={name}
                autoComplete="fname"
                variant="outlined"
                fullWidth
                label="First Name"
                error={!!errors.firstName?.message}
                onChange={onChange}
                onInput={fn}
                onBlur={({ target }) => onChange(target.value.trim())}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: /^[A-Za-z- ']+$/i,
                message: 'Incorrect value',
              },
            }}
          />
          {errors.firstName && <Typography variant="caption" color="error">{errors.firstName.message}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ onChange, value, name }) => (
              <TextField
                value={value}
                name={name}
                variant="outlined"
                fullWidth
                label="Last Name"
                autoComplete="lname"
                error={!!errors.lastName?.message}
                onChange={onChange}
                onInput={ln}
                onBlur={({ target }) => onChange(target.value.trim())}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: /^[A-Za-z- ']+$/i,
                message: 'Incorrect value',
              },
            }}
          />
          {errors.lastName && <Typography variant="caption" color="error">{errors.lastName.message}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ onChange, value, name }) => (
              <TextField
                value={value}
                name={name}
                variant="outlined"
                fullWidth
                label="Email"
                autoComplete="email"
                error={!!errors.email?.message}
                onChange={onChange}
                onInput={em}
                onBlur={({ target }) => onChange(target.value.trim())}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i,
                message: 'Incorrect value',
              },
            }}
          />
          {errors.email && <Typography variant="caption" color="error">{errors.email.message}</Typography>}
        </Grid>

        {/* ************************ */}
        <Grid
          item
          xs={12}
          className={cx(classes.phone, !!errors.mobileNumber && classes.errorPhone)}
        >
          <Controller
            name="mobileNumber"
            control={control}
            render={({ onChange, value, name }) => (
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <PhoneInput
                    country="us"
                    countryCodeEditable={false}
                    onlyCountries={['us']}
                    value={value}
                    inputProps={{
                      name,
                      className: (cx(
                        classes.inputPhone,
                        !!errors.mobileNumber && classes.errorInputPhone,
                      )),
                    }}
                    onChange={(tel) => {
                      onChange(tel || '');
                      mb();
                    }}
                    specialLabel="Mobile Number*"
                  />
                </Grid>
                  {statusVerification === 'NOT_VERIFIED' || statusVerification === 'PROCESS_VERIFIED' ?
                  <Grid item xs={12} className={classes.verifyMargin}>
                    <ButtonSC
                      variant="fill"
                      text="Confirm"
                      size="large"
                      // type="submit"
                      color="green"
                      disabled={activeVerify}
                      handleClick={() => {
                        if (value === undefined || value.length < 11) {
                          dispatch(addNotification({
                            key: new Date().getTime() + Math.random(),
                            message: 'Too short number',
                            options: { variant: 'error' },
                          }));
                          return;
                        }
                        setActiveVerify(true);
                        dispatch(activateMobilePhone(value));
                        setNumberForVerification(value);
                        setVerifyInputDisplay(false);
                        
                      }
                    }
                    />
                    <Typography
                      variant="caption"
                      className={smsTooltip === false ? classes.smsDisplayNone : classes.smsDisplayExist}
                    >
                      We sent SMS with confirmation code to your phone.
                      <Link
                        to={'#'}
                        onClick={() => {dispatch(activateMobilePhone(value))}}
                      >
                        Send again
                      </Link>
                    </Typography>
                  </Grid> :
                  <FormControlLabel
                    value="start"
                    control={<Checkbox disabled checked={true}/>}
                    label="Your phone number has been verified"
                    labelPlacement="end"
                  />
                  }
              </Grid>
            )}
            rules={{
              required: 'Required field',
              validate: (value) => {
                if (value && (value === '1' || value === '52')) return 'Required field';
                if (value) return value.length >= 11 || 'Invalid value';
                return true;
              },
            }}
          />
          {errors.mobileNumber &&
            <Typography variant="caption" color="error">{errors.mobileNumber.message}</Typography>}
        </Grid>
        {/* Block for accepting verification number */}
        <Grid
          item
          xs={12}
          className={statusVerification === 'NOT_VERIFIED' || statusVerification === 'VERIFIED' ? classes.verifyInputNone : classes.verifyInputFlex}
        >
          <Controller
            name="verificationCode"
            control={control}
            render={({ onChange, value, name }) => (
              // <Grid container justifyContent="space-between" alignItems="center">
              <Grid xs={12} className={classes.verifyCode_Wrapper}>
                <Grid item xs={12} md={6} className={classes.input_verifyCode_Wrapper}>
                  <Typography variant="caption">Enter the code below and click verify</Typography>
                  <TextField
                    className={classes.input_Verify}
                    value={verificationCode}
                    variant="outlined"
                    label="Verification code"
                    size="small"
                    // inputProps={{
                    //   name,
                    //   className: (cx(
                    //     classes.inputPhone,
                    //     !!errors.mobileNumber && classes.errorInputPhone,
                    //   )),
                    // }}
                    onChange={(event) => {
                      setVerificationCode(+event.target.value);
                    }}
                    // specialLabel="Verification Code*"
                  />
                </Grid>
                <Grid item xs={12} md={5} className={classes.verify_button_Wrapper}>
                  <ButtonSC
                    variant="fill"
                    text="Verify"
                    size="large"
                    // type="submit"
                    color="green"
                    disabled={false}
                    handleClick={() => {
                      dispatch(confirmVerificationCode(numberForVerification, verificationCode));
                      setVerifyInputDisplay(verificationCheckBox);
                      setVerificationCode('');
                      // setStatusPhoneNumberSendActive(false);
                      // setVerificationCheckBox(true);
                      // setVerificationCheckBox(true);
                    }}
                  />
                </Grid>
              </Grid>
            )}
            // rules={{
            //   required: 'Required field',
            //   validate: (value) => {
            //     if (value && (value === '1' || value === '52')) return 'Required field';
            //     if (value) return value.length >= 11 || 'Invalid value';
            //     return true;
            //   },
            // }}
          />
          {errors.mobileNumber &&
            <Typography variant="caption" color="error">{errors.mobileNumber.message}</Typography>}
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            onInput={ps}
            as={(
              <TextField
                variant="outlined"
                fullWidth
                label="Password"
                type="password"
                data-testid="password"
                autoComplete="current-password"
                error={!!errors.password?.message}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,}/,
                message: "Password should be at least 8 characters long must contain uppercase and lowercase letters, numbers and on of !@#$ %^&* symbols",
              },
            }}
          />
          {errors.password &&
            <Typography variant="caption" color="error">{errors.password.message}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="confirmPassword"
            control={control}
            as={(
              <TextField
                variant="outlined"
                fullWidth
                label="Confirm Password"
                type="password"
                onInput={confrm}
                onChange={activeSubmitButtonControl}
                error={!!errors.confirmPassword?.message}
              />
            )}
            rules={{
              required: 'Required field',
              validate: (value) => watch().password === value || 'Passwords must match',
            }}
          />
          {errors.confirmPassword &&
            <Typography variant="caption" color="error">{errors.confirmPassword.message}</Typography>}
        </Grid>
        <Grid item xs={12} className={classes.alignSelfCenter}>
          <Controller
            name="agreePrivatePolicy"
            control={control}
            as={(
              <CheckboxSC
                text={(
                  <>
                    {'I agree to the terms of '}
                    <LinkOpen href={`${constants.BASE_URL}media/Terms of Service.pdf`} target="_blank" rel="noreferrer" className={classes.link}>
                      Terms of Service
                    </LinkOpen>
                  </>
                )}
                dataTestid="agreePrivatePolicy"
                onChange={() => { }}
              />
            )}
            rules={{
              required: 'Must Accept Terms of Service',
            }}
          />
          <br />
          {errors.agreePrivatePolicy &&
            <Typography variant="caption" color="error">{errors.agreePrivatePolicy.message}</Typography>}
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={12} className={classes.alignSelfCenter}>
            <Typography variant="h1" className={classes.title}>Notifications</Typography>
          </Grid>
          <Grid item>
            <Controller
              render={({ onChange, name, value }) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      name={name}
                      checked={value}
                      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  )}
                  label="Email"
                />
              )}
              name="recieveEmailMessages"
              value="primary"
              control={control}
            />
          </Grid>
          <Grid item>
            <Controller
              render={({ onChange, name, value }) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      name={name}
                      checked={value}
                      checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  )}
                  label="SMS"
                />
              )}
              name="recieveSMSMessages"
              value="primary"
              control={control}
            />
          </Grid>
          <Grid item style={{ alignSelf: 'center' }}>
            {(errors.recieveSMSMessages || errors.recieveEmailMessages) &&
              <Typography variant="caption" color="error">{errorMessage}</Typography>}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonSC
            variant="fill"
            text="Submit"
            size="large"
            type="submit"
            color="green"
            disabled={activeSubmitButton}
          />
        </Grid>
        <Grid item xs={12} className={classes.textCenter}>
          <span>Already have an account?&nbsp;</span>
          <Link to="/signin" className={classes.link}>
            Sign In
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default SignUpForm;
