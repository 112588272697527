import { createSlice } from '@reduxjs/toolkit';
import {
  apiLogin,
  apiCheckLogin,
  apiConfirmNewPassword,
  apiActivateAccount,
  apiUpdateToken,
  apiLogout,
} from '../../api/auth';
import { AppThunk } from '../index';
import { AuthState } from '../../interfaces/auth.interface';
import { addNotification } from './snackbar.slice';
import history from '../../core/history';
import getLocalStorageData from '../../api/localStorage';

const initialState: AuthState = {
  userId: null,
  userRole: {
    isAdmin: false,
    isBroker: false,
    isSeller: false,
    isBuyer: false,
    requestCreatedAt: undefined,
    isConfirmed: false,
  },
  isLoading: false,
  isAuthenticated: false,
  authError: null,
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    authRequest(state) {
      state.userId = null;
      state.userRole = {
        isAdmin: false,
        isBroker: false,
        isSeller: false,
        isBuyer: false,
        requestCreatedAt: undefined,
        isConfirmed: false,
      };
      state.isLoading = true;
      state.isAuthenticated = false;
      state.authError = null;
    },
    authSuccess(state, action) {
      state.userId = action.payload.user;
      state.isLoading = false;
      state.isAuthenticated = true;
      state.authError = null;
    },
    authFailure(state, action) {
      state.userId = null;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.authError = action.payload;
    },
    checkLoginSuccess(state, action) {
      state.userId = action.payload.userId;
      state.userRole = action.payload.userRole;
      state.isLoading = false;
      state.isAuthenticated = true;
      state.authError = null;
    },
    checkLoginFailure(state, action) {
      state.userId = null;
      state.userRole.isAdmin = false;
      state.userRole.isBroker = false;
      state.userRole.isSeller = false;
      state.userRole.isBuyer = false;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.authError = action.payload;
    },
  },
});

export const {
  authRequest,
  authSuccess,
  authFailure,
  checkLoginSuccess,
  checkLoginFailure,
} = authSlice.actions;

export const signIn = (
  email: string,
  password: string,
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(authRequest());
    const authInfo: any = await apiLogin(email, password);
    localStorage.setItem('accessToken', authInfo.token);
    localStorage.setItem('refreshToken', authInfo.refreshToken);
    localStorage.setItem('userId', authInfo.user);
    localStorage.setItem('accountId', authInfo.account);
    localStorage.setItem('tokenExp', authInfo.tokenExp);
    dispatch(authSuccess(authInfo));
  } catch (error) {
    dispatch(authFailure(error?.response?.data?.error));
    dispatch(addNotification({
      key,
      message: error?.response?.data?.error,
      options: { variant: 'error' },
    }));
  }
};

export const activateAccount = (
  activateToken: string,
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await apiActivateAccount(activateToken);
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response?.data?.error,
      options: { variant: 'error' },
    }));
  }
};

// Activate Account
export const confirmNewPassword = (
  activateToken: string,
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await apiConfirmNewPassword(activateToken);
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response?.data?.error,
      options: { variant: 'error' },
    }));
  }
};

export const logOut = (
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(authRequest());
    localStorage.clear();
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    history.push('/signin');
    await dispatch(apiLogout(token));
    window.location.reload();
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error.response ? error?.response?.data?.error : error.message,
      options: { variant: 'error' },
    }));
  }
};

export const checkLogin = (
  clientAccountId: number | null,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    const userInfo = await dispatch(apiCheckLogin(clientAccountId, userId, token));
    dispatch(checkLoginSuccess(userInfo));
  } catch (error) {
    dispatch(checkLoginFailure(error.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const updateToken = (
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
): AppThunk => async () => {
  try {
    const { userId } = getLocalStorageData();
    const newToken = await apiUpdateToken(userId, token);
    localStorage.setItem('accessToken', newToken.token);
    localStorage.setItem('tokenExp', newToken.tokenExp);
    return newToken;
  } catch (error) {
    return error;
  }
};

export default authSlice.reducer;
