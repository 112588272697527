import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ProfilePage from '../../components/ProfilePage/ProfilePage';
import MarketPlacePage from '../../components/MarketPlace/MarketPlacePage';
import ListInventory from '../../tables/Inventory/ListInventory';
import ListOfMessagesAndAlerts from '../../tables/MessagesAndAlerts/ListMessagesAndAlerts';
import CreateInventory from '../../components/Inventory/CreateInventory';
import CreateOrder from '../../components/Order/CreateOrder';
import { RootState } from '../../store/slices';
import ListOrders from '../../tables/Order/ListOrders';
import DealRoom from '../../components/DealRoom/DealRoom';
import ListOfDeals from '../../tables/DealRoom/ListOfDeals';
import getLocalStorageData from '../../api/localStorage';
import {
  getAllDealsOfAccount, listOfDealsSuccess, getAllSettlementsOfAccount, listOfSettlementsSuccess,
} from '../../store/slices/deals.slice';
import { updateListOfOrders } from '../../store/slices/order.slice';
import ListOfContracts from '../../tables/Contract/ListOfContracts';
import Contract from '../../components/Contract/Contract';
import { getAllContracts, updateListOfContracts } from '../../store/slices/contracts.slice';
import { updateListInventorySuccess } from '../../store/slices/inventory.slice';
import { addNotification } from '../../store/slices/snackbar.slice';
import NotFoundPage from '../NotFound/NotFoundPage';
import ChangeRole from '../../components/ChangeRolePage/ChangeRolePage';
import ListOfSettlements from '../../tables/DealRoom/ListOfSettlements';
import { logOut } from '../../store/slices/auth.slice';
import { updateUserSuccess } from '../../store/slices/userUpdate.slice';
import { updateListAlertsAndMessages, getAllMessagesAndAlerts } from '../../store/slices/alertsAndMessages.slice';
import { Socket } from '../MainContainer/MainContainer';
import NotificationPage from '../../components/NotificationPage/NotificationPage';
import { Button } from '@material-ui/core';
import useStyles from '../../components/DealRoom/DealRoom.style';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const newMessage = require('../../sounds/alert_high-intensity.wav');

const CreateOrderWrapper = (props: any) => {
  return <CreateOrder {...props} />
};

const EditOrderWrapper = (props: any) => {
  return <CreateOrder {...props} />
};

const CreateInventoryWrapper = (props: any) => {
  return <CreateInventory {...props} />
};

const EditInventoryWrapper = (props: any) => {
  return <CreateInventory {...props} />
};

// const socket = io(`${constants.BASE_URL}`);
const ClientPage: FC = () => {
  const dispatch = useDispatch();
  const newMessageAudio = new Audio(newMessage);

  const classes = useStyles();

  let [isSoundAlertsAccepted, setIsSoundAlertsAccepted] = useState<boolean>(false);

  useEffect(() => {
    //show modal
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    isSafari && !isSoundAlertsAccepted ? setIsSoundAlertsAccepted(false) : setIsSoundAlertsAccepted(true);
    function unlockAudio() {
      const sound = newMessageAudio;
      sound.play();
      sound.pause();
      sound.currentTime = 0;
      document.body.removeEventListener('click', unlockAudio)
      document.body.removeEventListener('touchstart', unlockAudio)
    }
    document.body.addEventListener('click', unlockAudio);
    document.body.addEventListener('touchstart', unlockAudio);
  })

  const playSound = (audioFile: any) => {
    if(isSoundAlertsAccepted) {
      audioFile.play();
    }
   };

  const {
    deal,
  } = useSelector(
    (state: RootState) => state.deals,
  );

  useEffect(() => {
    const { clientAccountId, accountId, token } = getLocalStorageData();
    dispatch(getAllDealsOfAccount(
      clientAccountId && accountId,
      clientAccountId || accountId,
      token,
      new Date().getTime() + Math.random(),
    ));
    dispatch(getAllSettlementsOfAccount(
      clientAccountId && accountId,
      clientAccountId || accountId,
      token,
      new Date().getTime() + Math.random(),
    ));
    dispatch(getAllContracts(
      clientAccountId || accountId,
      clientAccountId && accountId,
      token,
      new Date().getTime() + Math.random(),
    ));
    dispatch(getAllMessagesAndAlerts(
      clientAccountId || accountId,
      token,
      new Date().getTime() + Math.random(),
    ));
  }, [dispatch]);

  useEffect(() => {
    const { accountId, clientAccountId, token } = getLocalStorageData();
    Socket.on('connectToPersonalRoom', (data: any) => {
      if (data.message === 'role updated' && data.accountId === (clientAccountId || accountId)) window.location.reload();
      if (data.message === 'administrator changed your email') dispatch(logOut(token, new Date().getTime() + Math.random()));
      if (data.message === 'new deal') {
        dispatch(listOfDealsSuccess(data.foundDealWithNPInfo));
        const user = clientAccountId || accountId;
        if (user && (((data.foundDealWithNPInfo.order.orderType === 'SELL') && (+data.foundDealWithNPInfo.seller.id === +user)) || ((data.foundDealWithNPInfo.order.orderType === 'BUY') && (+data.foundDealWithNPInfo.buyer.id === +user)))) {
          playSound(newMessageAudio);
        }
      }
      if (data.error) {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: data.message,
          options: { variant: 'error' },
        }));
      }
      if (data.message === 'client updated profile') dispatch(updateUserSuccess(data.userToSendToUI));
      if (data.message === 'deal updated') {
        dispatch(listOfDealsSuccess(data.foundDealWithNPInfo));
        if (data.foundDealWithNPInfo.status === 'DECLINED' && deal?.id === data.foundDealWithNPInfo.id) {
          dispatch(addNotification({
            key: new Date().getTime() + Math.random(),
            message: `Deal was declined by the administrator. Reason: ${data.foundDealWithNPInfo.request.reasonForDecline}`,
            options: { variant: 'error' },
          }));
        }
      }
      if (data.message === 'settlement updated') dispatch(listOfSettlementsSuccess(data.foundDealWithPTSInfo));
      if (data.message === 'orders updated') dispatch(updateListOfOrders(data.orderToSend));
      if (data.message === 'order questions updated') dispatch(updateListAlertsAndMessages(data.foundMessageWithAnswers));
      if (data.message === 'contract updated') dispatch(updateListOfContracts(data.foundContract));
      if (data.message === 'inventory item approved') dispatch(updateListInventorySuccess(data.foundInventoryItem));
      if (data.message === 'inventory item declined') {
        dispatch(updateListInventorySuccess(data.foundInventoryItem));
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: `Inventory Approval Rejected:${data.reasonForDecline}`,
          options: { variant: 'error' },
        }));
      }
    });
    return () => {
      Socket.off('connectToPersonalRoom');
    };
  }, [dispatch, deal, newMessageAudio]);

  return (

    <Switch>
        {/* {(!isSoundAlertsAccepted) && <Button className={classes.alertSoundModal}  onClick={() => setIsSoundAlertsAccepted(!isSoundAlertsAccepted)}>Click to allow audio notifications</Button>} */}
      <Route exact path="/" component={MarketPlacePage} />
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/changeRole" component={ChangeRole} />
      <Route exact path="/myInventory" component={ListInventory} />
      <Route exact path="/createInventory" component={CreateInventoryWrapper} />
      <Route exact path="/editInventory" component={EditInventoryWrapper} />
      <Route exact path="/orders" component={ListOrders} />
      <Route exact path="/newOrder/:inventoryItemId?" component={CreateOrderWrapper} />
      <Route exact path="/editOrder" component={EditOrderWrapper} />
      <Route exact path="/deals" component={ListOfDeals} />
      <Route exact path="/settlements" component={ListOfSettlements} />
      <Route exact path="/deal-room/:id" component={DealRoom} />
      <Route exact path="/contracts" component={ListOfContracts} />
      <Route exact path="/contract/:contractId?" component={Contract} />
      <Route exact path="/notfound" component={NotFoundPage} />
      <Route exact path="/messagesAndAlerts" component={ListOfMessagesAndAlerts} />
      <Route exact path="/notification" component={NotificationPage} />
      <Redirect to="notfound" />
    </Switch>
  );
};

export default ClientPage;
