import { createSlice } from '@reduxjs/toolkit';
import {
  apiPhoneConfirmation,
  apiPhoneVerification,
} from '../../api/auth';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';

interface PhoneConfirmationState {
  status: string;
  isLoading: boolean;
  activeSend: boolean;
}

const initialState: PhoneConfirmationState = {
  status: 'NOT_VERIFIED',
  isLoading: false,
  activeSend: false,
};

const mobileConfirmationSlice = createSlice({
  initialState,
  name: 'mobileConfirmation',
  reducers: {
    verifiedSuccess(state, action) {
      state.status = action.payload;
      state.isLoading = false;
    },
    setStatusPhoneNumberSendActive(state, action) {
      state.activeSend = action.payload;
    },
  },
});

export const {
  // authRequest,
  verifiedSuccess,
  setStatusPhoneNumberSendActive,
  // authFailure,
  // checkLoginSuccess,
  // checkLoginFailure,
} = mobileConfirmationSlice.actions;

export const activateMobilePhone = (
  phoneNumber: string,
): AppThunk => async (dispatch) => {
  try {
    await apiPhoneConfirmation(phoneNumber);
    dispatch(verifiedSuccess('PROCESS_VERIFIED'));
    dispatch(setStatusPhoneNumberSendActive(true));
  } catch (error) {
    dispatch(addNotification({
      message: error?.response?.data?.error,
      options: { variant: 'error' },
    }));
  }
};

// Activate Account
export const confirmVerificationCode = (
  phoneNumber: string,
  code: number | string,
): AppThunk => async (dispatch) => {
  try {
    const status = await apiPhoneVerification(phoneNumber, code);
    if (status === 'VERIFIED') {
      dispatch(verifiedSuccess(status));
      dispatch(setStatusPhoneNumberSendActive(false));
    } else {
      dispatch(verifiedSuccess('PROCESS_VERIFIED'));
    }
  } catch (error) {
    dispatch(addNotification({
      message: error?.response?.data?.error,
      options: { variant: 'error' },
    }));
  }
};

export default mobileConfirmationSlice.reducer;
