import { UserRole } from '../interfaces/user.interface';

export const getRole = (userRole: UserRole) => {
  if (!userRole.isConfirmed) return 'Unconfirmed';
  if (userRole.isAdmin) return 'Admin';
  if (userRole.isBroker) return 'Broker';
  if (userRole.isBuyer && !userRole.isSeller) return 'Buyer';
  if (!userRole.isBuyer && userRole.isSeller) return 'Seller';
  if (userRole.isBuyer && userRole.isSeller) return 'Seller & Buyer';
  return 'Guest';
};

export const getBrokerRole = (userRole: UserRole, client: number | string | null) => {
  if (!userRole.isBuyer && !userRole.isSeller && !userRole.isAdmin && client) return 'Guest';
  if (userRole.isBuyer && !userRole.isSeller) return 'Buyer';
  if (!userRole.isBuyer && userRole.isSeller) return 'Seller';
  if (userRole.isBuyer && userRole.isSeller) return 'Seller & Buyer';
  return 'Broker';
};
