import React, { FC } from 'react';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { User } from '../../interfaces/user.interface';
import useStyles from './ProfilePage.style';

interface Props {
  errors: any;
  control: any;
  userInfo: User;
  currentTab: number;
  newPassword: string;
}

const SecurityInfo: FC<Props> = ({
  control,
  errors,
  currentTab,
  newPassword,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={currentTab === 3 ? classes.fullHeight : classes.displayNone}
      spacing={4}
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          Change Password
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ onChange, value, name }) => (
            <TextField
              size="small"
              name={name}
              variant="outlined"
              fullWidth
              label="Old Password"
              type="password"
              data-testid="password"
              value={value}
              error={!!errors.password?.message}
              onChange={({ target }) => {
                onChange(target.value);
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <Controller
          name="newPassword"
          control={control}
          defaultValue=""
          render={({ onChange, value, name }) => (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              label="New Password"
              type="password"
              data-testid="newPassword"
              name={name}
              value={value}
              autoComplete="new-password"
              onChange={({ target }) => {
                onChange(target.value);
              }}
              error={!!errors.newPassword?.message}
              inputProps={{
                maxLength: 80,
              }}
            />
          )}
          rules={{
            // pattern: {
            //   value: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i,
            //   message: 'Incorrect value',
            // },
            // .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])
            // (?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/i,
            //   { message: 'Password should be at least 8 characters long
            //  must contain uppercase and lowercase letters,
            //  numbers and on of !@#$ %^&* symbols' }),
          }}
        />
        {errors.newPassword && (
          <Typography variant="caption" color="error">
            {errors.newPassword.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          render={({ onChange, value, name }) => (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              label="Confirm Password"
              type="password"
              data-testid="confirmPassword"
              name={name}
              value={value}
              onChange={({ target }) => {
                onChange(target.value);
              }}
              error={!!errors.confirmPassword?.message}
              inputProps={{
                maxLength: 80,
              }}
            />
          )}
          rules={{
            validate: (value) => newPassword === value || 'Passwords must match',
          }}
        />
        {errors.confirmPassword && (
          <Typography variant="caption" color="error">
            {errors.confirmPassword.message}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SecurityInfo;
