import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const AdminOrders = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path d="M11.8321 4.66782L9.33238 2.16808L2.81284 8.68762C2.71907 8.78139 2.6664 8.90856 2.6664 9.04117V11.1338C2.6664 11.2443 2.75594 11.3338 2.8664 11.3338H4.95903C5.09164 11.3338 5.21882 11.2811 5.31259 11.1874L11.8321 4.66782ZM13.8053 2.69469C13.8671 2.63302 13.9161 2.55977 13.9495 2.47913C13.983 2.39849 14.0002 2.31204 14.0002 2.22474C14.0002 2.13743 13.983 2.05099 13.9495 1.97035C13.9161 1.88971 13.8671 1.81645 13.8053 1.75478L12.2454 0.194943C12.1838 0.133147 12.1105 0.0841201 12.0299 0.0506692C11.9492 0.0172183 11.8628 0 11.7755 0C11.6882 0 11.6017 0.0172183 11.5211 0.0506692C11.4404 0.0841201 11.3672 0.133147 11.3055 0.194943L9.99898 1.50148L12.4987 4.00122L13.8053 2.69469Z" />
    <path d="M0 13.8336C0 13.5575 0.223858 13.3336 0.5 13.3336H15.4984C15.7745 13.3336 15.9984 13.5575 15.9984 13.8336V15.5C15.9984 15.7761 15.7745 16 15.4984 16H0.5C0.223857 16 0 15.7761 0 15.5V13.8336Z" />
  </SvgIcon>
);

export default AdminOrders;
