import React, { FC, useState } from 'react';
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateFnsUtils from '@date-io/date-fns';
import useStyles from './DealRoom.style';
import ButtonSC from '../ButtonSC/ButtonSC';
import getLocalStorageData from '../../api/localStorage';
import { getDateTime } from '../../utils/getDate';

interface Props {
  changeTimer: (newDate: Date | string) => void;
  agreeTimer: (newAgreedDate: string) => void;
  sellerSessionExpiredAt: string;
  buyerSessionExpiredAt: string;
  sellerId: number;
  buyerId: number;
  agree: boolean;
}

const ChangeTimeout: FC<Props> = ({
  changeTimer,
  agreeTimer,
  sellerSessionExpiredAt,
  buyerSessionExpiredAt,
  sellerId,
  buyerId,
  agree,
}) => {
  const classes = useStyles();
  const [timeout, setTimeout] = useState<Date | null>(null);

  const handleGetAccountId = () => {
    const { clientAccountId, accountId } = getLocalStorageData();
    return clientAccountId || accountId || 0;
  };

  const renderFields = (currentRole: string) => (
    (currentRole === 'valueBuyer' ? buyerId === handleGetAccountId() : sellerId === handleGetAccountId()) ? (
      <>
        <Grid container item xs={12} sm={4}>
          <Typography className={classes.heading} noWrap>
            {`${sellerId === handleGetAccountId() ? 'Seller' : 'Buyer'} Timeout:`}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {sellerId === handleGetAccountId() ?
              sellerSessionExpiredAt && getDateTime(sellerSessionExpiredAt) :
              buyerSessionExpiredAt && getDateTime(buyerSessionExpiredAt)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              minDate={new Date(new Date().getTime() + (15 * 60 * 1000))}
              variant="inline"
              emptyLabel="New Timeout"
              value={timeout}
              onChange={(date) => setTimeout(date)}
              fullWidth
              autoOk
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ButtonSC
            variant="fill"
            size="large"
            text={timeout ? 'Save' : 'Saved'}
            handleClick={() => {
              changeTimer(timeout || '');
              setTimeout(null);
            }}
            color="green"
            disabled={!timeout}
            styleClass={classes.npButton}
          />
        </Grid>
      </>
    ) :
      (
        <>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.heading} noWrap>
              {`${sellerId === handleGetAccountId() ? 'Buyer' : 'Seller'} Timeout:`}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {sellerId === handleGetAccountId() ?
                buyerSessionExpiredAt && getDateTime(buyerSessionExpiredAt) :
                sellerSessionExpiredAt && getDateTime(sellerSessionExpiredAt)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ButtonSC
              variant="outline"
              size="large"
              text={`Accept ${sellerId === handleGetAccountId() ? 'Buyer' : 'Seller'} Timeout`}
              handleClick={
                () => agreeTimer(sellerId === handleGetAccountId() ?
                  buyerSessionExpiredAt : sellerSessionExpiredAt)
              }
              color="green"
              styleClass={classes.acceptTimeout}
              disabled={buyerSessionExpiredAt === sellerSessionExpiredAt}
            />
          </Grid>
        </>
      )
  );

  return (
    <Accordion className={classes.expanationPanelTimeout}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.AccordionSummary}
        disabled={agree}
      >
        <Typography variant="h2">
          Change Timeout
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionDetails}>
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={6} direction="row" justifyContent="space-between" spacing={2}>
            {renderFields('valueBuyer')}
          </Grid>
          <Grid container item xs={12} sm={6} spacing={2}>
            {renderFields('valueSeller')}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ChangeTimeout;
