import React, { FC, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminPage from '../Admin/AdminPage';
import ClientPage from '../Client/ClientPage';
import BrokerPage from '../Broker/BrokerPage';
import UserPage from '../User/UserPage';
import { RootState } from '../../store/slices';
import { getRole } from '../../utils/getRole';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import ActivateAccountPage from '../Signup/ActivateAccountPage';

export const Protected: FC = () => {
  const {
    authError,
    isLoading,
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  const history = useHistory()
  const location = useLocation();
  
  useEffect(() => {
    if (authError && !isLoading) {
      history.push(`/signin?prev_page=${encodeURIComponent(location.pathname)}`)
        // <Redirect 
        //   to={{
        //     pathname: '/signin',
        //     search: `?prev_page=${encodeURIComponent(location.pathname)}`,
        //   }}
        // />
    }
  }, [authError])

  return (
    <>
      <Switch>
        {(getRole(userRole) === 'Admin') && <Route path="/" component={AdminPage} />}
        {(getRole(userRole) === 'Broker') && <Route path="/" component={BrokerPage} />}
        {(getRole(userRole) === 'Seller' || getRole(userRole) === 'Buyer' || getRole(userRole) === 'Seller & Buyer') && <Route path="/" component={ClientPage} />}
        {(getRole(userRole) === 'Guest') && <Route path="/" component={UserPage} />}
        {(getRole(userRole) === 'Unconfirmed') && <Route path="/" component={ActivateAccountPage} />}
      </Switch>
      {isLoading && <LoadingPage />}
    </>
  );
};

export default Protected;
