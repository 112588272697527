// import { makeStyles } from '@material-ui/core';

// const useStyles = makeStyles({
//   searchWidth: {
//     width: '320px',
//     paddingLeft: 0,
//     background: 'red',
//     '@media (min-width: 560px)' : {
//       width: '150px',
//       paddingLeft: 0,
//     }
//   },
// });

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  root: {
    width: '320px',
    paddingLeft: 0,
    background: 'red',
    '@media (min-width: 560px)' : {
      width: '150px',
      paddingLeft: 0,
    },
  },
});

export default useStyles;
