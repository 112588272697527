import axios from 'axios';
import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

export const apiGetUserInfo = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getUserInfo}${userId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiUpdateUserInfo = (
  user: FormData,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.updateUserInfo}${userId}`;
  return request(
    url,
    'PUT',
    user,
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
};

export const apiSendEmailChangeUserPassword = async (
  email: string,
) => {
  const url = `${constants.BASE_URL}${api.sendEmailChangePassword}`;
  const { data } = await axios.post(
    url,
    {
      email,
    },
  );
  return data;
};

export const apiChangeUserPassword = async (
  password: string,
  token: string,
) => {
  const url = `${constants.BASE_URL}${api.changePassword}`;
  const { data } = await axios.post(
    url,
    {
      password,
      token,
    },
  );
  return data;
};

// export const apiChangeUserRoleInfo = (
//   isBuyer: boolean,
//   isSeller: boolean,
//   userId: number | null,
//   brokerAccountId: number | null,
//   token: {
//     accessToken: string | null;
//     tokenExp: string | null;
//     refreshToken: string | null;
//   },
// ) => {
//   const url = `${constants.BASE_URL}${api.updateUserRole}${userId}`;
//   return request(
//     url,
//     'POST',
//     {
//       brokerAccountId,
//       isBuyer,
//       isSeller,
//     },
//     token,
//     { Authorization: `Bearer ${token.accessToken}` },
//   );
//   // const { data } = await axios.post(
//   //   url,
//   //   {
//   //     brokerAccountId,
//   //     isBuyer,
//   //     isSeller,
//   //   },
//   //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
//   // );
//   // return data;
// };
