import React,
{
  FC,
  useEffect,
  useState,
  useCallback,
} from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import {
  Paper,
  Grid,
  Hidden,
  Dialog,
  IconButton,
  Avatar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './DealRoom.style';
import { UserRole } from '../../interfaces/user.interface';
import { leftTheDeal } from '../../store/slices/deals.slice';
import { getLeftTime } from '../../utils/getDate';
import getLocalStorageData from '../../api/localStorage';
import ButtonSC from '../ButtonSC/ButtonSC';
import { getRole, getBrokerRole } from '../../utils/getRole';
import constants from '../../core/constants';
import { useHistory } from 'react-router';

interface Props {
  userRole: UserRole;
  status: string;
  dealRoomId: number;
  expireBuyerTime: string | null;
  expireSellerTime: string | null;
  sellerAgree: number | null;
  buyerAgree: number | null;
  seller: {
    id: number;
    name: string;
    userName: string;
    email: string;
    profilePhotoLink: string | null;
  };
  buyer: {
    id: number;
    name: string;
    userName: string;
    email: string;
    profilePhotoLink: string | null;
  };
  sellerBroker: {
    id: number;
    name: string;
    userName: string;
    email: string;
    profilePhotoLink: string | null;
  };
  buyerBroker: {
    id: number;
    name: string;
    userName: string;
    email: string;
    profilePhotoLink: string | null;
  };
  // eslint-disable-next-line react/require-default-props
  orderNum?: string;
  deleteOrderInventory: ()=>void;
}

const DealInfo: FC<Props> = ({
  userRole,
  status,
  dealRoomId,
  expireBuyerTime,
  expireSellerTime,
  sellerAgree,
  buyerAgree,
  seller,
  buyer,
  sellerBroker,
  buyerBroker,
  orderNum,
  deleteOrderInventory,
}) => {
  const [sellerTime, setSellerTime] = useState<string | null>('');
  const [buyerTime, setBuyerTime] = useState<string | null>('');

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === 'REJECTED' && getRole(userRole) !== 'Admin') {
      history.push('/')
    };
    const sellerTimer = setInterval(() => {
      if (expireSellerTime && (status === 'STARTED' || status === 'IN PROGRESS') && !sellerAgree) {
        setSellerTime(getLeftTime(expireSellerTime, new Date()));
      } else {
        clearInterval(sellerTimer);
      }
    }, 1000);
    const buyerTimer = setInterval(() => {
      if (expireBuyerTime && (status === 'STARTED' || status === 'IN PROGRESS') && !buyerAgree) {
        setBuyerTime(getLeftTime(expireBuyerTime, new Date()));
      } else {
        clearInterval(buyerTimer);
      }
    }, 1000);
    return () => {
      clearInterval(sellerTimer);
      clearInterval(buyerTimer);
    };
  }, [
    expireSellerTime,
    setSellerTime,
    expireBuyerTime,
    setBuyerTime,
    sellerAgree,
    buyerAgree,
    status,
  ]);

  const history = useHistory();
  const [openFullSizeImageDialog, setOpenFullSizeImageDialog] = useState(false);
  const [openImageSrc, setOpenImageSrc] = useState('');

  const handleOpenFullSizeImageDialog = async (imgSrc: any) => {
    setOpenFullSizeImageDialog(true);
    setOpenImageSrc(imgSrc);
  };

  const handleLeaveTheDeal = useCallback(
    () => {
      const { accountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      dispatch(leftTheDeal(dealRoomId, accountId, token, key));
      deleteOrderInventory();
      history.push('/');
    },
    [dispatch, dealRoomId],
  );

  const handleGetRole = useCallback(
    () => {
      const { clientAccountId, accountId } = getLocalStorageData();
      const role = getRole(userRole) === 'Broker' ?
        getBrokerRole(userRole, localStorage.getItem('clientAccountId')) :
        getRole(userRole);
      if (role === 'Seller & Buyer' && (clientAccountId || accountId) === seller.id) {
        return 'Seller';
      }
      if (role === 'Seller & Buyer' && (clientAccountId || accountId) === buyer.id) {
        return 'Buyer';
      }
      return role;
    },
    [userRole, seller, buyer],
  );

  return (
    <Paper className={classes.dealPaper}>
      <Grid container>
        <Grid container item xs={12} sm={8} lg={12} spacing={2}>
          <Hidden lgUp>
            <Grid container item>
              <Grid item xs={4}>
                <span className={cx(classes.heading, classes.headingLarge)}>Status:</span>
              </Grid>
              <Grid item xs={8}>
                <span className={cx(classes.secondaryHeading, classes.headingLarge)}>{status}</span>
              </Grid>
            </Grid>
          </Hidden>
          <Grid container item xs={12} lg={1} direction="column" justifyContent="space-between">
            {seller && (
              <Avatar
                onClick={() => {
                  if (buyer?.profilePhotoLink) {
                    handleOpenFullSizeImageDialog(
                      `${constants.AWS_URL}${buyer?.profilePhotoLink}`,
                    );
                  }
                }}
                variant="rounded"
                alt="Avatar"
                className={classes.picture}
                src={(buyer && `${constants.AWS_URL}${buyer?.profilePhotoLink}`)}
              />
            )}
          </Grid>
          <Grid container item xs={12} lg={3} direction="column" justifyContent="space-between">
            <Grid container item className={classes.marginBottom16}>
              <Grid item xs={4}>
                <span className={cx(classes.heading, classes.headingLarge)}>Buyer:</span>
              </Grid>
              <Grid item xs={8}>
                <span className={cx(classes.secondaryHeading, classes.headingLarge)}>
                  {buyer.userName}
                </span>
                {handleGetRole() === 'Buyer' ?
                  (buyerBroker && <span>{' (Represented by a broker)'}</span>) ||
                  <span>{' (Self)'}</span>
                  :
                  <span>{' (Counterparty)'}</span>}
              </Grid>
            </Grid>
            <Grid container item className={classes.marginBottom16}>
              <Grid item xs={4}>
                <span className={cx(classes.heading)}>Broker:</span>
              </Grid>
              <Grid item xs={8}>
                <span className={cx(classes.secondaryHeading)}>{buyerBroker ? buyerBroker.userName : '-'}</span>
              </Grid>
            </Grid>
            {status !== 'TIMEOUT' && (
              <Grid container item>
                <Grid item xs={4}>
                  {buyerAgree ? (
                    <span className={cx(classes.heading)}>Buyer:</span>
                  ) :
                    (
                      <span className={cx(classes.heading)}>Time left:</span>
                    )}
                </Grid>
                <Grid item xs={8}>
                  {buyerAgree ? (
                    <span className={cx(classes.secondaryHeading)}>AGREED</span>
                  ) :
                    (
                      <span className={cx(classes.secondaryHeading)}>{buyerTime}</span>
                    )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Hidden mdDown>
            <Grid container item xs={12} lg={3} direction="column" justifyContent="space-between">
              <Grid container item className={classes.marginBottom16}>
                <Grid item xs={4}>
                  <span className={cx(classes.heading, classes.headingLarge)}>Status:</span>
                </Grid>
                <Grid item xs={8}>
                  <span className={cx(classes.secondaryHeading, classes.headingLarge)}>
                    {status}
                  </span>
                </Grid>
              </Grid>
              {getRole(userRole) !== 'Admin' && (
                <ButtonSC
                  variant="fill"
                  size="small"
                  text="Reject the Deal"
                  handleClick={() => handleLeaveTheDeal()}
                  color="green"
                  styleClass={classes.leaveButton}
                  disabled={status === 'POST TRADE'}
                />
              )}
            </Grid>
          </Hidden>

          {/* Added OrderNum--------------------------------*/}
          <Grid container xs={12} lg={1} item direction="column" justifyContent="space-between">
            <Grid container item className={classes.marginBottom16}>
              <Grid item xs={12}>
                <span className={cx(classes.heading, classes.headingLarge)}>Order:</span>
              </Grid>
              <Grid item xs={12}>
                <span className={cx(classes.secondaryHeading, classes.headingLarge)}>
                  #{orderNum}
                </span>
              </Grid>
            </Grid>
          </Grid>

          {/* 1 */}
          <Grid container item xs={12} lg={1} direction="column" justifyContent="space-between">
            {seller && (
              <Avatar
                onClick={() => {
                  if (seller?.profilePhotoLink) {
                    handleOpenFullSizeImageDialog(
                      `${constants.AWS_URL}${seller?.profilePhotoLink}`,
                    );
                  }
                }}
                variant="rounded"
                alt="Avatar"
                className={classes.picture}
                src={(seller && `${constants.AWS_URL}${seller?.profilePhotoLink}`)}
              />
            )}
          </Grid>
          <Grid container item xs={12} lg={3} direction="column" justifyContent="space-between">
            <Grid container item className={classes.marginBottom16}>
              <Grid item xs={4}>
                <span className={cx(classes.heading, classes.headingLarge)}>Seller:</span>
              </Grid>
              <Grid item xs={8}>
                <span className={cx(classes.secondaryHeading, classes.headingLarge)}>
                  {seller.userName}
                </span>
                {handleGetRole() === 'Seller' ?
                  (sellerBroker && <span>{' (Represented by a broker)'}</span>) ||
                  <span>{' (Self)'}</span>
                  :
                  <span>{' (Counterparty)'}</span>}
              </Grid>
            </Grid>
            <Grid container item className={classes.marginBottom16}>
              <Grid item xs={4}>
                <span className={cx(classes.heading)}>Broker:</span>
              </Grid>
              <Grid item xs={8}>
                <span className={cx(classes.secondaryHeading)}>{sellerBroker ? sellerBroker.userName : '-'}</span>
              </Grid>
            </Grid>
            {status !== 'TIMEOUT' && (
              <Grid container item>
                <Grid item xs={4}>
                  {sellerAgree ? (
                    <span className={cx(classes.heading)}>Seller:</span>
                  ) :
                    (
                      <span className={cx(classes.heading)}>Time left:</span>
                    )}
                </Grid>
                <Grid item xs={8}>
                  {sellerAgree ? (
                    <span className={cx(classes.secondaryHeading)}>AGREED</span>
                  ) :
                    (
                      <span className={cx(classes.secondaryHeading)}>{buyerTime}</span>
                    )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid container item xs={12} sm={4} justifyContent="flex-end" alignContent="flex-end" alignItems="flex-end">
            {getRole(userRole) !== 'Admin' && (
              <ButtonSC
                variant="fill"
                size="large"
                text="Reject the Deal"
                handleClick={() => handleLeaveTheDeal()}
                color="green"
                styleClass={classes.leaveButton}
                disabled={status === 'POST TRADE'}
              />
            )}
          </Grid>
        </Hidden>
      </Grid>

      {/* dialog with fullSize image */}
      <Dialog
        style={{ minWidth: 'auto' }}
        open={openFullSizeImageDialog}
        onClose={() => setOpenFullSizeImageDialog(false)}
        scroll="paper"
        classes={{ paper: classes.dialogPaperFullSizeImage }}
      >

        <IconButton aria-label="close" onClick={() => setOpenFullSizeImageDialog(false)} className={classes.closeModalIcon}>
          <CloseIcon />
        </IconButton>

        <img className={classes.fullSizeImageInsideDialog} src={openImageSrc} alt="" />

      </Dialog>

    </Paper>
  );
};

export default DealInfo;
