/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Input,
  Hidden,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";

import OrderCard from "../Order/OrderCard";
import OrderCardToSell from "../Order/OrderCardToSell";
import { RootState } from "../../store/slices";
import {
  getAllOrders,
  getAllOrdersByAccountId,
  getAllOrdersForeGuest,
} from "../../store/slices/order.slice";
import { getInventoryTypes } from "../../store/slices/inventoryTypes.slice";
import { OrderMP } from "../../interfaces/order.interface";
import useStyles from "./MarketPlacePage.style";
import getLocalStorageData from "../../api/localStorage";
import NoRoleWarning from "../NoRoleWarning/NoRoleWarning";
import { getRole } from "../../utils/getRole";
import { getDateTime } from "../../utils/getDate";
import LoadingPage from "../LoadingPage/LoadingPage";
import { InventoryItemTypeField } from "../../interfaces/inventoryTypes.interface";
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import {
  updateUserProfile,
  getUserProfile,
} from "../../store/slices/userUpdate.slice";
import { apiGetUserInfo } from "../../api/updateUserInfo";

import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { getAllSellOrders } from "../../store/slices/orderSell.slice";
import { inventoryItemType } from "../../mockDataForTest";

const MarketPlace = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { listOfInventoryTypes } = useSelector(
    (state: RootState) => state.inventoryTypes
  );

  const [typeInventory, setTypeInventory] = useState<string>();

  const [inventoryTypeId, setInventoryTypeId] = useState<number>(1);

  useEffect(() => {
    if (listOfInventoryTypes.length !== 0 && inventoryTypeId) {
      setInventoryTypeId(listOfInventoryTypes[0].id);
    }
  }, [listOfInventoryTypes]);

  const [typeZipState, setTypeZipState] = useState<string>("State");
  const [typeState, setTypeState] = useState<string>("");
  const [typeStateSell, setTypeStateSell] = useState<string>();
  const [unitInventory, setUnitInventory] = useState<string>();

  const [priceSrotArrow, setPriceSortArrow] = useState<string>("none");
  const [amountSrotArrow, setAmountSrotArrow] = useState<string>("none");
  const [priceSrotArrowSell, setPriceSortArrowSell] = useState<string>("none");
  const [amountSrotArrowSell, setAmountSrotArrowSell] =
    useState<string>("none");

  const [sort, setSort] = useState<string>();
  const [order, setOrder] = useState<string>("DESC");
  const [limit, setLimit] = useState<number>(60);
  const [offset, setOffset] = useState<number>(0);
  const [type, setType] = useState<string>("BUY");
  const [filterState, setFilterState] = useState<string>("All states");

  const [sortSell, setSortSell] = useState<string>();
  const [orderSell, setOrderSell] = useState<string>("DESC");
  const [typeSell, setTypeSell] = useState<string>("SELL");
  const [filterStateSell, setFilterStateSell] = useState<string>("All states");

  const [
    dynamicColumnSelectedValueSellOrders,
    setDynamicColumnSelectedValueSellOrders,
  ] = useState<string>("totalPrice");

  const [
    dynamicColumnSelectedValueBuyOrders,
    setDynamicColumnSelectedValueBuyOrders,
  ] = useState<string>("totalPrice");

  const [
    dynamicColumnSelectedValueZIPorState,
    setDynamicColumnSelectedValueZIPorState,
  ] = useState<string>("totalPrice");

  const [selectedSingleInventoryType, setSelectedSingleInventoryType] =
    useState<Array<InventoryItemTypeField>>([]);

  const [selectedSingleZipCodeorState, setSelectedSingleZipCodeorState] =
    useState<any>([]);

  const [dynamicFieldVariants] = useState<Array<any>>(
    selectedSingleInventoryType && [
      ...selectedSingleInventoryType,
      "Total Price",
    ]
  );
  // const [dynamicFieldVariantsZip] = useState<Array<any>>([...selectedSingleZipCodeorState, 'ZIP']);

  const [buyOrders, setBuyOrders] = useState<any>([]);
  const [sellOrders, setSellOrders] = useState<Array<OrderMP>>([]);
  const [tabValue, setTabValue] = useState(0);
  const { listOfOrders, isLoadingOrders } = useSelector(
    (state: RootState) => state.order
  );

  const { listOfOrdersSell, isLoadingOrdersSell } = useSelector(
    (state: RootState) => state.orderSell
  );

  console.log("isLoadingOrders :>> ", isLoadingOrders);
  console.log("isLoadingOrdersSell :>> ", isLoadingOrdersSell);

  const { isLoading } = useSelector((state: RootState) => state.deals);

  const { userId: userIdState, userRole } = useSelector(
    (state: RootState) => state.auth
  );

  const { userId, token } = getLocalStorageData();

  useEffect(() => {
    if (userIdState) {
      dispatch(getInventoryTypes(userId, new Date().getTime() + Math.random()));
      dispatch(
        getAllSellOrders(
          false,
          token,
          new Date().getTime() + Math.random(),
          inventoryTypeId,
          sortSell,
          orderSell,
          limit,
          offset,
          typeSell,
          filterStateSell
        )
      );
      dispatch(
        getAllOrders(
          false,
          token,
          new Date().getTime() + Math.random(),
          inventoryTypeId,
          sort,
          order,
          limit,
          offset,
          type,
          filterState
        )
      );
    }
  }, [dispatch, userIdState]);

  useEffect(() => {
    if (listOfInventoryTypes.length !== 0) {
      const type = listOfInventoryTypes[0];
      setTypeInventory(type.type);
      setUnitInventory(type.units);
      setSelectedSingleInventoryType(type.inventoryItemTypeFieldsTypes);
    }
  }, [dispatch, listOfInventoryTypes]);

  const handleChangeState = (value: any) => {
    setFilterState(value);
  };

  const handleChangeStateSell = (value: any) => {
    setTypeSell("SELL");
    setFilterStateSell(value);
  };

  useEffect(() => {
    const slicelistOfOrders = listOfOrders.slice();
    const buyOrder = slicelistOfOrders.filter(
      (order: OrderMP) =>
        order.orderStatus === "OPEN" &&
        order.orderType === "BUY" &&
        (typeInventory !== "ALL"
          ? order.inventory &&
            typeInventory === order.inventory.inventoryItemType.type
          : true)
    );
    setBuyOrders(buyOrder);
  }, [listOfOrders, typeInventory, setBuyOrders]);

  useEffect(() => {
    const slicelistOfOrdersSell = listOfOrdersSell.slice();
    const sellOrder = slicelistOfOrdersSell.filter(
      (order: OrderMP) =>
        order.orderStatus === "OPEN" &&
        order.orderType === "SELL" &&
        (typeInventory !== "ALL"
          ? order.inventory &&
            typeInventory === order.inventory.inventoryItemType.type
          : true)
    );
    setSellOrders(sellOrder);
  }, [listOfOrdersSell, typeInventory, setSellOrders]);

  useEffect(() => {
    dispatch(
      getAllOrders(
        false,
        token,
        new Date().getTime() + Math.random(),
        inventoryTypeId,
        sort,
        order,
        limit,
        offset,
        type,
        filterState
      )
    );
  }, [order, sort, filterState]);

  useEffect(() => {
    dispatch(
      getAllSellOrders(
        false,
        token,
        new Date().getTime() + Math.random(),
        inventoryTypeId,
        sortSell,
        orderSell,
        limit,
        offset,
        typeSell,
        filterStateSell
      )
    );
  }, [orderSell, sortSell, filterStateSell]);

  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }
  const zipArr: any = [];
  let filterZipArr: any = [];
  const stateArr: any = [];
  let filterStateArr: any = [];

  const zipArrSell: any = [];
  let filterZipArrSell: any = [];
  const stateArrSell: any = [];
  let filterStateArrSell: any = [];

  async function createZipAndStateList() {
    await (listOfOrders &&
      buyOrders.map((item: any) => {
        // zipArr.push(item.inventory?.locationZip)
        // zipArr.push(item.account?.address?.zip);
        zipArr.push(item?.orderZip);
        filterZipArr = zipArr.filter(onlyUnique);

        // stateArr?.push(item.inventory?.locationState)
        // stateArr?.push(item.account?.address?.state)
        stateArr?.push(item?.orderState);

        filterStateArr = stateArr.filter(onlyUnique);
        filterStateArr.sort();
        filterStateArr.unshift("All states");
      }),
    listOfOrdersSell &&
      sellOrders.map((it: any) => {
        // zipArrSell.push(it.inventory?.locationZip);
        zipArrSell.push(it?.orderZip);
        filterZipArrSell = zipArrSell.filter(onlyUnique);
        // stateArrSell?.push(it.inventory?.locationState);
        stateArrSell?.push(it?.orderState);
        filterStateArrSell = stateArrSell.filter(onlyUnique);
        filterStateArrSell.sort();
        filterStateArrSell.unshift("All states");
      }));
  }

  createZipAndStateList();

  const sortingTable = (typeTable: string) => {
    if (typeTable === "priceSort") {
      if (priceSrotArrow == "up") {
        setPriceSortArrow("down");
        setSort("unitPrice");
        setOrder("DESC");
        setType("BUY");
      } else {
        setPriceSortArrow("up");
        setSort("unitPrice");
        setOrder("ASC");
        setType("BUY");
      }
    } else if (typeTable === "amountSort") {
      if (amountSrotArrow == "up") {
        setAmountSrotArrow("down");
        setSort("quantity");
        setOrder("DESC");
        setType("BUY");
      } else {
        setAmountSrotArrow("up");
        setSort("quantity");
        setOrder("ASC");
        setType("BUY");
      }
    } else if (typeTable === "priceSortSell") {
      if (priceSrotArrowSell == "up") {
        setPriceSortArrowSell("down");
        setSortSell("unitPrice");
        setOrderSell("DESC");
        setTypeSell("SELL");
      } else {
        setPriceSortArrowSell("up");
        setSortSell("unitPrice");
        setOrderSell("ASC");
        setTypeSell("SELL");
      }
    } else if (typeTable === "amountSortSell") {
      if (amountSrotArrowSell == "up") {
        setAmountSrotArrowSell("down");
        setSortSell("quantity");
        setOrderSell("DESC");
        setType("SELL");
      } else {
        setAmountSrotArrowSell("up");
        setSortSell("quantity");
        setOrderSell("ASC");
        setTypeSell("SELL");
      }
    }
  };
  const defaultRequestForAllOrders = (inventoryTypeID: number) => {
    setFilterState("All states");
    setFilterStateSell("All states");
    dispatch(
      getAllOrders(
        false,
        token,
        new Date().getTime() + Math.random(),
        inventoryTypeID,
        sort,
        order,
        limit,
        offset,
        type,
        filterState
      )
    );
    dispatch(
      getAllSellOrders(
        false,
        token,
        new Date().getTime() + Math.random(),
        inventoryTypeID,
        sortSell,
        orderSell,
        limit,
        offset,
        typeSell,
        filterStateSell
      )
    );
  };

  const handleChangeType = useCallback(
    (value) => {
      if (listOfInventoryTypes.length !== 0) {
        const type = listOfInventoryTypes.filter(
          (item) => item.type === value
        )[0];
        setInventoryTypeId(type.id);
        setTypeInventory(type.type);
        setUnitInventory(type.units);
        setSelectedSingleInventoryType(type.inventoryItemTypeFieldsTypes);
        setDynamicColumnSelectedValueSellOrders("totalPrice");
        setDynamicColumnSelectedValueBuyOrders("totalPrice");
        defaultRequestForAllOrders(type.id);
      }
    },
    [setTypeInventory, listOfInventoryTypes]
  );

  // change Flexible (dynamic) column
  // sell orders
  const handleChangeDynamicColumnValueSellOrders = (value: any) => {
    if (value === "totalPrice") {
      setDynamicColumnSelectedValueSellOrders("totalPrice");
    } else {
      if (dynamicFieldVariants.length !== 0) {
        setDynamicColumnSelectedValueSellOrders(value);
      }
    }
  };

  // buy orders
  const handleChangeDynamicColumnValueBuyOrders = (value: any) => {
    if (value === "totalPrice") {
      setDynamicColumnSelectedValueBuyOrders("totalPrice");
    } else {
      if (dynamicFieldVariants.length !== 0) {
        setDynamicColumnSelectedValueBuyOrders(value);
      }
    }
  };

  // sell orders
  const getDataForDynamicFieldValueSellOrders = (order: OrderMP) => {
    if (dynamicColumnSelectedValueSellOrders === "totalPrice") {
      return "totalPrice";
    } else {
      if (
        order.inventory &&
        order.inventory.inventoryItemSavedFields &&
        dynamicColumnSelectedValueSellOrders &&
        order.inventory.inventoryItemSavedFields.length > 0
      ) {
        const orderSavedFields = order.inventory.inventoryItemSavedFields.find(
          (element: any) =>
            element.name === dynamicColumnSelectedValueSellOrders
        );
        return orderSavedFields || "---";
      } else {
        return null;
      }
    }
  };

  // buy orders
  const getDataForDynamicFieldValueBuyOrders = (order: OrderMP) => {
    if (dynamicColumnSelectedValueBuyOrders === "totalPrice") {
      return "totalPrice";
    } else {
      if (
        order.orderSavedFields &&
        dynamicColumnSelectedValueBuyOrders &&
        order.orderSavedFields.length > 0
      ) {
        const orderSavedFields = order.orderSavedFields.find(
          (element: any) => element.name === dynamicColumnSelectedValueBuyOrders
        );
        return orderSavedFields || "---";
      } else {
        return null;
      }
    }
  };

  const handleReturnDynamicFieldName = (item: any) => {
    if (item.typeOfField !== "DOCUMENTS") {
      return (
        <MenuItem key={item.name} value={item.name}>
          {(item.format === "PERCENT" &&
            `${item.name.replace(/\/,/g, ".")} %`) ||
            (item.format === "DOLLAR" &&
              `${item.name.replace(/\/,/g, ".")} $`) ||
            item.name.replace(/\/,/g, ".")}
        </MenuItem>
      );
    }
  };

  return (
    <Paper className={classes.paper}>
      {listOfInventoryTypes.length !== 0 && typeInventory && (
        <Grid container direction="column" className={classes.root}>
          <Grid
            container
            item
            justifyContent="space-between"
            className={cx(classes.paddingBottom16, classes.titlePanel)}
          >
            <Hidden smDown>
              <Grid item xs={4} className={classes.title}>
                <Typography variant="h1">Bids to Buy</Typography>
              </Grid>
            </Hidden>
            {/* mobile choice bid/ask */}
            <Hidden mdUp>
              <Grid item xs={6}>
                <Tabs
                  value={tabValue}
                  onChange={(event, newValue) => setTabValue(newValue)}
                >
                  <Tab
                    label="Bids to Buy"
                    classes={{
                      root: classes.rootTab,
                    }}
                  />
                  <Tab
                    label="Offers to Sell"
                    classes={{
                      root: classes.rootTab,
                    }}
                  />
                </Tabs>
              </Grid>
            </Hidden>
            <Grid item xs={5} sm={4}>
              <Select
                name="type"
                id="type"
                value={typeInventory}
                onChange={({ target }) => handleChangeType(target.value)}
                fullWidth
                input={
                  <Input
                    classes={{
                      underline: classes.underline,
                    }}
                  />
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {listOfInventoryTypes.map((item) => (
                  <MenuItem key={item.type} value={item.type}>
                    {item.type}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Hidden smDown>
              <Grid item xs={4} className={classes.title}>
                <Typography variant="h1">Offers to Sell</Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Hidden smDown>
            <Grid
              container
              item
              justifyContent="space-between"
              className={classes.container}
            >
              {/* //Bids To Buy */}
              <Grid
                container
                item
                md={6}
                alignContent="flex-start"
                className={classes.ordersContainerRight}
              >
                {buyOrders.length !== 0 ? (
                  <>
                    <Grid
                      xs={12}
                      container
                      item
                      className={cx(classes.titleCard, classes.card)}
                    >
                      <Grid
                        container
                        item
                        xs={1}
                        className={classes.cardItems}
                        alignContent="center"
                      >
                        <Grid item className={classes.titleItem}>
                          Action
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={11}
                        className={classes.cardItems}
                        alignContent="center"
                      >
                        <Grid item xs={1} className={classes.titleItem}>
                          Q
                        </Grid>
                        {/* <Grid item xs={2} className={classes.titleItem}>State/Zip</Grid> */}
                        <Grid item xs={2} className={classes.titleItem}>
                          <Select
                            classes={{
                              select: classes.paddingSelect0,
                            }}
                            name="type"
                            id="type"
                            value={filterState}
                            onChange={({ target }) =>
                              handleChangeState(target.value)
                            }
                            fullWidth
                            input={
                              <Input
                                classes={{
                                  underline: classes.underline,
                                }}
                              />
                            }
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {/* <MenuItem key='allZip' value='allZip'>State</MenuItem> */}
                            {filterStateArr?.map((item: any) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={2} className={classes.titleItem}>
                          Info
                        </Grid>
                        <Grid item xs={2} className={classes.titleItem}>
                          {priceSrotArrow == "none" && (
                            <CompareArrowsIcon
                              className={classes.rowSort}
                              onClick={() => sortingTable("priceSort")}
                            />
                          )}
                          {priceSrotArrow == "down" && (
                            <ArrowUpwardIcon
                              className={classes.rowSortRotate}
                              onClick={() => sortingTable("priceSort")}
                            />
                          )}
                          {priceSrotArrow == "up" && (
                            <ArrowUpwardIcon
                              className={classes.rowSort}
                              onClick={() => sortingTable("priceSort")}
                            />
                          )}
                          Price $
                        </Grid>
                        <Grid item xs={2} className={classes.titleItem}>
                          {/* TODO: del lb */}
                          {amountSrotArrow == "none" && (
                            <CompareArrowsIcon
                              className={classes.rowSort}
                              onClick={() => sortingTable("amountSort")}
                            />
                          )}
                          {amountSrotArrow == "down" && (
                            <ArrowUpwardIcon
                              className={classes.rowSortRotate}
                              onClick={() => sortingTable("amountSort")}
                            />
                          )}
                          {amountSrotArrow == "up" && (
                            <ArrowUpwardIcon
                              className={classes.rowSort}
                              onClick={() => sortingTable("amountSort")}
                            />
                          )}
                          {`Amount ${unitInventory || "lb"}`}
                        </Grid>
                        <Grid item xs={3} className={classes.titleItem}>
                          {/* select for dynamic field instead of total price */}
                          <Select
                            classes={{
                              select: classes.paddingSelect0,
                            }}
                            name="type"
                            id="type"
                            value={dynamicColumnSelectedValueBuyOrders}
                            onChange={({ target }) =>
                              handleChangeDynamicColumnValueBuyOrders(
                                target.value
                              )
                            }
                            fullWidth
                            input={
                              <Input
                                classes={{
                                  underline: classes.underline,
                                }}
                              />
                            }
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem key="totalPrice" value="totalPrice">
                              Total Price
                            </MenuItem>
                            {selectedSingleInventoryType?.map((item) =>
                              handleReturnDynamicFieldName(item)
                            )}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.scrollOrdersContainer}>
                      {buyOrders.map((order: OrderMP) => (
                        <OrderCard
                          id={order.id}
                          key={order.createdAt}
                          inventory={order.inventory}
                          dynamicFieldValue={getDataForDynamicFieldValueBuyOrders(
                            order
                          )}
                          price={order.price}
                          quantity={order.quantity}
                          unitPrice={order.unitPrice}
                          btnLabel="Sell"
                          ownerAccountId={order.ownerAccountId}
                          isLoading={isLoading}
                          orderBuyFields={order.orderSavedFields}
                          type="sell"
                          state={
                            order?.orderState
                              ? order?.orderState
                              : order?.account?.address?.state
                          }
                          zipCode={
                            order?.orderZip
                              ? order?.orderZip
                              : order?.account?.address?.zip
                          }
                          // state={order?.inventory?.locationState}
                          // zipCode={order?.inventory?.locationZip}
                          uniqueNum={order.uniqueNum}
                          orderNumber={order?.orderNumber}
                          isForwardType={order.isForwardType}
                          forwardInventory={order.forwardInventory} 
                        />
                      ))}
                    </Grid>
                  </>
                ) : (
                  <Grid item sm={12}>
                    <Typography
                      variant="h1"
                      align="center"
                      color="secondary"
                      className={classes.marginTop32}
                    >
                      {isLoadingOrders ? "No orders" : "Loading..."}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {/* //Offers To sell */}
              <Grid
                container
                item
                xs={12}
                md={6}
                alignContent="flex-start"
                className={classes.ordersContainerLeft}
              >
                {sellOrders.length !== 0 ? (
                  <>
                    <Grid
                      xs={12}
                      container
                      item
                      className={cx(classes.titleCard, classes.card)}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.cardItemsSell}
                      >
                        <Grid item xs={2} className={classes.titleItem}>
                          {/* select for dynamic field instead of total price */}
                          <Select
                            classes={{
                              select: classes.paddingSelect0,
                            }}
                            name="type"
                            id="type"
                            value={dynamicColumnSelectedValueSellOrders}
                            onChange={({ target }) =>
                              handleChangeDynamicColumnValueSellOrders(
                                target.value
                              )
                            }
                            fullWidth
                            input={
                              <Input
                                classes={{
                                  underline: classes.underline,
                                }}
                              />
                            }
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem key="totalPrice" value="totalPrice">
                              Total Price
                            </MenuItem>
                            {selectedSingleInventoryType?.map((item) =>
                              handleReturnDynamicFieldName(item)
                            )}
                          </Select>
                        </Grid>

                        <Grid item xs={2} className={classes.titleItem}>
                          {amountSrotArrowSell == "none" && (
                            <CompareArrowsIcon
                              className={classes.rowSort}
                              onClick={() => sortingTable("amountSortSell")}
                            />
                          )}
                          {amountSrotArrowSell == "down" && (
                            <ArrowUpwardIcon
                              className={classes.rowSortRotate}
                              onClick={() => sortingTable("amountSortSell")}
                            />
                          )}
                          {amountSrotArrowSell == "up" && (
                            <ArrowUpwardIcon
                              className={classes.rowSort}
                              onClick={() => sortingTable("amountSortSell")}
                            />
                          )}
                          {`Amount ${unitInventory || "lb"}`}
                        </Grid>

                        <Grid item xs={2} className={classes.titleItem}>
                          {priceSrotArrowSell == "none" && (
                            <CompareArrowsIcon
                              className={classes.rowSort}
                              onClick={() => sortingTable("priceSortSell")}
                            />
                          )}
                          {priceSrotArrowSell == "down" && (
                            <ArrowUpwardIcon
                              className={classes.rowSortRotate}
                              onClick={() => sortingTable("priceSortSell")}
                            />
                          )}
                          {priceSrotArrowSell == "up" && (
                            <ArrowUpwardIcon
                              className={classes.rowSort}
                              onClick={() => sortingTable("priceSortSell")}
                            />
                          )}
                          Price $
                        </Grid>

                        <Grid item xs={1} className={classes.titleItem}>
                          Info
                        </Grid>

                        <Grid item xs={2} className={classes.titleItem}>
                          <Select
                            classes={{
                              select: classes.paddingSelect0,
                            }}
                            name="typeSell"
                            id="typeSell"
                            value={filterStateSell}
                            onChange={({ target }) =>
                              handleChangeStateSell(target.value)
                            }
                            fullWidth
                            input={
                              <Input
                                classes={{
                                  underline: classes.underline,
                                }}
                              />
                            }
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {/* <MenuItem key="allZip" value="allZip">State</MenuItem> */}
                            {filterStateArrSell?.map((item: any) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>

                        <Grid item xs={1} className={classes.titleItem}>
                          Q
                        </Grid>

                        <Grid
                          container
                          item
                          xs={1}
                          className={classes.cardItems}
                          alignContent="center"
                        >
                          <Grid item xs={12} className={classes.titleItem}>
                            Action
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.scrollOrdersContainer}>
                      {sellOrders.map((item: OrderMP) => {
                        return (
                          <OrderCardToSell
                            id={item.id}
                            key={item.createdAt}
                            inventory={item.inventory}
                            dynamicFieldValue={getDataForDynamicFieldValueSellOrders(
                              item
                            )}
                            price={item.price}
                            quantity={item.quantity}
                            unitPrice={item.unitPrice}
                            btnLabel="Buy"
                            ownerAccountId={item.ownerAccountId}
                            orderBuyFields={item.orderSavedFields}
                            isLoading={isLoading}
                            type="buy"
                            state={
                              item?.orderState
                                ? item?.orderState
                                : item?.inventory?.locationState
                            }
                            zipCode={
                              item?.orderZip
                                ? item?.orderZip
                                : item?.inventory?.locationZip
                            }
                            orderNumber={item?.orderNumber}
                            userOrderID={item.account.id}
                          />
                        );
                      })}
                    </Grid>
                  </>
                ) : (
                  <Grid item sm={12}>
                    <Typography
                      variant="h1"
                      align="center"
                      color="secondary"
                      className={classes.marginTop32}
                    >
                      {isLoadingOrdersSell ? "No orders" : "Loading..."}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Hidden>

          {/*  mobile layout-------------------------------------------------------------- */}
          <Hidden mdUp>
            <Grid
              container
              item
              justifyContent="space-between"
              className={classes.container}
            >
              {tabValue === 0 && (
                <Grid
                  container
                  item
                  sm={12}
                  alignContent="flex-start"
                  className={classes.ordersContainerRight}
                >
                  {buyOrders.length !== 0 ? (
                    <>
                      <Grid
                        container
                        xs={12}
                        item
                        className={cx(classes.titleCard, classes.card)}
                      >
                        <Grid
                          container
                          item
                          xs={1}
                          className={classes.cardItems}
                          alignContent="center"
                        >
                          <Grid item xs={12} className={classes.titleItem}>
                            Action
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={11}
                          className={classes.cardItems}
                          alignContent="center"
                        >
                          <Grid
                            item
                            xs={1}
                            md={1}
                            className={classes.titleItem}
                          >
                            Q
                          </Grid>

                          <Grid item xs={2} className={classes.titleItem}>
                            <Select
                              classes={{
                                select: classes.paddingSelect0,
                              }}
                              name="type"
                              id="type"
                              value={filterState}
                              onChange={({ target }) =>
                                handleChangeState(target.value)
                              }
                              fullWidth
                              input={
                                <Input
                                  classes={{
                                    underline: classes.underline,
                                  }}
                                />
                              }
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              {/* <MenuItem key='allZip' value='allZip'>State</MenuItem> */}
                              {filterStateArr?.map((item: any) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>

                          <Grid
                            item
                            xs={2}
                            md={1}
                            className={classes.titleItem}
                          >
                            Info
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={2}
                            className={classes.titleItem}
                          >
                            {priceSrotArrow == "none" && (
                              <CompareArrowsIcon
                                className={classes.rowSort}
                                onClick={() => sortingTable("priceSort")}
                              />
                            )}
                            {priceSrotArrow == "down" && (
                              <ArrowUpwardIcon
                                className={classes.rowSortRotate}
                                onClick={() => sortingTable("priceSort")}
                              />
                            )}
                            {priceSrotArrow == "up" && (
                              <ArrowUpwardIcon
                                className={classes.rowSort}
                                onClick={() => sortingTable("priceSort")}
                              />
                            )}
                            Price $
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={2}
                            className={classes.titleItem}
                          >
                            {amountSrotArrow == "none" && (
                              <CompareArrowsIcon
                                className={classes.rowSort}
                                onClick={() => sortingTable("amountSort")}
                              />
                            )}
                            {amountSrotArrow == "down" && (
                              <ArrowUpwardIcon
                                className={classes.rowSortRotate}
                                onClick={() => sortingTable("amountSort")}
                              />
                            )}
                            {amountSrotArrow == "up" && (
                              <ArrowUpwardIcon
                                className={classes.rowSort}
                                onClick={() => sortingTable("amountSort")}
                              />
                            )}
                            {`Amount ${unitInventory || "lb"}`}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            md={1}
                            className={classes.titleItem}
                          >
                            {/* select for dynamic field instead of total price */}
                            <Select
                              classes={{
                                select: `${classes.selectMobile} ${classes.paddingSelect0}`,
                              }}
                              name="type"
                              id="type"
                              value={dynamicColumnSelectedValueBuyOrders}
                              onChange={({ target }) =>
                                handleChangeDynamicColumnValueBuyOrders(
                                  target.value
                                )
                              }
                              fullWidth
                              input={
                                <Input
                                  classes={{
                                    underline: classes.underline,
                                  }}
                                />
                              }
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              <MenuItem key="totalPrice" value="totalPrice">
                                Total Price
                              </MenuItem>
                              {selectedSingleInventoryType?.map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>

                            {/* Total $ */}
                          </Grid>
                        </Grid>
                      </Grid>
                      {buyOrders.map((order: OrderMP) => (
                        <OrderCard
                          id={order.id}
                          key={order.createdAt}
                          inventory={order.inventory}
                          dynamicFieldValue={getDataForDynamicFieldValueBuyOrders(
                            order
                          )}
                          price={order.price}
                          quantity={order.quantity}
                          unitPrice={order.unitPrice}
                          btnLabel="Sell"
                          ownerAccountId={order.ownerAccountId}
                          isLoading={isLoading}
                          orderBuyFields={order.orderSavedFields}
                          type="sell"
                          state={
                            order?.orderState
                              ? order?.orderState
                              : order?.account?.address?.state
                          }
                          zipCode={
                            order?.orderZip
                              ? order?.orderZip
                              : order?.account?.address?.zip
                          }
                          uniqueNum={order.uniqueNum}
                          orderNumber={order?.orderNumber}
                        />
                      ))}
                    </>
                  ) : (
                    <Grid item sm={12}>
                      <Typography
                        variant="h1"
                        align="center"
                        color="secondary"
                        className={classes.marginTop32}
                      >
                        {isLoadingOrders ? "No orders" : "Loading..."}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              {tabValue === 1 && (
                <Grid
                  container
                  item
                  sm={12}
                  alignContent="flex-start"
                  className={classes.ordersContainerLeft}
                >
                  {sellOrders.length !== 0 ? (
                    <>
                      <Grid
                        container
                        item
                        className={cx(classes.titleCard, classes.card)}
                      >
                        <Grid
                          container
                          item
                          xs={11}
                          className={classes.cardItems}
                          alignContent="center"
                          justifyContent="space-around"
                        >
                          <Grid item xs={2} className={classes.titleItem}>
                            {/* select for dynamic field instead of total price */}
                            <Select
                              classes={{
                                select: `${classes.selectMobile} ${classes.paddingSelect0}`,
                              }}
                              name="type"
                              id="type"
                              value={dynamicColumnSelectedValueSellOrders}
                              onChange={({ target }) =>
                                handleChangeDynamicColumnValueSellOrders(
                                  target.value
                                )
                              }
                              fullWidth
                              input={
                                <Input
                                  classes={{
                                    underline: classes.underline,
                                  }}
                                />
                              }
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              <MenuItem key="totalPrice" value="totalPrice">
                                Total Price
                              </MenuItem>
                              {selectedSingleInventoryType?.map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {/* Total $ */}
                          </Grid>

                          <Grid item xs={2} className={classes.titleItem}>
                            {amountSrotArrowSell == "none" && (
                              <CompareArrowsIcon
                                className={classes.rowSort}
                                onClick={() => sortingTable("amountSortSell")}
                              />
                            )}
                            {amountSrotArrowSell == "down" && (
                              <ArrowUpwardIcon
                                className={classes.rowSortRotate}
                                onClick={() => sortingTable("amountSortSell")}
                              />
                            )}
                            {amountSrotArrowSell == "up" && (
                              <ArrowUpwardIcon
                                className={classes.rowSort}
                                onClick={() => sortingTable("amountSortSell")}
                              />
                            )}
                            {`Amount ${unitInventory || "lb"}`}
                          </Grid>

                          <Grid item xs={2} className={classes.titleItem}>
                            {priceSrotArrowSell == "none" && (
                              <CompareArrowsIcon
                                className={classes.rowSort}
                                onClick={() => sortingTable("priceSortSell")}
                              />
                            )}
                            {priceSrotArrowSell == "down" && (
                              <ArrowUpwardIcon
                                className={classes.rowSortRotate}
                                onClick={() => sortingTable("priceSortSell")}
                              />
                            )}
                            {priceSrotArrowSell == "up" && (
                              <ArrowUpwardIcon
                                className={classes.rowSort}
                                onClick={() => sortingTable("priceSortSell")}
                              />
                            )}
                            Price $
                          </Grid>

                          <Grid item xs={1} className={classes.titleItem}>
                            Info
                          </Grid>

                          <Grid item xs={2} className={classes.titleItem}>
                            <Select
                              classes={{
                                select: classes.paddingSelect0,
                              }}
                              name="typeSell"
                              id="typeSell"
                              value={filterStateSell}
                              onChange={({ target }) =>
                                handleChangeStateSell(target.value)
                              }
                              fullWidth
                              input={
                                <Input
                                  classes={{
                                    underline: classes.underline,
                                  }}
                                />
                              }
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              {/* <MenuItem key="allZip" value="allZip">State</MenuItem> */}
                              {filterStateArrSell?.map((item: any) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>

                          <Grid item xs={1} className={classes.titleItem}>
                            Q
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          item
                          xs={1}
                          className={classes.cardItems}
                          alignContent="center"
                        >
                          <Grid item xs={12} className={classes.titleItem}>
                            Action
                          </Grid>
                        </Grid>
                      </Grid>
                      {sellOrders.map((order: OrderMP) => {
                        return (
                          <OrderCardToSell
                            id={order.id}
                            key={order.createdAt}
                            inventory={order.inventory}
                            dynamicFieldValue={getDataForDynamicFieldValueSellOrders(
                              order
                            )}
                            price={order.price}
                            quantity={order.quantity}
                            unitPrice={order.unitPrice}
                            btnLabel="Buy"
                            ownerAccountId={order.ownerAccountId}
                            orderBuyFields={order.orderSavedFields}
                            isLoading={isLoading}
                            type="buy"
                            state={
                              order?.orderState
                                ? order?.orderState
                                : order?.inventory?.locationState
                            }
                            zipCode={
                              order?.orderZip
                                ? order?.orderZip
                                : order?.inventory?.locationZip
                            }
                            userOrderID={order.account.id}
                            orderNumber={order?.orderNumber}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <Grid item sm={12}>
                      <Typography
                        variant="h1"
                        align="center"
                        color="secondary"
                        className={classes.marginTop32}
                      >
                        {isLoadingOrdersSell ? "No orders" : "Loading..."}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Hidden>
        </Grid>
      )}
      {/* {
            getRole(userRole) === 'Guest' && !userRole.requestCreatedAt && (
              <NoRoleWarning>
                You have read only access, for full access click here to
                <a href='changeRole'>
                  {' change your role'}
                </a>
              </NoRoleWarning>
            )
          } */}
      {getRole(userRole) === "Guest" && userRole.requestCreatedAt && (
        <NoRoleWarning>
          {`Role Change request was submitted on ${getDateTime(
            userRole.requestCreatedAt
          )} and is being reviewed by the administrator.`}
        </NoRoleWarning>
      )}
    </Paper>
  );
};

export default MarketPlace;
