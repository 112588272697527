import React, {
  FC, useState, useCallback, useEffect,
} from 'react';
import cx from 'classnames';
import {
  Typography,
  List,
  ListItem,
  Link,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileUploader from './FileUploader';
import constants from '../../core/constants';
import useStyles from './FoleUploader.style';
import DocUpload from '../../svg/docUpload';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

interface Props {
  currentFiles: { link: string; title: string }[];
  newFiles: File[];
  setListFiles: (newFiles: File[], newList: { link: string; title: string }[]) => void;
  description?: string;
  docsTitle?: boolean;
  availableForEdit?: boolean;
}

export const MultiFileUploaderButton: FC<Props> = ({
  currentFiles,
  newFiles,
  setListFiles,
  description,
  docsTitle,
}) => {
  const classes = useStyles();

  const handleSetNewFiles = useCallback(
    (newFileList: File[]) => {
      const newList = newFiles
        .concat(newFileList.filter((item) => !newFiles.find((f) => f.name === item.name)));
      const newCurrentFilesList = currentFiles.reduce((accum, item) => {
        const file = newList.find((e) => e.name === item.title);
        if (file) {
          return accum;
        }
        return accum.concat([item]);
      }, [] as { link: string; title: string }[]);
      setListFiles(newList, newCurrentFilesList);
    },
    [currentFiles, newFiles, setListFiles],
  );

  return (
    <>
      {docsTitle && (
        <Typography variant="body1" className={classes.body1}>
          Documents
        </Typography>
      )}
      <Typography variant="body1" className={cx(classes.body1, classes.docName)}>
        {description}
      </Typography>
      <br />
      <Typography variant="body2" className={classes.body2}>
        Select and upload up to 10 files, up to 10Mb in size
      </Typography>
      <FileUploader multiSelect uploadFile={handleSetNewFiles} />
    </>
  );
};

export const MultiFileUploaderList: FC<Props> = ({
  currentFiles,
  newFiles,
  setListFiles,
  availableForEdit,
}) => {
  const classes = useStyles();

  const [isOpenDelFileModal, openDelFileModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({ name: '', type: '' });

  const [newFilesUri, setNewFilesUri] = useState<any>();

  const fileToDataUri = (file: any) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });

  const getNewFilesList = async (files: any) => {
    const newFileList = await Promise.all(files.map(async (item: any) => {
      const result = await fileToDataUri(item);
      const uri = `data:application/octet-stream;base64, ${(result as string).split(',')[1]}`;
      return {
        file: item,
        uri,
      };
    }));
    setNewFilesUri(newFileList);
    return newFileList;
  };

  useEffect(() => {
    if (newFiles.length > 0) {
      getNewFilesList(newFiles);
    } else {
      setNewFilesUri([]);
    }
  }, [newFiles]);

  const handleDeleteNewDocument = useCallback(
    (name: string) => {
      setListFiles(newFiles.filter((item) => item.name !== name), currentFiles);
    },
    [setListFiles, newFiles, currentFiles],
  );

  const handleDeleteCurrentDocument = useCallback(
    (name: string) => {
      setListFiles(newFiles, currentFiles.filter((item) => item.title !== name));
    },
    [setListFiles, currentFiles, newFiles],
  );

  return (
    <>
      <List>
        {currentFiles.map((doc: { link: string; title: string }) => (
          <ListItem key={doc.title}>
            <ListItemAvatar className={classes.fileIcon}>
              <DocUpload />
            </ListItemAvatar>
            <ListItemText>
              <p className={classes.docName}>
                <Link href={`${constants.AWS_URL}${doc.link}`} target="_blank" rel="noreferrer">
                  {doc.title}
                </Link>
              </p>
            </ListItemText>
            {availableForEdit && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    setFileToDelete({ name: doc.title, type: 'current' });
                    openDelFileModal(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
        {newFilesUri && Object.keys(newFilesUri).length > 0 && (
          <Typography variant="body1" className={classes.body1}>
            New:
          </Typography>
        )}
        {newFilesUri && Object.keys(newFilesUri).length > 0 && newFilesUri.map((doc: any) => (
          <ListItem key={doc.file.name}>
            <ListItemAvatar className={classes.fileIcon}>
              <DocUpload />
            </ListItemAvatar>
            <ListItemText>
              <p className={classes.docName}>
                <Link
                  href={doc.uri}
                  download={doc.file.name}
                  target="_blank"
                  rel="noreferrer"
                >
                  {doc.file.name}
                </Link>
              </p>
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  setFileToDelete({ name: doc.file.name, type: 'new' });
                  openDelFileModal(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {isOpenDelFileModal && (
        <ConfirmationDialog
          open={isOpenDelFileModal}
          describedby="simple-modal-title"
          labelledby="simple-modal-description"
          title="File deletion"
          description={(
            <p>
              Are you sure you want to delete the selected file??????
            </p>
          )}
          acceptButtonText="Delete"
          cancelButtonText="Cancel"
          color="green"
          onAcceptButtonClicked={
            () => {
              if (fileToDelete) {
                if (fileToDelete.type === 'new') handleDeleteNewDocument(fileToDelete.name);
                if (fileToDelete.type === 'current') handleDeleteCurrentDocument(fileToDelete.name);
              }
              openDelFileModal(false);
            }
          }
          onCancelButtonClicked={
            () => openDelFileModal(false)
          }
        />
      )}
    </>
  );
};

const MultiFileUploader: FC<Props> = ({
  currentFiles,
  newFiles,
  setListFiles,
  description,
  availableForEdit,
}) => (
  <>
    {availableForEdit && (
      <MultiFileUploaderButton
        currentFiles={currentFiles}
        newFiles={newFiles}
        setListFiles={(files, listFiles) => setListFiles(files, listFiles)}
        description={description}
      />
    )}

    <MultiFileUploaderList
      currentFiles={currentFiles}
      newFiles={newFiles}
      setListFiles={(files, listFiles) => setListFiles(files, listFiles)}
      availableForEdit={availableForEdit}
    />
  </>
);

export default MultiFileUploader;
