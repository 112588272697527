import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // TextField,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './Inventory.style';

// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

import ButtonSC from '../ButtonSC/ButtonSC';

interface Props {
  open: boolean;
  close: () => void;
}

export const LabAttestationModal: FC<Props> = ({
  open,
  close,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      scroll="paper"
    >
      <DialogTitle>
        {/* Lab Analysis Attestation */}
        <IconButton aria-label="close" onClick={() => close()} className={classes.closeModalIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="h1">
          Lab Analysis Attestation
        </Typography>
        <p>
          There is nothing more important to the quality of your product than the data used to
          support any label, sales data, or Certificate of Analysis you associate with it and use
          as proof of quality.
        </p>
        <p>
          It is imperative that an organization establish a process that the organization must
          follow in order to ensure the data generated by an analytical laboratory
          (or sub-contractor) that is performing sub-contracted analytical work is of highest
          quality. The outsourcing company will use a written procedure to qualify the
          sub-contracting organization (See the attached outline).
        </p>
        <ol>
          {/* 1 */}
          <li>
            Laboratory is operating under an accepted and recognized quality system.
            The preferred system is ISO 17025.
          </li>
          {/* 2 */}
          <li>
            Laboratory has passed an audit using the ISO 17025 system as a standard.
            A2LA is the accepted compliance audit organization providing accreditation.
          </li>
          {/* 3 */}
          <li>
            The organization will maintain a current book of operations.
            This book could be titled many ways but in its essence is how they do business
            and is often referred to a Quality Assurance Manual.
            <ol type="a">
              <li>
                This book (electronic or paper) shall be divided into administrative documents
                and operational documents.
              </li>
            </ol>
          </li>
          {/* 4 */}
          <li>
            The organization has written instructions for all systems and operations.
          </li>
          {/* 5 */}
          <li>
            Laboratory is licensed in the State or States to perform analysis in question (below).
            <ol type="a">
              <li>Potency CBD/ THC</li>
              <li>Heavy metals testing</li>
              <li>Pesticides</li>
              <li>Residual solvents</li>
              <li>Microbial, fungus, mycotoxins</li>
              <li>Moisture</li>
              <li>Terpene profile</li>
              <li>Residual solvent/volatile impurities</li>
            </ol>
          </li>
          {/* 6 */}
          <li>
            The laboratory owns/possesses equipment needed to perform sub-contracted services and
            are all appropriate for the tasks outsourced.
          </li>
          {/* 7 */}
          <li>
            The laboratory has a maintenance and calibration program.  Calibration is performed
            in-house or sub-contracted to a qualified organization and all standards are traceable
            to the original source.
          </li>
          {/* 8 */}
          <li>
            The electronic data capture system used by the laboratory is an integrated management
            system or recognized Laboratory Information Management System (LIMS).
          </li>
          {/* 9 */}
          <li>
            Data integrity and security.  All data captured/entered from the time a sample is
            submitted until a Certificate of Analysis is generated may be reviewed and not
            altered without proper documentation
          </li>
          {/* 10 */}
          <li>
            All laboratory personnel are trained to perform the tasks assigned and all training is
            recorded in a training file.
          </li>
          {/* 11 */}
          <li>
            Laboratory maintains an organizational chart that includes job descriptions and lines of
            authority with Quality System Management separate from Operational Management.
          </li>
          {/* 12 */}
          <li>
            Standard Operating Procedures (SOPs).  Where appropriate, SOPs will be used to operate
            all systems.
          </li>
          {/* 13 */}
          <li>
            Experience of personnel in testing the eight identified tests (4a-h) with hemp in the
            matrices notedabove.
          </li>
          {/* 14 */}
          <li>Procedures established for review and/or acceptance and rejection of data.</li>
          {/* 15 */}
          <li>SOP on record retention and data system control and back-up.</li>
          {/* 16 */}
          <li>SOP regarding standard report formats including detection limits.</li>
          {/* 17 */}
          <li>Established procedure for handling client complaints</li>
          {/* 18 */}
          <li>
            All equipment is brought on-line by a process that includes Installation, operation and
            performance qualification.
          </li>
          {/* 19 */}
          <li>Re-qualification of equipment has a set process (timing or number of samples).</li>
          {/* 20 */}
          <li>Established procedure/document for sample retention.</li>
          {/* 21 */}
          <li>
            Disaster recovery.  Does the laboratory routinely back up data and are they prepared
            for a disaster where data is lost or destroyed?
          </li>
          {/* 22 */}
          <li>
            Power back-up.  In the event that power is lost what is the process for bring the
            instruments back online?  Does the laboratory have a back-up generator?
          </li>
        </ol>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonSC
              variant="fill"
              text="OK"
              size="large"
              color="green"
              handleClick={() => close()}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LabAttestationModal;
