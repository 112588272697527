import { makeStyles } from '@material-ui/core';
import { textAlign } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
  actions: {
    color: `${theme.palette.primary.light} !important`,
    fontSize: 24,
    paddingLeft: 0,
  },
  marginTopAndBottom: {
    marginTop: 20,
    marginBottom: 15,
  },
  spacer: {
    display: 'none',
  },
  root: {
    justifyContent: 'flex-end',
  },
  addBtn: {
    fontFamily: 'Arimo, Arial',
    fontSize: 14,
    fontWeight: 700,
    padding: '10px 15px',
    background: theme.palette.primary.main,
    borderRadius: 5,
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },

  orderQuestionHeader: {
    textAlign: 'center',
  },

  tableCenter: {

    margin: '0 auto',

  },

  borderedTable: {

    border: 'solid 1px rgb(240, 244, 248);',

  },

  dialogPaper: {
    minHeight: '200px',
    width: '100%',
    borderRadius: 15,
    overflow: 'hidden',
  },
  dialogPaperOrderQuestions: {
    maxHeight: '100%',
    maxWidth: '100%',
    borderRadius: 15,
  },
  closeModalIcon: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },

  labelBtn: {
    display: 'block',
    flexWrap: 'wrap',
  },

  picture: {
    height: 50,
    width: 50,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.contrastText,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 40,
    },
  },

  infoColorAttest: {
    color: theme.palette.primary.light,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
      height: '32px',
      wordBreak: 'break-word',
      width: '32px',
    },
  },

  infoColorNoAttest: {
    color: theme.palette.secondary.main,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
      height: '32px',
      wordBreak: 'break-word',
      width: '32px',
    },
  },

  infoButton: {
    fontSize: 14,
    color: theme.palette.text.primary,
    '&.MuiButton-label': {
      display: 'block',
    },
  },

  messageInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
    marginBottom: '20px',
  },

  MuiDialogActions: {
    root: {
      justifyContent: 'center',
    },
  },

  sendButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },

  MuiDialogContent: {
    root: {
      marginBottom: '20px',
    },
  },

  dialogContent: {
    padding: 0,
    wordWrap: 'break-word',
  },

  docName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  dialogPadding40: {
    padding: 40,
  },

  longDescriptionHide: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  longDescriptionBtn: {
    padding: 0,
    textDecoration: 'underline',
    color: theme.palette.primary.light,
  },

  timeWrapper: {
    marginRight: '5px',
    width: '90px',
  },
  estWrapper: {
    marginRight: '5px',
    width: '60px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 680px)': {
      marginTop: '10px',
    },

  },
  calendarWrapper: {
    marginRight: '5px',
    width: '140px',
    '@media (max-width: 680px)': {
      marginTop: '10px',
    },
  },

  textMessagesWrapper: {
    fontSize: '16px',
    margin: '5px 0px 5px 10px',
    '@media (max-width: 680px)': {
      fontSize: '15px',
    },
  },

  InformMessage: {
    width: '100%',
    textAlign: 'center',
    color: '#db6161',
    '@media (max-width: 680px)': {
      padding: '8px',
      textAlign: 'justify',
    },
  },

  InfoWrapper: {
    width: '100%'
  },

  InformMessageForSend: {
    fontSize: '16px',
    width: '100%',
    textAlign: 'center',
    '@media (max-width: 680px)': {
      padding: '8px',
      textAlign: 'justify',
    },
  },

  InformMeeting: {
    fontSize: '16px',
    fontWeight: 500,
    width: '100%',
    textAlign: 'start',
    marginLeft: '30px',
    marginTop: '30px',
    '@media (max-width: 680px)': {
      padding: '8px',
      textAlign: 'justify',
    },
  },

  DateAndTimeWrapper: {
    width: '100%',
    display: 'flex',
    marginBottom: '30px',
    marginLeft: '30px',
    '@media (max-width: 680px)': {
      flexWrap: 'wrap',
      padding: '8px',
    },
  },

  buttonAnimation:{
    border: '2px solid white',
    boxShadow: '0px 0px 0px 0px white',
    borderRadius: '8px',
    animationName: '$NewMessageBlinker',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes NewMessageBlinker': {
    '0%': {
      border: '2px solid white',
      boxShadow: '0px 0px 0px 0px #b58d9f',
    },
    '100%': {
      border: '2px solid #87BC14',
      boxShadow: '0px 0px 10px 5px #87BC14',
      // transform: 'scale(1.2)',
    },
  },
  buttonAnimationNon:{
  },

  orderNumber: {
    position: 'absolute',
    top: '5px',
    right: '70px',
    fontSize: '21px'
  },

  dialogWrapper: {
    // width: '600px',
  //   position: 'absolute',
  //   top: '25vh',
  //   left: '30vw',
  }

}));

export default useStyles;
