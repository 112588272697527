import axios from 'axios';
import constants from '../core/constants';
import api from '../core/api';
import { InventoryItemTypeToSend, InventoryItemType } from '../interfaces/inventoryTypes.interface';
import request from './apiRequest';

// Create
export const apiCreateInventoryType = (
  inventoryType: InventoryItemTypeToSend,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.createInventoryItemType}${userId}`;
  return request(
    url,
    'POST',
    inventoryType,
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   inventoryType,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Get by Id
export const apiGetInventoryTypes = async (
  userId: number | null,
) => {
  const url = `${constants.BASE_URL}${api.getInventoryTypes}${userId}`;
  const { data } = await axios.get(
    url,
  );
  return data;
};

// Get by Id
export const apiGetInventoryTypeById = (
  idInventoryType: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getInventoryItemType}${userId}/${idInventoryType}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Update
export const apiUpdateInventoryType = (
  inventoryType: InventoryItemType,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.updateInventoryItemType}${userId}/${inventoryType.id}`;
  return request(
    url,
    'PUT',
    inventoryType,
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.put(
  //   url,
  //   inventoryType,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};
