import React, {
  FC,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import { AdminRequest } from '../../interfaces/adminRequest.interface';
import {
  approveRoleRequests,
  declineRoleRequests,
  declineInventoryRequests,
  approveInventoryRequests,
  declineBrokerClientRequests,
  approveBrokerClientRequests,
  declineDealRequests,
  approveDealRequests,
  declineChangeEmailRequests,
  approveChangeEmailRequests,
  setOpenRequest,
  setReloadRequest,
} from '../../store/slices/adminRequest.slice';

import { addNotification } from '../../store/slices/snackbar.slice';
import RoleRequestDetails from './RoleRequestDetails';
import InventoryRequestDetails from './InventoryRequestDetails';
import BrokerClientRequestDetails from './BrokerClientRequestDetails';
import DealRequestDetails from './DealRequestDetails';
import getLocalStorageData from '../../api/localStorage';
import ButtonSC from '../ButtonSC/ButtonSC';
import ChangeUserEmailDetails from './ChangeUserEmailDetails';
import useStyles from './Request.style';
import { RootState } from '../../store/slices';
import { updateUserSuccess } from '../../store/slices/userUpdate.slice';
import { dealSuccess } from '../../store/slices/deals.slice';
import translationEN from '../../locales/en/translation.json';

interface Props {
  onClose: () => void;
  request: AdminRequest;
  open: boolean;
}
const RequestDetail: FC<Props> = ({ open, onClose, request }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [comment, setComment] = useState('');
  const [openReload, setOpenReload] = useState(false);

  const handleClose = useCallback(
    () => {
      if (request.request_type === 'DEAL') {
        dispatch(dealSuccess(null));
      }
      setOpenReload(false);
      if (request.request_type === 'ROLE') dispatch(updateUserSuccess({}));
      dispatch(setOpenRequest(null));
      dispatch(setReloadRequest(false));
      setComment('');
      onClose();
    },
    [dispatch, onClose, request],
  );

  const {
    reloadRequest,
  } = useSelector(
    (state: RootState) => state.requests,
  );

  useEffect(() => {
    setOpenReload(reloadRequest);
  }, [reloadRequest]);

  const getTypeRequest = () => {
    if (request.request_type === 'ROLE') return `Request To Change User Role On ${request.request_requestedRole}`;
    if (request.request_type === 'INVENTORY') return 'Request To Confirm Inventory';
    if (request.request_type === 'BROKER') return 'Request To Connect User To Broker';
    if (request.request_type === 'DEAL') return 'Request To Confirm Deal';
    if (request.request_type === 'USER EMAIL') return 'Request To Change User Email';
    return '';
  };

  const handleDecline = useCallback(() => {
    const { userId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();

    if (comment) {
      if (request.request_type === 'ROLE') dispatch(declineRoleRequests(request.request_id, comment, userId, token, key));
      if (request.request_type === 'INVENTORY') dispatch(declineInventoryRequests(request.request_id, comment, userId, token, key));
      if (request.request_type === 'BROKER') dispatch(declineBrokerClientRequests(request.request_id, comment, userId, token, key));
      if (request.request_type === 'DEAL') dispatch(declineDealRequests(request.request_id, comment, userId, token, key));
      if (request.request_type === 'USER EMAIL') dispatch(declineChangeEmailRequests(request.request_id, comment, userId, token, key));
      handleClose();
    } else {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: translationEN.PleaseEnterAReasonForDeclining,
        options: { variant: 'error' },
      }));
    }
  }, [dispatch, comment, request, handleClose]);

  const handleApprove = useCallback(() => {
    const { userId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();

    if (request.request_type === 'ROLE') dispatch(approveRoleRequests(request.request_id, comment, userId, token, key));
    if (request.request_type === 'INVENTORY') dispatch(approveInventoryRequests(request.request_id, comment, userId, token, key));
    if (request.request_type === 'BROKER') dispatch(approveBrokerClientRequests(request.request_id, comment, userId, token, key));
    if (request.request_type === 'DEAL') dispatch(approveDealRequests(request.request_id, comment, userId, token, key));
    if (request.request_type === 'USER EMAIL') dispatch(approveChangeEmailRequests(request.request_id, comment, userId, token, key));
    handleClose();
  }, [dispatch, comment, request, handleClose]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {getTypeRequest()}
          <IconButton aria-label="close" onClick={handleClose} className={classes.closeModalIcon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {request.request_type === 'ROLE' && request.users_id && <RoleRequestDetails userId={request.users_id} />}
          {request.request_type === 'INVENTORY' && <InventoryRequestDetails request={request} />}
          {request.request_type === 'BROKER' && <BrokerClientRequestDetails request={request} />}
          {request.request_type === 'DEAL' && <DealRequestDetails request={request} />}
          {request.request_type === 'USER EMAIL' && <ChangeUserEmailDetails request={request} />}
        </DialogContent>
        <DialogActions>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <TextField
                id="standard-multiline-flexible"
                label="Comment"
                multiline
                rowsMax="4"
                fullWidth
                value={comment}
                onChange={({ target }) => setComment(target.value)}
              />
            </Grid>
            <Grid container item xs={12} className={classes.buttonMargin} justifyContent="space-between">
              <Grid item>
                <ButtonSC
                  variant="outline"
                  text="Decline"
                  size="small"
                  handleClick={handleDecline}
                  color="red"
                />
              </Grid>
              <Grid item>
                <ButtonSC
                  variant="outline"
                  text="Confirm"
                  size="small"
                  handleClick={handleApprove}
                  autofocus
                  color="green"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openReload}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Client has just updated information</DialogTitle>
        <DialogActions>
          <ButtonSC
            variant="fill"
            text="OK"
            size="large"
            handleClick={() => {
              handleClose();
            }}
            color="green"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestDetail;
