import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  paper: {
    height: '100%',
    boxShadow: 'none',
    borderRadius: 15,
    padding: '8px 8px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '32px 32px',
    },
    overflow: 'auto',
  },

  card_wrapper: {
    background: '#e6f7deb0',
    border: '1px solid white',
    borderRadius: '16px',
    margin: '8px',
    boxShadow: '1px 1px 2px #d7d6d657',
    '&:hover': {
      boxShadow: '-1px -1px -10px #d7d6d657',
      border: '1px solid #d7d6d657',
    }
  },

  card_wrapper_read: {
    border: '1px solid white',
    borderRadius: '16px',
    margin: '8px',
    boxShadow: '1px 1px 2px #d7d6d657',
    '&:hover': {
      boxShadow: '-1px -1px -10px #d7d6d657',
      border: '1px solid #d7d6d657',
    }
  },

  card_content_wrapper: {
    marginLeft: '18px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '80px'
  },

  text_wrapper_header: {
    fontSize: '12px',
    margin: '4px 0px 0px 0px',
    wordBreak: 'break-all'
  },

  text_wrapper: {
    fontSize: '16px',
    margin: '4px 4px 4px 16px',
    wordBreak: 'break-all'
  },

  text_wrapper_date: {
    fontSize: '8px',
  },

  link_style: {
    textDecoration: 'none',
    color: 'black'
  },

  wait_spinner: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-100px',
    marginLeft: '-80px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',

    },
  },

  spinner_rotate_animation: {
    height: '60px',
    width: '60px',
    color: 'green',
    animationName: '$NewNotificationBlinker',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },

  '@keyframes NewNotificationBlinker': {
    '0%': {
    // transform: 'scale(200%)',
    },
    '100%': {
      transform: 'rotate(720deg) scale(500%)',
    },
  },

}));

export default useStyles;
