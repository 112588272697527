import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: 'auto',
    padding: '40px 24px',
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      padding: '56px 64px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 520,
    borderRadius: 15,
    boxShadow: 'none',
  },
  title: {
    marginBottom: 24,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  margin16: {
    margin: '16px 0',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '16px',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
    },
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  textCenter: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '16px',
  },
  container: {
    height: '100%',
    padding: '112px 0',
    overflow: 'auto',
  },
}));

export default useStyles;
