const getLocalStorageData = () => {
  const userId = localStorage.getItem('userId');
  const accountId = localStorage.getItem('accountId');
  const accessToken = localStorage.getItem('accessToken');
  const clientUserName = localStorage.getItem('clientUserName');
  const clientLastName = localStorage.getItem('clientLastName');
  const clientFirstName = localStorage.getItem('clientFirstName');
  const clientAccountId = localStorage.getItem('clientAccountId');
  const clientId = localStorage.getItem('clientId');
  const tokenExp = localStorage.getItem('tokenExp');
  const refreshToken = localStorage.getItem('refreshToken');

  return {
    userId: userId ? +userId : null,
    accountId: accountId ? +accountId : null,
    token: { accessToken, tokenExp, refreshToken },
    clientUserName,
    clientLastName,
    clientFirstName,
    clientAccountId: clientAccountId ? +clientAccountId : null,
    clientId: clientId ? +clientId : null,
  };
};

export default getLocalStorageData;
