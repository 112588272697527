import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  contactsHeader: {

    color: 'rgb(4, 62, 59)',
    fontSize: '22px',

  },

  contactsText: {
    '& > a': {
      color: '#075955',
    },
    '& > a:hover': {
      color: '#32343C',
    },
    fontSize: '18px',

  },

}));

export default useStyles;
