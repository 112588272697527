import { createSlice } from '@reduxjs/toolkit';
import {
  apiGetAllPostTradeProcessingTemplates,
  apiUpdatePostTradeProcessingTemplate,
} from '../../api/postTradeProcessingTemplates';
import { AppThunk } from '../index';
import {
  PostTradeProcessingTemplatesState,
  PostTradeProcessingTemplates,
} from '../../interfaces/postTradeProcessingTemplates.interface';
import { addNotification } from './snackbar.slice';
import translationEN from '../../locales/en/translation.json';

const initialState: PostTradeProcessingTemplatesState = {
  listOfPostTradeProcessingSteps: [],
  postTradeProcessingTemplateId: null,
  error: '',
  isLoading: false,
};

const postTradeProcessingTemplatessSlice = createSlice({
  initialState,
  name: 'postTradeProcessingTemplates',
  reducers: {
    listPostTradeStepsRequest(state) {
      state.listOfPostTradeProcessingSteps = [];
      state.postTradeProcessingTemplateId = null;
      state.error = '';
      state.isLoading = true;
    },
    listPostTradeStepsSuccess(state, action) {
      state.listOfPostTradeProcessingSteps = action.payload.postTradeProcessingTemplatesSteps;
      state.postTradeProcessingTemplateId = action.payload.id;
      state.error = '';
      state.isLoading = false;
    },
    listPostTradeStepsFailure(state, action) {
      state.listOfPostTradeProcessingSteps = [];
      state.postTradeProcessingTemplateId = null;
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  listPostTradeStepsRequest,
  listPostTradeStepsSuccess,
  listPostTradeStepsFailure,
} = postTradeProcessingTemplatessSlice.actions;

export const getAllPostTradeProcessingTemplates = (
  typeId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
): AppThunk => async (dispatch) => {
  try {
    dispatch(listPostTradeStepsRequest());
    const requests = await dispatch(apiGetAllPostTradeProcessingTemplates(
      typeId,
      token,
    ));
    dispatch(listPostTradeStepsSuccess(requests));
  } catch (error) {
    dispatch(listPostTradeStepsFailure(
      error?.response ? error?.response?.data?.error : error?.message,
    ));
  }
};

export const updatePTSTemplate = (
  steps: PostTradeProcessingTemplates[],
  postProcessingTemplateId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(listPostTradeStepsRequest());
    const PTSTemplateInfo = await dispatch(apiUpdatePostTradeProcessingTemplate(
      steps,
      postProcessingTemplateId,
      token,
    ));
    dispatch(listPostTradeStepsSuccess(PTSTemplateInfo));
    dispatch(addNotification({
      key,
      message: translationEN.PostTradeProcessingTemplateUpdatedSuccessfully,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(listPostTradeStepsFailure(
      error?.response ? error?.response?.data?.error : error?.message,
    ));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default postTradeProcessingTemplatessSlice.reducer;
