import React, { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import {
  getInventory, deleteInventory,
  // setActiveStatusInventory,
  // approveInventory,
} from '../../store/slices/inventory.slice';
import { RootState } from '../../store/slices';
import { Inventory } from '../../interfaces/inventory.interface';
import { getDateTime } from '../../utils/getDate';
import { addNotification } from '../../store/slices/snackbar.slice';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import useStyles from './Inventory.style';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
import { apiCheckActiveOrders } from '../../api/inventory';

const ListInventory: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['rowListInventory']);
  const { token } = getLocalStorageData();
  const [currentInventoryList, setListOfInventory] = useState<Inventory[]>([]);

  const handleGetStatus = (data: Inventory) => {
    if (data.approved && data.active) return 'ACTIVE';
    if (!data.approved && data.sendToAdminApprovingDate && data.inventoryItemStatus !== 'REJECTED') return `AWAITING CONFIRMATION FROM ${getDateTime(data.sendToAdminApprovingDate)}`;
    if (!data.approved && data.inventoryItemStatus === 'REJECTED' && data.requestForApprovement) return `REJECTED Reason: ${data.requestForApprovement.reasonForDecline}`;
    return 'INACTIVE';
  };

  const columns: Array<Column<Inventory>> = [
    {
      title: 'New Order',
      field: '',
      render: (rowData) => (
        <ButtonSC
          variant="fill"
          text="Sell"
          size="large"
          color="green"
          disabled={!rowData.active || !rowData.approved}
          handleClick={() => {
            // if (!rowData.active) {
            //   return dispatch(addNotification({
            //     key: new Date().getTime() + Math.random(),
            //     message: 'Activate Inventory',
            //     options: { variant: 'error' },
            //   }));
            // }
            if (+rowData.quantityRemained === 0) {
              return dispatch(addNotification({
                key: new Date().getTime() + Math.random(),
                message: 'No inventory left to create order',
                options: { variant: 'error' },
              }));
            }
            return history.push(
              'newOrder',
              {
                inventoryId: rowData.id,
              },
            );
          }}
        />
      ),
    },
    {
      title: 'FC',
      field: 'isForwardInventory',
      type: 'boolean',
    },
    {
      title: 'Status',
      field: 'active',
      render: handleGetStatus,
    },
    {
      title: 'Attest',
      field: 'attest',
      type: 'boolean',
    },
    {
      title: 'Type',
      field: 'inventoryItemType.type',
    },
    {
      title: 'Title',
      field: 'title',
    },
    {
      title: 'Quantity',
      field: 'quantity',
      render: (rowData) => `${Number.isInteger(rowData.quantity) ? rowData.quantity : rowData.quantity.toFixed(2)} ${rowData.inventoryItemType.units}`,
      customFilterAndSearch: (term, rowData) => (`${Number.isInteger(rowData.quantity) ? rowData.quantity : rowData.quantity.toFixed(2)} ${rowData.inventoryItemType.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Available',
      field: 'quantityRemained',
      render: (rowData) => `${Number.isInteger(rowData.quantityRemained) ? rowData.quantityRemained : rowData.quantityRemained.toFixed(2)} ${rowData.inventoryItemType.units}`,
      customFilterAndSearch: (term, rowData) => (`${Number.isInteger(rowData.quantityRemained) ? rowData.quantityRemained : rowData.quantityRemained.toFixed(2)} ${rowData.inventoryItemType.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Broker',
      field: 'brokerUserName',
    },
  ];

  const {
    listOfInventory,
  } = useSelector(
    (state: RootState) => state.inventory,
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const {
      clientAccountId,
      accountId,
      userId,
      token: tokenUser,
    } = getLocalStorageData();
    if (userId) {
      const key = new Date().getTime() + Math.random();
      dispatch(getInventory(clientAccountId || accountId, tokenUser, key));
    }
  }, [dispatch]);

  useEffect(() => {
    setListOfInventory(listOfInventory.map((item) => ({ ...item })));
  }, [setListOfInventory, listOfInventory]);

  return (
    <TableContainer>
      <MaterialTable
        title=""
        columns={columns}
        data={currentInventoryList}
        actions={[
          {
            icon: () => (
              <div className={classes.addBtn}>
                Add Inventory
              </div>
            ),
            isFreeAction: true,
            onClick: () => history.push('createInventory'),
          },
          (rowData: Inventory) => ({
            icon: 'edit',
            tooltip: 'Edit',
            iconProps: {
              color: 'primary',
            },
            onClick: async () => {
              try {
                await dispatch(apiCheckActiveOrders(rowData.id, token));
                history.push('editInventory', { inventoryId: rowData.id });
              } catch (error) {
                // dispatch(addNotification({
                //   key: new Date().getTime() + Math.random(),
                //   message: error?.response ? error?.response?.data?.error : error?.message,
                //   options: { variant: 'error' },
                // }));
                enqueueSnackbar(error?.response?.data?.error, {
                  variant: 'error',
                });

              }
            },
            // if ((!rowData.active && !rowData.approved && !rowData.sendToAdminApprovingDate) ||
            //   (!rowData.active && rowData.approved && rowData.sendToAdminApprovingDate) ||
            // rowData.inventoryItemStatus === 'REJECTED') {
            //   return history.push('editInventory', { inventoryId: rowData.id });
            // }
            // return dispatch(addNotification({
            //   key: new Date().getTime() + Math.random(),
            //   message: 'To edit, make the inventory inactive or wait for administrator approval',
            //   options: { variant: 'error' },
            // }));
            //   return history.push('editInventory', { inventoryId: rowData.id });
            // },
          }),
          (rowData: Inventory) => ({
            icon: 'delete',
            tooltip: 'Delete',
            iconProps: {
              color: !rowData.approved ? 'default' : 'error',
            },
            disabled: !rowData.approved,
            onClick: async () => dispatch(deleteInventory(rowData.id, token, new Date().getTime() + Math.random())),
          }),
          // (rowData: Inventory) => ({
          //   icon: handleGetStatus(rowData) === 'ACTIVE' || handleGetStatus(rowData) ===
          // 'INACTIVE'
          // ? 'assignment_turned_in' : 'assignment_late',
          //   iconProps: {
          //     color: handleGetStatus(rowData) === 'ACTIVE' ? 'primary' :
          // (handleGetStatus(rowData) === 'INACTIVE' && 'error') || 'secondary',
          //   },
          //   tooltip: !rowData.approved ? 'Request Approval' :
          //     (rowData.active && 'Deactivate Inventory') || 'Activate Inventory',
          //   onClick: () => {
          //     const { clientAccountId, accountId, token } = getLocalStorageData();
          //     const key = new Date().getTime() + Math.random();
          //     if (!rowData.approved) {
          //       dispatch(approveInventory(rowData.id, clientAccountId && accountId, token, key));
          //     } else {
          //       dispatch(setActiveStatusInventory(rowData.id, token, rowData.active, key));
          //     }
          //   },
          // }),
          //   (rowData: Inventory) => ({
          //     icon: 'add',
          //     iconProps: {
          //       color: (handleGetStatus(rowData) !== 'ACTIVE' || +rowData.quantityRemained
          //  === 0)
          // ? 'secondary' : 'primary',
          //     },
          //     tooltip: 'New Order',
          //     onClick: () => {
          //       if (!rowData.active) {
          //         return dispatch(addNotification({
          //           key: new Date().getTime() + Math.random(),
          //           message: 'Activate Inventory',
          //           options: { variant: 'error' },
          //         }));
          //       }
          //       if (+rowData.quantityRemained === 0) {
          //         return dispatch(addNotification({
          //           key: new Date().getTime() + Math.random(),
          //           message: 'No inventory left to create order',
          //           options: { variant: 'error' },
          //         }));
          //       }
          //       return history.push(
          //         'newOrder',
          //         {
          //           inventoryId: rowData.id,
          //         },
          //       );
          //     },
          //   }),
        ]}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
            paddingLeft: 0,
          },
          pageSize: +cookies.rowListInventory || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowListInventory', pageSize, { path: '/' })}
        components={{
          Toolbar: (props) => (
            <MTableToolbar
              {...props}
              classes={{ actions: classes.actions, spacer: classes.spacer, root: classes.root }}
            />
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
          header: {
            actions: 'Edit',
          },
        }}
      />
    </TableContainer>
  );
};

export default ListInventory;
