import React, { FC, useState } from 'react';
import { Avatar, Button, Grid } from '@material-ui/core';
import ImageUploader from '../ImageUploader/ImageUploader';
import useStyles from './ProfilePage.style';

interface Props {
  setValue: (field: string, file: File) => void;
  photo: string;
}

const AvatarPhoto: FC<Props> = ({ setValue, photo }) => {
  const classes = useStyles();
  const [avatar, setAvatar] = useState<string>('');

  return (
    <Grid
      container
      direction="column"
      item
      xs={12}
      sm={4}
    >
      <Grid item className={classes.alignSelfCenter}>
        <Avatar variant="rounded" alt="Avatar" className={classes.avatar} src={avatar || photo} />
      </Grid>
      <Grid item className={classes.alignSelfCenter}>
        <Button
          variant="outlined"
          className={classes.uploadAvatarBtn}
        >
          <p>
            Upload your Selfie or corporate logo
          </p>
          <ImageUploader
            uploadPhoto={(file) => setValue('avatar', file)}
            setNewAvatar={(newAvatar) => setAvatar(newAvatar)}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default AvatarPhoto;
