import React, {
  FC,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useCookies } from 'react-cookie';

import { useHistory } from 'react-router-dom';
import MaterialTable, { Column } from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/slices';
import { ItemOfDealList } from '../../interfaces/deals.interface';
import { goToDeal, getAllSettlementsOfAccount } from '../../store/slices/deals.slice';
import { getRole } from '../../utils/getRole';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ListOfSettlements: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['rowListOfSettlements']);

  const [
    currentListOfSettlements,
    setListOfSettlements,
  ] = useState<ItemOfDealList[]>([]);
  const [openDeal, setOpenDeal] = useState<ItemOfDealList>({} as ItemOfDealList);

  // Selectors
  const {
    listOfSettlements,
    error,
    isLoading,
  } = useSelector(
    (state: RootState) => state.deals,
  );

  const {
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  // Use Efffect
  useEffect(() => {
    const { clientAccountId, accountId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    dispatch(getAllSettlementsOfAccount(
      clientAccountId && accountId,
      clientAccountId || accountId,
      token,
      key,
    ));
  }, [dispatch]);

  useEffect(() => {
    setListOfSettlements(listOfSettlements.map((item) => ({ ...item })));
  }, [
    listOfSettlements,
    setListOfSettlements,
  ]);

  const handleOpenDealRoom = useCallback(
    async (deal: ItemOfDealList) => {
      const { clientAccountId, accountId, token } = getLocalStorageData();
      setOpenDeal(deal);
      const key = new Date().getTime() + Math.random();
      if (getRole(userRole) !== 'Admin') {
        await dispatch(goToDeal(
          deal.dealRoomId,
          deal.id,
          clientAccountId ? accountId : null,
          accountId,
          token,
          key,
        ));
      }
    },
    [dispatch, userRole],
  );

  const columns: Array<Column<ItemOfDealList>> = [
    {
      title: 'Deal Room',
      field: '',
      render: (rowData) => (
        <ButtonSC
          variant="fill"
          text="Enter"
          size="large"
          color="green"
          disabled={isLoading}
          handleClick={() => handleOpenDealRoom(rowData as ItemOfDealList)}
        />
      ),
    },
    { title: 'No.', field: 'dealRoomId' },
    { title: 'Status', field: 'status' },
    {
      title: 'Order No',
      field: 'order.id',
      render: (rowData) => `# ${rowData.order.orderNumber}`,
    },
    {
      title: 'Quantity',
      field: 'order.quantity',
      render: (rowData) => (
        rowData.order.instantCheckout ?
          `${rowData.order.quantity} ${rowData.units}` :
          `${rowData.negotiationPointsDeals.find((item) => item.title === 'Quantity')?.agreedValue} ${rowData.units}`
      ),
      customFilterAndSearch: (term, rowData) => (
        (rowData.order.instantCheckout ?
          `${rowData.order.quantity} ${rowData.units}` :
          `${rowData.negotiationPointsDeals.find((item) => item.title === 'Quantity')?.agreedValue} ${rowData.units}`
        ).toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Price',
      field: 'order.price',
      render: (rowData) => `$ ${rowData.totalPrice.toFixed(2)}`,
      customFilterAndSearch: (term, rowData) => (`$ ${rowData.totalPrice.toFixed(2)}`).indexOf(term) !== -1,
    },
    {
      title: 'Unit Price',
      field: 'order.unitPrice',
      render: (rowData) => (
        rowData.order.instantCheckout ?
          `$ ${rowData.order.unitPrice.toFixed(2)}` :
          `$ ${(+(rowData.negotiationPointsDeals.find((item) => item.title === 'Unit price')?.agreedValue || 0)).toFixed(2)}`
      ),
      customFilterAndSearch: (term, rowData) => (
        (rowData.order.instantCheckout ?
          `$ ${rowData.order.unitPrice.toFixed(2)}` :
          `$ ${(+(rowData.negotiationPointsDeals.find((item) => item.title === 'Unit price')?.agreedValue || 0)).toFixed(2)}`
        )).indexOf(term) !== -1,
    },
    {
      title: 'Post Trade Steps',
      field: 'negotiationPoints',
      render: (rowData) => `${rowData.completeAmountOfPTS}/${rowData.totalAmountOfPTS}`,
      customFilterAndSearch: (term, rowData) => (`${rowData.completeAmountOfPTS}/${rowData.totalAmountOfPTS}`).indexOf(term) !== -1,
    },
    { title: 'Broker', field: 'brokerUserName' },
    { title: 'Counterparty', field: 'counterpartyUserName' },
  ];

  const columnsAdmin: Array<Column<ItemOfDealList>> = [
    {
      title: 'Deal Room',
      field: '',
      render: (rowData) => (
        <ButtonSC
          variant="fill"
          text="Enter"
          size="large"
          color="green"
          disabled={isLoading}
          handleClick={() => handleOpenDealRoom(rowData as ItemOfDealList)}
        />
      ),
    },
    { title: 'No.', field: 'dealRoomId' },
    { title: 'Status', field: 'status' },
    {
      title: 'Order No',
      field: 'order.id',
      render: (rowData) => `# ${rowData.order.orderNumber}`,
    },
    {
      title: 'Quantity',
      field: 'order.quantity',
      render: (rowData) => (
        rowData.order.instantCheckout ?
          `${rowData.order.quantity} ${rowData.units}` :
          `${rowData.negotiationPointsDeals.find((item) => item.title === 'Quantity')?.agreedValue} ${rowData.units}`
      ),
      customFilterAndSearch: (term, rowData) => (
        (rowData.order.instantCheckout ?
          `${rowData.order.quantity} ${rowData.units}` :
          `${rowData.negotiationPointsDeals.find((item) => item.title === 'Quantity')?.agreedValue} ${rowData.units}`
        ).toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Price',
      field: 'order.price',
      render: (rowData) => `$ ${rowData.totalPrice.toFixed(2)}`,
      customFilterAndSearch: (term, rowData) => (`$ ${rowData.totalPrice.toFixed(2)}`).indexOf(term) !== -1,
    },
    {
      title: 'Unit Price',
      field: 'order.unitPrice',
      render: (rowData) => (
        rowData.order.instantCheckout ?
          `$ ${rowData.order.unitPrice.toFixed(2)}` :
          `$ ${(+(rowData.negotiationPointsDeals.find((item) => item.title === 'Unit price')?.agreedValue || 0)).toFixed(2)}`
      ),
      customFilterAndSearch: (term, rowData) => (
        (rowData.order.instantCheckout ?
          `$ ${rowData.order.unitPrice.toFixed(2)}` :
          `$ ${(+(rowData.negotiationPointsDeals.find((item) => item.title === 'Unit price')?.agreedValue || 0)).toFixed(2)}`
        )).indexOf(term) !== -1,
    },
    {
      title: 'Post Trade Steps',
      field: 'negotiationPoints',
      render: (rowData) => `${rowData.completeAmountOfPTS}/${rowData.totalAmountOfPTS}`,
      customFilterAndSearch: (term, rowData) => (`${rowData.completeAmountOfPTS}/${rowData.totalAmountOfPTS}`).indexOf(term) !== -1,
    },
    {
      title: 'Members',
      field: 'members',
      render: (rowData) => (rowData.members && (
        <>
          {rowData.members.seller && <div key={rowData.members.seller}>{`Seller: ${rowData.members.seller}`}</div>}
          {rowData.members.buyer && <div key={rowData.members.buyer}>{`Buyer: ${rowData.members.buyer}`}</div>}
          {rowData.members.sellerBroker && <div key={rowData.members.sellerBroker}>{`Seller Broker: ${rowData.members.sellerBroker}`}</div>}
          {rowData.members.buyerBroker && <div key={rowData.members.buyerBroker}>{`Buyer Broker: ${rowData.members.buyerBroker}`}</div>}
        </>
      )),
      customFilterAndSearch: (term, rowData) => (rowData.members ? (`
        ${rowData.members.seller && `Seller: ${rowData.members.seller}`}
        ${rowData.members.buyer && `Buyer: ${rowData.members.buyer}`}
        ${rowData.members.sellerBroker && `Seller Broker: ${rowData.members.sellerBroker}`}
        ${rowData.members.buyerBroker && `Buyer Broker: ${rowData.members.buyerBroker}`}
        `.toLowerCase()) : '').indexOf(term.toLowerCase()) !== -1,
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && Object.keys(openDeal).length !== 0) {
      history.push(
        `deal-room/${openDeal.dealRoomId}`,
        {
          dealId: openDeal.id,
          dealRoomId: openDeal.dealRoomId,
        },
      );
    }
  }, [error, isLoading, openDeal, history]);

  useEffect(() => {
    if (error) {
      setOpenDeal({} as ItemOfDealList);
    }
  }, [error, setOpenDeal]);

  return (
    <TableContainer>
      <MaterialTable
        title=""
        columns={getRole(userRole) !== 'Admin' ? columns : columnsAdmin}
        // components={ChevronRightIcon}
        data={currentListOfSettlements}
        // actions={[
        //   {
        //     icon: () => <OpenContract fontSize="small" />,
        //     tooltip: 'Open deal room',
        //     onClick: (event, rowData) => handleOpenDealRoom(rowData as ItemOfDealList),
        //   },
        // ]}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
          },
          pageSize: +cookies.rowListOfSettlements || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowListOfSettlements', pageSize, { path: '/' })}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
        }}
      />
    </TableContainer>
  );
};

export default ListOfSettlements;
