import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

export const apiGetAllRequests = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllRequests}${userId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiGetAllRequestsByAdminId = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllRequestsByAdminId}${userId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiTakeRequest = (
  idRequest: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.takeRequestFromQueue}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   {},
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiApproveRoleRequest = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.approveRoleRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { comment },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { comment },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiDeclineRoleRequest = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.declineRoleRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { reasonForDecline },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { reasonForDecline },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiApproveInventoryRequest = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.approveInventoryRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { comment },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { comment },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiDeclineInventoryRequest = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.declineInventoryRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { reasonForDecline },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { reasonForDecline },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiApproveBrokerClientRequest = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.approveBrokerClientRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { comment },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { comment },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiDeclineBrokerClientRequest = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.declineBrokerClientRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { reasonForDecline },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  //   const { data } = await axios.post(
  //     url,
  //     { reasonForDecline },
  //     { headers: { Authorization: `Bearer ${token.accessToken}` } },
  //   );
  //   return data;
};

export const apiGetBrokerClientRequestInfo = (
  idRequest: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getBrokerClientRequestInfo}${idRequest}/${userId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiApproveDealRequest = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.approveDealRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { comment },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { comment },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiDeclineDealRequest = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.declineDealRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { reasonForDecline },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { reasonForDecline },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiGetChangeEmailRequest = (
  idRequest: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getChangeEmailRequest}${idRequest}/${userId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiApproveChangeEmailRequest = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.approveChangeEmailRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { comment },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { comment },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiDeclineChangeEmailRequest = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.declineChangeEmailRequest}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    { reasonForDecline },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { reasonForDecline },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};


export const apiSendRequestBackToQueue = (
  idRequest: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.sendRequestBackToQueue}${idRequest}/${userId}`;
  return request(
    url,
    'POST',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   {},
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};
