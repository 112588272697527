import { FC, useState, useCallback, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { RootState } from "../../store/slices";
import {
  // addDisplayedNotification, 
  delNotification
} from "../../store/slices/snackbar.slice";
import { Button } from "react-scroll";

export const Snackbar: FC<{
  newDeals: number;
  dealDetails?: any[] | undefined;
}> =
  ({
    newDeals,
    dealDetails,
  }) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();
    const [displayed, setDisplayed] = useState<number[]>([]);

    // const { notifications, displayedNotifications } = useSelector((state: RootState) => state.snackbar);

    const {
      notifications,
    } = useSelector(
      (state: RootState) => state.snackbar,
    );

    const storeDisplayed = useCallback(
      (key: number) => {
        setDisplayed([...displayed, key]);
      },
      [displayed, newDeals, dealDetails],
    );

    useEffect(() => {
      notifications.forEach((notification) => {
        setTimeout(() => {
          if (displayed.indexOf(notification.key) > -1) return;
          enqueueSnackbar(notification.message, notification.options);
          storeDisplayed(notification.key);
          dispatch(delNotification({ key: notification.key }));
        }, 1);
      });
    }, [ notifications ])

    // notifications.forEach((notification) => {
    //   console.log('notification_SNACKBAR :>> ', notification);
    //   setTimeout(() => {
    //     console.log('object :>> ', displayed.indexOf(notification.key));
    //     if (displayed.indexOf(notification.key) > -1) return;
    //   console.log('notification_SNACKBAR :>> ', 'RENDER_HER');

    //     enqueueSnackbar(notification.message, notification.options);
    //     storeDisplayed(notification.key);
    //     dispatch(delNotification({ key: notification.key }));
    //   }, 1);
    // });

    return null;
  };

export default memo(Snackbar);
