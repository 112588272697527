import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const Deals = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 14">
    <path d="M15.3657 3.71021C15.6063 3.60495 15.6051 3.26323 15.3637 3.15967L8.27602 0.118438C8.09978 0.0428135 7.90022 0.0428132 7.72398 0.118438L0.636281 3.15967C0.394941 3.26323 0.393731 3.60495 0.634331 3.71021L7.71943 6.80994C7.89829 6.88819 8.10171 6.88819 8.28057 6.80994L15.3657 3.71021Z" />
    <path d="M8.26614 11.4903C8.08961 11.566 7.88975 11.5658 7.71335 11.4898L3.09165 9.4989C2.91672 9.42354 2.71864 9.42272 2.5431 9.49663L0.650528 10.2934C0.406642 10.3961 0.405459 10.7412 0.648636 10.8456L7.72398 13.8815C7.90022 13.9571 8.09978 13.9571 8.27602 13.8815L15.3527 10.845C15.5957 10.7407 15.5947 10.3959 15.3512 10.293L13.4641 9.49531C13.2888 9.42117 13.0907 9.42168 12.9157 9.49671L8.26614 11.4903Z" />
    <path d="M15.3493 7.27908C15.5928 7.17461 15.5912 6.82887 15.3468 6.72663L13.5773 5.9864C13.4017 5.91296 13.2038 5.91417 13.0292 5.98974L8.28126 8.04414C8.10406 8.12082 7.90305 8.1209 7.72579 8.04436L2.96403 5.98828C2.78918 5.91279 2.59111 5.91179 2.41551 5.98553L0.651594 6.72625C0.407495 6.82876 0.406154 7.17415 0.649451 7.27855L7.72398 10.3141C7.90022 10.3898 8.09978 10.3898 8.27602 10.3141L15.3493 7.27908Z" />
  </SvgIcon>
);

export default Deals;
