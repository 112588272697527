import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import store from './store';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { theme } from './indexTheme';
import './i18n';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3800}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
