import { createSlice } from '@reduxjs/toolkit';
import {
  apiCreateInventory,
  apiGetInventory,
  apiGetInventoryById,
  apiUpdateInventory,
  apiGetAllExistedInventory,
  apiDeleteInventory,
} from '../../api/inventory';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import { InventoryState, Inventory } from '../../interfaces/inventory.interface';
import translationEN from '../../locales/en/translation.json';

const initialState: InventoryState = {
  listOfInventory: [],
  inventory: null,
  error: '',
  isLoading: false,
};

const inventorySlice = createSlice({
  initialState,
  name: 'inventory',
  reducers: {
    inventoryRequest(state) {
      state.inventory = null;
      state.error = '';
      state.isLoading = true;
    },
    inventorySuccess(state, action) {
      state.inventory = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    inventoryFailure(state, action) {
      state.inventory = null;
      state.error = action.payload;
      state.isLoading = false;
    },
    listInventoryRequest(state) {
      state.inventory = null;
      state.listOfInventory = [];
      state.error = '';
      state.isLoading = true;
    },
    listInventorySuccess(state, action) {
      state.inventory = null;
      state.listOfInventory = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    listInventoryFailure(state, action) {
      state.inventory = null;
      state.listOfInventory = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    updateListInventorySuccess(state, action) {
      if (Array.isArray(action.payload)) {
        state.listOfInventory = action.payload;
      } else {
        const index = state.listOfInventory
          .findIndex((obj: Inventory) => obj.id === action.payload.id);
        if (index !== -1) {
          state.listOfInventory[index] = action.payload;
        } else {
          state.listOfInventory.push(action.payload);
        }
      }
    },
  },
});

export const {
  inventoryRequest,
  inventorySuccess,
  inventoryFailure,
  listInventoryRequest,
  listInventorySuccess,
  listInventoryFailure,
  updateListInventorySuccess,
} = inventorySlice.actions;

export const createInventory = (
  inventoryData: FormData,
  relocate: () => void,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiCreateInventory(inventoryData, accountId, token));
    dispatch(addNotification({
      key,
      message: translationEN.InventoryCreatedSuccessfully,
      options: { variant: 'success' },
    }));
    relocate();
  } catch (error) {
    dispatch(inventoryFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};
export const deleteInventory = (
  inventoryItemId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch, state) => {
  try {
    await dispatch(apiDeleteInventory(inventoryItemId, token));
    dispatch(addNotification({
      key,
      message: translationEN.InventoryDeletedSuccessfully,
      options: { variant: 'success' },
    }));
    dispatch(listInventorySuccess(state().inventory.listOfInventory
      .filter((item) => item.id !== inventoryItemId)));
  } catch (error) {
    dispatch(listInventoryFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const updateInventory = (
  idInventory: number,
  inventoryData: FormData,
  relocate: () => void,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await dispatch(apiUpdateInventory(idInventory, inventoryData, accountId, token));
    dispatch(addNotification({
      key,
      message: translationEN.InventoryUpdatedSuccessfully,
      options: { variant: 'success' },
    }));
    relocate();
  } catch (error) {
    dispatch(inventoryFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getInventory = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
  status?: string | undefined,
  sortType?: string | undefined,
  sortBy?: string | undefined,
  limit?: number | undefined,
  offset?: number | undefined,
): AppThunk => async (dispatch) => {
  try {
    dispatch(listInventoryRequest());
    const inventoryInfo = await dispatch(apiGetInventory(accountId, token, status, sortType, sortBy, limit, offset));
    dispatch(listInventorySuccess(inventoryInfo));
  } catch (error) {
    dispatch(listInventoryFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getInventoryById = (
  idInventory: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(inventoryRequest());
    const inventoryInfo = await dispatch(apiGetInventoryById(idInventory, token));
    dispatch(inventorySuccess(inventoryInfo));
  } catch (error) {
    dispatch(inventoryFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getAllExistedInventory = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(listInventoryRequest());
    const inventoryInfo = await dispatch(apiGetAllExistedInventory(accountId, token));
    dispatch(listInventorySuccess(inventoryInfo));
  } catch (error) {
    dispatch(listInventoryFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default inventorySlice.reducer;
