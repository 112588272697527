import moment from 'moment-timezone';

export const formatAMPM = (utcDate: Date) => {
  const date = new Date(utcDate);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const minutesValue = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `at ${hours}:${minutesValue} ${ampm}`;
  return strTime;
};
export const formatMonth = (date: string) => ((new Date(date).getMonth() + 1) < 10 ? '0' : '');

export const formatDate = (date: string) => (new Date(date).getDate() < 10 ? '0' : '');

export const formatHour = (leftTimestamp: number) => (Math.floor((leftTimestamp % 86400000) / 3600000) < 10 ? '0' : '');
export const formatMinutes = (leftTimestamp: number) => (Math.floor((leftTimestamp % 3600000) / 60000) < 10 ? '0' : '');
export const formatSeconds = (leftTimestamp: number) => (Math.floor((leftTimestamp % 60000) / 1000) < 10 ? '0' : '');

export const getDate = (utcDate: string) => {
  const date = new Date(utcDate).toString();
  return `${formatMonth(date) + (new Date(date).getMonth() + 1)}/${formatDate(date) + new Date(date).getDate()}/${new Date(date).getFullYear()}`;
};
// export const getDateForContract = (utcDate: string) => {
//   const date = new Date(utcDate).toString();
//   // `${formatDate(date) + new Date(date).getDate()} day of ${new Date(date).toLocaleString('en-US', { month: 'long' })} ${new Date(date).getFullYear()}`;
//   return `${moment(date).tz('America/New_York').format('lll')} EST`;
// };

export const getDateTime = (utcDate: string) => {
  const date = new Date(utcDate).toString();
  return `${formatMonth(date) + (new Date(date).getMonth() + 1)}/${formatDate(date) + new Date(date).getDate()}/${new Date(date).getFullYear()} ${formatAMPM(new Date(date))}`;
};
export const getLeftTime = (data: string, currentDate: Date) => {
  const leftTimestamp = +(new Date(data)) - +(currentDate);
  if (leftTimestamp >= 0) {
    return `Day: ${Math.floor(leftTimestamp / 86400000)}, Time: ${formatHour(leftTimestamp) + Math.floor((leftTimestamp % 86400000) / 3600000)}:${formatMinutes(leftTimestamp) + Math.floor((leftTimestamp % 3600000) / 60000)}:${formatSeconds(leftTimestamp) + Math.floor((leftTimestamp % 60000) / 1000)}`;
  } else {
    return 'Day: 0, Time: 00:00:00';
  }
};
export const getDateUTC12H = (date: Date | null) => {
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset();
  return date ? new Date(date.setHours(12 + offset / 60, 0, 0, 0)) : null;
};
