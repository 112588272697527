import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    alignItems: 'center',
  },
  titlePanel: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  paddingBottom16: {
    paddingBottom: 16,
  },
  marginTop32: {
    marginTop: 32,
  },
  underline: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  root: {
    height: '100%',
  },
  container: {
    height: 'calc(100% - 57px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    fontSize: 12,
    lineHeight: '14px',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 48px)',
      fontSize: 13,
      lineHeight: '15px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },

  card: {
    '&:first-child': {
      marginTop: 56,
    },
    // maxHeight: 104,
  },
  titleCard: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 5,
    minHeight: 40,
  },
  titleItem: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    alignSelf: 'center',
  },
  cardItems: {
    width: 'calc(100% - 120px)',
    alignSelf: 'center',
  },
  rootTab: {
    flex: 1,
  },
  ordersContainerRight: {
    paddingRight: 8,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
  },
  ordersContainerLeft: {
    paddingRight: 8,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
  },
}));

export default useStyles;
