import React, {
  FC,
} from 'react';
import { useParams } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import { useLocation } from 'react-router';
import CreateSellerOrder from './CreateSellerOrder';
import CreateBuyerOrder from './CreateBuyerOrder';
import PaperBrokerClient from '../PaperBrokerClient/PaperBrokerClient';

export const CreateOrder: FC = () => {
  const location: any = useLocation();
  const { inventoryItemId } = useParams<{ inventoryItemId: string }>();
  const inventoryId = inventoryItemId || (location.state && location.state.inventoryId);
  return (
    <>
      <Hidden smUp>
        <PaperBrokerClient />
      </Hidden>
      {
        inventoryId ?
          (<CreateSellerOrder />)
          :
          (<CreateBuyerOrder />)
      }
    </>
  );
};
export default CreateOrder;
