import { createTheme } from '@material-ui/core';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
});
const { breakpoints } = defaultTheme;

export const arimo = {
  fontFamily: 'Arimo',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  alignItems: 'center',
  src: `
    local('Arimo'),
    local('Arimo-Regular'),
    url('./font/Arimo-Regular.ttf) format('ttf')
  `,
};

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [arimo],
      },
    },
    MuiDialog: {
      paper: {
        padding: '16px',
        overflow: 'hidden',
        borderRadius: 15,
        marginLeft: 272,
        '@media (max-width: 1280px)': {
          marginLeft: '114px',
      },
      '@media (max-width: 768px)': {
          margin: '16px',
      },
      },
      paperWidthSm: {
        minWidth: 300,
        minHeight: '160px'
      },
      scrollBody: {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: 0,
        },
        marginBottom: '20px',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        // textAlign: 'center',
          },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 5,
        '&$focused $notchedOutline': {
          borderColor: '#87BC14',
          borderWidth: 1,
        },
        '&:hover $notchedOutline': {
          borderColor: '#87BC14',
        },
      },
    },
    MuiCardHeader: {
      avatar: {
        marginRight: '8px',
      }
    },
    MuiCardActions: {
      root: {
        justifyContent: 'flex-end',
        marginRight: '18px',
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 4,
          padding: 2,
          paddingLeft: 4,
        },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#F0F4F8',
        },
        '&$selected:hover': {
          backgroundColor: '#F0F4F8',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#F0F4F8',
        },
      },
    },
    MuiMenuItem: {
      root: {
        lineHeight: '16px',
        padding: '4px 27px',
      },
    },
    MuiSelect: {
      icon: {
        color: '#87BC14',
      },
      select: {
        color: '#075955',
      },
    },
    MuiSlider:
    {
      root: {
        color: '#87BC14',
      },
      markLabel: {
        color: '#979BA9',
      },
      markLabelActive: {
        color: '#979BA9',
      },
      valueLabel: {
        fontSize: 12,
        left: 'calc(-50% - 4px)',
        top: -30,
      },
      rail: {
        color: '#D5D5D7',
        opacity: 1,
      },
      mark: {
        backgroundColor: 'transparent',
      },
      markActive: {
        backgroundColor: 'transparent',
      },
    },
    MuiInput: {
      underline: {
        '&:hover': {
          borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        transition: 'none',
      },
    },
    // MuiFormLabel: {
    //   root:{
    //     width: '100%',
    //   }
    // },
    MuiInputBase: {
      input: {
        '@media (max-width: 600px)': {
          height: 32,
        },
        // width: '320px',
        // paddingLeft: 0,
        // background: 'red',
        // '@media (min-width: 560px)': {
        //   width: '150px',
        //   paddingLeft: 0,
        // },
        '&.Mui-disabled': {
          color: '#075955',
          '-webkit-text-fill-color': '#075955',
        },
      },
    },
    MuiFormLabel: {
      root: {
        minwidth: '50px',
        '&$focused': {
          color: '#87BC14',
        },
        '&.Mui-disabled': {
          color: '#979BA9',
        },
      },
    },
    
    MuiFormControlLabel: {
      label: {
        '&.Mui-disabled': {
          color: '#979BA9',
        },
      },
    },   
    // MuiFormControl: {
    //   root: {
    //     margin: '0px'
    //   },
    // },
    MuiPaper: {
      elevation8: {
        boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiTabs: {
      root: {
        height: 40,
        minHeight: 40,
        '&.MuiPickerDTTabs-tabs': {
          backgroundColor: 'transparent',
        },
      },
      indicator: {
        backgroundColor: 'transparent',
      },
      flexContainer: {
        height: 40,
      },
    },
    MuiTab: {
      root: {
        height: 40,
        minHeight: 40,
        minWidth: 40,
        border: '1px solid #D5D5D7',
        '@media (min-width: 600px)': {
          minWidth: 40,
        },
        '&:hover': {
          border: '1px solid #87BC14',
          color: '#87BC14',
        },
        '&$selected': {
          fontWeight: 'bold',
          border: '1px solid #075955',
          color: '#075955',
        },
        '&$selected:hover': {
          border: '1px solid #87BC14',
          color: '#87BC14',
        },
        '&:first-child': {
          borderRadius: '5px 0 0 5px',
        },
        '&:last-child': {
          borderRadius: '0 5px 5px 0',
        },
      },
      textColorInherit: {
        opacity: 1,
        color: '#979BA9',
      },
      wrapper: {
        fontSize: 12,
        lineHeight: '14px',
        '@media (min-width: 960px)': {
          fontSize: 14,
          lineHeight: '16px',
        },
        textTransform: 'capitalize',
        color: 'inherit',
      },
    },
    MuiSnackbarContent: {
      root: {
        '&[class*="SnackbarItem-variantError"]': {
          backgroundColor: '#DC5D5D',
        },
        '&[class*="SnackbarItem-variantSuccess"]': {
          backgroundColor: '#87BC14',
        },
      },
      message: {
        height: 50,
        fontSize: 16,
        display: 'flex',
      },
    },
    MuiChip: {
      root: {
        border: '1px solid #87BC14',
        margin: 5,
      },
      colorPrimary: {
        color: '#87BC14',
        backgroundColor: '#FEFEFE',
      },
      deletableColorPrimary: {
        '&:focus': {
          backgroundColor: '#FEFEFE',
        },
      },
      deleteIconColorPrimary: {
        color: '#87BC14',
        '&:hover': {
          color: '#87BC14',
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        width: 'calc(100% - 48px)',
      },
    },
  },
  palette: {
    primary: {
      light: '#87BC14',
      main: '#075955',
    },
    secondary: {
      light: '#F0F4F8',
      main: '#979BA9',
      dark: '#32343C',
      contrastText: '#D5D5D7',
    },
    error: {
      main: '#DC5D5D',
      light: '#FCE48F',
    },
    text: {
      primary: '#32343C',
      secondary: '#979BA9',
      disabled: '#D5D5D7',
    },
  },
  typography: {
    fontFamily: 'Arimo, Arial',
    body1: {
      lineHeight: '16px',
    },
    body2: {
      lineHeight: '16px',
    },
    h1: {
      textTransform: 'capitalize',
      fontSize: 18,
      lineHeight: '21px',
      fontWeight: 'bold',
      [breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '23px',
      },
      [breakpoints.up('md')]: {
        fontSize: 24,
        lineHeight: '28px',
      },
    },
    h2: {
      textTransform: 'capitalize',
      fontSize: 18,
      lineHeight: '21px',
      fontWeight: 'bold',
    },
  },
});
