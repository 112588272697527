import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  main: {
    margin: '40px auto 0',
    width: '80%',
  },
  drawer: {
    width: drawerWidth,
  },

  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.palette.secondary.dark,
    justifyContent: 'space-between',
  },
  listItemText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
  },
  commonListItemText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    margin: '1px 0',
  },
  createOrder: {
    backgroundColor: theme.palette.secondary.dark,
    margin: '0 16px',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'rgba(240, 244, 248, 0.1)',
      borderRadius: 5,
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '0 16px',
      [theme.breakpoints.up('sm')]: {
        width: 208,
        margin: 'unset',
      },
      [theme.breakpoints.up('lg')]: {
        margin: '0 16px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: 208,
      margin: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 16px',
    },
  },
  createOrderDetails: {
    [theme.breakpoints.between(768, 1280)]: {
      paddingLeft: 0,
    },
  },
  createOrderIcon: {
    color: theme.palette.secondary.contrastText,
    margin: '0 10px',
  },
  createOrderExpandIcon: {
    padding: 0,
  },
  createOrderSummaryRoot: {
    padding: 0,
    minHeight: 40,
    '&.Mui-expanded': {
      minHeight: 40,
      margin: 0,
    },
  },
  createOrderSummaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      minHeight: 40,
      margin: 0,
    },
  },
  createOrderListItem: {
    padding: 0,
    height: 40,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
      borderRadius: 5,
      borderBottom: 'none',
    },
  },
  createOrderListItemActive: {
    color: theme.palette.primary.light,
  },
  createOrderListItemText: {
    color: theme.palette.secondary.contrastText,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  listItem: {
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'rgba(240, 244, 248, 0.1)',
      borderRadius: 5,
      borderBottom: 'none',
    },
    padding: 0,
    width: '92%',
    height: 40,
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      width: 208,
      margin: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 16px',
    },
  },  
  listItemAllerNewMessage: {
    marginBottom: '8px',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'rgba(240, 244, 248, 0.1)',
      borderRadius: 5,
      borderBottom: 'none',
    },
    padding: 0,
    width: '92%',
    height: 40,
    margin: '8px 16px',
    [theme.breakpoints.up('sm')]: {
      width: 208,
      margin: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '8px 16px',
    },
    animationName: '$NewMessageBlinker',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },
  '@keyframes NewMessageBlinker': {
    '0%': {
      background: 'rgba(240, 244, 248, 0.1)',
      border: '1px solid #green', 
      // margin: '8px 16px',
      
    },
    '100%': {
      background: '#548554',
      borderRadius: '8px',
      border: '1px solid #green', 
      // margin: '8px 16px',

    },
  },
  commonListItem: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'rgba(240, 244, 248, 0.1)',
      borderRadius: 5,
      borderBottom: 'none',
    },
    padding: 0,
    height: 32,
    width: '92%',
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      width: 208,
      margin: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 16px',
    },
  },
  linkItem: {
    textDecoration: 'none',
    height: 40,
    [theme.breakpoints.up('lg')]: {
      height: 40,
    },
  },
  listItemActive: {
    color: theme.palette.secondary.contrastText,
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    [theme.breakpoints.between('sm', 'md')]: {
      color: theme.palette.primary.light,
      borderBottom: 'none',
    },
  },
  menuIcon: {
    color: 'inherit',
    paddingLeft: '10px',
  },
  commonListMenuIcon: {
    color: 'inherit',
    paddingLeft: '10px',
    minWidth: '40px',
  },
  versionStyle: {
    display: 'block',
    marginLeft: '16px',
    position: 'absolute', 
    color: '#FEFEFE', 
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
      textOverflow: 'ellipsis',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'normal',
      fontSize: '12px',
      textAlign: 'left',
      '@media (max-width: 768px)': {
        '&:hover': {
          color: 'red',
          zIndex: 100,
          position: 'fixed',
        }
      },
    }, 
  }
}));

export default useStyles;
