import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  main: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
      width: 'calc(100% - 240px)',
    },
    backgroundColor: theme.palette.secondary.light,
  },
  content: {
    height: '100%',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: 42,
    },
  },
  cookieContainer: {
    width: '100%',
    height: 'auto',
    padding: 15,
    zIndex: 1300,
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
    },
  },
  alertSoundModal: {
    fontSize: '16px',
    position: 'absolute',
    zIndex: 10000,
    width: '40vw',
    height: '8vh',
    top: '2vh',
    right: '25vw',
    background: '#8ddbb99e',
    opacity: 0.7,
    borderRadius: '5px',
    transition: '0.7s',
    '&:hover': {
      background: '#619b81b8',
      opacity: 0.9,
      color: 'white'
    },
    '@media (max-width: 680px)': {
      fontSize: '12px',
      width: '60vw',
      top: '5vh',
      right: '15vw',
    }
  },

}));

export default useStyles;
