import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fileUploader: {
    margin: '24px 0',
    position: 'relative',
    height: 40,
    background: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    boxShadow: 'none',
    color: theme.palette.primary.dark,
    '&:hover': {
      background: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      boxShadow: 'none',
      color: 'white',
    },
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
  },
  fileUploaderInput: {
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: 40,
  },
  buttonName: {
    textAlign: 'center',
    marginTop: 11,
  },
  body1: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: '#979BA9',
  },
  body2: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
    color: '#979BA9',
    opacity: 0.5,
  },
  docName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
  },
  fileIcon: {
    minWidth: 37,
    maxWidth: 37,
  },
  docList: {
    // maxHeight: 220,
    // [theme.breakpoints.up('lg')]: {
    //   maxHeight: '65%',
    // },
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export default useStyles;
