/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { useCookies } from 'react-cookie';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  Grid,
  Typography,
  DialogContent,
  Fab,
  TextField,
  Button,
  Avatar,
  Divider,
  Link,
} from '@material-ui/core';
// import TextField from '@mui/material/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { getAllMessagesAndAlerts } from '../../store/slices/alertsAndMessages.slice';
import { RootState } from '../../store/slices';
import {
  AlertsAndMessages,
  OrderQuestionWithAnswers,
} from '../../interfaces/alertsAndMessages.interface';
import { getDateTime, getDate } from '../../utils/getDate';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import useStyles from './style';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
import SendIcon from '@material-ui/icons/Send';
import {
  loadAnswersForOrderQuestion,
  createOrderQuestionAnswer,
  markAsViewedAnswer,
} from '../../store/slices/orderQuestion.slice';
import constants from '../../core/constants';


const ListMessagesAndAlerts: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['rowListInventory']);
  const [openInfo, setOpenInfo] = useState(false);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [openFullSizeImageDialog, setOpenFullSizeImageDialog] = useState(false);
  const [openImageSrc, setOpenImageSrc] = useState('');

  const [openOrderQuestionAnswersDialog, setOpenOrderQuestionAnswersDialog] =
    useState(false);

  const [currentAlertsAndMessagesList, setListOfAlertsAndMessages] = useState<
    AlertsAndMessages[]
  >([]);

  const [orderQuestionWithAnswers, setOrderQuestionWithAnswers] =
    useState<OrderQuestionWithAnswers>();

  const { orderQuestionAnswers } = useSelector(
    (state: RootState) => state.orderQuestion,
  );

  const handleOpenFullSizeImageDialog = async (imgSrc: any) => {
    setOpenFullSizeImageDialog(true);
    setOpenImageSrc(imgSrc);
  };

  const handleSendAnswer = async (
    sendAnswerText: string,
    orderQuestionId: number | null
  ) => {
    const key = new Date().getTime() + Math.random();

    const { token } = getLocalStorageData();

    await dispatch(
      createOrderQuestionAnswer(orderQuestionId, sendAnswerText, token, key)
    );
    setOpenOrderQuestionAnswersDialog(false);
    setCurrentMessage('');
    setCurrencyHour('');
    setCurrencyMinute('');
    setCurrencyAmPm('');
    setCalendar(new Date());
  };

  const handleGetValueDynamicField = (item: any) => {
    if (item.typeOfField === 'DATE') return (item.value && getDate(item.value as string)) || '';
    if (item.typeOfField === 'NUMBER' && item.format === 'PERCENT' && item.value) return `${item.value} %`;
    if (item.typeOfField === 'NUMBER' && item.format === 'DOLLAR' && item.value) return `$ ${item.value}`;
    if (item.typeOfField === 'BOOLEAN') return item.value === 'false' ? 'No' : 'Yes';
    return item.value || '';
  };

  const getDocumentsField = (item: any) => (
    item.typeOfField === 'DOCUMENTS' && (
      item.proveDocuments &&
      item.proveDocuments.map((doc: { link: string; title: string }) => (
        <Grid container key={item.name}>
          <Grid item xs={6}>
            <Typography variant='body1' color='primary'>
              {`${(item.name).replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body1' className={classes.docName}>
              <Link key={doc.link} href={`${constants.AWS_URL}${doc.link}`} target='_blank' rel='noreferrer'>
                {doc.title}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      ))
    )
  );

  const getField = (item: any) => (
    item.typeOfField !== 'DOCUMENTS' && (
      <Grid container key={item.name}>
        <Grid item xs={6}>
          <Typography variant='body1' color='primary'>
            {`${(item.name).replace(/\/,/g, '.')}:`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body1'>
            {handleGetValueDynamicField(item)}
          </Typography>
        </Grid>
      </Grid>
    )
  );

  const handleLoadAnswersForOrderQuestion = async (data: AlertsAndMessages) => {
    const key = new Date().getTime() + Math.random();
    const { token } = getLocalStorageData();
    await dispatch(loadAnswersForOrderQuestion(data.id, token, key));
    if (data.answered) {
      await dispatch(markAsViewedAnswer(data.orderAnswers[0].id, token));
    }
    setOpenOrderQuestionAnswersDialog(true);
    handleGetDisabledStatus(data);
  };

  const isShowHideAnsweButtons = useCallback(() => {
    const { accountId, clientAccountId } = getLocalStorageData();
    if (orderQuestionWithAnswers) {
      return (
        (orderQuestionWithAnswers?.accountOfWhoIsTheQuestionFor.id ===
          clientAccountId ||
          accountId) &&
        !orderQuestionWithAnswers?.answered
      );
    }
    return false;
  }, [orderQuestionWithAnswers]);

  const renderButtonsAnswers = () => (
    <Grid
      container
      justifyContent='space-between'
      className={classes.marginTopAndBottom}
    >
      <Grid container direction='row' justifyContent='space-between'>
        <Grid item>
          <ButtonSC
            variant='fill'
            text='Yes'
            size='large'
            color='green'
            handleClick={() =>
              handleSendAnswer('Yes', orderQuestionWithAnswers?.id || null)
            }
          />
        </Grid>

        <Grid item>
          <ButtonSC
            variant='fill'
            text='No'
            size='large'
            color='green'
            handleClick={() =>
              handleSendAnswer('No', orderQuestionWithAnswers?.id || null)
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
  useEffect(() => {
    setOrderQuestionWithAnswers(orderQuestionAnswers);
  }, [orderQuestionAnswers]);
  const [disableAnswer, setDisableAnswer] = useState(false);
  const handleGetDisabledStatus = useCallback(
    (data: AlertsAndMessages) => {
      const {
        accountId,
        clientAccountId,
      } = getLocalStorageData();
      if (
        data.accountOfWhoIsTheQuestionFor.id !== (clientAccountId || accountId) && !data.answered
      ) return setDisableAnswer(true);
      return setDisableAnswer(false);
    },
    [],
  );


  const handleGetOwnerQuestion = useCallback(
    (data: AlertsAndMessages) => {
      const {
        accountId,
        clientAccountId,
      } = getLocalStorageData();
      if (
        data.accountOfWhoIsTheQuestionFor.id !== (clientAccountId || accountId)
      ) return true;
      return false;
    },
    [],
  );

  const handleGetBtnText = useCallback(
    (id: number) => {
      const {
        accountId,
        clientAccountId,
      } = getLocalStorageData();
      return (id === (clientAccountId || accountId)) ? 'Answer' : 'View Answers';
    },
    [],
  );

  const columns: Array<Column<AlertsAndMessages>> = [
    {
      field: '',
      render: (rowData) => (
        // <div className={(rowData.answered) ?  classes.buttonAnimationNon : classes.buttonAnimation }>
        <div className={(handleGetOwnerQuestion(rowData) && rowData.orderAnswers.length > 0 ? (rowData.orderAnswers[0].viewed && 'green') || classes.buttonAnimation : classes.buttonAnimationNon) || classes.buttonAnimationNon}>
          <ButtonSC
            variant='fill'
            text={handleGetBtnText(rowData.accountOfWhoIsTheQuestionFor.id)}
            size='large'
            color={
              (handleGetOwnerQuestion(rowData) && rowData.orderAnswers.length > 0 ? (rowData.orderAnswers[0].viewed && 'green') || 'red' : 'green') || 'green'
            }
            // disabled={handleGetDisabledStatus(rowData)}
            handleClick={() => {
              handleLoadAnswersForOrderQuestion(rowData);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Type',
      render: (rowData) =>
        rowData.hasOwnProperty('answered') ? 'MESSAGE' : 'ALERT',
      customFilterAndSearch: (term, rowData) =>
        (rowData.hasOwnProperty('answered') ? 'MESSAGE' : 'ALERT')
          .toLowerCase()
          .indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Status',
      render: (rowData) => (rowData.answered ? 'ANSWERED' : 'UNANSWERED'),
      customFilterAndSearch: (term, rowData) =>
        (rowData.answered ? 'ANSWERED' : 'UNANSWERED')
          .toLowerCase()
          .indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Date',
      field: 'createdAt',
      type: 'date',
    },
    {
      title: 'Order type',
      field: 'order.orderType',
    },
    {
      title: 'Total Price',
      field: 'order.price',
      render: (rowData) => `$ ${rowData?.order?.price.toFixed(2)}`,
    },

    {
      title: 'Order Number',
      field: '',
      render: (rowData) => {
        return rowData.order.orderNumber;
      },
    },

    {
      title: 'Inventory Type',
      field: 'order.inventory.inventoryItemType.type',
    },

    {
      title: 'Info',
      field: '',
      render: (rowData) => (
        <>
          <Button
            aria-label="info"
            onClick={() => {
              if (rowData.order.inventory) {
                // setInventoryDocs(inventory.labAttestationDocs);
                // setInventoryAttest(inventory.labAttest);
                setOpenInfo(true);
              }
            }}
            className={classes.infoButton}
            classes={{
              label: classes.labelBtn,
            }}
          >
            {rowData?.order?.inventory?.productPicture?.length ? (
              <Avatar
                variant='rounded'
                alt='Avatar'
                className={classes.picture}
                src={
                  rowData.order.inventory.productPicture &&
                  rowData.order.inventory.productPicture[0] &&
                  `${constants.AWS_URL}${rowData.order.inventory.productPicture[0].link}`
                }
              />
            ) : (
              <div
                className={
                  rowData.order.inventory && rowData.order.inventory.labAttest
                    ? classes.infoColorAttest
                    : classes.infoColorNoAttest
                }
              >
                More info
              </div>
            )}
          </Button>

          {/* HERE ARE THE NEW DIALOG */}

          <Dialog
            open={openInfo}
            onClose={() => setOpenInfo(false)}
            scroll='paper'
            classes={{ paper: classes.dialogPaper }}
            // className={classes.dialogWrapper}

          >
            <DialogTitle>
              {rowData.order.orderType === 'BUY' ? 'Order Info' : 'Inventory Sheet'}
            </DialogTitle>
            <Divider />

            <IconButton
              aria-label='close'
              onClick={() => setOpenInfo(false)}
              className={classes.closeModalIcon}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent className={classes.dialogContent}>
              {rowData.order.orderType === 'SELL' && (
                <Grid container className={classes.dialogPadding40}>
            <Grid item xs={6}>
                    <Typography variant='body1' color='primary'>
                      Order №:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>{rowData.order?.orderNumber}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1' color='primary'>
                      Title:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <Typography variant='body1'>{rowData.order?.inventory?.inventoryItemType?.type.toLowerCase()}</Typography> */}
                    <Typography variant='body1'>{rowData.order?.inventory?.inventoryItemType?.type}</Typography>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Typography variant='body1' color='primary'>
                      Status:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {rowData.order?.orderStatus}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1' color='primary'>
                      Type:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {rowData.order?.orderType}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1' color='primary'>
                      State:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {rowData.order?.orderState}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1' color='primary'>
                      ZIP:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {rowData.order?.orderZip}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={6}>
                    {rowData.order.inventory?.locationState && rowData.order.inventory?.locationZip && (
                      <Typography variant="body1">
                        {`${rowData.order.inventory?.locationState}, ${rowData.order.inventory?.locationZip.split(' ')[0]
                          }`}
                      </Typography>
                    )}
                  </Grid> */}
                  <Grid item xs={6}>
                    <Typography variant='body1' color='primary'>
                      Price:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {rowData.order?.price}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1' color='primary'>
                      Quantity:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {rowData.order?.quantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {rowData.order.inventory?.productPicture && (
                      <Avatar
                        onClick={() => {
                          if (rowData.order.inventory.productPicture[0]) {
                            handleOpenFullSizeImageDialog(
                              `${constants.AWS_URL}${rowData.order.inventory?.productPicture[0].link}`,
                            );
                          }
                        }}
                        variant="rounded"
                        alt="Avatar"
                        className={classes.picture}
                        src={
                          rowData.order.inventory?.productPicture &&
                          rowData.order.inventory?.productPicture[0] &&
                          `${constants.AWS_URL}${rowData.order.inventory?.productPicture[0].link}`
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              )}
              {rowData.order.orderType === 'SELL' && (
                <Grid container className={classes.dialogPadding40}>
                  {/* {orderBuyFields?.length > 0 &&
                    orderBuyFields?.map((item: any) => getField(item))}
                  {!orderBuyFields?.filter(
                    (item: any) => item.typeOfField !== 'DOCUMENTS'
                  ).length && (
                    <Typography variant="body1">No information</Typography>
                  )} */}
                </Grid>
              )}
            </DialogContent>
          </Dialog>
        </>
      ),
    },
  ];

  const { listOfAlertsAndMessages } = useSelector(
    (state: RootState) => state.alertsAndMessages,
  );

  let id: any = '';
    let userID;
  useEffect(() => {
    const { userId, token, clientAccountId, accountId } = getLocalStorageData();
    id = userId;
    userId ? userID = userId : userID = accountId;
    if (userId) {
      const key = new Date().getTime() + Math.random();
      dispatch(
        getAllMessagesAndAlerts(clientAccountId || accountId, token, key)
      );
    }
  }, [dispatch]);

  useEffect(() => {
    setListOfAlertsAndMessages(
      listOfAlertsAndMessages.map((item) => ({ ...item }))
    );
  }, [setListOfAlertsAndMessages, listOfAlertsAndMessages]);

  const [currentMessage, setCurrentMessage] = useState<string>('');

  const currentHours = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
  ];

  const [currencyHour, setCurrencyHour] = React.useState('');

  const handleChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyHour(event.target.value);
  };

  const currentMinutes = [
    { value: '00', label: '00' },
    { value: '05', label: '05' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '35', label: '35' },
    { value: '40', label: '40' },
    { value: '45', label: '45' },
    { value: '50', label: '50' },
    { value: '55', label: '55' },
  ];

  const [currencyMinute, setCurrencyMinute] = React.useState('');

  const handleChangeMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyMinute(event.target.value);
  };

  const currentAmPm = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ];

  const [currencyAmPm, setCurrencyAmPm] = React.useState('');

  const handleChangeAmPm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyAmPm(event.target.value);
  };

  const [calendar, setCalendar] = React.useState(new Date());

  let dataString = calendar.toString();
  const handleChangeCalendar = (newValue: Date) => {
    setCalendar(newValue);
    dataString = calendar.toString();
    dataString.substring(0, 15);
  };

  let sendAnswerText = currencyHour ? `${currentMessage} Seller suggested a different time:: ${dataString.substring(0, 15)} 
  at ${currencyHour}:${currencyMinute} ${currencyAmPm} EST.` : `${currentMessage} `;

  return (
    <>
      <TableContainer>
        <MaterialTable
          title=""
          columns={columns}
          data={currentAlertsAndMessagesList}
          options={{
            searchFieldStyle: {
              borderBottom: 'none',
              paddingLeft: 0,
            },
            pageSize: +cookies.rowListInventory || 5,
          }}
          onChangeRowsPerPage={(pageSize: number) =>
            setCookie('rowListInventory', pageSize, { path: '/' })
          }
          components={{
            Toolbar: (props) => (
              <MTableToolbar
                {...props}
                classes={{
                  actions: classes.actions,
                  spacer: classes.spacer,
                  root: classes.root,
                }}
              />
            ),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <p className='tableNoRecords'>No records to display</p>
              ),
            },
            header: {
              actions: 'Edit',
            },
          }}
        />
      </TableContainer>

      <Dialog
        open={openOrderQuestionAnswersDialog}
        onClose={() => setOpenOrderQuestionAnswersDialog(false)}
        scroll='paper'
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle className={classes.orderQuestionHeader}>
          {/* Question and answers */}
          <p className={classes.orderNumber}>
            {`Order # ${orderQuestionWithAnswers?.order?.orderNumber ? orderQuestionWithAnswers?.order?.orderNumber : ''}`}
          </p>
          <IconButton
            aria-label='close'
            onClick={() => setOpenOrderQuestionAnswersDialog(false)}
            className={classes.closeModalIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {/* <Grid item xs={12}>
          <Typography variant='body1'>Question:</Typography>
          <Typography variant='body2'>
            <div
              className={classes.textMessagesWrapper}
            >{`${orderQuestionWithAnswers?.text}`}</div>
            <div>
              {
                //@ts-ignore
                `${getDateTime(orderQuestionWithAnswers?.createdAt)}`
              }
            </div>
          </Typography>
        </Grid> */}

        {(!orderQuestionWithAnswers?.answered) && <DialogActions>
          <Grid xs={12} container direction='column' spacing={2}>

            <Grid item xs={12}>
              <Typography variant='body1'>Question:</Typography>
              <Typography variant='body2'>
                <div
                  className={classes.textMessagesWrapper}
                >{`${orderQuestionWithAnswers?.text}`}</div>
                <div>
                  {
                    //@ts-ignore
                    `${getDateTime(orderQuestionWithAnswers?.createdAt)}`
                  }
                </div>
              </Typography>
            </Grid>
            <p>
              Answer:
            </p>

            <Grid item xs={11} className={classes.messageInputWrapper}>
              <TextField
                label='Type your answer here'
                name='message'
                multiline
                variant='outlined'
                id='message'
                fullWidth
                placeholder='Write message'
                onChange={({ target }) => setCurrentMessage(target.value)}
                value={currentMessage}
                inputProps={{ 'data-testid': 'chat-message-input' }}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    handleSendAnswer(
                      sendAnswerText,
                      orderQuestionWithAnswers?.id || null
                    );
                  }
                }}
                disabled={orderQuestionWithAnswers?.answered || disableAnswer}
              />
              <Grid item xs={1} className={classes.sendButtonWrapper}>
                <Fab
                  color="primary"
                  aria-label="SEND"
                  size="small"
                  disabled={orderQuestionWithAnswers?.answered || disableAnswer}
                  onClick={() =>
                    handleSendAnswer(
                      sendAnswerText,
                      orderQuestionWithAnswers?.id || null
                    )
                  }
                >
                  <SendIcon
                  />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>}

        {(!orderQuestionWithAnswers?.answered) && <Grid>
          <p className={classes.InformMessage}>
            Please, refrain from sending email addresses and/or phone numbers.
            You may be blocked from MGTrader.
          </p>
        </Grid>}

        <DialogContent>

          <Grid container spacing={2}>
            {orderQuestionWithAnswers?.answered && <Grid item xs={12}>
              <Typography variant='body1'>Question:</Typography>
              <Typography variant='body2'>
                <div
                  className={classes.textMessagesWrapper}
                >{`${orderQuestionWithAnswers?.text}`}</div>
                <div>
                  {
                    //@ts-ignore
                    `${getDateTime(orderQuestionWithAnswers?.createdAt)}`
                  }
                </div>
              </Typography>
            </Grid>}
            {orderQuestionWithAnswers?.answered && <Grid item xs={12}>
              <Typography variant='body1'>Answer:</Typography>
              {orderQuestionWithAnswers?.orderAnswers.map((element: any) => (
                <Typography
                  variant='body2'
                  key={new Date().getTime() + Math.random()}
                >
                  <div
                    className={classes.textMessagesWrapper}
                  >{`${element.text}`}</div>
                  <div>{`${getDateTime(element.createdAt)}`}</div>
                </Typography>
              ))}
            </Grid>}
            {(orderQuestionWithAnswers?.answered) && <Grid className={classes.InfoWrapper}>
              <p className={classes.InformMessageForSend}>
                You may send another message using "?"
              </p>
            </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ListMessagesAndAlerts;
