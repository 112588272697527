import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

export const apiGetAllBrokerClients = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllClientsOfBroker}${accountId}`;
  return request(
    url,
    'GET',
    { },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiAddUserToBroker = (
  user: FormData,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.addUserToBroker}${accountId}`;
  return request(
    url,
    'POST',
    user,
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
  // const { data } = await axios.post(
  //   url,
  //   user,
  //   { headers: { Authorization: `Bearer ${token.accessToken}`,
  //  ContentType: 'multipart/form-data' } },
  // );
  // return data;
};

export const apiActAsClient = (
  userId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.actAsClient}${userId}/${accountId}`;
  return request(
    url,
    'POST',
    { },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   {},
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};
