import React, { FC } from 'react';
import cx from 'classnames';
import {
  Button,
} from '@material-ui/core';
import useStyles from './Button.style';

interface Props {
  variant: string;
  text: string;
  size: string;
  type?: 'button' | 'reset' | 'submit' | undefined;
  handleClick?: () => void;
  styleClass?: string;
  disabled?: boolean;
  autofocus?: boolean;
  color: 'red' | 'green' | 'lightGreen';
  mobileScreen?: string;
  background?: string;
}

const ButtonSC: FC<Props> = ({
  variant,
  text,
  size,
  handleClick,
  type,
  styleClass,
  disabled,
  children,
  autofocus,
  color,
  mobileScreen,
  background,
}) => {
  const classes = useStyles({ color });

  return (
    <Button
      variant="contained"
      type={type}
      className={cx(
        variant === 'fill' && classes.fill,
        variant === 'outline' && classes.outline,
        size === 'small' && classes.small,
        size === 'middle' && classes.middle,
        size === 'large' && classes.large,
        mobileScreen === 'true' && classes.smallButton,
        styleClass,
        classes.textCapitalize,
      )}
      onClick={handleClick}
      disabled={disabled}
      autoFocus={autofocus}
    >
      <p className={classes.textHide}>
        {text}
      </p>
      {children}
    </Button>
  );
};

export default ButtonSC;
