import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const MarketPlace = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 20">
    <path d="M11.2247 19.3499V0.521782C11.2247 0.290362 11.4756 0.146086 11.6756 0.262517L15.8509 2.69322C15.9432 2.74695 16 2.84569 16 2.95249V15.7891C16 15.8769 15.9615 15.9603 15.8947 16.0173L11.7194 19.5781C11.5246 19.7442 11.2247 19.6058 11.2247 19.3499Z" />
    <path d="M5.58879 17.2291L8.83192 15.7127C8.93743 15.6634 9.00485 15.5574 9.00485 15.441V3.1759C9.00485 3.04512 8.92013 2.92941 8.79545 2.88991L5.55233 1.86249C5.35892 1.80122 5.16172 1.9456 5.16172 2.14848V16.9573C5.16172 17.1769 5.38988 17.3221 5.58879 17.2291Z" />
    <path d="M2.72107 14.4011L0.381928 15.0615C0.190363 15.1156 0.000203444 14.9716 0.000408171 14.7725L0.0113082 4.17455C0.0114994 3.98861 0.179061 3.84762 0.362299 3.87923L2.69054 4.2808C2.83443 4.30562 2.93955 4.43043 2.93955 4.57644V14.1124C2.93955 14.2466 2.85031 14.3646 2.72107 14.4011Z" />
  </SvgIcon>
);

export default MarketPlace;
