import React, { FC } from 'react';
import cx from 'classnames';
import {
  FormControlLabel, Checkbox,
} from '@material-ui/core';
import useStyles from './style';

interface Props {
  text: JSX.Element | string;
  onChange: (value: boolean) => void;
  inputRef?: any;
  name?: string;
  dataTestid?: string;
  checked?: any;
}

const CheckboxSC: FC<Props> = ({
  // variant,
  text,
  inputRef,
  name,
  dataTestid,
  checked,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          inputRef={inputRef}
          name={name}
          data-testid={dataTestid}
          checked={checked}
          onChange={({ target }) => onChange(target.checked)}
        />
      )}
      label={<span className={classes.labelText}>{text}</span>}
      className={classes.checkBoxWithLabel}
    />
  );
};

export default CheckboxSC;
