import React, { FC } from 'react';
import { Paper, Typography } from '@material-ui/core';
import useStyles from './style';

const NotFoundPage: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h1">
          PAGE NOT FOUND
        </Typography>
        <Typography variant="h2" className={classes.text}>
          We apologize, but the page you are trying to reach doesn’t exist.
          Please navigate to a different page or contact System Administrator.
        </Typography>
      </Paper>
    </div>
  );
};

export default NotFoundPage;
