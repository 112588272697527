import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  warnContainer: {
    width: '100%',
    marginLeft: 123,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      bottom: 50,
      right: 40,
    },
    [theme.breakpoints.up('lg')]: {
      bottom: 50,
      right: 92,
    },
  },
  warnMessage: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    maxHeight: 40,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 60,
    },
    backgroundColor: theme.palette.error.light,
    padding: '11px 35px',
  },
  text: {
    marginLeft: 16,
  },
}));

export default useStyles;
