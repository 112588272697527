import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const Settlements = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 16">
    <path d="M1.47368 7.90069V10.9861C1.47368 11.6264 1.96737 12.1432 2.57895 12.1432C3.19053 12.1432 3.68421 11.6264 3.68421 10.9861V7.90069C3.68421 7.26046 3.19053 6.74364 2.57895 6.74364C1.96737 6.74364 1.47368 7.26046 1.47368 7.90069ZM5.89474 7.90069V10.9861C5.89474 11.6264 6.38842 12.1432 7 12.1432C7.61158 12.1432 8.10526 11.6264 8.10526 10.9861V7.90069C8.10526 7.26046 7.61158 6.74364 7 6.74364C6.38842 6.74364 5.89474 7.26046 5.89474 7.90069ZM1.10526 16H12.8947C13.5063 16 14 15.4832 14 14.843C14 14.2027 13.5063 13.6859 12.8947 13.6859H1.10526C0.493684 13.6859 0 14.2027 0 14.843C0 15.4832 0.493684 16 1.10526 16ZM10.3158 7.90069V10.9861C10.3158 11.6264 10.8095 12.1432 11.4211 12.1432C12.0326 12.1432 12.5263 11.6264 12.5263 10.9861V7.90069C12.5263 7.26046 12.0326 6.74364 11.4211 6.74364C10.8095 6.74364 10.3158 7.26046 10.3158 7.90069ZM6.31474 0.179342L0.493684 3.38821C0.191579 3.5502 0 3.88188 0 4.23671C0 4.76895 0.412632 5.20092 0.921053 5.20092H13.0863C13.5874 5.20092 14 4.76895 14 4.23671C14 3.88188 13.8084 3.5502 13.5063 3.38821L7.68526 0.179342C7.25789 -0.0597806 6.7421 -0.0597806 6.31474 0.179342V0.179342Z" />
  </SvgIcon>
);

export default Settlements;
