import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import {
  Typography,
  Paper,
} from '@material-ui/core';
import { signIn } from '../../store/slices/auth.slice';
import { RootState } from '../../store/slices';
import useStyles from './style';
import SignInForm from './SignInForm';

export const SignInPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');

  // Selectors
  const {
    isLoading,
    authError,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  // Callbacks
  const handleSignIn = useCallback(
    ({ email, password }) => {
      const key = new Date().getTime() + Math.random();
      dispatch(signIn(email, password, key));
    },
    [dispatch],
  );

  // Redirect after auth
  const location = useLocation();
  const history = useHistory();
  // IF prav_page -> redirect to prev_page ELSE redirect -> /

  if (location.search.includes('prev_page') && userId && !authError) {
    return <Redirect to={decodeURIComponent(location.search).slice(11)} />
  } else if ((!authError && !isLoading && userId)) {
    { return <Redirect to="/" />; }
  }

  // if (!authError && !isLoading && userId) { return <Redirect to="/" />; }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h1" className={classes.title}>
          Sign in
        </Typography>
        <SignInForm
          onSubmit={handleSignIn}
        />
      </Paper>
    </div>
  );
};

export default SignInPage;
