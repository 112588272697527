import React, {
  FC, useEffect, useState, ChangeEvent, useRef,
} from 'react';
import {
  Element,
  scroller,
} from 'react-scroll';
import cx from 'classnames';
import {
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  Avatar,
  CardHeader,
  FormControlLabel,
  Checkbox,
  Fab,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import { useSelector } from 'react-redux';
import useStyles from './DealRoom.style';
import constants from '../../core/constants';
import { getDateTime } from '../../utils/getDate';
import { ChatMessage } from '../../interfaces/deals.interface';
import { getRole } from '../../utils/getRole';
import { RootState } from '../../store/slices';
import getLocalStorageData from '../../api/localStorage';
// import ButtonSC from '../ButtonSC/ButtonSC';

interface Props {
  messages: ChatMessage[];
  sendMessage: (message: string, showForSeller: boolean, showForBuyer: boolean) => void;
  status?: string;
  sellerId?: number;
  buyerId?: number;
  // sellerOnOff: boolean;
  // buyerOnOff: boolean;
}

const Chat: FC<Props> = ({
  messages,
  sendMessage,
  status,
  sellerId,
  buyerId,
  // sellerOnOff,
  // buyerOnOff,
}) => {
  const classes = useStyles();
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [showMessages, setShowMessages] = useState<ChatMessage[]>([]);
  const [messageTo, setMessageTo] = useState({
    showForSeller: true,
    showForBuyer: true,
  });
  const {
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    const role = getRole(userRole);
    const { clientAccountId, accountId } = getLocalStorageData();
    if (role === 'Admin') setShowMessages(messages);
    if (
      (sellerId === clientAccountId) ||
      (sellerId === accountId)
    ) setShowMessages(messages.filter((item) => item.showForSeller));
    if (
      (buyerId === clientAccountId) ||
      (buyerId === accountId)
    ) setShowMessages(messages.filter((item) => item.showForBuyer));
  }, [messages, userRole, setShowMessages, buyerId, sellerId,
      // sellerOnOff, buyerOnOff
    ]);

  const deleteNumbersFromMessage = async (message: string) => {
    // emails ex. name@mail.com
    message = message.replace(/([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    // phones
    // ex.
    // (123) 456-7890
    // (123)456-7890
    // 123-456-7890
    // 123.456.7890
    // 1234567890
    // +31636363634
    // +8(999)999-9999
    message = message.replace(/\b[+]?[0-9]{0,3}[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    // words
    message = message.replace(/phone.+/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    message = message.replace(/phone number.+/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    message = message.replace(/email.+/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    message = message.replace(/skype id.+/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    message = message.replace(/skype name.+/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    message = message.replace(/skype.+/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    // skypeId ex. live:name
    message = message.replace(/\blive:.*\b/, 'Please, refrain from sending email addresses and/or phone numbers. You may be blocked from MGTrader.');
    message = message.replace(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/, 'Please, refrain from sending URLs to external resourses. You may be blocked from MGTrader.')
    return message;
  };

  const handleSendMessage = async (message: string) => {
    const newMessage = await deleteNumbersFromMessage(message);

    sendMessage(
      newMessage,
      messageTo.showForSeller,
      messageTo.showForBuyer,
    );
    setCurrentMessage('');
  };

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    //@ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [showMessages]);

  const getAvatarLetter = (name: string) => `${name[0].charAt(0)}`;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessageTo({ ...messageTo, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <Element
        name="chat"
        className="element"
        id="containerElement"
        style={
          {
            position: 'relative',
            height: `calc(100% - ${getRole(userRole) === 'Admin' ? '166px' : '116px'})`,
            overflow: 'scroll',
          }
        }
      >
        <Grid container direction="column" className={classes.chatContainer}>
          {showMessages.length === 0 && (
            <Typography variant="h6">
              No messages
            </Typography>
          )}


          {showMessages.map((item: ChatMessage, index) => (

            <Grid item xs={12} key={item.id} id={showMessages.length == index + 1 ? "containerElement" : ""}
              // className={showMessages.length == index + 1 ? classes.newMessageAnimation : classes.newMessage}>
              className={classes.newMessage}>
              <Card
                className={classes.userInfoChatWrapper}
              >
                <CardHeader
                  avatar={(
                    <Avatar aria-label="recipe" className={classes.avatar} src={`${constants.AWS_URL}${item.avatarLink}`}>
                      {getAvatarLetter(item.name)}
                    </Avatar>
                  )}
                  title={`${item.name} - ${item.role} `}
                  subheader={getDateTime(item.createdAt)}
                />
                {/* <div className={classes.statusWrapper}>
                  <div className={(item.author.id === sellerId) ?
                    (sellerOnOff ? classes.ledStatusOn : classes.ledStatusOff) :
                    (buyerOnOff ? classes.ledStatusOn : classes.ledStatusOff)}>
                  </div>
                  <div className={classes.textStatus}>
                   {
                     (item.author.id === sellerId) ?
                    (sellerOnOff ? 'Online' : 'Offline') :
                    (buyerOnOff ? 'Online' : 'Offline')
                   }
                  </div>
                </div> */}
                <CardContent

                >
                  <Typography variant="body2" component="p" className={classes.wordBreak}>
                    {item.message}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
          <div ref={messagesEndRef} />
          <Element name="last" id="last" />
        </Grid>
      </Element>
      <div className={classes.chatActions}>
        <Grid container item xs={12} spacing={1}>
          {getRole(userRole) === 'Admin' && (
            <Grid container item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={messageTo.showForSeller}
                    onChange={handleChange}
                    name="showForSeller"
                    color="primary"
                    checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                  />
                )}
                label="Seller"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={messageTo.showForBuyer}
                    onChange={handleChange}
                    name="showForBuyer"
                    color="primary"
                    checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                  />
                )}
                label="Buyer"
              />
            </Grid>
          )}
          <Grid item xs={11}>
            {/* {(!sellerOnOff || !buyerOnOff) && <Grid>
              User is Offline. We sent an Alert Message.
            </Grid>} */}
            <TextField
              name="message"
              variant="standard"
              id="message"
              fullWidth
              placeholder="Write message"
              onChange={({ target }) => setCurrentMessage(target.value)}
              value={currentMessage}
              inputProps={{ 'data-testid': 'chat-message-input' }}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  handleSendMessage(currentMessage);
                }
              }}
              // disabled={status === 'TIMEOUT'}
            />
          </Grid>
          <Grid item xs={1}>
            <Fab color="primary" aria-label="openChat" size="small" onClick={() => handleSendMessage(currentMessage)}
            //  disabled={status === 'TIMEOUT'}
             >
              <SendIcon />
            </Fab>
            {/* <ButtonSC
              variant="fill"
              text="Send"
              size="large"
              color="green"
              handleClick={() => handleSendMessage(currentMessage)}
              disabled={status === 'TIMEOUT'}
              styleClass={classes.npButton}
            /> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Chat;
