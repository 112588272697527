import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    height: 80,
    boxShadow: 'none',
    padding: 16,
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 24px',
      width: 'calc(100% - 42px)',
    },
    //     [theme.breakpoints.up('sm')]: {
    //   padding: '12px 24px',
    //   height: 62,
    //   width: 'calc(100% - 42px)',
    // },
            [theme.breakpoints.down('xs')]: {
      // padding: '12px 24px',
      // height: 62,
      width: '100%',
    },
    // [theme.breakpoints.up('lg')]: {
    //   padding: '24px',
    //   height: 80,
    //   width: '100%',
    // },
  },
  link: {
    color: '#ffffff',
    margin: theme.spacing(1, 1.5),
    textDecoration: 'none',
  },
  toCapitalize: {
    color: theme.palette.primary.dark,
    padding: 0,
    marginRight: 16,
  },
  roleString: {
    fontSize: 16,
    lineHeight: '21px',
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
  },
  nameRole: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
      paddingRight: 50,
    },
  },
  alignSelf: {
    alignSelf: 'center',
  },
  appBarButton: {
    height: 32,
    [theme.breakpoints.up('sm')]: {
      height: 40,
    },
  },

  ring_wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  button_ring: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },

  ring_icon: {
    color: 'gray',
  },

  ring_icon_active: {
    color: 'green',
    animationName: '$NewNotificationBlinker',
    animationDuration: '1400ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },

  '@keyframes NewNotificationBlinker': {
    '0%': {
      color: '#3bb83b',
      transform: 'rotate(-45deg)',
    },
    '100%': {
      color: '#dc4155',
      transform: 'rotate(45deg) scale(1.8)',
    },
  },

}));

export default useStyles;
