import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

export const apiGetAllDealsOfAccount = (
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  let url;
  if (brokerAccountId) {
    url = `${constants.BASE_URL}${api.getAllDealsOfBrokerAccount}${brokerAccountId}/${accountId}`;
  } else {
    url = `${constants.BASE_URL}${api.getAllDealsOfAccount}${accountId}`;
  }

  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiGetAllSettlementsOfAccount = (
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  let url;
  if (brokerAccountId) {
    url = `${constants.BASE_URL}${api.getAllSettlementsOfBrokerAccount}${brokerAccountId}/${accountId}`;
  } else {
    url = `${constants.BASE_URL}${api.getAllSettlementsOfAccount}${accountId}`;
  }

  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiCreateDealFromSellerStage1 = (
  orderId: number,
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.createDealFromSellerStage1}${accountId}/${orderId}`;
  return request(
    url,
    'POST',
    { brokerAccountId },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { brokerAccountId },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiCreateDealFromSellerStage2 = (
  orderId: number,
  chosenSellerInventoryId: number,
  needQuantity: number,
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.createDealFromSellerStage2}${accountId}/${chosenSellerInventoryId}/${orderId}`;
  return request(
    url,
    'POST',
    {
      brokerAccountId,
      needQuantity,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   {
  //     brokerAccountId,
  //     needQuantity,
  //   },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// CreateDealFromBuyer
export const apiCreateDealFromBuyer = (
  orderId: number,
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  param?: any,
  sendAnswerText?: any,
) => {
  const url = `${constants.BASE_URL}${api.createDealFromBuyer}${accountId}/${orderId}`;
  return request(
    url,
    'POST',
    { brokerAccountId, param, sendAnswerText},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { brokerAccountId },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiGoToDeal = (
  dealRoomId: number | string,
  brokerAccountId: number | null,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.goToDeal}${accountId}/${dealRoomId}`;
  return request(
    url,
    'POST',
    { brokerAccountId },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { brokerAccountId },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiGetSingleDealInfo = (
  dealId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getSingleDealInfo}${dealId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiAgreeWithDeal = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.agreeWithDeal}`;
  return request(
    url,
    'POST',
    {
      dealId,
      accountId,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   {
  //     dealId,
  //     accountId,
  //   },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiCheckСomission = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.checkСomission}`;
  return request(
    url,
    'POST',
    {
      dealId,
      accountId,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

export const apiLeftTheDeal = (
  dealRoomId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.leftTheDeal}${accountId}/${dealRoomId}`;
  return request(
    url,
    'POST',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   {},
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiMarkPTSCompleted = (
  ptsData: FormData,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.markPTSCompleted}`;
  return request(
    url,
    'POST',
    ptsData,
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
  // const { data } = await axios.post(
  //   url,
  //   {},
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiAcceptAllIA = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.acceptAllIA}`;
  return request(
    url,
    'POST',
    { dealId, accountId },
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
};

export const apiAcceptAllNP = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.acceptAllNP}`;
  return request(
    url,
    'POST',
    { dealId, accountId },
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
};

export const apiAcceptAllPTS = (
  dealId: number,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.acceptAllPTS}`;
  return request(
    url,
    'POST',
    { dealId, accountId },
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
};

export const apiChangeDealStatus = (
  dealId: number | null,
  // status: string,
  // accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  ) => {
  const url = `${constants.BASE_URL}${api.updateDealStatus}${dealId}`;
  return request(
    url,
    'PUT',
    {
      // orderStatus:`${status}`
      dealStatus:"IN PROGRESS"
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};
