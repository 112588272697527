import React, {
  FC, useCallback, useState,
} from 'react';
import {
  Grid,
  Button,
} from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import { useSelector } from 'react-redux';
import useStyles from './style';
import constants from '../../core/constants';
import { RootState } from '../../store/slices';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContractViewer: FC = () => {
  const { contract } = useSelector((state: RootState) => state.contracts);
  const classes = useStyles();
  const [contractExpand, setContractExpand] = useState(false);

  const [pages, setNumPages] = useState<number | null>(null);

  const handleRenderPage = useCallback(
    (size: any) => {
      const content = [];
      if (pages) {
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= pages; i++) {
          content.push(<Page key={`page_${i}`} pageNumber={i} width={size.width ? size.width : 1} />);
        }
      }
      return content;
    },
    [pages],
  );

  return (
    <Grid
      className={contractExpand ?
        classes.pdfContainerExpand : classes.pdfContainerShrink}
    >
      <Button
        className={classes.expandBtn}
        onClick={() => setContractExpand(!contractExpand)}
      >
        {contractExpand ? 'Collapse' : 'Expand'}
      </Button>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <div style={{ position: 'relative', top: '-90px' }}>
        <embed
          src={`${constants.AWS_URL}${contract.document.link}`}
          width="100%"
          height="500px"
        />
      </div>
    </Grid>
  );
};

export default ContractViewer;
