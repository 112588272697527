import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import {
  Drawer,
  Divider,
  List,
  Grid,
  makeStyles,
  IconButton,
  Hidden,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logo from '../../svg/logo.svg';
import { CommonListItems } from './dashboardListItems';
import ButtonSC from '../ButtonSC/ButtonSC';
import { logOut } from '../../store/slices/auth.slice';
import getLocalStorageData from '../../api/localStorage';

interface Props {
  menuItems: JSX.Element;
  openMenu: () => void;
  open: boolean;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    padding: '8px 6px',
  },
  drawer: {
    width:42,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  drawerOpen: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },
    backgroundColor: theme.palette.secondary.dark,
    justifyContent: 'space-between',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerClose: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.dark,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 42,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  divider: {
    border: '1px solid #3A3C45',
  },
  drawerItemsListContainer: {
    width: '100%',
  },
  commonMenuItems: {
    '&:last-child': {
      marginBottom: 32,
    },
  },
  menuItems: {
    marginTop: 8,
  },
  hide: {
    display: 'none',
  },
  fullSize: {
    height: '100%',
    width: '100%',
  },
  menuIcon: {
    padding: '5px',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  blurDrawerOpen: {
    width: '100%',
    backdropFilter: 'blur(10px)',
    zIndex: 1001,
  },
  appBarButton: {
    border: `1px solid ${theme.palette.primary.light}`,
    backgroundColor: 'transparent',
    color: '#ffffff',
    height: 32,
    [theme.breakpoints.up('sm')]: {
      height: 40,
    },
  },
  link: {
    color: '#ffffff',
    margin: theme.spacing(1, 1.5),
    textDecoration: 'none',
  },
}));

const DashboardDrawerTablet: FC<Props> = ({ menuItems, open, openMenu }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token } = getLocalStorageData();
  return (
    <>
      <Hidden smUp>
        <Drawer
          variant="temporary"
          className={classes.drawer}
          classes={{
            paper: open ? classes.drawerOpen : classes.drawerClose,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          open={open}
        >
          <Grid item className={classes.drawerItemsListContainer}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container alignItems="center">
                  <IconButton
                    aria-label="Open drawer"
                    onClick={openMenu}
                    className={classes.menuIcon}
                  >
                    {open ? <ArrowBackIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
                  </IconButton>
                  <img src={Logo} alt="MGT logo" height="17" width="100%" />
                </Grid>
              </Grid>
              <Grid item>
                {localStorage.getItem('userId') ? (
                  <Link color="textPrimary" to="/signin" className={classes.link}>
                    <ButtonSC
                      variant="outline"
                      text="Log Out"
                      size="small"
                      handleClick={() => dispatch(
                        logOut(token, new Date().getTime() + Math.random()),
                      )}
                      color="green"
                      styleClass={classes.appBarButton}
                    />
                  </Link>
                ) :
                  (
                    <Link color="textPrimary" to="/signin" className={classes.link}>
                      <ButtonSC
                        variant="outline"
                        text="Log In"
                        size="small"
                        color="green"
                        styleClass={classes.appBarButton}
                      />
                    </Link>
                  )}
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <List className={classes.menuItems}>
              {menuItems}
            </List>
          </Grid>
          <Grid item className={classes.drawerItemsListContainer}>
            <List className={classes.commonMenuItems}>
              <CommonListItems open />
            </List>
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: open ? classes.drawerOpen : classes.drawerClose,
          }}
          className={cx(classes.drawer, { [classes.blurDrawerOpen]: open })}
        >
          <Grid item className={classes.drawerItemsListContainer}>
            <Link to="/">
              <div className={classes.toolbarIcon}>
                <img src={Logo} alt="MGT logo" height="17" width="100%"/>
              </div>
            </Link>
            <Divider className={classes.divider} />
            <IconButton
              aria-label="Open drawer"
              onClick={openMenu}
              className={classes.menuIcon}
            >
              {open ? <ArrowBackIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
            </IconButton>
            <Divider className={classes.divider} />
            <List className={classes.menuItems}>
              {menuItems}
            </List>
          </Grid>
          <Grid item className={classes.drawerItemsListContainer}>
            <List className={classes.commonMenuItems}>
              <CommonListItems open={open} />
            </List>
          </Grid>
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardDrawerTablet;
