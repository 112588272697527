import axios from 'axios';
import { updateToken, authRequest } from '../store/slices/auth.slice';
import { AppThunk } from '../store';

const request = (
  uri: string,
  method: 'head' | 'get' | 'GET' | 'delete' | 'DELETE' | 'HEAD' | 'options' | 'OPTIONS' | 'post' | 'POST' | 'put' | 'PUT' | 'patch' | 'PATCH' | undefined,
  reqData: any,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  headers?: any,
): AppThunk => async (dispatch) => {
  try {
    const { accessToken, tokenExp } = token;
    if (!headers) { // если в запросе отсутствует headers, то задаем их
      headers = {};
    }

    if (accessToken && tokenExp) {
      if (Date.now() >= (new Date(tokenExp)).getTime()) { // проверяем не истек ли срок жизни токена
        const newToken: any = await dispatch(updateToken(token));
        if (newToken.token) {
          headers.Authorization = `Bearer ${newToken.token}`; // добавляем токен в headers запроса
        } else {
          dispatch(authRequest());
          document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
          window.location.reload();
          localStorage.clear();
          throw new Error(newToken);
        }
      }
      const response = await axios({
        method,
        url: uri,
        headers,
        data: ((method === 'POST' || method === 'PUT' || method === 'DELETE') && reqData) || undefined,
      });
      return response.data;
    }
  } catch (error) {
    throw error
    console.log(error)
  }
  // return history.push('/signin');
};

export default request;
