import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import { NegotiationPointsState, NegotiationPoint } from '../../interfaces/negotiationPoints.interface';
import {
  apiUpdateNegotiationPointTemplate,
  apiGetAllNegotiationPointTemplates,
} from '../../api/negotiationPoints';
import translationEN from '../../locales/en/translation.json';

const initialState: NegotiationPointsState = {
  listOfNegotiationPoints: {
    inventoryAttributes: [],
    negotiationPoints: [],
  },
  negotiationPoint: null,
  error: '',
  isLoading: false,
};

const negotiationPointSlice = createSlice({
  initialState,
  name: 'negotiationPoint',
  reducers: {
    listNegotiationPointRequest(state) {
      state.listOfNegotiationPoints.negotiationPoints = [];
      state.listOfNegotiationPoints.inventoryAttributes = [];
      state.error = '';
      state.isLoading = true;
    },
    listNegotiationPointSuccess(state, action) {
      state.listOfNegotiationPoints.negotiationPoints = action.payload.negotiationPoints;
      state.listOfNegotiationPoints.inventoryAttributes = action.payload.inventoryAttributes;
      state.error = '';
      state.isLoading = false;
    },
    listNegotiationPointFailure(state, action) {
      state.listOfNegotiationPoints.negotiationPoints = [];
      state.listOfNegotiationPoints.inventoryAttributes = [];
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  listNegotiationPointRequest,
  listNegotiationPointSuccess,
  listNegotiationPointFailure,
} = negotiationPointSlice.actions;

export const updateNegotiationPoint = (
  inventoryId: number,
  negotiationPoints: NegotiationPoint[],
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(listNegotiationPointRequest());
    const negotiationPointInfo =
      await dispatch(apiUpdateNegotiationPointTemplate(
        inventoryId,
        negotiationPoints,
        token,
      ));
    dispatch(listNegotiationPointSuccess(negotiationPointInfo));
    dispatch(addNotification({
      key,
      message: translationEN.NegotiationPointsUpdated,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(listNegotiationPointFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getAllNegotiationPoints = (
  inventoryId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(listNegotiationPointRequest());
    const listNegotiationPointInfo = await dispatch(apiGetAllNegotiationPointTemplates(inventoryId, token));
    dispatch(listNegotiationPointSuccess(listNegotiationPointInfo));
  } catch (error) {
    dispatch(
      listNegotiationPointFailure(error?.response ? error?.response?.data?.error : error?.message),
    );
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default negotiationPointSlice.reducer;
