import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actions: {
    color: theme.palette.primary.light,
    fontSize: 24,
    paddingLeft: 0,
    '@media (max-width: 680px)': {
      width: '150px',
      paddingLeft: 0,
    }
  },
}));

export default useStyles;
