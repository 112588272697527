import React, {
  FC,
  useEffect,
} from 'react';
import {
  Typography,
  Link,
  Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../../store/slices/userUpdate.slice';
import { RootState } from '../../store/slices';
import { getRole } from '../../utils/getRole';
import constants from '../../core/constants';
import { getDate } from '../../utils/getDate';
import getLocalStorageData from '../../api/localStorage';
import useStyles from './Request.style';

interface Props {
  userId: number;
}

const RoleRequestDetails: FC<Props> = ({ userId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    user,
  } = useSelector(
    (state: RootState) => state.user,
  );

  useEffect(() => {
    const { token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    dispatch(getUserProfile(userId, token, key));
  }, [dispatch, userId]);

  const {
    // userName,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    mainNumber,
    mobileNumber,
    email,
    isSeller,
    isBuyer,
    isBroker,
    isAdmin,
    isConfirmed,
    line1,
    line2,
    city,
    state,
    zip,
    country,
    profilePhotoLink,
    passportMediaLink,
    driverLicenseBackMediaLink,
    driverLicenseFrontMediaLink,
    stateIdDocumentMediaLink,
    legalStatus,
    SSN,
    contactsTitle,
    businessWebsiteAddress,
    organizationName,
    organizationOfficePhone,
    organizationOfficeFax,
    organizationLine1,
    organizationLine2,
    organizationCity,
    organizationState,
    organizationZip,
    federalEIN,
    stateOfIncorporation,
    W9MediaLink,
    LLC1DocMediaLink,
    LLC2DocMediaLink,
    incorporationCertificateMediaLink,
    incorporationArticlesMediaLink,
    tickerSymbolMediaLink,
    governmentIdType,
    governmentIdNumber,
    governmentIdDateOfIssue,
    governmentIdDateOfExpiration,
  } = user;

  return (
    <>
      {user && (
        <Grid container className={classes.fields}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Personal Info:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Current role:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {getRole({
                isSeller: isSeller || false,
                isBuyer: isBuyer || false,
                isBroker: isBroker || false,
                isAdmin: isAdmin || false,
                isConfirmed: isConfirmed || false,
              })}
            </Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography variant="body1" color={userName ? 'primary' : 'secondary'}>
              User name:
            </Typography>
          </Grid> */}
          {/* <Grid item xs={6}>
            <Typography variant="body1">
              {userName}
            </Typography>
          </Grid> */}
          <Grid item xs={6}>
            <Typography variant="body1" color={firstName ? 'primary' : 'secondary'}>
              First name:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {firstName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={middleName ? 'primary' : 'secondary'}>
              Middle name:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {middleName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={lastName ? 'primary' : 'secondary'}>
              Last name:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {lastName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={dateOfBirth ? 'primary' : 'secondary'}>
              Date of birth:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {!!dateOfBirth ? getDate(dateOfBirth as string) : ''}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={email ? 'primary' : 'secondary'}>
              Email:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {email}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={mainNumber ? 'primary' : 'secondary'}>
              Main Number:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {mainNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={mobileNumber ? 'primary' : 'secondary'}>
              Mobile Phone:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {mobileNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={line1 ? 'primary' : 'secondary'}>
              Line 1:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {line1}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={line2 ? 'primary' : 'secondary'}>
              Line 2:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {line2}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={city ? 'primary' : 'secondary'}>
              City:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {city}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={state ? 'primary' : 'secondary'}>
              State:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {state}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={zip ? 'primary' : 'secondary'}>
              Zip:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {zip}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={country ? 'primary' : 'secondary'}>
              Country:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {country}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={profilePhotoLink ? 'primary' : 'secondary'}>
              Profile photo:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {profilePhotoLink && (
                <Link href={`${constants.AWS_URL}${profilePhotoLink}`} target="_blank" rel="noreferrer">
                  {profilePhotoLink.split('/')[2]}
                </Link>
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={governmentIdType ? 'primary' : 'secondary'}>
              Government ID Type:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {governmentIdType}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={governmentIdNumber ? 'primary' : 'secondary'}>
              Government ID Number:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {governmentIdNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={governmentIdDateOfIssue ? 'primary' : 'secondary'}>
              Issue date:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {governmentIdDateOfIssue && getDate(governmentIdDateOfIssue)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={governmentIdDateOfExpiration ? 'primary' : 'secondary'}>
              Expire date:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {governmentIdDateOfExpiration && getDate(governmentIdDateOfExpiration)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              color={
                (
                  passportMediaLink?.link ||
                  driverLicenseBackMediaLink?.link ||
                  driverLicenseFrontMediaLink?.link ||
                  stateIdDocumentMediaLink?.link
                ) ? 'primary' : 'secondary'
              }
            >
              Documents:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {passportMediaLink?.link && (
              <div>
                <Link
                  href={`${constants.AWS_URL}${
                    passportMediaLink.link}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {passportMediaLink.title}
                </Link>
              </div>
            )}
            {driverLicenseBackMediaLink?.link && (
              <div>
                <Link
                  href={`${constants.AWS_URL}${
                    driverLicenseBackMediaLink.link}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {driverLicenseBackMediaLink.title}
                </Link>
              </div>
            )}
            {driverLicenseFrontMediaLink?.link && (
              <div>
                <Link
                  href={`${constants.AWS_URL}${
                    driverLicenseFrontMediaLink.link}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {driverLicenseFrontMediaLink.title}
                </Link>
              </div>
            )}
            {stateIdDocumentMediaLink?.link && (
              <div>
                <Link
                  href={`${constants.AWS_URL}${
                    stateIdDocumentMediaLink.link}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {stateIdDocumentMediaLink.title}
                </Link>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              Company Info:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={legalStatus ? 'primary' : 'secondary'}>
              Legal Status:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {legalStatus}
            </Typography>
          </Grid>
          {legalStatus !== 'SOLE PROPRIETOR' ? (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" color={contactsTitle ? 'primary' : 'secondary'}>
                  Contact&apos;s Title:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {contactsTitle}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={businessWebsiteAddress ? 'primary' : 'secondary'}>
                  Business website address:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {businessWebsiteAddress}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={organizationName ? 'primary' : 'secondary'}>
                  Organisation Name:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {organizationName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={federalEIN ? 'primary' : 'secondary'}>
                  EIN:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {federalEIN}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={organizationOfficePhone ? 'primary' : 'secondary'}>
                  Office Phone:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {organizationOfficePhone}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={organizationOfficeFax ? 'primary' : 'secondary'}>
                  Office Fax:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {organizationOfficeFax}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={organizationLine1 ? 'primary' : 'secondary'}>
                  Line 1:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {organizationLine1}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={organizationLine2 ? 'primary' : 'secondary'}>
                  Line 2:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {organizationLine2}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={stateOfIncorporation ? 'primary' : 'secondary'}>
                  State of Incorporation:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {stateOfIncorporation}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={organizationState ? 'primary' : 'secondary'}>
                  State:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {organizationState}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={organizationZip ? 'primary' : 'secondary'}>
                  Zip:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {organizationZip}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={organizationCity ? 'primary' : 'secondary'}>
                  City:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {organizationCity}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  color={
                    (
                      W9MediaLink?.link ||
                      LLC1DocMediaLink?.link ||
                      LLC2DocMediaLink?.link ||
                      incorporationCertificateMediaLink?.link ||
                      incorporationArticlesMediaLink?.link ||
                      tickerSymbolMediaLink?.link
                    ) ? 'primary' : 'secondary'
                  }
                >
                  Documents:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {W9MediaLink?.link && (
                  <div>
                    <Link
                      href={`${constants.AWS_URL}${
                        W9MediaLink.link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {W9MediaLink.title}
                    </Link>
                  </div>
                )}
                {LLC1DocMediaLink?.link && (
                  <div>
                    <Link
                      href={`${constants.AWS_URL}${
                        LLC1DocMediaLink.link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {LLC1DocMediaLink.title}
                    </Link>
                  </div>
                )}
                {LLC2DocMediaLink?.link && (
                  <div>
                    <Link
                      href={`${constants.AWS_URL}${
                        LLC2DocMediaLink.link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {LLC2DocMediaLink.title}
                    </Link>
                  </div>
                )}
                {incorporationCertificateMediaLink?.link && (
                  <div>
                    <Link
                      href={`${constants.AWS_URL}${
                        incorporationCertificateMediaLink.link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {incorporationCertificateMediaLink.title}
                    </Link>
                  </div>
                )}
                {incorporationArticlesMediaLink?.link && (
                  <div>
                    <Link
                      href={`${constants.AWS_URL}${
                        incorporationArticlesMediaLink.link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {incorporationArticlesMediaLink.title}
                    </Link>
                  </div>
                )}
                {tickerSymbolMediaLink?.link && (
                  <div>
                    <Link
                      href={`${constants.AWS_URL}${
                        tickerSymbolMediaLink.link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {tickerSymbolMediaLink.title}
                    </Link>
                  </div>
                )}
              </Grid>
            </>
          ) :
            (
              <>
                <Grid item xs={6}>
                  <Typography variant="body1" color={SSN ? 'primary' : 'secondary'}>
                    SSN:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {SSN}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" color={businessWebsiteAddress ? 'primary' : 'secondary'}>
                    Business website address:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {businessWebsiteAddress}
                  </Typography>
                </Grid>
              </>
            )}
        </Grid>
      )}
    </>
  );
};

export default RoleRequestDetails;
