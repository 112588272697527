import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import PublicMarketPlace from '../Public/PublicMarketPlace';
import NotFoundPage from '../NotFound/NotFoundPage';
import ContactsPage from '../Contacts/ContactsPage';
import PrivacyPolicyPage from '../PrivacyPolicy/PrivacyPolicyPage';
import TermsAndServicesPage from '../TermsAndServices/TermsAndServicesPage';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { checkLogin } from '../../store/slices/auth.slice';
import getLocalStorageData from '../../api/localStorage';
import ContentContainer from '../ContentContainer/ContentContainer';
import { RootState } from '../../store/slices';
import SignInPage from '../Signin/SignInPage';

export const PublicRoute: FC = () => {
  const dispatch = useDispatch();
  let loginCheck = false;

  if (localStorage.getItem('accessToken')) {
    const { clientAccountId, userId, token } = getLocalStorageData();
    loginCheck = true;
    const key = new Date().getTime() + Math.random();
    dispatch(checkLogin(clientAccountId, userId, token, key));

  } else {
    loginCheck = false;
  }
  return (
    <ContentContainer>
      <Switch>
        <Route exact path="/MarketPlace" component={PublicMarketPlace} />
        <Route exact path="/contacts" component={ContactsPage} />
        <Route exact path="/privacyPolicy" component={PrivacyPolicyPage} />
        <Route exact path="/termsOfService" component={TermsAndServicesPage} />
        <Route exact path="/notfound" component={NotFoundPage} />

        {/* Private routes */}
        {/* <Route path="/" component={loginCheck ? PrivateRoute : PublicMarketPlace} /> */}
        <Route path="/" component={loginCheck ? PrivateRoute : SignInPage} />
        {/* {loginCheck ? <Route path="/" component={PrivateRoute} /> : <Route path="/" component={SignInPage} />} */}
      </Switch>
    </ContentContainer>
  );
};

export default PublicRoute;
