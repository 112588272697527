import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Link,
} from 'react-router-dom';
import {
  Grid,
  TextField,
  Typography,
  Paper,
} from '@material-ui/core';
import useStyles from './style';
import { sendEmailChangeUserPassword } from '../../store/slices/userUpdate.slice';
import ButtonSC from '../../components/ButtonSC/ButtonSC';

export const PasswordResetEmail: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    handleSubmit, errors, control,
  } = useForm();

  // Callbacks
  const handleSendEmail = useCallback(
    (value) => {
      const key = new Date().getTime() + Math.random();
      dispatch(sendEmailChangeUserPassword(value.email, key));
    },
    [dispatch],
  );

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h1"> Enter email to change password </Typography>
        <form className={classes.form} onSubmit={handleSubmit(handleSendEmail)}>
          <Grid container className={classes.form} id="container">
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                as={(
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Email"
                    autoComplete="email"
                    error={!!errors.email?.message}
                  />
                )}
                rules={{
                  required: 'Required field',
                  pattern: {
                    value: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i,
                    message: 'Incorrect value',
                  },
                }}
              />
              {errors.email &&
                <Typography variant="caption" color="error">{errors.email.message}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <ButtonSC
                variant="fill"
                text="Submit"
                size="large"
                type="submit"
                color="green"
              />
            </Grid>
            <Grid container item justifyContent="space-between">
              <Link to="/signup" className={classes.link}>
                Sign Up
              </Link>
              <Link to="/signin" className={classes.link}>
                Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default PasswordResetEmail;
