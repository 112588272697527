import React, { FC, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';

import ProfilePage from '../../components/ProfilePage/ProfilePage';
import MarketPlacePage from '../../components/MarketPlace/MarketPlacePage';
import constants from '../../core/constants';
import getLocalStorageData from '../../api/localStorage';
import NotFoundPage from '../NotFound/NotFoundPage';
import ChangeRole from '../../components/ChangeRolePage/ChangeRolePage';
import { logOut } from '../../store/slices/auth.slice';
import { updateListOfOrders } from '../../store/slices/order.slice';
import ListOfMessagesAndAlerts from '../../tables/MessagesAndAlerts/ListMessagesAndAlerts';
import { getAllMessagesAndAlerts, updateListAlertsAndMessages } from '../../store/slices/alertsAndMessages.slice';
import { Socket } from '../MainContainer/MainContainer';

// const socket = io(`${constants.BASE_URL}`);
const UserPage: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { clientAccountId, accountId, token } = getLocalStorageData();
    dispatch(getAllMessagesAndAlerts(
      clientAccountId || accountId,
      token,
      new Date().getTime() + Math.random(),
    ));
  }, [dispatch]);

  useEffect(() => {
    const { accountId, clientAccountId, token } = getLocalStorageData();
    Socket.on('connectToPersonalRoom', (data: any) => {
      if (data.message === 'role updated') {
        if (data.accountId === (clientAccountId || accountId)) {
          window.location.reload();
        }
      }
      if (data.message === 'administrator changed your email') {
        logOut(token, new Date().getTime() + Math.random());
      }
      if (data.message === 'orders updated') dispatch(updateListOfOrders(data.orderToSend));
      if (data.message === 'order questions updated') dispatch(updateListAlertsAndMessages(data.foundMessageWithAnswers));
    });
    return () => {
      Socket.off('connectToPersonalRoom');
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path="/" component={MarketPlacePage} />
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/changeRole" component={ChangeRole} />
      <Route exact path="/notfound" component={NotFoundPage} />
      <Route exact path="/messagesAndAlerts" component={ListOfMessagesAndAlerts} />
      <Redirect to="/notfound" />
    </Switch>
  );
};

export default UserPage;
