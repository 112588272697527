import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fields: {
    '& > div': {
      padding: '8px 0',
    },
  },
  buttonMargin: {
    marginTop: 16,
    [theme.breakpoints.up('sm')]: {
      marginTop: 24,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 32,
    },
  },
  picture: {
    height: 100,
    width: 100,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.contrastText,
  },
  closeModalIcon: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
  content: {
    wordWrap: 'break-word',
  },
}));

export default useStyles;
