/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import InfoPageContainer from '../InfoPageContainer/InfoPageContainer';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import useStyles from './style';

const TermsAndServicesPage: FC = () => {
  const classes = useStyles();
  return (
    <>
      <PaperContainer>
        <InfoPageContainer>
          {/* <h1 className={classes.termsAndConditionsHeader}>Terms and Services</h1> */}
          <p className={classes.termsAndConditionsText}>
            PLEASE READ THESE TERMS AND CONDITIONS OF SERVICE BEFORE USING THIS SITE.
            By continuing to access or use this site, or any service on this site, you signify YOUR ACCEPTANCE OF THESE TERMS OF SERVICE.
            MGTrader is a service provided by MGTrading LLC, a wholly owned subsidiary of Mother Goose Holdings LLC.
            LLC. From time to time, MGTrading LLC may modify these terms and conditions of service.
            Accordingly, please continue to review these terms and conditions of service whenever accessing or using this site.
            Your use of the site, or any service on this site,
            after the posting of modifications to these terms and conditions of service will constitute
            YOUR ACCEPTANCE OF THE TERMS AND CONDITIONS OF SERVICE, as modified.
            If, at any time, you do not wish to accept the terms and conditions of service, you may not use the site.
            Any terms and conditions proposed by you which are in addition to or which conflict with these terms and
            conditions of service are expressly rejected by MGTrading LLC and shall be of no force or effect.
          </p>
          <p className={classes.termsAndConditionsText}>
            1. User Consent to Terms and Conditions of Service. You represent that you have read and agree to be bound by the terms and conditions of service ("TOS") for the web sites owned and operated by MGTrading LLC , specifically www.onepercentoptions.com. You further agree: (a) to comply with U.S. law regarding the transmission of any data obtained from the Service (as defined herein) in accordance with the TOS, (b) not to use the Service for illegal purposes, and (c) not to interfere or disrupt networks connected to the Service.
          </p>
          <p className={classes.termsAndConditionsText}>
            2. Intellectual Property. This Web site, including but not limited to text, content, data, photographs, video, audio and graphics (the "Service"), is protected by copyrights, trademarks, service marks, international treaties and/or other proprietary rights and laws of the U.S. and other countries. The Service is also protected as a collective work or compilation under U.S. copyright and other laws and treaties. All individual quotes, information, data and other elements making up the Service are also copyrighted works. You agree to abide by all applicable copyright and other laws, as well as any additional copyright notices or restrictions contained in the Service.
          </p>
          <p className={classes.termsAndConditionsText}>
            3. Intellectual Property Infringement Claims: It is the policy of MGTrading LLC to respond promptly to claims of intellectual property infringement and to terminate subscribers who repeatedly post infringing material on websites maintained by MGTrading LLC.
            MGTrading LLC will promptly investigate notices of alleged infringement and will take appropriate actions under applicable intellectual property laws.
            MGTrading LLC will act expeditiously to remove any material or any link to material that is claimed to be infringing.
            In the event that MGTrading LLC receives information claiming that any material posted on its websites infringes the intellectual property of some person, MGTrading LLC may attempt to contact the subscriber who has posted the material in order to give the subscriber an opportunity to respond to the notice of infringement.
            Any and all responses to the notice of infringement will be furnished to the complaining party. MGTrading LLC will give the complaining party an opportunity to seek judicial relief prior to restoring any material as a result of the response to the notice of infringement.
            Notices of any claimed infringement should be sent to MGTrading LLC via e-mail to support@onepercentoptions.com or via mail to MGTrading LLC , 1795 Riverside Drive, #3A, New York, NY 10034. Please put NOTICE OF INFRINGEMENT in the subject line of all such notifications.
          </p>
          <p className={classes.termsAndConditionsText}>
            4. Restrictions on Use. You may not use the Service for any illegal purpose or in any manner inconsistent with the TOS. You agree to use the Service solely for your own use and benefit, and not for resale or other transfer or disposition to, or use by or for the benefit of, any other person or entity. You agree not to use, transfer, distribute or dispose of any information contained in the Service in any manner that could compete with the business of MGTrading LLC.
            You acknowledge that the Service has been developed, compiled, prepared, revised, selected and arranged by MGTrading LLC and others (including certain other information sources) through the application of methods and standards of judgment developed and applied through the expenditure of substantial time, effort and money and constitutes valuable intellectual property and trade secrets of MGTrading LLC and such others. You agree to protect the proprietary rights of MGTrading LLC and all others having rights
            in the Service during and after the term of this agreement and to comply with all reasonable written requests made by MGTrading LLC or its suppliers of content, equipment or otherwise ("Suppliers") to protect their and others' contractual, statutory and common law rights in the Service. You agree to notify MGTrading LLC in writing promptly upon becoming aware of any unauthorized access or use of the Service by any party or of any claim that the Service infringes upon any copyright, trademark or other contractual, statutory or common law rights.
          </p>
          <p className={classes.termsAndConditionsText}>
            5. Further Restrictions on Use. YOU MAY NOT COPY, DISTRIBUTE, PUBLISH, DISPLAY, UPLOAD ANY INFORMTAION DISPLAYED OR GENERATED BY ONEPERCENTOPTIONS.COM WITHOUT PROPER CREDIT TO ONEPERCENTOPTIONS.COM. ALSO YOU MAY NOT REPRODUCE, RECOMPILE, DECOMPILE, DISASSEMBLE, REVERSE ENGINEER, MODIFY, CREATE DERIVATIVE WORKS FROM, TRANSMIT OR IN ANY WAY EXPLOIT ANY PART OF THE SERVICE, EXCEPT AS EXPRESSLY PERMITTED BY MGTRADING LLC FOR YOUR OWN PERSONAL, NONCOMMERCIAL USE, PROVIDED THAT YOU RETAIN ALL COPYRIGHT AND OTHER PROPRIETARY NOTICES.
            THE ANALYSIS AND PRESENTATION INCLUDED IN THE SERVICE MAY NOT BE RECIRCULATED, REDISTRIBUTED OR PUBLISHED WITHOUT PROPER CREDIT TO ONEPERCENTOPTIONS.COM AND WITHOUT MGTRADING LLC PRIOR WRITTEN CONSENT. MODIFICATION OF THE SERVICE'S CONTENT WOULD BE A VIOLATION OF MGTRADING LLC ’S COPYRIGHT AND OTHER PROPRIETARY RIGHTS. ADDITIONALLY, YOU MAY NOT OFFER ANY PART OF THE SERVICE FOR SALE OR DISTRIBUTE IT OVER ANY OTHER MEDIUM INCLUDING BUT NOT LIMITED TO OVER-THE-AIR TELEVISION OR RADIO BROADCAST, A COMPUTER NETWORK OR HYPERLINK FRAMING ON THE INTERNET WITHOUT THE PRIOR WRITTEN CONSENT OF MGTRADING LLC. THE SERVICE AND THE INFORMATION CONTAINED THEREIN MAY NOT BE USED TO CONSTRUCT A DATABASE OF ANY KIND. NOR MAY THE SERVICE BE STORED (IN ITS ENTIRETY OR IN ANY PART) IN DATABASES FOR ACCESS BY YOU OR ANY THIRD PARTY OR TO DISTRIBUTE ANY DATABASE SERVICES CONTAINING ALL OR PART OF THE SERVICE. YOU MAY NOT USE THE SERVICE IN ANY WAY TO IMPROVE THE QUALITY OF ANY DATA SOLD OR CONTRIBUTED BY YOU TO ANY THIRD PARTY. FURTHERMORE, YOU MAY NOT USE ANY OF SFO MGTRADING LLC ’S TRADEMARKS, TRADE NAMES OR SERVICE MARKS IN ANY MANNER WHICH CREATES THE IMPRESSION THAT SUCH NAMES AND MARKS BELONG TO OR ARE ASSOCIATED WITH YOU OR ARE USED WITH RFO CAPTIAL LLC'S CONSENT, AND YOU ACKNOWLEDGE THAT YOU HAVE NO OWNERSHIP RIGHTS IN AND TO ANY OF THESE NAMES AND MARKS. YOU WILL NOT USE THE SERVICE OR THE INFORMATION CONTAINED THEREIN IN UNSOLICITED MAILINGS OR SPAM MATERIAL. YOU WILL NOT USE ANY OF MGTRADING LLC ’S TRADEMARKS, TRADE NAMES OR SERVICE MARKS IN UNSOLICITED MAILINGS OR SPAM MATERIAL. YOU WILL NOT SPAM OR SEND UNSOLICITED MAILINGS TO ANY PERSON OR ENTITY USING THE SERVICE. YOU AGREE TO COMPLY WITH ANY OTHER APPLICABLE TERMS AND CONDITIONS OF SERVICE SET FORTH ON THE SERVICE, INCLUDING THE LINKING AND FRAMING POLICY.
          </p>
          <p className={classes.termsAndConditionsText}>6. License. You acquire absolutely no rights or licenses in or to the Service and materials contained within the Service other than the limited right to utilize the Service in accordance with the TOS. Should you choose to download content from the Service, you must do so in accordance with the TOS. Such download is licensed to you by MGTRADING LLC only for your own personal use in accordance with the TOS and does not transfer any other rights to you.</p>
          <p className={classes.termsAndConditionsText}>7. Rights Reserved. All present and future rights in and to trade secrets, patents, copyrights, trademarks, service marks, know-how and other proprietary rights of any type under the laws of any governmental authority, domestic or foreign, including rights in and to all applications and registrations relating to the Service (the "Intellectual Property Rights") shall, as between you and MGTrading LLC , at all times be and remain the sole and exclusive property of MGTrading LLC . All present and future rights in and title to the Service (including the right to exploit the Service and any portions of the Service over any present or future technology) are reserved to MGTrading LLC for its exclusive use. Except as specifically permitted by the TOS, you may not copy or make any use of the Service or any portion thereof. Except as specifically permitted herein, you shall not use MGTrading LLC ’s Intellectual Property Rights or the Service, or the names of any individual participant in, or contributor to, the Service, or any variations or derivatives thereof, for any purpose, without MGTrading LLC ’s prior written approval.</p>

          <p className={classes.termsAndConditionsSubHeader}>Disclaimer, Indemnification and Users Representation(s)</p>

          <p className={classes.termsAndConditionsText}>By typing your full name and current date and submitting and or accepting the Terms of Service you understand and agree to the of above Terms of Service and the following Disclaimer, Indemnification and Users Representation(s)</p>
          <p className={classes.termsAndConditionsText}>Any information you and your company provided to MGTrader, on our platform or otherwise, is true and accurate.</p>
          <p className={classes.termsAndConditionsText}>You, your company, employees and or clients operate in compliance with any applicable laws, rules, and regulations.</p>
          <p className={classes.termsAndConditionsText}>
            The information contained in this site, this platform or otherwise, is provided on an “as is” and “as available” “without prejudice” basis. It is not comprehensive and is subject at any time to revision and/or change and/or cancellation and/or modification and/or alteration without any notice whatsoever. You are using the site entirely at your own risk under the Terms of Use.
            The content on our site, this platform or otherwise, is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on or produced as a result of use on or of our site.
          </p>
          <p className={classes.termsAndConditionsText}>MGTrading LLC gives no warranty, condition or confirmation nor does it make any representation of any kind concerning the site or any of the material contained therein on this platform or otherwise.</p>

          <p className={classes.termsAndConditionsText}>MGTrading LLC expressly disclaims all warranties, whether express or implied, of availability, accuracy, completeness, currency, reliability, non-infringement of third parties’ rights, merchantability or fitness for a particular purpose of any material contained in, displayed on, resulting from or otherwise available from the site this platform or otherwise. MGTrading LLC will in no way be responsible or liable for any damages, direct, indirect, special, punitive or consequential of any kind whatsoever arising out of or in connection with the use, contents or information of this site, this platform or otherwise, which is intended for general guidance only, and which is subject to changes without prior notice.</p>
          <p className={classes.termsAndConditionsText}>MGTrader, its principals, directors, officers, and employees make no representations or warranties of any kind whatsoever in this site, either express or implied, nor do they act as principal, witness or clearing agent to any negotiated transactions. </p>
          <p className={classes.termsAndConditionsText}>
            MGTrading LLC also disclaims no responsibility whatsoever for any damage caused by viruses contained in this site or within any electronic files sent from MGTrading LLC or from this site.
            Neither MGTrading LLC, nor any of its external partners, agents and/or other related parties guarantee that the site will function without interruption or functional errors. In particular, the operation of the site and/ or platform may be interrupted due to maintenance, updates, or system or network failures. MGTrading LLC disclaims all liability for any damages caused by any such interruption or errors in functioning.
          </p>
        </InfoPageContainer>
      </PaperContainer>
    </>
  );
};

export default TermsAndServicesPage;
