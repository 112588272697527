import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '92px 12px 24px',
    height: '100vh',
    width: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // [theme.breakpoints.down('md')]: {
    //   padding: '72px 10px 32px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   padding: '74px 8px 32px',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   padding: '70px 8px 16px',
    // },
    // '@media (min-width: 1360px) and (max-height: 1025px)': {
    //   padding: '96px 16px 32px',
    // },
    // '@media (max-width: 1025px) and (max-height: 769px)': {
    //   padding: '72px 10px 32px',
    // },
  },
}));

export default useStyles;
