import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const LogoInfoLarge = (props: SvgIconProps) => (
  <SvgIcon {...props} width="241" height="60" viewBox="0 0 241 60" style={{ width: 'unset', height: 'unset' }}>
    <path d="M154.822 40.6095C154.822 39.0635 154.822 38.6519 153.958 38.1351L153.536 37.8957C153.482 37.7693 153.457 37.6323 153.462 37.4949C153.468 37.3574 153.505 37.223 153.57 37.1017C155.075 36.6252 156.53 36.0047 157.915 35.2491C158.018 35.2423 158.12 35.2685 158.206 35.3236C158.293 35.3788 158.359 35.4602 158.396 35.5558C158.362 36.2111 158.328 36.816 158.328 37.1017C158.328 37.3454 158.463 37.5218 158.674 37.5218C159.74 36.7656 160.878 35.7322 162.046 35.7322C163.213 35.7322 163.942 36.522 163.942 37.4126C163.956 37.672 163.916 37.9316 163.825 38.175C163.734 38.4184 163.593 38.6405 163.412 38.8273C163.231 39.0142 163.013 39.1618 162.772 39.261C162.531 39.3602 162.272 39.4088 162.012 39.4038C161.682 39.4036 161.358 39.3089 161.08 39.1308C160.604 38.7573 160.028 38.5309 159.424 38.4796C158.632 38.4796 158.425 39.2694 158.425 40.9498V45.659C158.425 48.1796 158.564 48.5787 159.77 48.7173L161.114 48.8559C161.354 49.0954 161.321 49.6961 161.047 49.8516C158.977 49.7802 157.806 49.7465 156.529 49.7465C155.395 49.7465 154.257 49.7802 152.946 49.8516C152.668 49.7129 152.634 49.0954 152.879 48.8559L153.498 48.7509C154.67 48.5451 154.809 48.2006 154.809 45.659L154.822 40.6095Z" fill="#075955" />
    <path d="M175.605 45.3731C175.499 47.3643 176.026 48.3936 177.261 48.3936C177.672 48.3448 178.07 48.2164 178.433 48.0155C178.605 48.0155 178.854 48.3936 178.74 48.7045C178.053 49.6665 176.363 50.2168 175.293 50.2168C174.835 50.2364 174.378 50.161 173.95 49.9954C173.523 49.8298 173.135 49.5776 172.81 49.2548C172.57 49.0111 172.435 48.8347 172.262 48.8347C172.09 48.8347 171.841 49.0027 171.331 49.3136C170.458 49.8784 169.443 50.1884 168.402 50.2084C166.059 50.2084 164.883 48.591 164.883 46.8728C164.883 45.6378 165.367 44.5035 167.744 43.6801C168.95 43.26 170.273 42.7853 171.538 42.3064C172.229 42.0628 172.33 41.7561 172.33 41.4116L172.364 40.5168C172.435 38.765 171.942 37.2527 170.256 37.2527C169.814 37.2141 169.373 37.3513 169.032 37.6345C168.69 37.9178 168.474 38.3241 168.431 38.765C168.437 39.0133 168.38 39.259 168.265 39.4793C168.15 39.6996 167.981 39.8874 167.774 40.0253C167.101 40.4069 166.344 40.6189 165.57 40.6428C165.123 40.6428 164.917 40.4034 164.917 40.0925C164.917 39.6724 165.123 38.9583 166.261 37.8954C167.315 36.8956 169.814 35.7109 171.571 35.7109C174.294 35.7109 175.984 36.9712 175.845 40.0085L175.605 45.3731ZM172.233 44.201C172.233 43.823 172.128 43.6171 171.955 43.6171C171.432 43.686 170.921 43.8274 170.438 44.0372C169.472 44.3817 168.579 44.8774 168.579 46.3435C168.571 46.6016 168.614 46.8588 168.707 47.0998C168.8 47.3408 168.941 47.5607 169.121 47.7464C169.301 47.9321 169.517 48.0799 169.755 48.181C169.994 48.282 170.25 48.3343 170.509 48.3348C171.748 48.3348 172.128 47.4946 172.161 46.6838L172.233 44.201Z" fill="#075955" />
    <path d="M193.445 44.1463C193.445 47.2718 193.685 48.3473 194.288 48.3473C194.785 48.3293 195.276 48.224 195.737 48.0364C196.011 48.2423 196.083 49.0698 195.704 49.2967C193.838 49.4934 192.006 49.9311 190.254 50.599C190.158 50.5782 190.068 50.5328 189.994 50.4673C189.92 50.4018 189.865 50.3184 189.833 50.2251L189.904 49.7084C189.972 49.3898 189.961 49.0595 189.871 48.7464L189.698 48.7128C188.661 49.4689 187.388 50.2587 185.732 50.2587C181.804 50.2587 179.355 47.0954 179.355 43.659C179.355 38.7145 183.321 35.7571 187.629 35.7571C188.303 35.74 188.976 35.8221 189.626 36.0007C189.765 35.9629 189.833 35.8285 189.833 35.4504V30.9176C189.833 28.6155 189.765 28.397 189.04 27.9307L188.56 27.6198C188.354 27.4812 188.316 26.9351 188.56 26.8301C189.904 26.452 191.696 25.7294 193.074 25.1791C193.174 25.1672 193.275 25.1877 193.362 25.2377C193.45 25.2876 193.518 25.3643 193.558 25.4563C193.487 26.8301 193.453 28.9599 193.453 30.4387L193.445 44.1463ZM189.803 39.9118C189.846 39.327 189.688 38.7451 189.357 38.2608C189.096 37.894 188.749 37.5961 188.346 37.3926C187.944 37.1892 187.498 37.0863 187.047 37.093C184.704 37.093 183.22 39.3951 183.22 42.2811C183.22 45.8225 185.083 48.1624 187.532 48.1624C188.276 48.149 188.992 47.8821 189.563 47.4063C189.763 47.1276 189.849 46.7837 189.803 46.4443V39.9118Z" fill="#075955" />
    <path d="M202.594 45.6563C202.594 48.1769 202.733 48.5424 203.905 48.7482L204.524 48.8532C204.764 49.0927 204.731 49.6934 204.457 49.8489C203.192 49.7774 202.008 49.7438 200.735 49.7438C199.597 49.7438 198.426 49.7774 197.115 49.8489C196.837 49.7102 196.803 49.0927 197.043 48.8532L197.663 48.7314C198.835 48.5256 198.969 48.1811 198.969 45.6395V40.5984C198.969 39.0524 198.969 38.6407 198.127 38.124L197.705 37.8846C197.651 37.7582 197.625 37.6212 197.631 37.4837C197.637 37.3463 197.674 37.2119 197.739 37.0906C199.299 36.6561 200.802 36.037 202.215 35.2464C202.311 35.2523 202.405 35.28 202.489 35.3273C202.572 35.3746 202.645 35.4403 202.699 35.5194C202.628 36.6537 202.594 38.3047 202.594 39.7204V45.6563ZM198.666 30.5035C198.658 30.0866 198.776 29.6768 199.003 29.3264C199.229 28.9761 199.556 28.701 199.94 28.5362C200.325 28.3714 200.749 28.3244 201.161 28.4011C201.572 28.4778 201.951 28.6748 202.249 28.967C202.548 29.2592 202.752 29.6334 202.837 30.0418C202.921 30.4503 202.882 30.8745 202.724 31.2605C202.565 31.6466 202.295 31.9769 201.948 32.2094C201.601 32.4419 201.192 32.5661 200.773 32.5662C200.496 32.5784 200.22 32.5337 199.961 32.4348C199.703 32.336 199.467 32.1851 199.27 31.9916C199.072 31.7981 198.916 31.5662 198.813 31.3102C198.709 31.0541 198.659 30.7796 198.666 30.5035V30.5035Z" fill="#075955" />
    <path d="M211.831 45.6595C211.831 48.1801 211.97 48.6002 213.141 48.785L213.656 48.8564C213.773 49 213.831 49.1827 213.819 49.3675C213.806 49.5523 213.724 49.7255 213.588 49.8521C212.383 49.7806 211.211 49.747 209.934 49.747C208.83 49.747 207.658 49.7806 206.213 49.8521C205.935 49.7134 205.901 49.0959 206.141 48.8564L206.9 48.7514C208.071 48.5792 208.21 48.2011 208.21 45.6595V40.6184C208.21 39.0724 208.21 38.6607 207.367 38.144L206.946 37.9046C206.893 37.7777 206.869 37.6406 206.875 37.5033C206.882 37.366 206.919 37.2319 206.984 37.1106C208.537 36.649 210.039 36.0281 211.464 35.258C211.557 35.2555 211.649 35.28 211.728 35.3285C211.807 35.3769 211.871 35.4473 211.911 35.531C211.843 36.1864 211.843 36.8375 211.843 37.1106C211.843 37.3837 211.944 37.4215 212.151 37.4551C213.761 36.5519 214.726 35.7117 216.863 35.7117C219.653 35.7117 220.964 37.6357 220.964 40.3327V45.6595C220.964 48.1801 221.103 48.6002 222.274 48.7514L223.067 48.8564C223.307 49.0959 223.273 49.6966 222.999 49.8521C221.549 49.7806 220.378 49.747 219.105 49.747C217.967 49.747 216.794 49.782 215.586 49.8521C215.312 49.7134 215.278 49.0959 215.518 48.8564L216.037 48.785C217.208 48.6128 217.343 48.2011 217.343 45.6595V40.9167C217.343 39.1649 216.656 37.7912 214.587 37.7912C214.151 37.7816 213.718 37.873 213.323 38.0582C212.928 38.2433 212.582 38.5171 212.311 38.8582C211.864 39.4757 211.831 39.7824 211.831 41.0553V45.6595Z" fill="#075955" />
    <path d="M236.921 38.7507C237.057 39.2313 237.115 39.7305 237.093 40.2294C237.093 43.1155 235.163 45.8965 230.772 45.8965C230.332 45.8839 229.894 45.8375 229.461 45.7578C228.909 46.0351 228.39 46.4804 228.39 47.0979C228.39 47.7869 228.875 48.438 230.287 48.438C231.286 48.438 232.596 48.3708 234.08 48.3708C236.562 48.3708 239.698 48.7909 239.698 52.5298C239.698 54.3152 238.455 55.9997 237.527 56.7307C235.634 58.2463 233.267 59.0494 230.839 58.9992C225.98 58.9992 224.395 56.7307 224.395 54.9453C224.361 54.4107 224.532 53.8832 224.875 53.4708C225.187 53.1263 226.498 52.3701 227.012 51.9206C227.29 51.7148 227.531 51.2695 227.219 51.1687C226.013 50.7486 224.943 49.6563 224.943 48.3162C224.945 48.1383 224.998 47.9646 225.096 47.8157C225.194 47.6668 225.332 47.549 225.495 47.476C226.013 47.203 227.703 46.1359 227.703 45.657C227.703 45.552 227.703 45.3462 227.564 45.2789C226.785 44.9222 226.13 44.3438 225.68 43.6165C225.23 42.8892 225.006 42.0454 225.036 41.1914C225.036 38.0953 227.619 35.7596 231.357 35.7596C232.559 35.7341 233.751 35.9812 234.843 36.4822C235.563 36.8266 235.943 36.9989 236.356 36.9989H239.727C239.815 37.0758 239.885 37.1709 239.933 37.2775C239.98 37.3842 240.004 37.4998 240.001 37.6164C240.001 38.3012 239.727 38.7171 239.454 38.7843L236.921 38.7507ZM231.13 51.4291C229.924 51.4291 229.2 51.5299 228.786 51.8492C228.472 52.064 228.214 52.3509 228.035 52.6857C227.855 53.0205 227.759 53.3935 227.754 53.7732C227.754 55.8737 229.617 57.2432 232.268 57.2432C235.197 57.2432 236.853 56.0418 236.853 54.0505C236.853 52.2945 235.235 51.4375 233.098 51.4375L231.13 51.4291ZM231.408 44.59C232.719 44.59 233.684 43.4599 233.684 41.0528C233.684 38.7171 232.681 37.0325 231.13 37.0325C229.924 37.0325 228.858 38.1667 228.858 40.5361C228.85 43.1827 230.114 44.59 231.408 44.59V44.59Z" fill="#075955" />
    <path d="M1.68502 4.12076C1.10762 3.54103 1.10762 1.80604 1.90417 1.6002C4.36969 1.74303 7.48845 1.81445 11.0413 1.81445C13.4352 1.81445 15.5382 1.81445 18.1513 1.6002C18.1513 6.0826 20.68 10.4894 23.8788 17.2865L28.6581 27.4738C29.324 28.9189 31.9329 33.4727 32.9486 35.2876C33.8926 33.5526 35.0558 31.3135 37.0114 27.0453L40.7118 19.0845C44.193 11.6405 46.0052 7.80919 47.6236 1.5918C50.0064 1.79003 52.398 1.86155 54.7884 1.80605C57.1822 1.80605 60.5201 1.73463 63.129 1.5918C63.9297 2.01189 63.9297 3.3982 63.3481 4.11236L61.2408 4.47364C58.2654 4.97776 56.8156 5.84735 56.8156 9.67861C56.8156 24.571 56.9631 32.3637 57.3972 41.4881C57.5405 44.8153 57.8186 46.5293 60.6592 46.9116L62.9814 47.2014C63.5631 47.777 63.5631 49.4405 62.8382 49.722C59.5002 49.5792 56.3815 49.5035 52.8286 49.5035C49.5623 49.5035 45.5754 49.5792 42.0941 49.722C41.2217 49.3607 41.1501 47.6971 41.875 47.2014L44.0497 46.9116C47.3876 46.4915 47.3876 45.6093 47.3876 41.1983L47.2443 22.4033V14.1694H47.0968C41.7233 25.7346 35.924 37.8081 31.1363 50.0287C30.7242 50.3554 30.2131 50.5331 29.6865 50.5328C29.0327 50.557 28.3837 50.4123 27.8026 50.1127C24.9704 41.1479 16.0524 23.2266 11.9896 13.8207H11.8463C11.5555 18.5216 11.4838 21.7017 11.4248 25.4616C11.3363 30.1456 11.2647 37.388 11.2647 41.715C11.2647 45.3278 11.6861 46.336 14.6026 46.7561L17.4306 47.1762C18.008 47.6803 17.852 49.3439 17.2831 49.6968C14.6026 49.554 11.4838 49.4783 8.72753 49.4783C6.40531 49.4783 3.67007 49.554 0.749386 49.6968C-0.194673 49.4069 -0.194673 47.5291 0.458582 47.1762L1.76088 46.9578C5.47811 46.3402 5.89957 45.9075 6.32102 41.4251C6.68347 37.8837 6.89842 31.5907 7.33673 24.7264C7.62332 19.8827 7.75818 14.0266 7.75818 10.1197C7.75818 5.99858 6.16087 4.91474 3.26126 4.41063L1.68502 4.12076Z" fill="#075955" />
    <path d="M108.563 41.3542C108.563 44.3873 108.711 45.8366 110.089 46.1223C110.38 46.4121 110.304 47.643 109.87 47.8573C108.151 48.0854 106.452 48.4493 104.791 48.9453C102.262 49.7393 96.0164 50.6803 91.9536 50.6803C87.0225 50.6803 82.8164 50.172 77.6662 48.1597C69.1823 44.7654 63.3789 36.7374 63.3789 26.3148C63.3789 17.2786 67.4417 9.32622 76.1448 4.46993C81.0758 1.79393 86.9509 0.638672 93.1168 0.638672C101.167 0.638672 105.158 2.37366 107.476 2.37366C107.695 4.54135 107.767 9.60348 108.491 14.0145C108.201 14.8084 106.245 14.8799 105.735 14.1573C103.417 7.36018 98.8443 3.96582 92.1011 3.96582C80.5701 3.96582 74.9858 13.5062 74.9858 24.9285C74.9858 35.267 78.5386 41.413 83.2505 44.5931C86.7317 46.9078 90.5037 47.3405 93.1884 47.3405C97.3229 47.3405 98.1911 46.5466 98.1911 41.8457V37.3045C98.1911 33.4018 97.7696 32.9649 94.2758 32.4608L91.0854 32.0407C90.2888 31.6206 90.2888 29.9402 91.0854 29.5201C96.2355 29.6672 99.7884 29.7386 103.341 29.7386C106.822 29.7386 110.38 29.6672 112.845 29.5201C113.422 29.9402 113.422 31.6206 112.845 32.0407L111.159 32.3264C108.985 32.6919 108.546 33.4144 108.546 37.3171L108.563 41.3542Z" fill="#075955" />
    <path d="M130.204 7.23155C130.204 5.49656 130.204 5.13108 128.463 5.13108H124.392C119.094 5.13108 118.18 8.02133 116.005 13.3019C115.133 13.722 113.754 13.5876 113.32 12.6508C114.846 7.66005 115.208 3.17764 115.714 0.358809C116.009 0.132149 116.37 0.00686792 116.743 0.00172887C117.104 -0.0147588 117.46 0.0870441 117.758 0.291594C118.121 1.51827 118.34 1.80813 123.852 1.80813H149.511C154.298 1.80813 155.386 1.73672 156.039 0.220178C156.324 0.064916 156.646 -0.0105686 156.97 0.00172887C157.174 0.0115626 157.374 0.0615421 157.559 0.148785C157.743 0.236028 157.909 0.358807 158.045 0.510043C157.333 2.96759 156.823 8.82371 156.97 12.9406C156.608 13.5918 154.648 13.7388 154.286 13.1591C153.051 8.53804 152.039 5.13528 146.598 5.13528H142.531C140.791 5.13528 140.576 5.34953 140.576 7.23575V39.1125C140.576 45.834 140.997 46.4137 144.272 46.8506L147.176 47.2119C147.682 47.632 147.682 49.3124 147.176 49.7325C142.46 49.5896 138.835 49.514 135.569 49.514C132.088 49.514 128.316 49.5896 123.241 49.7325C122.66 49.3124 122.66 47.632 123.241 47.2119L126.503 46.8506C129.77 46.4851 130.204 45.8382 130.204 39.1125V7.23155Z" fill="#075955" />
  </SvgIcon>
);

export const LogoInfoMiddle = (props: SvgIconProps) => (
  <SvgIcon {...props} width="202" height="50" viewBox="0 0 202 50" style={{ width: 'unset', height: 'unset' }}>
    <path d="M130.308 34.4126C130.308 33.1025 130.308 32.7537 129.58 32.3158L129.226 32.1128C129.18 32.0058 129.158 31.8897 129.163 31.7732C129.168 31.6567 129.199 31.5428 129.254 31.44C130.521 31.0362 131.745 30.5104 132.911 29.87C132.997 29.8643 133.083 29.8864 133.156 29.9332C133.229 29.98 133.285 30.0489 133.316 30.1299C133.287 30.6853 133.259 31.1979 133.259 31.44C133.259 31.6465 133.372 31.796 133.55 31.796C134.447 31.1552 135.405 30.2794 136.388 30.2794C137.37 30.2794 137.984 30.9487 137.984 31.7034C137.995 31.9233 137.962 32.1433 137.885 32.3495C137.808 32.5558 137.69 32.744 137.538 32.9024C137.385 33.0607 137.202 33.1858 136.999 33.2699C136.796 33.3539 136.578 33.3951 136.359 33.3909C136.081 33.3907 135.809 33.3104 135.575 33.1595C135.174 32.843 134.69 32.6512 134.181 32.6077C133.514 32.6077 133.34 33.277 133.34 34.701V38.6918C133.34 40.8278 133.457 41.166 134.472 41.2835L135.604 41.401C135.806 41.6039 135.777 42.113 135.547 42.2447C133.805 42.1842 132.819 42.1557 131.744 42.1557C130.79 42.1557 129.832 42.1842 128.729 42.2447C128.495 42.1272 128.467 41.6039 128.672 41.401L129.194 41.312C130.18 41.1375 130.297 40.8456 130.297 38.6918L130.308 34.4126Z" fill="#075955" />
    <path d="M147.8 38.4517C147.711 40.1392 148.154 41.0114 149.194 41.0114C149.54 40.9701 149.875 40.8612 150.18 40.691C150.325 40.691 150.534 41.0114 150.439 41.2749C149.86 42.0901 148.438 42.5565 147.537 42.5565C147.152 42.5731 146.767 42.5092 146.407 42.3689C146.048 42.2285 145.721 42.0148 145.448 41.7412C145.246 41.5347 145.132 41.3852 144.987 41.3852C144.841 41.3852 144.632 41.5276 144.203 41.7911C143.467 42.2697 142.613 42.5324 141.737 42.5493C139.765 42.5493 138.775 41.1787 138.775 39.7227C138.775 38.676 139.183 37.7148 141.184 37.0171C142.198 36.6611 143.312 36.2588 144.376 35.8529C144.958 35.6464 145.043 35.3866 145.043 35.0946L145.072 34.3364C145.132 32.8518 144.717 31.5702 143.298 31.5702C142.925 31.5375 142.555 31.6538 142.267 31.8938C141.98 32.1338 141.798 32.4781 141.762 32.8518C141.767 33.0622 141.719 33.2704 141.622 33.4571C141.525 33.6438 141.383 33.803 141.209 33.9198C140.642 34.2432 140.005 34.4229 139.354 34.4432C138.978 34.4432 138.804 34.2402 138.804 33.9768C138.804 33.6208 138.978 33.0156 139.935 32.1149C140.822 31.2676 142.926 30.2637 144.405 30.2637C146.696 30.2637 148.119 31.3317 148.002 33.9056L147.8 38.4517ZM144.962 37.4585C144.962 37.1381 144.873 36.9637 144.728 36.9637C144.288 37.0221 143.858 37.1419 143.451 37.3197C142.638 37.6116 141.886 38.0317 141.886 39.2741C141.879 39.4929 141.916 39.7108 141.994 39.915C142.073 40.1192 142.191 40.3056 142.343 40.463C142.494 40.6204 142.676 40.7456 142.876 40.8312C143.077 40.9169 143.293 40.9612 143.511 40.9616C144.554 40.9616 144.873 40.2496 144.901 39.5625L144.962 37.4585Z" fill="#075955" />
    <path d="M162.815 37.4103C162.815 40.059 163.017 40.9704 163.524 40.9704C163.943 40.9551 164.356 40.8659 164.745 40.7069C164.975 40.8814 165.035 41.5827 164.716 41.7749C163.146 41.9416 161.604 42.3126 160.13 42.8785C160.048 42.8609 159.973 42.8225 159.911 42.767C159.849 42.7114 159.802 42.6407 159.775 42.5617L159.835 42.1238C159.892 41.8538 159.882 41.5739 159.807 41.3086L159.661 41.2801C158.789 41.9209 157.718 42.5902 156.323 42.5902C153.017 42.5902 150.957 39.9095 150.957 36.9974C150.957 32.8072 154.294 30.3009 157.92 30.3009C158.487 30.2865 159.054 30.356 159.601 30.5074C159.718 30.4754 159.775 30.3615 159.775 30.0411V26.1998C159.775 24.2489 159.718 24.0638 159.108 23.6686L158.704 23.4052C158.53 23.2877 158.498 22.8249 158.704 22.7359C159.835 22.4155 161.343 21.8031 162.503 21.3368C162.587 21.3267 162.672 21.3441 162.746 21.3864C162.819 21.4287 162.877 21.4937 162.911 21.5717C162.85 22.7359 162.822 24.5408 162.822 25.7939L162.815 37.4103ZM159.75 33.8218C159.786 33.3262 159.653 32.8331 159.374 32.4227C159.154 32.1119 158.863 31.8594 158.524 31.687C158.185 31.5146 157.81 31.4274 157.43 31.433C155.458 31.433 154.209 33.3839 154.209 35.8297C154.209 38.8308 155.777 40.8137 157.838 40.8137C158.464 40.8023 159.068 40.5762 159.548 40.1729C159.716 39.9368 159.788 39.6453 159.75 39.3577V33.8218Z" fill="#075955" />
    <path d="M170.515 38.6902C170.515 40.8262 170.632 41.136 171.619 41.3104L172.14 41.3994C172.342 41.6023 172.314 42.1114 172.083 42.2431C171.019 42.1826 170.022 42.1541 168.951 42.1541C167.993 42.1541 167.007 42.1826 165.904 42.2431C165.67 42.1256 165.641 41.6023 165.844 41.3994L166.365 41.2962C167.351 41.1217 167.465 40.8298 167.465 38.676V34.4039C167.465 33.0938 167.465 32.745 166.755 32.3071L166.401 32.1041C166.355 31.9971 166.333 31.881 166.338 31.7645C166.343 31.648 166.374 31.5341 166.429 31.4313C167.742 31.0631 169.007 30.5384 170.196 29.8684C170.277 29.8735 170.356 29.8969 170.427 29.937C170.497 29.9771 170.558 30.0328 170.604 30.0998C170.544 31.0611 170.515 32.4602 170.515 33.6599V38.6902ZM167.209 25.8492C167.203 25.4958 167.302 25.1486 167.493 24.8517C167.684 24.5548 167.958 24.3216 168.282 24.182C168.605 24.0423 168.963 24.0025 169.309 24.0675C169.655 24.1325 169.974 24.2995 170.225 24.5471C170.477 24.7947 170.649 25.1118 170.72 25.4579C170.791 25.804 170.758 26.1636 170.624 26.4907C170.491 26.8178 170.264 27.0977 169.972 27.2948C169.679 27.4918 169.335 27.5971 168.983 27.5971C168.75 27.6075 168.517 27.5696 168.3 27.4858C168.082 27.4021 167.884 27.2742 167.717 27.1103C167.551 26.9463 167.42 26.7497 167.333 26.5328C167.245 26.3158 167.203 26.0831 167.209 25.8492Z" fill="#075955" />
    <path d="M178.29 38.6937C178.29 40.8297 178.407 41.1857 179.393 41.3424L179.826 41.4029C179.924 41.5246 179.974 41.6794 179.963 41.836C179.952 41.9926 179.883 42.1394 179.769 42.2466C178.754 42.1861 177.768 42.1576 176.693 42.1576C175.764 42.1576 174.778 42.1861 173.561 42.2466C173.327 42.1291 173.299 41.6058 173.501 41.4029L174.139 41.3139C175.126 41.1679 175.243 40.8475 175.243 38.6937V34.4217C175.243 33.1116 175.243 32.7627 174.533 32.3248L174.178 32.1219C174.134 32.0143 174.113 31.8982 174.119 31.7818C174.124 31.6655 174.156 31.5518 174.21 31.449C175.518 31.0578 176.782 30.5317 177.981 29.8791C178.059 29.877 178.136 29.8977 178.203 29.9388C178.27 29.9799 178.323 30.0395 178.357 30.1105C178.3 30.6658 178.3 31.2176 178.3 31.449C178.3 31.6804 178.385 31.7125 178.559 31.741C179.914 30.9755 180.727 30.2635 182.525 30.2635C184.873 30.2635 185.976 31.894 185.976 34.1796V38.6937C185.976 40.8297 186.094 41.1857 187.08 41.3139L187.747 41.4029C187.949 41.6058 187.92 42.1149 187.69 42.2466C186.47 42.1861 185.483 42.1576 184.412 42.1576C183.454 42.1576 182.467 42.1873 181.45 42.2466C181.22 42.1291 181.191 41.6058 181.393 41.4029L181.83 41.3424C182.816 41.1964 182.929 40.8475 182.929 38.6937V34.6744C182.929 33.1899 182.351 32.0258 180.61 32.0258C180.242 32.0177 179.878 32.0951 179.546 32.252C179.214 32.4089 178.922 32.641 178.694 32.93C178.318 33.4533 178.29 33.7132 178.29 34.7919V38.6937Z" fill="#075955" />
    <path d="M199.407 32.839C199.521 33.2463 199.57 33.6693 199.552 34.0921C199.552 36.5379 197.928 38.8946 194.231 38.8946C193.861 38.884 193.492 38.8447 193.128 38.7771C192.664 39.0121 192.227 39.3895 192.227 39.9128C192.227 40.4966 192.635 41.0484 193.823 41.0484C194.664 41.0484 195.767 40.9915 197.016 40.9915C199.105 40.9915 201.744 41.3475 201.744 44.5159C201.744 46.0289 200.698 47.4565 199.918 48.0759C198.324 49.3603 196.331 50.0409 194.288 49.9984C190.198 49.9984 188.864 48.0759 188.864 46.5629C188.836 46.1099 188.98 45.6629 189.269 45.3134C189.531 45.0214 190.635 44.3806 191.067 43.9997C191.301 43.8253 191.504 43.4479 191.241 43.3625C190.227 43.0065 189.326 42.0808 189.326 40.9452C189.327 40.7944 189.372 40.6472 189.454 40.521C189.537 40.3949 189.653 40.295 189.79 40.2332C190.227 40.0018 191.649 39.0975 191.649 38.6917C191.649 38.6027 191.649 38.4282 191.532 38.3713C190.876 38.069 190.324 37.5788 189.946 36.9625C189.567 36.3461 189.378 35.6311 189.404 34.9074C189.404 32.2836 191.578 30.3043 194.724 30.3043C195.736 30.2826 196.739 30.492 197.658 30.9166C198.265 31.2085 198.584 31.3545 198.931 31.3545H201.769C201.843 31.4196 201.902 31.5002 201.942 31.5906C201.982 31.681 202.002 31.779 202 31.8778C202 32.4581 201.769 32.8105 201.539 32.8675L199.407 32.839ZM194.533 43.5832C193.518 43.5832 192.908 43.6686 192.561 43.9392C192.296 44.1212 192.079 44.3643 191.928 44.648C191.777 44.9318 191.696 45.2479 191.692 45.5697C191.692 47.3497 193.259 48.5103 195.491 48.5103C197.956 48.5103 199.35 47.4921 199.35 45.8046C199.35 44.3165 197.988 43.5903 196.189 43.5903L194.533 43.5832ZM194.767 37.7874C195.87 37.7874 196.683 36.8298 196.683 34.7899C196.683 32.8105 195.838 31.3829 194.533 31.3829C193.518 31.3829 192.621 32.3442 192.621 34.352C192.614 36.5948 193.678 37.7874 194.767 37.7874Z" fill="#075955" />
    <path d="M1.41821 3.49275C0.932243 3.00147 0.932243 1.53117 1.60267 1.35673C3.6778 1.47777 6.30274 1.53829 9.29305 1.53829C11.3079 1.53829 13.0779 1.53829 15.2772 1.35673C15.2772 5.15529 17.4056 8.88976 20.0979 14.6499L24.1204 23.283C24.6809 24.5076 26.8766 28.3667 27.7315 29.9046C28.5261 28.4343 29.5051 26.5368 31.151 22.9199L34.2655 16.1736C37.1955 9.86521 38.7208 6.61846 40.083 1.34961C42.0884 1.5176 44.1014 1.5782 46.1132 1.53117C48.1281 1.53117 50.9375 1.47065 53.1332 1.34961C53.8072 1.70561 53.8072 2.88042 53.3177 3.48563L51.544 3.79179C49.0397 4.219 47.8195 4.95592 47.8195 8.20267C47.8195 20.823 47.9436 27.4269 48.309 35.1593C48.4296 37.9788 48.6637 39.4313 51.0545 39.7553L53.009 40.0009C53.4986 40.4886 53.4986 41.8984 52.8884 42.1369C50.079 42.0159 47.4541 41.9518 44.4638 41.9518C41.7147 41.9518 38.359 42.0159 35.429 42.1369C34.6947 41.8308 34.6344 40.421 35.2445 40.0009L37.0749 39.7553C39.8843 39.3993 39.8843 38.6516 39.8843 34.9136L39.7637 18.986V12.0084H39.6396C35.1168 21.8091 30.2359 32.0407 26.2062 42.3968C25.8594 42.6737 25.4292 42.8243 24.986 42.824C24.4357 42.8446 23.8895 42.7219 23.4004 42.468C21.0166 34.8709 13.5107 19.6838 10.0912 11.7129H9.97057C9.72581 15.6965 9.66551 18.3915 9.61585 21.5777C9.54136 25.5472 9.48105 31.6847 9.48105 35.3515C9.48105 38.4131 9.83578 39.2675 12.2905 39.6235L14.6706 39.9795C15.1566 40.4067 15.0254 41.8165 14.5465 42.1156C12.2905 41.9945 9.66551 41.9304 7.34562 41.9304C5.3911 41.9304 3.08896 41.9945 0.630729 42.1156C-0.163849 41.8699 -0.163849 40.2786 0.385971 39.9795L1.48206 39.7944C4.61071 39.2711 4.96544 38.9044 5.32016 35.1059C5.62522 32.1047 5.80613 26.7718 6.17504 20.9547C6.41625 16.85 6.52976 11.8873 6.52976 8.57648C6.52976 5.08408 5.18537 4.1656 2.74487 3.73839L1.41821 3.49275Z" fill="#075955" />
    <path d="M91.3735 35.0449C91.3735 37.6152 91.4977 38.8434 92.6576 39.0855C92.9024 39.3311 92.8385 40.3742 92.4732 40.5558C91.0263 40.7491 89.597 41.0575 88.1988 41.4778C86.0704 42.1507 80.8134 42.9481 77.3939 42.9481C73.2437 42.9481 69.7035 42.5174 65.3688 40.8121C58.2283 37.9356 53.3438 31.1324 53.3438 22.2999C53.3438 14.6423 56.7633 7.90316 64.0883 3.78777C68.2385 1.52003 73.1834 0.541016 78.373 0.541016C85.1482 0.541016 88.5074 2.01131 90.4583 2.01131C90.6428 3.84829 90.7031 8.13813 91.3132 11.8762C91.0685 12.549 89.4226 12.6095 88.9933 11.9972C87.0424 6.23707 83.1936 3.36056 77.5181 3.36056C67.8129 3.36056 63.1128 11.4454 63.1128 21.1251C63.1128 29.8864 66.1031 35.0947 70.0689 37.7896C72.9989 39.7512 76.1737 40.1179 78.4333 40.1179C81.9131 40.1179 82.6438 39.4451 82.6438 35.4614V31.613C82.6438 28.3057 82.2891 27.9355 79.3484 27.5083L76.6632 27.1523C75.9928 26.7963 75.9928 25.3722 76.6632 25.0162C80.9979 25.1408 83.9882 25.2014 86.9785 25.2014C89.9085 25.2014 92.9024 25.1408 94.9775 25.0162C95.4635 25.3722 95.4635 26.7963 94.9775 27.1523L93.5586 27.3943C91.7283 27.7041 91.3593 28.3164 91.3593 31.6237L91.3735 35.0449Z" fill="#075955" />
    <path d="M109.588 6.12828C109.588 4.65799 109.588 4.34827 108.123 4.34827H104.696C100.238 4.34827 99.4679 6.79757 97.6375 11.2725C96.9032 11.6285 95.7433 11.5146 95.3779 10.7207C96.662 6.49141 96.9671 2.69285 97.3928 0.304068C97.6411 0.111988 97.9448 0.00582013 98.2583 0.00146511C98.5623 -0.0125071 98.8626 0.0737644 99.1132 0.247107C99.4182 1.28664 99.6027 1.53228 104.242 1.53228H125.838C129.868 1.53228 130.783 1.47176 131.333 0.186587C131.572 0.0550123 131.843 -0.00895622 132.117 0.00146511C132.288 0.00979855 132.456 0.052153 132.612 0.126086C132.767 0.200019 132.906 0.304067 133.021 0.432229C132.422 2.51485 131.992 7.47754 132.117 10.9664C131.811 11.5182 130.162 11.6428 129.857 11.1515C128.818 7.23545 127.966 4.35183 123.387 4.35183H119.964C118.499 4.35183 118.318 4.53339 118.318 6.13184V33.1454C118.318 38.8414 118.673 39.3327 121.429 39.703L123.873 40.0091C124.298 40.3651 124.298 41.7891 123.873 42.1451C119.903 42.0241 116.853 41.96 114.104 41.96C111.174 41.96 107.999 42.0241 103.728 42.1451C103.239 41.7891 103.239 40.3651 103.728 40.0091L106.474 39.703C109.223 39.3932 109.588 38.845 109.588 33.1454V6.12828Z" fill="#075955" />
  </SvgIcon>
);

export const LogoInfoSmall = (props: SvgIconProps) => (
  <SvgIcon {...props} width="138" height="34" viewBox="0 0 138 34" style={{ width: 'unset', height: 'unset' }}>
    <path d="M89.0221 23.4021C89.0221 22.5112 89.0221 22.274 88.5253 21.9762L88.2829 21.8382C88.2516 21.7654 88.237 21.6865 88.2404 21.6072C88.2437 21.528 88.2649 21.4506 88.3023 21.3807C89.1676 21.1061 90.0044 20.7486 90.8008 20.3131C90.8597 20.3092 90.9183 20.3243 90.968 20.3561C91.0177 20.3879 91.056 20.4347 91.0771 20.4898C91.0577 20.8675 91.0383 21.2161 91.0383 21.3807C91.0383 21.5211 91.1158 21.6228 91.237 21.6228C91.8501 21.187 92.5044 20.5915 93.1757 20.5915C93.847 20.5915 94.2662 21.0466 94.2662 21.5598C94.2742 21.7093 94.2513 21.8589 94.1989 21.9992C94.1464 22.1394 94.0656 22.2674 93.9615 22.3751C93.8573 22.4828 93.7321 22.5678 93.5935 22.625C93.455 22.6822 93.3062 22.7102 93.1563 22.7073C92.9664 22.7072 92.7805 22.6526 92.6207 22.5499C92.3467 22.3347 92.0157 22.2043 91.6684 22.1747C91.2128 22.1747 91.094 22.6298 91.094 23.5982V26.3119C91.094 27.7644 91.174 27.9944 91.8671 28.0743L92.6401 28.1541C92.7783 28.2921 92.7589 28.6383 92.6014 28.7279C91.4115 28.6867 90.7378 28.6674 90.0035 28.6674C89.3516 28.6674 88.6973 28.6867 87.9437 28.7279C87.7837 28.648 87.7643 28.2921 87.9049 28.1541L88.2611 28.0936C88.9348 27.975 89.0148 27.7765 89.0148 26.3119L89.0221 23.4021Z" fill="#075955" />
    <path d="M100.972 26.146C100.911 27.2935 101.214 27.8866 101.924 27.8866C102.161 27.8585 102.389 27.7845 102.598 27.6687C102.697 27.6687 102.84 27.8866 102.775 28.0657C102.38 28.6201 101.408 28.9372 100.792 28.9372C100.529 28.9485 100.266 28.9051 100.02 28.8097C99.7747 28.7142 99.5515 28.5689 99.365 28.3829C99.2268 28.2424 99.1493 28.1408 99.0499 28.1408C98.9506 28.1408 98.8076 28.2376 98.5144 28.4167C98.0121 28.7422 97.4287 28.9208 96.8301 28.9324C95.4827 28.9324 94.8066 28.0004 94.8066 27.0103C94.8066 26.2985 95.0853 25.6449 96.4521 25.1704C97.1452 24.9283 97.9061 24.6548 98.6331 24.3788C99.0305 24.2384 99.0887 24.0617 99.0887 23.8632L99.1081 23.3475C99.1493 22.3381 98.8658 21.4666 97.8964 21.4666C97.6418 21.4443 97.3887 21.5234 97.1922 21.6866C96.9957 21.8498 96.8717 22.084 96.8471 22.3381C96.8504 22.4811 96.8175 22.6227 96.7514 22.7497C96.6853 22.8766 96.5882 22.9848 96.4691 23.0643C96.0819 23.2842 95.6468 23.4064 95.2016 23.4202C94.9448 23.4202 94.826 23.2822 94.826 23.103C94.826 22.861 94.9448 22.4494 95.5991 21.837C96.2049 21.2608 97.642 20.5781 98.6525 20.5781C100.218 20.5781 101.19 21.3044 101.11 23.0546L100.972 26.146ZM99.033 25.4706C99.033 25.2527 98.9724 25.1341 98.873 25.1341C98.5724 25.1738 98.2787 25.2553 98.0006 25.3762C97.4457 25.5747 96.9319 25.8604 96.9319 26.7052C96.927 26.854 96.9521 27.0022 97.0057 27.141C97.0593 27.2799 97.1402 27.4066 97.2437 27.5136C97.3472 27.6207 97.4712 27.7058 97.6083 27.7641C97.7454 27.8223 97.8928 27.8525 98.0418 27.8527C98.7543 27.8527 98.9724 27.3685 98.9918 26.9013L99.033 25.4706Z" fill="#075955" />
    <path d="M111.23 25.4397C111.23 27.2408 111.368 27.8606 111.715 27.8606C112.001 27.8502 112.283 27.7895 112.548 27.6814C112.706 27.8 112.747 28.2769 112.529 28.4077C111.456 28.521 110.403 28.7733 109.396 29.1581C109.34 29.1461 109.289 29.12 109.246 29.0822C109.204 29.0445 109.172 28.9964 109.153 28.9427L109.195 28.6449C109.233 28.4613 109.227 28.271 109.175 28.0905L109.076 28.0712C108.48 28.5069 107.748 28.962 106.795 28.962C104.537 28.962 103.129 27.1391 103.129 25.1589C103.129 22.3096 105.409 20.6053 107.886 20.6053C108.274 20.5955 108.661 20.6428 109.035 20.7458C109.115 20.724 109.153 20.6465 109.153 20.4286V17.8166C109.153 16.4899 109.115 16.3641 108.698 16.0954L108.422 15.9162C108.303 15.8363 108.281 15.5216 108.422 15.4611C109.195 15.2432 110.224 14.8268 111.017 14.5097C111.074 14.5028 111.133 14.5147 111.183 14.5435C111.233 14.5723 111.273 14.6164 111.296 14.6695C111.254 15.4611 111.235 16.6885 111.235 17.5406L111.23 25.4397ZM109.136 22.9995C109.161 22.6625 109.07 22.3272 108.88 22.0482C108.729 21.8368 108.53 21.6651 108.299 21.5479C108.067 21.4306 107.811 21.3713 107.552 21.3752C106.204 21.3752 105.351 22.7018 105.351 24.3649C105.351 26.4056 106.422 27.754 107.83 27.754C108.258 27.7463 108.67 27.5925 108.998 27.3183C109.113 27.1577 109.162 26.9595 109.136 26.7639V22.9995Z" fill="#075955" />
    <path d="M116.491 26.3098C116.491 27.7623 116.571 27.9729 117.245 28.0915L117.601 28.1521C117.739 28.29 117.72 28.6362 117.562 28.7258C116.835 28.6846 116.154 28.6653 115.422 28.6653C114.768 28.6653 114.094 28.6846 113.341 28.7258C113.181 28.6459 113.161 28.29 113.299 28.1521L113.656 28.0819C114.329 27.9632 114.407 27.7647 114.407 26.3001V23.3951C114.407 22.5043 114.407 22.267 113.922 21.9693L113.68 21.8313C113.648 21.7585 113.634 21.6795 113.637 21.6003C113.641 21.5211 113.662 21.4437 113.699 21.3738C114.596 21.1234 115.46 20.7666 116.273 20.311C116.328 20.3144 116.382 20.3304 116.43 20.3576C116.479 20.3849 116.52 20.4228 116.552 20.4684C116.51 21.122 116.491 22.0734 116.491 22.8892V26.3098ZM114.232 17.5779C114.228 17.3376 114.295 17.1015 114.426 16.8996C114.556 16.6977 114.744 16.5392 114.965 16.4442C115.186 16.3493 115.43 16.3222 115.667 16.3664C115.903 16.4106 116.121 16.5241 116.293 16.6925C116.464 16.8609 116.582 17.0765 116.631 17.3118C116.679 17.5472 116.656 17.7917 116.565 18.0141C116.474 18.2366 116.319 18.4269 116.119 18.5609C115.92 18.6949 115.685 18.7665 115.444 18.7665C115.285 18.7736 115.126 18.7478 114.977 18.6908C114.829 18.6339 114.693 18.5469 114.579 18.4354C114.466 18.3239 114.376 18.1903 114.317 18.0427C114.257 17.8952 114.228 17.737 114.232 17.5779Z" fill="#075955" />
    <path d="M121.802 26.3124C121.802 27.7649 121.882 28.007 122.556 28.1135L122.852 28.1547C122.919 28.2374 122.953 28.3427 122.946 28.4492C122.938 28.5557 122.891 28.6555 122.813 28.7284C122.12 28.6872 121.446 28.6679 120.712 28.6679C120.077 28.6679 119.403 28.6872 118.572 28.7284C118.412 28.6485 118.393 28.2927 118.531 28.1547L118.967 28.0941C119.641 27.9949 119.721 27.777 119.721 26.3124V23.4074C119.721 22.5166 119.721 22.2793 119.236 21.9816L118.994 21.8436C118.963 21.7705 118.949 21.6915 118.953 21.6124C118.957 21.5332 118.978 21.4559 119.015 21.386C119.909 21.12 120.772 20.7622 121.591 20.3185C121.645 20.317 121.698 20.3311 121.743 20.3591C121.789 20.387 121.825 20.4276 121.848 20.4758C121.81 20.8535 121.81 21.2287 121.81 21.386C121.81 21.5434 121.868 21.5652 121.987 21.5845C122.912 21.0641 123.467 20.5799 124.696 20.5799C126.3 20.5799 127.054 21.6886 127.054 23.2428V26.3124C127.054 27.7649 127.134 28.007 127.807 28.0941L128.263 28.1547C128.401 28.2927 128.382 28.6388 128.224 28.7284C127.391 28.6872 126.717 28.6679 125.985 28.6679C125.331 28.6679 124.656 28.6881 123.962 28.7284C123.804 28.6485 123.785 28.2927 123.923 28.1547L124.221 28.1135C124.895 28.0143 124.972 27.777 124.972 26.3124V23.5793C124.972 22.5698 124.577 21.7782 123.387 21.7782C123.136 21.7727 122.888 21.8254 122.661 21.9321C122.434 22.0388 122.234 22.1966 122.079 22.3931C121.822 22.749 121.802 22.9257 121.802 23.6592V26.3124Z" fill="#075955" />
    <path d="M136.228 22.3301C136.306 22.6071 136.34 22.8947 136.327 23.1823C136.327 24.8454 135.218 26.4479 132.692 26.4479C132.44 26.4407 132.188 26.414 131.939 26.3681C131.621 26.5278 131.323 26.7844 131.323 27.1403C131.323 27.5373 131.602 27.9125 132.414 27.9125C132.988 27.9125 133.742 27.8738 134.595 27.8738C136.022 27.8738 137.825 28.1159 137.825 30.2704C137.825 31.2993 137.11 32.27 136.577 32.6912C135.489 33.5646 134.127 34.0274 132.731 33.9985C129.937 33.9985 129.026 32.6912 129.026 31.6624C129.006 31.3543 129.105 31.0504 129.302 30.8127C129.482 30.6142 130.235 30.1784 130.531 29.9194C130.691 29.8008 130.829 29.5442 130.65 29.4861C129.956 29.244 129.341 28.6146 129.341 27.8423C129.342 27.7398 129.373 27.6397 129.429 27.5539C129.485 27.4681 129.565 27.4002 129.658 27.3582C129.956 27.2008 130.928 26.5859 130.928 26.31C130.928 26.2494 130.928 26.1308 130.848 26.0921C130.4 25.8865 130.023 25.5532 129.765 25.1341C129.506 24.715 129.377 24.2287 129.394 23.7366C129.394 21.9525 130.88 20.6065 133.029 20.6065C133.72 20.5918 134.406 20.7342 135.033 21.0229C135.448 21.2214 135.666 21.3206 135.903 21.3206H137.842C137.893 21.365 137.933 21.4198 137.96 21.4812C137.987 21.5427 138.001 21.6093 138 21.6765C138 22.0711 137.842 22.3108 137.685 22.3495L136.228 22.3301ZM132.898 29.6362C132.205 29.6362 131.789 29.6943 131.551 29.8783C131.37 30.002 131.222 30.1673 131.119 30.3603C131.015 30.5532 130.96 30.7682 130.957 30.987C130.957 32.1974 132.028 32.9866 133.553 32.9866C135.237 32.9866 136.189 32.2942 136.189 31.1468C136.189 30.1349 135.259 29.641 134.03 29.641L132.898 29.6362ZM133.058 25.6951C133.812 25.6951 134.367 25.0439 134.367 23.6567C134.367 22.3108 133.79 21.34 132.898 21.34C132.205 21.34 131.592 21.9936 131.592 23.359C131.587 24.8841 132.314 25.6951 133.058 25.6951Z" fill="#075955" />
    <path d="M0.968878 2.3753C0.636879 2.04123 0.636879 1.04143 1.09489 0.92281C2.51255 1.00512 4.30583 1.04627 6.34872 1.04627C7.72518 1.04627 8.93443 1.04627 10.4369 0.92281C10.4369 3.50583 11.8909 6.04527 13.7302 9.96216L16.4783 15.8327C16.8612 16.6654 18.3613 19.2896 18.9453 20.3354C19.4881 19.3356 20.157 18.0453 21.2814 15.5857L23.4091 10.9983C25.4108 6.70858 26.4528 4.50079 27.3834 0.917969C28.7535 1.0322 30.1287 1.07341 31.5031 1.04143C32.8796 1.04143 34.7989 1.00028 36.2989 0.917969C36.7593 1.16005 36.7594 1.95892 36.4249 2.37046L35.2133 2.57865C33.5024 2.86915 32.6687 3.37026 32.6687 5.57805C32.6687 14.1599 32.7536 18.6505 33.0032 23.9085C33.0856 25.8258 33.2455 26.8135 34.8788 27.0338L36.2141 27.2008C36.5485 27.5325 36.5485 28.4911 36.1317 28.6533C34.2124 28.571 32.4191 28.5275 30.3762 28.5275C28.4981 28.5275 26.2057 28.571 24.204 28.6533C23.7023 28.4451 23.6611 27.4865 24.078 27.2008L25.3284 27.0338C27.2477 26.7917 27.2477 26.2834 27.2477 23.7415L27.1653 12.9107V8.16591H27.0805C23.9907 14.8304 20.6562 21.7879 17.9033 28.8301C17.6663 29.0183 17.3724 29.1208 17.0696 29.1206C16.6937 29.1345 16.3205 29.0511 15.9864 28.8785C14.3579 23.7124 9.23008 13.3852 6.89397 7.96498H6.81158C6.64437 10.6739 6.60317 12.5064 6.56924 14.6731C6.51835 17.3723 6.47716 21.5458 6.47716 24.0393C6.47716 26.1212 6.71949 26.7022 8.39645 26.9442L10.0225 27.1863C10.3545 27.4768 10.2649 28.4355 9.9377 28.6388C8.39645 28.5565 6.60317 28.5129 5.0183 28.5129C3.68303 28.5129 2.11028 28.5565 0.430894 28.6388C-0.111936 28.4718 -0.111936 27.3897 0.263683 27.1863L1.0125 27.0604C3.14989 26.7046 3.39223 26.4552 3.63456 23.8722C3.84297 21.8315 3.96656 18.2051 4.21859 14.2494C4.38338 11.4582 4.46093 8.08361 4.46093 5.83224C4.46093 3.45741 3.54248 2.83284 1.87521 2.54234L0.968878 2.3753Z" fill="#075955" />
    <path d="M62.4231 23.8298C62.4231 25.5776 62.5079 26.4128 63.3004 26.5774C63.4676 26.7445 63.424 27.4538 63.1744 27.5772C62.1859 27.7087 61.2095 27.9184 60.2542 28.2042C58.8002 28.6618 55.2088 29.204 52.8727 29.204C50.0374 29.204 47.6189 28.9111 44.6575 27.7515C39.7793 25.7955 36.4424 21.1693 36.4424 15.1633C36.4424 9.95607 38.7785 5.37345 43.7827 2.57498C46.618 1.03291 49.9962 0.367188 53.5415 0.367188C58.1701 0.367188 60.4651 1.36699 61.7979 1.36699C61.9239 2.61613 61.9651 5.53322 62.3819 8.07509C62.2147 8.53262 61.0903 8.57378 60.7971 8.15739C59.4642 4.2405 56.8349 2.28448 52.9575 2.28448C46.3272 2.28448 43.1163 7.78217 43.1163 14.3644C43.1163 20.322 45.1592 23.8637 47.8685 25.6963C49.8702 27.0301 52.0391 27.2795 53.5827 27.2795C55.96 27.2795 56.4593 26.8219 56.4593 24.113V21.4961C56.4593 19.2472 56.2169 18.9954 54.208 18.7049L52.3735 18.4628C51.9155 18.2208 51.9155 17.2524 52.3735 17.0103C55.3348 17.0951 57.3777 17.1362 59.4206 17.1362C61.4223 17.1362 63.4676 17.0951 64.8853 17.0103C65.2172 17.2524 65.2172 18.2208 64.8853 18.4628L63.9159 18.6275C62.6655 18.8381 62.4134 19.2544 62.4134 21.5034L62.4231 23.8298Z" fill="#075955" />
    <path d="M74.8671 4.16723C74.8671 3.16743 74.8671 2.95682 73.8663 2.95682H71.5253C68.4792 2.95682 67.9533 4.62235 66.7029 7.66532C66.2012 7.9074 65.4088 7.82994 65.1592 7.29009C66.0364 4.41416 66.2448 1.83114 66.5356 0.206766C66.7053 0.0761515 66.9128 0.00395769 67.1269 0.000996272C67.3346 -0.00850485 67.5398 0.0501598 67.711 0.168033C67.9194 0.874913 68.0454 1.04195 71.2151 1.04195H85.9685C88.7214 1.04195 89.3466 1.0008 89.7223 0.126879C89.8861 0.0374083 90.0712 -0.00609023 90.2578 0.000996272C90.3751 0.00666301 90.4901 0.0354641 90.5961 0.0857383C90.7022 0.136013 90.7972 0.206765 90.8758 0.293916C90.4662 1.7101 90.173 5.08472 90.2578 7.45713C90.0494 7.83236 88.9226 7.91709 88.7141 7.58301C88.0041 4.92011 87.4225 2.95924 84.294 2.95924H81.9554C80.9546 2.95924 80.831 3.0827 80.831 4.16965V22.5389C80.831 26.4122 81.0733 26.7462 82.9563 26.998L84.626 27.2062C84.9168 27.4483 84.9168 28.4166 84.626 28.6587C81.9142 28.5764 79.8301 28.5328 77.9521 28.5328C75.9504 28.5328 73.7815 28.5764 70.8638 28.6587C70.5293 28.4166 70.5293 27.4483 70.8638 27.2062L72.7394 26.998C74.6175 26.7874 74.8671 26.4146 74.8671 22.5389V4.16723Z" fill="#075955" />
  </SvgIcon>
);
