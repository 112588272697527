import React, {
  FC,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useCookies } from 'react-cookie';

import { useHistory } from 'react-router-dom';
import MaterialTable, { Column } from 'material-table';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/slices';
import { getDate } from '../../utils/getDate';
import { getAllContracts } from '../../store/slices/contracts.slice';
import { Contract } from '../../interfaces/contract.interface';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import OpenContract from '../../svg/openContract';

const ListOfContracts: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['rowListOfContracts']);

  const [
    currentListOfContracts,
    setListOfContracts,
  ] = useState<Contract[]>([]);

  // Selectors
  const {
    listOfContracts,
  } = useSelector(
    (state: RootState) => state.contracts,
  );

  // Use Efffect
  useEffect(() => {
    const { accountId, clientAccountId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();

    dispatch(getAllContracts(
      clientAccountId || accountId,
      clientAccountId && accountId,
      token,
      key,
    ));
  }, [dispatch]);

  useEffect(() => {
    setListOfContracts(listOfContracts.map((item) => ({ ...item })));
  }, [
    listOfContracts,
  ]);

  const getSignatureRequired = useCallback(
    (rowData) => {
      const {
        seller,
        buyer,
        sellerBroker,
        buyerBroker,
      } = rowData.deal;
      const {
        sellerSignature,
        buyerSignature,
        sellerBrokerSignature,
        buyerBrokerSignature,
      } = rowData;
      const requiredSign = [];
      let requiredAll = false;
      if (seller && !sellerSignature) requiredSign.push('Seller');
      if (buyer && !buyerSignature) requiredSign.push('Buyer');
      if (sellerBroker && !sellerBrokerSignature) requiredSign.push('B Seller');
      if (buyerBroker && !buyerBrokerSignature) requiredSign.push('B Buyer');
      if (
        seller && buyer && sellerBroker && buyerBroker &&
        !sellerSignature && !buyerSignature && !sellerBrokerSignature && !buyerBrokerSignature
      ) requiredAll = true;
      if (
        seller && buyer && buyerBroker && !sellerBroker &&
        !sellerSignature && !buyerSignature && !buyerBrokerSignature
      ) requiredAll = true;
      if (
        seller && buyer && sellerBroker && !buyerBroker &&
        !sellerSignature && !buyerSignature && !sellerBrokerSignature
      ) requiredAll = true;
      if (
        seller && buyer && !sellerBroker && !buyerBroker &&
        !sellerSignature && !buyerSignature
      ) requiredAll = true;
      return { requiredAll, requiredSign };
    },
    [],
  );

  const columns: Array<Column<Contract>> = [
    { title: 'Status', field: 'status' },
    {
      title: 'Signatures Required',
      render: (rowData) => {
        const signs = getSignatureRequired(rowData);
        return signs.requiredAll ? 'All' : signs.requiredSign.map((item) => <div key={item}>{item}</div>);
      },
      customFilterAndSearch: (term, rowData) => ((getSignatureRequired(rowData).requiredSign.join(' ')).toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Type',
      field: 'deal.order.inventory.inventoryItemType.type',
    },
    {
      title: 'Quantity',
      field: 'deal.quantity',
      render: (rowData) => `${rowData.deal.quantity} ${rowData.deal.order.inventory && rowData.deal.order.inventory.inventoryItemType.units}`,
      customFilterAndSearch: (term, rowData) => (`${rowData.deal.quantity} ${rowData.deal.order.inventory && rowData.deal.order.inventory.inventoryItemType.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Price',
      field: 'deal.price',
      render: (rowData) => `$ ${rowData.deal.price && rowData.deal.price.toFixed(2)}`,
      customFilterAndSearch: (term, rowData) => (`$ ${rowData.deal.price && rowData.deal.price.toFixed(2)}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Date',
      field: 'deal.lastDayDate',
      render: (rowData) => getDate(rowData.deal.lastDayDate),
      customFilterAndSearch: (term, rowData) => (
        getDate(rowData.deal.lastDayDate)
      ).indexOf(term) !== -1,
    },
  ];

  return (
    <TableContainer>
      <MaterialTable
        title=""
        columns={columns}
        data={currentListOfContracts}
        actions={[
          {
            icon: () => <OpenContract fontSize="small" />,
            tooltip: 'Open contract',
            onClick: (event, rowData) => {
              const contract = rowData as Contract;
              history.push(
                'contract',
                {
                  contractId: contract.id,
                },
              );
            },
          },
        ]}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
          },
          pageSize: +cookies.rowListOfContracts || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowListOfContracts', pageSize, { path: '/' })}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
        }}
      />
    </TableContainer>
  );
};

export default ListOfContracts;
