import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

// Create
export const apiSignContract = (
  contractId: number,
  signName: string,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.signContract}`;
  return request(
    url,
    'POST',
    {
      accountId,
      contractId,
      signName,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   {
  //     accountId,
  //     contractId,
  //     signName,
  //   },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Get
export const apiGetAllContracts = (
  accountId: number | null,
  brokerAccountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllContracts}${accountId}`;
  return request(
    url,
    'POST',
    { brokerAccountId },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { brokerAccountId },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Get by Id
export const apiGetContractById = (
  idContract: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getSingleContract}${idContract}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};
