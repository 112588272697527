import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

// Update
export const apiUpdateNotificationType = (
  notificationId: number | null,
  body: any,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  }
) => {
  const url = `${constants.BASE_URL}${api.updateNotification}${notificationId}`;
  return request(url, 'PUT', body, token, {
    Authorization: `Bearer ${token.accessToken}`,
  });
};

export const apiGetAllNotifications = (
  accountId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  }
) => {
  const url = `${constants.BASE_URL}${api.getAllNotifications}${accountId}`;
  return request(url, 'GET', null, token, {
    Authorization: `Bearer ${token.accessToken}`,
  });
};
