import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: '28px',
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    overflowX: 'hidden',
    margin: '20px 0 0',
    [theme.breakpoints.up('sm')]: {
      margin: '33px 0 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '40px 152px 0',
    },
  },
  logo: {
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 40,
    },
  },
}));

export default useStyles;
