import React, { FC } from 'react';
import cx from 'classnames';
import { Paper, Hidden } from '@material-ui/core';
import useStyles from './PaperContainer.style';
import PaperBrokerClient from '../PaperBrokerClient/PaperBrokerClient';

const PaperContainer: FC = ({ children }) => {
  // const [clientName, setClientName] = useState<string>('');
  const classes = useStyles();

  // useEffect(() => {
  //   const lastName = localStorage.getItem('clientLastName');
  //   const firstName = localStorage.getItem('clientFirstName');
  //   // const name = ` ${lastName} ${firstName}`;
  //   // if (lastName && firstName) setClientName(name);
  // }, []);

  return (
    <>
      <Hidden smUp>
        <PaperBrokerClient />
      </Hidden>
      <Paper className={cx(classes.paper)}>
        {children}
      </Paper>
    </>
  );
};
export default PaperContainer;
