import React, { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { useCookies } from 'react-cookie';
import {
  getAllExistedInventory,
} from '../../store/slices/inventory.slice';
import { RootState } from '../../store/slices';
import { Inventory } from '../../interfaces/inventory.interface';
import { getDateTime } from '../../utils/getDate';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import useStyles from './Inventory.style';
import ButtonSC from '../../components/ButtonSC/ButtonSC';

const ListInventoryForAdmin: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['rowListInventoryForAdmin']);
  const [currentInventoryList, setListOfInventory] = useState<Inventory[]>([]);

  const handleGetStatus = (data: Inventory) => {
    if (data.approved && data.active) return 'ACTIVE';
    if (data.approved && !data.active) return 'CANCELLED';
    if (!data.approved && data.sendToAdminApprovingDate && data.inventoryItemStatus !== 'REJECTED') return `AWAITING CONFIRMATION FROM ${getDateTime(data.sendToAdminApprovingDate)}`;
    if (!data.approved && data.inventoryItemStatus === 'REJECTED' && data.requestForApprovement) return `REJECTED Reason: ${data.requestForApprovement.reasonForDecline}`;
    return 'INACTIVE';
  };

  const columns: Array<Column<Inventory>> = [
    {
      title: 'Open',
      field: '',
      render: (rowData) => (
        <ButtonSC
          variant="fill"
          text="Open"
          size="large"
          color="green"
          handleClick={() => history.push(
            'itemOfInventory',
            {
              inventoryId: rowData.id,
            },
          )}
        />
      ),
    },
    {
      title: 'Status',
      field: 'active',
      render: handleGetStatus,
    },
    {
      title: 'Inventory Status',
      field: 'inventoryItemStatus',
    },
    {
      title: 'Attest',
      field: 'attest',
      type: 'boolean',
    },
    {
      title: 'Type',
      field: 'inventoryItemType.type',
    },
    {
      title: 'Created At',
      field: 'createdAt',
      render: (rowData) => getDateTime((rowData.createdAt)),
    },
    {
      title: 'Title',
      field: 'title',
    },
    {
      title: 'Quantity',
      field: 'quantity',
      render: (rowData) => `${Number.isInteger(rowData.quantity) ? rowData.quantity : rowData.quantity.toFixed(2)} ${rowData.inventoryItemType.units}`,
      customFilterAndSearch: (term, rowData) => (`${Number.isInteger(rowData.quantity) ? rowData.quantity : rowData.quantity.toFixed(2)} ${rowData.inventoryItemType.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Available',
      field: 'quantityRemained',
      render: (rowData) => `${Number.isInteger(rowData.quantityRemained) ? rowData.quantityRemained : rowData.quantityRemained.toFixed(2)} ${rowData.inventoryItemType.units}`,
      customFilterAndSearch: (term, rowData) => (`${Number.isInteger(rowData.quantityRemained) ? rowData.quantityRemained : rowData.quantityRemained.toFixed(2)} ${rowData.inventoryItemType.units}`.toLowerCase()).indexOf(term.toLowerCase()) !== -1,
    },
    {
      title: 'Broker',
      field: 'brokerUserName',
    },
  ];

  const {
    listOfInventory,
  } = useSelector(
    (state: RootState) => state.inventory,
  );

  useEffect(() => {
    const {
      clientAccountId,
      accountId,
      userId,
      token: tokenUser,
    } = getLocalStorageData();
    if (userId) {
      const key = new Date().getTime() + Math.random();
      dispatch(getAllExistedInventory(clientAccountId || accountId, tokenUser, key));
    }
  }, [dispatch]);

  useEffect(() => {
    setListOfInventory(listOfInventory.map((item) => ({ ...item })));
  }, [setListOfInventory, listOfInventory]);

  return (
    <TableContainer>
      <MaterialTable
        title=""
        columns={columns}
        data={currentInventoryList}
        options={{
          searchFieldStyle: {
            borderBottom: 'none',
            paddingLeft: 0,
          },
          pageSize: +cookies.rowListInventoryForAdmin || 5,
        }}
        onChangeRowsPerPage={(pageSize: number) => setCookie('rowListInventoryForAdmin', pageSize, { path: '/' })}
        components={{
          Toolbar: (props) => (
            <MTableToolbar
              {...props}
              classes={{ actions: classes.actions, spacer: classes.spacer, root: classes.root }}
            />
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <p className="tableNoRecords">
                No records to display
              </p>),
          },
          header: {
            actions: 'Edit',
          },
        }}
      />
    </TableContainer>
  );
};

export default ListInventoryForAdmin;
