import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    // height: '100%',
    minHeight: '100%',
    boxShadow: 'none',
    borderRadius: 15,
    padding: '24px 16px 60px',
    [theme.breakpoints.up('sm')]: {
      padding: '60px 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '60px 72px',
    },
  },
  paperClient: {
    height: 'calc(100% - 69px)',
  },
  paperBrokerRole: {
    marginBottom: 16,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 15,
    boxShadow: 'none',
  },
  roleString: {
    fontSize: 18,
    lineHeight: '21px',
    color: theme.palette.secondary.main,
  },
  nameRole: {
    fontSize: 18,
    lineHeight: '21px',
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    padding: '0 5px',
  },
}));

export default useStyles;
