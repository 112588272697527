import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const MyOrders = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 12 16">
    <path d="M10.5 2H8C8 0.896875 7.10312 0 6 0C4.89688 0 4 0.896875 4 2H1.5C0.671875 2 0 2.67188 0 3.5V14.5C0 15.3281 0.671875 16 1.5 16H10.5C11.3281 16 12 15.3281 12 14.5V3.5C12 2.67188 11.3281 2 10.5 2ZM3 13.25C2.58437 13.25 2.25 12.9156 2.25 12.5C2.25 12.0844 2.58437 11.75 3 11.75C3.41563 11.75 3.75 12.0844 3.75 12.5C3.75 12.9156 3.41563 13.25 3 13.25ZM3 10.25C2.58437 10.25 2.25 9.91563 2.25 9.5C2.25 9.08437 2.58437 8.75 3 8.75C3.41563 8.75 3.75 9.08437 3.75 9.5C3.75 9.91563 3.41563 10.25 3 10.25ZM3 7.25C2.58437 7.25 2.25 6.91563 2.25 6.5C2.25 6.08437 2.58437 5.75 3 5.75C3.41563 5.75 3.75 6.08437 3.75 6.5C3.75 6.91563 3.41563 7.25 3 7.25ZM6 1.25C6.41563 1.25 6.75 1.58437 6.75 2C6.75 2.41563 6.41563 2.75 6 2.75C5.58437 2.75 5.25 2.41563 5.25 2C5.25 1.58437 5.58437 1.25 6 1.25ZM10 12.75C10 12.8875 9.8875 13 9.75 13H5.25C5.1125 13 5 12.8875 5 12.75V12.25C5 12.1125 5.1125 12 5.25 12H9.75C9.8875 12 10 12.1125 10 12.25V12.75ZM10 9.75C10 9.8875 9.8875 10 9.75 10H5.25C5.1125 10 5 9.8875 5 9.75V9.25C5 9.1125 5.1125 9 5.25 9H9.75C9.8875 9 10 9.1125 10 9.25V9.75ZM10 6.75C10 6.8875 9.8875 7 9.75 7H5.25C5.1125 7 5 6.8875 5 6.75V6.25C5 6.1125 5.1125 6 5.25 6H9.75C9.8875 6 10 6.1125 10 6.25V6.75Z" />
  </SvgIcon>
);

export default MyOrders;
