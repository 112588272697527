import React, { FC } from 'react';
import { MuiThemeProvider, Hidden } from '@material-ui/core';
import theme from './style';
import PaperBrokerClient from '../../components/PaperBrokerClient/PaperBrokerClient';

const TableContainer: FC = ({ children }) => (
  <>
    <Hidden smUp>
      <PaperBrokerClient />
    </Hidden>
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  </>
);

export default TableContainer;
