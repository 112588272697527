import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DocUpload = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 16">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.21901 0.269588C9.08777 0.16656 8.89551 0.260055 8.89551 0.426903V3.79936C8.89551 3.90981 8.98505 3.99936 9.09551 3.99936H13.3914C13.5813 3.99936 13.6643 3.75935 13.5149 3.64204L9.21901 0.269588Z" fill="#075955" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.14867 5.06496C7.98181 5.06664 7.84565 4.93185 7.84565 4.76498V0.3C7.84565 0.134314 7.71133 0 7.54565 0H1.99922C1.83353 0 1.69922 0.134315 1.69922 0.3V12.634C1.69922 12.7147 1.73174 12.792 1.78943 12.8484L1.93686 12.9926C2.05338 13.1066 2.23961 13.1067 2.35624 12.9928L4.12713 11.2636C4.24359 11.1498 4.42948 11.1497 4.54607 11.2633L6.2795 12.952C6.40029 13.0697 6.40039 13.2637 6.27974 13.3815L4.15375 15.4573C3.96127 15.6452 4.09432 15.972 4.36333 15.972H13.7003C13.866 15.972 14.0003 15.8377 14.0003 15.672V5.30898C14.0003 5.14212 13.8641 5.00732 13.6973 5.009L8.14867 5.06496Z" fill="#075955" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.47666 12.6108C4.39895 12.535 4.27502 12.5351 4.19737 12.6109L2.21579 14.545C2.17693 14.5829 2.11491 14.5829 2.07607 14.5449L0.850557 13.3479C0.772871 13.2721 0.648828 13.272 0.571114 13.3479L0.146641 13.7622C0.066235 13.8407 0.0662349 13.97 0.14664 14.0484L2.00622 15.8634C2.08391 15.9393 2.20792 15.9393 2.28561 15.8634L4.90108 13.3107C4.98153 13.2321 4.98148 13.1028 4.90098 13.0243L4.47666 12.6108Z" fill="#075955" />
  </SvgIcon>
);

export default DocUpload;
