/* eslint-disable arrow-body-style */
import React, {
  FC,
} from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import 'react-phone-input-2/lib/material.css';
import ButtonSC from '../../ButtonSC/ButtonSC';
import useStyles from '../ConfirmationDialog.style';

interface Props {
  open: boolean;
  disableBackdropClick?: boolean | undefined;
  labelledby?: string | undefined;
  color: 'green' | 'red';
  onAcceptButtonClicked: () => void;
  onCancelButtonClicked: () => void;
}

const ConfirmationWithoutSaving: FC<Props> = ({
  open, color, onAcceptButtonClicked, onCancelButtonClicked,
}) => {
  const classes = useStyles();

  return (

    <Dialog
      open={open}
      classes={{ paper: classes.leaveMessage }}
    >
      <DialogTitle
        id="alert-dialog-title"
      >
        Close without saving?
      </DialogTitle>
      <DialogContent>
        <p>
          You have unsaved changes.
          Are you sure you want to leave this page without saving?
        </p>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" className={classes.MuDialog}>
          <Grid item>
            <ButtonSC
              variant="outline"
              text="PROCEED"
              size="large"
              color={color}
              handleClick={() => {
                onAcceptButtonClicked();
              }}
            />
          </Grid>
          <Grid item>
            <ButtonSC
              variant="fill"
              text="CANCEL"
              size="large"
              color={color}
              handleClick={() => onCancelButtonClicked()}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>

  );
};

export default ConfirmationWithoutSaving;
