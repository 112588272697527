import React, { PureComponent, useEffect, useState } from 'react';
import {
  Route,
  Router,
  Switch,
} from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import history from './core/history';
import PublicRoute from './containers/PublicRoute/PublicRoute';
import SignUpPage from './containers/Signup/SignUpPage';
import SignInPage from './containers/Signin/SignInPage';
import MainContainer from './containers/MainContainer/MainContainer';
import PasswordResetEmail from './containers/PasswordReset/PasswordResetEmail';
import PasswordReset from './containers/PasswordReset/PasswordReset';
import ConfirmPage from './containers/Signup/ConfirmPage';
import ConfirmNewPasswordPage from './containers/Signup/ConfirmNewPasswordPage';
import PublicMarketPlace from './containers/Public/PublicMarketPlace';

class App extends PureComponent {
  render() {
    return (
      <Router history={history}>
        <CssBaseline />
        <MainContainer>
          <Switch>
            <Route exact path="/signup" component={SignUpPage} />
            <Route exact path="/signin" component={SignInPage} />
            <Route exact path="/changePassword" component={PasswordResetEmail} />
            <Route exact path="/new_password/:token+" component={PasswordReset} />
            <Route path="/confirm/:token+" component={ConfirmPage} />
            <Route path="/confirm_new_password/:token?" component={ConfirmNewPasswordPage} />

            <Route path="/" component={PublicRoute} />
          </Switch>
        </MainContainer>
      </Router>
    );
  }
}

export default App;
