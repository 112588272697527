import React, {
  FC,
  useState,
  useEffect,
  useCallback,
} from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { Location } from 'history';
import {
  Link, useHistory, Prompt, useLocation,
} from 'react-router-dom';
import {
  Tabs,
  Tab,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import PersonalInfo from './PersonalInfo';
import CompanyInfo from './CompanyInfo';
import Documents from './Documents';
import SecurityInfo from './SecurityInfo';
import { updateUserProfile, getUserProfile } from '../../store/slices/userUpdate.slice';
import { RootState } from '../../store/slices';
import NoRoleWarning from '../NoRoleWarning/NoRoleWarning';
import { getRole, getBrokerRole } from '../../utils/getRole';
import { addNotification } from '../../store/slices/snackbar.slice';
import { getDateTime } from '../../utils/getDate';
import useStyles from './ProfilePage.style';
import getLocalStorageData from '../../api/localStorage';
import ButtonSC from '../ButtonSC/ButtonSC';
import PaperContainer from '../PaperContainer/PaperContainer';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import ConfirmationWithoutSaving from '../ConfirmationDialog/ConfirmationWithoutSaving/ConfirmationwithoutSaving';
import translationEN from '../../locales/en/translation.json';

const ProfileForm: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location: any = useLocation();
  const {
    control,
    handleSubmit,
    errors,
    watch,
    setValue,
    formState,
    clearErrors,
    reset,
  } = useForm();

  const [tabValue, setTabValue] = useState(0);
  const [currentUserDocuments, setCurrentUserDocuments] = useState<any>({});
  const [userDocuments, setUserDocuments] = useState<any>({});
  const [changes, setChanges] = useState(false);
  const [openSaveChangesModal, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const {
    user,
  } = useSelector(
    (state: RootState) => state.user,
  );

  useEffect(() => {
    if (user && Object.keys(userDocuments).length !== 0) {
      const diffDocs = JSON.stringify(currentUserDocuments) !== JSON.stringify(userDocuments);
      setChanges(formState.isDirty || diffDocs);
    }
  }, [currentUserDocuments, userDocuments, user, formState]);

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && changes && (location.pathname !== '/profile')) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    if (changes) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [confirmedNavigation, lastLocation, history, changes]);

  const {
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    const { clientAccountId, userId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    if (userId) {
      dispatch(getUserProfile(clientAccountId || userId, token, key));
    }
  }, [dispatch]);

  useEffect(() => {
    const currentDocs = {
      driverLicenseFrontMediaLink: user.driverLicenseFrontMediaLink,
      driverLicenseBackMediaLink: user.driverLicenseBackMediaLink,
      stateIdDocumentMediaLink: user.stateIdDocumentMediaLink,
      passportMediaLink: user.passportMediaLink,
      W9MediaLink: user.W9MediaLink,
      LLC1DocMediaLink: user.LLC1DocMediaLink,
      LLC2DocMediaLink: user.LLC2DocMediaLink,
      profilePhotoLink: user.profilePhotoLink,
      incorporationCertificateMediaLink: user.incorporationCertificateMediaLink,
      incorporationArticlesMediaLink: user.incorporationArticlesMediaLink,
      tickerSymbolMediaLink: user.tickerSymbolMediaLink,
    };
    setCurrentUserDocuments(currentDocs);
    setUserDocuments(currentDocs);
  }, [user]);

  const handleClearProfileDocs = useCallback(() => {
    setUserDocuments({
      ...userDocuments,
      driverLicenseFrontMediaLink: null,
      driverLicenseBackMediaLink: null,
      stateIdDocumentMediaLink: null,
      passportMediaLink: null,
      driverLicenseFront: null,
      driverLicenseBack: null,
      stateIdDocument: null,
      passport: null,
    });
  }, [setUserDocuments, userDocuments]);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      const fieldName = Object.keys(errors)[0].replace(/([a-z0-9])([A-Z])/g, '$1 $2');
      let error = '';
      if (Object.keys(errors)[0] === 'recieveEmailMessages' || Object.keys(errors)[0] === 'recieveSMSMessages') {
        error = `${errors[Object.keys(errors)[0]].message}`;
      } else {
        error = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}: ${errors[Object.keys(errors)[0]].message}`;
      }
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: error,
        options: { variant: 'error' },
      }));
    }
  }, [errors, dispatch]);

  const handleSetState = useCallback((
    field: string,
    value: string | Date | undefined | Array<File> | File | {
      link: string;
      title: string;
    }[] | null,
  ) => {
    const newUserDocuments = { ...userDocuments, [field]: value };
    setUserDocuments(newUserDocuments);
  }, [userDocuments, setUserDocuments]);

  const handleSaveChanges = useCallback(async (values: any) => {
    const { clientAccountId, userId, token } = getLocalStorageData();
    const formData = new FormData();
    if (values.governmentIdDateOfIssue &&
      new Date(values.governmentIdDateOfIssue) >
      (values.governmentIdDateOfExpiration ?
        new Date(values.governmentIdDateOfExpiration) : new Date())) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: translationEN.DateOfIssueIsInvalidPleaseReEnter,
        options: { variant: 'error' },
      }));
      return;
    }
    if (values.governmentIdDateOfExpiration &&
      new Date(values.governmentIdDateOfExpiration) < new Date()) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: translationEN.DateOfExpirationIsInvalidPleaseReEnter,
        options: { variant: 'error' },
      }));
      return;
    }

    const userInfo = JSON.stringify({
      ...userDocuments,
      ...values,
      email: values.email === user.email ? '' : values.email,
      mobileNumber: values.mobileNumber === user.mobileNumber ? '' : values.mobileNumber,
      dateOfBirth: values.dateOfBirth === user.dateOfBirth ? '' : values.dateOfBirth,
      mainNumber: values.mainNumber === '1' || values.mainNumber === '52' ? '' : values.mainNumber,
      organizationOfficePhone: values.organizationOfficePhone === '1' || values.organizationOfficePhone === '52' ? '' : values.organizationOfficePhone,
      organizationOfficeFax: values.organizationOfficeFax === '1' || values.organizationOfficeFax === '52' ? '' : values.organizationOfficeFax,
    });

    formData.append('user', userInfo);
    if (userDocuments.avatar) formData.append('avatar', userDocuments.avatar);
    if (userDocuments.driverLicenseFront) formData.append('driverLicenseFront', userDocuments.driverLicenseFront[0]);
    if (userDocuments.driverLicenseBack) formData.append('driverLicenseBack', userDocuments.driverLicenseBack[0]);
    if (userDocuments.stateIdDocument) formData.append('stateIdDocument', userDocuments.stateIdDocument[0]);
    if (userDocuments.passport) formData.append('passport', userDocuments.passport[0]);
    if (userDocuments.W9) formData.append('W9', userDocuments.W9[0]);
    if (userDocuments.LLC1Doc) formData.append('LLC1Doc', userDocuments.LLC1Doc[0]);
    if (userDocuments.LLC2Doc) formData.append('LLC2Doc', userDocuments.LLC2Doc[0]);
    if (userDocuments.incorporationCertificate) formData.append('incorporationCertificate', userDocuments.incorporationCertificate[0]);
    if (userDocuments.incorporationArticles) formData.append('incorporationArticles', userDocuments.incorporationArticles[0]);
    if (userDocuments.tickerSymbol) formData.append('tickerSymbol', userDocuments.tickerSymbol[0]);

    const key = new Date().getTime() + Math.random();
    await dispatch(updateUserProfile(formData, clientAccountId || userId, token, key));
    reset({
      ...values,
    }, {
      errors: false,
      dirtyFields: false,
      isDirty: false,
      isSubmitted: false,
      touched: false,
      isValid: false,
      submitCount: false,
    });
  }, [dispatch, userDocuments, user, reset]);

  return (
    <PaperContainer>
      <Prompt when={changes} message={handleBlockedNavigation} />
      <Typography variant="h1">
        {getRole(userRole) === 'Broker' ?
          getBrokerRole(userRole, localStorage.getItem('clientAccountId')) :
          getRole(userRole)}
        {getRole(userRole) === 'Broker' ?
          (getBrokerRole(userRole, localStorage.getItem('clientAccountId')) !== 'Broker' && getRole(userRole) !== 'Admin') && (
            <Link color="primary" to="changeRole" className={classes.link}>
              Change role
            </Link>
          )
          :
          (getRole(userRole) !== 'Broker' && getRole(userRole) !== 'Admin') && (
            <Link color="primary" to="changeRole" className={classes.link}>
              Change role
            </Link>
          )}
      </Typography>
      {/* TODO: */}
      <form onSubmit={handleSubmit(handleSaveChanges)} className={classes.fullHeight} autoComplete="off" id="form">
        <Grid container justifyContent="space-between" className={classes.tabs}>
          <Grid item xs={12} sm={8}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}
            >
              <Tab
                label="Personal info"
                classes={{
                  root: classes.rootTab,
                }}
              />
              <Tab
                label="Company info"
                classes={{
                  root: classes.rootTab,
                }}
              />
              <Tab
                label="Corporate Documents"
                classes={{
                  root: classes.rootTab,
                }}
              />
              <Tab
                label="Security"
                classes={{
                  root: classes.rootTab,
                }}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: 'end' }}>
            <ButtonSC
              type="submit"
              variant="fill"
              text="Save changes"
              size="large"
              disabled={!changes}
              styleClass={classes.btnSave}
              color="green"
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid item className={cx(classes.values, classes.fullHeightForm)}>
          {
            user?.id && (
              <>
                <PersonalInfo
                  control={control}
                  errors={errors}
                  userInfo={user}
                  newFiles={userDocuments}
                  currentTab={tabValue}
                  setDocuments={handleSetState}
                  clearDocuments={handleClearProfileDocs}
                  setValue={setValue}
                  watchData={watch()}
                  clearErrors={clearErrors}
                />
                <CompanyInfo
                  control={control}
                  errors={errors}
                  userInfo={user}
                  currentTab={tabValue}
                  watchData={watch()}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />
                <Documents
                  setValue={handleSetState}
                  currentTab={tabValue}
                  newFiles={userDocuments}
                  // setOrganizationListFiles={handleSetOrganizationListFiles}
                  legalStatus={watch().legalStatus}
                />
                <SecurityInfo
                  control={control}
                  errors={errors}
                  currentTab={tabValue}
                  userInfo={userDocuments}
                  newPassword={watch().newPassword}
                />
              </>
            )
          }
        </Grid>
      </form>
      {/* {getRole(userRole) === 'Guest' && !userRole.requestCreatedAt && (
        <NoRoleWarning>
          You have read only access, for full access click here to
          <a href="changeRole">
            {' change your role'}
          </a>
        </NoRoleWarning>
      )} */}
      {userRole.requestCreatedAt && (
        <NoRoleWarning>
          {`Role Change request was submitted on ${getDateTime(userRole.requestCreatedAt)} and is being reviewed by the administrator.`}
        </NoRoleWarning>
      )}
      {openSaveChangesModal && (
        <ConfirmationWithoutSaving
          open={openSaveChangesModal}
          color="green"
          onAcceptButtonClicked={
            () => {
              handleConfirmNavigationClick();
            }
          }
          onCancelButtonClicked={
            () => closeModal()
          }
        />
      )}
    </PaperContainer>
  );
};

export default ProfileForm;
