import React, { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
import {
  Link,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import useStyles from './style';
import { registerRequest, authLogOut } from '../../store/slices/register.slice';
import ButtonSC from '../../components/ButtonSC/ButtonSC';
import CheckboxSC from '../../components/CheckboxSC/CheckboxSC';

type FormData = {
  email: string;
  password: string;
};

interface Props {
  onSubmit: (data: FormData) => void;
}

export const SignInForm: FC<Props> = ({ onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    handleSubmit, errors, control,
  } = useForm();

  useEffect(() => {
    dispatch(registerRequest());
    dispatch(authLogOut());
  }, [dispatch]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} data-testid="form">
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ onChange, value, name }) => (
          <TextField
            value={value}
            name={name}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email"
            autoComplete="email"
            className={classes.margin16}
            error={!!errors.email?.message}
            onChange={onChange}
            onBlur={({ target }) => onChange(target.value.trim())}
          />
        )}
        rules={{
          required: 'Required field',
          pattern: {
            value: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/i,
            message: 'Incorrect value',
          },
        }}
      />
      {errors.email && <Typography variant="caption" color="error">{errors.email.message}</Typography>}
      <Controller
        name="password"
        control={control}
        defaultValue=""
        as={(
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
            className={classes.margin16}
            error={!!errors.password?.message}
          />
        )}
        rules={{
          required: 'Required field',
        }}
      />
      {errors.password && <Typography variant="caption" color="error">{errors.password.message}</Typography>}
      <Grid container className={classes.margin16} justifyContent="space-between">
        <Grid item>
          <CheckboxSC
            onChange={() => { }}
            text="Remember Me"
          />
        </Grid>
        <Grid item className={classes.alignSelfCenter}>
          <Link to="/changePassword" className={classes.link}>
            Forgot Password?
          </Link>
        </Grid>
      </Grid>
      <ButtonSC
        variant="fill"
        text="Submit"
        size="large"
        type="submit"
        styleClass={classes.margin16}
        color="green"
      />
      <div className={cx(classes.margin16, classes.textCenter)}>
        <span>{`${"Don't have an account? "}`}</span>
        <Link to="/signup" className={classes.link}>
          Click Here
        </Link>
      </div>
    </form>
  );
};

export default SignInForm;
