import React, {
  FC,
  useState,
  useCallback,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Link,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch } from 'react-redux';
import useStyles from './Inventory.style';
import ButtonSC from '../ButtonSC/ButtonSC';
import getLocalStorageData from '../../api/localStorage';
import constants from '../../core/constants';
import { getDateUTC12H } from '../../utils/getDate';
import { addNotification } from '../../store/slices/snackbar.slice';
import { apiСheckSignName } from '../../api/inventory';

interface Props {
  open: boolean;
  changeDate: (date: Date | null) => void;
  attestationDate: Date | null;
  close: () => void;
  attest: (signature: number) => void;
}

export const GeneralAttestationModal: FC<Props> = ({
  open,
  changeDate,
  attestationDate,
  close,
  attest,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAttest = useCallback(
    async () => {
      const { clientAccountId, accountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      try {
        setIsLoading(true);
        const signature: any = await dispatch(apiСheckSignName(`${firstName} ${lastName}`, clientAccountId && accountId, accountId || 0, token));
        setIsLoading(false);
        attest(signature);
        setFirstName('');
        setLastName('');
      } catch (error) {
        setIsLoading(false);
        dispatch(addNotification({
          key,
          message: error?.response ? error?.response?.data.error : error?.message,
          options: { variant: 'error' },
        }));
      }
    },
    [dispatch, firstName, lastName, attest],
  );

  return (
    <div style={{marginLeft: 230}} className={classes.bigMurginLeft}>
    <Dialog
      open={open}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //@ts-ignore
      classes={classes.bigMurginLeft}
      // 
      // disableBackdropClck

    >
      <DialogTitle id="alert-dialog-title">
        Attestation
        <IconButton aria-label="close" onClick={() => { close(); }} className={classes.closeModalIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.paddingBottom40,
        }}
      >
        <Typography variant="body1">
          By typing your full name you understand and agree to the following:
        </Typography>
        <Typography variant="body1" className={classes.marginBottom24}>
          <Link href={`${constants.BASE_URL}media/E-sig_disclaimer.pdf`} target="_blank" rel="noreferrer">
            Click here to read the full disclosure
          </Link>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                value={attestationDate}
                onChange={(date) => changeDate(getDateUTC12H(date))}
                maxDate={new Date()}
                label="Date"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="firstName"
              label="First Name"
              id="firstName"
              size="small"
              onChange={({ target }) => setFirstName(target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              name="lastName"
              label="Last Name"
              id="lastName"
              size="small"
              onChange={({ target }) => setLastName(target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonSC
              variant="fill"
              text="I attest"
              size="large"
              color="green"
              disabled={!(firstName && lastName) || !attestationDate || isLoading}
              handleClick={() => handleAttest()}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonSC
              variant="outline"
              text="Cancel"
              size="large"
              color="green"
              handleClick={() => close()}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
    </div>
  );
};

export default GeneralAttestationModal;
