import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  typeSelect: {
    width: '100%',
  },
  paperType: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 15,
    boxShadow: 'none',
    margin: '0 auto',
    padding: '24px',
    [theme.breakpoints.up('sm')]: {
      padding: '56px 64px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '56px auto 0',
    },
  },
  marginBottom24: {
    marginBottom: 24,
  },
  padding5: {
    padding: 5,
  },
  marginLeft40: {
    marginLeft: 40,
    '@media (max-width: 680px)': {
      marginLeft: 0,
    }
  },
  marginBottom10: {
    marginBottom: 10,
  },
  saveBtn: {
    height: 40,
    width: 354,
  },
  leaveMessage: {
    maxWidth: 200,
  },
}));

export default useStyles;
