import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '100%',
    padding: '24px 16px',
    width: 'calc(100% - 32px)',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
      padding: '60px 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '60px 72px',
    },
    margin: 'auto',
    borderRadius: 15,
    boxShadow: 'none',
  },
  title: {
    textAlign: 'center',
  },
  role: {
    borderTop: '1px solid #F0F4F8;',
    borderBottom: '1px solid #F0F4F8;',
    alignItems: 'center',
    padding: '24px 0',
    margin: '24px 0',
    fontSize: 18,
    lineHeight: '21px',
  },
  roleTitle: {
    marginRight: 16,
  },
  roleValue: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  text: {
    marginBottom: 24,
    color: theme.palette.secondary.main,
  },
  button: {
    textAlign: 'end',
    marginTop: 24,
  },
  buttonSize: {
    width: 192,
  },
  input: {
    fontSize: 18,
    fontWeight: 'bold',
    width: 200,
  },
  fullWidth: {
    width: '100%',
  },
  docName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
  },
  inputPhone: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
    cursor: 'text',
    padding: '10px 14px 10px 50px',
    border: '1px solid #c4c4c4',
    outline: 'none',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.primary.light} !important`,
    },
    fontFamily: 'Arimo, Arial',
  },
  phone: {
    '& .react-tel-input::focus-within': {
      '& .special-label': {
        color: theme.palette.primary.light,
      },
    },
    '& .react-tel-input .special-label': {
      fontSize: 11,
      color: '#979BA9',
      padding: '3px 5px',
      left: 7,
      fontFamily: 'Arimo, Arial',
      lineHeight: 1,
    },
  },
  verticalMargin24: {
    margin: '24px 0',
  },
  errorInputPhone: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.error.main} !important`,
    },
  },
  errorPhone: {
    '& .react-tel-input .special-label': {
      color: theme.palette.error.main,
    },
  },
  popper: {
    boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
    borderRadius: 5,
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F4F8',
    },
    '&:hover': {
      backgroundColor: '#F0F4F8',
    },
  },
}));

export default useStyles;
