/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useHistory, Prompt } from "react-router";
import cx from "classnames";
import { Location } from "history";
import React, { FC, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  TextField,
  Typography,
  Slider,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  FormControl,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import useStyles from "./Order.style";
import {
  createBuyerOrder,
  getOrderById,
  updateOrder,
  orderSuccess,
} from "../../store/slices/order.slice";
import { RootState } from "../../store/slices";
import { getInventoryTypes } from "../../store/slices/inventoryTypes.slice";
import { InventoryItemType } from "../../interfaces/inventoryTypes.interface";
import { addNotification } from "../../store/slices/snackbar.slice";
import getLocalStorageData from "../../api/localStorage";
import ButtonSC from "../ButtonSC/ButtonSC";
import { NumberFormatCustom, NumberFormatZip } from "../NumberFormat/NumberFormat";
import { getDateUTC12H } from "../../utils/getDate";
import { Order } from "../../interfaces/order.interface";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import ConfirmationWithoutSaving from "../ConfirmationDialog/ConfirmationWithoutSaving/ConfirmationwithoutSaving";
import StateAutocomplete from "../StateAutocomplete/StateAutocomplete";

export const CreateBuyerOrder: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();

  const orderId = location.state && location.state.orderId;
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    formState,
  } = useForm();

  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [typeInventory, setTypeInventory] =
    useState<InventoryItemType | null>();
  const [fieldsInventory, setFieldsInventory] = useState<any[]>();
  const [openQuantity, setOpenQuantity] = useState(false);
  const [changes, setChanges] = useState(false);
  const [openSaveChangesModal, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const { listOfInventoryTypes } = useSelector(
    (state: RootState) => state.inventoryTypes
  );

  const { order, isLoadingOrders } = useSelector(
    (state: RootState) => state.order
  );

  useEffect(() => {
      dispatch(orderSuccess(null));
  }, [dispatch]);

  const closeModal = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    setChanges(formState.isDirty);
  }, [formState]);

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && changes) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    if (changes) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [confirmedNavigation, lastLocation, history, changes]);

  useEffect(() => {
    const { userId, token } = getLocalStorageData();
    dispatch(getInventoryTypes(userId, new Date().getTime() + Math.random()));
    if (orderId)
      dispatch(
        getOrderById(orderId, token, new Date().getTime() + Math.random())
      );
  }, [dispatch, orderId]);

  const getCurrentValuesForFields = (el: any) => {
    const field = { ...el };
    if (el.typeOfField === "DATE") {
      field.value =
        (el.value && new Date(el.value)) ||
        (el.defaultValue &&
          new Date(
            new Date().setHours(new Date().getHours() + 24 * +el.defaultValue)
          )) ||
        null;
    } else if (field.format === "CHECKBOX") {
      field.value = el.value === "true" || false;
    }
    return field.value;
  };

  const handleGetListOfFields = useCallback((inventoryFields: any) => {
    const listOfFields = JSON.parse(JSON.stringify(inventoryFields));
    const fieldList = listOfFields.map((field: any) => ({
      ...field,
      value: getCurrentValuesForFields(field),
    }));
    return fieldList;
  }, []);

  useEffect(() => {
    if (orderId && order?.inventory) {
      setCurrentOrder(order);
      setTypeInventory(order.inventory.inventoryItemType);
      setFieldsInventory(
        handleGetListOfFields(
          order.inventory.inventoryItemType.inventoryItemTypeFieldsTypes
        )
      );
    }
    return () => {
      setCurrentOrder(null);
      setTypeInventory(null);
      setFieldsInventory([]);
    };
  }, [orderId, order, handleGetListOfFields]);

  const handleSaveChanges = useCallback(
    (values) => {
      const increment = typeInventory ? typeInventory.priceIncrement : 1;
      const quantityIncrement = typeInventory
        ? typeInventory.tradeSizeIncrement
        : 1;
      const minTradeSize = typeInventory ? typeInventory.minTradeSize : 1;
      if (values.quantity && +minTradeSize > +values.quantity) {
        setOpenQuantity(true);
        return false;
      } else if (
        !Number.isInteger(
          +(values.unitPrice * 100).toFixed() / +(increment * 100).toFixed()
        )
      ) {
        dispatch(
          addNotification({
            key: new Date().getTime() + Math.random(),
            message: `Price must be a multiple of ${increment}`,
            options: { variant: "error" },
          })
        );
        return false;
      } else if (
        !Number.isInteger(
          +(+(values.quantity - minTradeSize).toFixed(2) * 100).toFixed(2) /
          (+quantityIncrement * 100)
        )
      ) {
        setOpenQuantity(true);
        return false;
      } else {
        return true;
      }
    },
    [dispatch, typeInventory]
  );

  const relocate = useCallback(() => {
    setChanges(false);
    history.push("orders");
  }, [history]);

  const handleCreateOrder = useCallback(
    async (values) => {
      const { accountId, clientAccountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      const inventories = fieldsInventory?.map((item) => {
        if (item.value === undefined) {
          return {
            ...item,
            value: item.defaultValue,
          };
        }
        return item;
      });

      let newForwardInventory = new Object
      newForwardInventory = { ...values, ...{fields: inventories}}

      if (handleSaveChanges(values)) {
        if(typeInventory?.forwardContract){
          await dispatch(
            createBuyerOrder(
              relocate,
              values.type,
              values.quantity,
              +values.unitPrice,
              clientAccountId || accountId,
              clientAccountId && accountId,
              token,
              key,
              inventories || [],
              values.orderState,
              values.orderZip,
              newForwardInventory,
              true
            ),
          );
        }else await dispatch(
          createBuyerOrder(
            relocate,
            values.type,
            values.quantity,
            +values.unitPrice,
            clientAccountId || accountId,
            clientAccountId && accountId,
            token,
            key,
            inventories || [],
            values.orderState,
            values.orderZip,
          ),
        );
      }
    },
    [dispatch, fieldsInventory, handleSaveChanges, relocate]
  );

  const handleUpdateOrder = useCallback(
    async (values) => {
      const { accountId, clientAccountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      if (handleSaveChanges(values)) {
        await dispatch(
          updateOrder(
            relocate,
            orderId,
            values.quantity,
            +values.unitPrice,
            values.type,
            clientAccountId || accountId,
            clientAccountId && accountId,
            token,
            key,
            fieldsInventory || [],
            //
            values.orderState,
            values.orderZip,
          )
        );
      }
    },
    [dispatch, orderId, fieldsInventory, handleSaveChanges, relocate]
  );

  const handleChangeType = useCallback(
    (value) => {
      const type = listOfInventoryTypes.find((item) => item.type === value);
      setTypeInventory(type);
      setFieldsInventory(
        handleGetListOfFields(type?.inventoryItemTypeFieldsTypes)
      );
      setValue("unitPrice", "");
      setValue("quantity", "");
    },
    [
      setTypeInventory,
      setFieldsInventory,
      handleGetListOfFields,
      listOfInventoryTypes,
      setValue,
    ]
  );

  const handleChangeField = useCallback(
    (id: number, value: any) => {
      if (fieldsInventory) {
        const listOfFields = fieldsInventory.map((a) => ({ ...a }));
        const itemFieldIndex = listOfFields.findIndex(
          (field: any) => field.id === id
        );
        if (listOfFields[itemFieldIndex].typeOfField === "NUMBER") {
          listOfFields[itemFieldIndex].value = value
            .toString()
            .replace(/,/g, ".");
        } else if (listOfFields[itemFieldIndex].typeOfField === "DOCUMENTS") {
          listOfFields[itemFieldIndex].newProveDocuments = value;
        } else {
          listOfFields[itemFieldIndex].value = value;
        }
        setFieldsInventory(listOfFields);
      }
    },
    [fieldsInventory, setFieldsInventory]
  );

  const handleRoundQuantity = useCallback(() => {
    if (typeInventory) {
      if (!getValues().quantity)
        setValue('quantity', typeInventory.minTradeSize);
      else if (getValues().quantity < typeInventory.minTradeSize) {
        setValue('quantity', typeInventory.minTradeSize);
      } else if (
        !Number.isInteger(
          ((getValues().quantity as number) - typeInventory.minTradeSize) /
          typeInventory.tradeSizeIncrement
        )
      ) {
        const value = Math.floor(
          ((getValues().quantity as number) - typeInventory.minTradeSize) /
          typeInventory.tradeSizeIncrement
        );
        setValue(
          'quantity',
          typeInventory.minTradeSize + typeInventory.tradeSizeIncrement * value
        );
      }
    }
    setOpenQuantity(false);
  }, [typeInventory, setValue, getValues]);

  const getField = (item: any) => (
    <Grid item xs={12} key={item.name}>
      {item.typeOfField === 'STRING' && (
        <Controller
          name={item.name}
          control={control}
          defaultValue={(orderId ? item.value : item.defaultValue) || ''}
          render={({ onChange, value, name }) => (
            <TextField
              label={item.name.replace(/\/,/g, '.')}
              size="small"
              variant="outlined"
              fullWidth
              name={name}
              value={value}
              onChange={({ target }) => {
                onChange(target.value);
                handleChangeField(item.id, target.value);
              }}
            />
          )}
        />
      )}
      {item.typeOfField === 'NUMBER' && (
        <>
          <Controller
            name={item.name}
            control={control}
            defaultValue={(orderId ? item.value : item.defaultValue) || ""}
            render={({ onChange, value, name }) => (
              <TextField
                label={`${item.name.replace(/\/,/g, ".")} ${item.format === 'PERCENT' ? '(%)' : ''
                  } ${item.format === 'DOLLAR' ? "($)" : ""}`}
                size="small"
                variant="outlined"
                fullWidth
                name={name}
                value={value}
                onChange={({ target }) => {
                  onChange(target.value);
                  handleChangeField(item.id, target.value);
                }}
                error={!!errors[item.name]?.message}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    name,
                  },
                }}
              />
            )}
            rules={{
              validate: {
                percent: (value: number) => {
                  if (item.format === "PERCENT" && (+value < 0 || +value > 100))
                    return "The percentage must be between 0 and 100";
                  return true;
                },
              },
            }}
          />
          {errors[item.name]?.message && (
            <Typography variant="caption" color="error">
              {errors[item.name]?.message}
            </Typography>
          )}
        </>
      )}
      {item.typeOfField === "BOOLEAN" && item.format === "CHECKBOX" && (
        <Controller
          render={({ onChange, name, value }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={name}
                  checked={value}
                  checkedIcon={
                    <span className={cx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleChangeField(item.id, e.target.checked);
                  }}
                />
              }
              label={item.name.replace(/\/,/g, ".")}
            />
          )}
          name={item.name}
          value="primary"
          control={control}
          defaultValue={
            orderId ? item.value : item.defaultValue === "true" || false
          }
        />
      )}
      {item.typeOfField === "BOOLEAN" && item.format === "DROPDOWN" && (
        <Controller
          name={item.name}
          control={control}
          defaultValue={(orderId ? item.value : item.defaultValue) || ""}
          render={({ onChange, value, name }) => (
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel error={!!errors[item.name]?.message}>
                {item.name.replace(/\/,/g, ".")}
              </InputLabel>
              <Select
                label={item.name.replace(/\/,/g, ".")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                onChange={({ target }) => {
                  onChange(target.value);
                  handleChangeField(item.id, target.value);
                }}
                className={classes.typeSelect}
                value={value}
                name={name}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">YES</MenuItem>
                <MenuItem value="false">NO</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      )}
      {item.typeOfField === "DATE" && (
        <>
          <Controller
            name={item.name}
            control={control}
            defaultValue={item.value}
            render={({ onChange, value, name }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  name={name}
                  inputVariant="outlined"
                  label={item.name.replace(/\/,/g, ".")}
                  format="MM/dd/yyyy"
                  value={value}
                  onChange={(date) => {
                    onChange(getDateUTC12H(date));
                    handleChangeField(item.id, getDateUTC12H(date));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  autoOk
                  fullWidth
                  error={!!errors[item.name]?.message}
                  invalidDateMessage=""
                />
              </MuiPickersUtilsProvider>
            )}
            rules={{
              validate: (value) => {
                // eslint-disable-next-line no-restricted-globals
                if (value && isNaN(value.getTime())) return "Invalid date";
                return true;
              },
            }}
          />
          {errors[item.name]?.message && (
            <Typography variant="caption" color="error">
              {errors[item.name]?.message}
            </Typography>
          )}
        </>
      )}
      {item.typeOfField === "CHOICE" && (
        <Controller
          name={item.name}
          control={control}
          defaultValue={(orderId ? item.value : item.defaultValue) || ""}
          render={({ onChange, value, name }) => (
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel error={!!errors[item.name]?.message}>
                {item.name.replace(/\/,/g, ".")}
              </InputLabel>
              <Select
                label={item.name.replace(/\/,/g, ".")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                onChange={({ target }) => {
                  onChange(target.value);
                  handleChangeField(item.id, target.value);
                }}
                className={classes.typeSelect}
                value={value}
                name={name}
                error={!!errors[item.name]?.message}
              >
                <MenuItem value="" disabled>
                  <em>None</em>
                </MenuItem>
                {item.arrayOfChoices.map((choice: string) => (
                  <MenuItem key={choice} value={choice}>
                    {choice}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      )}
    </Grid>
  );

  return (
    <Paper className={classes.paper}>
      <Prompt when={changes} message={handleBlockedNavigation} />
      {(orderId ? currentOrder : true) && (
        <form
          className={classes.form}
          onSubmit={handleSubmit(
            orderId ? handleUpdateOrder : handleCreateOrder,
          )}
        >
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.titleCreate}>
              {orderId ? "Update" : "Create"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              defaultValue={
                currentOrder?.inventory.inventoryItemType.type || ''
              }
              render={({ onChange, value, name }) => (
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel error={!!errors.type?.message}>Type*</InputLabel>
                  <Select
                    label="Type*"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={({ target }) => {
                      onChange(target.value);
                      handleChangeType(target.value);
                    }}
                    className={classes.typeSelect}
                    value={value}
                    name={name}
                    error={!!errors.type?.message}
                    disabled={!!orderId}
                  >
                    <MenuItem value="" disabled>
                      <em>None</em>
                    </MenuItem>
                    {listOfInventoryTypes.map((item) => (
                      <MenuItem key={item.type} value={item.type}>
                        {item.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              rules={{
                required: "Required field",
              }}
            />
            {errors.type && (
              <Typography variant="caption" color="error">
                {errors.type.message}
              </Typography>
            )}
          </Grid>
          {typeInventory?.instantCheckout && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={typeInventory && typeInventory.instantCheckout}
                    color="primary"
                    checkedIcon={
                      <span className={cx(classes.icon, classes.checkedIcon)} />
                    }
                    icon={<span className={classes.icon} />}
                  />
                }
                label="Instant Checkout"
              />
            </Grid>
          )}
          <Grid container item xs={12} justifyContent="space-between">
            {typeInventory?.maxTradeSize ? (
              <>
                <Grid item xs={12} style={{ margin: 0 }}>
                  <Typography className={classes.label}>
                    {`Amount* ${typeInventory ? typeInventory.units : ""}`}
                  </Typography>
                </Grid>
                {/* <Grid item xs={8} style={{ margin: 0 }}>
                  <Slider
                    name="quantity"
                    aria-labelledby="input-slider"
                    min={typeInventory ? typeInventory.minTradeSize : 1}
                    max={
                      typeInventory ? typeInventory.maxTradeSize || 100 : 100
                    }
                    step={
                      typeInventory
                        ? +(
                          !Number.isInteger(
                            typeInventory.tradeSizeIncrement
                          ) && typeInventory.tradeSizeIncrement.toFixed(2)
                        ) || typeInventory.tradeSizeIncrement
                        : 1
                    }
                    marks={[
                      {
                        value: typeInventory ? typeInventory.minTradeSize : 1,
                        label: typeInventory ? typeInventory.minTradeSize : 1,
                      },
                      {
                        value: typeInventory
                          ? typeInventory.maxTradeSize || 100
                          : 100,
                        label: typeInventory
                          ? typeInventory.maxTradeSize || 100
                          : 100,
                      },
                    ]}
                    onChange={(event, value) => {
                      setValue("quantity", value as number);
                    }}
                    value={+watch().quantity || +(currentOrder?.quantity || 0)}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Controller
                    name="quantity"
                    control={control}
                    defaultValue={currentOrder?.quantity || ""}
                    render={({ onChange, value, name }) => (
                      <TextField
                        name={name}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={value}
                        onChange={({ target }) => {
                          if (
                            typeInventory?.maxTradeSize >=
                            +(target as HTMLInputElement).value
                          ) {
                            onChange(+target.value);
                          } else {
                            onChange(typeInventory?.maxTradeSize);
                          }
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                        }}
                        error={!!errors.quantity?.message}
                      />
                    )}
                    rules={{
                      required: 'Required field',
                      validate: {
                        positive: (value) =>
                          parseFloat(value) > 0 || 'Should be greater than 0',
                      },
                    }}
                  />
                  {errors.quantity && (
                    <Typography variant="caption" color="error">
                      {errors.quantity.message}
                    </Typography>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Controller
                  name="quantity"
                  control={control}
                  defaultValue={currentOrder?.quantity || ""}
                  render={({ onChange, value, name }) => (
                    <TextField
                      label={`Amount* ${typeInventory ? typeInventory.units : ""
                        }`}
                      name={name}
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value);
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      error={!!errors.quantity?.message}
                    />
                  )}
                  rules={{
                    required: "Required field",
                    validate: {
                      positive: (value) =>
                        parseFloat(value) > 0 || "Should be greater than 0",
                    },
                  }}
                />
                {errors.quantity?.message && (
                  <Typography variant="caption" color="error">
                    {errors.quantity?.message}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="unitPrice"
              control={control}
              defaultValue={currentOrder?.unitPrice || null}
              render={({ onChange, value, name }) => (
                <TextField
                  label="Unit Price*"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors.unitPrice?.message}
                  name={name}
                  value={value}
                  onChange={({ target }) => {
                    onChange(+target.value);
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
              )}
              rules={{
                required: "Required field",
                validate: {
                  positive: (value) =>
                    parseFloat(value) > 0 || "Should be greater than 0",
                },
              }}
            />
            {errors.unitPrice && (
              <Typography variant="caption" color="error">
                {errors.unitPrice.message}
              </Typography>
            )}
          </Grid>

          {/* Begin OrderZip */}
          <Grid item xs={12}>
            <Controller
              name="orderZip"
              control={control}
              defaultValue={null}
              render={({ onChange, value, name }) => (
                <TextField
                  label="Order Zip*"
                  size="small"
                  variant="outlined"
                  fullWidth
                  error={!!errors.orderZip?.message}
                  name={name}
                  value={value}
                  onChange={({ target }) => {
                    onChange(target.value.toString());
                  }}
                  InputProps={{
                    inputComponent: NumberFormatZip as any,
                  }}
                />
              )}
              rules={{
                required: "Required field",
                minLength: {
                  value: 5,
                  message: "Incorrect zip: 5 symbols needed",
                },
              }}
            />
            {errors.orderZip && (
              <Typography variant="caption" color="error">
                {errors.orderZip.message}
              </Typography>
            )}
          </Grid>
          {/* End OrderZip */}

          {/* Begin OrderState */}
          <Grid item xs={12}>
            <Controller
              name="orderState"
              control={control}
              // defaultValue={inventoryId ?
              //   currentInventory.locationState :
              //   ''}
              render={({ onChange, value }) => (
                <StateAutocomplete
                  label="State*"
                  defaultValue=""
                  currentState={value || watch().locationState}
                  handleChange={(newValue: any) => {
                    onChange(newValue);
                  }}
                  disabled={false}
                  error={!!errors.locationState?.message}
                />
              )}
              rules={{
                required: 'Required field',
              }}
            />
            {errors.orderState?.message && (
              <Typography variant="caption" color="error">
                {errors.orderState?.message}
              </Typography>
            )}
          </Grid>
          {/* End OrderState */}

          {fieldsInventory &&
            fieldsInventory.length > 0 &&
            fieldsInventory.map((item: any) => getField(item))}
          <Grid item xs={12}>
            <ButtonSC
              variant="fill"
              text={(!isLoadingOrders && (orderId ? 'Update' : 'Create')) || ''}
              size="large"
              type="submit"
              disabled={isLoadingOrders || !changes}
              color="green"
            >
              {isLoadingOrders && (
                <CircularProgress color="primary" size={20} />
              )}
            </ButtonSC>
          </Grid>
        </form>
      )}

      {openQuantity && (
        <ConfirmationDialog
          open={openQuantity}
          labelledby="alert-dialog-title"
          describedby="alert-dialog-description"
          scroll="paper"
          dialogTitleId="alert-dialog-title"
          title="Error"
          disableBackdropClick
          gridSize={4}
          gridSapcing={2}
          description={
            <>
              <p>
                {`The amount entered should be multiple of ${typeInventory?.tradeSizeIncrement} and minimum value should be  ${typeInventory?.minTradeSize}.`}
              </p>
              <p>
                Would you like to change or it will be rounded down nearest
                correct amount?
              </p>
            </>
          }
          acceptButtonText="Delete"
          cancelButtonText="Cancel"
          color="green"
          onAcceptButtonClicked={() => {
            setOpenQuantity(false);
          }}
          onCancelButtonClicked={() => handleRoundQuantity()}
        />
      )}
      {openSaveChangesModal && (
        <ConfirmationWithoutSaving
          open={openSaveChangesModal}
          color="green"
          onAcceptButtonClicked={() => {
            handleConfirmNavigationClick();
          }}
          onCancelButtonClicked={() => closeModal()}
        />
      )}
    </Paper>
  );
};

export default CreateBuyerOrder;
