import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bigMurginLeft: {
    marginLeft: 232,
  },
  picture: {
    height: 114,
    width: 114,
    margin: '0 auto 24px',
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('sm')]: {
      height: 232,
      width: '100%',
      maxWidth: 232,

    },
    [theme.breakpoints.up('lg')]: {
      height: 296,
      width: '100%',
      maxWidth: 296,
    },
  },
  card: {
    // borderRadius: 0,
  },
  cardHeader: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '21px',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.light,
    padding: 8,
    color: theme.palette.text.primary,
  },
  cardContent: {
    padding: 8,
  },
  cardActions: {
    padding: '8px 48px 24px',
  },
  typeSelect: {
    width: '100%',
  },
  form: {
    // display: 'flex',
    width: '100%',
    '& .MuiGrid-item:not(:last-child)': {
      marginBottom: 20,
    },
    '& .MuiGrid-item(:first-child)': {
      marginTop: 40,
    },
  },
  formType: {
    display: 'flex',
    width: '100%',
    // '& .MuiGrid-item:not(:last-child)': {
    //   marginBottom: 20,
    // },
    // '& .MuiGrid-item(:first-child)': {
    //   marginTop: 40,
    // },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 520,
    borderRadius: 15,
    boxShadow: 'none',
    margin: '0 auto',
    padding: '24px',
    [theme.breakpoints.up('sm')]: {
      padding: '56px 64px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '56px auto 0',
    },
  },
  paperType: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // maxWidth: 520,
    borderRadius: 15,
    boxShadow: 'none',
    margin: '0 auto',
    padding: '14px',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 14px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '56px auto 0',
    },
  },
  titleCreate: {
    textAlign: 'center',
  },
  label: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.secondary.dark,
    marginBottom: 4,
    '@media (max-width: 480px)': {
      '&.MuiGrid-item': {
        padding: '8px 0',
        maxwidth: '100%',
        margin: 0,
      }
    }
  },
  paddingRight8: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 8,
    },
  },
  paddingLeft8: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 8,
    },
  },
  choiceAddIcon: {
    padding: '3px 0 0',
    marginLeft: 16,
    color: theme.palette.primary.light,
  },
  choiceInput: {
    width: 'calc(100% - 50px)',
  },
  marginBottom24: {
    marginBottom: 24,
  },
  heightFitContent: {
    height: 91,

    '& .MuiGrid-item': {
      height: 'fit-content',
    },
  },
  fieldsContainer: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    marginBottom: 20,
    '@media (max-width: 480px)': {
      alignContent: 'flex-start',
      padding: 0,
      margin: 0,
      justifyContent: 'center',
      '&.MuiGrid-item': {
        padding: '8px 0',
        maxwidth: '100%',
        margin: 0,
      }
    }
  },
  padding5: {
    padding: 5,
  },
  marginLeft40: {
    marginLeft: 40,
    '@media (max-width: 680px)': {
      marginLeft: 0,
    }
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginRight16: {
    marginRight: 16,
  },
  marginTop5: {
    marginTop: '-5px',
  },
  saveBtn: {
    height: 40,
    width: 354,
  },

  icon: {
    borderRadius: 5,
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.secondary.contrastText}`,
    'input:not([disabled]):hover ~ &': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'" +
        "%3E%3Cpath d='M5.36433 8.81296C5.2867 8.88842 5.16314 8.88842 5.08551 8.81296L1.9181 5.73398C1.72404 5.54535 1.41513 5.54534" +
        ' 1.22107 5.73398L0.36882 6.56244C0.166838 6.75878 0.166838 7.08314 0.36882 7.27948L4.87641 11.6612C5.07046 11.8499 5.37938' +
        ' 11.8499 5.57343 11.6612L15.6312 1.88428C15.8332 1.68794 15.8332 1.36358 15.6312 1.16724L14.7789 0.338782C14.5849 0.150146 ' +
        "14.276 0.150146 14.0819 0.338782L5.36433 8.81296Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
    },
  },
  paddingBottom40: {
    paddingBottom: 40,
  },
  closeModalIcon: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  infoColorAttest: {
    color: theme.palette.primary.light,
    textTransform: 'capitalize',
    textDecoration: 'underline',
  },
  leaveMessage: {
    maxWidth: 200,
  },
  popper: {
    boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
    borderRadius: 5,
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F4F8',
    },
    '&:hover': {
      backgroundColor: '#F0F4F8',
    },
  },
  saveButtonWrapper: {
    display: 'flex',
    marginLeft: '30%',
    marginRight: '30%',
    justifyContent: 'center',
    '@media (max-width: 480px)': {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginBottom: '16px',
      marginLeft: '0',
      marginRight: '0',  
    }
  },
  justefyCenter: {
    justifyContent: 'center',
    marginBottom: 24,
    alignItems: 'center',
  },
  inputWrapper: {
    '@media (max-width: 480px)': {
      '&.MuiGrid-item': {
        padding: '8px 0',
        maxwidth: '100%',
        margin: 0,
      }
    }
  },
}));

export default useStyles;
