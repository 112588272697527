import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paddingSelect0: {
    paddingRight: '0px !important',
  },

  selectMobile: {
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      marginRight: '14px',
    },
  },

  title: {
    textAlign: 'center',
    alignItems: 'center',
  },
  titlePanel: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  paddingBottom16: {
    paddingBottom: 16,
  },
  marginTop32: {
    marginTop: 32,
  },
  underline: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  root: {
    height: '100%',
  },
  container: {
    height: 'calc(100% - 57px)',
    fontSize: 12,
    lineHeight: '14px',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 60px)',
      fontSize: 13,
      lineHeight: '15px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },

  card: {
    '&:first-child': {
      marginTop: 56,
    },
    // maxHeight: 104,
  },
  titleCard: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 5,
    minHeight: 40,
  },
  titleItem: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  cardItems: {
    width: 'calc(100% - 120px)',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'space-around',
  },
  cardItemsSell: {
    width: 'calc(100% - 120px)',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'space-around',
  },
  rootTab: {
    flex: 1,
  },
  ordersContainerRight: {
    height: '100%',
    paddingRight: 8,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 780,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  ordersContainerLeft: {
    height: '100%',
    paddingRight: 8,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 780,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  paper: {
    height: '100%',
    boxShadow: 'none',
    borderRadius: 15,
    padding: '8px 8px 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px 24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '32px 32px',
    },
    overflow: 'hidden',
  },
  scrollOrdersContainer: {
    maxHeight: 'calc(100% - 96px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  itemStyle: {
    border: '1px solid red',
  },
  // sortingwrapper: {
  //   display: 'flex',
  //   justifyContent: 'space-evenly',
  //   alignItems: 'center',
  //   margin: '8px'
  // },
  rowSort: {
    color: '#87BC14',
    cursor: 'pointer',
    marginRight: '3px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  rowSortRotate: {
    color: '#87BC14',
    cursor: 'pointer',
    transform: 'rotate(180deg)',
    marginRight: '3px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
}));

export default useStyles;
