import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const PrivacyPolicy = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path d="M12 2.54545L6 0L0 2.54545V6.36364C0 9.89545 2.56 13.1982 6 14C7.53333 13.6436 8.88667 12.7909 9.92 11.6391L7.84 9.65364C7.19912 10.058 6.43148 10.2383 5.66722 10.1641C4.90295 10.0898 4.1891 9.76562 3.64667 9.24636C3.02225 8.64944 2.67154 7.84037 2.67154 6.99682C2.67154 6.15327 3.02225 5.3442 3.64667 4.74727C4.27202 4.15124 5.11962 3.81647 6.00333 3.81647C6.88705 3.81647 7.73465 4.15124 8.36 4.74727C8.90264 5.26579 9.24138 5.94706 9.3191 6.6762C9.39682 7.40535 9.20878 8.13778 8.78667 8.75L10.72 10.5955C11.5267 9.34818 12 7.87818 12 6.36364V2.54545Z" />
    <path d="M6 8.90998C7.10457 8.90998 8 8.05525 8 7.00089C8 5.94653 7.10457 5.0918 6 5.0918C4.89543 5.0918 4 5.94653 4 7.00089C4 8.05525 4.89543 8.90998 6 8.90998Z" />
  </SvgIcon>
);

export default PrivacyPolicy;
