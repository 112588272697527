import { createSlice } from '@reduxjs/toolkit';
import { UserInfo, User } from '../../interfaces/user.interface';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import history from '../../core/history';
import {
  apiUpdateUserInfo,
  apiSendEmailChangeUserPassword,
  apiChangeUserPassword,
  // apiChangeUserRoleInfo,
  apiGetUserInfo,
} from '../../api/updateUserInfo';
import translationEN from '../../locales/en/translation.json';

const initialState: UserInfo = {
  user: {} as User,
  isLoading: false,
  error: '',
};

const userUpdateSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    updateUserRequest(state) {
      state.isLoading = true;
      state.error = '';
    },
    updateUserSuccess(state, action) {
      state.user = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    updateUserFailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
} = userUpdateSlice.actions;

export const updateUserProfile = (
  user: FormData,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateUserRequest());
    const userInfo: any = await dispatch(apiUpdateUserInfo(user, userId, token));
    dispatch(updateUserSuccess(userInfo.user));
    dispatch(addNotification({
      key,
      message: translationEN.ProfileInformationWasUpdatedSuccessfully,
      options: { variant: 'success' },
    }));
    history.push('./profile');
  } catch (error) {
    console.log('error :>> ', error);
    dispatch(updateUserFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const sendEmailChangeUserPassword = (
  email: string,
  key: number,
): AppThunk => async (dispatch) => {
  try {
    const data = await apiSendEmailChangeUserPassword(email);
    dispatch(addNotification({
      key,
      message: data.status,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response?.data?.error,
      options: { variant: 'error' },
    }));
  }
};

export const changeUserPassword = (
  password: string,
  token: string,
  key: number,
): AppThunk => async (dispatch) => {
  try {
    await apiChangeUserPassword(password, token);
    history.push('/confirm_new_password');
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response?.data?.error,
      options: { variant: 'error' },
    }));
  }
};

// export const changeUserRole = (
//   isBuyer: boolean,
//   isSeller: boolean,
//   userId: number | null,
//   brokerAccountId: number | null,
//   token: {
//     accessToken: string | null;
//     tokenExp: string | null;
//     refreshToken: string | null;
//   },
//   key: number,
// ): AppThunk => async (dispatch) => {
//   try {
//     dispatch(updateUserRequest());
//     const userInfo = await dispatch(apiChangeUserRoleInfo(
//       isBuyer,
//       isSeller,
//       userId,
//       brokerAccountId,
//       token,
//     ));
//     dispatch(updateUserSuccess(userInfo));
//     dispatch(addNotification({
//       key,
//       message: 'Request to change role send to admin',
//       options: { variant: 'success' },
//     }));
//     history.push('./');
//   } catch (error) {
//     dispatch(updateUserFailure(error?.response ? error?.response?.data?.error : error?.message));
//     dispatch(addNotification({
//       key,
//       message: error?.response ? error?.response?.data?.error : error?.message,
//       options: { variant: 'error' },
//     }));
//   }
// };

export const getUserProfile = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateUserRequest());
    const userInfo = await dispatch(apiGetUserInfo(userId, token));
    dispatch(updateUserSuccess(userInfo));
  } catch (error) {
    dispatch(updateUserFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default userUpdateSlice.reducer;
