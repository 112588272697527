import axios from 'axios';
import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

// Registration
export const apiRegister = async (
  // userName: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  mobileNumber: string,
  recieveSMSMessages: string,
  recieveEmailMessages: string,
) => {
  const url = `${constants.BASE_URL}${api.signup}`;
  const { data } = await axios.post(url, {
    // userName,
    firstName,
    lastName,
    email,
    password,
    mobileNumber,
    recieveSMSMessages: recieveSMSMessages || false,
    recieveEmailMessages: recieveEmailMessages || false,
  });
  return data;
};

// Activate Account
export const apiActivateAccount = async (
  activateToken: string,
) => {
  const url = `${constants.BASE_URL}${api.activateAccount}`;
  await axios.post(url, { token: activateToken });
};

// ConfirmNewPassword
export const apiConfirmNewPassword = async (
  activateToken: string,
) => {
  const url = `${constants.BASE_URL}${api.confirmNewPassword}`;
  await axios.post(url, { token: activateToken });
};

// Phone Confirmation
export const apiPhoneConfirmation = async (
  phoneNumber: string,
) => {
  const url = `${constants.BASE_URL}${api.phoneConfirmation}`;
  const { data } = await axios.post(
    url,
    {
      phoneNumber,
    },
  );
  return data;
};

// Phone Verification
export const apiPhoneVerification = async (
  phoneNumber: string,
  code: number | string,
) => {
  const url = `${constants.BASE_URL}${api.verifyPhone}`;
  const { data } = await axios.post(
    url,
    {
      phoneNumber,
      code,
    },
  );
  return data;
};

// Login
export const apiLogin = async (
  email: string,
  password: string,
) => {
  const url = `${constants.BASE_URL}${api.login}`;
  const { data } = await axios.post(url, { email, password });
  return data;
};

// Logout
export const apiLogout = (
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.logout}`;
  return request(
    url,
    'POST',
    { refreshJWTToken: token.refreshToken },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

// Check Login status
export const apiCheckLogin = (
  clientAccountId: number | null,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.checkLogin}${userId}`;
  // const { data } = await axios.post(
  //   url,
  //   { clientAccountId },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  return request(
    url,
    'POST',
    { clientAccountId },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

export const apiUpdateToken = async (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.updateToken}`;
  const { data } = await axios.post(
    url,
    {
      refreshToken: token.refreshToken,
      userId,
    },
    { headers: { Authorization: `Bearer ${token.accessToken}` } },
  );
  return data;
};
