import React, {
  FC,
  useEffect,
} from 'react';
import {
  Typography,
  Link,
  Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRequest } from '../../interfaces/adminRequest.interface';
import { RootState } from '../../store/slices';
import constants from '../../core/constants';
import { getBrokerClientRequestInfo } from '../../store/slices/adminRequest.slice';
import getLocalStorageData from '../../api/localStorage';
import useStyles from './Request.style';

interface Props {
  request: AdminRequest;
}

const BrokerClientRequestDetails: FC<Props> = ({ request }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    brokerRequest,
  } = useSelector(
    (state: RootState) => state.requests,
  );

  useEffect(() => {
    const { userId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();

    dispatch(getBrokerClientRequestInfo(request.request_id, userId, token, key));
  }, [dispatch, request]);

  return (
    <>
      {brokerRequest && (
        <Grid container className={classes.fields}>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              User Name:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {`${brokerRequest.clientLastName} ${brokerRequest.clientFirstName}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              User Email:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {brokerRequest.clientEmail}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Broker Name:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {`${brokerRequest.brokerLastName} ${brokerRequest.brokerFirstName}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Broker Email:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {brokerRequest.brokerEmail}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={brokerRequest.documents.length ? 'primary' : 'secondary'}>
              Documents:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {
              brokerRequest.documents.map((item: { link: string; title: string }) => (
                <div key={item.link}>
                  <Link href={`${constants.AWS_URL}${item.link}`} target="_blank" rel="noreferrer">
                    {item.title}
                  </Link>
                </div>
              ))
            }
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BrokerClientRequestDetails;
