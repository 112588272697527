import { combineReducers } from 'redux';
import auth from './auth.slice';
import register from './register.slice';
import user from './userUpdate.slice';
import snackbar from './snackbar.slice';
import inventory from './inventory.slice';
import inventoryTypes from './inventoryTypes.slice';
import order from './order.slice';
import orderSell from './orderSell.slice';
// import quality from './quality.slice';
import requests from './adminRequest.slice';
import negotiationPoint from './negotiationPoints.slice';
import brokerClients from './brokerClients.slice';
import deals from './deals.slice';
import contracts from './contracts.slice';
import users from './adminRoleAssignment.slice';
import postTradeProcessingTemplates from './tradeProcessingTemplate.slice';
import alertsAndMessages from './alertsAndMessages.slice';
import orderQuestion from './orderQuestion.slice';
import mobileConfirmation from './mobileConfirmation.slice';

import notificationMess from './notifocation.slice';
// Combine all reducers.
const appReducer = combineReducers({
  auth,
  register,
  snackbar,
  user,
  inventory,
  inventoryTypes,
  order,
  orderSell,
  // quality,
  requests,
  negotiationPoint,
  brokerClients,
  deals,
  contracts,
  users,
  postTradeProcessingTemplates,
  alertsAndMessages,
  orderQuestion,
  mobileConfirmation,
  notificationMess,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/authRequest') state = undefined;

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
