import React,
{ useEffect, FC } from 'react';
import { useTable, useGlobalFilter, useSortBy, useAsyncDebounce } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { getInventoryTypes } from '../../store/slices/inventoryTypes.slice';
import { RootState } from '../../store/slices';
import getLocalStorageData from '../../api/localStorage';
import TableContainer from '../../containers/TableContainer/TableContainer';
import useStyles from './InventoryType.style';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@material-ui/core';


const InventoryType: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const listOfInventoryTypes = useSelector(
    (state: RootState) => JSON.parse(JSON.stringify(state.inventoryTypes.listOfInventoryTypes)),
  );

  useEffect(() => {
    if (listOfInventoryTypes?.length === 0) {
      const { userId } = getLocalStorageData();
      const key = new Date().getTime();
      dispatch(getInventoryTypes(userId, key));
    }

  }, []);

  const data = listOfInventoryTypes;

  // Define a default UI for filtering
  //@ts-ignore
  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: {
    preGlobalFilteredRows: any;
    globalFilter: any;
    setGlobalFilter: any;
  }): JSX.Element {
    // const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce((value: any) => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (
      <span className={classes.inputSearchSpan}>
        <SearchIcon />{' '}
        <input
          className={classes.searchInput}
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    )
  }

  //@ts-ignore
  function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      //@ts-ignore
      setGlobalFilter,
      //@ts-ignore
      preGlobalFilteredRows,
    } = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy
    )

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case
    const firstPageRows = rows.slice(0, 20)

    return (
      <Grid className={classes.tableWrapper}>
        {/* @ts-ignore */}
        <Grid className={classes.searchInputWrapper}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            //@ts-ignore
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <button
            className={classes.buttonStyle}
            onClick={() => history.push('createInventoryType')}
          ><AddBoxIcon /></button>

        </Grid>
        <table className={classes.table} {...getTableProps()}>
          {/* <thead className={classes.tableHeader}> */}
          {headerGroups.map((headerGroup: any) => (
            <tr  {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                //@ts-ignore
                <th className={classes.tableHeaderText} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <ArrowDropUpIcon />
                        : <ArrowDropDownIcon />
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {/* </thead> */}
          <tbody {...getTableBodyProps()}>
            {firstPageRows.map(
              (row: any, i: any) => {
                prepareRow(row);
                return (
                  <tr className={classes.tableText} {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
        <br />
      </Grid >
    )
  }

  function AppTable() {
    const columns = React.useMemo(
      () => [
        {
          Header: 'Actions',
          Cell: ({ row }: { row: any }) => {
            return (
              <button className={classes.buttonStyle}
                onClick={() => history.push('editInventoryType', { data: row.original })}><EditIcon /></button>
            )
          }
        },
        {
          Header: 'Active',
          accessor: 'active',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                {row.original.active ? <CheckIcon /> : <DoDisturbIcon />}
              </span>
            )
          }
        },
        {
          Header: 'Instant Checkout',
          accessor: 'instantCheckout',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                {row.original.instantCheckout ? <CheckIcon /> : <RemoveIcon />}
              </span>
            )
          }
        },

        {
          Header: 'Type',
          accessor: 'type',
        },
        {
          Header: 'Units',
          accessor: 'units',
        },
        {
          Header: 'Min Trade Size',
          accessor: 'minTradeSize',
        },
        {
          Header: 'Max Trade Size',
          accessor: 'maxTradeSize',
        },
        {
          Header: 'Trade Size Increment',
          accessor: 'tradeSizeIncrement',
        },
        {
          Header: 'Price Increment',
          accessor: 'priceIncrement',
          Cell: ({ row }: { row: any }) => {
            return (
              <span>
                $ {row.original.priceIncrement}
              </span>
            )
          }
        },
        {
          Header: 'Service Fee Type',
          accessor: 'systemCommission.feeType',
        },
        {
          Header: 'Seller Service Fee',
          accessor: 'systemCommission.feeForSeller',
        },
        {
          Header: 'Buyer service Fee',
          accessor: 'systemCommission.feeForBuyer',
        },
      ],
      []
    )

    return (
      <Table columns={columns} data={data} />
    )
  }

  return (
    <TableContainer>
      <AppTable />
    </TableContainer>
  );
};

export default InventoryType;

