import React,
{
  FC,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
  FormControl,
  IconButton,
  CircularProgress,
  InputLabel,
  Paper,
  Divider,
  FormHelperText,
} from '@material-ui/core';
import { Location } from 'history';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';

import { useLocation, Prompt, useHistory } from 'react-router-dom';
import useStyles from './CreateNegotiationPoint.style';
import { RootState } from '../../store/slices';
import { addNotification } from '../../store/slices/snackbar.slice';
import {
  updateNegotiationPoint,
  getAllNegotiationPoints,
} from '../../store/slices/negotiationPoints.slice';
import { NegotiationPoint } from '../../interfaces/negotiationPoints.interface';
import getLocalStorageData from '../../api/localStorage';
import ButtonSC from '../ButtonSC/ButtonSC';
import { NumberFormatCustom, NumberFormatInteger } from '../NumberFormat/NumberFormat';
import { InventoryItemTypeField } from '../../interfaces/inventoryTypes.interface';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import ConfirmationWithoutSaving from '../ConfirmationDialog/ConfirmationWithoutSaving/ConfirmationwithoutSaving';
import translationEN from '../../locales/en/translation.json';

export const CreateNegotiationPoint: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();

  const TypeId = location.state && location.state.id;
  const TypeType = location.state && location.state.type;

  const [choice, setChoice] = useState<{ id: number; value: string }>({ id: 0, value: '' });
  const [negotiationPoints, setNegotiationPoints] = useState<NegotiationPoint[]>([]);
  const [inventoryAttributes, setInventoryAttributes] = useState<InventoryItemTypeField[]>([]);
  const [changes, setChanges] = useState<boolean>(false);
  const [openSaveChangesModal, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [error, setError] = useState(false);

  const {
    listOfNegotiationPoints,
    isLoading,
  } = useSelector(
    (state: RootState) => state.negotiationPoint,
  );

  useEffect(() => {
    if (TypeId) {
      const listOfNP = listOfNegotiationPoints.negotiationPoints
        .map((item) => ({ ...item, uiId: new Date().getTime() + Math.random() * 100 }));
      const listOfIA = listOfNegotiationPoints.inventoryAttributes
        .map((item) => ({ ...item, uiId: new Date().getTime() + Math.random() * 100 }));
      setNegotiationPoints(listOfNP);
      setInventoryAttributes(listOfIA);
    }
  }, [TypeId, listOfNegotiationPoints]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && changes && (location.pathname !== 'profile')) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    if (changes) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [confirmedNavigation, lastLocation, history, changes]);

  // Use Effect
  useEffect(() => {
    const { token } = getLocalStorageData();
    if (TypeId) {
      dispatch(getAllNegotiationPoints(TypeId, token, new Date().getTime() + Math.random()));
    }
  }, [dispatch, TypeId]);

  useEffect(() => {
    const negotiationPointsList = JSON.parse(JSON.stringify(negotiationPoints));
    negotiationPointsList.map((item: NegotiationPoint) => delete item.uiId);
    setChanges(JSON.stringify(listOfNegotiationPoints.negotiationPoints) !== JSON.stringify(negotiationPointsList));
  }, [listOfNegotiationPoints, negotiationPoints]);

  const handleAddChoice = useCallback(
    (id) => {
      const listOfNP = JSON.parse(JSON.stringify(negotiationPoints));
      const itemNPIndex =
        listOfNP.findIndex((np: NegotiationPoint) => np.uiId === id);
      if (listOfNP[itemNPIndex].id === choice.id) {
        const valueChoice = listOfNP[itemNPIndex].negotiationPointsTemplateChoices
          .filter((item: { value: string }) => item.value.toUpperCase() === choice.value.toUpperCase());

        if (valueChoice.length) {
          dispatch(addNotification({
            key: new Date().getTime() + Math.random(),
            message: translationEN.ValueAlreadyExists,
            options: { variant: 'error' },
          }));
        } else {
          const newArrayOfChoices = listOfNP[itemNPIndex].negotiationPointsTemplateChoices.slice();
          newArrayOfChoices.push({ value: choice.value });
          listOfNP[itemNPIndex].negotiationPointsTemplateChoices = newArrayOfChoices;
          setNegotiationPoints(listOfNP);
          setChoice({ id: 0, value: '' });
        }
      }
    },
    [dispatch, choice, negotiationPoints, setNegotiationPoints],
  );

  const handleDeleteChoice = useCallback(
    (id, value) => {
      const listOfNP = JSON.parse(JSON.stringify(negotiationPoints));
      const itemNPIndex =
        listOfNP.findIndex((np: NegotiationPoint) => np.uiId === id);
      const newArrayOfChoices = listOfNP[itemNPIndex].negotiationPointsTemplateChoices
        .filter((obj: { value: string }) => obj.value !== value.value);
      listOfNP[itemNPIndex].negotiationPointsTemplateChoices = newArrayOfChoices;
      setNegotiationPoints(listOfNP);
    },
    [negotiationPoints],
  );

  const handleAddNP = useCallback(
    () => {
      const listOfNP = JSON.parse(JSON.stringify(negotiationPoints));
      listOfNP.push({
        uiId: new Date().getTime() + Math.random(),
        orderNumber: listOfNP.length + 1,

        active: false,
        forOrders: false,
        inventoryItemType: '',
        title: '',
        description: '',
        valueType: '',
        negotiationPointsTemplateChoices: [],
        defaultValue: undefined,
      });
      setNegotiationPoints(listOfNP);
    },
    [negotiationPoints, setNegotiationPoints],
  );

  const handleDelNP = useCallback(
    (id: number) => {
      setNegotiationPoints(negotiationPoints.filter((item) => item.uiId !== id));
    },
    [setNegotiationPoints, negotiationPoints],
  );

  const handleUpdateNPs = useCallback(
    () => {
      setError(false);
      const { token } = getLocalStorageData();
      const errorsCount: string[] = [];
      const key = new Date().getTime() + Math.random() * 1000;

      negotiationPoints.map((item) => {
        if (item.title !== 'Unit price' &&
          item.title !== 'Quantity' &&
          (!item.orderNumber || !item.title || !item.valueType)) {
          setError(true);
          errorsCount.push('Required fields');
        } else if (negotiationPoints
          .some((np: NegotiationPoint) => np.uiId !== item.uiId && np.title === item.title)) {
          setError(true);
          errorsCount.push('Duplicate title');
        } else if (negotiationPoints.some(
          (np: NegotiationPoint) => np.uiId !== item.uiId && +np.orderNumber === +item.orderNumber,
        )) {
          setError(true);
          errorsCount.push('Duplicate order number');
        } else if (item.valueType === 'CHOICE' && item.negotiationPointsTemplateChoices.length < 2) {
          setError(true);
          errorsCount.push('Choice type must have at least 2 value');
        } else if (item.valueType === 'CHOICE' && item.defaultValue && !item.negotiationPointsTemplateChoices
          .find((ch) => ch.value.toUpperCase() === (item.defaultValue as string).toUpperCase())) {
          setError(true);
          errorsCount.push('Default value should be one of specified values');
        } else if ((item.valueType === 'DATE' || item.valueType === 'NUMBER') &&
          +item.defaultValue < 0) {
          setError(true);
          errorsCount.push('Number or number of days should be positive or 0');
        } else if ((item.valueType === 'DATE') && +item.defaultValue > 100) {
          setError(true);
          errorsCount.push('Number of days must be less or equal 100');
        }
        return null;
      });
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= negotiationPoints.length; i++) {
        if (negotiationPoints.find((np) => +np.orderNumber === +i) === undefined) {
          setError(true);
          errorsCount.push(`Missed order number ${i}`);
        }
      }
      if (errorsCount.length) {
        return dispatch(addNotification({
          key,
          message: errorsCount[0],
          options: { variant: 'error' },
        }));
      }
      negotiationPoints.forEach((item) => {
        if (item.valueType === 'CHOICE') {
          item.defaultValue = item.negotiationPointsTemplateChoices
            .find((ch) => (item.defaultValue ? ch.value.toUpperCase() === (item.defaultValue as string).toUpperCase() : false))?.value || '';
        }
      });
      return dispatch(updateNegotiationPoint(
        TypeId,
        negotiationPoints,
        token,
        key,
      ));
    },
    [dispatch, negotiationPoints, TypeId],
  );

  const handleChangeNP = useCallback(
    (id: number, field: string, value: string | boolean | unknown) => {
      const listOfNP = JSON.parse(JSON.stringify(negotiationPoints));
      const itemNPIndex =
        listOfNP.findIndex((np: any) => np.uiId === id);
      listOfNP[itemNPIndex][field] = value;
      setNegotiationPoints(listOfNP);
    },
    [setNegotiationPoints, negotiationPoints],
  );

  const getNP = (item: NegotiationPoint) => (
    <Grid container spacing={2} className={classes.padding5} key={item.uiId}>
      <Grid item xs={4} sm={2} md={1}>
        <TextField
          size="small"
          margin="normal"
          variant="outlined"
          fullWidth
          value={item.orderNumber || ''}
          onChange={({ target }) => handleChangeNP(item.uiId, 'orderNumber', target.value)}
          error={error && (
            !item.orderNumber ||
            negotiationPoints.some((field: NegotiationPoint) => field.id !== item.id &&
              +field.orderNumber === +item.orderNumber) ||
            +item.orderNumber < 1
          )}
          helperText={error && (
            (!item.orderNumber && 'Required') ||
            (negotiationPoints.some((orderNumber: NegotiationPoint) => orderNumber.uiId !== item.uiId && +orderNumber.orderNumber === +item.orderNumber) && 'Duplicate') ||
            (item.orderNumber < 1 && 'Positive value required')
          )}
          label="No.*"
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
        />
      </Grid>
      <Grid container item xs={12} sm={10} md={4} spacing={1} alignContent="flex-start">
        <Grid item xs={5} sm={4} md={4}>
          <FormControl margin="normal" className={classes.checkbox}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={item.active}
                  checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={({ target }) => handleChangeNP(item.uiId, 'active', target.checked)}
                  disabled={item.title === 'Unit price' || item.title === 'Quantity'}
                />
              )}
              label="Status"
            />
          </FormControl>
        </Grid>
        <Grid item xs={7} sm={6} md={6}>
          <FormControl margin="normal" className={classes.checkbox}>
            <FormControlLabel
              control={(
                <Checkbox
                  checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  checked={item.forOrders}
                  onChange={({ target }) => handleChangeNP(item.uiId, 'forOrders', target.checked)}
                  disabled={item.title === 'Unit price' || item.title === 'Quantity'}
                />
              )}
              label="Involved in the deal"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12} md={6} spacing={1} alignContent="flex-start">
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            fullWidth
            label="Title*"
            value={item.title}
            onChange={({ target }) => handleChangeNP(item.uiId, 'title', target.value)}
            disabled={item.title === 'Unit price' || item.title === 'Quantity'}
            error={error && (
              !item.title ||
              negotiationPoints.some((field: NegotiationPoint) => field.id !== item.id &&
                field.title === item.title)
            )}
            helperText={error && (
              (!item.title && 'Required') ||
              (negotiationPoints.some((np: NegotiationPoint) => np.uiId !== item.uiId && np.title === item.title) && 'Duplicate')
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            fullWidth
            label="Description"
            value={item.description}
            onChange={({ target }) => handleChangeNP(item.uiId, 'description', target.value)}
            disabled={item.title === 'Unit price' || item.title === 'Quantity'}
            error={item.title !== 'Unit price' && item.title !== 'Quantity' && error && !item.description}
            helperText={item.title !== 'Unit price' && item.title !== 'Quantity' && error && !item.description && 'Required'}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12} md={11} spacing={1} alignContent="flex-start">
        <Grid item xs={12} sm={5} md={4} spacing={1}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            error={(error && !item.valueType) ||
              (error && item.valueType === 'CHOICE' && item.negotiationPointsTemplateChoices.length < 2)}
          >
            <InputLabel
              error={(error && !item.valueType) ||
                (error && item.valueType === 'CHOICE' && item.negotiationPointsTemplateChoices.length < 2)}
            >
              Value type*
            </InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              onChange={({ target }) => handleChangeNP(item.uiId, 'valueType', target.value)}
              value={item.valueType}
              label="Value type"
              disabled={item.title === 'Unit price' || item.title === 'Quantity'}
              error={(error && !item.valueType) ||
                (error && item.valueType === 'CHOICE' && item.negotiationPointsTemplateChoices.length < 2)}
            >
              <MenuItem value="" disabled>
                <em>None</em>
              </MenuItem>
              <MenuItem value="CHOICE">Choice</MenuItem>
              <MenuItem value="NUMBER">Number</MenuItem>
              <MenuItem value="STRING">String</MenuItem>
              <MenuItem value="BOOLEAN">Boolean</MenuItem>
              <MenuItem value="DATE">Date</MenuItem>
            </Select>
            {error && !item.valueType &&
              <FormHelperText>Required</FormHelperText>}
            {error && item.valueType === 'CHOICE' && item.negotiationPointsTemplateChoices.length < 2 &&
              <FormHelperText>Choice type must have at least 2 value</FormHelperText>}
          </FormControl>
        </Grid>
        {item.valueType === 'CHOICE' && (
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <TextField
                value={item.id === choice.id ? choice.value : ''}
                variant="outlined"
                size="small"
                onChange={({ target }) => setChoice({ id: item.id, value: target.value })}
                className={classes.choiceInput}
                label="Add choices"
                margin="normal"
              />
              <FormControl margin="normal">
                <IconButton
                  onClick={() => handleAddChoice(item.uiId)}
                  disabled={choice.value === '' || item.id !== choice.id}
                  className={classes.choiceAddIcon}
                >
                  <AddBoxIcon fontSize="large" />
                </IconButton>
              </FormControl>
            </div>
          </Grid>
        )}
        {item.valueType === 'CHOICE' && item.negotiationPointsTemplateChoices.map((ch: { value: string }, index: number) => (
          <Grid item xs={12} sm={4} md={12} key={`${index}_${ch.value}`}>
            <Chip key={`${index}_${ch.value}`} label={ch.value} onDelete={() => handleDeleteChoice(item.uiId, ch)} color="primary" />
          </Grid>
        ))}
        {item.valueType === 'NUMBER' && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl margin="normal" className={classes.checkbox}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    checked={item.affectTheTotalPrice}
                    onChange={({ target }) => handleChangeNP(item.uiId, 'affectTheTotalPrice', target.checked)}
                    disabled={item.title === 'Unit price' || item.title === 'Quantity'}
                  />
                )}
                label="Affect The Total Price"
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={4}>
          {(item.valueType === 'NUMBER') && (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={item.defaultValue || ''}
              onChange={({ target }) => handleChangeNP(item.uiId, 'defaultValue', target.value)}
              margin="normal"
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
              label="Default"
              disabled={item.title === 'Unit price' || item.title === 'Quantity'}
              error={error && item.defaultValue !== '' && item.defaultValue < 0}
              helperText={error && item.defaultValue !== '' && item.defaultValue < 0 &&
                'Number should be positive or 0'}
            />
          )}
          {(item.valueType === 'DATE') && (
            <TextField
              size="small"
              margin="normal"
              variant="outlined"
              fullWidth
              value={item.defaultValue || ''}
              onChange={({ target }) => handleChangeNP(item.uiId, 'defaultValue', target.value)}
              label="Default number of days to add"
              InputProps={{
                inputComponent: NumberFormatInteger as any,
              }}
              error={error && item.defaultValue !== '' && (item.defaultValue < 0 || item.defaultValue > 100)}
              helperText={error && item.defaultValue !== '' && ((item.defaultValue < 0 &&
                'Number of days must be positive or 0') || (item.defaultValue > 100 &&
                  'Number of days must be less or equal 100'))}
            />
          )}
          {(item.valueType === 'STRING' || item.valueType === 'CHOICE' || item.valueType === '') && (
            <TextField
              size="small"
              margin="normal"
              variant="outlined"
              fullWidth
              value={item.defaultValue || ''}
              onChange={({ target }) => handleChangeNP(item.uiId, 'defaultValue', target.value)}
              label="Default"
              error={
                error && item.valueType === 'CHOICE' && !!item.defaultValue &&
                !(item.negotiationPointsTemplateChoices.find(
                  (ch: { value: string }) => ch.value.toUpperCase() === (item.defaultValue as string).toUpperCase(),
                ))
              }
              helperText={error && item.valueType === 'CHOICE' && item.defaultValue &&
                !item.negotiationPointsTemplateChoices.find(
                  (ch: { value: string }) => ch.value.toUpperCase() === (item.defaultValue as string).toUpperCase(),
                ) &&
                'Default value should be one of specified values'}
            />
          )}
          {item.valueType === 'BOOLEAN' && (
            <FormControl variant="outlined" size="small" fullWidth margin="normal">
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    checked={item.defaultValue === 'true' || item.defaultValue === true}
                    onChange={({ target }) => handleChangeNP(item.uiId, 'defaultValue', target.checked)}
                  />
                )}
                label="Default"
              />
            </FormControl>
          )}
        </Grid>
      </Grid>
      {item.title !== 'Unit price' && item.title !== 'Quantity' && (
        <Grid item xs={1} sm={1} md={1}>
          <FormControl margin="normal">
            <IconButton aria-label="add" onClick={() => handleDelNP(item.uiId)} style={{ padding: '0 12px' }}>
              <DeleteIcon fontSize="large" />
            </IconButton>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );

  const getIA = (item: InventoryItemTypeField) => (
    <Grid xs={12} sm={12} md={12} container spacing={2} className={classes.padding5} key={item.uiId}>
      <Grid item xs={3} sm={2} md={1} spacing={2}>
        <TextField
          size="small"
          margin="normal"
          variant="outlined"
          fullWidth
          value={item.order || ''}
          label="No.*"
          disabled
        />
      </Grid>
      <Grid container item xs={9} sm={10} md={6} spacing={2} alignContent="flex-start">
        <Grid item xs={12} sm={10} md={12}>
          <TextField
            size="small"
            margin="normal"
            variant="outlined"
            fullWidth
            label="Title*"
            value={item.name}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );

  return (
    <Paper className={classes.paperType}>
      <Prompt when={changes} message={handleBlockedNavigation} />
      <Grid container spacing={4}>
        {inventoryAttributes.length > 0 && (
          <Grid container item xs={12} alignContent="flex-start">
            <Grid container alignItems="center" className={classes.marginBottom24}>
              <Grid item>
                <Typography variant="h2">
                  {`${TypeType} Inventory Attributes`}
                </Typography>
              </Grid>
            </Grid>
            {inventoryAttributes.slice().sort((a, b) => +a.order - +b.order).map((item) => (
              getIA(item)
            ))}
          </Grid>
        )}
        <Grid container item xs={12} alignContent="flex-start">
          <Grid container alignItems="center" className={classes.marginBottom24}>
            <Grid item>
              <Typography variant="h2">
                {`${TypeType} Negotiation Points`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="add" onClick={handleAddNP} className={cx(classes.padding5, classes.marginLeft40)}>
                <AddBoxIcon fontSize="large" style={{ color: '#87BC14' }} />
              </IconButton>
            </Grid>
          </Grid>
          {negotiationPoints.slice().sort((a, b) => +a.orderNumber - +b.orderNumber).map((item) => (
            getNP(item)
          ))}
        </Grid>
        <Grid container item justifyContent="flex-end">
          <ButtonSC
            variant="fill"
            text={(!isLoading && 'Save changes') || ''}
            size="middle"
            handleClick={() => handleUpdateNPs()}
            disabled={isLoading || !changes}
            color="green"
            styleClass={classes.saveBtn}
          >
            {isLoading && <CircularProgress color="primary" size={20} />}
          </ButtonSC>
        </Grid>
      </Grid>
      {openSaveChangesModal && (
        <ConfirmationWithoutSaving
          open={openSaveChangesModal}
          color="green"
          onAcceptButtonClicked={
            () => {
              handleConfirmNavigationClick();
            }
          }
          onCancelButtonClicked={
            () => closeModal()
          }
        />
      )}
    </Paper>
  );
};

export default CreateNegotiationPoint;
