import React, { FC, useCallback } from 'react';
import { Redirect } from 'react-router';
import {
  Typography,
  Paper,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { signUp } from '../../store/slices/register.slice';
import { RootState } from '../../store/slices';
import useStyles from './style';
import SignUpForm from './SignUpForm';

type FormData = {
  // userName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreePrivatePolicy: boolean;
  mobileNumber: string;
};

export const SignUpPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSignUp = useCallback(
    ({
      // userName,
      firstName,
      lastName,
      email,
      password,
      mobileNumber,
      recieveSMSMessages,
      recieveEmailMessages,
    }) => {
      const key = new Date().getTime() + Math.random();
      dispatch(signUp(
        // userName,
        firstName,
        lastName,
        email,
        password,
        mobileNumber,
        recieveSMSMessages,
        recieveEmailMessages,
        key,
      ));
    },
    [dispatch],
  );

  const {
    isLoading,
    isRegisterSuccess,
    registerError,
  } = useSelector(
    (state: RootState) => state.register,
  );

  if (!registerError && !isLoading && isRegisterSuccess) {
    return <Redirect to="/signin" />;
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h1" className={classes.title}>
          Sign Up
        </Typography>
        <SignUpForm
          onSubmit={handleSignUp}
          isLoading={isLoading}
        />
      </Paper>
    </div>
  );
};

export default SignUpPage;
