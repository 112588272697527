import React, {
  FC,
  useEffect,
} from 'react';
import {
  Typography,
  Link,
  Grid,
  Avatar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PageviewIcon from '@material-ui/icons/Pageview';
import { AdminRequest } from '../../interfaces/adminRequest.interface';
import { RootState } from '../../store/slices';
import constants from '../../core/constants';
import { getDate } from '../../utils/getDate';
import { getInventoryById } from '../../store/slices/inventory.slice';
import getLocalStorageData from '../../api/localStorage';
import useStyles from './Request.style';

interface Props {
  request: AdminRequest;
}

const InventoryRequestDetails: FC<Props> = ({ request }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    inventory,
  } = useSelector(
    (state: RootState) => state.inventory,
  );

  useEffect(() => {
    const { token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();

    dispatch(getInventoryById(request.inventoryItem_id, token, key));
  }, [dispatch, request]);

  const getField = (item: any) => (
    <Grid container key={item.name}>
      <Grid item xs={6}>
        <Typography variant="body1" color="primary">
          {`${(item.name).replace(/\/,/g, '.')}:`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">
          {item.typeOfField === 'DATE' && item.value && getDate(item.value)}
          {item.typeOfField === 'NUMBER' && item.format === 'PERCENT' && `${item.value} %`}
          {item.typeOfField === 'NUMBER' && item.format === 'DOLLAR' && `$ ${item.value}`}
          {item.typeOfField === 'DOCUMENTS' && (
            item.proveDocuments.map((doc: { link: string; title: string }) => (
              <div key={doc.link}>
                <Link href={`${constants.AWS_URL}${doc.link}`} target="_blank" rel="noreferrer">
                  {doc.title}
                </Link>
              </div>
            ))
          )}
          {item.typeOfField !== 'DATE' && item.format !== 'PERCENT' && item.format !== 'DOLLAR' && item.typeOfField !== 'DOCUMENTS' && item.value}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      {inventory && (
        <Grid container className={classes.fields}>
          <Grid item xs={6}>
            <Typography variant="body1" color={inventory.instantCheckout ? 'primary' : 'secondary'}>
              Instant Checkout:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {inventory.instantCheckout ? 'true' : 'false'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Created:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {inventory.createdAt && getDate(inventory.createdAt as string)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Type:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {inventory.inventoryItemType.type}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={inventory.title ? 'primary' : 'secondary'}>
              Title:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {inventory.title}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={inventory.description ? 'primary' : 'secondary'}>
              Description:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {inventory.description}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={inventory.shortDescription ? 'primary' : 'secondary'}>
              Short Description:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {inventory.shortDescription}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={inventory.shortDescription ? 'primary' : 'secondary'}>
              Location:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {`${inventory.locationLine1},${inventory.locationCity},${inventory.locationState},${inventory.locationZip},${inventory.locationCountry}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Quantity:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {inventory.quantity}
            </Typography>
          </Grid>
          {inventory.inventoryItemType.inventoryItemTypeFieldsTypes.length > 0 &&
            inventory.inventoryItemType.inventoryItemTypeFieldsTypes.map((item: any) => (
              getField(item)
            ))}
          <Grid item xs={6}>
            <Typography variant="body1" color={inventory.labAttest ? 'primary' : 'secondary'}>
              Lab Analysis:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {inventory?.labAttest && inventory?.labAttestationDocs ? (
              inventory?.labAttestationDocs &&
              inventory?.labAttestationDocs.map((doc: { link: string; title: string }) => (
                <Typography variant="body1">
                  <Link key={doc.link} href={`${constants.AWS_URL}${doc.link}`} target="_blank" rel="noreferrer">
                    Click here to see Test Results
                  </Link>
                </Typography>
              ))
            ) :
              (
                <Typography variant="body1">
                  Not Attested
                </Typography>
              )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={inventory.generalAttest ? 'primary' : 'secondary'}>
              General Attested:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {inventory.generalAttest ? `${getDate(inventory.generalAttestationDate)}` : ''}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color={inventory.productPicture[0] ? 'primary' : 'secondary'}>
              Display Picture
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Avatar variant="rounded" alt="Avatar" className={classes.picture} src={(inventory.productPicture && inventory.productPicture[0] && `${constants.AWS_URL}${inventory.productPicture[0].link}`)}>
              {!(inventory.productPicture.length) && <PageviewIcon fontSize="large" />}
            </Avatar>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InventoryRequestDetails;
