import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const NegotiationPoints = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 18">
    <path d="M0.395884 7.89975C0.395328 7.89975 0.395066 7.90044 0.39548 7.90081L1.80781 9.16575L5.80955 12.6191C6.07615 12.8492 6.47216 12.8454 6.73429 12.6103L8.13999 11.3493C8.17416 11.3187 8.20638 11.2854 8.2295 11.2458C8.28591 11.149 8.31321 11.0391 8.30769 10.928C8.3019 10.8116 8.26037 10.6996 8.18869 10.6054C8.16724 10.5772 8.14084 10.5533 8.11332 10.531L6.39907 9.14085C6.39744 9.13953 6.39775 9.13696 6.39965 9.13607C6.40149 9.13521 6.40185 9.13274 6.40034 9.13138L4.51113 7.43475C4.40383 7.32317 4.34583 7.17736 4.34894 7.02701C4.35206 6.87665 4.41605 6.73307 4.52789 6.6255L9.04448 2.5899C9.35572 2.31181 9.35599 1.82488 9.04507 1.54645L7.78446 0.417547C7.51895 0.179775 7.11717 0.179467 6.8513 0.41683L0.397492 6.1785C0.272046 6.28872 0.172034 6.42199 0.103658 6.57003C0.0352818 6.71808 0 6.87774 0 7.03913C0 7.20051 0.0352818 7.36017 0.103658 7.50822C0.171814 7.65578 0.271404 7.78867 0.396284 7.89869C0.396704 7.89906 0.396443 7.89975 0.395884 7.89975ZM15.6029 10.1013C15.603 10.1015 15.6028 10.1017 15.6027 10.1016L14.191 8.83425L10.1906 5.37952C9.9245 5.14969 9.52917 5.15296 9.26688 5.38717L7.84446 6.65726C7.81559 6.68304 7.78808 6.71065 7.76701 6.74311C7.70324 6.84136 7.67178 6.95564 7.67741 7.07146C7.68397 7.20632 7.74041 7.33481 7.83699 7.43475L9.59496 8.85143C9.59561 8.85196 9.59524 8.853 9.59441 8.853C9.5936 8.853 9.59322 8.854 9.59382 8.85453L11.4829 10.5518C11.5891 10.662 11.646 10.8065 11.6417 10.9551C11.6374 11.1038 11.5722 11.2451 11.4598 11.3498L6.94069 15.3863C6.63004 15.6637 6.62896 16.1495 6.93837 16.4283L8.21578 17.5796C8.48118 17.8188 8.88412 17.8197 9.15063 17.5818L15.6021 11.8215C15.7277 11.7113 15.8278 11.5781 15.8962 11.43C15.9647 11.282 16 11.1223 16 10.9609C16 10.7995 15.9647 10.6398 15.8962 10.4917C15.828 10.3441 15.7282 10.2111 15.6031 10.1011C15.603 10.101 15.6028 10.1012 15.6029 10.1013Z" />
  </SvgIcon>
);

export default NegotiationPoints;
