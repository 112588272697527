import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 114,
    width: 114,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('sm')]: {
      maxHeight: 250,
      height: 'auto',
      maxWidth: 250,
      width: '100%',
    },
  },
  uploadAvatarBtn: {
    background: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    boxShadow: 'none',
    color: theme.palette.primary.dark,
    '&:hover': {
      background: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      boxShadow: 'none',
      color: 'white',
    },
    marginTop: 20,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 250,
      width: '100%',
      fontSize: 13,
      lineHeight: '14px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  btnAddPhoto: {
    maxHeight: '40px',
  },
  show: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '21px',
    marginBottom: 8,
    paddingLeft: 10,
  },
  titleMaxHeight: {
    maxHeight: 76,
  },
  role: {
    marginTop: 20,
  },
  btnSave: {
    marginTop: 16,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      width: 168,
    },
  },
  tabs: {
    margin: '24px 0',
  },
  values: {
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      marginTop: 56,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
    },
  },
  fullHeight: {
    height: '100%',
  },
  fullHeightForm: {
    height: 'calc(100% - 100px)',
  },
  displayBlock: {
    display: 'block',
    '& .MuiGrid-item': {
      padding: '12px 0',
    },
  },
  displayNone: {
    display: 'none',
  },
  body1: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    color: '#979BA9',
  },
  body2: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
    color: '#979BA9',
    opacity: 0.5,
  },
  fileIcon: {
    minWidth: 37,
    maxWidth: 37,
  },
  docName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
  },
  link: {
    paddingLeft: 20,
    color: theme.palette.primary.main,
    fontSize: 18,
    lineHeight: '16px',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
    },
  },
  rightSpace: {
    paddingTop: 10,
    '& .MuiGrid-container:not(:last-child)': {
      paddingRight: 40,
    },
  },
  paddingTop10: {
    paddingTop: 10,
  },
  justify: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  marginBottom24: {
    marginBottom: 24,
  },
  rootTab: {
    flex: 1,
  },
  fullHeightAdapt: {
    maxHeight: '100%',
  },
  inputPhone: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
    cursor: 'text',
    padding: '10px 14px 10px 50px',
    border: '1px solid #c4c4c4',
    outline: 'none',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.primary.light} !important`,
    },
    fontFamily: 'Arimo, Arial',
  },
  errorInputPhone: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&:focus': {
      border: `1px solid ${theme.palette.error.main} !important`,
    },
  },
  errorPhone: {
    '& .react-tel-input .special-label': {
      color: theme.palette.error.main,
    },
  },
  phone: {
    '& .react-tel-input:focus': {
      '& .special-label': {
        color: theme.palette.primary.light,
      },
    },
    '& .react-tel-input .special-label': {
      fontSize: 11,
      color: '#979BA9',
      padding: '3px 5px',
      left: 7,
      fontFamily: 'Arimo, Arial',
      lineHeight: 1,
    },
    '& .react-tel-input:focus .special-label': {
      border: `1px solid ${theme.palette.error.main} !important`,
    },
  },

  leaveMessage: {
    maxWidth: 200,
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  icon: {
    borderRadius: 5,
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.secondary.contrastText}`,
    'input:hover ~ &': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'" +
        "%3E%3Cpath d='M5.36433 8.81296C5.2867 8.88842 5.16314 8.88842 5.08551 8.81296L1.9181 5.73398C1.72404 5.54535 1.41513 5.54534" +
        ' 1.22107 5.73398L0.36882 6.56244C0.166838 6.75878 0.166838 7.08314 0.36882 7.27948L4.87641 11.6612C5.07046 11.8499 5.37938' +
        ' 11.8499 5.57343 11.6612L15.6312 1.88428C15.8332 1.68794 15.8332 1.36358 15.6312 1.16724L14.7789 0.338782C14.5849 0.150146 ' +
        "14.276 0.150146 14.0819 0.338782L5.36433 8.81296Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
    },
  },
  popper: {
    boxShadow: '0px 4px 10px rgba(0, 41, 71, 0.2)',
    borderRadius: 5,
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: '#F0F4F8',
    },
    '&:hover': {
      backgroundColor: '#F0F4F8',
    },
  },
  label: {
    backgroundColor: '#FFF',
    padding: '0 5px',
    '&:hover': {
      color: theme.palette.primary.light,
    },
    '&.Mui-focused': {
      color: theme.palette.primary.light,
    },
  },
}));

export default useStyles;
