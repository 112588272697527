import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import useStyles from './PaperBrokerClient.style';
import { getBrokerRole } from '../../utils/getRole';
import { RootState } from '../../store/slices';

const PaperBrokerClient: FC = () => {
  const [clientName, setClientName] = useState<string>('');
  const classes = useStyles();

  const {
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    const lastName = localStorage.getItem('clientLastName');
    const firstName = localStorage.getItem('clientFirstName');
    const name = ` ${lastName} ${firstName}`;
    if (lastName && firstName) setClientName(name);
  }, [setClientName]);

  return (
    <>
      {clientName && (
        <Paper className={classes.paperBrokerRole}>
          <span className={classes.roleString}> Act as:</span>
          <span className={classes.nameRole}>
            {clientName}
          </span>
          <span className={classes.roleString}>  Role:</span>
          <span className={classes.nameRole}>
            {getBrokerRole(userRole, localStorage.getItem('clientAccountId'))}
          </span>
        </Paper>
      )}
    </>
  );
};
export default PaperBrokerClient;
