import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import { addNotification } from './snackbar.slice';
import { AdminRequestState, AdminRequest } from '../../interfaces/adminRequest.interface';
import {
  apiGetAllRequests,
  apiTakeRequest,
  apiGetAllRequestsByAdminId,
  // apiGetItemRequest,
  apiApproveRoleRequest,
  apiDeclineRoleRequest,
  apiApproveDealRequest,
  apiDeclineDealRequest,
  apiApproveInventoryRequest,
  apiDeclineInventoryRequest,
  apiApproveBrokerClientRequest,
  apiDeclineBrokerClientRequest,
  apiGetBrokerClientRequestInfo,
  apiSendRequestBackToQueue,
  apiApproveChangeEmailRequest,
  apiDeclineChangeEmailRequest,
  apiGetChangeEmailRequest,
} from '../../api/adminRequest';
import translationEN from '../../locales/en/translation.json';

const initialState: AdminRequestState = {
  requestInfo: null,
  listOfRequests: [],
  listOfRequestsByAdminId: [],
  brokerRequest: null,
  openRequest: null,
  reloadRequest: false,
  error: '',
  isLoading: false,
};

const adminRequestsSlice = createSlice({
  initialState,
  name: 'requests',
  reducers: {
    requestRequest(state) {
      state.requestInfo = null;
      state.error = '';
      state.isLoading = true;
    },
    requestSuccess(state, action) {
      state.requestInfo = action.payload;
      const index = state.listOfRequests
        .findIndex((obj: AdminRequest) => obj.request_id === action.payload.request_id);
      if (index !== -1) {
        state.listOfRequests[index] = action.payload;
      } else {
        state.listOfRequests.push(action.payload);
      }
      const indexByAdmin = state.listOfRequestsByAdminId
        .findIndex((obj: AdminRequest) => obj.request_id === action.payload.request_id);
      if (indexByAdmin !== -1 && action.payload.request_status !== 'IN PROGRESS') {
        state.listOfRequestsByAdminId.splice(indexByAdmin, 1);
      } else if (indexByAdmin !== -1) {
        state.listOfRequestsByAdminId[indexByAdmin] = action.payload;
      } else if (indexByAdmin === -1 && action.payload.request_status === 'IN PROGRESS') {
        state.listOfRequestsByAdminId.push(action.payload);
      }
      state.error = '';
      state.isLoading = false;
    },
    requestFailure(state, action) {
      state.requestInfo = null;
      state.error = action.payload;
      state.isLoading = false;
    },
    listOfAdminRequestsRequest(state) {
      state.listOfRequests = [];
      state.error = '';
      state.isLoading = true;
    },
    listOfAdminRequestsSuccess(state, action) {
      state.listOfRequests = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    listOfAdminRequestsFailure(state, action) {
      state.listOfRequests = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    listOfAdminRequestsByAdminIdRequest(state) {
      state.listOfRequestsByAdminId = [];
      state.error = '';
      state.isLoading = true;
    },
    listOfAdminRequestsByAdminIdSuccess(state, action) {
      state.listOfRequestsByAdminId = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    listOfAdminRequestsByAdminIdFailure(state, action) {
      state.listOfRequestsByAdminId = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    brokerRequest(state) {
      state.brokerRequest = null;
      state.error = '';
      state.isLoading = true;
    },
    brokerSuccess(state, action) {
      state.brokerRequest = action.payload;
      state.error = '';
      state.isLoading = false;
    },
    brokerFailure(state, action) {
      state.brokerRequest = null;
      state.error = action.payload;
      state.isLoading = false;
    },
    setOpenRequest(state, action) {
      state.openRequest = action.payload;
    },
    setReloadRequest(state, action) {
      state.reloadRequest = action.payload;
    },
  },
});

export const {
  requestRequest,
  requestSuccess,
  requestFailure,
  brokerRequest,
  brokerSuccess,
  brokerFailure,
  listOfAdminRequestsRequest,
  listOfAdminRequestsSuccess,
  listOfAdminRequestsFailure,
  listOfAdminRequestsByAdminIdRequest,
  listOfAdminRequestsByAdminIdSuccess,
  listOfAdminRequestsByAdminIdFailure,
  setOpenRequest,
  setReloadRequest,
} = adminRequestsSlice.actions;

export const getAllRequests = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
): AppThunk => async (dispatch) => {
  try {
    dispatch(listOfAdminRequestsRequest());
    const requests = await dispatch(apiGetAllRequests(userId, token));
    dispatch(listOfAdminRequestsSuccess(requests));
  } catch (error) {
    dispatch(listOfAdminRequestsFailure(
      error?.response ? error?.response?.data?.error : error?.message,
    ));
  }
};

export const getAllRequestsByAdminId = (
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
): AppThunk => async (dispatch) => {
  try {
    dispatch(listOfAdminRequestsByAdminIdRequest());
    const requests = await dispatch(apiGetAllRequestsByAdminId(userId, token));
    dispatch(listOfAdminRequestsByAdminIdSuccess(requests));
  } catch (error) {
    dispatch(listOfAdminRequestsByAdminIdFailure(
      error?.response ? error?.response?.data?.error : error?.message,
    ));
  }
};

export const takeRequests = (
  idRequest: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    await dispatch(apiTakeRequest(idRequest, userId, token));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const approveRoleRequests = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request = await dispatch(apiApproveRoleRequest(idRequest, comment, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.YourRoleWasChangedSuccessfully,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const declineRoleRequests = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request =
      await dispatch(apiDeclineRoleRequest(idRequest, reasonForDecline, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.YourRoleWasChangedUnSuccessful,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const approveInventoryRequests = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request = await dispatch(apiApproveInventoryRequest(idRequest, comment, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.InventoryCreatedSuccessfully,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const declineInventoryRequests = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request =
      await dispatch(apiDeclineInventoryRequest(idRequest, reasonForDecline, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.InventoryCreationUnsuccessful,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const approveDealRequests = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request = await dispatch(apiApproveDealRequest(idRequest, comment, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.SmartDealRequestApproved,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const declineDealRequests = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request =
      await dispatch(apiDeclineDealRequest(idRequest, reasonForDecline, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.SmartDealRequestDenied,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const approveBrokerClientRequests = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request =
      await dispatch(apiApproveBrokerClientRequest(idRequest, comment, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.BrokerRequestConfirmed,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const declineBrokerClientRequests = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request =
      await dispatch(apiDeclineBrokerClientRequest(idRequest, reasonForDecline, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.BrokerRequestDenied,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getBrokerClientRequestInfo = (
  idRequest: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(brokerRequest());
    const requests = await dispatch(apiGetBrokerClientRequestInfo(idRequest, userId, token));
    dispatch(brokerSuccess(requests));
  } catch (error) {
    dispatch(brokerFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const getChangeEmailRequest = (
  idRequest: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const requests = await dispatch(apiGetChangeEmailRequest(idRequest, userId, token));
    dispatch(requestSuccess(requests));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const approveChangeEmailRequests = (
  idRequest: number,
  comment: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request = await dispatch(apiApproveChangeEmailRequest(idRequest, comment, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.EmailChangeWasSuccessfully,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const declineChangeEmailRequests = (
  idRequest: number,
  reasonForDecline: string,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(requestRequest());
    const request =
      await dispatch(apiDeclineChangeEmailRequest(idRequest, reasonForDecline, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.EmailChangedUnsuccessful,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(requestFailure(error?.response ? error?.response?.data?.error : error?.message));
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export const sendRequestBackToQueue = (
  idRequest: number,
  userId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  key: number,
): AppThunk => async (dispatch) => {
  try {
    const request = await dispatch(apiSendRequestBackToQueue(idRequest, userId, token));
    dispatch(requestSuccess(request));
    dispatch(addNotification({
      key,
      message: translationEN.SuccessReturnToQueue,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(addNotification({
      key,
      message: error?.response ? error?.response?.data?.error : error?.message,
      options: { variant: 'error' },
    }));
  }
};

export default adminRequestsSlice.reducer;
