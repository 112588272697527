/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useEffect, useState } from 'react';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import { Grid, Typography, Paper, CardContent } from '@material-ui/core';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './NotificationPage.style';
import getLocalStorageData from '../../api/localStorage';
import { getRole } from '../../utils/getRole';
import { RootState } from '../../store/slices';
import { goToDeal } from '../../store/slices/deals.slice';
import { apiUpdateNotificationType } from '../../api/notification';
import { readNotificationsMessage } from '../../store/slices/notifocation.slice';

const NotificationPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userRole } = useSelector((state: RootState) => state.auth);

  const { notificationsMessage, loading } = useSelector(
    (state: RootState) => state.notificationMess
  );
  // const noMessages =
  const readNotification = (notificationId: number, read: boolean) => {
    if (!read) {
      const { token } = getLocalStorageData();
      dispatch(
        apiUpdateNotificationType(notificationId, { read: true }, token)
      );
      dispatch(readNotificationsMessage({ notificationId }));
    }
  };

  const handleOpenDealRoom = useCallback(
    async (dealId: number, dealRoomId: string, notificationId: number) => {
      const { clientAccountId, accountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      dispatch(
        apiUpdateNotificationType(notificationId, { read: true }, token)
      );
      if (getRole(userRole) !== 'Admin') {
        await dispatch(
          goToDeal(
            dealRoomId,
            dealId,
            clientAccountId ? accountId : null,
            accountId,
            token,
            key,
          )
        );
      }
    },
    [dispatch, userRole]
  );

  const dateFormateUpdate = (date: any) => {
    const getDate = new Date(date);
    return getDate.toString().substring(0, 24)
  };


  return (
    <Paper className={classes.paper}>
      <Grid>
        {notificationsMessage &&
          notificationsMessage.map((item) => (
          <Link
            key={item.id}
            to={{
              pathname: `${item.link}`,
              state: {
                dealId: item.deal?.id,
                dealRoomId: item.deal?.dealRoomId,
              },
            }}
            onClick={() =>
              handleOpenDealRoom(
                item.deal?.id,
                item.deal?.dealRoomId,
                item.id,
              )
            }
            className={classes.link_style}
          >
            <Card
              className={
                item.read ? classes.card_wrapper_read : classes.card_wrapper
              }
              onMouseOver={() => readNotification(item.id, item.read)}
            >
              <CardContent className={classes.card_content_wrapper}>
                <Typography className={classes.text_wrapper_header}>
                  {item.template.template}
                </Typography>
                <Typography className={classes.text_wrapper} component="div">
                  {item.text}
                </Typography>
                <Typography className={classes.text_wrapper_date}>
                  {dateFormateUpdate(item.createdAt)}
                </Typography>
              </CardContent>
            </Card>
          </Link>
          ))}

          {!notificationsMessage.length && (
            <Card className={classes.card_wrapper}>
              <CardContent className={classes.card_content_wrapper}>
                <Typography variant="h5" gutterBottom>
                  You don't have new notifications
                </Typography>
              </CardContent>
            </Card>
          )}
          {loading && (
            <Grid className={classes.wait_spinner}>
              <ChangeCircleIcon className={classes.spinner_rotate_animation} />
            </Grid>
          )}
      </Grid>
    </Paper>
  );
};

export default memo(NotificationPage);
