import constants from '../core/constants';
import api from '../core/api';
import { NegotiationPoint } from '../interfaces/negotiationPoints.interface';
import request from './apiRequest';

// Get
export const apiGetAllNegotiationPointTemplates = (
  inventoryId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllNegotiationPointTemplates}${inventoryId}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

// Update
export const apiUpdateNegotiationPointTemplate = (
  inventoryId: number,
  negotiationPoints: NegotiationPoint[],
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.updateNegotiationPointTemplate}${inventoryId}`;
  return request(
    url,
    'POST',
    negotiationPoints,
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};
