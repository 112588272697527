import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Drawer,
  Divider,
  List,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Logo from '../../svg/logo.svg';
import { CommonListItems } from './dashboardListItems';

interface Props {
  menuItems: JSX.Element;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    paddingTop: 24,
    paddingLeft: 32,
    height: 80,
    color: '#FEFEFE',
  },
  drawer: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.palette.secondary.dark,
    justifyContent: 'space-between',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  divider: {
    border: '1px solid #3A3C45',
  },
  drawerItemsListContainer: {
    width: '100%',
  },
  commonMenuItems: {
    '&:last-child': {
      marginBottom: 32,
    },
  },
  menuItems: {
    marginTop: 25,
  },
}));

const DashboardDrawer: FC<Props> = ({ menuItems }) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerOpen,
      }}
    >
      <Grid item className={classes.drawerItemsListContainer}>
        <Link to="/">
          <div className={classes.toolbarIcon}>
            <img src={Logo} alt="MGT logo" />
          </div>
        </Link>
        <Divider className={classes.divider} />
        <List className={classes.menuItems}>
          {menuItems}
        </List>
      </Grid>
      <Grid item className={classes.drawerItemsListContainer}>
        <List className={classes.commonMenuItems}>
          <CommonListItems open />
        </List>
      </Grid>
    </Drawer>
  );
};

export default DashboardDrawer;
