import { makeStyles } from '@material-ui/core';

interface Props {
  color: 'red' | 'green' | 'lightGreen';
}

const useStyles = makeStyles((theme) => ({
  fill: {
    background: (props: Props) => (
      props.color === 'green'
        ? theme.palette.primary.main
        : theme.palette.error.main ||
      props.color === 'lightGreen'
        ? '#87BC14'
        : theme.palette.error.main),
    borderRadius: '5px',
    boxShadow: 'none',
    color: (props: Props) => (
      props.color === 'lightGreen'
        ? 'black'
        : 'white'),
    '&:hover': {
      background: (props: Props) => (
        props.color === 'lightGreen'
          ? 'rgb(182 231 75)'
          : theme.palette.primary.light),
      boxShadow: 'none',
    }, 

  },
  outline: {
    background: 'white',
    border: (props: Props) => (
      props.color === 'green'
        ? `1px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.error.main}`),
    borderRadius: '5px',
    boxShadow: 'none',
    color: (props: Props) => (
      props.color === 'green'
        ? theme.palette.primary.dark
        : theme.palette.error.main),
    '&:hover': {
      background: (props: Props) => (
        props.color === 'green'
          ? theme.palette.primary.light
          : theme.palette.error.main),
      border: (props: Props) => (
        props.color === 'green'
          ? `1px solid ${theme.palette.primary.light}`
          : `1px solid ${theme.palette.error.main}`),
      boxShadow: 'none',
      color: 'white',
    },
    '&:disabled': {
      border: 'none',
    },
  },
  small: {
    height: 40,
    width: 120,
  },
  middle: {
    height: 40,
    width: '75%',
    '@media (max-width: 1024px)': {
      width: '85%',
    },
     '@media (max-width: 780px)': {
      width: '100%',
    },
  },
  large: {
    height: 40,
    width: '100%',
  },
  smallButton: {
    width: '60px',
  },
  textCapitalize: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 13,
      lineHeight: '14px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  textHide: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: 0,
  },
  MuDialog: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '15px',
  },

}));

export default useStyles;
