import { createSlice } from '@reduxjs/toolkit';
import { apiRegister } from '../../api/auth';
import { AppThunk } from '../index';
import { RegisterState } from '../../interfaces/auth.interface';
import { addNotification } from './snackbar.slice';

const initialState: RegisterState = {
  isLoading: false,
  isRegisterSuccess: false,
  registerError: null,
};

const registerSlice = createSlice({
  initialState,
  name: 'register',
  reducers: {
    registerRequest(state) {
      state.isLoading = true;
      state.isRegisterSuccess = false;
      state.registerError = null;
    },
    registerSuccess(state) {
      state.isLoading = false;
      state.isRegisterSuccess = true;
      state.registerError = null;
    },
    registerFailure(state, action) {
      state.isLoading = false;
      state.isRegisterSuccess = false;
      state.registerError = action.payload;
    },
    authLogOut(state) {
      state.isLoading = false;
      state.isRegisterSuccess = false;
      state.registerError = null;
    },
  },
});

export const {
  registerRequest, registerSuccess, registerFailure, authLogOut,
} = registerSlice.actions;

export const signUp = (
  // userName: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  mobileNumber: string,
  recieveSMSMessages: string,
  recieveEmailMessages: string,
  key: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(registerRequest());
    const data = await apiRegister(firstName, lastName, email, password, mobileNumber, recieveSMSMessages, recieveEmailMessages);
    dispatch(registerSuccess());
    dispatch(addNotification({
      key,
      message: data.status,
      options: { variant: 'success' },
    }));
  } catch (error) {
    dispatch(registerFailure(error?.response?.data?.error));
    dispatch(addNotification({
      key,
      message: error?.response?.data?.error,
      options: { variant: 'error' },
    }));
  }
};
export default registerSlice.reducer;
