import React, {
  FC,
  useEffect,
} from 'react';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRequest } from '../../interfaces/adminRequest.interface';
import { RootState } from '../../store/slices';
import { getChangeEmailRequest } from '../../store/slices/adminRequest.slice';
import getLocalStorageData from '../../api/localStorage';
import useStyles from './Request.style';

interface Props {
  request: AdminRequest;
}

const ChangeUserEmailDetails: FC<Props> = ({ request }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    requestInfo,
  } = useSelector(
    (state: RootState) => state.requests,
  );

  useEffect(() => {
    const { userId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();

    dispatch(getChangeEmailRequest(request.request_id, userId, token, key));
  }, [dispatch, request]);

  return (
    <>
      {requestInfo && (
        <Grid container className={classes.fields}>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Old User Email:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {requestInfo.users_email}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              New User Email:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {requestInfo.request_newUserEmail}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Request created by:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {requestInfo.adminUser_email}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ChangeUserEmailDetails;
