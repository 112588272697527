import constants from "../core/constants";
import api from "../core/api";
import request from "./apiRequest";
import { InventoryItemTypeField } from "../interfaces/inventoryTypes.interface";

// Create
export const apiCreateSellerOrder = (
  inventoryId: number,
  quantity: number,
  unitPrice: number,
  accountId: number | null,
  brokerAccountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  }
) => {
  const url = `${constants.BASE_URL}${api.createOrderSell}${accountId}/${inventoryId}`;
  return request(
    url,
    "POST",
    {
      unitPrice,
      quantity,
      brokerAccountId,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` }
  );
  // const { data } = await axios.post(
  //   url,
  //   {
  //     unitPrice,
  //     quantity,
  //     brokerAccountId,
  //   },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiCreateBuyerOrder = (
  typeInventory: string,
  quantity: number,
  // quality: string,
  unitPrice: number,
  accountId: number | null,
  brokerAccountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  fieldsInventory?: InventoryItemTypeField[],
  orderState?: string,
  orderZip?: number,
  forwardInventory?: any, 
  isForwardType?: boolean
) => {
  const url = `${constants.BASE_URL}${api.createOrderBuy}${accountId}`;
  return request(
    url,
    "POST",
    {
      inventoryItemType: typeInventory,
      quantity,
      // quality,
      unitPrice,
      brokerAccountId,
      fieldsInventory,
      orderState,
      orderZip,
      forwardInventory,
      isForwardType
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` }
  );
  // const { data } = await axios.post(
  //   url,
  //   {
  //     inventoryItemType: typeInventory,
  //     quantity,
  //     quality,
  //     unitPrice,
  //     brokerAccountId,
  //   },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiUpdateOrderStatus = (
  idOrder: number | null,
  // accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  }
) => {
  const url = `${constants.BASE_URL}${api.updateOrderStatus}${idOrder}`;
  return request(
    url,
    "POST",
    {
      orderStatus: "IN_PROGRESS",
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` }
  );
};

export const apiUpdateOrder = (
  orderId: number,
  quantity: number,
  unitPrice: number,
  typeInventory: string,
  // quality: string,
  accountId: number | null,
  brokerAccountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  fieldsInventory?: InventoryItemTypeField[],
  orderState?: string,
  orderZip?: string
) => {
  const url = `${constants.BASE_URL}${api.updateOrder}${orderId}/${accountId}`;
  return request(
    url,
    "PUT",
    {
      inventoryItemType: typeInventory,
      quantity,
      // quality,
      unitPrice,
      fieldsInventory,
      brokerAccountId,
      orderState,
      orderZip,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` }
  );
  // const { data } = await axios.put(
  //   url,
  //   {
  //     inventoryItemType: typeInventory,
  //     quantity,
  //     quality,
  //     unitPrice,
  //     brokerAccountId,
  //   },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Get by Id
export const apiGetOrderById = (
  idOrder: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  }
) => {
  const url = `${constants.BASE_URL}${api.getOrderById}${idOrder}`;
  return request(url, "GET", {}, token, {
    Authorization: `Bearer ${token.accessToken}`,
  });
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Get
export const apiGetAllOrders = (
  isAdmin: boolean,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  inventoryTypeId: number,
  sort?: string | undefined,
  order?: string | undefined,
  limit?: number | undefined,
  offset?: number | undefined,
  type?: string | undefined,
  filterState?: string | undefined
) => {
  let url = "";
  if (isAdmin) url = `${constants.BASE_URL}${api.getAllOrdersForAdmin}`;
  else
    url = `${constants.BASE_URL}${api.getAllOrders}?sort=${sort}&order=${order}&limit=${limit}&offset=${offset}&type=${type}&filterState=${filterState}&inventoryTypeId=${inventoryTypeId}`;
  return request(
    url,
    "GET",
    {
      sort,
      order,
      limit,
      offset,
      type,
      filterState,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` }
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Get
export const apiGetAllOrdersByAccountId = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  }
) => {
  const url = `${constants.BASE_URL}${api.getAllOrdersByAccountId}${accountId}`;
  return request(url, "GET", {}, token, {
    Authorization: `Bearer ${token.accessToken}`,
  });
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiGetAllOrdersForeGuest = (token: {
  accessToken: string | null;
  tokenExp: string | null;
  refreshToken: string | null;
}) => {
  const url = `${constants.BASE_URL}${api.getAllOrders}`;
  return request(url, "GET", {}, token, {
    Authorization: `Bearer ${token.accessToken}`,
  });
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiDeleteOrder = (
  idOrder: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  }
) => {
  const url = `${constants.BASE_URL}${api.deleteOrder}${idOrder}`;
  return request(url, "DELETE", {}, token, {
    Authorization: `Bearer ${token.accessToken}`,
  });
  // const { data } = await axios.delete(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};
