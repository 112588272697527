import React, {
  FC,
  useEffect,
  useCallback,
  Fragment,
} from 'react';
import {
  Typography, Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRequest } from '../../interfaces/adminRequest.interface';
import { RootState } from '../../store/slices';
import { getSingleDealInfo } from '../../store/slices/deals.slice';
import { getDate } from '../../utils/getDate';
import getLocalStorageData from '../../api/localStorage';
import { NegotiationPointForDeal, ItemOfDealList } from '../../interfaces/deals.interface';
import useStyles from './Request.style';

interface Props {
  request: AdminRequest;
}

const DealRequestDetails: FC<Props> = ({ request }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    deal,
  } = useSelector(
    (state: RootState) => state.deals,
  );

  useEffect(() => {
    const { token } = getLocalStorageData();
    dispatch(getSingleDealInfo(request.deal_id, token));
  }, [dispatch, request]);

  let type = '';
  if (deal && deal.order && deal.order.inventory && deal.order.inventory.inventoryItemType) {
    type = deal.order.inventory.inventoryItemType.type;
  }

  const getValue = useCallback(
    (item: NegotiationPointForDeal, dealValue: ItemOfDealList) => {
      let title = '';
      const units = dealValue.order.inventory &&
        dealValue.order.inventory.inventoryItemType &&
        dealValue.order.inventory.inventoryItemType.units;
      let value;
      if (item.valueType === 'NUMBER' && item.title === 'Quantity') {
        title = item.title;
        value = `${item.valueSellerNumber} ${units}`;
      } else if (item.valueType === 'NUMBER' && item.title !== 'Quantity') {
        title = item.title;
        value = (+item.valueSellerNumber).toFixed(2);
      } else if (item.valueType === 'STRING') {
        title = item.title;
        value = item.valueSellerString;
      } else if (item.valueType === 'DATE') {
        title = item.title;
        value = getDate(item.valueSellerDate);
      } else if (item.valueType === 'CHOICE') {
        title = item.title;
        value = item.valueSellerString;
      } else {
        title = item.title;
        value = item.valueSellerBoolean ? 'true' : 'false';
      }
      return (
        <Fragment key={item.id}>
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              {`${title.replace(/\/,/g, '.')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {value}
            </Typography>
          </Grid>
          {item.descriptionOfNPMadeByUserInDealValueSeller && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  {`${title} description:`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {item.descriptionOfNPMadeByUserInDealValueSeller}
                </Typography>
              </Grid>
            </>
          )}
        </Fragment>
      );
    }, [],
  );

  return (
    <>
      {deal && (
        <Grid container className={classes.fields}>
          {deal.order.inventory.instantCheckout && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Instant Checkout:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {deal.order.inventory.instantCheckout && 'true'}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography variant="body1" color="primary">
              Type:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {type}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              Clients:
            </Typography>
          </Grid>
          {deal.seller && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Seller:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {deal.seller.name}
                </Typography>
              </Grid>
            </>
          )}
          {deal.sellerBroker && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Seller Broker:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {deal.sellerBroker.name}
                </Typography>
              </Grid>
            </>
          )}
          {deal.buyer && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Buyer:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {deal.buyer.name}
                </Typography>
              </Grid>
            </>
          )}
          {deal.buyerBroker && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" color="primary">
                  Buyer broker:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {deal.buyerBroker.name}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h5">
              Inventory Attributes:
            </Typography>
          </Grid>
          {
            deal.negotiationPointsDealsInventoryAttribute.map((item) => (
              getValue(item, deal)
            ))
          }
          <Grid item xs={12}>
            <Typography variant="h5">
              Negotiation Points:
            </Typography>
          </Grid>
          {
            deal.negotiationPointsDeals.map((item) => (
              getValue(item, deal)
            ))
          }
          <Grid item xs={12}>
            <Typography variant="h5">
              Post Trade:
            </Typography>
          </Grid>
          {
            deal.foundPostTradeProcessingStepsDeal.map((item) => (
              <Fragment key={item.id}>
                <Grid item xs={6}>
                  <Typography variant="body1" color="primary">
                    {`${item.description}:`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {getDate(item.valueSeller)}
                  </Typography>
                </Grid>
                {item.responsibleValueSeller && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" color="primary">
                        Responsible:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {item.responsibleValueSeller}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Fragment>
            ))
          }
        </Grid>
      )}
    </>
  );
};

export default DealRequestDetails;
