import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  contractNumber: {
    color: theme.palette.primary.dark,
    marginLeft: 16,
  },
  chapterContainer: {
    padding: '24px 0 16px',
  },
  heightFitContent: {
    height: 'fit-content',
  },
  heading: {
    color: theme.palette.secondary.dark,
    marginRight: 16,
  },
  secondaryHeading: {
    color: theme.palette.primary.dark,
    minHeight: 24,
    fontWeight: 'bold',
  },
  marginBottom8: {
    marginBottom: 8,
  },
  margin8: {
    margin: '8px 0',
  },
  marginBottom20: {
    marginBottom: 20,
  },
  italic: {
    fontStyle: 'italic',
  },
  agreementHeight: {
    maxHeight: 200,
    overflowY: 'auto',
  },
  pdfContainerExpand: {
    position: 'relative',
    border: '1px solid #D5D5D7',
    padding: 5,
    maxHeight: 600,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  pdfContainerShrink: {
    position: 'relative',
    border: '1px solid #D5D5D7',
    padding: 5,
    maxHeight: 200,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
    },
  },
  longDescriptionHide: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  longDescriptionBtn: {
    padding: 0,
    textDecoration: 'underline',
    color: theme.palette.primary.light,
  },
  expandBtn: {
    position: 'sticky',
    zIndex: 1,
    left: '95%',
    top: 0,
    backgroundColor: '#F0F4F8',
  },
}));

export default useStyles;
