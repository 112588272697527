import constants from '../core/constants';
import api from '../core/api';
import request from './apiRequest';

// Create
export const apiCreateInventory = (
  inventory: FormData,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.createInventory}${accountId}`;
  return request(
    url,
    'POST',
    inventory,
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
  // const { data } = await axios.post(
  //   url,
  //   inventory,
  //   { headers: { Authorization: `Bearer ${token.accessToken}`,
  //  ContentType: 'multipart/form-data' } },
  // );
  // return data;
};

// delete
export const apiDeleteInventory = (
  inventoryItemId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.deleteInventory}${inventoryItemId}`;
  return request(
    url,
    'DELETE',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
};

// Set active
export const apiSetActiveStatusInventory = (
  idInventory: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.setActiveStatusInventory}${idInventory}`;
  return request(
    url,
    'PUT',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.put(
  //   url,
  //   {},
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiApproveInventory = (
  idInventory: number,
  brokerAccountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.approveInventory}${idInventory}`;
  return request(
    url,
    'POST',
    { brokerAccountId },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.post(
  //   url,
  //   { brokerAccountId },
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

export const apiСheckSignName = (
  signName: string,
  brokerAccountId: number | null,
  accountId: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.checkSignName}`;
  return request(
    url,
    'POST',
    {
      signName,
      brokerAccountId,
      accountId,
    },
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

// Get by Id
export const apiGetInventoryById = (
  idInventory: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getInventoryById}${idInventory}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Get by Id
export const apiCheckActiveOrders = (
  idInventory: number,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.checkActiveOrders}${idInventory}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};

// Get
export const apiGetInventory = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
  status?: string | undefined,
  sortType?: string | undefined,
  sortBy?: string | undefined,
  limit?: number | undefined,
  offset?: number | undefined,
) => {
  const url = `${constants.BASE_URL}${api.getInventory}${accountId}?status=${status}&sortType=${sortType}&sortBy=${sortBy}&limit=${limit}&offset=${offset}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
  // const { data } = await axios.get(
  //   url,
  //   { headers: { Authorization: `Bearer ${token.accessToken}` } },
  // );
  // return data;
};

// Update
export const apiUpdateInventory = (
  idInventory: number,
  inventory: FormData,
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.updateInventory}${idInventory}/${accountId}`;

  return request(
    url,
    'PUT',
    inventory,
    token,
    { Authorization: `Bearer ${token.accessToken}`, ContentType: 'multipart/form-data' },
  );
  // const { data } = await axios.put(
  //   url,
  //   inventory,
  //   { headers: { Authorization: `Bearer ${token.accessToken}`,
  // ContentType: 'multipart/form-data' } },
  // );
  // return data;
};

// Get
export const apiGetAllExistedInventory = (
  accountId: number | null,
  token: {
    accessToken: string | null;
    tokenExp: string | null;
    refreshToken: string | null;
  },
) => {
  const url = `${constants.BASE_URL}${api.getAllExistedInventory}`;
  return request(
    url,
    'GET',
    {},
    token,
    { Authorization: `Bearer ${token.accessToken}` },
  );
};
