import React, {
  FC, useEffect, useState, useCallback, useRef, useMemo,
} from 'react';
import cx from 'classnames';
import {
  useLocation, Prompt, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Paper,
  Hidden,
  Grid,
  Fab,
  TextField,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  MenuItem,
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { Stack } from '@mui/material';
import SendIcon from '@material-ui/icons/Send';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import {
  getSingleDealInfo,
  updateChatMessages,
  updateNP,
  agreeWithDeal,
  updatePostTradeSteps,
  markPTSCompleted,
  goToDeal,
  dealSuccess,
  updateIA,
  leftTheDeal,
  acceptAllIA,
  acceptAllNP,
  acceptAllPTS,
} from '../../store/slices/deals.slice';
import { RootState } from '../../store/slices';
import getLocalStorageData from '../../api/localStorage';
import InventoryInfo from './InventoryInfo';
import { Inventory } from '../../interfaces/inventory.interface';
import NegotiationPoints from './NegotiationPoints';
import { NegotiationPointForDeal, ChatMessage, PostTradeStepForDeal, ItemOfDealList } from '../../interfaces/deals.interface';
import { addNotification } from '../../store/slices/snackbar.slice';
// import { getAllQualityTypesForInventory } from '../../store/slices/quality.slice';
import PostTradeSteps from './PostTradeSteps';
import { getRole, getBrokerRole } from '../../utils/getRole';
import PostTradeStepsCheck from './PostTradeStepsCheck';
import LoadingPage from '../LoadingPage/LoadingPage';
import DealInfo from './DealInfo';
import useStyles from './DealRoom.style';
// import { updateToken, logOut } from '../../store/slices/auth.slice';
import ButtonSC from '../ButtonSC/ButtonSC';
import PaperBrokerClient from '../PaperBrokerClient/PaperBrokerClient';
import ChangeTimeout from './ChangeTimeout';
import { NumberFormatZip } from '../NumberFormat/NumberFormat';
import InventoryAttributes from './InventoryAttribute';
import { apiChangeDealStatus, apiCheckСomission } from '../../api/deals';
import TextFieldProfile from '../TextFieldProfile/TextFieldProfile';
import StateAutocomplete from '../StateAutocomplete/StateAutocomplete';
import Indicators from './Indicators';
import DrawerChat from './DrawerChat/DrawerChat';
import { Socket } from '../../containers/MainContainer/MainContainer';
import { apiUpdateOrderStatus } from '../../api/order';
import translationEN from '../../locales/en/translation.json';
import { apiUpdateNotificationType } from '../../api/notification';
import { readNotificationsMessage } from '../../store/slices/notifocation.slice';
import { apiDeleteInventory } from '../../api/inventory';
// import { token } from '../../mockDataForTest';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const newMessage = require('../../sounds/alert_high-intensity.wav');

const DealRoom: FC = () => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const IARef = useRef();
  const NPRef = useRef();
  const PTSRef = useRef();

  const dealId = (location?.state?.dealId) || location?.search?.match(/dealId=(\d+)/)[1];
  const dealRoomId = (location?.state?.dealRoomId) || location?.search?.match(/dealRoomId=(\d+)/)[1];
  const [currentInventory, setCurrentInventory] = useState<Inventory | undefined>(undefined);
  const [currentNegotiationPoints, setCurrentNP] = useState<NegotiationPointForDeal[]>([]);
  const [currentInventoryAttributes, setCurrentIA] = useState<NegotiationPointForDeal[]>([]);

  const [currentPostTradeSteps, setCurrentPostTradeSteps] = useState<PostTradeStepForDeal[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [openChat, setOpenChat] = useState<boolean>(true);

  const [shipToLocationLine1, setShipToLine1] = useState<string>('');
  const [shipToLocationLine2, setShipToLine2] = useState<string>('');
  const [shipToLocationCity, setShipToCity] = useState<string>('');
  const [shipToLocationState, setShipToState] = useState<string>('');
  const [shipToLocationZip, setShipToZip] = useState<string>('');
  const [shipToLocationCountry, setShipToCountry] = useState<string>('');
  const [proceedError, setProceedError] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<string | false>(false);
  const [locationZipError, setLocationZipError] = useState<string | false>(false);

  const [shipFromLocationLine1, setShipFromLine1] = useState<string>('');
  const [shipFromLocationLine2, setShipFromLine2] = useState<string>('');
  const [shipFromLocationCity, setShipFromCity] = useState<string>('');
  const [shipFromLocationState, setShipFromState] = useState<string>('');
  const [shipFromLocationZip, setShipFromZip] = useState<string>('');
  const [shipFromLocationCountry, setShipFromCountry] = useState<string>('');
  const [shipFromlocationError, setShipFromLocationError] = useState<string | false>(false);
  const [shipFromlocationZipError, setShipFromLocationZipError] = useState<string | false>(false);

  const [sellerLocationAddressChanges, setSellerLocationAddressChanges] = useState<boolean>(false);
  
  const [locationAddressChanges, setLocationAddressChanges] = useState<boolean>(false);
  const [newMessageIcon, setNewMessage] = useState<boolean>(false);

  const [isOpenFeeAccept, openFeeAccept] = useState<false | string>(false);
  const [isOpenFeeAcceptSuccess, openFeeAcceptSuccess] = useState<boolean>(false);
  const [isOpenFeeAcceptFailuare, openFeeAcceptFailuare] = useState<boolean>(false);
  const [commision, setCommision] = useState<string>('');

  const newMessageAudio = new Audio(newMessage);

  // const [sellerOnOff, setSellerOnOff] = useState<boolean>(true);
  // const [buyerOnOff, setBuyerOnOff] = useState<boolean>(false);

  // const [orderRenderStatus, setOrderRenderStatus] = useState<string>('OPEN');
  // const isSoundAlertsAccepted = useSelector((state: RootState) => state.alertsAndMessages.isSoundAlertsAccepted)
  const playSound = (audioFile: any) => {
    audioFile.play();
  };

  const {
    deal,
    chatMessages,
    negotiationPoints,
    inventoryAttributes,
    postTradeSteps,
    isLoading,
    totalPrice,
  } = useSelector(
    (state: RootState) => state.deals,
  );


  //@ts-ignore
  const deals: Partial<ItemOfDealList> = useSelector(
    (state: RootState) => state.deals,
  );

  const dealss: Partial<ItemOfDealList> | null = deals;

  const {
    userRole,
  } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    if (!!isOpenFeeAccept || !!isOpenFeeAcceptFailuare) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [history, isOpenFeeAccept, isOpenFeeAcceptFailuare]);

  useEffect(() => {
    if (deal?.status !== 'IN PROGRESS') {
      openFeeAccept(false);
      openFeeAcceptSuccess(false);
      openFeeAcceptFailuare(false);
    }
    if (deal?.status === 'NEEDS CONFIRMATION') {
      openFeeAcceptSuccess(true);
    }
    if (deal?.status === 'CONFIRMED') {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: 'Your Deal is confirmed. Please, sign the Agreement.',
        options: { variant: 'success', autoHideDuration: 4000 },
      }));
    }

  }, [deal]);

  // connectToDealRoom

  useEffect(() => {
    Socket.emit('connectToDealRoom', {
      dealRoomId,
      userId: (deals?.deal?.buyerBroker && deals?.deal?.buyerBroker.id) || (deals?.deal?.buyer && deals?.deal?.buyer.id),
      secondUserId: (deals?.deal?.sellerBroker && deals?.deal?.sellerBroker.id) || (deals?.deal?.seller && deals?.deal?.seller.id),
    });
  }, []);

  // giveMeConnectionInfo
  useEffect(() => {
    Socket.on('giveMeConnectionInfo', () => {
      Socket.emit('connectToDealRoom', {
        dealRoomId,
        userId: (deals?.deal?.buyerBroker && deals?.deal?.buyerBroker.id) || (deals?.deal?.buyer && deals?.deal?.buyer.id),
        secondUserId: (deals?.deal?.sellerBroker && deals?.deal?.sellerBroker.id) || (deals?.deal?.seller && deals?.deal?.seller.id)

      });
    });
    return () => {
      Socket.emit('disconnectFromDealRoom', {
        dealRoomId,
        userId: (deals?.deal?.buyerBroker && deals?.deal?.buyerBroker.id) || (deals?.deal?.buyer && deals?.deal?.buyer.id),
        secondUserId: (deals?.deal?.sellerBroker && deals?.deal?.sellerBroker.id) || (deals?.deal?.seller && deals?.deal?.seller.id),
      });
      Socket.on('sendStatus', (data: any) => {
        // setSellerOnOff(data.user.isOnline);
        // setBuyerOnOff(data.secondUser.isOnline);
      });
      Socket.off('giveMeConnectionInfo');
    };
  }, []);



  const notificationMessageState = useSelector(
    (state: RootState) => state.notificationMess.notificationsMessage
  );

  const [notificationArray, setNotificationArray] = useState<any>([]);

  const ReadNotificationOfDeal = () => {
    const { token } = getLocalStorageData();
    setNotificationArray(notificationMessageState);
    notificationArray.map((item: any) => {
      if( item.link === location.pathname && !item.read ) {
        dispatch(apiUpdateNotificationType(item.id, { read: true }, token));
        dispatch(readNotificationsMessage( item.id ));
      }
    });
  }

  useEffect(()=> {
    ReadNotificationOfDeal();
  },[notificationArray, dispatch])



  useEffect(() => {
    if (!location.state && dealId) {
      const { clientAccountId, accountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      dispatch(goToDeal(
        +dealRoomId,
        +dealId,
        clientAccountId ? accountId : null,
        accountId,
        token,
        key,
      ));
    }
  }, [dispatch, location]);

  useEffect(() => {
    const { token } = getLocalStorageData();
    if (dealId) dispatch(getSingleDealInfo(dealId, token));
  }, [dispatch, dealId]);

  const handleGetRole = useCallback(
    () => {
      const { clientAccountId, accountId } = getLocalStorageData();
      const role = getRole(userRole) === 'Broker' ?
        getBrokerRole(userRole, localStorage.getItem('clientAccountId')) :
        getRole(userRole);
      if (role === 'Seller & Buyer' && (clientAccountId || accountId) === deal?.seller?.id) {
        return 'Seller';
      }
      if (role === 'Seller & Buyer' && (clientAccountId || accountId) === deal?.buyer?.id) {
        return 'Buyer';
      }
      return role;
    },
    [userRole, deal],
  );

  useEffect(() => {
    const { token } = getLocalStorageData();
    Socket.on('message', (data: ChatMessage) => {
      const { clientAccountId, accountId } = getLocalStorageData();
      const user = clientAccountId || accountId;
      if (user && (+data.author !== +user) && ((handleGetRole() === 'Buyer' && data.showForBuyer) || (handleGetRole() === 'Seller' && data.showForSeller) || handleGetRole() === 'Admin')) {
        if (!openChat) setNewMessage(true);
        setOpenChat(true);
        playSound(newMessageAudio);
      }
      dispatch(updateChatMessages(data));
    });
    Socket.on('NP', (data: { foundNP: NegotiationPointForDeal | NegotiationPointForDeal[] }) => {
      dispatch(updateNP(data));
    });
    Socket.on('IA', (data: { foundNP: NegotiationPointForDeal | NegotiationPointForDeal[] }) => {
      dispatch(updateIA(data));
    });
    Socket.on('PTS', (data: NegotiationPointForDeal | NegotiationPointForDeal[]) => {
      dispatch(updatePostTradeSteps(data));
    });
    Socket.on('shipToAddress', (data: any) => {
      dispatch(dealSuccess({ ...deal, ...data }));
      if (handleGetRole() === 'Buyer') {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: 'Saved',
          options: { variant: 'success' },
        }));
      }
      setShipToLine1(data.shipToLocationLine1 || '');
      setShipToLine2(data.shipToLocationLine2 || '');
      setShipToCity(data.shipToLocationCity || '');
      setShipToState(data.shipToLocationState || '');
      setShipToZip(data.shipToLocationZip || '');
      setShipToCountry(data.shipToLocationCountry || '');
    });
    Socket.on('shipFromAddress', (data: any) => {
      dispatch(dealSuccess({ ...deal, ...data }));
      if (handleGetRole() === 'Seller') {
        dispatch(addNotification({
          key: new Date().getTime() + Math.random(),
          message: 'Saved',
          options: { variant: 'success' },
        }));
      }
      setShipFromLine1(data.shipFromLocationLine1 || '');
      setShipFromLine2(data.shipFromLocationLine2 || '');
      setShipFromCity(data.shipFromLocationCity || '');
      setShipFromState(data.shipFromLocationState || '');
      setShipFromZip(data.shipFromLocationZip || '');
      setShipFromCountry(data.shipFromLocationCountry || '');
    });
    return () => {
      Socket.off('message');
      Socket.off('NP');
      Socket.off('IA');
      Socket.off('PTS');
      Socket.off('shipToAddress');
      Socket.off('shipFromAddress');
    };
  }, [dispatch, handleGetRole, openChat, newMessageAudio, deal]);

  const [orderStatus, setOrderStatus] = useState<any>();


  const [conectionOn, setConection] = useState(false);

  const expensiveOperation = (conectionOn: any) => {
   if(conectionOn){
    Socket.emit('connectToDealRoom', {
      dealRoomId,
      userId: (deals?.deal?.buyerBroker && deals?.deal?.buyerBroker.id) || (deals?.deal?.buyer && deals?.deal?.buyer.id),
      secondUserId: (deals?.deal?.sellerBroker && deals?.deal?.sellerBroker.id) || (deals?.deal?.seller && deals?.deal?.seller.id),
    });
    setConection(Socket)
   } 
  };

  useEffect(() => {
    if (deal && deal.order) {
      setOrderStatus(deal.order.orderStatus);
      setCurrentInventory(deal.order.inventory);
      setCurrentNP(negotiationPoints);
      setCurrentIA(inventoryAttributes);
      setCurrentPostTradeSteps(postTradeSteps);
      setShipToLine1(deal.shipToLocationLine1 || '');
      setShipToLine2(deal.shipToLocationLine2 || '');
      setShipToCity(deal.shipToLocationCity || '');
      setShipToState(deal.shipToLocationState || '');
      setShipToZip(deal.shipToLocationZip || '');
      setShipToCountry(deal.shipToLocationCountry || '');
      setShipFromLine1(deal.shipFromLocationLine1 || '');
      setShipFromLine2(deal.shipFromLocationLine2 || '');
      setShipFromCity(deal.shipFromLocationCity || '');
      setShipFromState(deal.shipFromLocationState || '');
      setShipFromZip(deal.shipFromLocationZip || '');
      setShipFromCountry(deal.shipFromLocationCountry || '');
    }
  }, [
    dispatch,
    // setCurrentInventory,
    deal?.order?.inventory,
    // setCurrentNP,
    // setCurrentIA,
    // setCurrentPostTradeSteps,
    currentNegotiationPoints,
    deal,
    negotiationPoints,
    inventoryAttributes,
    postTradeSteps,
    orderStatus,
  ]);

  useEffect(() => {
    if (deal && deal.status === 'TIMEOUT') {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: 'TIMEOUT',
        options: { variant: 'error' },
      }));
      dispatch(apiDeleteInventory(deal?.order?.inventory?.id, token));
      openFeeAccept(false);
    }
  }, [dispatch, deal]);

  const handleSaveLocation = (currentDeal: any) => {
    let error = false;
    setLocationError(false);
    setLocationZipError(false);
    if (handleGetRole() === 'Buyer' && (!shipToLocationLine1 || !shipToLocationCity || !shipToLocationState)) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: 'Required fields',
        options: { variant: 'error' },
      }));
      setLocationError('Required field');
      setLocationZipError(!shipToLocationZip ? 'Required field' : false);
      error = true;
    }
    if (handleGetRole() === 'Buyer' && (shipToLocationZip.length < 5)) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: translationEN.ReEnterZipCode,
        options: { variant: 'error' },
      }));
      setLocationZipError('Incorrect zip, 5 symbols needed');
      error = true;
    } else if (handleGetRole() === 'Buyer' && (shipToLocationZip.length > 5 && shipToLocationZip.length < 9)) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: translationEN.ReEnterZipCode,
        options: { variant: 'error' },
      }));
      setLocationZipError('Incorrect zip, min 5 or 9 symbols needed');
      error = true;
    }
    if (error) return;
    setLocationAddressChanges(false);
    Socket.emit('changeShipToAddress', {
      dealId: currentDeal.id,
      shipToLocationLine1,
      shipToLocationLine2,
      shipToLocationCity,
      shipToLocationState,
      shipToLocationZip,
      shipToLocationCountry,
    });
  };

  const handleSaveSellerLocation = (currentDeal: any) => {
    let error = false;
    setShipFromLocationError(false);
    setShipFromLocationZipError(false);
    if (handleGetRole() === 'Seller' && (!shipFromLocationLine1 || !shipFromLocationCity || !shipFromLocationState)) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: 'Required fields',
        options: { variant: 'error' },
      }));
      setShipFromLocationError('Required field');
      setShipFromLocationZipError(!shipFromLocationZip ? 'Required field' : false);
      error = true;
    }
    if (handleGetRole() === 'Seller' && (shipFromLocationZip.length < 5)) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: translationEN.ReEnterZipCode,
        options: { variant: 'error' },
      }));
      setShipFromLocationZipError('Incorrect zip, 5 symbols needed');
      error = true;
    } else if (handleGetRole() === 'Seller' && (shipFromLocationZip.length > 5 && shipFromLocationZip.length < 9)) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: translationEN.ReEnterZipCode,
        options: { variant: 'error' },
      }));
      setShipFromLocationZipError('Incorrect zip, min 5 or 9 symbols needed');
      error = true;
    }
    if (error) return;
    setSellerLocationAddressChanges(false);
    Socket.emit('changeShipFromAddress', {
      dealId: currentDeal.id,
      shipFromLocationLine1,
      shipFromLocationLine2,
      shipFromLocationCity,
      shipFromLocationState,
      shipFromLocationZip,
      shipFromLocationCountry,
    });
  };

  const handleSendMessage = (message: string, showForSeller: boolean, showForBuyer: boolean) => {
    const { accountId, token } = getLocalStorageData();
    const data = {
      dealRoomId,
      authorAccountId: accountId,
      message,
      dealId,
      showForSeller,
      showForBuyer,
      token,
    };
    Socket.emit('sendMessage', data);
  };

  const handleSetAgreeToNP = (
    agreedValue: { data: string | boolean | number; description?: string | undefined },
    negotiationPointId: number,
  ) => {
    const { accountId } = getLocalStorageData();
    const data = {
      accountId,
      agreedValue: agreedValue.data,
      agreedDescriptionValue: agreedValue.description,
      negotiationPointId,
      dealId,
      dealRoomId,
    };
    Socket.emit('agreeNP', data);
  };

  const handleSetAgreeToPTS = (
    agreedValue: { date: string; responsible: string },
    postTradeProcessingStepDealId: number,
  ) => {
    const { accountId } = getLocalStorageData();
    const data = {
      accountId,
      agreedValue: agreedValue.date,
      agreedResponsibleValue: agreedValue.responsible,
      postTradeProcessingStepDealId,
      dealId,
      dealRoomId,
    };
    Socket.emit('agreePTS', data);
  };

  const handleUpdateNP = (
    value: { data: string | boolean | number; description: string | undefined },
    negotiationPointId: number,
  ) => {
    const { accountId } = getLocalStorageData();
    const data = {
      dealRoomId,
      accountId,
      dealId,
      newValue: value.data,
      newDescriptionValue: value.description,
      negotiationPointId,
    };
    Socket.emit('changeNP', data);
  };

  const handleUpdatePostTradeSteps = (
    value: { date: string; responsible: string },
    postTradeProcessingStepDealId: number,
  ) => {
    const { accountId } = getLocalStorageData();
    const data = {
      dealRoomId,
      accountId,
      dealId,
      newValue: new Date(new Date(value.date).setHours(12, 0, 0, 0)),
      newResponsibleValue: value.responsible,
      postTradeProcessingStepDealId,
    };
    console.log('data :>> ', data);

    Socket.emit('changePTS', data);
  };

  const handleChangeTimer = (
    newDate: string | Date,
  ) => {
    const { accountId } = getLocalStorageData();
    const data = {
      dealId,
      newDate,
      dealRoomId,
      accountId,
    };
    Socket.emit('changeTimer', data);
  };

  const handleAgreeTimer = (
    newAgreedDate: string,
  ) => {
    const data = {
      dealId,
      newAgreedDate,
    };
    Socket.emit('agreeTimer', data);
  };

  const handleFinishPTS = (postTradeProcessingStepDealId: number, documents: File[]) => {
    const { accountId, clientAccountId, token } = getLocalStorageData();
    const key = new Date().getTime() + Math.random();
    const formData = new FormData();

    const data = {
      dealId,
      dealRoomId,
      accountId: clientAccountId || accountId,
      postTradeProcessingStepDealId,
      accountBrokerId: clientAccountId ? accountId : null,
    };
    const pts = JSON.stringify(data);
    formData.append('pts', pts);
    if (documents && documents.length > 10) {
      dispatch(addNotification({
        key: new Date().getTime() + Math.random(),
        message: translationEN.TheMaximumNumberOfFilesIs10,
        options: { variant: 'error' },
      }));
      return;
    }
    if (documents && !!documents.length) {
      documents.forEach((item: File) => {
        formData.append('ptsDocs', item);
      });
    }
    dispatch(markPTSCompleted(formData, token, key));
  };

  const getAgreeRole = useCallback(
    () => {
      const { accountId } = getLocalStorageData();
      if (getRole(userRole) === 'Admin') return false;
      if (deal &&
        (deal.sellerSideAgreedOnADeal === accountId || deal.buyerSideAgreedOnADeal === accountId)
      ) return false;
      return true;
    },
    [deal, userRole],
  );

  const handleAgreeWithDeal = useCallback(
    async (currentDealId: number) => {
      const { clientAccountId, accountId, token } = getLocalStorageData();
      const key = new Date().getTime() + Math.random();
      setProceedError(false);
      setLocationError(false);
      setLocationZipError(false);

      if (handleGetRole() === 'Buyer' && (!shipToLocationLine1 || !shipToLocationCity || !shipToLocationState)) {
        dispatch(addNotification({
          key,
          message: 'Empty fields in address',
          options: { variant: 'error' },
        }));
        setLocationError('Required field');
        setLocationZipError(!shipToLocationZip ? 'Required field' : false);
        return;
      }
      if (currentInventoryAttributes.filter((item) => !item.agreed).length !== 0) {
        dispatch(addNotification({
          key,
          message: 'Both Parties MUST Agree on ALL Inventory Attributes!',
          options: { variant: 'error' },
        }));
        setProceedError(true);
        return;
      }
      if (currentNegotiationPoints.filter((item) => !item.agreed).length !== 0) {
        dispatch(addNotification({
          key,
          message: 'Both Parties MUST Agree on ALL Negotiation Points!',
          options: { variant: 'error' },
        }));
        setProceedError(true);
        return;
      }
      if (currentPostTradeSteps.filter((item) => !item.agreed).length !== 0) {
        dispatch(addNotification({
          key,
          message: 'Both Parties MUST Agree on ALL Post Negotiation Steps!',
          options: { variant: 'error' },
        }));
        setProceedError(true);
        return;
      }

      try {
        const commisionValue: any =
          await dispatch(apiCheckСomission(dealId, clientAccountId || accountId, token));
        setCommision(commisionValue);
        if (commisionValue.systemCommision > 0) openFeeAccept(commisionValue.systemCommision);
        else {
          dispatch(agreeWithDeal(
            dealId,
            accountId,
            token,
            new Date().getTime() + Math.random(),
          ));
          // openFeeAccept(false);
          openFeeAcceptSuccess(true);
        }
      } catch (error) {
        dispatch(addNotification({
          key,
          message: error.response ? error?.response?.data.error : error?.message,
          options: { variant: 'error' },
        }));
      }
    },
    [
      dispatch,
      currentNegotiationPoints,
      currentInventoryAttributes,
      currentPostTradeSteps,
      handleGetRole,
      shipToLocationCity,
      shipToLocationLine1,
      shipToLocationState,
      shipToLocationZip,
      dealId,
    ],
  );

  const numberWithSpaces = (number: string) => {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  };

  const handleCheckDeliveryAddressFill = (currentDeal: any) => {
    if (handleGetRole() === 'Buyer') return currentDeal.shipToLocationLine1 && currentDeal.shipToLocationCity && currentDeal.shipToLocationState && currentDeal.shipToLocationZip;
    return true;
  };

  const handleAcceptAllIA = useCallback(
    () => {
      const { accountId, token } = getLocalStorageData();
      dispatch(acceptAllIA(dealId, accountId, token, new Date().getTime() + Math.random()));
    },
    [dispatch, dealId],
  );

  const handleAcceptAllNP = useCallback(
    () => {
      const { accountId, token } = getLocalStorageData();
      dispatch(acceptAllNP(dealId, accountId, token, new Date().getTime() + Math.random()));
    },
    [dispatch, dealId],
  );

  const handleAcceptAllPTS = useCallback(
    () => {
      const { accountId, token } = getLocalStorageData();
      dispatch(acceptAllPTS(dealId, accountId, token, new Date().getTime() + Math.random()));
    },
    [dispatch, dealId],
  );

  const { accountId, token } = getLocalStorageData();

  const getNextStepToAccept = useCallback(
    () => {
      const arrayOfPoints: { type: string; id: number; agreed: boolean | 'byOne' }[] = [];
      currentInventoryAttributes.map((item) => {
        if (!item.agreedBySeller && !item.agreedByBuyer) {
          arrayOfPoints.push({ type: 'IA', id: item.id, agreed: false });
        } else if (item.agreed) {
          arrayOfPoints.push({ type: 'IA', id: item.id, agreed: true });
        } else {
          arrayOfPoints.push({ type: 'IA', id: item.id, agreed: 'byOne' });
        }
        return null;
      });
      currentNegotiationPoints.map((item) => {
        if (!item.agreedBySeller && !item.agreedByBuyer) {
          arrayOfPoints.push({ type: 'NP', id: item.id, agreed: false });
        } else if (item.agreed) {
          arrayOfPoints.push({ type: 'NP', id: item.id, agreed: true });
        } else {
          arrayOfPoints.push({ type: 'NP', id: item.id, agreed: 'byOne' });
        }
        return null;
      });
      currentPostTradeSteps.map((item) => {
        if (!item.agreedBySeller && !item.agreedByBuyer) {
          arrayOfPoints.push({ type: 'PTS', id: item.id, agreed: false });
        } else if (item.agreed) {
          arrayOfPoints.push({ type: 'PTS', id: item.id, agreed: true });
        } else {
          arrayOfPoints.push({ type: 'PTS', id: item.id, agreed: 'byOne' });
        }
        return null;
      });
      const findItemArray = arrayOfPoints.find((item) => item.agreed === 'byOne' || !item.agreed);
      return (findItemArray === undefined || findItemArray?.agreed === 'byOne') ? false : findItemArray;
    },
    [currentInventoryAttributes, currentNegotiationPoints, currentPostTradeSteps],
  );

  const executeScroll = (ref: any) => { if (ref) ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' }); };
  const handleDrawerOpen = () => {
    setOpenChat(false);
  };

  const sendStartMessage = () => {
    handleSendMessage('Negotiations started', true, true);
  }

  const confirmOrderReady = () => {
    const dt = new Date();
    dt.setHours(dt.getHours() + 23);
    // const { token } = getLocalStorageData();
    const orderId = deal?.order.id ? deal?.order.id : null;
    // const dealId = deal?.id ? deal?.id : null;
    // const status = 'IN PROGRESS';
    dispatch(apiUpdateOrderStatus(orderId, token));
    dispatch(apiChangeDealStatus(dealId, token));
    // window.location.reload();
    handleChangeTimer(dt);
    setOrderStatus('');
    deal?.status === 'IN PROGRESS' ? sendStartMessage() : setTimeout(sendStartMessage, 1500);
  };

  const handleBuy = async () => {
    const key = new Date().getTime() + Math.random();
    const {
      accountId,
      clientAccountId,
      token,
    } = getLocalStorageData();

    const handleSendMessage = (message: string, showForSeller: boolean, showForBuyer: boolean) => {
      const { accountId, token } = getLocalStorageData();
      const data = {
        dealRoomId,
        authorAccountId: accountId,
        message,
        dealId,
        showForSeller,
        showForBuyer,
        token,
      };
      Socket.emit('sendMessage', data);
    };
    handleSendMessage(sendAnswerText, true, true);
    setCurrencyHour('');
    setCurrencyMinute('');
    setCurrencyAmPm('');
  };

  const [currentMessage, setCurrentMessage] = useState<string>('');

  const currentHours = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
  ];

  const [currencyHour, setCurrencyHour] = useState('');

  const handleChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyHour(event.target.value);
  };

  const currentMinutes = [
    { value: '00', label: '00' },
    { value: '05', label: '05' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '35', label: '35' },
    { value: '40', label: '40' },
    { value: '45', label: '45' },
    { value: '50', label: '50' },
    { value: '55', label: '55' },
  ];

  const [currencyMinute, setCurrencyMinute] = useState('');

  const handleChangeMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyMinute(event.target.value);
  };

  const currentAmPm = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ];

  const [currencyAmPm, setCurrencyAmPm] = useState('');

  const handleChangeAmPm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyAmPm(event.target.value);
  };

  const [calendar, setCalendar] = useState(new Date());

  let dataString = calendar.toString();
  const handleChangeCalendar = (newValue: Date) => {
    setCalendar(newValue);
    dataString = calendar.toString();
    dataString.substring(0, 15);
  };

  let sendAnswerText = currencyHour ? `${currentMessage} Suggests to meet in SmartDeal Room at: ${currencyHour}:${currencyMinute}${currencyAmPm} EST on ${dataString.substring(0, 15)}` : `${currentMessage}`;

  const onlineStatus = useSelector(
    (state: RootState) => state?.user?.user?.isOnline
  );

  useEffect(() => {
    const { token, accountId } = getLocalStorageData();
    if (!onlineStatus) {
    }
    Socket.emit('connectToPersonalRoom', accountId);
  }, [getLocalStorageData, localStorage.getItem('accessToken')]);

  
  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) :
        (deal && (
          <div className={classes.box_smart_deal1_wrapper}>
            <div
              className={classes.box_style}
            >
              <>
                {isLoading ? (
                  <LoadingPage />
                ) :
                  (deal && (
                    <>
                      <Prompt
                        when={!!isOpenFeeAccept || !!isOpenFeeAcceptFailuare}
                        message="Error"
                      />
                      {deal.status === 'IN PROGRESS' && (handleGetRole() === 'Buyer' ? handleCheckDeliveryAddressFill(deal) : true) && (
                        <Indicators
                          IA={currentInventoryAttributes}
                          NP={currentNegotiationPoints}
                          PTS={currentPostTradeSteps}
                          nextStep={getNextStepToAccept()}
                          userRole={handleGetRole()}
                          executeScroll={executeScroll}
                          IARef={IARef}
                          NPRef={NPRef}
                          PTSRef={PTSRef}
                        />
                      )}
                      <Hidden smUp>
                        <PaperBrokerClient />
                      </Hidden>
                      <DealInfo
                        userRole={userRole}
                        status={deal.status}
                        dealRoomId={dealRoomId}
                        expireBuyerTime={
                          deal.status === 'IN PROGRESS' ? deal.expiredAt : deal.expiredAt
                        }
                        expireSellerTime={
                          deal.status === 'IN PROGRESS' ? deal.expiredAt : deal.expiredAt
                        }
                        sellerAgree={deal.sellerSideAgreedOnADeal}
                        buyerAgree={deal.buyerSideAgreedOnADeal}
                        seller={deal.seller}
                        buyer={deal.buyer}
                        sellerBroker={deal.sellerBroker}
                        buyerBroker={deal.buyerBroker}
                        orderNum={deal?.order?.orderNumber}
                        deleteOrderInventory={()=> dispatch(apiDeleteInventory(deal?.order?.inventory?.id, token))}
                      />

                      {/* {orderStatus === 'OPEN' && <Grid className={classes.confirm_order_wrapper}> */}
                      {deal.status === 'PENDING' && <Grid className={classes.confirm_order_wrapper}>
                        <Paper className={classes.dealPaper}>
                          {handleGetRole() === 'Buyer' && <Typography className={classes.confirm_message_wrapper} >
                            <h1 className={classes.confirm_message}>
                              Please, wait for the Seller to start the meeting.
                            </h1>
                            <br />
                            <h4>
                              You may send the Seller a message in Chat.
                            </h4>
                          </Typography>}

                          {handleGetRole() === 'Seller' &&
                            <Grid container justifyContent="center">
                              <Grid item xs={5}>
                                <ButtonSC
                                  variant="fill"
                                  text="Start the Meeting"
                                  size="large"
                                  color="green"
                                  handleClick={() => confirmOrderReady()}
                                />
                              </Grid>
                            </Grid>
                          }
                          {handleGetRole() === 'Seller' && <Typography className={classes.confirm_message_wrapper} >
                            <h4>
                              Or send a message in Chat.
                            </h4>
                          </Typography>}
                        </Paper>

                        <Paper className={classes.dealPaperDate}>
                          <Grid className={classes.textAlignCenter}>
                            <p>
                              Suggest the time and date for your SmartDeal Room meeting here:
                            </p>
                          </Grid>


                          <Grid className={classes.DateAndTimeWrapper} >

                            <TextField
                              id="select_currency_Hours"
                              select
                              label="Hour"
                              value={currencyHour}
                              onChange={handleChangeHours}
                              size="small"
                              className={classes.timeWrapper}
                            >
                              {currentHours.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              id="select_currency_Minutes"
                              select
                              label="Minutes"
                              value={currencyMinute}
                              onChange={handleChangeMinutes}
                              size="small"
                              className={classes.timeWrapper}
                            >
                              {currentMinutes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              id="select_currency_AMPM"
                              select
                              label="AM/PM"
                              value={currencyAmPm}
                              onChange={handleChangeAmPm}
                              size="small"
                              className={classes.timeWrapper}
                            >
                              {currentAmPm.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              id="outlined-read-only-input"
                              // label="Read Only"
                              defaultValue="EST"
                              size="small"
                              className={classes.estWrapper}
                              InputProps={{
                                readOnly: true,
                              }}
                            />

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <DesktopDatePicker
                                  label="Date desktop"
                                  inputFormat="MM/dd/yyyy"
                                  value={calendar}
                                  //@ts-ignore
                                  onChange={handleChangeCalendar}
                                  size="small"
                                  renderInput={(params) =>
                                    //@ts-ignore
                                    <TextField {...params}
                                      size="small"
                                      className={classes.calendarWrapper}
                                    />}
                                />
                              </Stack>
                            </LocalizationProvider>
                            <Grid item xs={1} className={classes.sendButtonWrapper}>
                              <Fab color="primary" aria-label="openChat" size="small" onClick={() => handleBuy()}>
                                <SendIcon />
                              </Fab>
                            </Grid>
                          </Grid>
                        </Paper>



                      </Grid>}

                      {!openChat && (
                                <Fab
                                  aria-label="openChat"
                                  className={cx(
                                    classes.chatFixedContainer,
                                    newMessageIcon && classes.chatFabNew,
                                    classes.chatFab,
                                  )}
                                  onClick={() => {
                                    if (!openChat) setNewMessage(false);
                                    setOpenChat(!openChat);
                                  }}
                                >
                                  <ChatIcon />
                                </Fab>
                              )}
                      {/* {orderStatus !== 'OPEN' && <> */}
                      {deal.status !== 'PENDING' && <>
                        {deal.status === 'IN PROGRESS' && getRole(userRole) !== 'Admin' && (
                          <>
                            <br />
                            <ChangeTimeout
                              changeTimer={handleChangeTimer}
                              agreeTimer={handleAgreeTimer}
                              sellerSessionExpiredAt={deal.sellerNewSetInDealSessionExpiredAt || deal.sellerSessionExpiredAt || ''}
                              buyerSessionExpiredAt={deal.buyerNewSetInDealSessionExpiredAt || deal.buyerSessionExpiredAt || ''}
                              sellerId={deal.seller?.id}
                              buyerId={deal.buyer?.id}
                              agree={!getAgreeRole()}
                            />
                          </>
                        )}
                        <br />
                        {currentInventory && (
                          <InventoryInfo
                            inventory={currentInventory}
                          />
                        )}
                        {deal.status === 'STARTED' ? (
                          <>
                            <br />
                            <Typography variant="h1" align="center" className={classes.textColorGreen}>
                              Waiting for the second participant.
                            </Typography>
                          </>
                        ) :
                          (
                            <>
                              <br />
                              <Paper className={classes.dealPaper}>
                                <Typography variant="h2" className={classes.paperTitle} style={{ paddingLeft: 0 }}> Inventory Location and Delivery Address </Typography>
                                <Grid container justifyContent="space-between">
                                  {/* <Hidden lgUp> */}
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      lg={6}
                                      alignContent="flex-start"
                                    >
                                      <Grid container item xs={12} justifyContent="space-between"
                                      // lg={6}
                                      >
                                        {handleGetRole() === 'Seller' ? (
                                          <>
                                            <Grid item xs={12}>
                                              <Typography>Ship From</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={6} className={classes.padding8}>
                                              <TextFieldProfile
                                                name="locationLine1"
                                                value={shipFromLocationLine1}
                                                label="Address Line1*"
                                                error={!!shipFromlocationError && !shipFromLocationLine1}
                                                handleChange={(data) => {
                                                  setShipFromLine1(data);
                                                  setSellerLocationAddressChanges(
                                                    deal.shipFromLocationLine1 !== data,
                                                  );
                                                }}
                                                disabled={deal.status !== 'IN PROGRESS'}
                                                margin="none"
                                              />
                                              {!!locationError && !shipToLocationLine1 &&
                                                <Typography variant="caption" color="error">Required field</Typography>}
                                            </Grid>
                                            <Grid item xs={12} lg={6} className={classes.padding8}>
                                              <TextFieldProfile
                                                name="locationLine2"
                                                value={shipFromLocationLine2}
                                                label="Address Line2"
                                                error={false}
                                                handleChange={(data) => {
                                                  setShipFromLine2(data);
                                                  setSellerLocationAddressChanges(
                                                    deal.shipFromLocationLine2 !== data,
                                                  );
                                                }}
                                                disabled={deal.status !== 'IN PROGRESS'}
                                                margin="none"
                                              />
                                            </Grid>
                                            <Grid item xs={12} lg={6} className={classes.padding8}>
                                              <TextFieldProfile
                                                name="locationCity"
                                                value={shipFromLocationCity}
                                                label="City*"
                                                error={!!shipFromlocationError && !shipFromLocationCity}
                                                handleChange={(data) => {
                                                  setShipFromCity(data);
                                                  setSellerLocationAddressChanges(
                                                    deal.shipFromLocationCity !== data,
                                                  );
                                                }}
                                                disabled={deal.status !== 'IN PROGRESS'}
                                                margin="none"
                                              />
                                              {!!shipFromlocationError && !shipFromLocationCity &&
                                                <Typography variant="caption" color="error">Required field</Typography>}
                                            </Grid>
                                            <Grid item xs={12} lg={6} className={classes.padding8}>
                                              <FormControl margin="none" fullWidth>
                                                <StateAutocomplete
                                                  label="State*"
                                                  defaultValue={deal.shipFromLocationState || ''}
                                                  currentState={shipFromLocationState}
                                                  handleChange={(newValue: any) => {
                                                    setShipFromState(newValue as string);
                                                    setSellerLocationAddressChanges(
                                                      deal.shipFromLocationState !== newValue,
                                                    );
                                                  }}
                                                  disabled={deal.status !== 'IN PROGRESS'}
                                                  error={!!shipFromlocationError && !shipFromLocationState}
                                                />
                                              </FormControl>
                                              {!!shipFromlocationError && !shipFromLocationState &&
                                                <Typography variant="caption" color="error">Required field</Typography>}
                                            </Grid>
                                            <Grid item xs={12} lg={6} className={classes.padding8}>
                                              <TextField
                                                name="locationZip"
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                id="locationZip"
                                                label="Zip*"
                                                margin="none"
                                                autoComplete="new-password"
                                                value={shipFromLocationZip}
                                                onChange={({ target }) => {
                                                  setShipFromLocationZipError(false);
                                                  setShipFromZip(target.value);
                                                  setSellerLocationAddressChanges(
                                                    deal.shipFromLocationZip !== target.value,
                                                  );
                                                }}
                                                error={(!!shipFromlocationZipError && !shipFromLocationZip) ||
                                                  (!!shipFromlocationZipError && (shipFromLocationZip.length < 5 ||
                                                    (shipFromLocationZip.length > 5 &&
                                                      shipFromLocationZip.length < 9)))}
                                                InputProps={{
                                                  inputComponent: NumberFormatZip as any,
                                                }}
                                                disabled={deal.status !== 'IN PROGRESS'}
                                              />
                                              {!!shipFromlocationZipError &&
                                                (shipFromLocationZip.length < 5 ||
                                                  (shipFromLocationZip.length > 5 && shipFromLocationZip.length < 9)) &&
                                                <Typography variant="caption" color="error">{shipFromlocationZipError}</Typography>}
                                            </Grid>
                                            <Grid item xs={12} lg={6} className={classes.padding8}>
                                              <TextFieldProfile
                                                name="locationCountry"
                                                value={shipFromLocationCountry}
                                                label="Country"
                                                error={false}
                                                // error={!!locationError && !shipToLocationCountry}
                                                handleChange={(data) => {
                                                  setShipFromCountry(data);
                                                  setSellerLocationAddressChanges(
                                                    deal.shipFromLocationCountry !== data,
                                                  );
                                                }}
                                                disabled={deal.status !== 'IN PROGRESS'}
                                                margin="none"
                                              />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <ButtonSC
                                                variant="fill"
                                                text="Save changes"
                                                size="large"
                                                color="green"
                                                handleClick={() => handleSaveSellerLocation(deal)}
                                                disabled={deal.status !== 'IN PROGRESS' || !sellerLocationAddressChanges}
                                              />
                                            </Grid>
                                          </>
                                        ) :
                                          (
                                            <>
                                              <Grid item xs={4}>
                                                <Typography>Ship From:</Typography>
                                              </Grid>
                                              
                                              <Grid item xs={8}>
                                                <Typography className={classes.secondaryHeading}>{` ${shipFromLocationState}${shipFromLocationState && ','} ${shipFromLocationZip.substring(0, 5)}`}</Typography>
                                              </Grid>
                                            </>
                                          )}
                                      </Grid>
                                      {/* <Grid item xs={4}>
                                        <Typography className={classes.pos}>
                                          Ship From:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} >
                                        <Typography className={classes.secondaryHeading}>
                                          {`${currentInventory?.locationState}${currentInventory?.locationState && ','} ${currentInventory?.locationZip.substring(0, 5)}`}
                                        </Typography>
                                      </Grid> */}
                                    </Grid>
                                  {/* </Hidden> */}
                                  <Grid container item xs={12} justifyContent="space-between"
                                  // lg={6}
                                  >
                                    {handleGetRole() === 'Buyer' ? (
                                      <>
                                        <Grid item xs={12}>
                                          <Typography>Ship To</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={6} className={classes.padding8}>
                                          <TextFieldProfile
                                            name="locationLine1"
                                            value={shipToLocationLine1}
                                            label="Address Line1*"
                                            error={!!locationError && !shipToLocationLine1}
                                            handleChange={(data) => {
                                              setShipToLine1(data);
                                              setLocationAddressChanges(
                                                deal.shipToLocationLine1 !== data,
                                              );
                                            }}
                                            disabled={deal.status !== 'IN PROGRESS'}
                                            margin="none"
                                          />
                                          {!!locationError && !shipToLocationLine1 &&
                                            <Typography variant="caption" color="error">Required field</Typography>}
                                        </Grid>
                                        <Grid item xs={12} lg={6} className={classes.padding8}>
                                          <TextFieldProfile
                                            name="locationLine2"
                                            value={shipToLocationLine2}
                                            label="Address Line2"
                                            error={false}
                                            handleChange={(data) => {
                                              setShipToLine2(data);
                                              setLocationAddressChanges(
                                                deal.shipToLocationLine2 !== data,
                                              );
                                            }}
                                            disabled={deal.status !== 'IN PROGRESS'}
                                            margin="none"
                                          />
                                        </Grid>
                                        <Grid item xs={12} lg={6} className={classes.padding8}>
                                          <TextFieldProfile
                                            name="locationCity"
                                            value={shipToLocationCity}
                                            label="City*"
                                            error={!!locationError && !shipToLocationCity}
                                            handleChange={(data) => {
                                              setShipToCity(data);
                                              setLocationAddressChanges(
                                                deal.shipToLocationCity !== data,
                                              );
                                            }}
                                            disabled={deal.status !== 'IN PROGRESS'}
                                            margin="none"
                                          />
                                          {!!locationError && !shipToLocationCity &&
                                            <Typography variant="caption" color="error">Required field</Typography>}
                                        </Grid>
                                        <Grid item xs={12} lg={6} className={classes.padding8}>
                                          <FormControl margin="none" fullWidth>
                                            <StateAutocomplete
                                              label="State*"
                                              defaultValue={deal.shipToLocationState || ''}
                                              currentState={shipToLocationState}
                                              handleChange={(newValue: any) => {
                                                setShipToState(newValue as string);
                                                setLocationAddressChanges(
                                                  deal.shipToLocationState !== newValue,
                                                );
                                              }}
                                              disabled={deal.status !== 'IN PROGRESS'}
                                              error={!!locationError && !shipToLocationState}
                                            />
                                          </FormControl>
                                          {!!locationError && !shipToLocationState &&
                                            <Typography variant="caption" color="error">Required field</Typography>}
                                        </Grid>
                                        <Grid item xs={12} lg={6} className={classes.padding8}>
                                          <TextField
                                            name="locationZip"
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            id="locationZip"
                                            label="Zip*"
                                            margin="none"
                                            autoComplete="new-password"
                                            value={shipToLocationZip}
                                            onChange={({ target }) => {
                                              setLocationZipError(false);
                                              setShipToZip(target.value);
                                              setLocationAddressChanges(
                                                deal.shipToLocationZip !== target.value,
                                              );
                                            }}
                                            error={(!!locationZipError && !shipToLocationZip) ||
                                              (!!locationZipError && (shipToLocationZip.length < 5 ||
                                                (shipToLocationZip.length > 5 &&
                                                  shipToLocationZip.length < 9)))}
                                            InputProps={{
                                              inputComponent: NumberFormatZip as any,
                                            }}
                                            disabled={deal.status !== 'IN PROGRESS'}
                                          />
                                          {!!locationZipError &&
                                            (shipToLocationZip.length < 5 ||
                                              (shipToLocationZip.length > 5 && shipToLocationZip.length < 9)) &&
                                            <Typography variant="caption" color="error">{locationZipError}</Typography>}
                                        </Grid>
                                        <Grid item xs={12} lg={6} className={classes.padding8}>
                                          <TextFieldProfile
                                            name="locationCountry"
                                            value={shipToLocationCountry}
                                            label="Country"
                                            error={false}
                                            // error={!!locationError && !shipToLocationCountry}
                                            handleChange={(data) => {
                                              setShipToCountry(data);
                                              setLocationAddressChanges(
                                                deal.shipToLocationCountry !== data,
                                              );
                                            }}
                                            disabled={deal.status !== 'IN PROGRESS'}
                                            margin="none"
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <ButtonSC
                                            variant="fill"
                                            text="Save changes"
                                            size="large"
                                            color="green"
                                            handleClick={() => handleSaveLocation(deal)}
                                            disabled={deal.status !== 'IN PROGRESS' || !locationAddressChanges}
                                          />
                                        </Grid>
                                      </>
                                    ) :
                                      (
                                        <>
                                          <Grid item xs={4}>
                                            <Typography>Ship To:</Typography>
                                          </Grid>
                                          
                                          <Grid item xs={8}>
                                            <Typography className={classes.secondaryHeading}>{` ${shipToLocationState}${shipToLocationState && ','} ${shipToLocationZip.substring(0, 5)}`}</Typography>
                                          </Grid>
                                        </>
                                      )}
                                  </Grid>
                                  {/* <Hidden mdDown>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      lg={6}
                                      alignContent="flex-start"
                                    >
                                      <Grid item xs={4}>
                                        <Typography className={classes.pos}>
                                          Ship From ZALUPA:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography className={classes.secondaryHeading}>
                                          {`${currentInventory?.locationState}${currentInventory?.locationState && ','} ${currentInventory?.locationZip.substring(0, 5)}`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Hidden> */}
                                </Grid>
                              </Paper>
                              <br />
                              {handleCheckDeliveryAddressFill(deal) ? (
                                <>
                                  {!!currentInventoryAttributes.length &&
                                    (
                                      <Paper className={classes.paperContainer} ref={IARef}>
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          className={classes.paperTitle}
                                        >
                                          <Grid item>
                                            <Typography variant="h2"> Inventory Attributes</Typography>
                                          </Grid>
                                          <Grid item>
                                            {currentInventoryAttributes.filter((item) => !item.agreed).length > 0 && getRole(userRole) !== 'Admin' && (
                                              <ButtonSC
                                                variant="fill"
                                                text="Agree to All"
                                                size="small"
                                                color="green"
                                                handleClick={handleAcceptAllIA}
                                                disabled={deal.status !== 'IN PROGRESS'}
                                              />
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          container
                                          className={cx(
                                            classes.AccordionSummary,
                                            classes.negotiationPointsHeader,
                                          )}
                                        >
                                          <Grid item xs={5} lg={4}>
                                            <Typography> Inventory Attribute</Typography>
                                          </Grid>
                                          <Grid item xs={5} lg={4}>
                                            <Typography>Values</Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            lg={4}
                                            className={classes.negotiationPointsHeaderAlignRight}
                                          >
                                            <Typography> Expand </Typography>
                                          </Grid>
                                        </Grid>
                                        <InventoryAttributes
                                          proceedError={proceedError}
                                          inventoryAttributes={currentInventoryAttributes}
                                          sellerId={deal.seller && deal.seller.id}
                                          buyerId={deal.buyer && deal.buyer.id}
                                          saveValue={handleUpdateNP}
                                          setAgree={handleSetAgreeToNP}
                                          status={deal.status}
                                          agree={!getAgreeRole()}
                                          expanded={expanded}
                                          setExpanded={setExpanded}
                                          userRole={handleGetRole()}
                                          nextStep={getNextStepToAccept()}
                                        />
                                      </Paper>
                                    )}
                                  <br />
                                  {!!currentNegotiationPoints.length &&
                                    (
                                      <Paper className={classes.paperContainer} ref={NPRef}>
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          className={classes.paperTitle}
                                        >
                                          <Grid item>
                                            <Typography variant="h2"> Negotiation Points </Typography>
                                          </Grid>
                                          <Grid item>
                                            {currentNegotiationPoints.filter((item) => !item.agreed).length > 0 && getRole(userRole) !== 'Admin' && (
                                              <ButtonSC
                                                variant="fill"
                                                text="Agree to All"
                                                size="small"
                                                color="green"
                                                handleClick={handleAcceptAllNP}
                                                disabled={deal.status !== 'IN PROGRESS'}
                                              />
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          container
                                          className={cx(
                                            classes.AccordionSummary,
                                            classes.negotiationPointsHeader,
                                          )}
                                        >
                                          <Grid item xs={5} lg={4}>
                                            <Typography> Negotiation Points</Typography>
                                          </Grid>
                                          <Grid item xs={5} lg={4}>
                                            <Typography>Values</Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            lg={4}
                                            className={classes.negotiationPointsHeaderAlignRight}
                                          >
                                            <Typography>Expand</Typography>
                                          </Grid>
                                        </Grid>
                                        <NegotiationPoints
                                          proceedError={proceedError}
                                          negotiationPoints={currentNegotiationPoints}
                                          sellerId={deal.seller && deal.seller.id}
                                          buyerId={deal.buyer && deal.buyer.id}
                                          saveValue={handleUpdateNP}
                                          setAgree={handleSetAgreeToNP}
                                          status={deal.status}
                                          agree={!getAgreeRole()}
                                          expanded={expanded}
                                          setExpanded={setExpanded}
                                          unitPriceIncrement={deal?.order?.inventory ?
                                            deal?.order?.inventory.inventoryItemType.priceIncrement : 1}
                                          tradeSizeIncrement={deal?.order?.inventory ?
                                            deal?.order?.inventory.inventoryItemType.tradeSizeIncrement : 1}
                                          minTradeSizeIncrement={deal.order.inventory ?
                                            deal?.order?.inventory.inventoryItemType.minTradeSize : 1}
                                          userRole={handleGetRole()}
                                          nextStep={getNextStepToAccept()}
                                        />
                                        <Typography variant="h2" className={classes.totalPrice}>
                                          {`Total Price: $${numberWithSpaces(totalPrice.toFixed(2))}`}
                                        </Typography>
                                      </Paper>
                                    )}
                                  <br />
                                  {!!currentPostTradeSteps.length &&
                                    (
                                      <Paper className={classes.paperContainer} ref={PTSRef}>
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          className={classes.paperTitle}
                                        >
                                          <Grid item>
                                            <Typography variant="h2"> Post Trade Steps </Typography>
                                          </Grid>
                                          <Grid item>
                                            {currentPostTradeSteps.filter((item) => !item.agreed).length > 0 && getRole(userRole) !== 'Admin' && (
                                              <ButtonSC
                                                variant="fill"
                                                text="Agree to All"
                                                size="small"
                                                color="green"
                                                handleClick={handleAcceptAllPTS}
                                                disabled={deal.status !== 'IN PROGRESS'}
                                              />
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          container
                                          className={cx(
                                            classes.AccordionSummary,
                                            classes.negotiationPointsHeader,
                                          )}
                                        >
                                          <Grid item xs={5} lg={4}>
                                            <Typography> Post Trade Steps</Typography>
                                          </Grid>
                                          <Grid item xs={5} lg={4}>
                                            <Typography>Values</Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            lg={4}
                                            className={classes.negotiationPointsHeaderAlignRight}
                                          >
                                            <Typography>Expand</Typography>
                                          </Grid>
                                        </Grid>
                                        <PostTradeSteps
                                          proceedError={proceedError}
                                          postTradeSteps={currentPostTradeSteps}
                                          sellerId={deal.seller && deal.seller.id}
                                          buyerId={deal.buyer && deal.buyer.id}
                                          saveValue={handleUpdatePostTradeSteps}
                                          setAgree={handleSetAgreeToPTS}
                                          status={deal.status}
                                          agree={!getAgreeRole()}
                                          expanded={expanded}
                                          setExpanded={setExpanded}
                                          userRole={handleGetRole()}
                                          nextStep={getNextStepToAccept()}
                                        />
                                      </Paper>
                                    )}
                                  {
                                    getAgreeRole() && deal.status === 'IN PROGRESS' && (
                                      <Grid xs={12} className={classes.acceptButtonWrapper}>
                                        <br />
                                        <ButtonSC
                                          variant="fill"
                                          text="Accept all information and proceed to signing the Contract"
                                          size="middle"
                                          color="lightGreen"
                                          handleClick={() => handleAgreeWithDeal(+deal.id)}
                                          disabled={isLoading}
                                        />
                                      </Grid>
                                    )
                                  }
                                </>
                              ) :
                                (
                                  <>
                                    <br />
                                    <Typography variant="h1" align="center" className={classes.textColorGreen}>
                                      Please enter your delivery address to continue
                                    </Typography>
                                  </>
                                )}
                              {deal.postTradeProcessing && (
                                <>
                                  <br />
                                  <Paper className={classes.paperContainer}>
                                    <Typography variant="h2" className={classes.paperTitle}>
                                      Post Trade Steps Check
                                    </Typography>
                                    <PostTradeStepsCheck
                                      postTradeSteps={currentPostTradeSteps}
                                      finishStep={handleFinishPTS}
                                      sellerId={deal.seller && deal.seller.id}
                                      buyerId={deal.buyer && deal.buyer.id}
                                      expanded={expanded}
                                      setExpanded={setExpanded}
                                      currentStep={deal.postTradeProcessing.stepNow}
                                    />
                                  </Paper>
                                </>
                              )}
                              {openChat && <div style={{ height: 230 }} />}
                            </>
                          )}
                      </>}


                    </>
                  )
                  )}

              </>
            </div>
            <DrawerChat
              open={openChat}
              handleDrawerOpen={handleDrawerOpen}
              deal={deal}
              chatMessages={chatMessages}
              handleSendMessage={handleSendMessage}
              // sellerOnOff={sellerOnOff}
              // buyerOnOff={buyerOnOff}
            />
          </div>

        ))
      }

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={!!isOpenFeeAccept}
        onClose={() => openFeeAccept(false)}
      >
        <DialogTitle>
          AgroAccord Service Fee Notification and Terms and Condition Acceptance
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Service Fee for this SmartDeal
            <sup>TM</sup>
            {` transaction is $ ${isOpenFeeAccept}. `}
            To execute and memorialize the “Purchase and Sale Agreement” associated with the
            SmartDeal
            <sup>TM</sup>
            {` #${dealRoomId} `}
            negotiated terms and conditions,
            including Post Trade Steps,
            {' please press the “Accept” button below. '}
            You will then automatically be sent an invoice via email.
            <br />
            If you decline to accept the Service Fee, your SmartDeal
            <sup>TM</sup>
            {' negotiation session will end and you will be placed back in the general MarketPlace'}
            <br />
            <br />
            {'Additionally, by pressing “Accept” button below you indicate that you have read, understand, acknowledge,'}
            and accept the AgroAccord <a target="_blank" href={`${process.env.REACT_APP_BASE_UI_URL || 'http://localhost:3000'}/termsOfService`} >Terms and Conditions</a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-around">
            <Grid item>
              <ButtonSC
                variant="fill"
                text="Decline"
                size="large"
                color="red"
                handleClick={() => {
                  openFeeAccept(false);
                  openFeeAcceptFailuare(true);
                }}
              />
            </Grid>
            <Grid item>
              <ButtonSC
                variant="fill"
                text="Accept"
                size="large"
                color="green"
                handleClick={() => {
                  dispatch(agreeWithDeal(
                    dealId,
                    accountId,
                    token,
                    new Date().getTime() + Math.random(),
                  ));
                  openFeeAccept(false);
                  openFeeAcceptSuccess(true);
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
        <DialogContent>
          <DialogContentText>
            *Failure to accept the AgroAccord Service Fee will result in canceling SmartDeal
            <sup>TM</sup>
            {` #${dealRoomId} `}
            and all negotiation points will be lost!
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={!!isOpenFeeAcceptSuccess}
        onClose={() => openFeeAcceptSuccess(false)}
      >
        <DialogTitle>
          MGTrader Service Fee Notification and Acceptance
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            “Thank you for using MGTrader and accepting the Service Fee, please check your email and
            pay the invoice within 48 Hours. If you do not see the invoice in your inbox,
            please check your deleted or trash file!
            <br />
            <br />
            Thank you,
            <br />
            The MGTrader Team”
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Grid item>
              <ButtonSC
                variant="fill"
                text="Close"
                size="large"
                color="red"
                handleClick={() => {
                  openFeeAcceptSuccess(false);
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={!!isOpenFeeAcceptFailuare}
        onClose={() => openFeeAcceptFailuare(false)}
      >
        <DialogTitle>
          MGTrader Service Fee Notification and Acceptance
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failure to accept the MGTrader Service Fee will result in canceling SmartDeal
            <sup>TM</sup>
            {` #${dealRoomId} `}
            and all negotiation points will be lost!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-around">
            <Grid item>
              <ButtonSC
                variant="fill"
                text="Confirm Cancelation"
                size="large"
                color="red"
                handleClick={() => {
                  dispatch(leftTheDeal(
                    dealRoomId,
                    accountId,
                    token,
                    new Date().getTime() + Math.random(),
                  ));
                  openFeeAcceptFailuare(false);
                }}
              />
            </Grid>
            <Grid item>
              <ButtonSC
                variant="fill"
                text="Go Back and Accept the Service Fee"
                size="large"
                color="green"
                handleClick={() => {
                  openFeeAcceptFailuare(false);
                  openFeeAccept(commision);
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )

};

export default React.memo(DealRoom);


